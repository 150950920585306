import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartsModule } from 'ng2-charts';
import { WeatherWidgetComponent } from './weather-widget.component';
import { WeatherService } from './weather.service';
import { MatSelectModule } from '@angular/material/select';
@NgModule({
  declarations: [WeatherWidgetComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatSelectModule,
    WidgetModule,
    TranslateModule,
    ChartsModule,
    MatSortModule,
    MatTreeModule,
    AngularResizedEventModule,
    MatExpansionModule,
  ],
  providers: [WeatherService],
  exports: [WeatherWidgetComponent],
})
export class WeatherWidgetModule {
}
