import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { ArcgisWidgetService } from './arcgis-widget.service';
import { TranslateModule } from '@ngx-translate/core';
import { HotSpotControlComponent } from './hotspot-control/hotspot-control.component';
import { ArcgisWidgetComponent } from '@app/modules/widgets/arcgis-widget/arcgis-widget.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [ArcgisWidgetComponent, HotSpotControlComponent],
  imports: [CommonModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    MapWidgetModule,
    MatRadioModule],
  providers: [ArcgisWidgetService,
    { provide: 'arcgis_service', useClass: ArcgisWidgetService }],
  exports: [ArcgisWidgetComponent, HotSpotControlComponent],
})
export class ArcgisWidgetModule {
}
