import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { FlowsModule } from '@app/modules/widgets/flows/flows.module';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { TranslateModule } from '@ngx-translate/core';
import { FlowMapWidgetComponent } from './flow-map-widget.component';
import { FlowMapWidgetService } from './flow-map-widget.service';

@NgModule({
  declarations: [FlowMapWidgetComponent],
  providers: [FlowMapWidgetService,
    { provide: 'flow_map_widget_service', useClass: FlowMapWidgetService }],
  imports: [
    CommonModule,
    AppMaterialModule,
    MapWidgetModule,
    WidgetModule,
    LeafletModule,
    FlowsModule,
    TranslateModule,
    MapLegendModule,
    MatButtonModule,
  ],
  exports: [FlowMapWidgetComponent],
})
export class FlowMapWidgetModule {
}
