<div class="page-menu-bar d-flex flex-column align-items-stretch">
  <div class="menu d-flex flex-row justify-content-start align-items-stretch m-3" *ngIf="menu && menu.length > 1">
    <button
      *ngFor="let tab of menu"
      class="btn btn-default mr-2"
      [ngClass]="{'disabled': !tab.enabled, 'active': tab.active}"
      (click)="setPage(tab)">
      {{'PAGES.' + pageLabel + '.MENU.' + tab.label | translate }}
    </button>
  </div>
  <div *ngIf="menu" class="content d-flex" [ngClass]="{'content--large': menu.length === 1}" >
    <ng-container *ngIf="activeTab && activeTab.enabled">
      <ng-container *ngIf="activeTab.menuType === 'url' || activeTab.menuType === 'nanocube'" >
        <iframe class="iframe" width="100%" height="100%" seamless title="big-data-page" [hidden]="loaded" *ngIf="safeUrl" (load)="uploadDone()" (error)="iframeError()"
                [src]="safeUrl"></iframe>
        <div class="iframe-error" *ngIf="showIframeError">
          <h1>{{'COMMONS.IFRAME_ERROR' | translate}}</h1>
        </div>
      </ng-container>
      <ng-container *ngIf="activeTab.menuType === 'content'">
        <ng-content></ng-content>
      </ng-container>
      <ng-container *ngIf="activeTab.menuType === 'dashboard'">
        <app-dashboard class="dashboard" *ngIf="dashboardPage" [page]="dashboardPage">
        </app-dashboard>
      </ng-container>
    </ng-container>
    <div class="full-screen d-flex flex-column justify-content-center align-items-center" *ngIf="!activeTab">
      <h1>{{'COMMONS.IFRAME_ERROR' | translate}}</h1>
    </div>
    <div class="full-screen  d-flex flex-column justify-content-center align-items-center" *ngIf="error">
      <img src="assets/logo/mindicity.jpg" />
      <h2 *ngIf="errorType === 'TAB_NOT_ENABLED'">{{'COMMONS.TAB_NOT_ENABLED' | translate}}</h2>
      <h2 *ngIf="errorType === 'NOT_ACTIVE_TAB'">{{'COMMONS.NOT_ACTIVE_TAB' | translate}}</h2>
    </div>
  </div>
</div>
