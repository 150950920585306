import { EventEmitter } from '@angular/core';
import { GridStackItem } from '@app/modules/dashboard/ngx-grid-stack/models/grid-stack-item.model';
import { WidgetModel } from '@app/shared/models/widgets/widget-model';
import { WidgetType } from '../../enums/widget-type';
import { cloneDeep } from 'lodash';
import { SerializedWidget, WidgetConfigurationData } from '@app/shared/models/dashboard/serialized-grid';

export class DashboardItem extends GridStackItem {
  x: number;
  y: number;
  width: number;
  height: number;
  name: string;
  originalX: number;
  originalY: number;
  originalWidth: number;
  originalHeight: number;
  title: string;
  type: WidgetType;
  fullscreen: boolean;
  noMove: boolean;
  noResize: boolean;
  data: WidgetConfigurationData;
  events: EventEmitter<WidgetEvent>;
  alarms: string;
  configuration: string;
  thresholds: string;
  configurationId: number;
  roles: Array<string>;
  privileges: Array<string>;

  constructor() {
    super();
    this.x = 0;
    this.y = 0;
    this.width = 2;
    this.height = 3;
    this.name = null;
    this.title = 'Sample Title';
    this.type = WidgetType.WIDGET;
    this.data = {};
    this.fullscreen = false;
    this.events = new EventEmitter<WidgetEvent>();
    this.alarms = null;
    this.configuration = null;
    this.thresholds = null;
    this.configurationId = 0;
    this.roles = [];
    this.privileges = [];
  }

  initFromDefault(node: WidgetModel): void {
    this.x = 0;
    this.y = 0;
    this.width = node.width;
    this.height = node.height;
    this.type = node.type as WidgetType;
    this.title = node.title;
    this.data = JSON.parse(node.configuration);
    this.name = node.name;
    this.roles = node.roles;
    this.privileges = node.privileges;
  }

  setData(node: SerializedWidget): void {
    this.x = node.x;
    this.y = node.y;
    this.width = node.width;
    this.height = node.height;
    this.type = node.type as WidgetType;
    this.title = node.title;
    this.name = node.name;
    this.data = cloneDeep(node.data);
  }

  toggleFullScreen(fullScreen: boolean): void {
    this.fullscreen = fullScreen;
    if (this.fullscreen) {
      this.originalWidth = this.width;
      this.originalHeight = this.height;
      this.width = 12;
      this.height = 12;
    } else {
      this.width = this.originalWidth;
      this.height = this.originalHeight;
    }
  }

  toggleOrigin(fullScreen: boolean): void {
    if (fullScreen) {
      this.originalX = this.x;
      this.originalY = this.y;
      this.x = 0;
      this.y = 0;
    } else {
      this.x = this.originalX;
      this.y = this.originalY;
    }
  }

  export(): SerializedWidget {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
      title: this.title,
      type: this.type,
      name: this.name,
      data: this.data
    };
  }

  handleEvent(event: string, data: any): void {
    this.events.emit({
      event: event,
      data: data,
    });
  }
}


export interface WidgetEvent {
  event: string;
  data: any;
}
