import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataList } from '@app/shared/models/venice-data-lake/data-result';
import { SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { WidgetModel } from '@app/shared/models/widgets/widget-model';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class WidgetListService {

  constructor(private http: HttpClient) {
  }

  getWidgetModels(): Observable<Array<WidgetModel>> {
    const body: SearchRequest = {};
    return this.http.post<DataList<WidgetModel>>(`/gateway/mcapi/manager/widgets-sources/widgets-list`, body).pipe(
      switchMap((w: DataList<WidgetModel>) => {
        return of(w.list);
      })
    );
  }
}
