type AnyDuringRendererMigration = any;

export function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration): Array<string | any> {
  if (name[0] === ':') {
    const match: any = name.match(/^:([^:]+):(.+)$/);
    return [match[1], match[2]];
  }
  return ['', name];
}

export function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration): void {
  const [namespace, name]: Array<any> = __ngRendererSplitNamespaceHelper(namespaceAndName);
  if (value != null) {
    renderer.setAttribute(element, name, value, namespace);
  } else {
    renderer.removeAttribute(element, name, namespace);
  }
}
