<h1 mat-dialog-title>{{title}}</h1>
<div class="pb-3">
  <json-schema-form
    class="json-schema-form"
    *ngIf="schema"
    language="it"
    [form]="schema"
    framework="material-design"
    [(ngModel)]="schema.data"
    [widgets]="widgets"
    [ngModelOptions]="{standalone: true}"
    (isValid)="isValid($event)"
    (validationErrors)="validationErrors($event)"
    >
  </json-schema-form>
  <div class="d-flex flex-row justify-content-end align-items-stretch">
    <button mat-raised-button color="warn" class="mr-2" (click)="cancel()">{{'COMMONS.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary" type="submit"
            (click)="submit(schema.data)">{{'COMMONS.OK' | translate}}</button>
  </div>
</div>
