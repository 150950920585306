<app-widget class="mindicity-widget" [sources]="sources" [title]="title" [description]="description"
            (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
>
  <div class="container">
    <ul class="list-group w-100 mt-2">
      <li *ngFor="let event of events" class="text-left">
        <div class="row mb-2 event">
          <div class="col p-2 infoContainer" [ngClass]="event.type">
            <div class="eventDescr mb-2">
              <div class="font-weight-bold">{{event.title}}</div>
            </div>
            <div class="eventDescr mb-2">
              <div>{{event.location}}</div>
            </div>
            <div class="eventDescr mb-2">
              <div>{{event.description}}</div>
            </div>
            <div class="eventStatus">
              <div *ngIf="event.status.info">
                Stato: {{event.status.info}}
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</app-widget>
