import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoadFlowMeanService } from '@app/modules/widgets/flows/road-flow-mean-widget/road-flow-mean.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoadFlowMeanWidgetComponent } from './road-flow-mean-widget.component';



@NgModule({
  declarations: [RoadFlowMeanWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule,
  ],
  providers: [RoadFlowMeanService],
  exports: [RoadFlowMeanWidgetComponent]
})
export class RoadFlowMeanWidgetModule { }
