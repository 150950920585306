import { SerializedIndices } from '@app/modules/widgets/indexes-widget/model/serialized-indices';
import moment, { Moment } from 'moment';

export class Indices {
  time: Moment;
  airTemperature: number | null;
  camera: number | null;
  group: number | null;
  humidity: number | null;
  meteo: number | null;
  pedestrian: number | null;
  publicSentiment: number | null;
  reportCzrmDime: number | null;
  roadVehiclesInefficiency: number | null;
  securityEvent: number | null;
  tide: number | null;
  tplGlobalInefficiency: number | null;
  trafficRoad: number | null;
  trafficWater: number | null;
  trafficWaterAvg: number | null;
  waterTemperature: number | null;
  waterVehiclesInefficiency: number | null;
  wave: number | null;
  waveAvg: number | null;
  windSpeed: number | null;
  heartbit: number | null;

  constructor(s: SerializedIndices) {

    this.time = moment(s.time);
    this.airTemperature = s.air_temperature;
    this.camera = s.camera;
    this.group = s.group;
    this.humidity = s.humidity;
    this.meteo = s.meteo;
    this.pedestrian = s.pedestrian;
    this.publicSentiment = s.public_sentiment;
    this.reportCzrmDime = s.report_czrm_dime;
    this.roadVehiclesInefficiency = s.road_vehicles_inefficiency;
    this.securityEvent = s.security_event;
    this.tide = s.tide;
    this.tplGlobalInefficiency = s.tpl_global_inefficiency;
    this.trafficRoad = s.traffic_road;
    this.trafficWater = s.traffic_water;
    this.trafficWaterAvg = s.traffic_water_avg;
    this.waterTemperature = s.water_temperature;
    this.waterVehiclesInefficiency = s.water_vehicles_inefficiency;
    this.wave = s.wave;
    this.waveAvg = s.wave_avg;
    this.windSpeed = s.wind_speed;
    this.heartbit = s.heartbit;
  }

}
