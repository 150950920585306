import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { Observable, Subject } from 'rxjs';

const headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'text/plain' });

@Injectable()
export class MeteoService extends WidgetService {

  private tempAria: Subject<any> = new Subject<any>();
  private umidita: Subject<any> = new Subject<any>();

  getBaseUrl(): string {
    return 'https://cors-anywhere.herokuapp.com/http://dati.venezia.it/';
  }

  getTempAria(): Observable<any> {
    return this.http.get(this.getBaseUrl() + 'sites/default/files/dataset/opendata/temparia.json', {
      responseType: 'text',
      headers: headers,
    });
  }

  getUmidita(): Observable<any> {
    const httpHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'text/plain' });
    return this.http.get(this.getBaseUrl() + 'sites/default/files/dataset/opendata/umidita.json', {
      responseType: 'text',
      headers: httpHeaders,
    });
  }
}

