import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { EventsWidgetComponent } from './events-widget.component';


@NgModule({
  declarations: [EventsWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule
  ],
  exports: [EventsWidgetComponent],
})
export class EventsWidgetModule {
}
