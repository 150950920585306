export const ARCGIS_LEGEND: any = {
  'CAMERA': {
    title: 'CAMERA_TITLE',
    description: 'CAMERA_DESCRIPTION',
    type: 'CAMERA_LEGEND',
    elements: [{
      color: '#D21933',
      label: 'GUASTA',
    }, {
      color: '#FFFF74',
      label: 'NON MONITORATA',
    }, {
      color: '#99E600',
      label: 'IN FUNZIONE',
    }],
    additionalData: null,
  },
};

