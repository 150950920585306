import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { AngularResizedEventModule } from 'angular-resize-event';
import { TideWidgetComponent } from './tide-widget.component';
import { TideService } from './tide.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TideWidgetComponent],
  imports: [
    WidgetModule,
    CommonModule,
    TranslateModule,
    AngularResizedEventModule,
    FormsModule
  ],
  providers: [TideService],
  exports: [TideWidgetComponent]
})
export class TideWidgetModule {
}
