<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData">
  <div class="image-demo-widget">
    <div class="img-box" *ngIf="images && images.length === 1">
      <ng-container *ngFor="let image of images">
        <img class="mock img-fluid" [src]="image" alt="">
      </ng-container>
    </div>
    <ng-container *ngIf="images && images.length > 1">
      <mat-carousel
        class="img-box"
        color="primary"
        style="height: 100%;"
        timings="250ms ease-in"
        [interval]="data.timer"
        [autoplay]="true"
        [maintainAspectRatio]="false"
        [hideArrows]="true"
        [hideIndicators]="false"
        [loop]="true">
        <mat-carousel-slide
          class="slide"
          overlayColor="#fff" *ngFor="let image of images">
          <img class="mock img-fluid" [src]="image" alt="">
        </mat-carousel-slide>
      </mat-carousel>
    </ng-container>
  </div>
</app-widget>
