import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { ErrorInterceptor } from '@app/core/interceptors/error.interceptor';
import { HttpConfigInterceptor } from '@app/core/interceptors/httpConfig.interceptor';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { AlertsService } from '@services/alerts/alerts.service';
import { AppConfigService } from '@services/app-config/app-config.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { GridService } from '@services/grid/grid.service';
import { InitializerService } from '@services/initializer/initializer.service';
import { LoaderService } from '@services/loader/loader.service';
import { RangeManagerService } from '@services/range-manager/range-manager.service';
import { SourcesService } from '@services/sources/sources.service';
import { StorageService } from '@services/storage/storage.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { WidgetListService } from '@services/widget-list/widget-list.service';
import { NgPipesModule, SlugifyPipe } from 'ngx-pipes';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { ChangelogsService } from '@services/changelogs/changelogs.service';

@NgModule({
  providers: [
    AppConfigService,
    AlertsService,
    ConfigurationService,
    ChangelogsService,
    SourcesService,
    GridService,
    ErrorInterceptor,
    JwtInterceptor,
    HttpConfigInterceptor,
    LoaderService,
    StorageService,
    InitializerService,
    TimeMachineService,
    ThemeService,
    RangeManagerService,
    WidgetListService,
    DashboardService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InitializerService],
      useFactory: (initializerService: InitializerService): () => any => {
        return (): any => {
          return initializerService.initApp();
        };
      },
    },
    SlugifyPipe,
  ],
  declarations: [],
  imports: [
    CommonModule,
    NgPipesModule,
  ],
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
