import { PermissionsConfig } from '@app/shared/models/app-config/app-options';

export interface TimeMachineSettings {
  enabled: boolean;
  allowFuture: boolean;
  autoRefresh: boolean;
  advanceRangeMode: boolean;
  defaultRange: boolean;
  defaultRangeKey?: string;
}

export interface MenuVoice extends PermissionsConfig {
  id: string;
  title: string;
  icon: string;
  link: string;
  enabled: boolean;
  big: boolean;
  subMenu: Array<SubMenu>;
  roles?: Array<string>;
  privileges?: Array<string>;
  pageLabel?: string;
  menuKey?: string;
  timeMachine?: TimeMachineSettings;
}
export enum MenuType {
  URL = 'url',
  DASHBOARD = 'dashboard',
  CONTENT = 'content',
  NANOCUBE = 'nanocube'
}

export interface SubMenu {
  active: boolean;
  enabled: boolean;
  label: string;
  id: string;
  dashboardId?: string;
  menuType?: MenuType;
  url?: string;
  sourceName?: string;
  additional?: boolean;
  roles?: Array<string>;
  privileges?: Array<string>;
  timeMachine?: TimeMachineSettings;
  pageConfig?: any;
}
