export interface FlowCamera {
  coordinates: {
    latitude: number;
    longitude: number;
  };
  id: string | number;
  cameraId: string;
  stream: boolean;
  status: FlowCameraStatus;
  reliability: number;
  version?: FlowCameraVersion;
  streamUrl?: string;
  statusUrl: string;
  direction?: string;
}

export enum FlowCameraStatus {
  OK = 0,
  NOT_REACHABLE = 1,
  NO_STREAMING = 2,
  NO_AI = 3,
  NON_WORKING = 4
}

export interface FlowCameraVersion {
  Pegaso: string;
  Detector: string;
  Tracker: string;
  Torch: string;
}
