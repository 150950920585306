import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable, of, throwError } from 'rxjs';
import { cloneDeep } from 'lodash';

@Injectable()
export class VideoFeedService extends WidgetService {

  public loadData(timestamp: TimeMachineData, sources: Array<WidgetDataSource>): Observable<string> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'video';
    });
    if (mainSource) {
      return of(cloneDeep(this.addTimeMachineDataToUrl(timestamp, mainSource)));
    } else {
      throwError('No video source provided');
    }
  }

  public checkSource(source: WidgetDataSource): Observable<ArrayBuffer> {
    const newUrl: string = cloneDeep(source.rewriteUrl).replace('{{number}}', '3128');
    return this.http.get<ArrayBuffer>(newUrl, { responseType: 'blob' as 'json' });
  }
}
