<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable">
  <div class="chartBox flex flex-column align-items-stretch justify-content-start">
    <p class="mb-1">
      {{ dateTime }}
    </p>
    <!--
    <canvas baseChart [datasets]="barChartData" [labels]="chartLabels" [options]="lineChartOptions " [colors]="lineChartColors " [legend]="lineChartLegend " [chartType]="lineChartType " [plugins]=" " (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event) "></canvas>
    -->
    <canvas class="chart" baseChart
            [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions" [plugins]="chartPlugins"
            [legend]="chartLegend" [chartType]="chartType">
    </canvas>
  </div>
</app-widget>
