export interface SearchRequest {
  fields?: Array<string>;
  filter?: FilterRequest;
  filters?: Array<any>;
  pagination?: PaginationRequest;
  order?: Array<OrderRequest>;
  orders?: Array<OrderRequest>;
  relations?: Array<any>;
}

export interface OrderRequest {
  field: string;
  mode: OrderRequestDirection;
}

export interface PaginationRequest {
  from: number;
  size: number;
}

export interface FilterRequest {
  [propName: string]: any;
}

export enum OrderRequestDirection {
  DESC = 'desc',
  ASC = 'asc'
}
