import { CommonModule, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { IndexesWidgetComponent } from '@app/modules/widgets/indexes-widget/indexes-widget.component';
import { IndexesWidgetService } from '@app/modules/widgets/indexes-widget/indexes-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';

registerLocaleData(localeIT);

@NgModule({
  declarations: [IndexesWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule,
    AngularResizedEventModule
  ],
  providers: [IndexesWidgetService, { provide: LOCALE_ID, useValue: 'it' }],
  exports: [IndexesWidgetComponent],
})
export class IndexesWidgetModule {
}
