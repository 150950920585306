import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const CAMERA_LEGEND: MapLegend = {
  title: 'CAMERA_TITLE',
  description: 'CAMERA_DESCRIPTION',
  type: 'CAMERA_LEGEND',
  elements: [{
    color: '#D21933',
    label: 'GUASTA',
  }, {
    color: '#FFFF74',
    label: 'NON MONITORATA',
  }, {
    color: '#99E600',
    label: 'IN FUNZIONE',
  }],
  additionalData: null,
};

