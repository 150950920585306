<app-widget
  class="mindicity-widget"
  [sources]="sources"
  [title]="title"
  [description]="description"
  (fullScreen)="toggleFullscreen()"
  [isEditable]="isEditable"
  [isLoading]="isLoading"
  (removeWidget)="sendRemoveWidget()"
  [sourceStatus]="sourceStatus"
  (sendReloadWidget)="reloadWidget()"
  [timeMachineOn]="timeMachineOn"
  [noData]="noData"
>
  <div class="container d-flex flex-column justify-content-center align-items-center">
    <div class="number mb-2">
      {{ number }}
    </div>
    <div class="date mt-2">
      <ng-container *ngIf="!timeMachineService.rangeOn">
        <i class="date__text">{{'WIDGETS.ROAD_FLOW_NUMBER.DEFAULT_DATA' | translate }}</i>
      </ng-container>
      <ng-container *ngIf="timeMachineService.rangeOn">
        <i
          class="date__text">{{'COMMONS.FROM' | translate}}&nbsp;{{this.timeMachineService.getCurrentSelectionRange().getFromTimeStamp() | date:'dd/MM/yyyy - HH:mm:ss'}}
          <br />{{'COMMONS.TO' | translate | lowercase}}&nbsp;{{this.timeMachineService.getCurrentSelectionRange().getToTimeStamp() | date:'dd/MM/yyyy - HH:mm:ss'}}
        </i>
      </ng-container>

    </div>
  </div>
</app-widget>
