import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EditOverallData, WidgetEditableData } from '@app/modules/dashboard/dashboard.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { DashboardItem } from '@app/shared/models/dashboard/dashboard-item';
import { DataList, DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { InsertResponse, UpsertResponse } from '@app/shared/models/venice-data-lake/update-response';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetRequestBody, WidgetSettings } from '@app/shared/models/widgets/widget-settings';
import { WidgetInstance } from '@app/shared/models/widgets/widget-instance';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import moment, { Moment } from 'moment';
import { SlugifyPipe } from 'ngx-pipes';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import {
  DashboardResponse,
  LoadDashboardResponse, SaveDashboardRequest,
  UpdateDashboardRequest,
} from '@app/shared/models/dashboard/dashboard';
import { SerializedGridInstance, SerializedWidget } from '@app/shared/models/dashboard/serialized-grid';

@Injectable()
export class StorageService {

  currentDashboard: string;
  dashboard: Map<string, SerializedGridInstance> = new Map<string, SerializedGridInstance>();
  availableDashboards: Array<string> = [];
  CURRENT_DASHBOARD_KEY: string = 'scr_dashboard';

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService,
              private configurationService: ConfigurationService,
              private dashboardService: DashboardService,
              private appConfigService: AppConfigService,
              private slugifyPipe: SlugifyPipe) {
    const dashboard: string = localStorage.getItem(this.getCurrentDashboardKey());
    if (dashboard) {
      this.currentDashboard = dashboard;
    }
  }

  getCurrentDashboardKey(): string {
    const currentUser: string = this.authenticationService.getUserName();
    return this.CURRENT_DASHBOARD_KEY + '-' + this.slugifyPipe.transform(currentUser);
  }

  getSavedDashboard(): string {
    return this.currentDashboard;
  }

  hasSavedDashboard(): boolean {
    return this.currentDashboard !== undefined;
  }

  cleanCurrentDashboard(): void {
    localStorage.removeItem(this.getCurrentDashboardKey());
    this.currentDashboard = undefined;
  }

  serializeMap(dashboardMap: Map<string, SerializedGridInstance>): string {
    const object: any = {};
    for (const [key, value] of dashboardMap) {
      object[key] = value;
    }
    return JSON.stringify(object);
  }

  deSerializeJSON(jsonObject: JSON): Map<string, SerializedGridInstance> {
    const loadedDashboards: Map<string, SerializedGridInstance> = new Map<string, SerializedGridInstance>();
    for (const key in jsonObject) {
      if (jsonObject.hasOwnProperty(key)) {
        const property: SerializedGridInstance | Array<SerializedWidget> = jsonObject[key];
        if (property['widgets']) {
          loadedDashboards.set(key, property as SerializedGridInstance);
        } else {
          const widgets: Array<SerializedWidget> = property as Array<SerializedWidget>;
          loadedDashboards.set(key, {
            options: {
              rows: 12,
              columns: 12,
              scrollable: false,
            },
            widgets: widgets,
          });
        }
      }
    }
    return loadedDashboards;
  }

  loadDashboard(page: string): Observable<SerializedGridInstance> {
    if (this.currentDashboard) {
      return this.dashboardService.getDashboard(this.currentDashboard).pipe(
        switchMap((dashboard: LoadDashboardResponse) => {
          const serializedDashboard: JSON = JSON.parse(dashboard.configuration);
          const loadedDashboards: Map<string, SerializedGridInstance> = this.deSerializeJSON(serializedDashboard);
          this.dashboard = loadedDashboards;
          return of(loadedDashboards.get(page));
        }),
      );
    } else {
      return of(undefined);
    }
  }

  loadCustomThresholdsWidgetsTypes(): Observable<Array<WidgetInstance>> {
    const request: SearchRequest = {
      filter: {
        customThresholds: 1,
      },
    };
    return this.http.post<DataList<WidgetInstance>>(`/gateway/mcapi/manager/widgets-sources/widgets-list`, request).pipe(switchMap((d: DataList<WidgetInstance>) => {
      return of(d.list);
    }));
  }

  saveGlobalThreshold(result: EditOverallData): Observable<void> {
    const request: any = {
      thresholds: JSON.stringify(result.data),
    };
    if (result.name) {
      return this.http.patch<any>(`/gateway/mcapi/manager/widgets-sources/widgets/${result.name}`, request);
    } else {
      throw new Error('No widget name');
    }
  }

  saveDashboard(): Observable<any> {
    const dashboard: string = this.serializeMap(this.dashboard);
    const domain: string = this.authenticationService.readUserData().dom;
    if (domain) {
      if (this.availableDashboards.indexOf(this.currentDashboard) === -1) {
        const request: SaveDashboardRequest = {
          name: this.currentDashboard,
          description: '',
          configuration: dashboard,
        };
        return this.dashboardService.saveDashboard(request);
      } else {
        const request: UpdateDashboardRequest = {
          description: '',
          configuration: dashboard,
        };
        return this.dashboardService.updateDashboard(this.currentDashboard, request);
      }
    }
  }

  loadGlobalThresholds(name: string): Observable<Array<WidgetEditableData>> {
    return this.http.get<WidgetInstance>(`/gateway/mcapi/manager/widgets-sources/widgets/${name}`).pipe(
      switchMap((widgetTypeSettings: WidgetInstance) => {
        if (widgetTypeSettings) {
          return of(JSON.parse(widgetTypeSettings.thresholds));
        }
      }),
      catchError(() => {
        return of([]);
      }),
    );
  }

  loadWidgetThreshold(name: string): Observable<Array<WidgetEditableData>> {
    return this.http.get<WidgetSettings>(`/gateway/mcapi/manager/widgets-sources/users/widgetsbynames/${name}`).pipe(
      switchMap((settings: WidgetSettings) => {
        if (settings) {
          return of(JSON.parse(settings.thresholds));
        }
      }),
      catchError(() => {
        return of([]);
      }));
  }

  saveWidgetThreshold(widget: DashboardItem, config: Array<WidgetEditableData>): Observable<UpsertResponse> {
    const body: WidgetRequestBody = {
      widgetName: widget.name,
      thresholds: JSON.stringify(config),
    };
    return this.http.post<InsertResponse>(`/gateway/mcapi/manager/widgets-sources/users/widgetsbynames`, body);
  }

  getAvailableDashboards(): Observable<Array<string>> {
    return this.http.post<DataResult<DashboardResponse>>(`/gateway/mcapi/manager/ui-dashboards/dashboards-list`, {}).pipe(
      switchMap((dashboards: DataResult<DashboardResponse>) => {
        this.availableDashboards = dashboards.data.map((d: DashboardResponse) => {
          return d.name;
        });
        return of(this.availableDashboards);
      }),
    );
  }

  load(page: string): SerializedGridInstance {
    return this.dashboard.get(page);
  }

  save(dashboard: string, data: SerializedGridInstance, page: string): Observable<void> {
    this.currentDashboard = dashboard;
    localStorage.setItem(this.getCurrentDashboardKey(), dashboard);
    this.dashboard.set(page, data);
    return this.saveDashboard();
  }


  setDashboard(dashboard: string, page: string): Observable<SerializedGridInstance> {
    this.currentDashboard = dashboard;
    localStorage.setItem(this.getCurrentDashboardKey(), dashboard);
    this.dashboard = new Map<string, SerializedGridInstance>();
    return this.loadDashboard(page);
  }

  checkWidgetThresholds(timeMachineData: TimeMachineData, type: string): Observable<DataResult<WidgetEvent>> {
    const domain: string = this.authenticationService.readUserData().dom;
    if (domain && type) {
      const minutesAgo: number = moment().subtract(this.appConfigService.getOptions().alertTime, 'minutes').valueOf();
      const requestBody: any = {
        fields: [],
        filter: {
          typeId: type,
          fromDate: minutesAgo,
        },
        order: [
          {
            field: 'unixstamp',
            mode: 'desc',
          },
          {
            field: 'name.keyword',
            mode: 'desc',
          },
        ],
        pagination: {
          from: 0,
          size: 2,
        },
      };
      return this.http.post<DataResult<WidgetEvent>>(`/gateway/mcapi/events/${domain}/users/queues-list`, requestBody);
    }
  }
}

export interface UpdateWidgetRequest {
  widgetId: number;
  name: string;
  description?: string;
  configuration?: string;
  alarms?: string;
  thresholds?: string;
}

export interface WidgetResponse {
  id: number;
  uid: string;
  domain: string;
  name: string;
  description: string;
  createdAt: Moment;
  updatedAt: Moment;
}

export interface LoadWidgetResponse extends WidgetResponse {
  configuration: string;
}

export interface StorageErrorObject {
  instance: string;
  status: number;
  type: string;
  errcode: string;
  message: string;
}
