import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { WindWidgetComponent } from './wind-widget.component';

@NgModule({
  declarations: [WindWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule
  ], exports: [
    WindWidgetComponent,
  ],
})
export class WindWidgetModule {
}
