import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { FeatureGroup, TileLayer } from 'leaflet';
import { Observable, of } from 'rxjs';

@Injectable()
export class HereMapWidgetService extends MapWidgetService {

  getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<FeatureGroup> {
    const featureGroup: FeatureGroup = new FeatureGroup();
    const mainSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'here-traffic';
    });
    if (mainSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, mainSource);
      const layer: TileLayer = new TileLayer(url);
      featureGroup.addLayer(layer);
    }
    return of(featureGroup);
  }

  checkSource(source: WidgetDataSource): Observable<any> {
    let url: string = this.addTimeMachineDataToUrl(new Date().getTime(), source);
    url = url.replace('{z}/{x}/{y}', '14/8692/5866');
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }
}
