import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private jwtInterceptor: JwtInterceptor) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.jwtInterceptor.isAllowedDomain(request) && !this.jwtInterceptor.isDisallowedRoute(request)) {
      return next.handle(request).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401) {
            return this.authenticationService.refreshToken().pipe(mergeMap(() => {
                return this.jwtInterceptor.intercept(request, next);
              }),
            );
          }
          return throwError(err);
        }));
    } else {
      return next.handle(request);
    }
  }
}
