import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { PublicTransportRoute, Transports } from '@app/modules/widgets/public-transport-widget/models';
import { PublicTransportDataSource } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-data-source';
import { Feature, FeatureCollection, Point } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, LatLng, Layer, Marker, Tooltip } from 'leaflet';
import { uniqBy } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PublicTransportsStopsService extends MapWidgetService {

  public getLines(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<Array<PublicTransportRoute>> {
    const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === 'public-transports-lines';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      const sourceData: any = JSON.parse(source.configuration).sourceData;
      const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[type].id.toString());
      return this.http.get<DataResult<PublicTransportRoute>>(agencyUrl).pipe(map((result: DataResult<PublicTransportRoute>) => {
        return result.data;
      }));
    }
  }

  public getLineAndStops(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<FeatureCollection<Point>> {
    const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === 'public-transports-lines-stops-and-routes';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      const sourceData: any = JSON.parse(source.configuration).sourceData;
      const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[type].id.toString());
      return this.http.get<FeatureCollection<Point>>(agencyUrl);
    }
  }

  public addStop(routes: Array<PublicTransportRoute>): ((feature: Feature<Point>, latlng: LatLng) => Layer) {
    return (feature: Feature<Point>, latlng: LatLng): FeatureGroup => {
      const layer: FeatureGroup = new FeatureGroup();
      const marker: Marker = new Marker(latlng);
      const tooltip: Tooltip = new Tooltip({ offset: [0, -5], direction: 'top' });
      let tooltipContent: string = '';
      tooltipContent += `<h6>${feature.properties.name}</h6><br />`;
      uniqBy(feature.properties['routes'], 'shortName').forEach((route: any, index: number, object: Array<any>) => {
        const routeDetails: PublicTransportRoute = routes.find((p: PublicTransportRoute) => {
          return p.shortName === route.shortName;
        });
        if (routeDetails) {
          tooltipContent += `<div class="stop d-flex flex-row justify-content-between align-items-center">
            <div class="stop__entry">
                <div class="tooltip-stop" style="background-color: #${route.color};">
                    <div class="tooltip-stop__circle" style="border: .2rem solid #${route.color}; background-color: #${route.textColor}"></div>
                </div>
            </div>
            <div class="stop__name">${routeDetails.shortName} - ${routeDetails.longName}</div></div>`;
        }
      });
      const markerIcon: DivIcon = new DivIcon({
        className: `stopMarker stopMarker--water`,
        html: `<svg height="12" width="12"><rect height="10" width="10" x="1" y="1" stroke="black" stroke-width="2" fill="white" /></svg>`,
      });
      tooltip.setContent(tooltipContent);
      marker.setIcon(markerIcon);
      marker.bindTooltip(tooltip);
      marker.addTo(layer);
      return layer;
    };
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<FeatureGroup> {
    const routesObservable: Observable<Array<PublicTransportRoute>> = this.getLines(timeMachineData, sources, type);
    const linesAndStopsObservable: Observable<FeatureCollection<Point>> = this.getLineAndStops(timeMachineData, sources, type);
    return forkJoin([routesObservable, linesAndStopsObservable]).pipe(
      map((data: Array<any>) => {
        const routes: Array<PublicTransportRoute> = data[0];
        const linesAndStops: FeatureCollection<Point> = data[1];
        return geoJSON(linesAndStops, {
          pointToLayer: this.addStop(routes),
        });
      }),
    );
  }
}
