<app-widget class="mindicity-widget" [sources]="sources" [title]="title" [description]="description"
            (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
>
  <div class="container">
    <ul class="list-group w-100 mt-2">
      <li *ngFor="let event of events" class="mb-2 text-left">
        <div class="eventStatus mb-2">
          <div class="font-weight-bold"><span class="badge badge-{{event.status.type}}">&nbsp;</span> {{event.title}}
          </div>
        </div>
        <div class="eventStatus mb-2">
          <div>
            Stato: {{event.status.info}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</app-widget>
