import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideosurveillanceWidgetComponent } from './videosurveillance-widget.component';
import { VideosurveillanceWidgetService } from './videosurveillance-widget.service';

@NgModule({
  declarations: [VideosurveillanceWidgetComponent],
  imports: [
    CommonModule,
  ],
  providers: [VideosurveillanceWidgetService,
    { provide: 'video_surveillance_service', useClass: VideosurveillanceWidgetService }],
  exports: [VideosurveillanceWidgetComponent],
})
export class VideosurveillanceWidgetModule {
}
