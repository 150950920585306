import { AfterViewInit, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { ForecastWidgetService } from '@app/modules/widgets/forecast-widget/forecast-widget.service';
import { Forecast } from '@app/modules/widgets/forecast-widget/model/forecast';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { Dictionary, groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import { WidgetConfigurationData } from '@app/shared/models/dashboard/serialized-grid';

@Component({
  selector: 'app-forecast-widget',
  templateUrl: './forecast-widget.component.html',
  styleUrls: ['./forecast-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForecastWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {

  @Input()
  data: WidgetConfigurationData;

  forecasts: Array<Forecast>;
  forecastsGrouped: Array<DayForecasts>;

  constructor(public widgetService: ForecastWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  checkData(): boolean {
    try {
      return this.forecasts.length > 0;
    } catch (e) {
      return false;
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.forecastsGrouped = [];
    this.isLoading = true;
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources, null).subscribe((forecasts: Array<Forecast>) => {
      this.forecasts = forecasts;
      this.noData = !this.checkData();
      const groupedForecast: Dictionary<Array<Forecast>> = groupBy(this.forecasts, 'day');
      Object.keys(groupedForecast).forEach((k: string) => {
        this.forecastsGrouped.push({
          day: moment(k, 'MM-DD-YYYY'),
          forecasts: this.forecasts.filter((f: Forecast) => {
            return f.day === k;
          }),
        });
      });
      this.isLoading = false;
    });
  }

}

export interface DayForecasts {
  day: Moment;
  forecasts: Array<Forecast>;
}
