export type GeocodingRequest = GeocodingLatitudeRequest | GeocodingAddressRequest;

export interface GeocodingAddressRequest {
  type: GeocodingRequestType.ADDRESS;
  longitude?: number;
  latitude?: number;
  radius?: number;
  address?: string;
  cn?: string;
  subcn?: string;
  addressType?: number;
  exactly?: boolean;
}

export interface GeocodingLatitudeRequest {
  type: GeocodingRequestType.LATITUDE;
  latitude: number;
  longitude: number;
  radius: number;
}

export enum GeocodingRequestType {
  LATITUDE = 'LATITUDE',
  ADDRESS = 'ADDRESS'
}
