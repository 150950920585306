<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable" [isLoading]="isLoading" (resized)="handleResize($event)" [noData]="noData">
  <iframe [src]="iframeSrc" [width]="'100%'" [height]="'100%'" *ngIf="iframeSrc"

          (load)="iframeLoaded()"
          (error)="iframeError()"
          seamless
          [title]="title"></iframe>
</app-widget>
