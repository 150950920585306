<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable">
  <div class="map-box" *ngIf="leafletOptions">
    <div leaflet [id]="mapId"
         [leafletOptions]="leafletOptions"
         (leafletMapReady)="onMapReady($event)"
         class="map-container">

      <div *ngIf="showDrawer" leafletDraw [leafletDrawOptions]="drawOptions"
           (leafletDrawCreated)="onDrawCreated($event)"></div>

      <div [leafletLayer]="drawnItems"></div>
    </div>
  </div>
</app-widget>
