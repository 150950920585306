<div class="mobility-control-panel">
  <app-map-legend class="map-legend" *ngIf="legends && legends.length > 0" [legends]="legends"></app-map-legend>

  <app-map-base-control *ngIf="timeseries && timeseries.length > 0"
                        [titleLabel]="'WIDGETS.MOBILITY_MAP.TIMELINE.TITLE'" class="timeline" [show]="true">
    <div class="d-flex flex-row justify-content-between align-items-center mt-3">
      <span>{{currentSimulatorTime | date: 'fullDate' }}</span>
      <span>{{'WIDGETS.MOBILITY_MAP.HOUR' | translate}}: {{currentSimulatorTime | date: 'shortTime' }}</span>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center w-100 mt-2">
      <button mat-mini-fab class="timeline-button" [disabled]="sliderTimeserieValue < 1 || timeserieLoading"
              (click)="previousTime()">
        <mat-icon class="inverted">skip_previous</mat-icon>
      </button>
      <mat-slider class="w-100 mr-2 ml-2 play-slider" [min]="0" [max]="timeseries.length-1" [step]="1"
                  [value]="sliderTimeserieValue"
                  (input)="updateTimeserieValue($event)"
                  (change)="setTimeserieNewValue($event)"></mat-slider>
      <button mat-mini-fab class="timeline-button"
              [disabled]="sliderTimeserieValue === timeseries.length || timeserieLoading"
              (click)="nextTime()">
        <mat-icon class="inverted">skip_next</mat-icon>
      </button>
    </div>
    <!-- <h6>{{'WIDGETS.MOBILITY_MAP.TIMELINE.CONTROLS' | translate }}</h6> -->
    <div class="d-flex flex-row justify-content-between align-items-center w-100 mt-2">
      <span>{{'WIDGETS.MOBILITY_MAP.TIMELINE.SPEED' | translate }}</span>
      <mat-slider class="w-100 mr-2 ml-2" [min]="1" [max]="10" [step]="1" [(ngModel)]="sliderTimeserieSpeedValue"
                  (change)="updateTimeserieSpeedValue($event)"></mat-slider>
      <mat-slide-toggle [(ngModel)]="loop">{{'WIDGETS.MOBILITY_MAP.TIMELINE.LOOP' | translate}}</mat-slide-toggle>
      <button mat-mini-fab class="timeline-button ml-3" [disabled]="timeserieLoading" (click)="togglePlay()">
        <mat-icon *ngIf="!playing" class="inverted">play_arrow</mat-icon>
        <mat-icon *ngIf="playing" class="inverted">pause</mat-icon>
      </button>
    </div>
  </app-map-base-control>

  <app-map-base-control [show]="true" class="layers" [titleLabel]="'WIDGETS.MOBILITY_MAP.PARAMS.LEVELS'">
    <mat-slide-toggle [disabled]="!isTypeEnabled('pedestrian')" class="mr-4 mt-2"
                      [(ngModel)]="checkedTypes['pedestrian']"
                      (change)="visibilityChanged('pedestrian')">{{'WIDGETS.MOBILITY_MAP.PARAMS.PEDESTRIAN' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [disabled]="!isTypeEnabled('water')" class="mr-4 mt-2"
                      [(ngModel)]="checkedTypes['water']"
                      (change)="visibilityChanged('water')">{{'WIDGETS.MOBILITY_MAP.PARAMS.WATER' | translate }}</mat-slide-toggle>
    <mat-slide-toggle [disabled]="!isTypeEnabled('road')" class="mr-4 mt-2"
                      [(ngModel)]="checkedTypes['road']"
                      (change)="visibilityChanged('road')">{{'WIDGETS.MOBILITY_MAP.PARAMS.ROAD' | translate }}</mat-slide-toggle>
  </app-map-base-control>

  <app-map-base-control [show]="true" class="categories" [titleLabel]="'WIDGETS.MOBILITY_MAP.PARAMS.TYPES'">
    <ng-select
      appendTo="body"
      class="select"
      [items]="categories"
      [multiple]="true"
      [closeOnSelect]="false"
      [(ngModel)]="categoriesSelection"
      [hideSelected]="false"
      (ngModelChange)="onCategoriesSelectionChange($event)">
      <ng-template ng-header-tmp>
        <button class="btn btn-link"
                (click)="onSelectAllCategories('all')">{{'WIDGETS.MOBILITY_MAP.PARAMS.SELECT_ALL' | translate}}</button>
        <button class="btn btn-link" [disabled]="!isTypeEnabled('pedestrian')"
                (click)="onSelectAllCategories('pedestrian')">{{'WIDGETS.MOBILITY_MAP.PARAMS.SELECT_PEDESTRIAN' | translate}}</button>
        <button class="btn btn-link" [disabled]="!isTypeEnabled('water')"
                (click)="onSelectAllCategories('water')">{{'WIDGETS.MOBILITY_MAP.PARAMS.SELECT_WATER' | translate}}</button>
        <button class="btn btn-link" [disabled]="!isTypeEnabled('road')"
                (click)="onSelectAllCategories('road')">{{'WIDGETS.MOBILITY_MAP.PARAMS.SELECT_ROAD' | translate}}</button>
      </ng-template>
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div class="ng-value {{item.id}}" [ngStyle]="{ 'background-color': item.color }">
          <span class="ng-value-icon left" (click)="clear(item)">×</span>
          <span class="ng-value-label">{{'WIDGETS.MOBILITY_MAP.CATEGORIES.' + item.label | translate }}</span>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <div class="pl-2 pr-2"
             [ngStyle]="{ 'background-color': item.color }"> {{'WIDGETS.MOBILITY_MAP.CATEGORIES.' + item.label | translate }}</div>
      </ng-template>
    </ng-select>
  </app-map-base-control>

  <app-map-tide-control *ngIf="originalTideValue" [show]="true" [loading]="!tideSliderEnabled"
                        [currentTideValue]="originalTideValue" [sliderTideValue]="sliderTideValue"
                        (sliderTideChanged)="sliderTideChanged.emit($event)"
                        (sliderTideOffsetChanged)="sliderTideOffsetChanged.emit($event)"
                        (walkwaysChanged)="walkwaysChanged.emit($event)" [options]="tideControlOptions">
  </app-map-tide-control>

  <app-map-base-control [show]="showGates" class="gates" [titleLabel]="'WIDGETS.MOBILITY_MAP.GATES.TITLE'">
    <div class="d-flex flex-row justify-content-between align-items-center mb-2">
      <mat-form-field class="w-100" *ngIf="gates">
        <mat-select [(value)]="currentGateId" (selectionChange)="setCurrentGate()">
          <mat-option *ngFor="let gate of gates" [value]="gate.id">
            {{gate.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex flex-column mb-2" *ngIf="currentGate">
      <ng-container *ngFor="let location of currentGate.locations">
        <span class="font-weight-bold">{{'WIDGETS.MOBILITY_MAP.GATES.LOCATIONS.' + location.label | translate}}</span>
        <ng-container *ngFor="let category of location.categories">
          <div class="d-flex flex-row justify-content-between align-items-center mb-2">
            <input class="mr-2" type="number" min="0" max="1000" [(ngModel)]="category.valueOverride">
            <span
              class="mr-2">{{'WIDGETS.MOBILITY_MAP.CATEGORIES.' + category.label | translate}} {{'WIDGETS.MOBILITY_MAP.PER_MIN' | translate}}</span>
            <button mat-raised-button class="ml-auto mr-2"
                    (click)="setCurrentGateTPM()">{{'WIDGETS.MOBILITY_MAP.GATES.SET' | translate}}</button>
            <button mat-raised-button
                    (click)="category.valueOverride=null; resetCurrentGateTPM()">{{'WIDGETS.MOBILITY_MAP.GATES.RESET' | translate}}</button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <mat-slide-toggle class="mt-2" [(ngModel)]="viewGates"
                        (change)="setViewGatesNewValue($event)">{{'WIDGETS.MOBILITY_MAP.GATES.TITLE' | translate}}</mat-slide-toggle>
      <button mat-raised-button
              (click)="resetGatesTPM()">{{'WIDGETS.MOBILITY_MAP.GATES.RESET_ALL' | translate}}</button>
    </div>
  </app-map-base-control>
  <div class="control d-flex flex-row justify-content-center align-items-center p-4">
    <button mat-raised-button class="text-uppercase"
            [disabled]="!categoriesSelection || categoriesSelection.length === 0"
            (click)="generateSimulation()">{{'WIDGETS.MOBILITY_MAP.PARAMS.GENERATE_SIMULATOR' | translate}}</button>
    <button mat-raised-button class="ml-3" *ngIf="timeseries" (click)="clearSimulation.emit()">
      <mat-icon class="inverted font-size-x-large">delete_forever</mat-icon>
    </button>
  </div>
</div>
