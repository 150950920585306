import { ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { ARCGIS_LEGEND } from '@app/modules/widgets/arcgis-widget/data/legend';
import { HotSpotControlComponent } from '@app/modules/widgets/arcgis-widget/hotspot-control/hotspot-control.component';
import { ArcgisMapRequest, ArcgisSourceData } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-map-request';
import {
  ArcgisSource,
  FilterFieldValues,
  InfoData,
  InfoEnumData,
} from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { ColorData, EnumData, EnumLabels } from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-source';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { TranslateService } from '@ngx-translate/core';
import { ArcGisMapServerImageryProvider } from 'cesium';
import { FeatureLayer, FeatureLayerOptions } from 'esri-leaflet';
import { FeatureLayer as ClusterFeatureLayer, FeatureLayerOptions as ClusterFeatureLayerOptions } from 'esri-leaflet-cluster';
import { Feature, FeatureCollection } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, GeoJSONOptions, LatLngExpression, Layer, Marker, PathOptions, Popup, Tooltip } from 'leaflet';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import urlRegex from 'url-regex';

@Injectable()
export class ArcgisWidgetService extends MapWidgetService {

  featureChanged: EventEmitter<FeatureEvent> = new EventEmitter<FeatureEvent>();

  getSourceAdditionalData(additionalData: ArcgisMapRequest): ArcgisSourceData | null {
    let sourceAdditionalData: any = null;
    if (additionalData && additionalData.levelInfo.overrideServer) {
      try {
        sourceAdditionalData = additionalData.levelInfo.additionalData ? JSON.parse(additionalData.levelInfo.additionalData.sourceData) : {};
      } catch (e) {
        sourceAdditionalData = null;
      }
    }
    return sourceAdditionalData;
  }

  getIconFontClass(icon: string): string {
    if (icon.includes('fa-')) {
      return `icon ${icon}`;
    } else {
      return `icon icon-venice ${icon}`;
    }
  }

  getIconStyle(icon: string, color: string): string {
    if (icon.includes('fa-')) {
      return `background-color:${color}`;
    } else {
      return `color:${color}`;
    }
  }

  getTemplateLine(languageKey: string, value: string, propertyName: string): string {
    const translation: string = this.translateService.instant(languageKey) === languageKey ? propertyName : this.translateService.instant(languageKey);
    if (urlRegex().test(value)) {
      return `<div class="tooltip-line"><div class="mr-2"><b>${translation}:</b></div><div class="truncate"><a href="${value}" target="_blank">${value || ''}</a></div></div>`;
    } else {
      return `<div class="tooltip-line"><div class="mr-2"><b>${translation}:</b></div><div class="truncate">${value || ''}</div></div>`;
    }
  }

  loadTemplate(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): string {
    const translate: TranslateService = this.translateService;
    let template: string = '';
    const sourceAdditionalData: ArcgisSourceData = this.getSourceAdditionalData(additionalData);
    let sourceData: Array<InfoData> = [];
    if (source.sourceData && source.sourceData.infoData && source.sourceData.infoData.length) {
      sourceData = source.sourceData.infoData;
    }
    if (sourceAdditionalData && sourceAdditionalData.infoData && sourceAdditionalData.infoData.length) {
      sourceData = sourceAdditionalData.infoData;
    }
    for (const [key, value] of Object.entries(feature.properties)) {
      if (sourceData && sourceData.length) {
        const i: InfoData = sourceData.find((d: InfoData) => {
          return key === d.value;
        });
        if (i) {
          const languageKey: string = `SOURCE.${source.name.toUpperCase()}.${i.value}`;
          if (i.type) {
            if (i.type === 'DATE') {
              template = template + `<div class="tooltip-line"><div class="mr-2"><b>${translate.instant(languageKey)}:</b></div> ${moment(value).format(i.format) || ''}</div>`;
            }

            if (i.type === 'ENUM') {
              const enumData: EnumData = source.sourceData.enumFields.find((item: EnumData) => item.field === i.value.toString());
              let enumValue: string = '';
              if (enumData) {
                const property: any = feature.properties[i.value];
                enumValue = enumData.values.find((label: EnumLabels) => label.value === property.toString()).name || property.toString();
              }
              template = template + `<div class="tooltip-line"><div class="mr-2"<b>${translate.instant(languageKey)}:</b></div> ${enumValue || ''}</div>`;
            }
          } else {
            template = template + this.getTemplateLine(languageKey, value as string, i.value);
          }
        }
      } else {
        template = template + this.getTemplateLine(key, value as string, key);
      }
    }

    return template;
  }

  loadTooltipFromData(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): Tooltip {
    const dataTooltip: Tooltip = new Tooltip({
      direction: 'top',
      className: 'arcgis-tooltip',
      offset: [5, -15],
    });

    const template: string = this.loadTemplate(feature, source, additionalData);
    dataTooltip.setContent(template);
    return dataTooltip;
  }

  addPopupToLayer(source: ArcgisSource, additionalData: ArcgisMapRequest, ignoreRenderer: boolean):
    (f: Feature, l: Layer) => void {
    return (f: Feature, l: Layer): void => {
      const sourceAdditionalData: ArcgisSourceData = this.getSourceAdditionalData(additionalData);
      const widgetLevel: string = source.name;
      let sourceData: any = source.sourceData ? source.sourceData : {};
      if (sourceAdditionalData) {
        sourceData = Object.assign(sourceData, sourceAdditionalData);
      }
      if (f.geometry.type !== 'Point' && f.geometry.type !== 'MultiPoint' || !ignoreRenderer) {
        if (source.sourceData && source.sourceData.titleLabel && !source.sourceData.preventTooltip) {
          const label: string = f.properties[source.sourceData.titleLabel];
          if (label && label.trim() !== '') {
            l.bindTooltip(label,
              { permanent: true, direction: 'center', className: `my-labels my-labels--${widgetLevel}` },
            ).openTooltip();
          }
        }
        if (sourceData && !sourceData.preventTooltip || !sourceData) {
          if (sourceData && sourceData.titleLabel) {
            const popup: Popup = new Popup({
              autoClose: false,
              closeOnClick: false,
            });
            const template: string = this.loadTemplate(f, source, additionalData);
            popup.setContent(template);
            l.bindPopup(popup);
          } else {
            const dataTooltip: Tooltip = this.loadTooltipFromData(f, source, additionalData);
            l.bindTooltip(dataTooltip);
          }
        }
      }
      if (sourceData && sourceData.usePopup) {
        const popup: Popup = new Popup({
          autoClose: true,
          closeOnClick: false,
        });
        const template: string = this.loadTemplate(f, source, additionalData);
        popup.setContent(template);
        l.bindPopup(popup);
        l.on('click', () => {
          l.openPopup();
        });
      }
    };
  }

  getEnumValue(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): string {
    let enumValue: string = source.sourceData && source.sourceData.style && source.sourceData.style.color ? source.sourceData.style.color : additionalData.levelInfo.color;
    const sourceAdditionalData: any = this.getSourceAdditionalData(additionalData);
    if (sourceAdditionalData && sourceAdditionalData.style && sourceAdditionalData.style.color && sourceAdditionalData.style.color !== '#ffffff') {
      enumValue = sourceAdditionalData.style.color;
    }
    return enumValue;
  }

  getSourceData(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): any {
    const sourceAdditionalData: any = this.getSourceAdditionalData(additionalData);
    let sourceData: Array<InfoData> = [];
    if (source.sourceData && source.sourceData.infoData && source.sourceData.infoData.length) {
      sourceData = source.sourceData.infoData;
    }
    if (sourceAdditionalData && sourceAdditionalData.infoData && sourceAdditionalData.infoData.length) {
      sourceData = sourceAdditionalData.infoData;
    }
    return sourceData;
  }

  loadColorFromEnum(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): string {
    let enumValue: string = this.getEnumValue(feature, source, additionalData);
    const sourceData: any = this.getSourceData(feature, source, additionalData);
    if (source.sourceData.colorByField) {
      const colorByFieldData: ColorData = source.sourceData.colorByField;
      const property: any = feature.properties[colorByFieldData.field];
      const enumData: InfoEnumData = colorByFieldData.colors.find((item: InfoEnumData) => item.value === property.toString());
      if (enumData && enumData.color) {
        enumValue = enumData.color;
      }
    }
    if (sourceData && sourceData.length) {
      sourceData.forEach((i: InfoData) => {
        const property: any = feature.properties[i.value];
        if (i.type && i.type === 'CAMERA') {
          switch (property) {
            case null:
            case '':
              enumValue = '#FFFF74';
              break;
            case '100':
              enumValue = '#99E600';
              break;
            default:
              enumValue = '#D21933';
              break;
          }
        }
      });
    }
    return enumValue;
  }

  createLevelMarker(feature: Feature, latlng: LatLngExpression, source: ArcgisSource, additionalData: ArcgisMapRequest): Layer {
    const widgetLevel: string = source.name;
    const dataTooltip: Tooltip = this.loadTooltipFromData(feature, source, additionalData);
    const markerColor: string = this.loadColorFromEnum(feature, source, additionalData);
    let icon: string;
    if (source.sourceData && source.sourceData.infoData && !source.sourceData.preventTooltip) {
      icon = source.sourceData.iconFont ? source.sourceData.iconFont : additionalData.levelInfo.icon;
    } else {
      if (additionalData) {
        icon = additionalData.levelInfo.icon;
      } else {
        icon = 'siti-interesse';
      }
    }
    return new Marker(latlng, {
      icon: new DivIcon({
        className: `arcgis-marker arcgis-marker--${widgetLevel}`,
        html: `<div class="marker-pin"><i style="${this.getIconStyle(icon, markerColor)};" class="${this.getIconFontClass(icon)}"></i></div>`,
      }),
    }).bindTooltip(dataTooltip);
  }

  addLevelTooltip(source: ArcgisSource, additionalData: ArcgisMapRequest): (feature: Feature, latlng: LatLngExpression) => Layer {
    return (feature: Feature, latlng: LatLngExpression): Layer => {
      return this.createLevelMarker(feature, latlng, source, additionalData);
    };
  }

  public filterStaticLayer(source: ArcgisSource, additionalData: ArcgisMapRequest):
    (f: Feature) => boolean {
    return (f: Feature): boolean => {
      if (source.sourceData.filterField) {
        let filterString: string = null;
        if (additionalData.filterValue) {
          const filterFieldValue: FilterFieldValues = source.sourceData.filterField.values.find((flt: FilterFieldValues) => {
            return flt.value === additionalData.filterValue;
          });
          filterString = filterFieldValue.name;
          return f.properties[source.sourceData.filterField.field] === filterString;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };
  }

  public levelStyle(source: ArcgisSource, additionalData: ArcgisMapRequest): (feature: any) => PathOptions {
    return (): PathOptions => {
      const sourceAdditionalData: any = this.getSourceAdditionalData(additionalData);
      let color: string = source.sourceData && source.sourceData.style && source.sourceData.style.color
      && source.sourceData.style.color !== '#ffffff' ? source.sourceData.style.color : additionalData.levelInfo.color;
      if (sourceAdditionalData && sourceAdditionalData.style && sourceAdditionalData.style.color && sourceAdditionalData.style.color !== '#ffffff') {
        color = sourceAdditionalData.style.color;
      }
      return { color: color, weight: 2 };
    };
  }

  createClusterIcon(source: ArcgisSource, additionalData: ArcgisMapRequest): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const total: Array<Marker> = cluster.getChildCount();
      const widgetLevel: string = source.name;
      const markers: Array<Marker> = cluster.getAllChildMarkers();
      const markerColor: string = this.loadColorFromEnum(markers[0].feature, source, additionalData);
      const sourceAdditionalData: ArcgisSourceData = this.getSourceAdditionalData(additionalData);
      let icon: string = additionalData.levelInfo.icon;
      if (source.sourceData && source.sourceData.iconFont) {
        icon = source.sourceData.iconFont;
      }
      if (sourceAdditionalData && sourceAdditionalData.iconFont) {
        icon = sourceAdditionalData.iconFont;
      }
      return new DivIcon({
        className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
        html: `<div class="d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column marker-pin">
                    <div style="${this.getIconStyle(icon, markerColor)};" class=\'d-flex flex-column ${this.getIconFontClass(icon)}\'><span class="marker-group__label">${total}</span></div>
                </div>
               </div>`,
      });
    };
  }

  public queryUrl(source: ArcgisSource, additionalData: ArcgisMapRequest): FeatureLayer {
    const sourceAdditionalData: ArcgisSourceData = this.getSourceAdditionalData(additionalData);
    let ignoreRenderer: boolean = true;
    if (sourceAdditionalData && sourceAdditionalData.keepArcgisStyle) {
      ignoreRenderer = false;
    }
    const options: FeatureLayerOptions = {
      url: source.rewriteUrl,
      style: this.levelStyle(source, additionalData),
      onEachFeature: this.addPopupToLayer(source, additionalData, ignoreRenderer),
      pointToLayer: this.addLevelTooltip(source, additionalData),
      ignoreRenderer: ignoreRenderer,
    };
    if (source.sourceData && source.sourceData.minZoom) {
      options.minZoom = source.sourceData.minZoom;
    }
    if (source.sourceData && source.sourceData.maxZoom) {
      options.maxZoom = source.sourceData.maxZoom;
    }
    let layer: FeatureLayer;
    let hasCluster: boolean = false;
    if (source.sourceData) {
      hasCluster = source.sourceData.cluster;
    }
    if (sourceAdditionalData) {
      hasCluster = sourceAdditionalData.cluster;
    }
    if (hasCluster) {
      const clusterOptions: ClusterFeatureLayerOptions = Object.assign(options, {
        iconCreateFunction: ignoreRenderer ? this.createClusterIcon(source, additionalData) : false,
        showCoverageOnHover: false,
        animate: false,
        spiderfy: false,
        spiderfyOnMaxZoom: false,
        zoomToBoundsOnClick: true,
        style: this.levelStyle(source, additionalData),
        maxClusterRadius: 120,
        disableClusteringAtZoom: 22,
      });
      layer = new ClusterFeatureLayer(clusterOptions);
    } else {
      layer = new FeatureLayer(options);
    }
    layer.on('createfeature', (e: any) => {
      const id: string = e.feature.id;
      const feature: Layer = layer.getFeature(id);
      this.featureChanged.emit({
        feature: e.feature,
        featureLayer: feature,
        status: FeatureStatus.CREATED,
        source: source,
        additionalData: additionalData,
      });
    });
    layer.on('addfeature', (e: any) => {
      const id: string = e.feature.id;
      const feature: Layer = layer.getFeature(id);
      this.featureChanged.emit({
        feature: e.feature,
        featureLayer: feature,
        status: FeatureStatus.UPDATED,
        source: source,
        additionalData: additionalData,
      });
    });
    layer.on('removefeature', (e: any) => {
      const id: string = e.feature.id;
      const feature: Layer = layer.getFeature(id);
      this.featureChanged.emit({
        feature: e.feature,
        featureLayer: feature,
        status: FeatureStatus.DELETED,
        source: source,
        additionalData: additionalData,
      });
    });
    return layer;
  }

  parseCesiumArcgisUrl(url: string): CesiumArcGisUrl {
    let u: string = url;
    if (u.lastIndexOf('/') + 1 === u.length) {
      u = u.substring(0, u.length - 1);
    }
    const outputUrl: string = u.substring(0, u.lastIndexOf('/') + 1);
    const showLayer: string = u.substring(u.lastIndexOf('/') + 1);
    return {
      url: outputUrl,
      layer: showLayer,
    };
  }

  getSingleCesiumLevel(timeMachineData: TimeMachineData,
                       source: ArcgisSource, additionalData: ArcgisMapRequest): Observable<ArcGisMapServerImageryProvider> {

    source.rewriteUrl = this.addTimeMachineDataToUrl(timeMachineData, source);
    const parsedUrl: CesiumArcGisUrl = this.parseCesiumArcgisUrl(source.rewriteUrl);
    const arcgisProvider: ArcGisMapServerImageryProvider = new ArcGisMapServerImageryProvider({
      url: parsedUrl.url,
      layers: parsedUrl.layer,
      credit: 'fabbricadigitale',
    });
    arcgisProvider.defaultAlpha = 0.5;
    return of(arcgisProvider);
  }


  getSingleMapLevel(timeMachineData: TimeMachineData,
                    source: ArcgisSource, additionalData: ArcgisMapRequest): Observable<Layer> {

    source.rewriteUrl = this.addTimeMachineDataToUrl(timeMachineData, source);

    if (source.sourceData && source.sourceData.static) {
      return this.http.get<FeatureCollection>(source.rewriteUrl).pipe(
        map((layer: FeatureCollection) => {
          const options: GeoJSONOptions = {
            style: this.levelStyle(source, additionalData),
            onEachFeature: this.addPopupToLayer(source, additionalData, true),
            pointToLayer: this.addLevelTooltip(source, additionalData),
            filter: this.filterStaticLayer(source, additionalData),
          };
          return geoJSON(layer, options);
        }),
      );
    } else {
      let layer: FeatureLayer;
      layer = this.queryUrl(source, additionalData);
      return of(layer);
    }
  }

  getCesiumLevel(timeMachineData: TimeMachineData,
                 sources: Array<ArcgisSource>, additionalData: ArcgisMapRequest): Observable<Array<ArcGisMapServerImageryProvider>> {
    const observables: Array<Observable<ArcGisMapServerImageryProvider>> = sources.filter((source: ArcgisSource) => {
      return source.sourceType === 'arcgis';
    }).map((source: ArcgisSource) => {
      const sourceConfiguration: any = JSON.parse(source.configuration);
      if (sourceConfiguration) {
        source.sourceData = sourceConfiguration.sourceData;
      }
      return this.getSingleCesiumLevel(timeMachineData, source, additionalData);
    });
    return forkJoin(observables);
  }

  getMapLevel(timeMachineData: TimeMachineData,
              sources: Array<ArcgisSource>, additionalData: ArcgisMapRequest): Observable<FeatureGroup> {
    const mapLevel: FeatureGroup = new FeatureGroup();
    const observables: Array<Observable<Layer>> = sources.filter((source: ArcgisSource) => {
      return source.sourceType === 'arcgis';
    }).map((source: ArcgisSource) => {
      const sourceConfiguration: any = JSON.parse(source.configuration);
      if (sourceConfiguration) {
        source.sourceData = sourceConfiguration.sourceData;
      }
      return this.getSingleMapLevel(timeMachineData, source, additionalData);
    });
    return forkJoin(observables).pipe(
      map((features: Array<FeatureGroup>) => {
        features.forEach((f: FeatureGroup) => {
          f.addTo(mapLevel);
        });
        return mapLevel;
      }),
    );
  }

  public getControl(container: ViewContainerRef,
                    factoryResolver: ComponentFactoryResolver): ComponentRef<HotSpotControlComponent | any> {
    const factory: ComponentFactory<HotSpotControlComponent> = factoryResolver.resolveComponentFactory(HotSpotControlComponent);
    return container.createComponent(factory);
  }

  public checkSource(source: ArcgisSource): Observable<any> {
    const url: string = this.addTimeMachineDataToUrl(moment().valueOf(), source);
    if (source.sourceData && source.sourceData.static) {
      return this.http.get<any>(url);
    } else {
      return this.http.get<any>(url + '?f=pjson');
    }
  }

  public getLegend(sources: Array<ArcgisSource>, widgetRequestData: ArcgisMapRequest): MapLegend {
    let legend: MapLegend;
    const additionalSourceData: ArcgisSourceData = this.getSourceAdditionalData(widgetRequestData);
    try {
      if (additionalSourceData && additionalSourceData.legend && additionalSourceData.legend !== '') {
        return JSON.parse(additionalSourceData.legend);
      } else {
        sources.forEach((s: ArcgisSource) => {
          if (s.sourceData && s.sourceData.legend) {
            legend = ARCGIS_LEGEND[s.sourceData.legend];
          }
        });
      }
    } catch {
      return legend;
    }
    return legend;
  }
}

export interface FeatureEvent {
  feature: Feature;
  featureLayer: Layer;
  source: ArcgisSource;
  status: FeatureStatus;
  additionalData: ArcgisMapRequest;
}

export enum FeatureStatus {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
}

export interface CesiumArcGisUrl {
  url: string;
  layer: string;
}
