import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { SubMenuChangeEvent, SubMenuSelectAllEvent } from '@app/features/pages/map-page/map-control-sub-bar/models';
import { DataLoad, MapPageService } from '@app/features/pages/map-page/map-page.service';
import { MapStaticSubMenu } from '@app/features/pages/map-page/models/map-static-sub-menu';
import { WidgetType } from '@app/shared/enums/widget-type';
import { orderBy } from 'lodash';
import { MapControlBarButton, MapSubMenuElement } from '../models';

@Component({
  selector: 'app-map-control-sub-bar',
  templateUrl: './map-control-sub-bar.component.html',
  styleUrls: ['./map-control-sub-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapControlSubBarComponent implements OnInit, OnChanges {

  private selectAll: boolean = false;
  selectedRoutes: any;

  panelExpanded: boolean;


  @Input()
  field: MapControlBarButton;

  @Input()
  staticSubMenu: MapStaticSubMenu<unknown>;

  @Output()
  subMenuChanged: EventEmitter<SubMenuChangeEvent> = new EventEmitter<SubMenuChangeEvent>();

  @Output()
  subMenuSelectAll: EventEmitter<SubMenuSelectAllEvent> = new EventEmitter<SubMenuSelectAllEvent>();

  @Output()
  toggledPanel: EventEmitter<boolean> = new EventEmitter<boolean>();

  subMenuType: WidgetType;

  subMenuData: unknown;

  constructor(private mapPageService: MapPageService) {
  }

  ngOnInit(): void {
    this.panelExpanded = true;
    if (this.field) {
      this.field.submenu = orderBy(this.field.submenu, ['id'], ['asc']);
    }
    this.mapPageService.subMenuDataLoaded.subscribe((data: DataLoad) => {
      if (data.type === WidgetType.INFRASTRUCTURES_WIDGET) {
        this.subMenuType = data.type;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.field) {
      this.field = changes.field.currentValue;
      this.field.submenu = orderBy(this.field.submenu, ['id'], ['asc']);
      this.selectedRoutes = this.field.submenu.filter((value: MapSubMenuElement) => {
        return value.selected;
      });
    }
    if (changes.staticSubMenu && !changes.staticSubMenu.isFirstChange()) {
      this.staticSubMenu = changes.staticSubMenu.currentValue;
    }
  }

  onClearAll(field: MapControlBarButton): void {
    this.selectAll = false;
    field.submenu.forEach((element: MapSubMenuElement) => {
      element.selected = this.selectAll;
    });
    this.subMenuSelectAll.emit({
      value: this.selectAll,
      field: field,
    });
  }

  onSelectAll(field: MapControlBarButton): void {
    this.selectAll = !this.selectAll;
    field.submenu.forEach((element: MapSubMenuElement) => {
      element.selected = this.selectAll;
    });
    this.subMenuSelectAll.emit({
      value: this.selectAll,
      field: field,
    });
  }

  onSubmenuChange(event: object, status: boolean, field: MapControlBarButton): void {
    const submenu: MapSubMenuElement = event as MapSubMenuElement;
    submenu.selected = status;
    this.field.submenu.forEach((element: MapSubMenuElement) => {
      this.selectAll = this.selectAll && element.selected;
    });
    this.subMenuChanged.emit({ status: status, field: field, submenu: submenu });
  }

  panelOpened(open: boolean): void {
    this.panelExpanded = open;
    this.toggledPanel.emit(this.panelExpanded);
  }

  extractGroupValue(item: any, children: Array<any>): any {
    return children.filter((g: any) => {
      return g.group === item;
    }).pop();
  }

}
