<app-widget class="mindicity-widget" [sources]="sources" [title]="title" [description]="description"
            (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable" [isLoading]="isLoading" (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()" [timeMachineOn]="timeMachineOn"
            [noData]="noData">
  <div class="container">
    <ul class="list-group w-100 mt-2" *ngIf="events">
      <li *ngFor="let event of events" class="text-left">
        <div class="row mb-2 event" [ngClass]="{'blink-border': checkNewAlert(event) }" *ngIf="!event.read">
          <div class="col p-2 infoContainer" [ngClass]="'alert--' + event.typeId">
            <div class="eventDescr mb-2" (click)="centerOnMap(event)"
                 [ngClass]="{'pointer': event.position || event.shape }">
              <div class="font-weight-bold">
                <i class="alert-data__waiting icon-venice icon-venice_alert"
                   [ngClass]="{'icon-venice_alert': event.priority > 6, 'icon-venice_marea-allarme': event.priority >= 4 && event.priority <= 6,'red': event.priority > 6, 'orange': event.priority >= 4 && event.priority <= 6  }"></i>
                {{event.name}}</div>
              <div>{{event.createdAt | date: 'dd/MM/yyyy HH:mm'}}</div>
            </div>
            <div class="eventDescr mb-2">
              <div>{{event.description}}</div>
            </div>
            <div class="d-flex flex-row">
              <div class="eventStatus d-flex flex-row justify-content-start align-items-center">
                <i *ngIf="event.recipient" class="icon-venice icon-venice_presenze"></i>
                <span *ngIf="event.users === 1">{{'PAGES.ALERTS.ONLY_YOU' | translate}}</span>
                <span *ngIf="event.labels && event.labels.length>0"
                      class="alert-data__state badge badge-primary">{{event.labels[0] | translate | titlecase}}</span>
              </div>
              <div class="eventStatusRead d-flex justify-content-end align-items-center">
                <button mat-button [color]="'accent'"
                        (click)="onAlertClick(event)"><span
                  class="alert-button">{{'PAGES.ALERTS.MARK_AS_READ' | translate}}</span></button>
              </div>
            </div>

          </div>
        </div>
      </li>
    </ul>
  </div>
</app-widget>
