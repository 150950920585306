import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { PublicLightsService } from '@app/modules/widgets/public-lights-widget/public-lights.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { PublicLightsWidgetComponent } from './public-lights-widget.component';
import { PublicLightsControlComponent } from './public-lights-control/public-lights-control.component';

@NgModule({
  declarations: [PublicLightsWidgetComponent, PublicLightsControlComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
  ],
  providers: [PublicLightsService],
  exports: [PublicLightsWidgetComponent],
})
export class PublicLightsWidgetModule {

}
