import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetModule } from '../../widget/widget.module';
import { RoadFlowDateWidgetComponent } from './road-flow-date-widget.component';
import { RoadFlowDateService } from './road-flow-date.service';

@NgModule({
  declarations: [RoadFlowDateWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule
  ], providers: [RoadFlowDateService],
  exports: [RoadFlowDateWidgetComponent],
})
export class RoadFlowDateWidgetModule {
}
