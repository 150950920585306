<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description"
            (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus" [noData]="noData">
  <div class="behavioral d-flex flex-column justify-content-start align-items-stretch">
    <div class="behavioral__title">
      <strong><span *ngFor="let activeZoneId of activeZoneIds, let last = last">
        {{activeZoneId}}
        <ng-container *ngIf="!last">, </ng-container>
      </span></strong>
    </div>
    <div class="note">
      <i class="note__text" *ngIf="!timeMachineService.rangeOn">{{'WIDGETS.TRAFFIC.DEFAULT_DATA' | translate }}</i>
    </div>

    <!-- Table for Water Flows -->
    <div class="table" *ngIf="data.type==='water' && tableDataSource">
      <table mat-table matSort [dataSource]="tableDataSource" (matSortChange)="onSortActivate($event)">
        <!-- Color Column -->
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="round" [style.backgroundColor]="row.color">

            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>{{'WIDGETS.TRAFFIC.WATER_LABELS.TYPE' | translate }}</th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.type}} </td>
          <td mat-footer-cell (click)="selectData(null)"
              *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' | translate }} </td>
        </ng-container>

        <!-- Speed Avg Column -->
        <ng-container matColumnDef="speed_avg">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>  {{'WIDGETS.TRAFFIC.COMMON_LABELS.SPEED_AVG' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.speed_avg}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef></td>
        </ng-container>

        <!-- Speed Max Column -->
        <ng-container matColumnDef="speed_max">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.SPEED_MAX' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.speed_max}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef></td>
        </ng-container>

        <!-- Reverse Column -->
        <ng-container matColumnDef="reverse">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.REVERSE' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.reverse}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal('reverse')}} </td>
        </ng-container>

        <!-- Crossing Column -->
        <ng-container matColumnDef="crossing">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>  {{'WIDGETS.TRAFFIC.COMMON_LABELS.CROSSING' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.crossing}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal('crossing')}} </td>
        </ng-container>

        <!-- Wrong Direction Column -->
        <ng-container matColumnDef="wrong_direction">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.WRONG_DIRECTION' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.wrong_direction}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal('wrong_direction')}} </td>
        </ng-container>

        <!-- Prohibited Stops Column -->
        <ng-container matColumnDef="prohibited_stops">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.PROHIBITED_STOPS' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.prohibited_stops}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal('prohibited_stops')}} </td>
        </ng-container>

        <!-- Anomalies Column -->
        <ng-container matColumnDef="anomalies">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.ANOMALIES' | translate }} </th>
          <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.anomalies}} </td>
          <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal('anomalies')}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="waterTableColumns"></tr>
        <tr mat-row [ngClass]="{'selected': (selectedEntry && selectedEntry.type == row.type)}"
            *matRowDef="let row; columns:waterTableColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="waterTableColumns"></tr>
      </table>
    </div>

    <!-- Table for Road Flows -->
    <div class="table" *ngIf="data.type==='road'">
      <table mat-table matSort [dataSource]="tableDataSource">
        <!-- Color Column -->
        <ng-container matColumnDef="color">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <div class="round" [style.backgroundColor]="row.color">

            </div>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.TRAFFIC.ROAD_LABELS.TYPE' | translate }}</th>
          <td mat-cell *matCellDef="let row"> {{row.type}} </td>
          <td mat-footer-cell *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' | translate }} </td>
        </ng-container>

        <!-- Speed Avg Column -->
        <ng-container matColumnDef="speed_avg">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>  {{'WIDGETS.TRAFFIC.COMMON_LABELS.SPEED_AVG' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.speed_avg}} </td>
          <td mat-footer-cell *matFooterCellDef>{{getAvg('speed_avg')}} </td>
        </ng-container>

        <!-- Speed Max Column -->
        <ng-container matColumnDef="speed_max">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header> {{'WIDGETS.TRAFFIC.COMMON_LABELS.SPEED_MAX' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.speed_max}} </td>
          <td mat-footer-cell *matFooterCellDef>{{getMax('speed_max')}} </td>
        </ng-container>

        <!-- Prohibited Stops Column -->
        <ng-container matColumnDef="prohibited_stops">
          <th mat-header-cell *matHeaderCellDef
              mat-sort-header>  {{'WIDGETS.TRAFFIC.COMMON_LABELS.PROHIBITED_STOPS' | translate }} </th>
          <td mat-cell *matCellDef="let row"> {{row.prohibited_stops}} </td>
          <td mat-footer-cell *matFooterCellDef>{{getTotal('prohibited_stops')}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="roadTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns:roadTableColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="roadTableColumns"></tr>
      </table>
    </div>
  </div>
</app-widget>
