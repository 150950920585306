import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { HighwayTrafficWidgetComponent } from './highway-traffic-widget.component';
import { HighwayTrafficService } from '@app/modules/widgets/highway-traffic-widget/highway-traffic-widget.service';

@NgModule({
  declarations: [HighwayTrafficWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule
  ],
  providers: [HighwayTrafficService,
    { provide: 'highway_traffic_widget_service', useClass: HighwayTrafficService }],
  exports: [HighwayTrafficWidgetComponent],
})
export class HighwayTrafficWidgetModule {
}
