import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MapControlBarButton } from '@app/features/pages/map-page/models';
import { AddressResponse } from '@app/modules/widgets/registry-widget';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-registry-search',
  templateUrl: './registry-search.component.html',
  styleUrls: ['./registry-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrySearchComponent implements OnChanges {
  searchValue: string;
  registrySearchControl: FormControl = new FormControl();

  @Input()
  data: Array<MapControlBarButton>;

  @Input()
  selectLevel: boolean;

  @Output()
  searchSubmit: EventEmitter<RegistrySearchQuery> = new EventEmitter<RegistrySearchQuery>();

  @Output()
  searchUpdate: EventEmitter<RegistrySearchQuery> = new EventEmitter<RegistrySearchQuery>();

  @Output()
  clear: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  clearAll: EventEmitter<MapControlBarButton> = new EventEmitter<MapControlBarButton>();

  @Input()
  options: Array<AddressResponse> = [];

  searchOption: number;

  @Input()
  showActiveLayers: boolean;

  constructor(private titleCasePipe: TitleCasePipe) {
    this.searchOption = 0;
  }

  private _normalizeValue(value: AddressResponse): AddressResponse {
    return {
      roadAddress: value.roadAddress.toLowerCase().replace(/\s/g, ''),
      sestiereAddress: value.sestiereAddress.toLowerCase().replace(/\s/g, ''),
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.options = orderBy(changes.options.currentValue, ['sestiereAddress', 'roadAddress']);
    }
  }

  submit(): void {
    this.searchSubmit.emit({ type: this.searchOption, argument: this.searchValue, exactly: false });
  }

  onAutocompileSelect(): void {
    this.searchValue = this.titleCasePipe.transform(this.searchValue);
    this.searchSubmit.emit({ type: this.searchOption, argument: this.searchValue, exactly: true });
  }

  displayFn(address: string): string {
    return address ? address : '';
  }

  clearSearch(): void {
    this.searchValue = null;
    this.clear.emit();
  }

  clearMap(value: MapControlBarButton): void {
    this.clearAll.emit(value);
  }

  updateSearchOption(newModel: string): void {
    this.searchValue = newModel;
    this.updateAutocomplete();
  }

  updateSearchType(newModel: number): void {
    this.searchOption = newModel;
    this.updateAutocomplete();
  }

  updateAutocomplete(): void {
    if (this.searchValue && this.searchValue.length >= 3) {
      this.options = [];
      this.searchUpdate.emit({ type: this.searchOption, argument: this.searchValue, exactly: false });
    } else {
      this.options = [];
    }
  }
}

export interface RegistrySearchQuery {
  type: number;
  argument: string;
  exactly: boolean;
}
