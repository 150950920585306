import { Component, Injector } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { PresencesClusterService } from '@app/modules/widgets/presences-cluster-widget/presences-cluster.service';

@Component({
  selector: 'app-presences-cluster-widget',
  templateUrl: './presences-cluster-widget.component.html',
  styleUrls: ['./presences-cluster-widget.component.scss']
})
export class PresencesClusterWidgetComponent extends MapWidgetComponent {

  constructor(public widgetService: PresencesClusterService, public injector: Injector) {
    super(widgetService, injector);
  }


}
