import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { WindService } from '@app/modules/widgets/wind-widget/wind.service';

@Component({
  selector: 'app-wind-widget',
  templateUrl: './wind-widget.component.html',
  styleUrls: ['./wind-widget.component.scss'],
})
export class WindWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  constructor(public widgetService: WindService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.legends = [this.widgetService.getLegend()];
    super.ngOnInit();
  }
}
