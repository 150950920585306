import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CulturalEventsService } from '@app/modules/widgets/cultural-events-widget/cultural-events.service';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { CulturalEventsWidgetComponent } from './cultural-events-widget.component';


@NgModule({
  declarations: [CulturalEventsWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule,
  ],
  providers: [CulturalEventsService],
  exports: [CulturalEventsWidgetComponent]
})
export class CulturalEventsWidgetModule {
}
