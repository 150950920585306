import { Component, Injector, OnInit } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MessagePanelsService } from '@app/modules/widgets/message-panels-widget/message-panels.service';

@Component({
  selector: 'app-message-panels-widget',
  templateUrl: './message-panels-widget.component.html'
})
export class MessagePanelsWidgetComponent extends MapWidgetComponent implements OnInit {

  constructor(public widgetService: MessagePanelsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  // loadWidget(timeMachineData: TimeMachineData): void {
  //   this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources, null).subscribe((f: FeatureGroup) => {
  //     this.map.addLayer(f);
  //   });
  // }
}
