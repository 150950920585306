<ng-template #roleList>
  <span class="badge badge-primary m-1" *ngFor="let role of userData.roles">{{role}}</span>
</ng-template>
<ng-template #privilegeList>
  <span class="badge badge-primary m-1" *ngFor="let privilege of userData.privileges">{{privilege}}</span>
</ng-template>

<div class="d-flex flex-column justify-content-between align-items-stretch">
  <div class="info d-flex flex-column justify-content-between align-items-stretch pb-4" *ngIf="userData">
    <h5 class="pb-2">{{'MENU.INFO' | translate | uppercase}}</h5>
    <div class="pb-1">
      <strong class="desc">{{'MENU.USERNAME' | translate}}:</strong>
      <span>{{userData.sub}}</span>
    </div>
    <div class="pb-1">
      <strong class="desc">{{'MENU.NAME' | translate}}:</strong>
      <span>{{userFirstname}}</span>
    </div>
    <div class="pb-1">
      <strong class="desc">{{'MENU.SURNAME' | translate}}:</strong>
      <span>{{userSurname}}</span>
    </div>
    <div class="pb-1" *ngIf="userData.roles">
      <div class="desc">{{'MENU.ROLES' | translate}}:</div>
      <ng-container *ngIf="!isString(userData.roles)">
        <span class="cursor-pointer badge badge-primary m-1" *ngIf="!viewRole"
              [ngbPopover]="roleList">{{userData.roles.length}} </span>
        <span *ngIf="viewRole"><span class="badge badge-primary m-1" *ngFor="let role of userData.roles">{{role}}</span></span>
      </ng-container>
      <ng-container *ngIf="isString(userData.roles)">
        <span class="badge badge-primary m-1">{{userData.roles}}</span>
      </ng-container>
    </div>
    <div class="pb-1" *ngIf="userData.privileges">
      <div class="desc">{{'MENU.PRIVILEGES' | translate}}:</div>
      <ng-container *ngIf="!isString(userData.privileges)">
        <span class="cursor-pointer badge badge-primary m-1" *ngIf="!viewPrivilege"
              [ngbPopover]="privilegeList">{{userData.privileges.length}} </span>
        <span *ngIf="viewPrivilege"><span class="badge badge-primary m-1"
                                          *ngFor="let privilege of userData.privileges">{{privilege}}</span></span>
      </ng-container>
      <ng-container *ngIf="isString(userData.privileges)">
        <span class="badge badge-primary m-1">{{userData.privileges}}</span>
      </ng-container>
    </div>
    <div class="pb-1" [hidden]="!savedDashboard">
      <strong class="dashboard">{{'MENU.ACTIVE_DASHBOARD' | translate}}:</strong><br>
      <span>{{savedDashboard}}</span>
    </div>
  </div>
  <div class="settings d-flex flex-column justify-content-between align-items-stretch">
    <h5 class="pb-2">{{'MENU.SETTINGS' | translate | uppercase}}</h5>
    <div class="btn-group-vertical mt-1" [hidden]="hideDashboardSettings">
      <button (click)="openManager()" [hidden]="!isAdmin"
              class="btn btn-block btn-outline-primary">{{'MENU.MANAGER' | translate}}</button>
      <button (click)="addWidget()" class="btn btn-block btn-outline-primary"
              [disabled]="!isGridEditable">{{'MENU.ADD_WIDGET' | translate}}</button>
      <button (click)="toggleEditWidgets()" class="btn btn-block btn-outline-primary"
              [className]="isGridEditable ? 'btn btn-warning  mr-1 d-inline' : 'btn btn-outline-primary mr-1 d-inline'">
        <span [hidden]="!isGridEditable">{{'MENU.LOCK_GRID' | translate}}</span>
        <span [hidden]="isGridEditable">{{'MENU.UNLOCK_GRID' | translate}}</span>
      </button>
      <button (click)="saveGrid()"
              class="btn btn-block btn-outline-primary"
              [disabled]="isGridEditable">{{'MENU.SAVE_GRID' | translate}}</button>
      <button (click)="loadGrid()" class="btn btn-block btn-outline-primary"
              [disabled]="isGridEditable">{{'MENU.LOAD_GRID' | translate}}</button>
      <button (click)="removeGrid()" class="btn btn-block btn-outline-primary"
              [disabled]="isGridEditable">{{'MENU.REMOVE_GRID' | translate}}</button>
      <button (click)="clearGrid()" class="btn btn-block btn-outline-primary"
              [disabled]="isGridEditable">{{'MENU.CLEAR_GRID' | translate}}</button>
      <button (click)="toggleDark()" class="btn btn-block btn-outline-primary"
              [hidden]="!canSeeDarkTheme"
              [ngClass]="{'active': themeService.isDark}">{{'MENU.DARK_THEME' | translate}}</button>
    </div>
    <div class="btn-group-vertical mt-1">
      <button (click)="editGlobalData()" class="btn btn-block btn-outline-secondary"
              [hidden]="!isThresholdAdmin"
              [disabled]="isGridEditable">{{'MENU.EDIT_GLOBAL_DATA' | translate}}</button>
    </div>
    <div class="btn-group-vertical" *ngIf="docs">
      <a [href]="docs" target="_blank" class="btn btn-outline-success btn-block mt-1">{{'MENU.DOCS' | translate}}</a>
    </div>
    <div class="btn-group-vertical">
      <button (click)="logout()" class="btn btn-outline-warning btn-block mt-1">{{'MENU.LOGOUT' | translate}}</button>
    </div>
  </div>
</div>
