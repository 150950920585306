import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WidgetEditableData } from '@app/modules/dashboard/dashboard.component';
import { AirQualityWidgetService } from '@app/modules/widgets/air-quality-widget/air-quality-widget.service';
import { HeartBeatWidgetService } from '@app/modules/widgets/heart-beat-widget/heart-beat-widget.service';
import { TemperatureWidgetService } from '@app/modules/widgets/temperature-widget/temperature-widget.service';
import { TideService } from '@app/modules/widgets/tide-widget/tide.service';
import { ButtonsWidgetComponent } from '@app/shared/components/modals/buttons-widget/buttons-widget.component';
import { WidgetType } from '@app/shared/enums/widget-type';
import { AngularJsonSchemaForm, AngularJsonSchemaFormError } from '@app/shared/models/angular-json-schema-form';
import { DashboardItem } from '@app/shared/models/dashboard/dashboard-item';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/loader/loader.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-edit-data-modal',
  templateUrl: './edit-data-modal.component.html',
  styleUrls: ['./edit-data-modal.component.scss']
})
export class EditDataModalComponent implements OnInit {

  schema: AngularJsonSchemaForm;
  widgets: any;
  title: string;
  formValid: boolean;
  errorMessage: Array<AngularJsonSchemaFormError>;

  constructor(private temperatureWidgetService: TemperatureWidgetService,
              private tideWidgetService: TideService,
              private translateService: TranslateService,
              private airQualityWidgetService: AirQualityWidgetService,
              private heartBeatWidgetService: HeartBeatWidgetService,
              private loaderService: LoaderService,
              public dialogRef: MatDialogRef<EditDataModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DashboardItem) {
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.widgets = {
      submit: ButtonsWidgetComponent,
    };
    this.formValid = true;
    this.title = this.translateService.instant('PAGES.DASHBOARD.EDIT_WIDGET_DATA');
    let schemaObservable: Observable<AngularJsonSchemaForm>;
    if (this.data.type === WidgetType.TEMPERATURES_WIDGET) {
      schemaObservable = this.temperatureWidgetService.getDataSchema(this.data.name);
    }
    if (this.data.type === WidgetType.TIDE_WIDGET) {
      schemaObservable = this.tideWidgetService.getDataSchema(this.data.name);
    }
    if (this.data.type === WidgetType.AIR_QUALITY_WIDGET) {
      schemaObservable = this.airQualityWidgetService.getDataSchema(this.data.name);
    }
    if (this.data.type === WidgetType.HEART_BEAT_WIDGET) {
      schemaObservable = this.heartBeatWidgetService.getDataSchema(this.data.name);
    }
    if (this.data.type === WidgetType.MESSAGE_PANELS_WIDGET) {
      schemaObservable = of(JSON.parse(this.data.configuration) as AngularJsonSchemaForm);
      this.title = this.translateService.instant('PAGES.DASHBOARD.EDIT_PANELS_DATA');
    }
    if (schemaObservable) {
      schemaObservable.subscribe((schema: AngularJsonSchemaForm) => {
        this.loaderService.hide();
        this.schema = schema;
      });
    } else {
      this.loaderService.hide();
    }
  }

  submit(data: any): void {
    if (this.formValid) {
      if (this.data.type === WidgetType.MESSAGE_PANELS_WIDGET) {
        this.dialogRef.close({ info: data, id: this.data.name });
      } else {
        const saved: Array<WidgetEditableData> = [];
        const listPropertyNames: Array<string> = Object.keys(data);
        listPropertyNames.forEach((item: string) => {
          const nameP: string = item.replace('low_', '').replace('high_', '');
          const p: WidgetEditableData = saved.find((x: WidgetEditableData) => x.name === nameP);

          if (!p) {
            const xp: WidgetEditableData = { name: nameP, low: data['low_' + nameP], high: data['high_' + nameP] };
            saved.push(xp);
          }
        });
        this.dialogRef.close(saved);
      }
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  isValid(data: boolean): void {
    this.formValid = data;
  }

  validationErrors(data: Array<AngularJsonSchemaFormError>): void {
    if (data) {
      data.forEach((msg: AngularJsonSchemaFormError) => {
        if (msg.dataPath.indexOf('/') >= 0) {
          const splitted: Array<string> = msg.dataPath.split('/');
          let newMsg: string = splitted[1];
          if (splitted.length > 2) {
            newMsg += '[' + splitted[2] + ']';
          }
          msg.dataPath = newMsg;
        }
      });
      this.errorMessage = data;
    }
  }
}
