import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageDemoService } from '@app/modules/widgets/image-demo-widget/image-demo.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { ImageDemoWidgetComponent } from './image-demo-widget.component';

@NgModule({
  declarations: [ImageDemoWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MatCarouselModule,
  ],
  exports: [ImageDemoWidgetComponent],
  providers: [ImageDemoService],
})
export class ImageDemoWidgetModule {
}
