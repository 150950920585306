import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-road-maintenance-widget',
  templateUrl: './road-maintenance-widget.component.html',
  styleUrls: ['./road-maintenance-widget.component.scss'],
})
export class RoadMaintenanceWidgetComponent extends WidgetComponent {


  events: Array<any> = [
    {
      title: 'Manto Stradale',
      km: '201 I',
      location: 'Peschiera - Sommacampagna',
      time: {
        start: '14:00',
        duration: '01:35 min',
      },
      type: 'brd-danger',
      status: {
        info: '2-1 Corsia chiusa',
        type: 'success',
      },
    },
    {
      title: 'Manutenzione Colonnine',
      km: '207 I',
      location: 'Vicenza',
      time: {
        start: '12:00',
        duration: '51:25 min',
      },
      type: 'brd-info',
      status: {
        info: '1',
        type: 'success',
      },
    },
    {
      title: 'Sostituzione Guard Rail',
      km: '201 I',
      location: 'Peschiera - Sommacampagna',
      time: {
        start: '15:00',
        duration: '15:05 min',
      },
      type: 'brd-info',
      status: {
        info: '2-1',
        type: 'success',
      },
    },

  ];
}
