<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            [isEditable]="isEditable" [isLoading]="isLoading"
            [noData]="noData">

  <div class="indices d-flex flex-row justify-content-around align-items-stretch"
       *ngIf="!noData"
       [ngStyle]="{'font-size.px': fontSize}" (resize)="handleResize($event)">

    <div class="indexes" [hidden]="!index">
      <div class="index__value d-flex justify-content-center align-items-center"
           [ngStyle]="{'border-color': getCircleColor(index)}">
        <span>{{index | number:'1.1-1'}}</span>
      </div>
      <div class="index__label text-left pl-3" [hidden]="!showLabel">{{label}}</div>
    </div>

    <div class="indexes" [hidden]="index">
      <div class="index__value d-flex justify-content-center align-items-center"
           [ngStyle]="{'border-color': '#808080'}">
        <span>N.D</span>
      </div>

    </div>
  </div>

</app-widget>
