<h1 mat-dialog-title>{{'PAGES.DASHBOARD.REMOVE_WIDGET_CONFIRM' | translate}}</h1>
<div mat-dialog-content class="pb-3">
  <p>{{'PAGES.DASHBOARD.REMOVE_WIDGET_MESSAGE' | translate:data}}</p>
  <br/>
  <br/>
  <p>{{'PAGES.DASHBOARD.REMOVE_WIDGET_NOTE' | translate}}</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button [mat-dialog-close]="false">{{'PAGES.DASHBOARD.CANCEL' | translate}}</button>
  <button mat-raised-button [color]="'warn'" [mat-dialog-close]="true" cdkFocusInitial>{{'PAGES.DASHBOARD.REMOVE' | translate}}</button>
</div>
