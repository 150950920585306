<ngx-grid-stack #gridStackMain id="gridStackMain" class="grid-stack" *ngIf="widgets" (resized)="handleResize($event)"
                [ngClass]="{'grid-stack--editable': isGridEditable, 'grid-stack--scrollable': isScrollable}"
                [options]="options">
  <div class="full-screen d-flex flex-column justify-content-center align-items-center"
       *ngIf="!widgets.length && !(this.loaderService.isLoading | async)">
    <img src="assets/logo/mindicity.jpg" />
    <h2>{{'PAGES.DASHBOARD.EMPTY' | translate}}</h2>
  </div>
  <ng-container *ngFor="let widget of widgets; let i = index">
    <ngx-grid-stack-item id="widget-{{widget.type}}" [option]="widget" [hidden]="isFullscreen && !widget.fullscreen"
                         [ngClass]="{'fullScreen': widget.fullscreen}" class="grid-stack-item">
      <app-widget class="mindicity-widget" *ngIf="widget.type=='widget'" [title]="widget.title"
                  [isEditable]="isGridEditable"
                  [ngClass]="{'full': widget.data && widget.data.fullView}"
                  [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-widget>
      <app-tide-widget *ngIf="widget.type=='tide_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                       (editData)="editData(i)" (fullScreen)="toggleFullscreen($event,i)" [data]="widget.data"
                       [name]="widget.name"
                       [ngClass]="{'full': widget.data && widget.data.fullView}"
                       (removeWidget)="removeWidget(i)"></app-tide-widget>
      <app-map-widget *ngIf="widget.type=='map_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                      [ngClass]="{'full': widget.data && widget.data.fullView}"
                      [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-map-widget>
      <app-arrivals-widget *ngIf="widget.type=='arrivals_widget'" [title]="widget.title" [name]="widget.name"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                           (removeWidget)="removeWidget(i)">
      </app-arrivals-widget>
      <app-iframe-widget *ngIf="widget.type=='iframe_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                         [ngClass]="{'full': widget.data && widget.data.fullView}"
                         (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                         (removeWidget)="removeWidget(i)">
      </app-iframe-widget>
      <app-tide-forecast-widget *ngIf="widget.type=='tide_forecast_widget'" [title]="widget.title" [name]="widget.name"
                                [ngClass]="{'full': widget.data && widget.data.fullView}"
                                [data]="widget.data" [isEditable]="isGridEditable"
                                (fullScreen)="toggleFullscreen($event, i)"
                                (removeWidget)="removeWidget(i)"></app-tide-forecast-widget>
      <app-flow-map-widget *ngIf="widget.type=='flow_map_widget'" [mapId]="widget.data['mapId']" [name]="widget.name"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [isEditable]="isGridEditable" [title]="widget.title" [data]="widget.data"
                           (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-flow-map-widget>
      <app-cells-attendance-widget *ngIf="widget.type=='cells_attendance_widget'" [title]="widget.title"
                                   [ngClass]="{'full': widget.data && widget.data.fullView}"
                                   [name]="widget.name" [isEditable]="isGridEditable"
                                   (fullScreen)="toggleFullscreen($event, i)"
                                   (removeWidget)="removeWidget(i)">
      </app-cells-attendance-widget>
      <app-heart-beat-widget *ngIf="widget.type=='heart_beat_widget'" [title]="widget.title" [data]="widget.data"
                             [ngClass]="{'full': widget.data && widget.data.fullView}"
                             [name]="widget.name" (editData)="editData(i)" [isEditable]="isGridEditable"
                             (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-heart-beat-widget>
      <app-traffic-widget *ngIf="widget.type=='traffic_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                          (removeWidget)="removeWidget(i)">
      </app-traffic-widget>
      <app-parking-widget *ngIf="widget.type=='parking_widget'" [title]="widget.title" [name]="widget.name"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          [isEditable]="isGridEditable" [mapId]="widget.data['mapId']" [data]="widget.data"
                          (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-parking-widget>
      <app-here-map-widget *ngIf="widget.type=='here_map_widget'" [title]="widget.title" [name]="widget.name"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [isEditable]="isGridEditable" [mapId]="widget.data['mapId']" [data]="widget.data"
                           (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)">
      </app-here-map-widget>
      <app-public-transport-widget *ngIf="widget.type=='public_transport_widget'" [title]="widget.title"
                                   [ngClass]="{'full': widget.data && widget.data.fullView}"
                                   [name]="widget.name" [isEditable]="isGridEditable" [data]="widget.data"
                                   (fullScreen)="toggleFullscreen($event, i)"
                                   (removeWidget)="removeWidget(i)"></app-public-transport-widget>
      <app-events-widget *ngIf="widget.type=='events_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                         [ngClass]="{'full': widget.data && widget.data.fullView}"
                         [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)"
                         (removeWidget)="removeWidget(i)">
      </app-events-widget>
      <app-road-maintenance-widget *ngIf="widget.type=='road_maintenance_widget'" [title]="widget.title"
                                   [name]="widget.name" [isEditable]="isGridEditable"
                                   [ngClass]="{'full': widget.data && widget.data.fullView}"
                                   (fullScreen)="toggleFullscreen($event, i)"
                                   (removeWidget)="removeWidget(i)">
      </app-road-maintenance-widget>
      <app-caselli-widget *ngIf="widget.type=='caselli_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                          [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          (removeWidget)="removeWidget(i)">
      </app-caselli-widget>
      <app-auxiliary-widget *ngIf="widget.type=='auxiliary_widget'" [title]="widget.title" [name]="widget.name"
                            [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                            [ngClass]="{'full': widget.data && widget.data.fullView}"
                            (removeWidget)="removeWidget(i)">
      </app-auxiliary-widget>
      <app-highway-traffic-widget *ngIf="widget.type=='highway_traffic_widget'" [title]="widget.title"
                                  [name]="widget.name" [isEditable]="isGridEditable"
                                  [ngClass]="{'full': widget.data && widget.data.fullView}"
                                  (fullScreen)="toggleFullscreen($event, i)"
                                  (removeWidget)="removeWidget(i)">
      </app-highway-traffic-widget>
      <app-video-feed-widget *ngIf="widget.type=='video_feed_widget'" [title]="widget.title"
                             [isEditable]="isGridEditable" [data]="widget.data"
                             [ngClass]="{'full': widget.data && widget.data.fullView}"
                             (fullScreen)="toggleFullscreen($event, i)"
                             (removeWidget)="removeWidget(i)"></app-video-feed-widget>
      <app-alerts-widget *ngIf="widget.type=='alerts_widget'" [name]="widget.name" [title]="widget.title"
                         [ngClass]="{'full': widget.data && widget.data.fullView}"
                         [data]="widget.data" [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                         (removeWidget)="removeWidget(i)"></app-alerts-widget>
      <app-alerts-sr-widget *ngIf="widget.type=='alerts_sr_widget'" [name]="widget.name" [title]="widget.title"
                            [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                            [ngClass]="{'full': widget.data && widget.data.fullView}"
                            (removeWidget)="removeWidget(i)">
      </app-alerts-sr-widget>
      <app-danger-index-widget *ngIf="widget.type=='danger_index_widget'" [name]="widget.name" [title]="widget.title"
                               [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                               [ngClass]="{'full': widget.data && widget.data.fullView}"
                               (removeWidget)="removeWidget(i)">
      </app-danger-index-widget>
      <app-big-map-widget *ngIf="widget.type=='big_map_widget'" [name]="widget.name" [title]="widget.title"
                          [isEditable]="isGridEditable" [data]="widget.data" (fullScreen)="toggleFullscreen($event, i)"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          (removeWidget)="removeWidget(i)"></app-big-map-widget>
      <app-message-panels-widget *ngIf="widget.type=='message_panels_widget'" [title]="widget.title"
                                 [name]="widget.name" [isEditable]="isGridEditable" [data]="widget.data"
                                 [ngClass]="{'full': widget.data && widget.data.fullView}"
                                 (fullScreen)="toggleFullscreen($event, i)"
                                 (removeWidget)="removeWidget(i)"></app-message-panels-widget>
      <app-flow-control-widget *ngIf="widget.type=='flow_control_widget'" [title]="widget.title" [name]="widget.name"
                               [isEditable]="isGridEditable" [data]="widget.data"
                               [ngClass]="{'full': widget.data && widget.data.fullView}"
                               (fullScreen)="toggleFullscreen($event, i)"
                               (removeWidget)="removeWidget(i)"></app-flow-control-widget>
      <app-behavioral-statistics-widget *ngIf="widget.type=='behavioral_statistics_widget'" [title]="widget.title"
                                        [name]="widget.name" [isEditable]="isGridEditable" [data]="widget.data"
                                        [ngClass]="{'full': widget.data && widget.data.fullView}"
                                        (fullScreen)="toggleFullscreen($event, i)"
                                        (removeWidget)="removeWidget(i)"></app-behavioral-statistics-widget>
      <app-arcgis-widget *ngIf="widget.type=='arcgis_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                         [name]="widget.name" [data]="widget.data" [mapId]="widget.data['mapId']"
                         [ngClass]="{'full': widget.data && widget.data.fullView}"
                         (fullScreen)="toggleFullscreen($event, i)"
                         (removeWidget)="removeWidget(i)"></app-arcgis-widget>
      <app-bridges-widget *ngIf="widget.type=='bridges_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                          [name]="widget.name" [mapId]="widget.data['mapId']" [data]="widget.data"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          (fullScreen)="toggleFullscreen($event, i)"
                          (removeWidget)="removeWidget(i)"></app-bridges-widget>
      <app-forecast-widget *ngIf="widget.type=='forecast_widget'" [title]="widget.title" [data]="widget.data"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [name]="widget.name" [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                           (removeWidget)="removeWidget(i)">
      </app-forecast-widget>
      <app-indexes-widget *ngIf="widget.type=='indexes_widget'" [title]="widget.title" [data]="widget.data"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          [name]="widget.name" [isEditable]="isGridEditable" (fullScreen)="toggleFullscreen($event, i)"
                          (removeWidget)="removeWidget(i)">
      </app-indexes-widget>
      <app-temperature-widget *ngIf="widget.type=='temperatures_widget'" [title]="widget.title" [data]="widget.data"
                              [name]="widget.name" [isEditable]="isGridEditable"
                              [ngClass]="{'full': widget.data && widget.data.fullView}"
                              [configurationId]="widget.configurationId"
                              (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                              (editData)="editData(i)">
      </app-temperature-widget>
      <app-heatmap-widget *ngIf="widget.type=='heatmap_widget'" [title]="widget.title" [data]="widget.data"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          [isEditable]="isGridEditable" [name]="widget.name" [configurationId]="widget.configurationId"
                          (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                          (editData)="editData(i)">
      </app-heatmap-widget>
      <app-tide-map-widget *ngIf="widget.type=='tide_map_widget'" [title]="widget.title" [data]="widget.data"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [isEditable]="isGridEditable" [name]="widget.name" [configurationId]="widget.configurationId"
                           (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                           (editData)="editData(i)">
      </app-tide-map-widget>
      <app-wind-widget *ngIf="widget.type=='wind_widget'" [title]="widget.title" [data]="widget.data"
                       [ngClass]="{'full': widget.data && widget.data.fullView}"
                       [isEditable]="isGridEditable" [name]="widget.name" [configurationId]="widget.configurationId"
                       (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                       (editData)="editData(i)">
      </app-wind-widget>
      <app-registry-widget *ngIf="widget.type=='registry_widget'" [title]="widget.title" [data]="widget.data"
                           [ngClass]="{'full': widget.data && widget.data.fullView}"
                           [isEditable]="isGridEditable" [name]="widget.name" [configurationId]="widget.configurationId"
                           (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                           (editData)="editData(i)">
      </app-registry-widget>
      <app-air-quality-widget *ngIf="widget.type=='air_quality_widget'" [title]="widget.title" [data]="widget.data"
                              [isEditable]="isGridEditable" [name]="widget.name"
                              [configurationId]="widget.configurationId"
                              [ngClass]="{'full': widget.data && widget.data.fullView}"
                              (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                              (editData)="editData(i)">
      </app-air-quality-widget>
      <app-pedestrian-flows *ngIf="widget.type=='pedestrian_flow_widget'" [title]="widget.title" [data]="widget.data"
                            [isEditable]="isGridEditable" [name]="widget.name"
                            [configurationId]="widget.configurationId"
                            [ngClass]="{'full': widget.data && widget.data.fullView}"
                            (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                            (editData)="editData(i)">
      </app-pedestrian-flows>
      <app-image-demo *ngIf="widget.type=='image_demo_widget'" [title]="widget.title" [data]="widget.data"
                      [ngClass]="{'full': widget.data && widget.data.fullView}"
                      [isEditable]="isGridEditable" [name]="widget.name" [configurationId]="widget.configurationId"
                      (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                      (editData)="editData(i)">
      </app-image-demo>
      <app-mobility-map-widget *ngIf="widget.type=='mobility_map_widget'" [title]="widget.title" [data]="widget.data"
                               [isEditable]="isGridEditable" [name]="widget.name"
                               [configurationId]="widget.configurationId"
                               [ngClass]="{'full': widget.data && widget.data.fullView}"
                               (fullScreen)="toggleFullscreen($event, i)" (removeWidget)="removeWidget(i)"
                               (editData)="editData(i)">
      </app-mobility-map-widget>
      <app-radar-widget *ngIf="widget.type=='radar_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                        [ngClass]="{'full': widget.data && widget.data.fullView}"
                        [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                        (removeWidget)="removeWidget(i)">
      </app-radar-widget>
      <app-weather-widget *ngIf="widget.type=='weather_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                          [ngClass]="{'full': widget.data && widget.data.fullView}"
                          [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                          (removeWidget)="removeWidget(i)">
      </app-weather-widget>
      <app-road-flow-base-widget *ngIf="widget.type=='road_flow_base_widget'" [title]="widget.title"
                                 [isEditable]="isGridEditable"
                                 [ngClass]="{'full': widget.data && widget.data.fullView}"
                                 [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                 (removeWidget)="removeWidget(i)">
      </app-road-flow-base-widget>
      <app-road-flow-current-widget *ngIf="widget.type=='road_flow_current_widget'" [title]="widget.title"
                                    [isEditable]="isGridEditable"
                                    [ngClass]="{'full': widget.data && widget.data.fullView}"
                                    [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                    (removeWidget)="removeWidget(i)">
      </app-road-flow-current-widget>
      <app-road-flow-number-widget *ngIf="widget.type=='road_flow_number_widget'" [title]="widget.title"
                                   [isEditable]="isGridEditable"
                                   [ngClass]="{'full': widget.data && widget.data.fullView}"
                                   [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                   (removeWidget)="removeWidget(i)">
      </app-road-flow-number-widget>
      <app-road-arrivals-widget *ngIf="widget.type=='road_arrivals_widget'" [title]="widget.title"
                                [isEditable]="isGridEditable"
                                [ngClass]="{'full': widget.data && widget.data.fullView}"
                                [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                (removeWidget)="removeWidget(i)">
      </app-road-arrivals-widget>
      <app-road-flow-date-widget *ngIf="widget.type=='road_flow_date_widget'" [title]="widget.title"
                                 [isEditable]="isGridEditable"
                                 [ngClass]="{'full': widget.data && widget.data.fullView}"
                                 [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                 (removeWidget)="removeWidget(i)">
      </app-road-flow-date-widget>
      <app-road-flow-mean-widget *ngIf="widget.type=='road_flow_mean_widget'" [title]="widget.title"
                                 [isEditable]="isGridEditable"
                                 [ngClass]="{'full': widget.data && widget.data.fullView}"
                                 [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                 (removeWidget)="removeWidget(i)">
      </app-road-flow-mean-widget>
      <app-geoserver-widget *ngIf="widget.type=='geoserver_widget'" [title]="widget.title" [isEditable]="isGridEditable"
                            [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                            [ngClass]="{'full': widget.data && widget.data.fullView}"
                            (removeWidget)="removeWidget(i)">
      </app-geoserver-widget>
      <app-geoserver-ol-widget *ngIf="widget.type=='geoserver_ol_widget'" [title]="widget.title"
                               [isEditable]="isGridEditable"
                               [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                               [ngClass]="{'full': widget.data && widget.data.fullView}"
                               (removeWidget)="removeWidget(i)">
      </app-geoserver-ol-widget>
      <app-traffic-image-widget *ngIf="widget.type=='traffic_image_widget'" [title]="widget.title"
                                [isEditable]="isGridEditable"
                                [ngClass]="{'full': widget.data && widget.data.fullView}"
                                [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                (removeWidget)="removeWidget(i)">
      </app-traffic-image-widget>
      <app-cultural-events-widget *ngIf="widget.type=='cultural_events_widget'" [title]="widget.title"
                                  [isEditable]="isGridEditable"
                                  [ngClass]="{'full': widget.data && widget.data.fullView}"
                                  [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)" [data]="widget.data"
                                  (removeWidget)="removeWidget(i)">
      </app-cultural-events-widget>
      <app-map-object-position-widget *ngIf="widget.type=='map_object_position_widget'" [title]="widget.title"
                                      [isEditable]="isGridEditable"
                                      [ngClass]="{'full': widget.data && widget.data.fullView}"
                                      [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)"
                                      [data]="widget.data"
                                      (removeWidget)="removeWidget(i)">
      </app-map-object-position-widget>
      <app-amat-widget *ngIf="widget.type=='amat_widget'" [title]="widget.title"
                       [isEditable]="isGridEditable"
                       [ngClass]="{'full': widget.data && widget.data.fullView}"
                       [name]="widget.name" (fullScreen)="toggleFullscreen($event, i)"
                       [data]="widget.data"
                       (removeWidget)="removeWidget(i)">
      </app-amat-widget>
    </ngx-grid-stack-item>
  </ng-container>
</ngx-grid-stack>
<div class="loader d-flex justify-content-center align-items-center" *ngIf="!widgets">
  <mat-spinner></mat-spinner>
</div>
