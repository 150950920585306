import { Component, Injector, OnInit } from '@angular/core';
import {
  FlowDateTimestamp,
  ParkingStatus,
  RoadFlowDateService,
} from '@app/modules/widgets/flows/road-flow-date-widget/road-flow-date.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';

@Component({
  selector: 'app-road-flow-date-widget',
  templateUrl: './road-flow-date-widget.component.html',
  styleUrls: ['./road-flow-date-widget.component.scss'],
})
export class RoadFlowDateWidgetComponent extends WidgetComponent implements OnInit {
  date: number | null;
  status: ParkingStatus;

  constructor(public widgetService: RoadFlowDateService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.loadData(timeMachineData, this.sources).subscribe((data: FlowDateTimestamp) => {
      this.date = data.date;
      this.status = data.status;
      this.isLoading = false;
      this.noData = false;
    }, (error: any) => {
      this.isLoading = false;
      this.noData = true;
    });
  }
}
