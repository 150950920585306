import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MapWidgetModule } from '../map-widget/map-widget.module';
import { WidgetModule } from '../widget/widget.module';
import { GeoserverOlWidgetComponent } from './geoserver-ol-widget.component';
import { GeoserverOlWidgetService } from './geoserver-ol-widget.service';


@NgModule({
  declarations: [GeoserverOlWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MapWidgetModule,
    LeafletModule,
  ], exports: [GeoserverOlWidgetComponent],
  providers: [GeoserverOlWidgetService,
    { provide: 'geoserver_ol_service', useClass: GeoserverOlWidgetService }],
})
export class GeoserverOlWidgetModule {
}
