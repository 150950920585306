import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { TideMapRequest } from '@app/modules/widgets/tide-map-widget/models/tide-map-request';
import { TideMapWidgetService } from '@app/modules/widgets/tide-map-widget/tide-map-widget.service';
import { CurrentTideEntry } from '@app/modules/widgets/tide-widget/models';
import { TideService } from '@app/modules/widgets/tide-widget/tide.service';
import { FeatureGroup } from 'leaflet';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-tide-map-widget',
  templateUrl: './tide-map-widget.component.html',
  styleUrls: ['./tide-map-widget.component.scss'],
})
export class TideMapWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  tideLevel: FeatureGroup;
  tide: number;

  constructor(public widgetService: TideMapWidgetService, private tideService: TideService, public injector: Injector) {
    super(widgetService, injector);
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    const tideSubscription: Subscription = this.tideService.loadData(timeMachineData, this.sources).subscribe((tide: CurrentTideEntry) => {
      this.tide = tide.level;
      this.refreshWidget(timeMachineData);
      tideSubscription.unsubscribe();
    });
  }

  refreshWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    const tideMapRequest: TideMapRequest = {
      tide: this.tide,
      zoom: this.map.getZoom(),
    };
    if (this.tideLevel) {
      this.tideLevel.remove();
    }
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources, tideMapRequest).subscribe((level: FeatureGroup) => {
      this.tideLevel = level;
      this.tideLevel.addTo(this.map);
      this.isLoading = false;
    });
  }

  tideChanged(newValue: number): void {
    this.tide = newValue;
    this.refreshWidget(this.timeMachineService.getCurrentSelection());
  }

}
