import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TideForecastWidgetComponent } from './tide-forecast-widget.component';

@NgModule({
  declarations: [TideForecastWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule
  ],
  exports: [TideForecastWidgetComponent],
})
export class TideForecastWidgetModule {
}
