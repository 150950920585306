import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmatResponse, AmatWidgetService } from '@app/modules/widgets/amat-widget/amat-widget.service';
import { AmatConfiguration, RatioType } from '@app/modules/widgets/amat-widget/model/amat-configuration';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { SuccessComponent } from '@app/shared/components/modals/global-modals/message-modals/success/success.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Configuration, ConfigurationUpdateRequest } from '@app/shared/models/configuration/configurationUpdateRequest';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { ResizedEvent } from 'angular-resize-event/lib/resized-event';
import * as chartJs from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Label } from 'ng2-charts/lib/base-chart.directive';

@Component({
  selector: 'app-amat-widget',
  templateUrl: './amat-widget.component.html',
  styleUrls: ['./amat-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmatWidgetComponent extends WidgetComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  times: Array<string> = [];
  configuration: AmatConfiguration;
  originalConfiguration: Configuration;
  showOverlay: boolean = false;

  Object: any = Object;

  barChartOptions: chartJs.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    onHover: (event: MouseEvent, chartElement: Array<{}>): void => {
      (event.target as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
  };

  barChartLabels: Array<Label> = [];
  barChartData: Array<chartJs.ChartDataSets> = [];

  constructor(public widgetService: AmatWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    const configService: ConfigurationService = this.injector.get(ConfigurationService);
    this.isLoading = true;
    configService.getConfiguration('amat-tpl').subscribe((c: Configuration) => {
      this.isLoading = false;
      this.configuration = JSON.parse(c.configuration);
      this.originalConfiguration = c;
      super.ngOnInit();
      this.checkSources();
    });
  }

  graphResize(event: ResizedEvent): void {
    if (this.chart) {
      this.chart.chart.update();
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.loadData(timeMachineData, this.sources, this.configuration).subscribe((data: AmatResponse) => {
      this.isLoading = false;
      this.barChartData = Object.entries(data.results).map((key: [string, any], value: number) => {
        return {
          data: key[1],
          label: key[0],
          stack: key[0] === 'Capacita' ? undefined : 'a',
          type: key[0] === 'Capacita' ? 'line' : 'bar',
        };
      });
      this.barChartLabels = data.times;
      this.times = data.times;
    });
  }

  resetConfig(): void {
    this.configuration = JSON.parse(this.originalConfiguration.configuration);
  }

  saveConfig(): void {
    const configService: ConfigurationService = this.injector.get(ConfigurationService);
    const dialog: MatDialog = this.injector.get(MatDialog);
    const newConf: ConfigurationUpdateRequest = {
      title: this.originalConfiguration.title,
      description: this.originalConfiguration.description,
      configuration: JSON.stringify(this.configuration),
      enabled: this.originalConfiguration.enabled,
    };
    this.isLoading = true;
    configService.updateConfiguration('amat-tpl', newConf).subscribe(() => {
      this.originalConfiguration.configuration = JSON.stringify(this.configuration);
      this.isLoading = false;
      dialog.open(SuccessComponent, {});
    }, (error: any) => {
      this.isLoading = false;
      dialog.open(FailureComponent, {});
    });

  }

  setOverlay(value: boolean): void {
    this.showOverlay = value;
  }

  submitForm(): void {
    this.reloadWidget();
  }

}
