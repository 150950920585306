import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WidgetInstance } from '@app/shared/models/widgets/widget-instance';
import { ButtonsWidgetComponent } from '@app/shared/components/modals/buttons-widget/buttons-widget.component';
import { WidgetEditableData } from '@app/modules/dashboard/dashboard.component';

@Component({
  selector: 'app-edit-global-data-modal',
  templateUrl: './edit-global-data-modal.component.html',
  styleUrls: ['./edit-global-data-modal.component.scss'],
})
export class EditGlobalDataModalComponent implements OnInit {

  activeWidgetType: WidgetInstance;
  thresholdsSchema: any;
  widgets: any;
  selectedLevel: WidgetInstance;

  constructor(public dialogRef: MatDialogRef<EditGlobalDataModalComponent>, @Inject(MAT_DIALOG_DATA) public data: Array<WidgetInstance>) {
    this.widgets = {
      submit: ButtonsWidgetComponent,
    };
  }

  ngOnInit(): void {
    this.selectWidget(this.data[0]);
    this.selectedLevel = this.data[0];
  }

  selectWidget(widget: WidgetInstance): void {
    this.activeWidgetType = widget;
    if (widget.thresholdsSchema) {
      this.thresholdsSchema = JSON.parse(widget.thresholdsSchema);
      const thresholds: any = JSON.parse(widget.thresholds);
      thresholds.forEach((item: any) => {
        this.thresholdsSchema.data['low_' + item.name] = item.low;
        this.thresholdsSchema.data['high_' + item.name] = item.high;
      });
    } else {
      this.thresholdsSchema = {};
    }
  }

  submit(data: any): void {
    const saved: Array<WidgetEditableData> = [];
    const listPropertyNames: Array<string> = Object.keys(data);
    listPropertyNames.forEach((item: string) => {
      const nameP: string = item.replace('low_', '').replace('high_', '');
      const p: WidgetEditableData = saved.find((x: WidgetEditableData) => x.name === nameP);

      if (!p) {
        const xp: WidgetEditableData = { name: nameP, low: data['low_' + nameP], high: data['high_' + nameP] };
        saved.push(xp);
      }
    });

    this.dialogRef.close({
      id: this.activeWidgetType.id,
      name: this.activeWidgetType.name,
      data: saved,
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
