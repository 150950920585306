import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoadFlowNumberWidgetComponent } from '@app/modules/widgets/flows/road-flow-number-widget/road-flow-number-widget.component';
import { RoadFlowNumberService } from '@app/modules/widgets/flows/road-flow-number-widget/road-flow-number.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RoadFlowNumberWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule,
  ],
  providers: [RoadFlowNumberService],
  exports: [RoadFlowNumberWidgetComponent],
})
export class RoadFlowNumberModule {
}
