import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable, of } from 'rxjs';

@Injectable()
export class TrafficImageService extends WidgetService {


  public loadData(timeMachineData: TimeMachineData, sources?: Array<WidgetDataSource>): Observable<TrafficImageData> {
    const baseSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'traffic-map-base';
    });
    const dataSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'traffic-map-data';
    });
    if (baseSource && dataSource) {
      return of({
        base: this.addTimeMachineDataToUrl(timeMachineData, baseSource),
        animation: this.addTimeMachineDataToUrl(timeMachineData, dataSource),
      });
    }
  }
}

export interface TrafficImageData {
  base: string;
  animation: string;
}
