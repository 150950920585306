<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData"
>
  <div class="container d-flex flex-column justify-content-around">
    <ng-container *ngIf="meanData">
      <div class="number d-flex flex-row justify-content-center align-items-center">
        <div class="d-inline" *ngIf="meanData[option]>0 && option === 'mean'">+</div>
        <div class="d-inline" *ngIf="option === 'mean'">{{meanData.mean | number:'1.0-2'}}</div>
        <div class="d-inline" *ngIf="option !== 'mean'">{{meanData[option] | number:'1.0'}}</div>
      </div>
      <div class="label" *ngIf="option === 'mean'">
        <ng-container *ngIf="!timeMachineService.rangeOn">
          <i class="date__text">{{'WIDGETS.ROAD_FLOWS.DEFAULT_DATA' | translate }}</i>
        </ng-container>
        <ng-container *ngIf="timeMachineService.rangeOn">
          <i
            class="date__text">{{'WIDGETS.ROAD_FLOWS.DEFAULT_DATA_RANGE' | translate }}&nbsp;{{this.timeMachineService.getCurrentSelectionRange().getToTimeStamp() | date:'dd/MM/yyyy - HH:mm:ss'}}
          </i>
        </ng-container>
      </div>
      <div class="label" *ngIf="option !== '!mean'">
        <ng-container *ngIf="!timeMachineService.rangeOn">
          <i class="date__text">{{'WIDGETS.ROAD_FLOWS.DEFAULT_DATA_TOTAL' | translate }}</i>
        </ng-container>
        <ng-container *ngIf="timeMachineService.rangeOn">
          <i
            class="date__text">{{'WIDGETS.ROAD_FLOWS.DEFAULT_DATA_RANGE_TOTAL' | translate }}&nbsp;{{this.timeMachineService.getCurrentSelectionRange().getToTimeStamp() | date:'dd/MM/yyyy - HH:mm:ss'}}
          </i>
        </ng-container>
      </div>
    </ng-container>
  </div>
</app-widget>

