import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-danger-index-widget',
  templateUrl: './danger-index-widget.component.html',
  styleUrls: ['./danger-index-widget.component.scss']
})
export class DangerIndexWidgetComponent extends WidgetComponent {

  baseImgUrl: string = 'assets/img/home/MicrosoftTeams-image.png';

  events: Array<any> = [
    {
      title: 'Spave B. - Verona Est',
      value: '8.1',
      type: 'danger'
    },
    {
      title: 'Verona Est - Soave B.',
      value: '8.0',
      type: 'danger'
    },
    {
      title: 'Montebello - Alto Montecchio',
      value: '7.6',
      type: 'danger'
    },
    {
      title: 'Verona Est - Verona Sud',
      value: '7.5',
      type: 'warning'
    },
    {
      title: 'Verona Sud - Brescia Est',
      value: '6.4',
      type: 'warning'
    },
    {
      title: 'Sommacampagna - Verona Sud',
      value: '6.6',
      type: 'warning'
    },
    {
      title: 'Sirmione - Peschiera',
      value: '5.6',
      type: 'warning'
    }
  ];
}
