import { Component, ElementRef, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HeatmapService } from '@app/modules/widgets/heatmap-widget/heatmap.service';
import { HeatmapData } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-data';
import { HeatmapLevel } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-level';
import { HeatmapLevels } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-levels';
import { HeatmapPoint } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-point';
import { HeatmapWidgetData } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-widget-data';
import { MapConfig } from '@app/modules/widgets/map-widget/model';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { HeatmapLayer } from '@deck.gl/aggregation-layers';
import { MapboxLayer } from '@deck.gl/mapbox';
import { ResizedEvent } from 'angular-resize-event';
import { FeatureCollection, Point } from 'geojson';
import { cloneDeep } from 'lodash';
import * as mapboxgl from 'mapbox-gl';
import { Layer, LngLat, LngLatBounds, Map, MapboxEvent, NavigationControl } from 'mapbox-gl';

const heatmapLayerName: string = 'heatmap-layer';

@Component({
  selector: 'app-heatmap-widget',
  templateUrl: './heatmap-widget.component.html',
  styleUrls: ['./heatmap-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeatmapWidgetComponent extends WidgetComponent implements OnInit {

  private refreshBounds: boolean = true;
  @ViewChild('mapEl', { static: true })
  public mapEl: ElementRef<HTMLDivElement>;

  map: Map;
  popup: mapboxgl.Popup;

  @Input()
  data: HeatmapWidgetData;

  bounds: LngLatBounds;

  dataLevel: DataResult<HeatmapData>;

  heatmapSelection: Array<HeatmapLevel>;

  layerOptions: Array<HeatmapLevel> = [{
    id: 1,
    label: 'Venezia',
  }, {
    id: 2,
    label: 'Mestre',
  }, {
    id: 3,
    label: 'Resto del comune',
  }];

  config: MapConfig = {
    center: {
      lat: 45.440316,
      lon: 12.315556,
    },
    zoom: 10,
    url: 'mapbox://styles/mapbox/dark-v9',
  };

  mapZoom: number;
  heatMapLevels: HeatmapLevels;
  showLayerOptions: boolean = true;

  constructor(public widgetService: HeatmapService, public injector: Injector) {
    super(widgetService, injector);
    this.heatmapSelection = cloneDeep(this.layerOptions);
  }

  ngOnInit(): void {
    this.isLoading = true;
    if (this.data.layerOptions) {
      this.layerOptions = this.data.layerOptions;
    }
    if (this.data.mapConfig) {
      this.config = Object.assign(this.config, this.data.mapConfig);
    }
    this.initMap().then((map: Map) => {
      super.ngOnInit();
      this.checkSources();
      this.isLoading = false;
    });
  }

  initMap(): Promise<Map> {
    // @ts-ignore
    mapboxgl.accessToken = this.appConfigService.getOptions().mapbox.accessToken;
    return new Promise((resolve: (value?: (PromiseLike<mapboxgl.Map> | mapboxgl.Map)) => void
      , reject: (reason?: any) => void) => {
      this.map = new Map({
        container: this.mapEl.nativeElement,
        style: this.config.url,
        center: {
          lng: this.config.center.lon,
          lat: this.config.center.lat,
        },
        zoom: this.config.zoom,
        pitch: 0,
        attributionControl: false,
      });
      this.map.on('load', (event: MapboxEvent) => {
        this.map.addControl(new NavigationControl({
          showCompass: false,
          showZoom: true,
          visualizePitch: false,
        }));
        resolve(this.map);
      });
      this.mapZoom = this.map.getZoom();
      this.map.on('zoomend', (event: MapboxEvent) => {
        this.mapZoom = this.map.getZoom();
        this.refreshDataLevel();
        this.refreshHeatmapLayer();
      });
    });
  }

  refreshDataLevel(): void {
    if (this.heatMapLevels) {
      switch (true) {
        case (this.mapZoom > 9):
          this.dataLevel = this.heatMapLevels.towns;
          break;
        case (this.mapZoom < 9 && this.mapZoom > 8):
          this.dataLevel = this.heatMapLevels.provinces;
          break;
        case (this.mapZoom < 8):
          this.dataLevel = this.heatMapLevels.regions;
          break;
      }
    }
  }

  checkData(): boolean {
    try {
      return this.heatMapLevels.all.data.length > 0 &&
        this.heatMapLevels.provinces.data.length > 0 &&
        this.heatMapLevels.regions.data.length > 0 &&
        this.heatMapLevels.towns.data.length > 0;
    } catch (e) {
      return false;
    }
  }


  refreshHeatmapLayer(): void {
    const oldLayer: Layer = this.map.getLayer(heatmapLayerName);
    if (oldLayer) {
      this.map.removeLayer(heatmapLayerName);
    }
    if (this.mapZoom < 7) {
      this.map.addLayer(new MapboxLayer({
        id: heatmapLayerName,
        // @ts-ignore
        type: HeatmapLayer,
        pickable: true,
        data: this.heatMapLevels.all.data.map((h: HeatmapData): HeatmapPoint => {
          return {
            position: [h.lon, h.lat],
            intensity: h.total,
          };
        }),
      }));
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    const selection: Array<number> = this.heatmapSelection.map((h: HeatmapLevel) => {
      return h.id;
    });
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources, selection).subscribe((heatmapLevels: HeatmapLevels) => {
      this.heatMapLevels = heatmapLevels;
      this.noData = !this.checkData();
      const heatmapLayer: Layer = this.map.getLayer(heatmapLayerName);
      if (heatmapLayer) {
        this.map.removeLayer(heatmapLayerName);
      }
      const sourceData: FeatureCollection<Point> = this.widgetService.heatMapDataToGeoJson(this.heatMapLevels.all.data);
      const oldSource: any = this.map.getSource('points');
      if (oldSource) {
        oldSource.setData(sourceData);
        this.refreshHeatmapLayer();
      } else {
        this.map.addSource('points', {
          type: 'geojson',
          data: sourceData,
        });
        this.refreshHeatmapLayer();
        this.map.addLayer({
          id: heatmapLayerName + '-circles',
          type: 'circle',
          minzoom: 7,
          source: 'points',
          paint: {
            'circle-radius': 10,
            'circle-color': {
              property: 'total',
              type: 'exponential',
              stops: [
                [0, 'rgb(236,222,239)'],
                [10, 'rgb(255,255,178)'],
                [50, 'rgb(254,217,118)'],
                [100, 'rgb(254,178,76)'],
                [500, 'rgb(253,141,60)'],
                [1000, 'rgb(240,59,32)'],
                [2000, 'rgb(189,0,38)'],
              ],
            },
            'circle-stroke-color': 'transparent',
            'circle-stroke-width': 1,
            'circle-opacity': 1,
          },
        });
      }
      this.map.on('click', heatmapLayerName + '-circles', (e: any) => {
        if (this.popup) {
          this.popup.remove();
        }
        this.popup = new mapboxgl.Popup()
          .setLngLat(e.features[0].geometry.coordinates)
          .setHTML(`<b>Comune:</b> ${e.features[0].properties.comune}<br/>
                    <b>Ace:</b> ${e.features[0].properties.ace}<br/>
                    <b>Totale:</b> ${e.features[0].properties.total}`)
          .addTo(this.map);
      });
      this.refreshDataLevel();
      if (this.refreshBounds && this.heatMapLevels.all.data && this.heatMapLevels.all.data.length) {
        this.bounds = new LngLatBounds();
        this.heatMapLevels.all.data.forEach((h: HeatmapData) => {
          this.bounds.extend(new LngLat(h.lon, h.lat));
        });
        this.map.fitBounds(this.bounds);
      }
      this.refreshBounds = false;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  handleResize(resize: ResizedEvent): void {
    super.handleResize(resize);
    try {
      if (this.map) {
        this.map.resize();
      }
    } catch (e) {

    }
  }

  onChange(event: Array<HeatmapLevel>): void {
    this.heatmapSelection = event;
    this.loadWidget(this.timeMachineService.getCurrentSelection());
  }

  onSelectAll(): void {
    this.heatmapSelection = cloneDeep(this.layerOptions);
    this.loadWidget(this.timeMachineService.getCurrentSelection());
  }

}
