import { AfterViewInit, Component, DoCheck, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { MapConfig, MapMarker } from '@app/modules/widgets/map-widget/model';
import { MapWidgetData } from '@app/modules/widgets/map-widget/model/interfaces/mapWidgetData';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import {
  Layer,
  latLng,
  LatLng,
  LeafletEvent,
  LeafletMouseEvent,
  Map,
  MapOptions,
  TileLayer,
  tileLayer,
  Control,
  FeatureGroup,
  featureGroup,
  DrawEvents,
} from 'leaflet';

declare var L: any;

@Component({
  selector: 'app-map-widget',
  templateUrl: './map-widget.component.html',
  styleUrls: ['./map-widget.component.scss'],
})
export class MapWidgetComponent extends WidgetComponent implements OnInit, DoCheck, OnDestroy, AfterViewInit {
  timeDimensionControlOptions: any;
  timeDimensionControl: Control;

  @Input()
  public mapId: string;

  @Input()
  public map: Map;

  markers: Array<MapMarker> = [];

  @Input()
  public data: MapWidgetData;

  @Input()
  public legends: Array<MapLegend>;

  defaultConfig: MapConfig = {
    center: {
      lat: 45.440316,
      lon: 12.315556,
    },
    zoom: 14,
  };

  @Input()
  showLegend: boolean;

  @Input()
  showLegendReduced: boolean;

  layer: FeatureGroup;

  public drawOptions: Control.DrawConstructorOptions;
  public drawnItems: FeatureGroup = featureGroup();
  showDrawer: boolean = false;

  public baseLayer: TileLayer = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {});
  public cartoDbBaseLayer: TileLayer = tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {});
  public cartoDbBaseLayerDark: TileLayer = tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {});

  @Input()
  public leafletOptions: MapOptions = {};

  moveEnd: EventEmitter<LeafletMouseEvent> = new EventEmitter<LeafletMouseEvent>();
  mouseDown: EventEmitter<LeafletMouseEvent> = new EventEmitter<LeafletMouseEvent>();
  zoom: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  zoomStart: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  zoomEnd: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();

  @Output()
  mapReady: EventEmitter<Map> = new EventEmitter<Map>();

  constructor(public mapWidgetService: MapWidgetService, public injector: Injector) {
    super(mapWidgetService, injector);
  }

  ngOnInit(): void {
    this.loadSources();
    this.defaultConfig = Object.assign(this.defaultConfig, this.data.mapConfig);
    this.leafletOptions = {
      layers: this.themeService.isDark ? [this.cartoDbBaseLayerDark] : [this.cartoDbBaseLayer],
      zoom: this.defaultConfig.zoom,
      center: latLng(this.defaultConfig.center.lat, this.defaultConfig.center.lon),
      // @ts-ignore
      timeDimension: this.data.timeDimension,
    };
    this.drawOptions = {
      position: 'topleft',
      edit: {
        featureGroup: this.drawnItems,
      },
      draw: {
        marker: false,
        circle: false,
        circlemarker: false,
        polyline: false,
      },
    };
    this.showLegend = this.data.showLegend ? this.data.showLegend : true;
    this.showLegendReduced = this.data.showLegendReduced ? this.data.showLegendReduced : false;
    this.themeService.sendReload.subscribe(() => {
      if (this.map && this.themeService.isDark) {
        this.map.removeLayer(this.cartoDbBaseLayer);
        this.map.addLayer(this.cartoDbBaseLayerDark);
        this.cartoDbBaseLayerDark.bringToBack();
      } else {
        this.map.removeLayer(this.cartoDbBaseLayerDark);
        this.map.addLayer(this.cartoDbBaseLayer);
        this.cartoDbBaseLayer.bringToBack();
      }
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
    super.ngAfterViewInit();
  }

  initTimeDimensionControl(): void {
    // @ts-ignore
    this.timeDimensionControlOptions = {
      position: 'bottomleft',
      loopButton: true,
      autoPlay: true,
      timeZones: ['Local'],
      speedStep: 1,
      minSpeed: 1,
      maxSpeed: 5,
      playerOptions: {
        loop: true,
        buffer: 5,
        startOver: true,
      },
      speedSlider: false,
    };

    this.timeDimensionControl = new L.control.timeDimension(this.timeDimensionControlOptions);
    this.map.addControl(this.timeDimensionControl);
  }

  toggleLegend(): void {
    this.showLegend = !this.showLegend;
  }

  toggleDrawer(): void {
    this.showDrawer = !this.showDrawer;
  }

  setMapHandlers(leafletMap: Map): void {
    this.map = leafletMap;
    if (this.data.sync) {
      this.widgetsEventBusService.syncMap(this.map);
    }
    this.map.on('moveend', async (e: LeafletMouseEvent) => {
      this.moveEnd.emit(e);
    });
    this.map.on('mousedown', async (e: LeafletMouseEvent) => {
      this.mouseDown.emit(e);
    });
    this.map.on('zoom', async (e: LeafletEvent) => {
      this.zoom.emit(e);
    });
    this.map.on('zoomstart', async (e: LeafletEvent) => {
      this.zoomStart.emit(e);
    });
    this.map.on('zoomend', async (e: LeafletEvent) => {
      this.zoomEnd.emit(e);
    });
    super.ngOnInit();
    if (this.data.timeDimension) {
      this.initTimeDimensionControl();
    }
  }

  onMapReady(leafletMap: Map): void {
    new Control.Scale({ imperial: false }).addTo(leafletMap);

    L.Control.Projection = L.Control.extend({
      onAdd: () => {
        const el: any = L.DomUtil.create('div', 'leaflet-control-scale leaflet-control');
        el.innerHTML = '<div class="leaflet-control-scale-line" id="projection">' + leafletMap.options.crs.code + '</div>';
        return el;
      },
      onRemove: () => {
        // Nothing to do here
      },
    });
    L.control.projection = (opts: any) => {
      return new L.Control.Projection(opts);
    };

    L.control.projection({
      position: 'bottomleft',
    }).addTo(leafletMap);
    return this.mapReady.emit(leafletMap);
  }

  onDrawCreated(e: DrawEvents.Created): void {
    this.drawnItems.addLayer(e.layer);
  }

  onDrawDeleted(e: DrawEvents.Deleted): void {
    e.layers.eachLayer((layer: Layer) => {
      this.drawnItems.removeLayer(layer);
    });
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = (this.widgetService as MapWidgetService).getMapLevel(timeMachineData, this.sources).subscribe((layer: FeatureGroup) => {
      this.layer = layer;
      this.noData = false;
      if (this.layer) {
        this.layer.remove();
      }
      this.layer.addTo(this.map);
      this.isLoading = false;
      try {
        this.map.getBounds().extend(this.layer.getBounds());
      } catch (e) {
        console.error(e);
      }
    }, (error: any) => {
      this.noData = true;
      this.isLoading = false;
      console.error(error);
    });
  }

  onDrawEdited(e: DrawEvents.Edited): void {
    // Todo
  }

  setupMap(url: string = null, lat: number = this.defaultConfig.center.lat, long: number = this.defaultConfig.center.lon): void {
    if (url) {
      this.baseLayer = tileLayer(url, {
        maxZoom: this.defaultConfig.zoom,
      });
    }
    this.centerMap(lat, long);
  }

  centerMap(lat: number, long: number): void {
    this.leafletOptions.center = new LatLng(long, lat);
    if (this.map) {
      this.map.panTo(new LatLng(long, lat));
    }
  }

  getMap(): Map {
    return this.map;
  }

  checkLegendVisibility(): boolean {
    if (this.isFullScreen) {
      return this.showLegend;
    } else {
      return this.showLegendReduced;
    }
    return false;
  }

  ngDoCheck(): void {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.map = null;
    this.widgetsEventBusService.resetMapList();
  }
}
