
<h1 mat-dialog-title>{{'COMMONS.MAP_TIDE_CONTROL.BOOTS_MODAL_TITLE' | translate}}</h1>
<div mat-dialog-content class="pb-3">
  <ng-container *ngFor="let option of data">
    <label class="mb-1">{{ option.label | translate}}</label>
    <div class="d-flex justify-content-between">
      <mat-form-field class="p-1">
        <mat-label>{{ 'COMMONS.MAP_TIDE_CONTROL.HEIGHT' | translate}}</mat-label>
        <input matInput placeholder="{{ 'COMMONS.MAP_TIDE_CONTROL.HEIGHT' | translate}}" [(ngModel)]="option.value">
      </mat-form-field>
      <mat-form-field class="p-1">
        <mat-label>{{ 'COMMONS.MAP_TIDE_CONTROL.OFFSET' | translate}}</mat-label>
        <input matInput placeholder="{{ 'COMMONS.MAP_TIDE_CONTROL.OFFSET' | translate}}" [(ngModel)]="option.offset">
      </mat-form-field>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button class="btn btn-warning mr-2" mat-raised-button [mat-dialog-close]="false">{{'COMMONS.CANCEL' | translate | uppercase }}</button>
  <button class="btn btn-success" mat-raised-button (click)="updateBootsSettings()" cdkFocusInitial>{{'COMMONS.APPLY' | translate | uppercase }}</button>
</div>
