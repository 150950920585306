import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { DangerIndexWidgetComponent } from './danger-index-widget.component';


@NgModule({
  declarations: [DangerIndexWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule
  ],
  exports: [DangerIndexWidgetComponent],
})
export class DangerIndexWidgetModule {
}
