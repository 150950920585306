import { JsonSchemaFormModule } from '@ajsf/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
import { TideViewerModule } from '@app/modules/map/tide-viewer/tide-viewer.module';
import { AlertsComponent } from '@app/shared/components/alerts/alerts.component';
import { MapBaseControlModule } from '@app/modules/map/map-base-control/map-base-control.module';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { RegistrySearchModule } from '@app/modules/map/registry-search/registry-search.module';
import { MenuBarComponent } from '@app/shared/components/menu-bar/menu-bar.component';
import { MenuComponent } from '@app/shared/components/menu-bar/menu/menu.component';
import { SettingsComponent } from '@app/shared/components/menu-bar/menu/settings/settings.component';
import { ButtonsWidgetComponent } from '@app/shared/components/modals/buttons-widget/buttons-widget.component';
import { AddWidgetModalComponent } from '@app/shared/components/modals/dashboard-modals/add-widget-modal/add-widget-modal.component';
import { DeleteModalComponent } from '@app/shared/components/modals/dashboard-modals/delete-modal/delete-modal.component';
import { EditDataModalComponent } from '@app/shared/components/modals/dashboard-modals/edit-data-modal/edit-data-modal.component';
import { LoadModalComponent } from '@app/shared/components/modals/dashboard-modals/load-modal/load-modal.component';
import { RemoveWidgetModalComponent } from '@app/shared/components/modals/dashboard-modals/remove-widget-modal/remove-widget-modal.component';
import { SaveModalComponent } from '@app/shared/components/modals/dashboard-modals/save-modal/save-modal.component';
import { ConfirmModalComponent } from '@app/shared/components/modals/global-modals/action-modals/confirm-modal/confirm-modal.component';
import { EditGlobalDataModalComponent } from '@app/shared/components/modals/global-modals/action-modals/edit-global-data-modal/edit-global-data-modal.component';
import { EditIndexesModalComponent } from '@app/shared/components/modals/global-modals/action-modals/edit-indexes-modal/edit-indexes-modal.component';
import { ImageModalComponent } from '@app/shared/components/modals/global-modals/action-modals/image-modal/image-modal.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { SuccessComponent } from '@app/shared/components/modals/global-modals/message-modals/success/success.component';
import { PageMenuBarModule } from '@app/modules/page-menu-bar/page-menu-bar.module';
import { TimeMachineComponent } from '@app/shared/components/time-machine/time-machine.component';
import { WidgetsModule } from '@app/modules/widgets/widgets.module';
import { NgbDropdownModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DestinationRouteManagerComponent } from '@app/shared/components/destination-route-manager/destination-route-manager.component';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartsModule } from 'ng2-charts';
import { NouisliderModule } from 'ng2-nouislider';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BootsSettingsModalComponent } from './components/modals/boots-settings-modal/boots-settings-modal.component';
import { MobileErrorComponent } from './components/mobile-error/mobile-error.component';

@NgModule({
  declarations: [
    AlertsComponent,
    DestinationRouteManagerComponent,
    BootsSettingsModalComponent,
    ButtonsWidgetComponent,
    TimeMachineComponent,
    ButtonsWidgetComponent,
    AddWidgetModalComponent,
    DeleteModalComponent,
    EditDataModalComponent,
    LoadModalComponent,
    RemoveWidgetModalComponent,
    SaveModalComponent,
    ConfirmModalComponent,
    EditGlobalDataModalComponent,
    EditIndexesModalComponent,
    ImageModalComponent,
    FailureComponent,
    SuccessComponent,
    SettingsComponent,
    MenuComponent,
    MenuBarComponent,
    MobileErrorComponent],
  imports: [
    AngularResizedEventModule,
    CommonModule,
    ChartsModule,
    TranslateModule.forChild({
      extend: true,
    }),
    AppMaterialModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    Ng2PanZoomModule,
    NgbDropdownModule,
    NgxDaterangepickerMd,
    NouisliderModule,
    WidgetsModule,
    RouterModule,
    NgbPopoverModule,
    MapBaseControlModule,
    MapLegendModule,
    PageMenuBarModule,
    RegistrySearchModule,
    TideViewerModule,
    JsonSchemaFormModule,
  ],
  exports: [
    // Modules
    AngularResizedEventModule,
    AppMaterialModule,
    ChartsModule,
    TranslateModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxDaterangepickerMd,
    Ng2PanZoomModule,
    NgbDropdownModule,
    NouisliderModule,
    WidgetsModule,
    RouterModule,
    NgbPopoverModule,
    MapBaseControlModule,
    MapLegendModule,
    RegistrySearchModule,
    PageMenuBarModule,
    TideViewerModule,

    // Component
    AlertsComponent,
    DestinationRouteManagerComponent,
    TimeMachineComponent,
    ButtonsWidgetComponent,
    AddWidgetModalComponent,
    DeleteModalComponent,
    EditDataModalComponent,
    LoadModalComponent,
    RemoveWidgetModalComponent,
    SaveModalComponent,
    ConfirmModalComponent,
    EditGlobalDataModalComponent,
    EditIndexesModalComponent,
    ImageModalComponent,
    FailureComponent,
    SuccessComponent,
    SettingsComponent,
    MenuComponent,
    MenuBarComponent, MobileErrorComponent],
})
export class SharedModule {
}
