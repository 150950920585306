import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { ParkingWidgetService } from '@app/modules/widgets/parking-widget/parking-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FeatureGroup } from 'leaflet';

@Component({
  selector: 'app-parking-widget',
  templateUrl: './parking-widget.component.html'
})
export class ParkingWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  currentLevel: FeatureGroup;

  constructor(
    public widgetService: ParkingWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources).subscribe((currentLevel: FeatureGroup) => {
      if (this.currentLevel) {
        this.map.removeLayer(this.currentLevel);
      }
      this.currentLevel = currentLevel;
      this.currentLevel.addTo(this.map);
    });
  }

}
