<h1 mat-dialog-title>{{'MENU.MODALS.EDIT_INDEX_GLOBAL.TITLE' | translate}}</h1>
<p class="mt-1 mb-4">{{'MENU.MODALS.EDIT_INDEX_GLOBAL.SELECT_INDEX' | translate}}</p>

<ng-select class="search__select"
  [items]="data"
  [multiple]="false"
  [closeOnSelect]="true"
  [hideSelected]="false"
  [clearable]="false"
  bindLabel="title"
  (change)="selectWeight($event)"
  [(ngModel)]="selectedWeight">
  <ng-template ng-option-tmp  let-item="item" >
    <div class="selected-item" >
      <div class="d-flex selected-item__label">
          <span class="item">
              <span class="label">{{item.title}}</span></span>
        </div>
    </div>
  </ng-template>

</ng-select>
<div class="form scroll" *ngIf="activeWeightType">
<form name="weightForm" class="pb-3" #weightForm>
  <json-schema-form
    class="json-schema-form"
    *ngIf="weightsSchema"
    language="it"
    [form]="weightsSchema"
    [(ngModel)]="weightsSchema.data"
    [ngModelOptions]="{standalone: true}"
    framework="material-design"
    [widgets]="weight"
    (onSubmit)="submit($event)"
    (isValid)="isValid($event)"
    (validationErrors)="validationErrors($event)">
  </json-schema-form>
  <div class="flex-row align-items-center justify-content-between justify-content-start error"
    *ngFor="let msg of errorMessage">
    {{msg.dataPath}} {{msg.message}}
  </div>
  <div class="d-flex flex-row justify-content-end align-items-stretch">

    <button mat-raised-button color="warn" class="mr-2" (click)="cancel()">{{'COMMONS.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary" type="submit"
            (click)="submit(weightsSchema.data)">{{'COMMONS.OK' | translate}}</button>
  </div>

</form>
</div>
