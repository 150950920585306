import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { WidgetEditableData } from '@app/modules/dashboard/dashboard.component';
import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { SourceData, WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { SliderRange, TimeMachineData } from '@app/shared/components/time-machine/models';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { SourcesService } from '@services/sources/sources.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { AngularJsonSchemaForm } from '@app/shared/models/angular-json-schema-form';
import { WidgetType } from '@app/shared/enums/widget-type';
import { WidgetServiceInterface } from '@app/modules/widgets/widget/widgetServiceInterface';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@services/storage/storage.service';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppConfigService } from '@services/app-config/app-config.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '@services/loader/loader.service';

@Injectable()
export class WidgetService implements WidgetServiceInterface {
  schemaUrl: string = null;
  widgetType: WidgetType;
  // tslint:disable-next-line:no-duplicate-string
  timeMachineTags: Array<string> = ['{{from}}', '{{to}}', '{{dateFrom}}', '{{dateTo}}', '{{dateUTCFrom}}', '{{dateUTCTo}}', '{{from-rounded-hour}}', '{{to-rounded-hour}}'];
  DEFAULT_REFRESH_INTERVAL: number = 5 * 1000;

  constructor(public http: HttpClient,
              public storageService: StorageService,
              public sourcesService: SourcesService,
              public translateService: TranslateService,
              public timeMachineService: TimeMachineService,
              public appConfigService: AppConfigService,
              public configurationService: ConfigurationService,
              public sanitizer: DomSanitizer,
              public dialog: MatDialog,
              public loaderService: LoaderService,
              public authenticationService: AuthenticationService,
              public themeService: ThemeService,
              public zone: NgZone) {
  }

  public static addTimeStamp(url: string): string {
    const timestamp: number = new Date().getTime();
    return url.replace('{{timestamp}}', timestamp.toString());
  }

  public static replaceTimestamp(url: string, timestamp: number): string {
    return url.replace('{{timestamp}}', timestamp.toString());
  }

  public getSuperSetUrl(id: string): string {
    let superSetUrl: string;
    const domain: string = this.authenticationService.getDomain();
    const hostname: string = window.location.hostname;
    if (hostname.includes('dev-') || hostname.includes('localhost')) {
      superSetUrl = `https://sset${id}-dev-${domain}.mindicity.it/`;
    } else if (hostname.includes('stg-')) {
      superSetUrl = `https://sset${id}-stg-${domain}.mindicity.it/`;
    } else {
      superSetUrl = `https://sset${id}-${domain}.mindicity.it/`;
    }
    return superSetUrl;
  }

  public calculateTimeMachineData(timeMachineData: TimeMachineData, offset: number): TimeMachineData {
    let newTimeMachineData: TimeMachineData;
    if (this.timeMachineService.isTimeMachineActive()) {
      if (offset) {
        if (typeof timeMachineData === 'number') {
          newTimeMachineData = timeMachineData;
          newTimeMachineData = newTimeMachineData + offset * this.DEFAULT_REFRESH_INTERVAL;
        } else {
          newTimeMachineData = JSON.parse(JSON.stringify(timeMachineData)) as TimeMachineRange;
          newTimeMachineData.to = moment(newTimeMachineData.to).add(offset * this.DEFAULT_REFRESH_INTERVAL, 'milliseconds');
        }
      } else {
        return timeMachineData;
      }
    } else {
      newTimeMachineData = new Date().valueOf();
    }
    return newTimeMachineData;
  }

  public replaceUrl(originalUrl: string, from?: number, to?: number): string {
    if (originalUrl.indexOf('{{from}}') !== -1 && from) {
      originalUrl = originalUrl.replace('{{from}}', from.toString());
    }
    if (originalUrl.indexOf('{{to}}') !== -1 && to) {
      originalUrl = originalUrl.replace('{{to}}', to.toString());
    }
    if (originalUrl.indexOf('{{beginDayTo}}') !== -1 && to) {
      originalUrl = originalUrl.replace('{{beginDayTo}}', moment(to).startOf('day').valueOf().toString());
    }
    if (originalUrl.indexOf('{{beginDayNow}}') !== -1 && to) {
      originalUrl = originalUrl.replace('{{beginDayNow}}', moment().startOf('day').valueOf().toString());
    }
    if (originalUrl.indexOf('{{now}}') !== -1) {
      originalUrl = originalUrl.replace('{{now}}', moment().valueOf().toString());
    }
    if (originalUrl.indexOf('{{fromSeconds}}') !== -1 && from) {
      const rounded: number = Math.round(from / 1000);
      originalUrl = originalUrl.replace('{{fromSeconds}}', rounded.toString());
    }
    if (originalUrl.indexOf('{{toSeconds}}') !== -1 && to) {
      const rounded: number = Math.round(to / 1000);
      originalUrl = originalUrl.replace('{{toSeconds}}', rounded.toString());
    }
    if (originalUrl.indexOf('{{dateFrom}}') !== -1 && from) {
      originalUrl = originalUrl.replace('{{dateFrom}}', moment(from).format('YYYY-MM-DD'));
    }
    if (originalUrl.indexOf('{{dateTo}}') !== -1 && to) {
      originalUrl = originalUrl.replace('{{dateTo}}', moment(to).format('YYYY-MM-DD'));
    }
    if (originalUrl.indexOf('{{dateUTCFrom}}') !== -1 && from) {
      originalUrl = originalUrl.replace('{{dateUTCFrom}}', moment(from).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss'));
    }
    if (originalUrl.indexOf('{{dateUTCTo}}') !== -1 && to) {
      originalUrl = originalUrl.replace('{{dateUTCTo}}', moment(to).utcOffset(0).format('YYYY-MM-DDTHH:mm:ss'));
    }
    if (originalUrl.indexOf('{{from-rounded-hour}}') !== -1 && from) {
      const fromRounded: number = moment(from).startOf('hour').valueOf();
      originalUrl = originalUrl.replace('{{from-rounded-hour}}', fromRounded.toString());
    }
    if (originalUrl.indexOf('{{to-rounded-hour}}') !== -1 && to) {
      const toRounded: number = moment(to).startOf('hour').valueOf();
      originalUrl = originalUrl.replace('{{to-rounded-hour}}', toRounded.toString());
    }
    if (originalUrl.indexOf('{{superset}}') !== 1) {
      originalUrl = originalUrl.replace('{{superset}}', this.getSuperSetUrl(''));
    }
    if (originalUrl.indexOf('{{superset2}}') !== 1) {
      originalUrl = originalUrl.replace('{{superset2}}', this.getSuperSetUrl('02'));
    }
    if (originalUrl.indexOf('{{apiKey}}') !== 1) {
      originalUrl = originalUrl.replace('{{apiKey}}', this.appConfigService.getOptions().here.apiKey);
    }
    if (originalUrl.indexOf('{{domain}}') !== 1) {
      originalUrl = originalUrl.replace('{{domain}}', this.authenticationService.getDomain());
    }
    if (originalUrl.indexOf('{{dom}}') !== 1) {
      const domain: string = this.authenticationService.getDomain();
      const master: boolean = this.authenticationService.isUserMaster();
      if (master) {
        originalUrl = originalUrl.replace('{{dom}}', '');
      } else {
        originalUrl = originalUrl.replace('{{dom}}', domain);
      }
    }
    if (originalUrl.indexOf('{{token}}') !== -1) {
      originalUrl = originalUrl.replace('{{token}}', this.authenticationService.getTokenInstant());
    }
    if (originalUrl.indexOf('{{theme}}') !== -1) {
      originalUrl = originalUrl.replace('{{theme}}', this.themeService.getGrafanaString());
    }
    return originalUrl;
  }

  public replaceAdvancedTimeRangeUrl(timeRange: SliderRange, mainSource: WidgetDataSource, offset?: number): string {
    const newUrl: string = mainSource.rewriteUrl;
    const from: number = timeRange.from.valueOf();
    let to: number = timeRange.to.valueOf();
    if (offset) {
      to = moment(to).add(offset, 'hours').valueOf();
    }
    return this.replaceUrl(newUrl, from, to);
  }

  public replaceTimeRangeUrl(timeRange: TimeMachineRange, mainSource: WidgetDataSource, offset?: number): string {
    const newUrl: string = mainSource.rewriteUrl;
    const from: number = timeRange.getFromTimeStamp();
    let to: number = timeRange.getToTimeStamp();
    if (offset) {
      to = moment(to).add(offset, 'hours').valueOf();
    }
    return this.replaceUrl(newUrl, from, to);
  }

  public replaceTimeStampUrl(timestamp: number, mainSource: WidgetDataSource, hoursAgo: number = 1, offset?: number): string {
    const sourceConfiguration: any = JSON.parse(mainSource.configuration);
    const newUrl: string = mainSource.rewriteUrl;
    let from: number = moment(timestamp).subtract(hoursAgo, 'hour').valueOf();
    if (offset) {
      timestamp = moment(timestamp).add(offset, 'hours').valueOf();
    }
    let to: number = timestamp;
    if (sourceConfiguration) {
      const sourceData: SourceData = sourceConfiguration.sourceData;
      if (sourceData && sourceData.from) {
        from = moment(timestamp).subtract(sourceData.from, 'hour').valueOf();
      }
      if (sourceData && sourceData.to) {
        to = moment(timestamp).subtract(sourceData.to, 'hour').valueOf();
      }
    }
    return this.replaceUrl(newUrl, from, to);
  }

  public addTimeMachineDataToUrl(timeStamp: TimeMachineData, mainSource: WidgetDataSource, hoursAgo: number = 1, offset?: number): string {
    if (typeof timeStamp === 'number') {
      return this.replaceTimeStampUrl(timeStamp, mainSource, hoursAgo, offset);
    } else if (timeStamp instanceof TimeMachineRange) {
      return this.replaceTimeRangeUrl(timeStamp, mainSource, offset);
    } else {
      return this.replaceAdvancedTimeRangeUrl(timeStamp, mainSource, offset);
    }
  }

  public replaceParameter(url: string, parameterName: string, value: string): string {
    return url.replace('{{' + parameterName + '}}', value);
  }

  public loadTimeStampData(timeStamp: number, sources?: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): any {
    return;
  }

  public loadRangeData(range: TimeMachineRange, sources?: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): any {
    return;
  }

  public loadAdvancedRangeData(range: SliderRange, sources?: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<any> {
    return;
  }

  public loadData(timeMachineData: TimeMachineData, sources?: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<any> {
    if (typeof timeMachineData === 'number') {
      return this.loadTimeStampData(timeMachineData, sources, additionalData, forecast);
    } else if (timeMachineData instanceof TimeMachineRange) {
      return this.loadRangeData(timeMachineData, sources, additionalData, forecast);
    } else {
      return this.loadAdvancedRangeData(timeMachineData, sources, additionalData, forecast);
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const url: string = this.addTimeMachineDataToUrl(moment().valueOf(), source);
    return this.http.get<any>(url);
  }

  public getGlobalThresholds(typeId: string): Observable<Array<WidgetEditableData>> {
    return this.storageService.loadGlobalThresholds(typeId);
  }

  public setThresholdValueSchema(savedData: Array<WidgetEditableData>, currentSchema: AngularJsonSchemaForm): AngularJsonSchemaForm {
    savedData.forEach((item: any) => {
      currentSchema.data['low_' + item.name] = item.low;
      currentSchema.data['high_' + item.name] = item.high;
    });
    return currentSchema;
  }

  public getDataSchema(typeId: string): Observable<AngularJsonSchemaForm> {
    return this.http.get<AngularJsonSchemaForm>(this.schemaUrl).pipe(
      switchMap((currentSchema: AngularJsonSchemaForm) => {
        return forkJoin([of(currentSchema), this.getGlobalThresholds(typeId)]);
      }), switchMap((data: Array<any>) => {
        const currentSchema: AngularJsonSchemaForm = data[0];
        const globalThresholds: Array<WidgetEditableData> = data[1];
        return forkJoin([of(this.setThresholdValueSchema(globalThresholds, currentSchema)), this.getSavedThresholds(typeId)]);
      }), switchMap((data: Array<any>) => {
        const currentSchema: AngularJsonSchemaForm = data[0];
        const globalThresholds: Array<WidgetEditableData> = data[1];
        const savedData: Array<WidgetEditableData> = data[2];
        if (savedData) {
          return of(this.setThresholdValueSchema(savedData, currentSchema));
        } else {
          if (globalThresholds) {
            return of(this.setThresholdValueSchema(globalThresholds, currentSchema));
          } else {
            throw Error('No data');
          }
        }
      }));
  }

  /*public getDataSchema(typeId: number, configurationId: number): Observable<AngularJsonSchemaForm> {
    return this.http.get<AngularJsonSchemaForm>(this.schemaUrl).pipe(
      map((currentSchema: AngularJsonSchemaForm) => {
        this.getGlobalThresholds(typeId).pipe(
          map((globalThresholds: any) => {
            if (configurationId) {
              this.getSavedThresholds(configurationId).pipe(
                map((savedData: any) => {
                  if (savedData) {
                    return this.setThresholdValueSchema(savedData, currentSchema);
                  }
                }, () => {
                  return this.setThresholdValueSchema(globalThresholds, currentSchema);
                }),
              );
            } else {
              return this.setThresholdValueSchema(globalThresholds, currentSchema);
            }
          }));
        return currentSchema;
      }),
    );
  }*/

  public getSavedThresholds(name: string): Observable<Array<WidgetEditableData>> {
    return this.storageService.loadWidgetThreshold(name);
  }

  public checkThresholds(timeMachineData: TimeMachineData): Observable<DataResult<WidgetEvent>> {
    return this.storageService.checkWidgetThresholds(timeMachineData, null);
  }

}
