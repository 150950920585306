import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardModule } from '@app/modules/dashboard/dashboard.module';
import { TranslateModule } from '@ngx-translate/core';
import { PageMenuBarComponent } from './page-menu-bar.component';

@NgModule({
  declarations: [
    PageMenuBarComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DashboardModule,
  ],
  exports: [PageMenuBarComponent],
})
export class PageMenuBarModule {
}
