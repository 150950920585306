import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable } from 'rxjs';

@Injectable()
export class RoadFlowCurrentService extends WidgetService {
  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>): Observable<CurrentFlowValue> {
    const source: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'road-flow-percentage';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<CurrentFlowValue>(url);
    }
  }

}

export interface CurrentFlowValue {
  percentage: number;
  full: number;
  free: number;
  max: number;
}
