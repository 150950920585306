import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-highway-traffic-widget',
  templateUrl: './highway-traffic-widget.component.html',
  styleUrls: ['./highway-traffic-widget.component.scss']
})
export class HighwayTrafficWidgetComponent extends WidgetComponent {

  baseImgUrl: string = 'assets/img/fakeChart.png';

}
