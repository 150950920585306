import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { RadarData, RadarFlow, RadarFlowData } from '@app/modules/widgets/radar-widget/models/radar-flow';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { ChartOptions, ChartType } from 'chart.js';
import { cloneDeep, orderBy } from 'lodash';
import { BaseChartDirective, Color, Label, MultiDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { RadarTableDataSourceItem } from './models';
import { RadarService } from './radar.service';

@Component({
  selector: 'app-radar-widget',
  templateUrl: './radar-widget.component.html',
  styleUrls: ['./radar-widget.component.scss']
})
export class RadarWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('pieChart')
  public chart: BaseChartDirective;

  ngStyle: { [klass: string]: any; };

  squareHeight: string;
  squareWidth: string;

  tableDataSource: MatTableDataSource<RadarTableDataSourceItem>;

  radarFepTableColumns: Array<string> = ['color', 'type', 'total', 'speed'];
  radarSmartSensorTableColumns: Array<string> = ['color', 'type', 'total'];

  pieChartData: MultiDataSet = [[]];
  pieChartLabels: Array<Label> = [];
  pieChartType: ChartType = 'doughnut';
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    aspectRatio: 1,
  };

  pieChartColors: Array<Color>;

  activeSort: Sort;

  trafficData: Array<RadarData>;
  originalRadarData: Array<RadarData>;
  selectedEntry: RadarTableDataSourceItem;

  zonesSelectedSubscription: Subscription;
  zonesActivatedSubscription: Subscription;
  trafficDataSubscription: Subscription;
  typePickSubscription: Subscription;
  panelActive: boolean;

  blockUpdate: boolean;

  constructor(public widgetService: RadarService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.populateWidget();
  }

  populateWidget(): void {
    this.isLoading = true;

    this.panelActive = true;
    const timeMachineData: TimeMachineData = this.timeMachineService.getCurrentSelection();

    this.trafficDataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((trafficData: RadarFlowData) => {
      this.noData = false;
      this.isLoading = true;
      trafficData.radarFlow = orderBy(trafficData.radarFlow, ['name'], ['asc']);
      this.trafficData = [];

      trafficData.radarFlow.forEach((f: RadarFlow) => {
        const tableData: Array<RadarTableDataSourceItem> =
          this.widgetService.getTableData(timeMachineData, this.sources, f, trafficData.vehicleColors);

        this.trafficData.push({
          name: f.name,
          total: 0,
          data: trafficData.radarFlow,
          tableDataSource: new MatTableDataSource(tableData),
          expanded: false,
        });
      });

      this.noData = !this.trafficData.length;
      this.orderData();
      this.originalRadarData = cloneDeep(this.trafficData);
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  expandPanel(radarData: RadarData): void {
    radarData.expanded = true;
  }

  scrollToTop(radarData: RadarData): void {
    const scroll: HTMLElement = document.getElementById(radarData.name);
    scroll.scrollIntoView();
  }

  orderData(): void {
    this.trafficData = orderBy(this.trafficData, ['name'], ['asc']);
    this.trafficData.forEach((c: RadarData) => {
      if (c && c.data) {
        c.total = this.getTotal(c.tableDataSource, 'total');
        this.initGraph(c.tableDataSource, c);
      }
    });
  }

  handleResize(resize: ResizedEvent): void {
    const minDimension: number = Math.min(resize.newHeight, resize.newWidth);
    const dimension: string = (minDimension - (minDimension * 20 / 100)).toString();
    this.squareHeight = dimension;
    this.squareWidth = dimension;
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  initGraph(trafficData: MatTableDataSource<RadarTableDataSourceItem>, c: RadarData): void {
    const data: Array<RadarTableDataSourceItem> = trafficData.filteredData;
    c.graphData = {
      data: [[].concat(data.map((item: RadarTableDataSourceItem) => {
        return item.total;
      }))],
      labels: [].concat(data.map((item: RadarTableDataSourceItem) => item.name)),
      colors: [{
        backgroundColor: [].concat(data.map((item: RadarTableDataSourceItem) => item.color)),
      }],
    };
  }

  getTotal(dataSource: MatTableDataSource<RadarTableDataSourceItem>, column: string): number {
    const data: Array<RadarTableDataSourceItem> = dataSource.filteredData;
    return data.reduce((acc: number, t: RadarTableDataSourceItem) => {
      return acc + (t[column] || 0);
    }, 0);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.typePickSubscription) {
      this.typePickSubscription.unsubscribe();
    }
    if (this.zonesActivatedSubscription) {
      this.zonesActivatedSubscription.unsubscribe();
    }
    if (this.trafficDataSubscription) {
      this.trafficDataSubscription.unsubscribe();
    }
  }

}
