import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { CellsAttendanceWidgetComponent } from './cells-attendance-widget.component';

@NgModule({
  declarations: [CellsAttendanceWidgetComponent],
  imports: [CommonModule,
  WidgetModule],
  exports: [CellsAttendanceWidgetComponent],
})
export class CellsAttendanceWidgetModule {
}
