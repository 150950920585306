import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { CaselliWidgetComponent } from './caselli-widget.component';

@NgModule({
  declarations: [CaselliWidgetComponent],
  imports: [CommonModule,
  WidgetModule],
  exports: [CaselliWidgetComponent],
})
export class CaselliWidgetModule {
}
