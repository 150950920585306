import { SerializedForecast } from '@app/modules/widgets/forecast-widget/model/serialized-forecast';
import moment, { Moment } from 'moment';

export class Forecast {
  time: Moment;
  day: string;
  partOfDay: 'm' | 'a' | null;
  precipitation: string;
  precipitationProbability: string;
  reliability: string;
  sky: string;
  snowAltitude: string;
  symbol: string;
  temperature: string;
  timeForecast: number;

  constructor(s: SerializedForecast) {
    this.time = moment(s.time);
    this.day = moment(Number(s.timeForecast)).format('MM-DD-YYYY');
    this.partOfDay = s.partOfDay;
    this.precipitation = s.precipitation;
    this.precipitationProbability = s.precipitationProbability;
    this.reliability = s.reliability;
    this.sky = s.sky;
    this.snowAltitude = s.snowAltitude;
    this.symbol = s.symbol;
    this.temperature = s.temperature;
    this.timeForecast = Number(s.timeForecast);
  }

}
