import { EventEmitter, Injectable } from '@angular/core';
import { WidgetType } from '@app/shared/enums/widget-type';
import { Subject } from 'rxjs';

@Injectable()
export class LoaderService {
  isLoading: Subject<boolean> = new Subject<boolean>();
  isMaintenance: EventEmitter<boolean> = new EventEmitter<boolean>();
  refreshWidget: EventEmitter<WidgetType> = new EventEmitter<WidgetType>();

  show(): void {
    this.isLoading.next(true);
  }

  hide(): void {
    this.isLoading.next(false);
  }

  showMaintenance(): void {
    this.isMaintenance.emit(true);
  }

}
