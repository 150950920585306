import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { PermissionsConfig } from '@app/shared/models/app-config/app-options';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Moment } from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
  private refreshTokenCall: Observable<string>;

  private token: string = null;
  helper: JwtHelperService;
  tokenKey: string;

  logout: EventEmitter<void> = new EventEmitter<void>();
  sendToLogin: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(DOCUMENT) private document: Document, private httpClient: HttpClient) {
    this.helper = new JwtHelperService();
    this.tokenKey = 'mindicityToken';
  }

  private static getParameterByName(name: string, url: string): string {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex: RegExp = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results: Array<string> = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  public getLoginUrl(document: Document, truncatePathName?: boolean): string {
    if (truncatePathName) {
      return '/cas/login?service=' + encodeURIComponent(document.location.origin + '/');
    } else {
      return '/cas/login?service=' + encodeURIComponent(document.location.origin + document.location.pathname);
    }
  }

  public getLogoutUrl(document: Document): string {
    return '/cas/logout?service=' + encodeURIComponent(document.location.origin + this.getLoginUrl(document, true));
  }

  public getCleanLogoutUrl(document: Document): string {
    return '/cas/logout?service=' + encodeURIComponent(document.location.origin);
  }


  public getTokenInstant(): string {
    return localStorage.getItem(this.tokenKey);
  }

  public getToken(): Observable<string> {
    if (localStorage.getItem(this.tokenKey)) {
      return of(localStorage.getItem(this.tokenKey));
    }

    return this.refreshToken();
  }

  public getDomain(): string {
    try {
      return this.readUserData().dom;
    } catch {
      throw new Error('Domain not available');
    }
  }

  public getUserName(): string {
    try {
      return this.readUserData().sub;
    } catch {
      throw new Error('UserName not available');
    }
  }

  public isUserMaster(): boolean {
    try {
      return this.readUserData().master ? this.readUserData().master === 'true' : false;
    } catch {
      throw new Error('master not available');
    }
  }

  public getUserFullName(): string {
    try {
      return (this.readUserData().firstname ?
          this.readUserData().firstname : '')
        + ' ' + (this.readUserData().lastname ?
          this.readUserData().lastname : '');
    } catch {
      throw new Error('UserFullName not available');
    }
  }

  public checkPermissionsConfig(perm: PermissionsConfig): boolean {
    let check: boolean = false;
    if (perm) {
      if (perm.roles && perm.roles.length) {
        check = check || this.userHasRole(perm.roles);
      }
      if (perm.privileges && perm.privileges.length) {
        check = check || this.userHasPrivilege(perm.privileges);
      }
    }
    return check;
  }

  public userHasRole(roles: Array<string>): boolean {
    let hasRole: boolean = false;
    if (roles) {
      roles.forEach((r: string) => {
        if (this.hasRole(r)) {
          hasRole = true;
        }
      });
    }
    return hasRole;
  }

  public userHasPrivilege(privileges: Array<string>): boolean {
    let hasPrivilege: boolean = false;
    if (privileges) {
      privileges.forEach((r: string) => {
        if (this.hasPrivilege(r)) {
          hasPrivilege = true;
        }
      });
    }
    return hasPrivilege;
  }

  public hasRole(role: string): boolean {
    if (typeof this.readUserData().roles === 'string') {
      return role === this.readUserData().roles;
    } else {
      return (this.readUserData().roles as Array<string>).find((rl: string) => rl === role) !== undefined;
    }
  }

  public hasPrivilege(role: string): boolean {
    if (typeof this.readUserData().privileges === 'string') {
      return role === this.readUserData().privileges;
    } else {
      return (this.readUserData().privileges as Array<string>).find((rl: string) => rl === role) !== undefined;
    }
  }

  public setToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.token = token;
  }

  public clearToken(): void {
    this.token = null;
    localStorage.removeItem(this.tokenKey);
  }

  public makeLogout(): void {
    localStorage.removeItem(this.tokenKey);
    this.logout.emit();
  }

  public reSendToLogin(): void {
    this.sendToLogin.emit();
  }

  public isTokenExpired(): boolean {
    return this.helper.isTokenExpired(this.token);
  }

  public readUserData(): User {
    if (!this.token) {
      throw new Error('No token loaded');
    }
    return this.helper.decodeToken(this.token);
  }

  public getExpirationDate(): Date {
    if (this.token) {
      return this.helper.getTokenExpirationDate(this.token);
    }
  }

  public parseToken(url: string): Observable<string> {
    const newToken: string = AuthenticationService.getParameterByName('ticket', url);
    if (newToken) {
      this.setToken(newToken);
      return of(newToken);
    } else {
      throw new Error('Token refresh failure');
    }
  }

  public refreshToken(): Observable<string> {
    if (!this.refreshTokenCall) {
      const requestUrl: string = this.getLoginUrl(document);
      this.refreshTokenCall = this.httpClient.get(requestUrl, {
        responseType: 'text',
        observe: 'response',
      }).pipe(switchMap((res: HttpResponse<any>) => {
        this.refreshTokenCall = undefined;
        return this.parseToken(res.url);
      }), catchError((err: HttpErrorResponse) => {
          if (err.url) {
            return this.parseToken(err.url);
          } else {
            throw new Error('Token refresh failure');
          }
        },
      ));
    }
    return this.refreshTokenCall;
  }
}

export interface User {
  sub: string;
  firstname: string;
  lastname: string;
  username: string;
  roles: Array<string> | string;
  privileges: Array<string> | string;
  authenticationDate: Moment;
  dom: string;
  master?: string;
}
