import { GridStackWidget } from 'gridstack';

export class GridStackItem implements GridStackWidget {
  x: number = 0;
  y: number = 0;
  height: number = 3;
  width: number = 3;
  maxHeight: number;
  minHeight: number;
  maxWidth: number;
  minWidth: number;
  noResize: boolean = false;
  noMove: boolean;
  autoPosition: boolean = true;
  locked: boolean = false;
  el: any;

  static Clone(widget: GridStackItem): GridStackItem {
    const result: GridStackItem = new GridStackItem();

    result.autoPosition = widget.autoPosition;
    result.el = widget.el;
    result.height = widget.height;
    result.locked = widget.locked;
    result.maxHeight = widget.maxHeight;
    result.maxWidth = widget.maxWidth;
    result.minHeight = widget.minHeight;
    result.minWidth = widget.minWidth;
    result.noMove = widget.noMove;
    result.noResize = widget.noResize;
    result.width = widget.width;
    result.x = widget.x;
    result.y = widget.y;

    return result;
  }
}
