import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { orderBy } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { TIDE_LEVELS } from './data/levels';
import { CurrentTideEntry, TideEntry, TidePeakEntry } from './models';
import { TideLevel } from './models/interfaces/tide-level';
import { TideService } from './tide.service';
import moment from 'moment';

@Component({
  selector: 'app-tide-widget',
  templateUrl: './tide-widget.component.html',
  styleUrls: ['./tide-widget.component.scss'],
})
export class TideWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
  @ViewChild('background')
  tideGraph: ElementRef;

  displayedTide: CurrentTideEntry;
  selectedStation: TideEntry;
  stationList: Array<TideEntry>;
  peakTide: TidePeakEntry;
  tideHeight: string;
  backgroundHeight: string;
  widgetHeight: string;
  rightLevel: Array<TideLevel>;
  maxTide: number;
  minTide: number;
  infoWidth: string;
  loadedStation: string = 'Punta Salute Canal Grande';

  tideLevels: Array<TideLevel>;

  constructor(public widgetService: TideService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.displayedTide = null;
    this.tideLevels = TIDE_LEVELS.slice(0);

    this.tideLevels = orderBy(this.tideLevels, ['value'], ['desc']);

    this.maxTide = this.tideLevels[0].value;
    this.minTide = this.tideLevels[this.tideLevels.length - 1].value;
    this.tideLevels.shift();

    this.rightLevel = this.tideLevels.filter((d: TideLevel) => {
      return d.right === true;
    });
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    const peakData$: Observable<TidePeakEntry> = this.widgetService.loadPeakData(timeMachineData, this.sources);
    const stationList$: Observable<Array<TideEntry>> = this.widgetService.loadStationList(timeMachineData, this.sources);
    this.isLoading = true;
    this.dataSubscription = forkJoin([peakData$, stationList$]).subscribe((data: [TidePeakEntry, Array<TideEntry>]) => {
      this.peakTide = data[0];
      if (this.peakTide) {
        this.peakTide.time = moment(this.peakTide.time);
      }
      this.stationList = data[1];
      this.stationList.sort((a: TideEntry, b: TideEntry) => (a.order > b.order) ? 1 : (a.order === b.order) ? ((a.station > b.station) ? 1 : -1) : -1);
      this.updateWidget();
      this.adjustTideHeight();
      this.isLoading = false;
    }, () => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  displayLevelClass(tideLevel: TideLevel): string {
    if (this.displayedTide && this.displayedTide.valid && ((tideLevel.value >= 0 && this.displayedTide.level >= tideLevel.value) ||
      (tideLevel.value < 0 && this.displayedTide.level <= tideLevel.value))) {
      return tideLevel.color;
    }
  }

  getPulse(tideLevel: TideLevel): boolean {
    if (this.displayedTide && this.displayedTide.valid && ((tideLevel.value >= 0 && this.displayedTide.level >= tideLevel.value) ||
      (tideLevel.value < 0 && this.displayedTide.level <= tideLevel.value))) {
      return true;
    }
    return false;
  }

  handleTideResize(resize: ResizedEvent): void {
    const minSize: number = Math.min(resize.newWidth, resize.newHeight);
    this.backgroundHeight = minSize.toString() + 'px';
  }

  handleResize(resize: ResizedEvent): void {
    super.handleResize(resize);
    this.widgetHeight = (this.minSize - 20) + 'px';
    if (resize.newHeight > resize.oldHeight || !resize.oldHeight) {
      this.fontSize = Math.floor((Number(this.fontSize)) * 0.9).toString();
    }
  }

  adjustTideHeight(): void {
    if (this.displayedTide) {
      if (this.displayedTide.level > this.maxTide) {
        this.tideHeight = '100%';
      } else if (this.displayedTide.level < this.minTide) {
        this.tideHeight = '0%';
      } else {
        this.tideHeight = ((100 * (-this.minTide + this.displayedTide.level)) / (this.maxTide - this.minTide)) + '%';
      }
    }
  }

  updateWidget(): void {
    this.selectedStation = this.stationList.find((s: TideEntry) => {
      return s.station === this.loadedStation;
    });
    if (!this.selectedStation) {
      this.selectedStation = this.stationList[0];
      this.loadedStation = this.stationList[0].station;
    }
    this.displayedTide = {
      level: this.selectedStation.value * 100,
      moment: moment(this.selectedStation.time),
      trend: this.selectedStation.trend,
      station: this.selectedStation.station,
      valid: this.selectedStation.validValue,
      label: this.selectedStation.label
    };
  }


  stationChange(deviceValue: TideEntry): void {
    this.loadedStation = deviceValue.station;
    this.updateWidget();
    this.adjustTideHeight();
  }

}
