import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { SerializedIndices } from '@app/modules/widgets/indexes-widget/model';
import { Indices } from '@app/modules/widgets/indexes-widget/model/indices';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class IndexesWidgetService extends WidgetService {

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<Indices>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'indicators';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<DataResult<SerializedIndices>>(url).pipe(
        map((result: DataResult<SerializedIndices>) => {
          return result.data.map((s: SerializedIndices) => {
            return new Indices(s);
          });
        }),
      );
    } else {
      throwError('No source');
    }
  }

  public getColorFill(value: number): string {
    if (value >= 0 && value <= 4) {
      return '#f7a0a3';
    } else if (value > 4 && value <= 6) {
      return '#fcd5a8';
    } else if (value > 6 && value <= 7.5) {
      return '#FFFF99';
    } else if (value > 7 && value <= 10.0) {
      return '#d5efd9';
    }
  }
}
