import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MapBaseControlComponent } from '@app/modules/map/map-base-control/map-base-control.component';
import { MatSliderChange } from '@angular/material/slider';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MapTideBootOption, MapTideControlOptions } from '@app/shared/models/map-tide/map-tide';
import { BootsSettingsModalComponent } from '@app/shared/components/modals/boots-settings-modal/boots-settings-modal.component';

@Component({
  selector: 'app-map-tide-control',
  templateUrl: './map-tide-control.component.html',
  styleUrls: ['./map-tide-control.component.scss']
})
export class MapTideControlComponent extends MapBaseControlComponent implements OnInit {

  @Input()
  currentTideValue: number; // current referred to current time machine data

  @Input()
  sliderTideValue: number;

  @Input()
  bootsValue: MapTideBootOption;

  @Input()
  offsetLabel: string;

  @Input()
  future: boolean;

  @Input()
  past: boolean;

  @Input()
  walkways: boolean;

  @Input()
  options: MapTideControlOptions;

  @Input()
  bootsLabel: string;

  @Input()
  bootsOffsetLabel: string;

  @Output()
  sliderTideOffsetChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  sliderTideChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  walkwaysChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  bootsChanged: EventEmitter<MapTideBootOption> = new EventEmitter<MapTideBootOption>();

  @Output()
  bootsSettingsChanged: EventEmitter<Array<MapTideBootOption>> = new EventEmitter<Array<MapTideBootOption>>();

  sliderTideOffsetValue: number;

  constructor(public dialog: MatDialog) {
    super();
    this.loading = false;
    this.sliderTideOffsetValue = 0;
    this.walkways = false;
    this.titleLabel = 'COMMONS.MAP_TIDE_CONTROL.TITLE';
    this.offsetLabel = 'COMMONS.MAP_TIDE_CONTROL.OFFSET';
  }

  ngOnInit(): void {
  }

  updateTideOffsetValue(newValue: number): void {
    this.sliderTideOffsetValue = newValue;
    this.setTideOffsetNewValue();
  }

  setTideOffsetNewValue(): void {
    this.sliderTideOffsetChanged.emit(this.sliderTideOffsetValue);
  }

  updateTideValue(newValue: number): void {
    this.sliderTideValue = newValue;
    this.setTideNewValue();
  }

  setTideNewValue(): void {
    this.adjustWalkways();
    this.sliderTideChanged.emit(this.sliderTideValue);
  }

  setActualTide(): void {
    this.sliderTideValue = this.currentTideValue;
    this.adjustWalkways();
    this.sliderTideChanged.emit(this.sliderTideValue);
  }

  adjustWalkways(): void {
    if (this.options.autoWalkways && this.sliderTideValue >= 80 && this.sliderTideValue <= 120) {
      this.walkways = true;
      this.walkwaysChanged.emit(this.walkways);
    } else if (this.options.autoWalkways && (this.sliderTideValue < 80 || this.sliderTideValue > 120)) {
      this.walkways = false;
      this.walkwaysChanged.emit(this.walkways);
    }
  }

  selectedBootsChanged(event: MatSelectChange): void {
    this.bootsValue = event.value;
    this.bootsChanged.emit(this.bootsValue);
  }

  openBootsSettings(): void {
    const dialogRef: MatDialogRef<BootsSettingsModalComponent, string> = this.dialog.open(BootsSettingsModalComponent, {
      data: this.options.bootsOptions
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result) {
        this.bootsSettingsChanged.emit(result.bootsSettings);
      }
    });
  }
}
