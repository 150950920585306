import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WidgetsEventBusService } from '@app/modules/widgets/widget/widgets-event-bus.service';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { SourcesService } from '@services/sources/sources.service';
import { ResizedEvent } from 'angular-resize-event';
import { forkJoin, interval, Observable, of, Subscription, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { last } from 'lodash';
import { WidgetConfigurationData } from '@app/shared/models/dashboard/serialized-grid';

@Component({
  selector: 'app-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WidgetComponent implements OnDestroy, OnInit, AfterViewInit {

  private DEFAULT_WIDGET_HEIGHT: number = 220;
  private DEFAULT_BAR_HEIGHT: number = 42.750;
  private DEFAULT_FONT_HEIGHT: number = 12;

  TIME_UPDATE_PERIOD: number = 15 * 1000;

  @Output()
  public fullScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public removeWidget: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public editData: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  sources: Array<WidgetDataSource> = [];

  @Input()
  title: string;

  @Input()
  description: string;

  @Input()
  sourceStatus: DataSourceStatus;

  @Input()
  isFullScreen: boolean = false;
  flipped: boolean = false;

  @Input()
  isEditable: boolean = false;

  @Input()
  id: number;

  fontSize: string = '12';

  minSize: number;

  @Input()
  isLoading: boolean;

  @Input()
  data: WidgetConfigurationData;

  timeMachineSubscription: Subscription;
  showDetails: boolean;
  pingThresholdsInterval: Observable<number> = interval(this.TIME_UPDATE_PERIOD);
  pingThresholdsSubscription: Subscription;
  thresholdsEvents: DataResult<WidgetEvent>;
  closeAlertSubscription: Subscription;
  dataSubscription: Subscription;
  checkStatusSubscription: Subscription;
  stopObservable: Subject<void> = new Subject<void>();

  @Input()
  showThresholdAlert: boolean;

  @Input()
  showGlobalThresholdAlert: boolean;

  @Input()
  noData: boolean;

  @Output()
  sendReloadWidget: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  timeMachineActive: boolean;

  @Input()
  timeMachineOn: boolean;

  @Input()
  name: string;

  @Input()
  ready: boolean;

  public widgetsEventBusService: WidgetsEventBusService;
  public timeMachineService: TimeMachineService;
  public sourcesService: SourcesService;
  public themeService: ThemeService;
  public translateService: TranslateService;
  public authenticationService: AuthenticationService;
  public appConfigService: AppConfigService;
  public sanitizer: DomSanitizer;

  constructor(public widgetService: WidgetService, public injector: Injector) {
    this.sourceStatus = DataSourceStatus.WARNING;
    this.widgetsEventBusService = injector.get(WidgetsEventBusService);
    this.timeMachineService = injector.get(TimeMachineService);
    this.sourcesService = injector.get(SourcesService);
    this.themeService = injector.get(ThemeService);
    this.translateService = injector.get(TranslateService);
    this.authenticationService = injector.get(AuthenticationService);
    this.appConfigService = injector.get(AppConfigService);
    this.sanitizer = injector.get(DomSanitizer);
  }

  ngOnInit(): void {
    this.loadSources();
    this.timeMachineSubscription = this.timeMachineService.timeMachineChanged.subscribe((tmdata: TimeMachineData) => {
      this.loadWidget(tmdata);
      this.checkTimeMachineActive();
    });
    this.loadWidget(this.timeMachineService.getCurrentSelection());
    this.pingThresholdsSubscription = this.pingThresholdsInterval.subscribe(async () => {
      this.pingThresholds();
    });
    this.pingThresholds();
    this.sendReloadWidget.subscribe(() => {
      this.loadWidget(this.timeMachineService.getCurrentSelection());
    });
    this.themeService.sendReload.subscribe(() => {
      this.reloadWidget();
    });
  }

  ngAfterViewInit(): void {
    this.ready = true;
  }


  checkTimeMachineActive(): void {
    this.timeMachineService.checkForecastPast();
    this.timeMachineActive = this.timeMachineService.forecast || this.timeMachineService.past;
  }

  reloadWidget(): void {
    this.sendReloadWidget.emit();
  }

  pingThresholds(): void {
    const checkThresholdWidgetService: Observable<DataResult<WidgetEvent>> = this.widgetService.checkThresholds(this.timeMachineService.getCurrentSelection());
    if (checkThresholdWidgetService) {
      checkThresholdWidgetService.subscribe((d: DataResult<WidgetEvent>) => {
        this.thresholdsEvents = d;
        if (this.thresholdsEvents && this.thresholdsEvents.data) {
          this.checkEvents();
        }
      });
    }
  }

  checkData(): boolean {
    return true;
  }

  checkEvents(): void {
    const unreadEvents: Array<WidgetEvent> = this.thresholdsEvents.data.filter((w: WidgetEvent) => {
      return w.read === null;
    });
    const lastEvent: WidgetEvent = last(unreadEvents);
    if (lastEvent) {
      this.showGlobalThresholdAlert = !lastEvent.recipient;
      this.showThresholdAlert = true;
    } else {
      this.showThresholdAlert = false;
    }
  }

  loadSources(): void {
    if (this.data && this.data.sources) {
      this.sources = this.data.sources.map((i: string) => {
        return this.sourcesService.loadSourceData(i);
      });
      this.timeMachineOn = false;
      this.sources.forEach((s: WidgetDataSource) => {
        this.widgetService.timeMachineTags.forEach((t: string) => {
          if (s.rewriteUrl && s.rewriteUrl.includes(t)) {
            this.timeMachineOn = true;
          }
        });
      });
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {

  }

  /**
   * Flip the widget
   */
  flipIt(): void {
    this.flipped = !this.flipped;
  }

  /**
   * Set the widget fullscreen
   */
  toggleFullscreen(): void {
    this.isFullScreen = !this.isFullScreen;
    this.fullScreen.emit(this.isFullScreen);
  }

  sendRemoveWidget(): void {
    this.removeWidget.emit();
  }

  sendEditData(): void {
    this.editData.emit();
  }

  checkSourceDataAvailability(source: WidgetDataSource): Observable<WidgetDataSource> {
    if (source.rewriteUrl) {
      source.status = DataSourceStatus.WARNING;
      return this.widgetService.checkSource(source).pipe(
        map(
          (status: any) => {
            if (status.error) {
              source.status = DataSourceStatus.UNAVAILABLE;
            } else {
              source.status = DataSourceStatus.AVAILABLE;
            }
            return source;
          }, (e: HttpErrorResponse) => {
            if (e.status && (e.status === 0 || e.status === 200 || e.status === 204)) {
              source.status = DataSourceStatus.AVAILABLE;
            } else {
              source.status = DataSourceStatus.UNAVAILABLE;
              return source;
            }
          }),
      );
    } else {
      source.status = DataSourceStatus.AVAILABLE;
      return of(source);
    }
  }

  /**
   * Check delle sorgenti, per il momento vengono controllate solo le sorgenti che hanno un parent, quindi le secondarie.
   * @param sources
   */
  checkSourceArray(sources: Array<WidgetDataSource>): Observable<Array<WidgetDataSource>> {
    const observables: Array<Observable<any>> = [];
    sources.filter((item: WidgetDataSource) => {
      return item.level === 1;
    }).forEach((item: WidgetDataSource) => {
      observables.push(this.checkSourceDataAvailability(item));
    });
    return forkJoin(observables);
  }

  /**
   * Check layer sources availability by calling the api
   * @returns DataSourceStatus the status of the source
   */
  checkSources(): void {
    const observables: Array<Observable<Array<WidgetDataSource>>> = [];
    if (this.sources && this.sources.length) {
      observables.push(this.checkSourceArray(this.sources));
    }
    this.checkStatusSubscription = forkJoin(observables).subscribe((data: Array<Array<WidgetDataSource>>) => {
      const allSources: Array<WidgetDataSource> = [].concat.apply([], data);
      let finalStatus: DataSourceStatus = DataSourceStatus.AVAILABLE;
      allSources.forEach((d: WidgetDataSource) => {
        if (d.status === DataSourceStatus.UNAVAILABLE) {
          finalStatus = DataSourceStatus.UNAVAILABLE;
        }
      });
      this.sourceStatus = finalStatus;
      return this.sourceStatus;
    }, (error: any) => {
      if (error.status) {
        if (error.status !== 200) {
          this.sourceStatus = DataSourceStatus.UNAVAILABLE;
        } else {
          this.sourceStatus = DataSourceStatus.AVAILABLE;
        }
      } else {
        this.sourceStatus = DataSourceStatus.UNAVAILABLE;
      }
    });
  }

  setMainSourcesUnavailable(): void {
    this.sourceStatus = DataSourceStatus.UNAVAILABLE;
    this.sources.forEach((s: WidgetDataSource) => {
      s.status = DataSourceStatus.UNAVAILABLE;
    });
  }

  handleResize(resize: ResizedEvent): void {
    const FONT_RATIO: number = (this.DEFAULT_FONT_HEIGHT / (this.DEFAULT_WIDGET_HEIGHT - this.DEFAULT_BAR_HEIGHT));
    this.minSize = Math.min(resize.newHeight - this.DEFAULT_BAR_HEIGHT, resize.newWidth);
    this.fontSize = Math.floor((this.minSize) * FONT_RATIO).toString();
    this.showDetails = resize.newWidth > 0.3 * window.innerWidth;
  }

  ngOnDestroy(): void {
    if (this.timeMachineSubscription) {
      this.timeMachineSubscription.unsubscribe();
    }
    if (this.pingThresholdsSubscription) {
      this.pingThresholdsSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    if (this.checkStatusSubscription) {
      this.checkStatusSubscription.unsubscribe();
    }
    this.unsubscribeWithTakeUntil();
  }

  unsubscribeWithTakeUntil(): void {
    if (this.stopObservable) {
      this.stopObservable.next();
      this.stopObservable.complete();
    }
  }
}
