<div class="event-control p-3">
  <h6>{{'WIDGETS.EVENT_MAP.PUBLIC_WORKS.TITLE' | translate }}</h6>
  <div class="control__radio d-flex flex-row justify-content-between align-items-center">
    <mat-radio-group class="container" aria-label="Select an option" (ngModelChange)="radioChange($event)" [(ngModel)]="filterValue">
      <div class="row">
        <div class="col-5">
          <mat-radio-button class="control__option"
                            [value]="0">{{'WIDGETS.EVENT_MAP.PUBLIC_WORKS.NOFILTER' | translate }}</mat-radio-button>
        </div>
        <div class="col-5">
          <mat-radio-button class="control__option"
                            [value]="30">
            <div class="d-flex align-items-center">
              <div class="element__color mr-3 yellow"></div>
              {{'WIDGETS.EVENT_MAP.PUBLIC_WORKS.ENDS' | translate }}</div>
          </mat-radio-button>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <mat-radio-button class="control__option"
                            [value]="20">
            <div class="d-flex align-items-center">
              <div class="element__color mr-3 orange"></div>
              {{'WIDGETS.EVENT_MAP.PUBLIC_WORKS.IN_CHARGE' | translate }}</div>
          </mat-radio-button>
        </div>
        <div class="col-5">
          <mat-radio-button class="control__option"
                            [value]="10">
            <div class="d-flex align-items-center">
              <div class="element__color mr-3 green"></div>
              {{'WIDGETS.EVENT_MAP.PUBLIC_WORKS.RECEIPT' | translate }}</div>
          </mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
  </div>
</div>
