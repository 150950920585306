import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-arrivals-widget',
  templateUrl: './arrivals-widget.component.html',
  styleUrls: ['./arrivals-widget.component.scss']
})
export class ArrivalsWidgetComponent extends WidgetComponent {

}
