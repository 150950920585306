import { Directive, Host, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@services/loader/loader.service';
import { Range, RangeManagerService } from '@services/range-manager/range-manager.service';
import { GridService } from '@services/grid/grid.service';
import { StorageService } from '@services/storage/storage.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { AppConfigService } from '@services/app-config/app-config.service';
import { SourcesService } from '@services/sources/sources.service';
import { Subscription } from 'rxjs';
import { TimeMachineData, NoUiSliderRangeTimestamps, TimeMachineSliderRange } from '@app/shared/components/time-machine/models';
import { MenuVoice } from '@app/shared/models/menu/menu-voice';

@Directive()
export class PageDirective implements OnInit, OnDestroy {
  @HostBinding('class.app-page') someClass: Host = true;

  previousTimeMachineValue: TimeMachineData;
  previousRangeTimeSliderValue: NoUiSliderRangeTimestamps;
  timeMachineSubscription: Subscription;
  title: string;
  settings: MenuVoice;
  noPageError: boolean;
  noPermissionsError: boolean;

  currentPage: string;

  constructor(public timeService: TimeMachineService,
              public storageService: StorageService,
              public sourcesService: SourcesService,
              public appConfigService: AppConfigService,
              public gridService: GridService,
              public dialog: MatDialog,
              public sanitizer: DomSanitizer,
              public loaderService: LoaderService,
              public rangeManagerService: RangeManagerService,
              public activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.previousTimeMachineValue = this.timeService.getCurrentSelection();
    this.previousRangeTimeSliderValue = this.timeService.rangeTimeSliderValue;
    this.noPageError = false;
    this.noPermissionsError = false;

    this.settings = this.appConfigService.getPageMenu(this.currentPage);
    if (this.settings) {
      this.timeService.setTimeMachineStatus(this.settings.timeMachine.enabled);
      this.timeService.setAllowFuture(this.settings.timeMachine.allowFuture);
      this.timeService.setAutoRefresh(this.settings.timeMachine.autoRefresh);
      if (this.settings.timeMachine.advanceRangeMode) {
        this.timeService.goInAdvancedRangeMode();
      }
    } else {
      this.noPageError = true;
    }

    if (this.settings && this.settings.timeMachine.defaultRangeKey) {
      this.timeService.showPeriod = this.settings.timeMachine.defaultRange;
      this.timeService.setAdvancedRange(this.settings.timeMachine.defaultRangeKey);
    }
  }

  ngOnDestroy(): void {
    if (this.timeMachineSubscription) {
      this.timeMachineSubscription.unsubscribe();
    }
    this.timeService.setTimeMachineStatus(true);
    this.timeService.setAllowFuture(true);
    this.timeService.setAutoRefresh(true);
    this.restorePreviousTimeMachineValue();
  }

  restorePreviousTimeMachineValue(): void {
    if (this.previousTimeMachineValue) {
      if (typeof this.previousTimeMachineValue === 'number') {
        this.timeService.setTimeFromTimespan(new Date().valueOf());
      } else if (this.previousTimeMachineValue instanceof TimeMachineSliderRange) {
        this.timeService.advancedRangeOn = false;
        this.timeService.updateTimeRange(this.previousRangeTimeSliderValue);
      } else {
        this.timeService.setAdvancedTimeRangeFromMoment(this.previousTimeMachineValue.getFrom(), this.previousTimeMachineValue.getTo());
      }
    }
  }


}
