import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const WATER_FLOW_LEGEND: MapLegend = {
  title: 'WATER_FLOW_TITLE',
  description: 'WATER_FLOW_DESCRIPTION',
  type: 'WATER_FLOW',
  elements: [{
    color: '#e2d4b7',
    label: 'n = 0',
  }, {
    color: 'green',
    label: '0 < n < 5',
  }, {
    color: 'yellow',
    label: '5 < n < 10',
  }, {
    color: 'orange',
    label: '10 < n < 15',
  }, {
    color: 'red',
    label: 'n > 15',
  }],
  additionalData: null,
};

export const ROAD_FLOW_LEGEND: MapLegend = {
  title: 'ROAD_FLOW_TITLE',
  description: 'ROAD_FLOW_DESCRIPTION',
  type: 'ROAD_FLOW',
  elements: [{
    color: '#e2d4b7',
    label: 'n = 0',
  }, {
    color: 'green',
    label: '0 < n < 5',
  }, {
    color: 'yellow',
    label: '5 < n < 10',
  }, {
    color: 'orange',
    label: '10 < n < 15',
  }, {
    color: 'red',
    label: 'n > 15',
  }],
  additionalData: null,
};
