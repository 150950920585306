import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import {
  MobilitySimulatorBaseLayerTypes,
  MobilitySimulatorGate,
  MobilitySimulatorGateLocationCategoryItem,
  MobilitySimulatorGateLocationItem,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-base-data';
import { MobilitySimulatorLayerVisibility } from '@app/modules/widgets/mobility-map-widget/models/mobility-simulator';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { Observable, Subscription, interval } from 'rxjs';
import { MobilitySimulatorCategoryIndexDescriptorExt } from '../models/mobility-widget-data';
import { MapTideControlOptions } from '@app/shared/models/map-tide/map-tide';
@Component({
  selector: 'app-mobility-control',
  templateUrl: './mobility-control.component.html',
  styleUrls: ['./mobility-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobilityControlComponent implements OnInit {

  @Input()
  sliderTideValue: number;

  @Input()
  gates: Array<MobilitySimulatorGate>;

  @Input()
  currentGateId: string;

  @Input()
  timeseries: Array<Date>;

  @Input()
  originalTideValue: number;

  @Input()
  legends: Array<MapLegend>;

  @Input()
  tideSliderEnabled: boolean;

  @Input()
  timeserieLoading: boolean;

  @Input()
  categories: Array<MobilitySimulatorCategoryIndexDescriptorExt>;

  @Input()
  categoriesSelection: Array<MobilitySimulatorCategoryIndexDescriptorExt>;

  @Input()
  viewGates: boolean;

  @Input()
  checkedTypes: Record<MobilitySimulatorBaseLayerTypes, boolean>;

  @Output()
  sliderTideChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  viewGatesChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  gatesChanged: EventEmitter<Array<MobilitySimulatorGate>> = new EventEmitter<Array<MobilitySimulatorGate>>();

  @Output()
  startSimulation: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  simulatorTimeChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  layerVisibilityChanged: EventEmitter<MobilitySimulatorLayerVisibility> = new EventEmitter<MobilitySimulatorLayerVisibility>();

  @Output()
  categoriesSelectionChanged: EventEmitter<Array<MobilitySimulatorCategoryIndexDescriptorExt>> = new EventEmitter<Array<MobilitySimulatorCategoryIndexDescriptorExt>>();

  @Output()
  sliderTideOffsetChanged: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  walkwaysChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  clearSimulation: EventEmitter<void> = new EventEmitter<void>();

  DEFAULT_REFRESH_INTERVAL: number = 500;
  playInterval: Observable<number>;
  playSubscription: Subscription;

  currentGate: MobilitySimulatorGate;
  currentTPM: number;
  sliderTimeserieValue: number;
  currentSimulatorTime: number;
  playing: boolean = false;
  sliderTimeserieSpeedValue: number;
  loop: boolean = false;
  showGates: boolean = false;
  sliderTideOffsetValue: number = 0;
  tideControlOptions: MapTideControlOptions = {
    autoWalkways: true,
    walkwaysReadOnly: false,
    bootsEnabled: false,
    offsetEnabled: true,
    bootsOptions: []
  };

  constructor(public timeMachineService: TimeMachineService, private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.originalTideValue = this.sliderTideValue;
    this.sliderTimeserieSpeedValue = 10;
    this.playInterval = interval(Math.abs(this.sliderTimeserieSpeedValue - 11) * this.DEFAULT_REFRESH_INTERVAL);
    this.showGates = this.viewGates;
  }

  updateTimeserieSpeedValue(event: MatSliderChange): void {
    this.sliderTimeserieSpeedValue = event.value;
    let wasStarted: boolean = false;
    if (this.playSubscription) {
      wasStarted = true;
      this.playSubscription.unsubscribe();
      this.playSubscription = null;
    }

    this.playInterval = interval(Math.abs(this.sliderTimeserieSpeedValue - 11) * this.DEFAULT_REFRESH_INTERVAL);
    if (wasStarted) {
      this.playSubscription = this.playInterval.subscribe(() => { this.playIntervalTick(); });
    }
  }

  playIntervalTick(): void {
    if (this.sliderTimeserieValue < this.timeseries.length - 1) {
      if (!this.timeserieLoading) {
        this.nextTime();
      }
    } else {
      if (this.loop) {
        this.sliderTimeserieValue = 0;
        this.setTimeserieNewValue();
      } else {
        if (this.playSubscription) {
          this.playSubscription.unsubscribe();
          this.playSubscription = null;
          this.playing = false;
        }
      }
    }
  }

  togglePlay(): void {
    if (!this.playing) {
      this.playSubscription = this.playInterval.subscribe(() => { this.playIntervalTick(); });
    } else {
      if (this.playSubscription) {
        this.playSubscription.unsubscribe();
        this.playSubscription = null;
      }
    }
    this.playing = !this.playing;
  }

  previousTime(): void {
    if (this.sliderTimeserieValue >= 1) {
      this.sliderTimeserieValue--;
      this.setTimeserieNewValue();
    }
  }

  nextTime(): void {
    if (this.sliderTimeserieValue < this.timeseries.length - 1) {
      this.sliderTimeserieValue++;
      this.setTimeserieNewValue();
    }
  }

  updateTimeserieValue(event: MatSliderChange): void {
    this.sliderTimeserieValue = event.value;
  }

  setTimeserieNewValue(event?: MatSliderChange): void {
    this.currentSimulatorTime = this.timeseries[this.sliderTimeserieValue].valueOf();
    this.simulatorTimeChanged.emit(this.currentSimulatorTime);
  }

  setViewGatesNewValue(event: MatSlideToggleChange): void {
    this.viewGatesChanged.emit(this.viewGates);
  }

  setCurrentGate(gateId?: string): void {
    if (gateId) {
      this.currentGateId = gateId;
    }
    const gate: MobilitySimulatorGate = this.gates.find((gt: MobilitySimulatorGate) => gt.id === this.currentGateId);
    this.gates.forEach((gt: MobilitySimulatorGate) => { gt.selected = false; });
    gate.selected = true;
    this.currentGate = gate;
    this.gatesChanged.emit(this.gates);
  }

  setCurrentGateTPM(): void {
    this.gatesChanged.emit(this.gates);
  }

  resetCurrentGateTPM(): void {
    this.gatesChanged.emit(this.gates);
  }

  resetGatesTPM(): void {
    this.gates.forEach((gate: MobilitySimulatorGate) => {
      gate.locations.forEach((l: MobilitySimulatorGateLocationItem) => {
        l.categories.forEach((c: MobilitySimulatorGateLocationCategoryItem) => {
          c.valueOverride = null;
        });
      });
    });
    this.gatesChanged.emit(this.gates);
  }

  generateSimulation(): void {
    this.startSimulation.emit();
  }

  visibilityChanged(clickedType: string): void {
    this.layerVisibilityChanged.emit({
      type: clickedType,
      visibile: this.checkedTypes[clickedType]
    });
  }

  initTimeseries(): void {
    this.sliderTimeserieValue = 0;
    this.setTimeserieNewValue();
  }

  onCategoriesSelectionChange(event: Array<MobilitySimulatorCategoryIndexDescriptorExt>): void {
    this.categoriesSelection = event;
    this.categoriesSelectionChanged.emit(this.categoriesSelection);
  }

  onSelectAllCategories(type: string): void {
    if (type === 'all') {
      this.categoriesSelection = this.categories;
    } else {
      this.categoriesSelection = this.categories.filter((c: MobilitySimulatorCategoryIndexDescriptorExt) => c.type === type);
    }
    this.categoriesSelectionChanged.emit(this.categoriesSelection);
  }

  isTypeEnabled(type: string): boolean {
    return !this.categories ? false : this.categories.filter((c: MobilitySimulatorCategoryIndexDescriptorExt) => c.type === type).length > 0;
  }
}
