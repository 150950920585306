import { Injectable } from '@angular/core';
import { AmatConfiguration, Ratio, Ratios, RatioType } from '@app/modules/widgets/amat-widget/model/amat-configuration';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable } from 'rxjs';

@Injectable()
export class AmatWidgetService extends WidgetService {

  private getRatios(configuration: AmatConfiguration, type: RatioType): Ratios {
    const response: any = {};
    Object.entries(configuration.ratios).forEach((key: [string, Ratio], value: number) => {
      if (key[1].type === type) {
        response[key[0]] = key[1].value;
      }
    });
    return response;
  }

  loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData: AmatConfiguration, forecast?: boolean): Observable<AmatResponse> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'amat-data';
    });
    if (widgetDataSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, widgetDataSource);
      this.getRatios(additionalData, RatioType.IN);
      return this.http.post<AmatResponse>(url, {
        period1: '19-Oct',
        period2: '20-Jun',
        ratiosIN: this.getRatios(additionalData, RatioType.IN),
        ratiosOUT: this.getRatios(additionalData, RatioType.OUT),
        tot: additionalData.scenario.total,
        tpl_ratio: additionalData.scenario.tpl_ratio,
        tpl_metro_ratio: additionalData.scenario.tpl_metro_ratio,
        scenario_ratio: additionalData.scenario.scenario_ratio,
      });
    }
  }
}


export interface Students {
  'Studenti Secondarie II TPL metro': Array<number>;
  'Studenti Secondarie II TPL solo superficie': Array<number>;
  'Studenti Secondarie II non TPL': Array<number>;
}

export interface Results {
  'Quota Lavoratori Anelastica': Array<number>;
  'Quota Flessibile': Array<number>;
  'Giovani Lavoratori': Array<number>;
  'Studenti Superiori II Metro': Array<number>;
  'Studenti Universitari': Array<number>;
  'Capacita': Array<number>;
}

export interface AmatResponse {
  times: Array<string>;
  students: Students;
  results: Results;
}
