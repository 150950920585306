import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { Feature, FeatureCollection, MultiPolygon, Point } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, GeoJSONOptions, LatLng, Layer, Marker, MarkerOptions, Popup } from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CulturalEventsService extends MapWidgetService {

  loadDailyEvents(timeMachineData: TimeMachineData,
                        sources: Array<WidgetDataSource>): Observable<FeatureCollection> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.name === 'events-locations-months';
    });
    if (mainSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, mainSource);
      return this.http.get<FeatureCollection>(url);
    }
  }

  addPopupToEvent(f: Feature<Point>, latLng: LatLng): Layer {
    const layer: FeatureGroup = new FeatureGroup();
    let template: string;
    template = `<h6>${f.properties['title']}</h6>`;
    template += `<i>${f.properties['geoTitle']}</i><br/>`;
    template += `${f.properties['date']}`;
    const popup: Popup = new Popup();
    popup.setContent(template);
    const markerOptions: MarkerOptions = {
      icon: new DivIcon({
        className: `events-marker`,
        html: `<div class=\'marker-pin\'></div><i style="color:#f5d209;" class=\'icon-venice_musei'></i>`,
      }),
    };
    const marker: Marker = new Marker(latLng, markerOptions);
    marker.addTo(layer);
    marker.bindPopup(popup);
    marker.on('click', () => {
      popup.openPopup(latLng);
    });
    return layer;
  }

  getMapLevel(timeMachineData: TimeMachineData,
              sources: Array<WidgetDataSource>): Observable<FeatureGroup> {
    return this.loadDailyEvents(timeMachineData, sources).pipe(
      map((dailyEvents: FeatureCollection) => {
        const options: GeoJSONOptions = {
          pointToLayer: this.addPopupToEvent,
        };
        return geoJSON<MultiPolygon>(dailyEvents, options);
      }),
    );
  }
}
