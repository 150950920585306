import {
  AfterViewInit,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  Renderer2,
} from '@angular/core';
import { __ngRendererSetElementAttributeHelper } from '@app/modules/dashboard/ngx-grid-stack/renderer-utils';
import { GridStackItem } from '../models/grid-stack-item.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-grid-stack-item',
  templateUrl: './grid-stack-item.component.html'
})
export class GridStackItemComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('contentPlaceholder', { read: ViewContainerRef, static: true }) contentPlaceholder: ViewContainerRef;
  @Input() contentTemplate: string;
  @Input() option: GridStackItem;
  @Output() GridConfigurationChanged: EventEmitter<GridStackItem> = new EventEmitter<GridStackItem>();

  contentComponentRef: ComponentRef<any> = null;
  jGridRef: any = null;
  public jWidgetRef: any = null;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.jWidgetRef = el.nativeElement;
  }

  get nativeElement(): HTMLElement {
    return this.el.nativeElement;
  }

  ngOnInit(): void {
    this.renderWidget(null);
  }

  updateWidget(item: GridStackItem): void {
  }

  renderWidget(item: GridStackItem): void {
    const renderer: Renderer2 = this.renderer;
    if (item != null) {
      this.option = item;
    }

    __ngRendererSetElementAttributeHelper(this.renderer, this.nativeElement, 'data-gs-x', String(this.option.x));
    __ngRendererSetElementAttributeHelper(this.renderer, this.nativeElement, 'data-gs-y', String(this.option.y));
    __ngRendererSetElementAttributeHelper(this.renderer, this.nativeElement, 'data-gs-width', String(this.option.width));
    __ngRendererSetElementAttributeHelper(this.renderer, this.nativeElement, 'data-gs-height', String(this.option.height));

    if (this.option.minWidth) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-min-width', String(this.option.minWidth));
    }

    if (this.option.maxHeight) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-max-height', String(this.option.maxHeight));
    }

    if (this.option.maxWidth) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-max-width', String(this.option.maxWidth));
    }

    if (this.option.minHeight) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-min-height', String(this.option.minHeight));
    }

    if (this.option.noResize != null && this.option.noResize === true) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-no-resize', true);
    }

    if (this.option.noMove != null && this.option.noMove === true) {
      __ngRendererSetElementAttributeHelper(renderer, this.nativeElement, 'data-gs-no-move', true);
    }
  }

  update(x: number, y: number, width: number, height: number): void {
    if (x === this.option.x && y === this.option.y && width === this.option.width && height === this.option.height) {
      return;
    }

    if (this.option) {
      this.option.x = x;
      this.option.y = y;
      this.option.width = width;
      this.option.height = height;

      const optionNew: GridStackItem = GridStackItem.Clone(this.option);
      this.GridConfigurationChanged.emit(optionNew);
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.contentComponentRef !== null) {
      this.contentComponentRef.destroy();
    }
  }
}
