<div class="control">
  <div class="control-header d-flex flex-row justify-content-between align-items-center p-3">
    <h6>{{ titleLabel | translate }}</h6>
    <mat-icon *ngIf="!show" (click)="toggleControl()">keyboard_arrow_up</mat-icon>
    <mat-icon *ngIf="show" (click)="toggleControl()">keyboard_arrow_down</mat-icon>
  </div>
  <div class="control-body" *ngIf="show">
    <ng-content></ng-content>
    <div class="spinner d-flex justify-content-between align-items-center" *ngIf="loading">
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
  </div>
</div>
