import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { PARKING_LEGEND } from '@app/modules/widgets/parking-widget/data/legend';
import {
  OccupancyStatus,
  ParkingStatus,
  ParkingStatusResponse,
  UserType,
} from '@app/modules/widgets/parking-widget/models/parking-response';
import { TranslateService } from '@ngx-translate/core';
import { Feature, Point } from 'geojson';
import { DivIcon, FeatureGroup, LatLngExpression, Layer, Marker, Tooltip } from 'leaflet';
import { FeatureLayer } from 'esri-leaflet-cluster';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ArcgisMapRequest, ArcgisSourceData } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-map-request';

@Injectable()
export class ParkingWidgetService extends MapWidgetService {

  getIcon(parkingStatus: ParkingStatus): string {
    switch (parkingStatus.userType) {
      case UserType.OCCASIONAL:
        return 'icon-parking_occasional';
      case UserType.DISABLED:
        return 'icon-parking_disabled';
      case UserType.PASS:
        return 'icon-parking_pass';
      case null:
        return 'icon-parking_occasional';
    }
  }

  loadTooltip(parkingStatus: ParkingStatus): Tooltip {
    const translate: TranslateService = this.translateService;
    const tooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [5, -15],
    });
    let template: string = '';
    template += `<b>${translate.instant('MARKERS.PARKING.NUMBER')}:</b> ${parkingStatus.numParkingBay}<br/>`;
    template += `<b>${translate.instant('MARKERS.PARKING.STATUS')}:</b> ${translate.instant('MARKERS.PARKING.STATUS_TEXT.' + parkingStatus.occupancyStatus)}<br/>`;
    template += `<b>${translate.instant('MARKERS.PARKING.IN_VIOLATION')}:</b> ${parkingStatus.inViolation ? translate.instant('COMMONS.YES') : translate.instant('COMMONS.NO')}<br/>`;
    if (parkingStatus.inViolation) {
      template += `<b>${translate.instant('MARKERS.PARKING.VIOLATION_TYPE')}:</b> ${translate.instant('MARKERS.PARKING.VIOLATION_TYPE_TEXT.' + parkingStatus.violationType)}<br/>`;
    }
    template += `<b>${translate.instant('MARKERS.PARKING.AVAILABLE')}:</b> ${parkingStatus.available ? translate.instant('COMMONS.YES') : translate.instant('COMMONS.NO')}<br/>`;
    template += `<b>${translate.instant('MARKERS.PARKING.CHECKED')}:</b> ${parkingStatus.checked ? translate.instant('COMMONS.YES') : translate.instant('COMMONS.NO')}<br/>`;
    template += `<b>${translate.instant('MARKERS.PARKING.OUT_OF_RANGE')}:</b> ${parkingStatus.outOfRange ? translate.instant('COMMONS.NO') : translate.instant('COMMONS.YES')}<br/>`;
    if (parkingStatus.userType) {
      template += `<b>${translate.instant('MARKERS.PARKING.USER_TYPE')}:</b> ${translate.instant('MARKERS.PARKING.USER_TYPE_TEXT.' + parkingStatus.userType)}<br/>`;
    }
    template += `<b>${translate.instant('MARKERS.PARKING.OCCUPANCY_TIMESTAMP')}:</b> ${moment(parkingStatus.occupancyTimestamp).format('LLL')}<br/>`;
    if (parkingStatus.ticketExpirationTimestamp) {
      template += `<b>${translate.instant('MARKERS.PARKING.TICKET_EXPIRATION')}:</b> ${moment(parkingStatus.ticketExpirationTimestamp).format('LLL')}<br/>`;
    }
    template += `<b>${translate.instant('MARKERS.PARKING.UPDATE_TIMESTAMP')}:</b> ${moment(parkingStatus.updateTimestamp).format('LLL')}`;
    return tooltip.setContent(template);
  }

  loadParkingColor(parkingStatus: ParkingStatus): string {
    switch (parkingStatus.occupancyStatus) {
      case OccupancyStatus.FREE:
        return 'green';
      case OccupancyStatus.OCCUPIED:
        return 'red';
      case OccupancyStatus.UNKNOWN:
        return 'blue';
    }
  }

  getParkingStatus(parkingStatuses: ParkingStatusResponse, f: Feature<Point>): ParkingStatus {
    const bayName: string = f.properties['Stallo'];
    const bayId: string = bayName.split(' ')[1];
    return parkingStatuses.status.find((p: ParkingStatus) => {
      return p.numParkingBay === bayId;
    });
  }

  addParkingData(parkingStatuses: ParkingStatusResponse): (f: Feature<Point>, latlng: LatLngExpression) => Layer {
    return (f: Feature<Point>, latlng: LatLngExpression): Layer => {
      const parkingStatus: ParkingStatus = this.getParkingStatus(parkingStatuses, f);
      if (parkingStatus) {
        const dataTooltip: Tooltip = this.loadTooltip(parkingStatus);
        const markerColor: string = this.loadParkingColor(parkingStatus);
        const markerIcon: string = this.getIcon(parkingStatus);
        return new Marker(latlng, {
          icon: new DivIcon({
            className: `parking-marker ${parkingStatus.inViolation ? 'mark--yellow' : ''}`,
            html: `<div class=\'marker-pin\'><i style="background-color:${markerColor};" class="icon-venice ${markerIcon}"></i></div>`,
          }),
        }).bindTooltip(dataTooltip);
      } else {
        const tooltip: Tooltip = new Tooltip({
          direction: 'top',
          offset: [5, -15],
        });
        tooltip.setContent(this.translateService.instant('MARKERS.PARKING.NO_DATA'));
        return new Marker(latlng, {
          icon: new DivIcon({
            className: `parking-marker parking-marker--disabled`,
            html: `<div class=\'marker-pin\'><i style="background-color:grey;" class="icon-venice icon-parking_occasional"></i></div>`,
          }),
        }).bindTooltip(tooltip);
      }
    };
  }


  getParkingStatuses(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>): Observable<ParkingStatusResponse> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'parking-bay-status';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<ParkingStatusResponse>(url);
    }
  }

  createClusterIcon(parkingStatuses: ParkingStatusResponse): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const markers: Array<Marker> = cluster.getAllChildMarkers();
      let busy: number = 0;
      let free: number = 0;
      let hasViolation: boolean = false;
      markers.forEach((m: Marker) => {
        const parkingStatus: ParkingStatus = this.getParkingStatus(parkingStatuses, m.feature);
        if (parkingStatus) {
          if (parkingStatus.occupancyStatus === OccupancyStatus.FREE) {
            free++;
          }
          if (parkingStatus.occupancyStatus === OccupancyStatus.OCCUPIED) {
            busy++;
          }
          if (parkingStatus.inViolation) {
            hasViolation = true;
          }
        }
      });
      return new DivIcon({
        className: `parking-marker marker-group ${hasViolation ? 'mark--yellow' : ''}`,
        html: `<div class="d-flex flex-row justify-content-center align-items-center">
                    <div class="marker-pin d-flex flex-column"><div style="background-color:green;" class=\'icon-venice icon-parking_occasional'\'><span class="marker-group__label">${free}</span></div></div>
                    <div class="marker-pin d-flex flex-column"><div style="background-color:red;" class=\'icon-venice icon-parking_occasional'\'><span class="marker-group__label">${busy}</span></div></div>
               </div>`,
      });
    };
  }

  getLegend(): MapLegend {
    return PARKING_LEGEND;
  }

  getMapLevel(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>, additionalData?: ArcgisMapRequest): Observable<FeatureGroup> {
    return this.getParkingStatuses(timeMachineData, sources).pipe(
      switchMap((parkingStatuses: ParkingStatusResponse) => {
        const featureGroup: FeatureGroup = new FeatureGroup();
        const arcGisSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
          return w.sourceType === 'arcgis';
        });

        let ignore: boolean = true;
        let sourceAdditionalData: any = null;
        if (additionalData) {
          sourceAdditionalData = this.getSourceAdditionalData(additionalData);
          if (sourceAdditionalData && sourceAdditionalData.keepArcgisStyle) {
            ignore = false;
          }
        } else {
          if (arcGisSource.configuration) {
            try {
              sourceAdditionalData = JSON.parse(arcGisSource.configuration);
            } catch (e) {
              sourceAdditionalData = null;
            }

            if (sourceAdditionalData && sourceAdditionalData.sourceData && sourceAdditionalData.sourceData.keepArcgisStyle) {
              ignore = false;
            }
          }
        }

        const level: FeatureLayer = new FeatureLayer({
          url: arcGisSource.rewriteUrl,
          pointToLayer: this.addParkingData(parkingStatuses),
          disableClusteringAtZoom: 19,
          iconCreateFunction: ignore ? this.createClusterIcon(parkingStatuses) : false,
          showCoverageOnHover: false,
          animate: false,
          spiderfy: false,
          spiderfyOnMaxZoom: false,
          zoomToBoundsOnClick: true,
          maxClusterRadius: 120,
          ignoreRenderer: ignore,
        });
        featureGroup.addLayer(level);
        return of(featureGroup);
      }),
    );
  }

  getSourceAdditionalData(additionalData: ArcgisMapRequest): ArcgisSourceData | null {
    let sourceAdditionalData: any = null;
    if (additionalData && additionalData.levelInfo && additionalData.levelInfo.overrideServer) {
      try {
        sourceAdditionalData = additionalData.levelInfo.additionalData ? JSON.parse(additionalData.levelInfo.additionalData.sourceData) : {};
      } catch (e) {
        sourceAdditionalData = null;
      }
    }
    return sourceAdditionalData;
  }

  checkSource(source: WidgetDataSource): Observable<any> {
    const url: string = this.addTimeMachineDataToUrl(moment().valueOf(), source);
    if (source.rewriteUrl.includes('arcgis')) {
      return this.http.get<any>(url + '?f=pjson');
    } else {
      return this.http.get<any>(url);
    }
  }
}
