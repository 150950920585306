import {
  Component,
  EventEmitter,
  Host,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { BasePageConfig, NanocubeConfig, NanocubePageConfig } from '@app/shared/models/page-config-models';
import { Range, RangeManagerService } from '@services/range-manager/range-manager.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { AppConfigService } from '@services/app-config/app-config.service';
import { SourcesService } from '@services/sources/sources.service';
import moment, { Moment } from 'moment';
import { Subscription } from 'rxjs';
import { LoaderService } from '@services/loader/loader.service';
import { MenuType, SubMenu } from '@app/shared/models/menu/menu-voice';

@Component({
  selector: 'app-page-menu-bar',
  templateUrl: './page-menu-bar.component.html',
  styleUrls: ['./page-menu-bar.component.scss'],
})
export class PageMenuBarComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.page-menu-bar') pageMenuBarClass: Host = true;

  @Input()
  menuKey: string;

  @Input()
  pageLabel: string;

  @Output()
  tabActivated: EventEmitter<SubMenu> = new EventEmitter<SubMenu>();

  menu: Array<SubMenu>;
  activeTab: SubMenu;
  safeUrl: SafeResourceUrl;
  dashboardPage: string;
  loaded: boolean = true;
  showIframeError: boolean = false;
  timeMachineSubscription: Subscription;
  error: boolean = false;
  errorType: string;

  constructor(private appConfigService: AppConfigService,
              private sourcesService: SourcesService,
              private widgetService: WidgetService,
              private timeService: TimeMachineService,
              public loaderService: LoaderService,
              public rangeManagerService: RangeManagerService,
              public themeService: ThemeService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.initComponent();
    this.themeService.sendReload.subscribe(() => {
      this.initComponent();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuKey && !changes.menuKey.isFirstChange()) {
      this.loaderService.show();
      this.initComponent();
    }
  }

  initComponent(): void {
    this.loaded = true;
    this.menu = this.appConfigService.subMenu(this.menuKey);
    this.activeTab = this.menu.find((u: SubMenu) => {
      return u.active;
    });
    if (this.activeTab) {
      this.error = false;
      this.setPage(this.activeTab);
      this.timeMachineSubscription = this.timeService.timeMachineChanged.subscribe((newValue: TimeMachineData) => {
        if (this.activeTab && this.activeTab.menuType === MenuType.URL) {
          this.error = false;
          this.loaderService.show();
          this.loaded = true;
          this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUpdatedUrl(this.activeTab));
          this.loaderService.hide();
        }
      });
    } else {
      this.error = true;
      this.errorType = 'NOT_ACTIVE_TAB';
    }
  }

  getUpdatedUrl(activeTab: SubMenu): string {
    const dataSource: WidgetDataSource = this.sourcesService.loadSourceData(activeTab.sourceName);
    if (dataSource) {
      return this.widgetService.addTimeMachineDataToUrl(this.timeService.getCurrentSelection(), dataSource);
    }
  }

  setPage(button: SubMenu): void {
    if (button.timeMachine) {
      this.timeService.setTimeMachineStatus(button.timeMachine.enabled);
      this.timeService.showPeriod = button.timeMachine.defaultRange;

      if (button.timeMachine.defaultRangeKey) {
        this.timeService.setAdvancedRange(button.timeMachine.defaultRangeKey);
      } else {
        this.timeService.rangeOn = button.timeMachine.advanceRangeMode;
        this.timeService.showAdvancedRange = button.timeMachine.advanceRangeMode;
        this.timeService.advancedRangeOn = button.timeMachine.defaultRange;
        this.timeService.onNowClick();
      }
    }
    if (button.enabled) {
      this.error = false;
      this.activeTab.active = false;
      this.activeTab = button;
      this.activeTab.active = true;
      this.tabActivated.emit(this.activeTab);
      if (this.activeTab.menuType === MenuType.URL) {
        this.loaded = true;
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getUpdatedUrl(this.activeTab));
      }
      if (this.activeTab.menuType === MenuType.DASHBOARD) {
        this.dashboardPage = this.activeTab.dashboardId;
      }
      if (this.activeTab.menuType === MenuType.NANOCUBE) {
        const url: string = this.getNanocubeUrl(button.sourceName, JSON.parse(button.pageConfig));
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    } else {
      this.error = true;
      this.errorType = 'TAB_NOT_ENABLED';
    }
  }

  getNanocubeUrl(sourceName: string, pageConfig: BasePageConfig): string {
    const source: WidgetDataSource = this.sourcesService.loadSourceData(sourceName);
    if (source) {
      let url: string = source.rewriteUrl;
      if ((pageConfig as NanocubePageConfig).nanocube && (pageConfig as NanocubePageConfig).nanocube.timeseries) {
        const nanoPageConfig: NanocubeConfig = (pageConfig as NanocubePageConfig).nanocube;
        const format: string = 'YYYY-MM-DD[T]HH:mm:ss.000[Z]';

        const now: Moment = moment(new Date());

        let start: Moment;
        let end: Moment;
        if (nanoPageConfig.timeseries.type === 'future') {
          start = moment(now);
          if (nanoPageConfig.timeseries.brush && nanoPageConfig.timeseries.brush.enabled) {
            if (nanoPageConfig.timeseries.brush.interval.unit === 'hours' || nanoPageConfig.timeseries.brush.interval.unit === 'hour') {
              let h: number = now.hour();
              h = (nanoPageConfig.timeseries.brush.interval.value * Math.round(h / nanoPageConfig.timeseries.brush.interval.value)) % 24;
              start = moment(now.set({
                hours: h,
                minutes: 0,
                seconds: 0,
                milliseconds: 0,
              }));
            } else if (nanoPageConfig.timeseries.brush.interval.unit === 'minutes' || nanoPageConfig.timeseries.brush.interval.unit === 'minute') {
              let m: number = now.minute();
              m = (nanoPageConfig.timeseries.brush.interval.value * Math.round(m / nanoPageConfig.timeseries.brush.interval.value)) % 60;
              start = moment(now.set({
                minutes: m,
                seconds: 0,
                milliseconds: 0,
              }));
            } else if (nanoPageConfig.timeseries.brush.interval.unit === 'second' || nanoPageConfig.timeseries.brush.interval.unit === 'seconds') {
              let s: number = now.second();
              s = (nanoPageConfig.timeseries.brush.interval.value * Math.round(s / nanoPageConfig.timeseries.brush.interval.value)) % 60;
              start = moment(now.set({
                seconds: s,
                milliseconds: 0,
              }));
            }
          }
          end = moment(start).add(nanoPageConfig.timeseries.interval.value, nanoPageConfig.timeseries.interval.unit);
        } else {
          start = moment(now).subtract(nanoPageConfig.timeseries.interval.value, nanoPageConfig.timeseries.interval.unit).startOf(nanoPageConfig.timeseries.interval.unit);
          end = moment(now);
        }

        url += '&time={"global":{"start":"' +
          start.format(format) + '", "end":"' +
          end.format(format) + '"}';

        if (nanoPageConfig.timeseries.brush && nanoPageConfig.timeseries.brush.enabled) {
          let startBrush: Moment;
          let endBrush: Moment;

          if (nanoPageConfig.timeseries.type === 'future') {
            startBrush = moment(start);
            endBrush = moment(start).add(nanoPageConfig.timeseries.brush.interval.value, nanoPageConfig.timeseries.brush.interval.unit);
          } else {
            startBrush = moment(new Date()).startOf('hour')
              .subtract(nanoPageConfig.timeseries.brush.interval.value, nanoPageConfig.timeseries.brush.interval.unit);
            endBrush = moment(new Date()).startOf('hour');
          }

          url += ',"brush":{"start":"' +
            startBrush.format(format) + '","end":"' +
            endBrush.format(format) + '"}}';
        }
      }
      return url;
    }
  }

  uploadDone(): void {
    this.loaded = false;
    this.loaderService.hide();
    this.showIframeError = false;
  }

  iframeError(): void {
    this.loaderService.hide();
    this.showIframeError = true;
  }

  ngOnDestroy(): void {
    if (this.timeMachineSubscription) {
      this.timeMachineSubscription.unsubscribe();
    }
    this.timeService.setTimeMachineStatus(true);
  }
}
