import { AfterViewInit, Component, DoCheck, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { GeoserverOlWidgetService } from '@app/modules/widgets/geoserver-ol-widget/geoserver-ol-widget.service';
import { GeoserverWidgetData } from '@app/modules/widgets/geoserver-widget/models/geoserver-widget-data';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { View } from 'ol';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import Map from 'ol/Map';
import * as Proj from 'ol/proj';
import { OSM } from 'ol/source';

@Component({
  selector: 'app-geoserver-ol-widget',
  templateUrl: './geoserver-ol-widget.component.html',
  styleUrls: ['./geoserver-ol-widget.component.scss'],
})
export class GeoserverOlWidgetComponent extends WidgetComponent implements OnInit, DoCheck, AfterViewInit {
  @ViewChild('mapElement', { static: false }) mapElement: ElementRef;

  layer: TileLayer<any>;

  @Input()
  public data: GeoserverWidgetData;

  public map: Map;
  view: View;

  layers: LayerGroup;

  legend: string;

  constructor(public widgetService: GeoserverOlWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngDoCheck(): void {
    if (this.map) {
      this.map.updateSize();
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.view = new View({
      center: Proj.fromLonLat([this.data.mapConfig.center.lon, this.data.mapConfig.center.lat]),
      zoom: this.data.mapConfig.zoom,
    });
    this.map = new Map({
      target: this.mapElement.nativeElement,
      layers: [new TileLayer({
        source: new OSM(),
      })],
      view: this.view,
      controls: [],
    });
    this.map.updateSize();
    this.map.once('postrender', (event: any) => {
      super.ngOnInit();
      this.checkSources();
    });
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.legend = this.widgetService.getLegend(timeMachineData, this.sources);
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources).subscribe((layers: LayerGroup) => {
      this.isLoading = false;
      this.map.addLayer(layers);
      if (this.layers) {
        this.map.removeLayer(this.layers);
      }
      this.layers = layers;
      this.isLoading = false;
    });
  }
}
