import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoFeedService } from '@app/modules/widgets/video-feed-widget/video-feed.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { VgBufferingModule, VgControlsModule, VgCoreModule, VgOverlayPlayModule, VgStreamingModule } from 'ngx-videogular';
import { VideoFeedWidgetComponent } from './video-feed-widget.component';

@NgModule({
  declarations: [VideoFeedWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
  ],
  providers: [VideoFeedService],
  exports: [VideoFeedWidgetComponent],
})
export class VideoFeedWidgetModule {
}
