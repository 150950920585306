<h1 mat-dialog-title>{{'MENU.MODALS.DELETE.TITLE' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'MENU.MODALS.DELETE.TEXT' | translate}}</p>
  <p *ngIf="activeDashboard">{{'MENU.MODALS.DELETE.TEXT_2' | translate:textArgs}}</p>
  <mat-selection-list [multiple]="false" #dashboards [(ngModel)]="dashboardSelected"
                      (ngModelChange)="updateForm($event)">
    <mat-list-option *ngFor="let dashboard of dashboardItems" [value]="dashboard"
                     [disabled]="dashboard === activeDashboard"
                     [ngClass]="{'cursor-not-allowed': dashboard===activeDashboard}">
      {{dashboard}}
    </mat-list-option>
  </mat-selection-list>
  <br>
  <br>
  <hr/>
  <div class="d-flex flex-row justify-content-end align-items-stretch">
    <div [hidden]="!needOverwrite">
      <mat-slide-toggle [(ngModel)]="confirmOverwrite">{{'MENU.MODALS.DELETE.CONFIRM' | translate}}</mat-slide-toggle>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button
          [mat-dialog-close]="false">{{'MENU.MODALS.DELETE.CANCEL' | translate}}</button>
  <button mat-raised-button [color]="'warn'" [disabled]="needOverwrite && !confirmOverwrite"
          [mat-dialog-close]="result"
          cdkFocusInitial>{{'MENU.MODALS.DELETE.OK' | translate}}</button>
</div>
