import { Component, Input } from '@angular/core';
import { MapLegend, MapLegendElement } from '@app/shared/models/map-legend/map-legend';

@Component({
  selector: 'app-map-legend',
  templateUrl: './map-legend.component.html',
  styleUrls: ['./map-legend.component.scss'],
})
export class MapLegendComponent {

  @Input()
  hiddenCondition: boolean;

  @Input()
  showCondition: boolean;

  @Input()
  legends: Array<MapLegend>;

  getGradient(legend: MapLegend): string {
    let gradient: string = `linear-gradient(`;
    legend.elements.forEach((e: MapLegendElement, idx: number, array: Array<MapLegendElement>) => {
      gradient = gradient += `${e.color} ${e.position * 100}%`;
      if (idx !== array.length - 1) {
        gradient += `, `;
      }
    });
    gradient += `)`;
    return gradient;
  }

  getFirst(legend: MapLegend): string {
    return legend.elements[0].label;
  }

  getLast(legend: MapLegend): string {
    return legend.elements[legend.elements.length - 1].label;
  }
}
