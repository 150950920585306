import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppConfigService } from '@services/app-config/app-config.service';
import { GridService } from '@services/grid/grid.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { interval, Subscription } from 'rxjs';
import { version } from '../../../../../../package.json';
import { AlertsService } from '@services/alerts/alerts.service';
import { LoaderService } from '@services/loader/loader.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { MenuVoice } from '@app/shared/models/menu/menu-voice';
import { ChangelogsService } from '@services/changelogs/changelogs.service';
import { Changelog, ChangelogType, Notes } from '@app/shared/models/changelogs/changelog';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Output()
  popOverShown: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  popOverHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  public version: string = version;

  menuItems: Array<MenuVoice>;
  notes: Array<Notes>;
  date: string;
  hideDashboardSettings: boolean;
  hideDashboardSubscription: Subscription;
  alertColor: string;
  isScr: boolean;
  showAlerts: boolean;

  constructor(
    private appConfigService: AppConfigService,
    private dashboardService: GridService,
    public loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    public timeMachineService: TimeMachineService,
    private changelogsService: ChangelogsService,
    private alertsService: AlertsService) {
  }

  getChangelogType(changelogs: Array<Changelog>, type: ChangelogType): Notes {
    return {
      key: type,
      values: changelogs.filter((c: Changelog) => {
        return c.type === type;
      }),
    };
  }

  ngOnInit(): void {
    this.hideDashboardSettings = false;
    this.alertColor = '#000000';

    this.menuItems = this.appConfigService.getMenu();

    this.changelogsService.getChangelog(version).subscribe((changelogs: Array<Changelog>) => {
      this.notes = Object.values(ChangelogType).map((c: ChangelogType) => {
        return this.getChangelogType(changelogs, c);
      });
    });
    this.date = this.appConfigService.getOptions().lastEditDate;
    this.showAlerts = this.appConfigService.getOptions().showAlerts;
    this.isScr = this.authenticationService.getDomain() === 'scr';
    this.hideDashboardSubscription = this.dashboardService.hideDashboardSettings.subscribe((hide: boolean) => {
      this.hideDashboardSettings = hide;
    });

    this.handleAlertRefresh();
    this.alertsService.checkForNewAlerts();
  }

  ngOnDestroy(): void {
    if (this.hideDashboardSubscription) {
      this.hideDashboardSubscription.unsubscribe();
    }
  }

  handleAlertRefresh(): void {
    this.alertsService.newAlerts.subscribe((newAlerts: boolean) => {
      if (newAlerts) {
        this.alertColor = '#ed6f45';
      } else {
        this.alertColor = '#000000';
      }
    });
    interval(60000).subscribe(() => {
      this.alertsService.checkForNewAlerts();
    });
  }

  recordShown(): void {
    this.popOverShown.emit(true);
  }

  recordHidden(): void {
    this.popOverHidden.emit(false);
  }
}
