<h1 *ngIf="data" mat-dialog-title>{{data.imageTitle}}</h1>
<div mat-dialog-content #container>
  <div class="pan-to-zoom-container">
    <pan-zoom [config]="panzoomConfig" class="pan-zoom">
      <div class="image-container" >
        <img [src]="imageUrl" #image (load)="onImageLoad()">
      </div>
    </pan-zoom>
    <div class="controls d-flex flex-column">
      <button class="btn btn-primary mb-2" (click)="center()" >
        <mat-icon>filter_center_focus</mat-icon>
      </button>
      <button class="btn btn-primary mb-2" (click)="zoomIn()" >
        <mat-icon>zoom_in</mat-icon>
      </button>
      <button class="btn btn-primary mb-2" (click)="zoomOut()" >
        <mat-icon>zoom_out</mat-icon>
      </button>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>{{'HOME.CLOSE' | translate | uppercase }}</button>
</div>
