import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { TrafficImageData, TrafficImageService } from '@app/modules/widgets/traffic-image-widget/traffic-image.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';

@Component({
  selector: 'app-traffic-image-widget',
  templateUrl: './traffic-image-widget.component.html',
  styleUrls: ['./traffic-image-widget.component.scss'],
})
export class TrafficImageWidgetComponent extends WidgetComponent implements OnInit {
  @ViewChild('base', { static: false }) baseMapElement: ElementRef;
  @ViewChild('animation', { static: false }) animationElement: ElementRef;

  backgroundImageUrl: string;
  trafficImageUrl: string;

  constructor(public widgetService: TrafficImageService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((data: TrafficImageData) => {
      this.isLoading = false;
      this.backgroundImageUrl = data.base;
      this.trafficImageUrl = data.animation + '?ver=' + new Date().getTime();
    });
  }

}
