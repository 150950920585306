import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Host,
  HostBinding,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GridStackItemComponent } from '@app/modules/dashboard/ngx-grid-stack/grid-stack-item/grid-stack-item.component';
import { GridStackComponent } from '@app/modules/dashboard/ngx-grid-stack/grid-stack/grid-stack.component';
import { AddWidgetModalComponent } from '@app/shared/components/modals/dashboard-modals/add-widget-modal/add-widget-modal.component';
import { DeleteModalComponent } from '@app/shared/components/modals/dashboard-modals/delete-modal/delete-modal.component';
import { EditDataModalComponent } from '@app/shared/components/modals/dashboard-modals/edit-data-modal/edit-data-modal.component';
import { LoadModalComponent } from '@app/shared/components/modals/dashboard-modals/load-modal/load-modal.component';
import {
  RemoveWidgetModalComponent,
} from '@app/shared/components/modals/dashboard-modals/remove-widget-modal/remove-widget-modal.component';
import { SaveModalComponent } from '@app/shared/components/modals/dashboard-modals/save-modal/save-modal.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { SuccessComponent } from '@app/shared/components/modals/global-modals/message-modals/success/success.component';
import { LoadDashboardResponse } from '@app/shared/models/dashboard/dashboard';
import { DashboardItem } from '@app/shared/models/dashboard/dashboard-item';
import { Grid, GridInstance, GridOptions, SerializedGridInstance, SerializedWidget } from '@app/shared/models/dashboard/serialized-grid';
import { InsertResponse, UpdateResponse, UpsertResponse } from '@app/shared/models/venice-data-lake/update-response';
import { WidgetModel } from '@app/shared/models/widgets/widget-model';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { DashboardService } from '@services/dashboard/dashboard.service';
import { GridService } from '@services/grid/grid.service';
import { LoaderService } from '@services/loader/loader.service';
import { StorageService } from '@services/storage/storage.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { WidgetListService } from '@services/widget-list/widget-list.service';
import { ResizedEvent } from 'angular-resize-event';
import { GridstackOptions } from 'gridstack';
import { cloneDeep } from 'lodash';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {

  private defaultDashboard: Grid;

  @ViewChildren(GridStackItemComponent) items: QueryList<GridStackItemComponent>;
  @ViewChild('gridStackMain') grid: GridStackComponent;
  @HostBinding('class.app-dashboard') someClass: Host = true;

  @Input()
  page: string;

  public deviceInfo: DeviceInfo = null;
  public isMobilevar: boolean = false;
  public isTabletvar: boolean = false;
  public isDesktopvar: boolean = false;
  public widgets: Array<DashboardItem>;
  public originalWidgets: Array<DashboardItem>;
  public defaultWidgets: Array<WidgetModel>;
  public options: GridstackOptions;
  public defaultOptions: GridstackOptions;
  public currentOptions: GridOptions;

  clearGridSubscription: Subscription;
  addWidgetSubscription: Subscription;
  editableGridSubscription: Subscription;
  saveGridSubscription: Subscription;
  loadGridSubscription: Subscription;
  removeGridSubscription: Subscription;

  public isGridEditable: boolean = false;
  public isFullscreen: boolean = false;
  public isScrollable: boolean = false;

  public gridLoaded: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public gridService: GridService,
    private el: ElementRef,
    private deviceService: DeviceDetectorService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private appConfigService: AppConfigService,
    private cd: ChangeDetectorRef,
    private storageService: StorageService,
    public loaderService: LoaderService,
    private widgetListService: WidgetListService,
    private timeMachineService: TimeMachineService,
    private dashboardService: DashboardService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
  ) {
    this.widgets = [];
    this.options = {
      disableResize: true,
      disableDrag: true,
      animate: true,
      float: true,
      column: 12,
      minWidth: 1920,
      disableOneColumnMode: false,
      alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    };
    this.currentOptions = {
      rows: 12,
      columns: 12,
      scrollable: false,
    };
  }

  private loadOrderedWidgets(widgets: Array<DashboardItem>): void {
    widgets.sort((a: DashboardItem, b: DashboardItem) => {
      if (a.y < b.y) {
        return -1;
      }
      if (a.y > b.y) {
        return 1;
      }
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return -1;
      }
      return 0;
    });
    this.widgets = widgets;
  }

  private getDashboardItem(w: SerializedWidget): DashboardItem {
    if (this.defaultWidgets) {
      const dashboardItem: DashboardItem = new DashboardItem();
      const originalWidget: WidgetModel = this.defaultWidgets.find((ow: WidgetModel) => {
        return ow.name === w.name;
      });
      if (originalWidget) {
        const configuration: any = JSON.parse(originalWidget.configuration);
        if (!w.title) {
          w.title = originalWidget.title;
        }
        if (!w.data) {
          w.data = configuration;
        }
        if (!w.data['sources']) {
          w.data['sources'] = configuration['sources'];
        }
        w.data = cloneDeep(Object.assign(configuration, w.data));
        dashboardItem.setData(w);
      }
      return dashboardItem;
    }
  }

  private serializeDashboard(widgets: Array<SerializedWidget>): Array<DashboardItem> {
    this.clearGrid();
    return widgets.map((w: SerializedWidget) => {
      return this.getDashboardItem(w);
    });
  }

  private loadDefaultDashboard(): GridInstance {
    if (this.defaultDashboard[this.page]) {
      const loadedPage: SerializedGridInstance = cloneDeep(this.defaultDashboard[this.page]);
      return {
        options: loadedPage.options,
        widgets: this.serializeDashboard(loadedPage.widgets),
      };
    } else {
      return {
        options: this.currentOptions,
        widgets: [],
      };
    }
  }

  loadGridOptions(): void {
    const height: number = this.el.nativeElement.offsetHeight;
    this.options.cellHeight = Math.round(height / this.currentOptions.rows || 12);
  }

  handleResize(resize: ResizedEvent): void {
    const height: number = resize.newHeight;
    this.options.cellHeight = Math.round(height / this.currentOptions.rows || 12);
    this.grid.updateGrid(this.options.cellHeight);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page && !changes.page.isFirstChange()) {
      this.loadSavedDashboard(changes.page.currentValue);
    }
  }

  ngOnInit(): void {
    this.loadGridOptions();
    this.timeMachineService.toggleLabel(true);
    this.gridService.hideDashboardSettings.emit(false);

    if (!this.page) {
      this.page = 'home';
    }

    this.detectDevice();
    this.isDesktop();
    this.isMobile();
    this.isTablet();

    this.gridService.gridReady.emit();
    this.cd.detectChanges();

    this.clearGridSubscription = this.gridService.clearGrid.subscribe(() => {
      this.clearGrid();
    });
    this.addWidgetSubscription = this.gridService.addWidget.subscribe(() => {
      this.addNewWidget();
    });
    this.editableGridSubscription = this.gridService.gridEditable.subscribe((status: boolean) => {
      this.toggleEditableGrid(status);
    });
    this.saveGridSubscription = this.gridService.saveGrid.subscribe(() => {
      this.saveGrid();
    });
    this.loadGridSubscription = this.gridService.loadGrid.subscribe(() => {
      this.loadGrid();
    });
    this.removeGridSubscription = this.gridService.removeGrid.subscribe(() => {
      this.removeGrid();
    });
  }

  ngOnDestroy(): void {
    if (this.clearGridSubscription) {
      this.clearGridSubscription.unsubscribe();
    }
    if (this.addWidgetSubscription) {
      this.addWidgetSubscription.unsubscribe();
    }
    if (this.editableGridSubscription) {
      this.editableGridSubscription.unsubscribe();
    }
    if (this.saveGridSubscription) {
      this.saveGridSubscription.unsubscribe();
    }
    if (this.loadGridSubscription) {
      this.loadGridSubscription.unsubscribe();
    }
    if (this.removeGridSubscription) {
      this.removeGridSubscription.unsubscribe();
    }
    this.clearGrid();
    this.timeMachineService.toggleLabel(true);
    this.gridService.hideDashboardSettings.emit(true);
  }

  ngAfterViewInit(): void {
    this.grid.reloadGrid();
    this.loadSavedDashboard(this.page);
  }

  loadSavedDashboard(dashboard: string): void {
    const loadDashboard$: Observable<SerializedGridInstance> = this.storageService.loadDashboard(dashboard);
    const widgetModels$: Observable<Array<WidgetModel>> = this.widgetListService.getWidgetModels();
    const defaultDashboard$: Observable<LoadDashboardResponse> = this.dashboardService.getDefaultDashboard();

    this.loaderService.show();
    forkJoin([loadDashboard$, widgetModels$, defaultDashboard$]).subscribe((results: [SerializedGridInstance, Array<WidgetModel>, LoadDashboardResponse]) => {
      this.defaultWidgets = results[1];
      this.defaultDashboard = JSON.parse(results[2].configuration);
      const defaultDashboard: GridInstance = this.loadDefaultDashboard();
      this.originalWidgets = cloneDeep(defaultDashboard.widgets);
      let dashboardItems: Array<SerializedWidget>;
      let dashboardOptions: GridOptions;
      if (results[0]) {
        dashboardItems = results[0].widgets;
        dashboardOptions = results[0].options;
      }
      let widgets: Array<DashboardItem> = [];
      if (dashboardItems) {
        widgets = dashboardItems.map((w: SerializedWidget) => {
          return this.getDashboardItem(w);
        });
      } else {
        widgets = cloneDeep(this.originalWidgets);
      }
      if (dashboardOptions) {
        this.currentOptions = dashboardOptions;
      } else {
        this.currentOptions = defaultDashboard.options;
      }
      this.processOptions();
      this.loadOrderedWidgets(this.processLoadedWidgets(widgets, this.defaultWidgets));
      this.processDashboardLoading();
      this.loaderService.hide();
    }, (error: any) => {
      const defaultDashboard: GridInstance = this.loadDefaultDashboard();
      this.originalWidgets = cloneDeep(defaultDashboard.widgets);
      this.currentOptions = defaultDashboard.options;
      this.processOptions();
      this.loadOrderedWidgets(this.originalWidgets);
      this.processDashboardLoading();
      this.loaderService.hide();
    });
  }

  processDashboardLoading(): void {
    this.widgets = this.widgets.filter((w: DashboardItem) => {
      return this.checkWidgetPermission(w);
    });
    this.gridService.gridReady.emit();
    this.grid.makeWidgets();
  }

  checkWidgetPermission(w: DashboardItem | WidgetModel): boolean {
    let hasPermission: boolean = false;
    if (w.data.roles && w.data.roles.length) {
      w.data.roles.forEach((role: string) => {
        hasPermission = hasPermission || this.authenticationService.hasRole(role);
      });
    }
    if (w.data.privileges && w.data.privileges.length) {
      w.data.privileges.forEach((privilege: string) => {
        hasPermission = hasPermission || this.authenticationService.hasPrivilege(privilege);
      });
    }
    return hasPermission;
  }

  processOptions(): void {
    this.options.column = this.currentOptions.columns || 12;
    this.options.row = this.currentOptions.rows || 12;
    this.isScrollable = this.currentOptions.scrollable || false;
    this.loadGridOptions();
    this.options = cloneDeep(this.options);
    this.grid.updateGrid(Number(this.options.cellHeight));
    this.grid.setColumn(this.options.column);
  }

  processLoadedWidgets(widgets: Array<DashboardItem>, widgetModels: Array<WidgetModel>): Array<DashboardItem> {
    widgets.forEach((d: DashboardItem) => {
      const widgetModel: WidgetModel = widgetModels.find((m: WidgetModel) => {
        return d.name === m.name;
      });
      if (widgetModel) {
        d.alarms = widgetModel.alarms;
        d.configuration = widgetModel.configuration;
        d.thresholds = widgetModel.thresholds;
      }
    });
    return widgets;
  }

  toggleEditableGrid(status: boolean): void {
    this.isGridEditable = status;
    this.grid.checkResize(this.isGridEditable);
  }

  /**
   *
   * Adds a widget on the dashboard
   *
   */
  addNewWidget(): void {
    this.loaderService.show();
    this.widgetListService.getWidgetModels().subscribe((w: Array<WidgetModel>) => {
      this.loaderService.hide();
      const widgetList: Array<WidgetModel> = w.filter((wModel: WidgetModel) => {
        if (wModel.configuration) {
          wModel.data = JSON.parse(wModel.configuration);
          return this.checkWidgetPermission(wModel);
        }
      });
      const dialogRef: MatDialogRef<AddWidgetModalComponent, WidgetModel> = this.dialog.open(AddWidgetModalComponent, {
        data: widgetList,
      });
      dialogRef.afterClosed().subscribe((result: WidgetModel) => {
        try {
          if (this.grid) {
            const serializedWidget: DashboardItem = new DashboardItem();
            serializedWidget.initFromDefault(result);
            this.widgets.push(serializedWidget);
            this.cd.detectChanges();
            const arr: Array<GridStackItemComponent> = this.items.toArray();
            const newElement: GridStackItemComponent = arr[this.items.length - 1];
            this.grid.addWidget(newElement);
          }
        } catch (e) {
          throw new Error('Can\'t add a new Widget');
        }
      });
    }, (error: any) => {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    });
  }

  removeWidget(index: number): void {
    const dialogRef: MatDialogRef<RemoveWidgetModalComponent, boolean> = this.dialog.open(RemoveWidgetModalComponent, {
      data: this.widgets[index],
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.widgets.splice(index, 1);
        const arr: Array<GridStackItemComponent> = this.items.toArray();
        const item: GridStackItemComponent = arr[index];
        this.grid.removeWidget(item);
        this.cd.detectChanges();
      }
    });
  }

  clearGrid(): void {
    this.widgets = [];
  }

  serializeWidget(): Array<SerializedWidget> {
    return this.widgets.map((node: DashboardItem) => {
      return node.export();
    });
  }

  saveGrid(): void {
    this.loaderService.show();
    this.storageService.getAvailableDashboards().subscribe((availableDashboards: Array<string>) => {
      const gridData: Array<SerializedWidget> = this.serializeWidget();
      const saveGrid: SerializedGridInstance = {
        widgets: gridData,
        options: this.currentOptions,
      };
      this.loaderService.hide();
      const dialogRef: MatDialogRef<SaveModalComponent, string> = this.dialog.open(SaveModalComponent, {
        data: {
          currentDashboard: this.storageService.currentDashboard,
          availableDashboards: availableDashboards,
        },
      });
      dialogRef.afterClosed().pipe(switchMap((result: string) => {
        if (result) {
          this.loaderService.show();
          return this.storageService.save(result, saveGrid, this.page);
        }
      })).subscribe(() => {
        this.loaderService.hide();
        this.dialog.open(SuccessComponent, {});
        this.loaderService.hide();
      }, (error: any) => {
        this.dialog.open(FailureComponent, {});
        this.loaderService.hide();
      });
    }, (error: any) => {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    });
  }

  loadDashboard(dashboardName: string): void {
    this.loaderService.show();
    this.storageService.setDashboard(dashboardName, this.page);
    this.clearGrid();
    this.loadSavedDashboard(this.page);
    const gridLoadSubscription: Subscription = this.gridService.gridReady.subscribe(() => {
      gridLoadSubscription.unsubscribe();
      this.dialog.open(SuccessComponent, {});
      this.loaderService.hide();
    }, (e: any) => {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    });
  }

  loadGrid(): void {
    try {
      this.loaderService.show();
      this.storageService.getAvailableDashboards().subscribe((availableDashboards: Array<string>) => {
        const dialogRef: MatDialogRef<LoadModalComponent, string> = this.dialog.open(LoadModalComponent, {
          data: {
            currentDashboard: this.storageService.currentDashboard,
            availableDashboards: availableDashboards,
          },
        });
        this.loaderService.hide();
        dialogRef.afterClosed().subscribe((result: string) => {
          if (result) {
            this.loadDashboard(result);
          } else if (result === null) {
            this.storageService.cleanCurrentDashboard();
            this.currentOptions = this.loadDefaultDashboard().options;
            this.processOptions();
            this.loadOrderedWidgets(this.loadDefaultDashboard().widgets);
            this.processDashboardLoading();
          }
          this.gridService.gridReady.emit();
        });
      }, (error: any) => {
        this.dialog.open(FailureComponent, {});
        this.loaderService.hide();
      });
    } catch (e) {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    }
  }

  removeGrid(): void {
    try {
      this.loaderService.show();
      this.storageService.getAvailableDashboards().subscribe((availableDashboards: Array<string>) => {
        const dialogRef: MatDialogRef<DeleteModalComponent, string> = this.dialog.open(DeleteModalComponent, {
          data: {
            currentDashboard: this.storageService.currentDashboard,
            availableDashboards: availableDashboards,
          },
        });
        this.loaderService.hide();
        dialogRef.afterClosed().pipe(switchMap((result: string) => {
          if (result) {
            this.loaderService.show();
            return this.dashboardService.deleteDashboard(result);
          } else {
            throw new Error('Error in removing dashboard');
          }
        })).subscribe(() => {
          this.dialog.open(SuccessComponent, {});
          this.loaderService.hide();
        }, (error: any) => {
          this.dialog.open(FailureComponent, {});
          this.loaderService.hide();
        });
      });
    } catch (e) {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    }
  }

  editData(index: number): void {
    const currentWidget: DashboardItem = this.widgets[index];
    if (this.storageService.getSavedDashboard()) {
      const editDataRef: MatDialogRef<EditDataModalComponent, Array<WidgetEditableData>> = this.dialog.open(EditDataModalComponent, {
        data: currentWidget,
      });
      editDataRef.afterClosed().subscribe(async (result: Array<WidgetEditableData>) => {
        if (result) {
          try {
            this.loaderService.show();
            this.storageService.saveWidgetThreshold(currentWidget, result).pipe(switchMap((updateResponse: UpsertResponse) => {
              if ((updateResponse as UpdateResponse).outAffected || (updateResponse as InsertResponse).outId) {
                currentWidget.data = Object.assign(currentWidget.data, result);
                if ((updateResponse as InsertResponse).outId) {
                  currentWidget.configurationId = Number((updateResponse as InsertResponse).outId);
                }
                const gridData: Array<SerializedWidget> = this.serializeWidget();
                const saveGrid: SerializedGridInstance = {
                  widgets: gridData,
                  options: this.currentOptions,
                };
                return this.storageService.getAvailableDashboards().pipe(switchMap(() => {
                  return this.storageService.save(this.storageService.getSavedDashboard(), saveGrid, this.page);
                }));
              } else {
                throw new Error();
              }
            })).subscribe(() => {
              this.widgets[index] = cloneDeep(currentWidget);
              this.loaderService.refreshWidget.emit(currentWidget.type);
              this.dialog.open(SuccessComponent, {});
              this.loaderService.hide();
            }, (error: any) => {
              this.dialog.open(FailureComponent, {});
              this.loaderService.hide();
            });
          } catch (e) {
            this.dialog.open(FailureComponent, {});
            this.loaderService.hide();
          }
        }
      });
    } else {
      this.dialog.open(FailureComponent, {
        data: {
          message: 'HOME.SAVE_DASHBOARD_BEFORE',
        },
      });
      this.loaderService.hide();
    }
  }

  /**
   *
   * Switch from widget to fullscreen and viceversa
   *
   * @param fullScreen: if is fullscreen
   * @param index: number of widget
   */
  toggleFullscreen(fullScreen: boolean, index: number): void {
    this.isFullscreen = fullScreen;
    const item: DashboardItem = this.widgets[index];
    item.fullscreen = fullScreen;
    if (fullScreen) {
      if (item.data && item.data['timeMachineOn'] === false) {
        this.timeMachineService.toggleLabel(false);
      } else {
        this.timeMachineService.toggleLabel(true);
      }
    } else {
      this.timeMachineService.toggleLabel(true);
    }
  }

  detectDevice(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  isMobile(): void {
    this.isMobilevar = this.deviceService.isMobile();
  }

  isTablet(): void {
    this.isTabletvar = this.deviceService.isTablet();
  }

  isDesktop(): void {
    this.isDesktopvar = this.deviceService.isDesktop();
  }
}

export interface WidgetEditableData {
  [propName: string]: string | number;
}

export interface EditOverallData {
  id?: number;
  title?: string;
  name?: string;
  data: Array<WidgetEditableData>;
  saveNewConfiguration?: boolean;
}
