import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { WidgetType } from '@app/shared/enums/widget-type';
import { AIR_QUALITY_LEGEND } from '@app/modules/widgets/air-quality-widget/data/legend';
import { AirOzoneData, AirPm10Data, AirStationData } from '@app/modules/widgets/air-quality-widget/model';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TranslateService } from '@ngx-translate/core';
import { DivIcon, FeatureGroup, LatLng, Marker, Tooltip } from 'leaflet';

import moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AirQualityWidgetService extends MapWidgetService {
  widgetType: WidgetType = WidgetType.AIR_QUALITY_WIDGET;
  schemaUrl: string = 'assets/schemas/airquality-widget.json';

  private static getPm10Threshold(pm10: number): number {
    if (!pm10) {
      return 0;
    }
    switch (true) {
      case (pm10 < 50):
        return 1;
      case (pm10 <= 100):
        return 2;
      case (pm10 > 100):
        return 3;
      default:
        return 0;
    }
  }

  private getOzoneThreshold(ozone: number): number {
    if (!ozone) {
      return 0;
    }
    switch (true) {
      case (ozone < 120):
        return 1;
      case (ozone < 180):
        return 2;
      case (ozone < 240):
        return 3;
      case (ozone < 300):
        return 4;
      default:
        return 0;
    }
  }

  private getLayerFromStation(s: AirStationData): FeatureGroup {
    const translate: TranslateService = this.translateService;
    const layer: FeatureGroup = new FeatureGroup();
    const markerPosition: LatLng = new LatLng(s.lat, s.lon);
    const marker: Marker = new Marker(markerPosition);
    const tooltip: Tooltip = new Tooltip();
    const pm10String: string = s.pm10 ? s.pm10.toFixed(2) + ' µg/m3' : translate.instant('COMMONS.UNAVAILABLE');
    const ozoneString: string = s.ozone ? s.ozone.toFixed(2) + ' µg/m3' : translate.instant('COMMONS.UNAVAILABLE');
    const threshold: number = AirQualityWidgetService.getPm10Threshold(s.pm10);
    const markerIcon: DivIcon = new DivIcon({
      className: `air-quality-marker air-quality-marker--${AirQualityWidgetService.getPm10Threshold(s.pm10)}`,
      html: `<div class="d-flex flex-row justify-content-center align-items-center air-quality-marker__icon">${threshold ? s.pm10.toFixed(0) : 'N/D'}</div>`,
    });
    marker.setIcon(markerIcon);
    let template: string = '';
    template += `<h6>${s.name}</h6><br/>`;
    template += `<b class="text-left">${translate.instant('WIDGETS.AIR_QUALITY.PM10')}:</b> ${pm10String}<br/>`;
    template += `<i class="text-left">${translate.instant('WIDGETS.AIR_QUALITY.LAST_UPDATE')}: ${moment(s.pm10Time).format('LTS')}<br/><br/></i>`;
    template += `<b class="text-left">${translate.instant('WIDGETS.AIR_QUALITY.OZONE')}:</b> ${ozoneString}<br/>`;
    template += `<i class="text-left">${translate.instant('WIDGETS.AIR_QUALITY.LAST_UPDATE')}: ${moment(s.ozoneTime).format('LTS')}<br/></i>`;
    tooltip.setContent(template);
    marker.bindTooltip(tooltip);
    marker.addTo(layer);
    return layer;
  }

  public loadOzoneData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<AirOzoneData>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'air-quality-ozone';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<DataResult<AirOzoneData>>(url).pipe(
        map((result: DataResult<AirOzoneData>) => {
          return result.data;
        }),
      );
    }
  }

  public loadPm10Data(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<AirPm10Data>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'air-quality-pm10';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<DataResult<AirPm10Data>>(url).pipe(
        map((result: DataResult<AirPm10Data>) => {
          return result.data;
        }),
      );
    }
  }

  public loadStation(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<AirStationData>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'air-quality-station';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<DataResult<AirStationData>>(url).pipe(
        map((result: DataResult<AirStationData>) => {
          return result.data;
        }),
      );
    }
  }

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<AirStationData>> {
    const observables: Array<Observable<any>> = [];
    observables.push(this.loadStation(timeMachineData, sources));
    observables.push(this.loadPm10Data(timeMachineData, sources));
    observables.push(this.loadOzoneData(timeMachineData, sources));
    return forkJoin(observables).pipe(
      map((data: Array<any>) => {
        let stations: Array<AirStationData> = data[0];
        const pm10Data: Array<AirPm10Data> = data[1];
        const ozoneData: Array<AirOzoneData> = data[2];
        if (stations) {
          stations = stations.map((s: AirStationData) => {
            const pm10StationData: AirPm10Data = pm10Data.find((pm10s: AirPm10Data) => {
              return pm10s.codseqst === s.codseqst;
            });
            const ozoneStationData: AirOzoneData = ozoneData.find((ozoneS: AirOzoneData) => {
              return ozoneS.codseqst === s.codseqst;
            });
            if (pm10StationData) {
              s.pm10 = pm10StationData.pm10;
              s.pm10Trend = pm10StationData.trend;
              s.pm10Time = pm10StationData.time;
            }
            if (ozoneStationData) {
              s.ozone = ozoneStationData.ozone;
              s.ozoneTrend = ozoneStationData.trend;
              s.ozoneTime = ozoneStationData.time;
            }
            return s;
          });
        }
        return stations;
      }),
    );
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: Array<string>): Observable<FeatureGroup> {
    return this.loadData(timeMachineData, sources).pipe(
      map((stationList: Array<AirStationData>) => {
        const featureGroup: FeatureGroup = new FeatureGroup();
        if (stationList) {
          if (additionalData) {
            stationList = stationList.filter((s: AirStationData) => {
              return additionalData.includes(s.codseqst);
            });
          }
          stationList.forEach((s: AirStationData) => {
            const layer: FeatureGroup = this.getLayerFromStation(s);
            layer.addTo(featureGroup);
          });
        }
        return featureGroup;
      }),
    );
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const timestamp: number = new Date().getTime();
    const trendTimeStamp: number = moment(timestamp).subtract(4, 'hour').valueOf();
    const url: string = this.addTimeMachineDataToUrl(timestamp, source).replace('{{trendStamp}}', trendTimeStamp.toString());
    return this.http.get<DataResult<AirOzoneData>>(url);
  }

  public getLegend(): MapLegend {
    return AIR_QUALITY_LEGEND;
  }

  public checkThresholds(timeMachineData: TimeMachineData): Observable<DataResult<WidgetEvent>> {
    return this.storageService.checkWidgetThresholds(timeMachineData, 'air');
  }
}
