import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { MarkerList } from '@app/shared/models/map/marker-list';
import { FeatureGroup } from 'leaflet';
import { Observable } from 'rxjs';
import { MapWidgetServiceInterface } from './model/interfaces';

@Injectable()
export class MapWidgetService extends WidgetService implements MapWidgetServiceInterface {
  markers: Array<MarkerList> = [];

  public getMapLevel(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<FeatureGroup> {
    return null;
  }

}
