import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { head, last } from 'lodash';
import { NoUiSliderRangeTimestamps } from './time-machine-data';
import moment, { DurationInputArg1, Moment } from 'moment';

export class TimeMachineSliderRange extends TimeMachineRange {
  constructor (timeMachineInput: NoUiSliderRangeTimestamps) {
    super(TimeMachineSliderRange.timestampFromHours(head(timeMachineInput)),
      TimeMachineSliderRange.timestampFromHours(last(timeMachineInput)));
  }

  private static timestampFromHours (hoursShift: number): Moment {
    const timer: Moment = moment().seconds(0);
    return timer.add(hoursShift as DurationInputArg1, 'h');
  }
}
