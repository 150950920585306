import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MapTideBootOption } from '@app/shared/models/map-tide/map-tide';

@Component({
  selector: 'app-boots-settings-modal',
  templateUrl: './boots-settings-modal.component.html'
})
export class BootsSettingsModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BootsSettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<MapTideBootOption>) {

  }

  ngOnInit(): void {
  }

  updateBootsSettings(): void {
    this.dialogRef.close({ bootsSettings: this.data });
  }
}
