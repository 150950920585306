import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { AuxiliaryWidgetComponent } from './auxiliary-widget.component';

@NgModule({
  declarations: [AuxiliaryWidgetComponent],
  imports: [CommonModule, WidgetModule],
  exports: [AuxiliaryWidgetComponent],
})
export class AuxiliaryWidgetModule {
}
