import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { RadarFlow, RadarFlowData } from '@app/modules/widgets/radar-widget/models/radar-flow';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  RadarTableDataSourceItem,
  VehicleColor,
  VehicleColorType
} from './models';

@Injectable()
export class RadarService extends MapWidgetService {
  public loadData(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>): Observable<RadarFlowData> {
    return forkJoin([this.getVehicleColors(sources), this.getRadarData(timeMachineData, sources)])
      .pipe(switchMap((data: [VehicleColor, Array<RadarFlow>]) => {
        return of({
          radarFlow: data[1],
          vehicleColors: data[0],
        });
      }));
  }

  getTableData(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>, radarFlowData: RadarFlow, vc: VehicleColor): Array<RadarTableDataSourceItem> {
    const tableData: Array<RadarTableDataSourceItem> = [];

    vc.roadVehicleColors.forEach((type: VehicleColorType) => {
      tableData.push(
        {
          name: type.name,
          total: this.getSum(radarFlowData, type.field),
          color: type.color,
          fontColor: type.fontColor,
          font: type.font,
          speedAvg: this.getAvg(radarFlowData, 'mean_speed'),
          distanceAvg: this.getAvg(radarFlowData, 'mean_headway')
        });
    });
    return tableData;
  }

  public getRadarData(timestamp: TimeMachineData,
    sources: Array<WidgetDataSource>): Observable<Array<RadarFlow>> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'radar-data';
    });
    if (mainSource) {
      let url: string;
      url = this.addTimeMachineDataToUrl(timestamp, mainSource, 1);
      if (this.timeMachineService.isTimeMachineActive()) {
        url = url.replace('{{isNow}}', '0');
      } else {
        url = url.replace('{{isNow}}', '1');
      }

      return this.http.get<Array<RadarFlow>>(url);
    } else {
      return of([]);
    }
  }

  public getVehicleColors(dataSources: Array<WidgetDataSource>): Observable<VehicleColor> {
    const widgetDataSource: WidgetDataSource = dataSources.find((s: WidgetDataSource) => {
      return s.sourceType === 'radar-vehicle-colors';
    });
    if (widgetDataSource) {
      return this.http.get<VehicleColor>(widgetDataSource.rewriteUrl);
    }
  }

  getSum(array: RadarFlow, field: string): number {
    return array[field] ? Object.keys(array[field]).reduce((acc1: any, value: any) => acc1 + array[field][value], 0) : 0;
  }

  getAvg(array: RadarFlow, column: string): number {
    return array[column] ? Math.round(this.getSum(array, column) / Object.keys(array[column]).length) : 0;
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    let timeStampUrl: string = this.addTimeMachineDataToUrl(new Date().getTime(), source, 1);
    if (this.timeMachineService.isTimeMachineActive()) {
      timeStampUrl = timeStampUrl.replace('{{isNow}}', '0');
    } else {
      timeStampUrl = timeStampUrl.replace('{{isNow}}', '1');
    }

    return this.http.get<any>(timeStampUrl);
  }
}
