<div class="lights-control p-3">
  <h6>{{'WIDGETS.PUBLIC_LIGHTS.TITLE' | translate }}</h6>
  <div class="control__radio d-flex flex-row justify-content-between align-items-center">
    <ng-select
      class="control__select"
      [placeholder]="'WIDGETS.PUBLIC_LIGHTS.TITLE' | translate"
      [items]="items"
      [multiple]="true"
      [closeOnSelect]="false"
      [hideSelected]="true"
      bindLabel="value"
      [(ngModel)]="selectedItems"
      (add)="submit()"
      (clear)="submit()"
      (remove)="submit()">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <div class="selected-item">
          <div class="d-flex selected-item__label">
            <div class="item d-flex flex-row justify-content-start">
              <div class="ball mr-2" [style.backgroundColor]="item.color"></div>
              <span class="label">{{'WIDGETS.PUBLIC_LIGHTS.TYPES.' + item.value | translate}}</span></div>
            <span class="ng-value-icon left" (click)="clear(item)"
                  aria-hidden="true">×</span>
          </div>
        </div>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" class="d-flex flex-column align-items-center">
        <div class="selected-item">
          <div class="d-flex selected-item__label">
          <div class="item d-flex flex-row justify-content-start">
            <div class="ball mr-2" [style.backgroundColor]="item.color"></div>
            <span class="label">{{'WIDGETS.PUBLIC_LIGHTS.TYPES.' + item.value | translate}}</span></div>
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
