import { Injectable } from '@angular/core';
import { ArcgisWidgetService } from '@app/modules/widgets/arcgis-widget/arcgis-widget.service';
import { ArcgisMapRequest } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-map-request';
import { ArcgisSource } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { CAMERA_LEGEND } from '@app/modules/widgets/videosurveillance-widget/data/legend';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { Feature } from 'geojson';
import { LatLngExpression, Layer, Popup } from 'leaflet';

@Injectable()
export class VideosurveillanceWidgetService extends ArcgisWidgetService {

  loadColorFromEnum(feature: Feature, source: ArcgisSource, additionalData: ArcgisMapRequest): string {
    const property: any = feature.properties['STATO'];
    switch (property) {
      case null:
      case '':
        return '#FFFF74';
      case '100':
        return '#99E600';
      default:
        return '#D21933';
    }
  }

  createLevelMarker(feature: Feature, latlng: LatLngExpression, source: ArcgisSource, additionalData: ArcgisMapRequest): Layer {
    const marker: Layer = super.createLevelMarker(feature, latlng, source, additionalData);
    const popup: Popup = new Popup({
      autoClose: false,
      closeOnClick: false,
    });
    const totalVideos: number = 5;
    const videoUrls: Array<string> = [];
    for (let i: number = 1; i <= totalVideos; i++) {
      const videoUrl: string = feature.properties['VIDEO' + i];
      if (videoUrl) {
        videoUrls.push(videoUrl);
      }
    }
    if (videoUrls.length) {
      let template: string = '';
      videoUrls.forEach((u: string) => {
        template += `<video controls autoplay controlsList="nodownload">
                      <source src="${u}" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>`;
      });
      marker.bindPopup(popup);
      popup.setContent(template);
      marker.addEventListener('click', () => {
        marker.openPopup();
        popup.getElement().addEventListener('click', () => {
          popup.bringToFront();
        });
      });
      marker.addEventListener('popupclose', (evt: any) => {
        const elements: NodeList = evt.popup.getElement().querySelectorAll('video');
        elements.forEach((node: HTMLVideoElement) => {
          node.pause();
          node.removeAttribute('src'); // empty source
          node.load();
        });
      });
    }
    return marker;
  }

  public getLegend(sources: Array<ArcgisSource>): MapLegend {
    return CAMERA_LEGEND;
  }
}
