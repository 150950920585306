<app-map-widget class="mindicity-widget bridges-widget"
                [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
                [isEditable]="isEditable"
                [isLoading]="isLoading"
                (removeWidget)="sendRemoveWidget()"
                [sourceStatus]="sourceStatus"
                (sendReloadWidget)="reloadWidget()"
                [timeMachineOn]="timeMachineOn"
                [mapId]="mapId"
                [map]="map"
                [data]="data"
                [noData]="noData"
                (resized)="handleResize($event)"
                (mapReady)="setMapHandlers($event)"
                [legends]="legends"
                [leafletOptions]="leafletOptions">
  <div class="map-containers bridges-control control d-flex flex-column justify-content-between align-items-stretch">
    <app-bridges-control [hidden]="!showDetails" [boatHeightValue]="heightLevel"
                         [tideValue]="tide"
                         (sliderChanged)="heightChanged($event)"></app-bridges-control>
  </div>
</app-map-widget>
