import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { ParkingWidgetService } from '@app/modules/widgets/parking-widget/parking-widget.service';
import { ParkingWidgetComponent } from './parking-widget.component';


@NgModule({
  declarations: [ParkingWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule,
  ],
  providers: [ParkingWidgetService],
  exports: [ParkingWidgetComponent]
})
export class ParkingWidgetModule {
}
