import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PanZoomConfig, PanZoomAPI, PanZoomModel } from 'ng2-panzoom';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit, AfterViewInit, OnDestroy {

  private panZoomAPI: PanZoomAPI;
  private apiSubscription: Subscription;

  @ViewChild('container', { static: true }) private container: ElementRef;
  @ViewChild('image', { static: true }) private image: ElementRef;

  public panzoomConfig: PanZoomConfig = new PanZoomConfig({
    zoomLevels: 10,
    neutralZoomLevel: 0,
    initialZoomLevel: 0,
    scalePerZoomLevel: 2.0,
    zoomStepDuration: 0.2,
    freeMouseWheelFactor: 0.01,
    zoomToFitZoomLevelFactor: 0.9,
    dragMouseButton: 'left'
  });
  public imageUrl: HTMLImageElement;
  public panzoomModel: PanZoomModel;

  constructor(public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageUrl = data.imageUrl;
  }

  ngOnInit(): void {
    this.apiSubscription = this.panzoomConfig.api.subscribe((api: PanZoomAPI) => { this.panZoomAPI = api; });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
  }

  resetZoomToFit(): void {
    if (this.container && this.image) {
      const containerWidth: number = this.container.nativeElement.clientWidth;
      const imageWidth: number = this.image.nativeElement.clientWidth;
      this.panZoomAPI.panDelta({ x: -((containerWidth / 2) - (imageWidth / 2)), y: 0 });
    }
  }

  center(): void {
    this.panZoomAPI.resetView();
    setTimeout(() => {
      this.resetZoomToFit();
    }, 500);
  }

  zoomIn(): void {
    this.panZoomAPI.zoomIn();
  }

  zoomOut(): void {
    this.panZoomAPI.zoomOut();
  }

  onImageLoad(): void {
    this.resetZoomToFit();
  }
}
