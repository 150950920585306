import { AfterViewInit, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ImageDemoService } from '@app/modules/widgets/image-demo-widget/image-demo.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetConfigurationData } from '@app/shared/models/dashboard/serialized-grid';


@Component({
  selector: 'app-image-demo',
  templateUrl: './image-demo-widget.component.html',
  styleUrls: ['./image-demo-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ImageDemoWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {
  images: Array<SafeResourceUrl>;

  @Input()
  data: ImageWidgetData;

  constructor(public widgetService: ImageDemoService, public injector: Injector) {
    super(widgetService, injector);
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((images: Array<SafeResourceUrl>) => {
      this.images = images;
      this.noData = false;
    }, (error: any) => {
      this.noData = true;
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

}

export interface ImageWidgetData extends WidgetConfigurationData {
  timeMachineOn: boolean;
  timer: number;
}
