import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { PUBLIC_LIGHTS_TYPES, PublicLightsType } from '@app/modules/widgets/public-lights-widget/mock/public-lights-type';

@Component({
  selector: 'app-public-lights-control',
  templateUrl: './public-lights-control.component.html',
  styleUrls: ['./public-lights-control.component.scss'],
})
export class PublicLightsControlComponent implements OnInit {

  items: Array<PublicLightsType>;

  @Input()
  selectedItems: Array<PublicLightsType>;

  typeSelected: EventEmitter<Array<PublicLightsType>> = new EventEmitter<Array<PublicLightsType>>();

  constructor() {
  }

  ngOnInit(): void {
    this.items = PUBLIC_LIGHTS_TYPES;
    this.submit();
  }

  submit(): void {
    this.typeSelected.emit(this.selectedItems);
  }

}
