<ng-template #circle let-label="label" let-higher="higher" let-lower="lower" let-color="color">
  <li class="legend-value d-flex flex-row ">
    <div class="legend-value__circle d-flex justify-content-center align-items-center"
         [ngStyle]="{'border-color': getCircleColor(color)}">
      <span></span>
    </div>
    <div class="legend-value__text text-center">{{'WIDGETS.TEMPERATURE.' + label | translate:{
      'higher': higher ? higher : null,
      'lower': lower ? lower : null
    } }}</div>
  </li>
</ng-template>

<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" (editData)="sendEditData()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()" [showThresholdAlert]="showThresholdAlert"
            [showGlobalThresholdAlert]="showGlobalThresholdAlert" [noData]="noData" [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable" [isLoading]="isLoading">

  <div class="temperature d-flex  flex-column justify-content-between align-items-stretch"
       *ngIf="!noData"
       [ngStyle]="{'font-size.px': fontSize}" (resized)="handleResize($event)">

    <div class="indexes">
      <ul class="indexes__table d-flex align-items-start justify-content-around">
        <li *ngFor="let temperature of temperatures" class="index d-flex flex-column align-items-center">
          <div class="index d-flex flex-row align-items-center">
            <div class="index__label text-left pr-3"><span>{{temperature.station}}</span><br>
              <span class="date" [hidden]="false">{{temperature.updateOn}}</span></div>
            <div class="index__value d-flex justify-content-center align-items-center "
                 [ngStyle]="{'border-color': getCircleColor(temperature.value)}">
              <span>{{temperature.value}}°C</span>
              <span *ngIf="temperature.humidity">{{temperature.humidity}}%</span>
            </div>
            <i class="icon-venice_costante " [hidden]="getShowSpace(temperature.trend)"></i>
            <i class="icon-venice_costante  rotate-45-positive" [hidden]="getShowArrow(temperature.trend, true)"></i>
            <i class="icon-venice_costante  rotate-45-negative" [hidden]="getShowArrow(temperature.trend, false)"></i>
          </div>
        </li>
      </ul>
    </div>
    <ul class="legend flex-shrink-0 d-flex flex-row align-items-center justify-content-around ">
      <ng-container
        *ngTemplateOutlet="circle; context:{label: 'LOWER', lower:thresholds.low_temperature, color: thresholds.low_temperature - 1 }">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="circle; context:{label: 'BETWEEN', higher: thresholds.high_temperature, lower:thresholds.low_temperature, color: thresholds.high_temperature - 1 }">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="circle; context:{label: 'HIGHER', higher: thresholds.high_temperature, color: thresholds.high_temperature + 1 }">
      </ng-container>

    </ul>
  </div>
</app-widget>
