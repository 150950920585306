<app-map-base-control [hidden]="hiddenCondition" [show]="showCondition" [titleLabel]="'PAGES.MAP.LEGEND.TITLE'">
  <div class="legends d-flex flex-column align-items-stretch p-3">
    <div class="legends__entry d-flex flex-row justify-content-between" [ngClass]="{'legends__entry--middle': !last}"
         *ngFor="let legend of legends; let last = last;">
      <div class="legend">
        <h6
          *ngIf="legend.title && ('PAGES.MAP.LEGEND.' + legend.title | translate) !== 'PAGES.MAP.LEGEND.' + legend.title"
          class="legend__title pb-3">{{'PAGES.MAP.LEGEND.' + legend.title | translate | uppercase}}</h6>
        <h6
          *ngIf="legend.title && ('PAGES.MAP.LEGEND.' + legend.title | translate) === 'PAGES.MAP.LEGEND.' + legend.title"
          class="legend__title pb-3">{{legend.title | uppercase}}</h6>
        <ul class="legend__elements" *ngIf="!legend.gradient">
          <li class="element d-flex flex-row justify-content-start align-items-center mb-2"
              [ngClass]="{'element--last': element.separatorAfter}"
              *ngFor="let element of legend.elements">
            <div class="element__color mr-3" [style.backgroundColor]="element.color"
                 [style.borderRadius]="'50%'"
                 [style.borderColor]="element.border ? element.border.color : 'transparent'"
                 [style.borderWidth]="element.border ? element.border.width : '.2rem'"
                 [style.borderStyle]="element.border ? element.border.style : 'solid'"
                 *ngIf="!element.icon"></div>
            <div class="element__color element__color--icon icon-venice mr-3" *ngIf="element.icon"
                 [style.backgroundColor]="element.icon.backgroundColor"
                 [style.color]="element.icon.color"
                 [style.borderRadius]="element.icon.round ?'50%' : undefined"
                 [style.borderColor]="element.icon.border ? element.icon.border.color : undefined"
                 [style.borderWidth]="element.icon.border ? element.icon.border.width : undefined"
                 [style.borderStyle]="element.icon.border ? element.icon.border.style : undefined"
                 [ngClass]="element.icon.iconName"></div>
            <div class="element__label">{{element.label}}</div>
          </li>
        </ul>
        <div class="legend__gradient d-flex flex-row justify-content-start align-items-stretch mb-2" *ngIf="legend.gradient">
          <div class="legend__gradient__gradient" [style.background]="getGradient(legend)">
          </div>
          <div class="d-flex flex-column ml-3 justify-content-between">
            <div class="legend__gradient__first">
              {{getFirst(legend)}}
            </div>
            <div class="legend__gradient__last">
              {{getLast(legend)}}
            </div>
          </div>
        </div>
        <div class="legend__description"
             *ngIf="legend.description && ('PAGES.MAP.LEGEND.' + legend.description | translate) !== 'PAGES.MAP.LEGEND.' + legend.description">{{'PAGES.MAP.LEGEND.' + legend.description | translate }}</div>
        <div class="legend__description"
             *ngIf="legend.description && ('PAGES.MAP.LEGEND.' + legend.description | translate) === 'PAGES.MAP.LEGEND.' + legend.description">
          {{legend.description}}</div>
      </div>
    </div>
  </div>
</app-map-base-control>
