<div class="alerts-list">
  <div id="overlay" class="overlay" *ngIf="isLoading">
    <mat-progress-spinner color="#ddd" mode="indeterminate"></mat-progress-spinner>
  </div>
  <ng-container *ngIf="noalert">
    <div class="d-flex flex-row justify-content-center">
      {{'PAGES.ALERTS.NO_ALERTS' | translate}}
    </div>
  </ng-container>
  <ng-container *ngFor="let alert of alerts">
    <div class="alert-entry" *ngIf="!alert.read" [ngClass]="{'alert-entry--open': alert.open}">
      <div class="alert-container d-flex flex-row justify-content-between align-items-stretch"
           [ngClass]="'alert--' + alert.typeId">
        <div class="alert-data">
          <h5 class="alert-data__title d-flex flex-row justify-content-start align-items-center"><span
            class="icon-venice--big icon-venice" *ngIf="iconClasses[alert.typeId - 1]"
            [ngClass]="iconClasses[alert.typeId - 1]"></span>{{alert.name}}
          </h5>

          <div class="alert-data__description d-flex flex-row justify-content-between align-items-end"
               *ngIf="alert.description">
            {{alert.description}}
          </div>
          <div class="alert-data__timestamp d-flex flex-row justify-content-start align-items-center">
            {{alert.createdAt | date:'dd/MM/yyyy HH:mm'}}
            <span class="alert-data__users d-flex flex-row justify-content-start align-items-center">
              <span class="d-flex flex-row justify-content-start align-items-center" *ngIf="alert.name">
                <i *ngIf="alert.recipient" class="icon-venice icon-venice_presenze"></i>
                <span *ngIf="alert.users === 1">{{'PAGES.ALERTS.ONLY_YOU' | translate}}</span>
              </span>
            </span>
          </div>
          <div class="d-flex flex-row justify-content-between align-items-end">
            <div class="alert-data__actions d-flex flex-row justify-content-start align-items-center">
              <i class="alert-data__waiting icon-venice icon-venice_alert"
                 [ngClass]="{'icon-venice_alert--hidden': !alert.priority}"></i>
              <span *ngIf="alert.labels && alert.labels.length>0"
                    class="alert-data__state badge badge-primary">{{alert.labels[0] | translate | titlecase}}</span>
            </div>
            <div>
              <button mat-button class="alert-data__button" [color]="'accent'"
                      (click)="onAlertClick(alert)">{{'PAGES.ALERTS.MARK_AS_READ' | translate}}</button>
              <mat-checkbox [(ngModel)]="alert.checked" *ngIf="viewMultiSelection"></mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="d-flex flex-row justify-content-end align-items-end" *ngIf="viewMultiSelection">
    <button mat-button class="alert-data__button" [color]="'accent'"
            (click)="onSelectAllClick()">{{'PAGES.ALERTS.SELECT_ALL' | translate}}</button>
    <button mat-button class="alert-data__button" [color]="'accent'"
            (click)="onAlertAllClick()">{{'PAGES.ALERTS.MARK_AS_READS' | translate}}</button>
  </div>
</div>
