<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData"
>
  <div class="container d-flex flex-column justify-content-center align-items-center">
    <div class="number mb-2">
      {{data.percentage | number:'1.0-1'}}%
    </div>
    <div class="label mt-2 mb-2">
      {{data.free}}&nbsp;{{'WIDGETS.ROAD_CURRENT.ON' | translate}}&nbsp;{{data.max}}&nbsp;{{'WIDGETS.ROAD_CURRENT.AVAILABLE_PLACES' | translate}}
    </div>
    <div class="label mt-2">
      <i>&nbsp;{{'COMMONS.FROM' | translate}}
        <ng-container *ngIf="!this.timeMachineService.rangeOn">
          {{this.timeMachineService.getCurrentSelectionTimestamp() | date:'dd/MM/yyyy - 00:00'}}
        </ng-container>
        <ng-container *ngIf="this.timeMachineService.rangeOn">
          {{this.timeMachineService.getCurrentSelectionRange().to | date:'dd/MM/yyyy - 00:00'}}
        </ng-container>
        <br />
        {{'COMMONS.TO' | translate | lowercase }}
        <ng-container *ngIf="!this.timeMachineService.rangeOn">
          {{this.timeMachineService.getCurrentSelectionTimestamp() | date:'dd/MM/yyyy - HH:mm'}}
        </ng-container>
        <ng-container *ngIf="this.timeMachineService.rangeOn">
          {{this.timeMachineService.getCurrentSelectionRange().to | date:'dd/MM/yyyy - HH:mm'}}
        </ng-container>
      </i>
    </div>
  </div>
</app-widget>

