import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetType } from '@app/shared/enums/widget-type';
import { SerializedTemperatures, Thresholds } from '@app/modules/widgets/temperature-widget/model';
import { Temperature } from '@app/modules/widgets/temperature-widget/model/temperature';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';

import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TemperatureWidgetService extends WidgetService {
  schemaUrl: string = 'assets/schemas/temperature-widget.json';
  widgetType: WidgetType = WidgetType.TEMPERATURES_WIDGET;

  public loadData(timestamp: TimeMachineData, sources: Array<WidgetDataSource>, sourceName: string): Observable<Array<Temperature>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === sourceName;
    });
    if (source) {
      const timeStampUrl: string = this.addTimeMachineDataToUrl(timestamp, source, 4);
      let trendTimeStamp: number;
      if (typeof timestamp === 'number') {
        trendTimeStamp = timestamp;
      } else if (timestamp instanceof TimeMachineRange) {
        trendTimeStamp = timestamp.getToTimeStamp();
      }
      trendTimeStamp = moment(trendTimeStamp).add(-4, 'hours').valueOf();
      const trendStampUrl: string = this.replaceParameter(timeStampUrl, 'trendStamp', trendTimeStamp.toString());
      return this.http.get<DataResult<SerializedTemperatures>>(trendStampUrl).pipe(map((result: DataResult<SerializedTemperatures>) => {
        return result.data.map((s: SerializedTemperatures) => {
          return new Temperature(s);
        });
      }));
    } else {
      throw Error('No source found');
    }
  }

  public getColorFill(value: number, thresholds: Thresholds): string {
    if (thresholds) {
      if (value >= thresholds.low_temperature && value <= thresholds.high_temperature) {
        return '#7CB518';
      } else if (value > thresholds.high_temperature) {
        return '#F41628';
      } else if (value < thresholds.low_temperature) {
        return '#4381C1';
      }
    } else {
      return '#000000';
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const timestamp: number = new Date().getTime();
    const trendTimeStamp: number = moment(timestamp).subtract(4, 'hour').valueOf();
    const url: string = source.rewriteUrl.replace('{{to}}', timestamp.toString()).replace('{{trendStamp}}', trendTimeStamp.toString());
    return this.http.get<DataResult<SerializedTemperatures>>(url);
  }

  public checkThresholds(timeMachineData: TimeMachineData): Observable<DataResult<WidgetEvent>> {
    return this.storageService.checkWidgetThresholds(timeMachineData, 'temperature');
  }
}
