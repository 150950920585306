import { Component, Injector } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { EventMapWidgetService } from '@app/modules/widgets/event-map-widget/event-map-widget.service';

@Component({
  selector: 'app-event-map-widget',
  templateUrl: './event-map-widget.component.html'
})
export class EventMapWidgetComponent extends MapWidgetComponent {

  constructor(public widgetService: EventMapWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

}
