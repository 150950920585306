import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SubMenuChangeEvent, SubMenuSelectAllEvent } from '@app/features/pages/map-page/map-control-sub-bar/models';
import { MapPageService } from '@app/features/pages/map-page/map-page.service';
import { MapControlBarButton, MapControlBarPanel, MapSubMenuElement } from '@app/features/pages/map-page/models';
import { MapLevel } from '@app/features/pages/map-page/models/map-level';
import { MapPageConfig } from '@app/features/pages/map-page/models/map-page-config';
import { MapStaticSubMenu } from '@app/features/pages/map-page/models/map-static-sub-menu';
import { MapSubMenu } from '@app/features/pages/map-page/models/map-sub-menu';
import { PageDirective } from '@app/features/pages/page.directive';
import { StreetType } from '@app/features/pages/sanctions-page/model';
import { RegistrySearchQuery } from '@app/modules/map/registry-search/registry-search.component';
import { AirQualityWidgetService } from '@app/modules/widgets/air-quality-widget/air-quality-widget.service';
import { ArcgisWidgetService, FeatureEvent, FeatureStatus } from '@app/modules/widgets/arcgis-widget/arcgis-widget.service';
import { ArcgisMapRequest } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-map-request';
import { ArcgisSource, IconAttributes } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { BridgesControlValues } from '@app/modules/widgets/bridges-widget/bridges-control/bridges-control.component';
import { BridgesWidgetService } from '@app/modules/widgets/bridges-widget/bridges-widget.service';
import { BridgesRequest } from '@app/modules/widgets/bridges-widget/models/bridges-request';
import { BridgesType } from '@app/modules/widgets/bridges-widget/models/bridges-type';
import { CulturalEventsService } from '@app/modules/widgets/cultural-events-widget/cultural-events.service';
import { EventMapWidgetService } from '@app/modules/widgets/event-map-widget/event-map-widget.service';
import { EventMapRequest } from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-request';
import { EventMapSource } from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-source';
import { FlowMapRequest } from '@app/modules/widgets/flows/flow-map-widget/data/flow-map-request';
import { FlowMapWidgetService } from '@app/modules/widgets/flows/flow-map-widget/flow-map-widget.service';
import { GeoserverService } from '@app/modules/widgets/geoserver-widget/geoserver-widget.service';
import { GeoserverDataSource } from '@app/modules/widgets/geoserver-widget/models/geoserver-data-source';
import { HeatmapService } from '@app/modules/widgets/heatmap-widget/heatmap.service';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { ParkingWidgetService } from '@app/modules/widgets/parking-widget/parking-widget.service';
import {
  PedestrianFlowMode,
  PedestrianFlowsWidgetService,
} from '@app/modules/widgets/pedestrian-flows-widget/pedestrian-flows-widget.service';
import { PresencesClusterService } from '@app/modules/widgets/presences-cluster-widget/presences-cluster.service';
import { PublicLightsType } from '@app/modules/widgets/public-lights-widget/mock/public-lights-type';
import { PublicLightsRequest } from '@app/modules/widgets/public-lights-widget/models/public-lights-request';
import { PublicLightsService } from '@app/modules/widgets/public-lights-widget/public-lights.service';
import {
  TimeTableData,
  TimetableModalComponent,
} from '@app/modules/widgets/public-transport-widget/modals/timetable-modal/timetable-modal.component';
import { LineSelect, MarkerOnMap, PublicTransportRoute, Transports } from '@app/modules/widgets/public-transport-widget/models';
import { LineRequest } from '@app/modules/widgets/public-transport-widget/models/interfaces/line-request';
import { PublicTransportDataSource } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-data-source';
import { StopSelect } from '@app/modules/widgets/public-transport-widget/models/interfaces/stop-select';
import { TimetableEntry } from '@app/modules/widgets/public-transport-widget/models/interfaces/timetable-entry';
import { NewActiveLine, PublicTransportService } from '@app/modules/widgets/public-transport-widget/public-transport.service';
import { PublicTransportsStopsService } from '@app/modules/widgets/public-transports-stops-widget/public-transports-stops.service';
import {
  AddressResponse,
  GeocodingAddressRequest,
  GeocodingRequest,
  GeocodingRequestType,
  Person,
  RegistryService,
} from '@app/modules/widgets/registry-widget';
import { SanctionsMapService } from '@app/modules/widgets/sanctions/sanctions-map/sanctions-map.service';
import { TideMapRequest } from '@app/modules/widgets/tide-map-widget/models/tide-map-request';
import { TideMapType } from '@app/modules/widgets/tide-map-widget/models/tide-map-type';
import { TideControlComponent } from '@app/modules/widgets/tide-map-widget/tide-control/tide-control.component';
import { TideMapWidgetService } from '@app/modules/widgets/tide-map-widget/tide-map-widget.service';
import { CurrentTideEntry } from '@app/modules/widgets/tide-widget/models';
import { TideService } from '@app/modules/widgets/tide-widget/tide.service';
import { VideosurveillanceWidgetService } from '@app/modules/widgets/videosurveillance-widget/videosurveillance-widget.service';
import { WindService } from '@app/modules/widgets/wind-widget/wind.service';
import { MenuComponent } from '@app/shared/components/menu-bar/menu/menu.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetType } from '@app/shared/enums/widget-type';
import { SpecialPermissionConfig } from '@app/shared/models/app-config/app-options';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';
import { FlowType } from '@app/shared/models/flow-models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { GridService } from '@services/grid/grid.service';
import { LoaderService } from '@services/loader/loader.service';
import { RangeManagerService } from '@services/range-manager/range-manager.service';
import { SourcesService } from '@services/sources/sources.service';
import { StorageService } from '@services/storage/storage.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { DynamicMapLayer, RasterLayer } from 'esri-leaflet';
import {
  Circle,
  circle,
  Content,
  Control,
  DivIcon,
  DrawEvents,
  FeatureGroup,
  featureGroup,
  latLng,
  LatLng,
  LatLngBounds,
  Layer,
  LayerGroup,
  LeafletEvent,
  LeafletMouseEvent,
  Map as LeafletMap,
  MapOptions,
  Marker,
  Popup,
  TileLayer,
  tileLayer,
  Tooltip,
} from 'leaflet';
import { cloneDeep } from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

const DEFAULT_REFRESH_INTERVAL: number = 5000;

declare var L: any;

@Component({
  selector: 'app-map-page',
  templateUrl: './map-page.component.html',
  styleUrls: ['./map-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapPageComponent extends PageDirective implements OnInit, OnDestroy {
  @ViewChild('controlContainer', { read: ViewContainerRef }) controlContainer: ViewContainerRef;
  @ViewChild('menu') menuControl: MenuComponent;

  labels: {} = {};
  currentPage: string = 'map';
  pageConfig: MapPageConfig;

  tideChanged: EventEmitter<CurrentTideEntry> = new EventEmitter<CurrentTideEntry>();

  map: LeafletMap;
  intervalActive: boolean;

  fields: Array<MapSubMenu> = [];
  oldRegistrySearchSubscription: Subscription;

  public baseLayer: TileLayer;
  public veniceBackgroundLayer: RasterLayer;
  public cartoDbBaseLayer: TileLayer = tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
    zIndex: 1,
    maxZoom: 19,
  });
  public cartoDbBaseLayerDark: TileLayer = tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {
    zIndex: 1,
    maxZoom: 19,
  });

  public leafletOptions: MapOptions;
  public drawOptions: Control.DrawConstructorOptions;
  public drawnItems: FeatureGroup = featureGroup();
  showDrawer: boolean = true;
  tideLoading: boolean = true;

  activeLegends: Array<MapLegend> = [];
  markerLayers: Array<Layer>;
  boundingBox: LatLngBounds;
  layers: Map<string, FeatureGroup> = new Map<string, FeatureGroup>();
  timeDimensionLayers: Map<string, FeatureGroup> = new Map<string, FeatureGroup>();
  intervals: Map<string, Subscription> = new Map<string, Subscription>();
  controlComponents: Map<string, ComponentRef<any>> = new Map<string, ComponentRef<any>>();
  legends: Map<string, MapLegend> = new Map<string, MapLegend>();
  timeDimensionControlOn: boolean = false;
  showTide: boolean = false;

  activeLayers: Array<MapControlBarButton> = new Array<MapControlBarButton>();
  data: Array<MapControlBarPanel>;
  originalData: Array<MapControlBarPanel>;
  currentTide: CurrentTideEntry;
  currentEventFilter: number;
  currentArcgisFilter: number;
  currentLightsFilter: Array<PublicLightsType> = [];

  currentTimeMachineSelection: TimeMachineData;
  isUpdating: boolean = false;
  changeDefault: boolean = false;
  mouseClickMode: boolean = false;
  circleLayer: Circle;

  timeMachineSubscription: Subscription;
  veniceLayer: RasterLayer;
  veniceLayerOn: boolean = false;
  activeTransportMarker: Marker;

  hideControls: boolean;

  registryOptions: Array<AddressResponse> = [];

  tideSubscription: Subscription;
  animation: boolean;
  reloadLayer: boolean = false;
  isTimeMachinePopoverOpen: boolean = false;

  timeDimensionControlOptions: any;
  timeDimensionControl: Control;

  constructor(public timeMachineService: TimeMachineService,
              public storageService: StorageService,
              public sourcesService: SourcesService,
              public appConfigService: AppConfigService,
              public gridService: GridService,
              public dialog: MatDialog,
              public sanitizer: DomSanitizer,
              public loaderService: LoaderService,
              private flowMapService: FlowMapWidgetService,
              private publicTransportService: PublicTransportService,
              private publicTransportStopsService: PublicTransportsStopsService,
              private authenticationService: AuthenticationService,
              private registryService: RegistryService,
              private bridgesWidgetService: BridgesWidgetService,
              private tideService: TideService,
              private arcgisService: ArcgisWidgetService,
              private eventMapService: EventMapWidgetService,
              private publicLightsWidgetService: PublicLightsService,
              private tideMapService: TideMapWidgetService,
              private mapPageService: MapPageService,
              private windService: WindService,
              private translateService: TranslateService,
              private airQualityService: AirQualityWidgetService,
              private culturalEventsService: CulturalEventsService,
              private pedestrianFlowsService: PedestrianFlowsWidgetService,
              private parkingWidgetService: ParkingWidgetService,
              private sanctionsService: SanctionsMapService,
              private componentFactoryResolver: ComponentFactoryResolver,
              public rangeManagerService: RangeManagerService,
              public activatedRoute: ActivatedRoute,
              public configurationService: ConfigurationService,
              public themeService: ThemeService,
              private elementRef: ElementRef,
              private cd: ChangeDetectorRef, private injector: Injector) {
    super(timeMachineService, storageService, sourcesService, appConfigService, gridService, dialog, sanitizer, loaderService, rangeManagerService, activatedRoute);

    document.removeEventListener('visibilitychange', this.browserHidden);
    document.addEventListener('visibilitychange', () => {
      this.browserHidden();
    });
  }

  private browserHidden(): void {
    if (!document.hidden) {
      this.resetAnimationLayer();
    }
  }

  private resetAnimationLayer(): void {
    this.publicTransportService.resetMapMarkers();

    if (this.data) {
      this.data.forEach((panel: MapControlBarPanel) => {
        panel.fields.forEach((level: MapControlBarButton) => {
          if (level.active && level.type === WidgetType.PUBLIC_TRANSPORT_WIDGET) {
            this.removeAnimationData(level);
          }
        });
      });
    }
  }

  private getTide(): number {
    if (this.currentTide) {
      return this.currentTide.level;
    } else {
      return 0;
    }
  }

  private initCurrentTide(): void {
    if (this.pageConfig.tideSources && this.pageConfig.tideSources.length) {
      this.showTide = true;
      this.tideLoading = true;
      this.tideSubscription = this.tideService.loadData(this.currentTimeMachineSelection,
        this.sourcesService.loadSourcesData(this.pageConfig.tideSources), null, this.timeMachineService.forecast)
        .subscribe((currentTide: CurrentTideEntry) => {
          this.tideLoading = false;
          this.currentTide = currentTide;
          this.tideChanged.emit(currentTide);
        }, (error: HttpErrorResponse) => {
          this.tideLoading = false;
        });
    }
  }

  private getMenuButtonLevelKey(level: MapControlBarButton): string {
    if (level.type === 'arcgis_widget') {
      return level.type + level.id;
    } else {
      return level.type + level.additionalData;
    }
  }

  private getLevelKey(level: MapLevel): string {
    return level.type + level.additionalData;
  }

  private getSubMenuLevelKey(level: MapControlBarButton, route: MapSubMenuElement): string {
    return level.type + 'submenu' + level.additionalData + 'key' + route.key;
  }

  private async updateAllLevels(): Promise<void> {
    this.isUpdating = true;
    const promises: Array<Promise<void>> = [];
    if (this.data) {
      this.data.forEach((panel: MapControlBarPanel) => {
        panel.fields.forEach((level: MapControlBarButton) => {
          if (level.active) {
            this.removeLevelData(level);
            promises.push(this.loadLevelData(level));
            if (level.zoomLevel) {
              this.removeZoomLevel(level);
              promises.push(this.loadZoomLevel(level));
            }
          }
        });
      });
    }
    return await Promise.all(promises).then(() => {
      this.isUpdating = false;
    });
  }

  private restoreOldLayers(level: MapControlBarButton): void {
    if (level.submenu) {
      level.submenu.forEach(async (route: MapSubMenuElement) => {
        if (route.selected) {
          const transport: Transports = level.additionalData as Transports;
          const lineRequestData: LineRequest = {
            type: transport,
            routeId: route.key,
            tripId: route.key.toString(),
          };
          this.publicTransportService.getSingleLineLevel(this.timeMachineService.getCurrentSelection(),
            this.sourcesService.loadSourcesData(level.sources) as Array<PublicTransportDataSource>, lineRequestData)
            .subscribe((routeLevel: FeatureGroup) => {
              this.layers.set(this.getSubMenuLevelKey(level, route), routeLevel);
              if (routeLevel) {
                routeLevel.addTo(this.map);
              }
            });
        }
      });
    }
  }

  private removeOldLayers(oldLayers: LayerGroup): void {
    oldLayers.getLayers().forEach((l: Layer) => {
      try {
        this.map.removeLayer(l);
      } catch (err) {
      }
    });
    try {
      this.map.removeLayer(oldLayers);
    } catch (err) {
    }
  }

  private fieldUpdate(level: MapControlBarButton): void {
    const levelId: string = this.getMenuButtonLevelKey(level);
    const subMenuField: MapSubMenu = this.fields.find((subMenu: MapSubMenu) => {
      return subMenu.id === levelId;
    });
    const subMenuElement: MapSubMenu = {
      id: levelId,
      title: level.additionalData,
      elements: level,
    };
    if (!subMenuField) {
      this.fields.push(subMenuElement);
    }
    this.updateActiveLayers(level);
  }

  private updateLegendOnMap(legend: MapLegend, level: MapControlBarButton): void {
    const oldLegend: MapLegend = this.legends.get(level.type);
    if (!oldLegend) {
      this.legends.set(level.type, legend);
      this.activeLegends.push(legend);
    }

    if (this.pageConfig.timeDimension && this.activeLegends && this.activeLegends.length) {
      const legendControl: any = document.getElementsByClassName('map__control-panel');
      if (legendControl && legendControl.length > 0) {
        legendControl[0].classList.add('timedimension');
      }
    }
  }

  private updateTimeDimensionOnMap(layer: FeatureGroup, level: MapControlBarButton): void {
    if (level.active) {
      this.timeDimensionLayers.set(this.getMenuButtonLevelKey(level), layer);
      this.manageTimeDimensionControl();
    }
  }

  private updateLayerOnMap(layer: FeatureGroup, level: MapControlBarButton): void {
    if (level.active) {
      if (document.getElementById('projection')) {
        document.getElementById('projection').innerHTML = this.map.options.crs.code;
      }
      if (level.type !== WidgetType.PUBLIC_TRANSPORT_WIDGET) {
        this.addLayers(layer, level);
      } else {
        this.injector.get(PublicTransportService).setDuration(this.sourcesService.loadSourcesData(level.sources) as Array<PublicTransportDataSource>, this.animation, this.elementRef);
        if (this.animation) {
          this.refreshTrafficPosition(layer, level);
        } else {
          this.addLayers(layer, level);
        }
      }
    }
  }

  private addLayers(layer: FeatureGroup, level: MapControlBarButton): void {
    const oldLayers: FeatureGroup = this.layers.get(this.getMenuButtonLevelKey(level));
    if (oldLayers) {
      this.removeOldLayers(oldLayers);
    }
    this.layers.set(this.getMenuButtonLevelKey(level), layer);
    this.updateActiveLayers(level);
    layer.addTo(this.map);
  }

  private getFeatureGroupByType(level: MapControlBarButton): FeatureGroup {
    const featureGroup1: FeatureGroup = new FeatureGroup();
    this.map.eachLayer((layer: FeatureGroup) => {
      if (layer['type'] === level.additionalData) {
        featureGroup1.addLayer(layer);
      }
    });
    return featureGroup1;
  }

  private async refreshTrafficPosition(layer: FeatureGroup, level: MapControlBarButton): Promise<void> {
    // Additional check to check if level is still active after the promise
    if (level.active && !this.isUpdating) {
      const trafficMarkers: FeatureGroup = this.getFeatureGroupByType(level);
      if (trafficMarkers.getLayers().length) {
        layer.eachLayer((f: Layer) => {
          const publicTransportService: PublicTransportService = this.injector.get(PublicTransportService);
          publicTransportService.updateMapMarker(this.map, f);
        });
      } else {
        layer.eachLayer((f: Layer) => {
          this.map.addLayer(f);
        });

        this.layers.set(this.getMenuButtonLevelKey(level), layer);
        layer['type'] = level.additionalData;
      }
    }
  }

  private updateActiveLayers(level: MapControlBarButton): void {
    if (!this.activeLayers.find((x: MapControlBarButton) => x.label === level.label && x.icon === level.icon)) {
      const label: string = 'PAGES.MAP.LABELS.' + level.label;
      const name: string = this.translateService.instant(label) !== label ? this.translateService.instant(label) : level.label;
      level.panelTitle = name;
      this.activeLayers = [...this.activeLayers, level];
    }
  }

  private updateLayer(service: MapWidgetService, level: MapControlBarButton, offset?: number): void {
    let timestampoffset: number;
    if (this.timeMachineService.isTimeMachineActive()) {
      this.currentTimeMachineSelection = this.timeMachineService.getCurrentSelection();
      if (typeof this.currentTimeMachineSelection === 'number') {
        timestampoffset = this.currentTimeMachineSelection + offset;
      } else {
        timestampoffset = this.currentTimeMachineSelection.getToTimeStamp() + offset;
      }
    } else {
      timestampoffset = new Date().valueOf();
    }
    service.getMapLevel(timestampoffset,
      this.sourcesService.loadSourcesData(level.sources), level.additionalData as Transports).subscribe((layer: FeatureGroup) => {
      // Additional check to check if level is still active after the promise
      if (level.active && !this.isUpdating) {
        this.updateLayerOnMap(layer, level);
      }
    });
  }

  // tslint:disable-next-line:no-big-function
  private async loadLevelData(level: MapControlBarButton): Promise<void> {
    let layer: FeatureGroup;
    let legend: MapLegend;
    let controlComponent: ComponentRef<any>;
    let generateComponent: boolean = false;

    const oldComponent: ComponentRef<any> = this.controlComponents.get(this.getLevelKey(level));
    if (!oldComponent) {
      generateComponent = true;
    }
    this.loaderService.show();

    const bboxList: Array<LatLngBounds> = [];
    if (this.drawnItems.getLayers().length > 0) {
      this.drawnItems.getLayers().forEach((layerD: any) => {
        bboxList.push(layerD.getBounds());
      });
    } else {
      bboxList.push(this.map.getBounds());
    }
    let updateTimeDimension: boolean = false;

    switch (level.type) {
      case WidgetType.WIDGET:
        break;
      case WidgetType.GEOSERVER_WIDGET:
        const geoServerService: GeoserverService = this.injector.get('geoserver_service');
        const geoserverSources: Array<GeoserverDataSource> = this.sourcesService.loadSourcesData(level.sources) as Array<GeoserverDataSource>;
        if (geoServerService.hasTimeDimension(geoserverSources)) {
          updateTimeDimension = true;
          this.manageTimeDimensionControl(true);
        }
        layer = await geoServerService.getMapLevel(this.currentTimeMachineSelection,
          geoserverSources, {
            bbox: bboxList,
            selectedAlert: null,
          }).toPromise();
        legend = geoServerService.getLegend(geoserverSources);
        break;
      case WidgetType.HERE_MAP_WIDGET:
        const hereMapWidgetService: MapWidgetService = this.injector.get('here_map_widget_service');
        layer = await hereMapWidgetService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), level.additionalData).toPromise();
        break;
      case WidgetType.MESSAGE_PANELS_WIDGET:
        const messagePanelService: MapWidgetService = this.injector.get('message_panels_widget_service');
        layer = await messagePanelService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), {
            bbox: bboxList,
          }).toPromise();
        break;
      case WidgetType.PUBLIC_TRANSPORT_WIDGET:
        try {
          if (!level.submenu) {
            const lines: Array<PublicTransportRoute> = await this.publicTransportService.getLines(this.timeMachineService.getCurrentSelection(),
              this.sourcesService.loadSourcesData(level.sources) as Array<PublicTransportDataSource>, level.additionalData as Transports).toPromise();
            lines.sort((a: PublicTransportRoute, b: PublicTransportRoute) => {
              return b.key - a.key;
            });
            level.submenu = lines.map((line: PublicTransportRoute) => {
              return {
                id: line.key,
                group: line.shortName,
                active: true,
                selected: false,
                color: '#' + line.color,
                textColor: '#' + line.textColor,
                label: `${line.longName}`,
                labelIcon: `${line.shortName}`,
                key: line.key,
                sources: level.sources,
              };
            });
          }
          level.submenuActive = true;
        } catch (error) {
          level.submenuActive = false;
        }
        this.fieldUpdate(level);
        this.restoreOldLayers(level);
        this.publicTransportService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources) as Array<PublicTransportDataSource>, level.additionalData as Transports)
          .pipe(takeWhile(() => {
            return level.active;
          }))
          .subscribe((transportLayer: FeatureGroup) => {
            this.updateLayerOnMap(transportLayer, level);
          });
        legend = this.publicTransportService.getLegend();
        break;
      case WidgetType.PUBLIC_TRANSPORT_STOPS_WIDGET:
        layer = await this.publicTransportStopsService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources) as Array<PublicTransportDataSource>, level.additionalData).toPromise();
        break;
      case WidgetType.PARKING_WIDGET:
        layer = await this.parkingWidgetService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), { levelInfo: level }).toPromise();
        legend = this.parkingWidgetService.getLegend();
        break;
      case WidgetType.REGISTRY_WIDGET:
        if (!level.staticSubMenu) {
          const staticMenu: MapStaticSubMenu<GeocodingRequest> = {
            content: 'PAGES.MAP.CLICK_HERE',
            data: null,
          };
          level.staticSubMenu = staticMenu;
        }
        this.fieldUpdate(level);
        level.submenuActive = true;
        break;
      case WidgetType.BRIDGES_WIDGET:
        const additionalData: BridgesRequest = {
          type: level.additionalData as BridgesType,
          elevation: this.bridgesWidgetService.arrowValue,
          tide: this.currentTide.level,
        };
        layer = await this.bridgesWidgetService
          .getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources), additionalData, this.timeMachineService.forecast).toPromise();
        legend = this.bridgesWidgetService.getLegend(level.additionalData as BridgesType);
        if (generateComponent) {
          controlComponent =
            this.bridgesWidgetService.getControl(level.additionalData as BridgesType, this.controlContainer, this.componentFactoryResolver);
          if (controlComponent) {
            controlComponent.instance.bridgesService = this.bridgesWidgetService;
            controlComponent.instance.originalTideValue = this.currentTide.level;
            controlComponent.instance.tideValue = this.currentTide.level;
            controlComponent.instance.boatHeightValue = this.bridgesWidgetService.arrowValue;
            this.tideChanged.subscribe((c: CurrentTideEntry) => {
              controlComponent.instance.originalTideValue = c.level;
            });
            controlComponent.instance.sliderChanged.subscribe(async (bridgeControlValues: BridgesControlValues) => {
              this.loaderService.show();
              const updatedAdditionalData: BridgesRequest = {
                type: level.additionalData as BridgesType,
                elevation: bridgeControlValues.boatHeight,
                tide: bridgeControlValues.tide,
              };
              layer = await this.bridgesWidgetService
                .getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources),
                  updatedAdditionalData, this.timeMachineService.forecast).toPromise();
              this.updateLayerOnMap(layer, level);
              this.loaderService.hide();
            });
          }
        }
        break;
      case WidgetType.HEATMAP_WIDGET:
        const heatmapService: HeatmapService = this.injector.get('heatmap_service');
        const heatmapSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
        layer = await heatmapService.getMapLevel(this.currentTimeMachineSelection,
          heatmapSources, []).toPromise();
        // legend = heatmapService.getLegend(heatmapSources as Array<WidgetDataSource>);
        break;
      case WidgetType.PRESENCES_CLUSTER_WIDGET:
        const presencesClusterService: PresencesClusterService = this.injector.get('presences_cluster_service');
        const presencesClusterSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
        layer = await presencesClusterService.getMapLevel(this.currentTimeMachineSelection,
          presencesClusterSources, level.additionalData).toPromise();
        // legend = heatmapService.getLegend(heatmapSources as Array<WidgetDataSource>);
        break;
      case WidgetType.VIDEOSURVEILLANCE_WIDGET:
        const videosurveillanceService: VideosurveillanceWidgetService = this.injector.get('video_surveillance_service');
        const videoSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
        layer = await videosurveillanceService.getMapLevel(this.currentTimeMachineSelection,
          videoSources as Array<ArcgisSource>, { levelInfo: level }).toPromise();
        legend = videosurveillanceService.getLegend(videoSources as Array<ArcgisSource>);
        break;
      case WidgetType.ARCGIS_WIDGET:
        if (level.label === 'HOTSPOT') {
          if (generateComponent) {
            controlComponent = this.arcgisService.getControl(this.controlContainer, this.componentFactoryResolver);
            if (controlComponent) {
              controlComponent.instance.arcgisService = this.arcgisService;
              controlComponent.instance.filterValue = (this.currentArcgisFilter) ? this.currentArcgisFilter : 0;
              controlComponent.instance.filterChanged.subscribe(async (filterValue: number) => {
                const updatedAdditionalData: ArcgisMapRequest = {
                  filterValue: filterValue,
                  levelInfo: level,
                };
                this.currentArcgisFilter = filterValue;
                layer = await this.arcgisService.getMapLevel(this.currentTimeMachineSelection,
                  this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>,
                  updatedAdditionalData).toPromise();
                this.updateLayerOnMap(layer, level);
              });
            }
          } else {
            const updatedAdditionalData: ArcgisMapRequest = {
              filterValue: this.currentArcgisFilter,
              levelInfo: level,
            };
            layer = await this.arcgisService.getMapLevel(this.currentTimeMachineSelection,
              this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>, updatedAdditionalData).toPromise();
          }
        } else {
          const arcgisSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
          layer = await this.arcgisService.getMapLevel(this.currentTimeMachineSelection,
            arcgisSources as Array<ArcgisSource>, { levelInfo: level }).toPromise();
          legend = this.arcgisService.getLegend(arcgisSources as Array<ArcgisSource>, { levelInfo: level });
        }
        break;
      case WidgetType.CULTURAL_EVENTS_WIDGET:
        layer = await this.culturalEventsService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources)).toPromise();
        break;
      case WidgetType.PEDESTRIAN_FLOW_WIDGET:
        layer = await this.pedestrianFlowsService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), level.additionalData as PedestrianFlowMode).toPromise();
        legend = this.pedestrianFlowsService.getLegend(level.additionalData as PedestrianFlowMode);
        break;
      case WidgetType.PUBLIC_LIGHTS_WIDGET:
        if (generateComponent) {
          controlComponent = this.publicLightsWidgetService.getControl(this.controlContainer, this.componentFactoryResolver);
          if (controlComponent) {
            controlComponent.instance.publicLightsWidgetService = this.publicLightsWidgetService;
            controlComponent.instance.selectedItems = this.currentLightsFilter;
            controlComponent.instance.typeSelected.subscribe(async (filterValue: Array<PublicLightsType>) => {
              const updatedAdditionalData: PublicLightsRequest = {
                filter: filterValue,
                levelInfo: level,
              };
              this.currentLightsFilter = filterValue;
              layer = await this.publicLightsWidgetService.getMapLevel(this.currentTimeMachineSelection,
                this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>,
                updatedAdditionalData).toPromise();
              this.updateLayerOnMap(layer, level);
            });
          }
        } else {
          const updatedAdditionalData: PublicLightsRequest = {
            filter: this.currentLightsFilter,
            levelInfo: level,
          };
          layer = await this.publicLightsWidgetService.getMapLevel(this.currentTimeMachineSelection,
            this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>,
            updatedAdditionalData).toPromise();
        }
        break;
      case WidgetType.EVENT_MAP_WIDGET:
        if (generateComponent) {
          controlComponent = this.eventMapService.getControl(this.controlContainer, this.componentFactoryResolver);
          if (controlComponent) {
            controlComponent.instance.eventMapService = this.eventMapService;
            controlComponent.instance.filterValue = (this.currentEventFilter) ? this.currentEventFilter : 20;
            this.tideChanged.subscribe((c: CurrentTideEntry) => {
              controlComponent.instance.originalTideValue = c.level;
            });
            controlComponent.instance.filterChanged.subscribe(async (filterValue: number) => {
              const updatedAdditionalData: EventMapRequest = {
                publicWorksState: filterValue,
                levelInfo: level,
              };
              this.currentEventFilter = filterValue;
              layer = await this.eventMapService.getMapLevel(this.currentTimeMachineSelection,
                this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>,
                updatedAdditionalData).toPromise();
              this.updateLayerOnMap(layer, level);
            });
          }
        } else {
          const updatedAdditionalData: EventMapRequest = {
            publicWorksState: this.currentEventFilter,
            levelInfo: level,
          };
          layer = await this.eventMapService.getMapLevel(this.currentTimeMachineSelection,
            this.sourcesService.loadSourcesData(level.sources) as Array<EventMapSource>,
            updatedAdditionalData).toPromise();
        }
        break;
      case WidgetType.FLOW_MAP_WIDGET:
        const flowData: FlowMapRequest = JSON.parse(level.additionalData) as FlowMapRequest;
        flowData.bbox = bboxList;
        layer = await this.flowMapService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), flowData).toPromise();
        legend = await this.flowMapService.getLegend(JSON.parse(level.additionalData).type as FlowType).toPromise();
        break;
      case WidgetType.WIND_WIDGET:
        layer = await this.windService.getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources)).toPromise();
        legend = this.windService.getLegend();
        break;
      case WidgetType.AIR_QUALITY_WIDGET:
        layer = await this.airQualityService.getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources)).toPromise();
        legend = this.airQualityService.getLegend();
        break;
      case WidgetType.SANCTIONS_MAP_WIDGET:
        layer = await this.sanctionsService.getMapLevel(this.currentTimeMachineSelection,
          this.sourcesService.loadSourcesData(level.sources), level.additionalData as StreetType).toPromise();
        legend = this.sanctionsService.getLegend();
        break;
      case WidgetType.TIDE_MAP_WIDGET:
        const tideMapData: TideMapType = level.additionalData as TideMapType;
        layer = await this.tideMapService.getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources), {
          tide: this.getTide(),
          zoom: this.map.getZoom(),
        }).toPromise();
        if (generateComponent && tideMapData === TideMapType.SIMULATOR) {
          controlComponent =
            this.tideMapService.getControl(level.additionalData as TideMapType, this.controlContainer, this.componentFactoryResolver);
          if (controlComponent) {
            controlComponent.instance.tideMapService = this.tideMapService;
            controlComponent.instance.sliderValue = this.getTide();
            controlComponent.instance.sliderChanged.subscribe(async (sliderValue: number) => {
              const updatedAdditionalData: TideMapRequest = {
                tide: sliderValue,
                zoom: this.map.getZoom(),
              };
              layer = await this.tideMapService.getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(level.sources), updatedAdditionalData).toPromise();
              this.updateLayerOnMap(layer, level);
            });
          }
        }
        legend = this.tideMapService.getLegend();
        break;
    }
    if (layer) {
      this.updateLayerOnMap(layer, level);
    }
    if (legend) {
      this.updateLegendOnMap(legend, level);
    }
    if (updateTimeDimension) {
      this.updateTimeDimensionOnMap(layer, level);
    }
    if (level.mouseInteraction) {
      this.mouseClickMode = true;
    }
    if (controlComponent) {
      this.controlComponents.set(this.getLevelKey(level), controlComponent);
      this.controlContainer.insert(controlComponent.hostView);
      this.cd.detectChanges();
    }
    this.loaderService.hide();
  }

  private removeAnimationData(level: MapControlBarButton): void {
    if (level.type === WidgetType.PUBLIC_TRANSPORT_WIDGET) {
      const trafficL: FeatureGroup = this.getFeatureGroupByType(level);
      if (trafficL) {
        trafficL.eachLayer((l: Layer) => {
          this.map.removeLayer(l);
        });
      }
      this.publicTransportService.resetMapMarkers();
    }
  }

  private removeLevelData(level: MapControlBarButton): void {
    const updateInterval: Subscription = this.intervals.get(level.additionalData);
    if (updateInterval) {
      updateInterval.unsubscribe();
    }
    if (level.type !== WidgetType.PUBLIC_TRANSPORT_WIDGET || !this.animation) {
      const oldLayers: LayerGroup = this.layers.get(this.getMenuButtonLevelKey(level));
      const oldTimeDimensionLayer: FeatureGroup = this.timeDimensionLayers.get(this.getMenuButtonLevelKey(level));
      if (oldLayers) {
        this.removeOldLayers(oldLayers);
      }
      if (oldTimeDimensionLayer) {
        this.timeDimensionLayers.delete(this.getMenuButtonLevelKey(level));
        this.manageTimeDimensionControl();
      }
    }
    if (!level.active) {
      this.removeAnimationData(level);
    }

    if (level.submenu) {
      level.submenuActive = false;
      this.fieldUpdate(level);
      for (const key of this.layers.keys()) {
        const regex: RegExp = new RegExp(`${level.type + 'submenu'}\\S+key\\S+`);
        if (key.match(regex)) {
          this.map.removeLayer(this.layers.get(key));
        }
        if (key === WidgetType.PUBLIC_TRANSPORT_WIDGET + '-active') {
          this.map.removeLayer(this.layers.get(key));
          this.publicTransportService.setActiveMarker(null);
        }
      }
    }
    // Remove Legends
    const oldLegend: MapLegend = this.legends.get(level.type);
    const index: number = this.activeLegends.indexOf(oldLegend);
    if (index !== -1) {
      this.activeLegends.splice(index, 1);
      this.legends.delete(level.type);
    }

    if (level.mouseInteraction) {
      this.mouseClickMode = false;
    }

    // Remove Control Components if level is unactive
    if (!level.active) {
      const controlComponent: ComponentRef<any> = this.controlComponents.get(this.getLevelKey(level));
      if (controlComponent) {
        const componentIndex: number = this.controlContainer.indexOf(controlComponent.hostView);
        this.controlContainer.remove(componentIndex);
        this.controlComponents.delete(this.getLevelKey(level));
        controlComponent.destroy();
      }
    }
    this.activeLayers = this.activeLayers.filter((name: MapControlBarButton) => {
      if (name.additionalData) {
        return name.additionalData !== level.additionalData;
      } else {
        return name.label !== level.label;
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loaderService.show();


    // this.pageConfig = {
    //   "mapSettings": {
    //     "showMenuBar": true,
    //     "satellitePic": true,
    //     "satellitePicSource": "venice-map",
    //     "center": {
    //       "lat": 45.4287631,
    //       "lon": 12.3238708
    //     },
    //     "zoom": 14,
    //     "maxZoom": 25,
    //     "url": "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
    //   },
    //   "tideSources": ["tide", "tide-forecast"],
    //   "registrySources": ["geocode-service-addresses", "geocode-service-owners"],
    //   "registrySearch": true,
    //   "timeDimension": true,
    //   "timeDimensionControl": true
    // };
    forkJoin([this.configurationService.getConfiguration('map-config'), this.configurationService.getConfiguration('map-menu'), this.configurationService.getUserConfiguration()])
      .subscribe((c: [Configuration, Configuration, Array<Configuration>]) => {
        this.pageConfig = JSON.parse(c[0].configuration);
        const userConfiguration: Array<Configuration> = c[2];
        if (userConfiguration && userConfiguration.length) {
          this.pageConfig.mapSettings.zoom = userConfiguration[0].zoom;
          this.pageConfig.mapSettings.center.lat = userConfiguration[0].latitude;
          this.pageConfig.mapSettings.center.lon = userConfiguration[0].longitude;
        }
        this.animation = this.pageConfig.animation;

        this.intervalActive = true;
        const layers: Array<Layer> = [];
        this.baseLayer = this.themeService.isDark ? this.cartoDbBaseLayerDark : this.cartoDbBaseLayer;
        layers.push(this.baseLayer);
        if (this.pageConfig.mapSettings.satellitePic && this.pageConfig.mapSettings.satellitePicSource) {
          const veniceLayerSource: WidgetDataSource = this.sourcesService.loadSourceData(this.pageConfig.mapSettings.satellitePicSource);
          this.veniceLayer = new DynamicMapLayer({
            url: veniceLayerSource.rewriteUrl,
            f: 'image',
            position: 'front',
            pane: 'tilePane',
            zIndex: 2,
          });
          this.veniceBackgroundLayer = new DynamicMapLayer({
            minZoom: 20,
            url: veniceLayerSource.rewriteUrl,
            f: 'image',
            position: 'front',
            pane: 'tilePane',
            zIndex: 2,
          });
          layers.push(this.veniceBackgroundLayer);
        }

        this.themeService.sendReload.subscribe(() => {
          if (this.map && this.themeService.isDark) {
            this.map.removeLayer(this.cartoDbBaseLayer);
            this.map.addLayer(this.cartoDbBaseLayerDark);
            this.cartoDbBaseLayerDark.bringToBack();
          } else {
            this.map.removeLayer(this.cartoDbBaseLayerDark);
            this.map.addLayer(this.cartoDbBaseLayer);
            this.cartoDbBaseLayer.bringToBack();
          }
        });

        this.leafletOptions = {
          layers: layers,
          zoom: this.pageConfig.mapSettings.zoom,
          maxZoom: this.pageConfig.mapSettings.maxZoom,
          center: latLng(this.pageConfig.mapSettings.center.lat, this.pageConfig.mapSettings.center.lon),
          // @ts-ignore
          timeDimension: this.pageConfig.timeDimension,
        };

        if (this.pageConfig.timeDimension) {
          // @ts-ignore
          this.leafletOptions.timeDimensionOptions = {
            timeInterval: 'P1W/' + this.timeMachineService.getEndTimeMachineISOPeriod(this.timeMachineService.getCurrentSelection()),
            period: 'P1D',
            currentTime: this.timeMachineService.getCurrentSelection(),
          };
        }

        // @ts-ignore
        this.timeDimensionControlOptions = {
          position: 'bottomleft',
          loopButton: true,
          autoPlay: true,
          timeZones: ['Local'],
          speedStep: 1,
          minSpeed: 1,
          maxSpeed: 5,
          playerOptions: {
            loop: true,
            buffer: 5,
            startOver: true,
          },
          speedSlider: false,
        };

        this.timeDimensionControl = new L.control.timeDimension(this.timeDimensionControlOptions);

        this.drawOptions = {
          position: 'topleft',
          edit: {
            featureGroup: this.drawnItems,
          },
          draw: {
            marker: false,
            circle: false,
            circlemarker: false,
            polyline: false,
          },
        };
        this.currentTimeMachineSelection = this.timeMachineService.getCurrentSelection();

        this.timeMachineSubscription = this.timeMachineService.timeMachineChanged.subscribe((newValue: TimeMachineData) => {
          this.onTimeMachineChange(newValue);
        });
        this.publicTransportService.activateLoader.subscribe((loader: boolean) => {
          loader ? this.loaderService.show() : this.loaderService.hide();
        });

        this.data = JSON.parse(c[1].configuration);
        this.originalData = cloneDeep(this.data);
        this.initCurrentTide();
        this.loaderService.hide();
      });
  }

  async onTimeMachineChange(newValue: TimeMachineData): Promise<void> {
    this.currentTimeMachineSelection = newValue;
    this.resetAnimationLayer();
    this.initCurrentTide();
    // @ts-ignore
    // if (this.map.options.timeDimensionOptions) {
    // @ts-ignore
    // this.map.options.timeDimensionOptions.timeInterval = this.timeMachineService.getTimeMachineISOPeriod(newValue);
    // }
    this.updateAllLevels();
  }

  toggleVeniceLevel(value: boolean): void {
    this.veniceLayerOn = value;
    if (value) {
      this.map.addLayer(this.veniceLayer);
    } else {
      this.map.removeLayer(this.veniceLayer);
    }
  }

  activeFields(): boolean {
    if (this.fields && this.fields.length) {
      return this.fields.filter((f: MapSubMenu) => {
        return f.elements.active;
      }).length > 0;
    }
    return false;
  }

  manageTimeDimensionControl(force?: boolean): void {
    if (this.timeDimensionLayers.size && !this.timeDimensionControlOn) {
      this.timeDimensionControlOn = true;
      this.map.addControl(this.timeDimensionControl);
    } else if (!this.timeDimensionLayers.size) {
      this.timeDimensionControlOn = false;
      this.map.removeControl(this.timeDimensionControl);
    }
    if (!this.timeDimensionControlOn && force) {
      this.timeDimensionControlOn = true;
      this.map.addControl(this.timeDimensionControl);
    }
  }

  ngOnDestroy(): void {
    /**
     * Removing timers
     */
    if (this.timeMachineSubscription) {
      this.timeMachineSubscription.unsubscribe();
    }
    if (this.tideSubscription) {
      this.tideSubscription.unsubscribe();
    }
    this.intervalActive = false;
    this.intervals.forEach((value: Subscription) => {
      value.unsubscribe();
    });
    document.removeEventListener('visibilitychange', this.browserHidden);
    super.ngOnDestroy();
  }

  invalidateMap(): void {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  processSubMenuItemLayers(event: SubMenuChangeEvent, subMenuItemLayers: FeatureGroup): void {
    event.submenu.selected = event.status;
    if (event.status) {
      subMenuItemLayers.addTo(this.map);
      const layerFeatureGroup: FeatureGroup = new FeatureGroup();
      for (const key of this.layers.keys()) {
        const regex: RegExp = new RegExp(`${event.field.type + 'submenu'}\\S+key\\S+`);
        if (key.match(regex)) {
          layerFeatureGroup.addLayer(this.layers.get(key));
        }
      }
      if (layerFeatureGroup.getLayers().length > 0) {
        this.map.fitBounds(layerFeatureGroup.getBounds());
      }
    } else {
      this.map.removeLayer(subMenuItemLayers);
    }
    event.submenu.elements = [].concat(event.submenu.elements);
  }

  recordShown(): void {
    this.isTimeMachinePopoverOpen = true;
  }

  recordHidden(): void {
    this.isTimeMachinePopoverOpen = false;
  }

  handleSubMenuChange(event: SubMenuChangeEvent): void {
    this.loaderService.show();
    switch (event.field.type) {
      case WidgetType.WIDGET:
        break;
      case WidgetType.PUBLIC_TRANSPORT_WIDGET:
        const levelId: string = this.getSubMenuLevelKey(event.field, event.submenu);
        let subMenuItemLayers: FeatureGroup = this.layers.get(levelId);
        if (!subMenuItemLayers) {
          const transport: Transports = event.field.additionalData as Transports;
          const lineRequestData: LineRequest = {
            type: transport,
            routeId: event.submenu.key,
            tripId: event.submenu.key.toString(),
          };
          this.publicTransportService.getSingleLineLevel(this.timeMachineService.getCurrentSelection(),
            this.sourcesService.loadSourcesData(event.field.sources) as Array<PublicTransportDataSource>, lineRequestData).subscribe((layers: FeatureGroup) => {
            subMenuItemLayers = layers;
            this.layers.set(levelId, subMenuItemLayers);
            this.processSubMenuItemLayers(event, subMenuItemLayers);
          });
        } else {
          this.processSubMenuItemLayers(event, subMenuItemLayers);
        }
        break;
    }
    this.loaderService.hide();
    // Trigger refresh
  }

  handleSubMenuSelectAll(event: SubMenuSelectAllEvent): void {
    this.loaderService.show();
    switch (event.field.type) {
      case WidgetType.WIDGET:
        break;
      case WidgetType.PUBLIC_TRANSPORT_WIDGET:
        event.field.submenu.forEach((element: MapSubMenuElement) => {
          const levelId: string = this.getSubMenuLevelKey(event.field, element);
          const layerGroup: LayerGroup = this.layers.get(levelId);
          if (layerGroup) {
            if (event.value) {
              element.selected = true;
              layerGroup.addTo(this.map);
            } else {
              element.selected = false;
              layerGroup.remove();
            }
          }
        });
        break;
    }
    this.loaderService.hide();
  }

  // tslint:disable-next-line:no-big-function
  onMapReady(leafletMap: LeafletMap): void {
    this.map = leafletMap;
    new Control.Scale({ imperial: false }).addTo(this.map);

    L.Control.Projection = L.Control.extend({
      onAdd: () => {
        const el: any = L.DomUtil.create('div', 'leaflet-control-scale leaflet-control');
        el.innerHTML = '<div class="leaflet-control-scale-line" id="projection">' + this.map.options.crs.code + '</div>';
        return el;
      },
      onRemove: () => {
        // Nothing to do here
      },
    });
    L.control.projection = (opts: any) => {
      return new L.Control.Projection(opts);
    };

    L.control.projection({
      position: 'bottomleft',
    }).addTo(this.map);

    if (this.pageConfig.mapSettings.satellitePic && this.veniceLayerOn) {
      this.veniceLayer.addTo(this.map);
      this.veniceLayer.bringToFront();
    }
    this.map.addControl(new L.Control.LinearMeasurement({
      color: '#1e88e5',
      unitSystem: 'metric', // imperial | metric
      type: 'ruler',
    }));
    this.map.addControl(new L.Control.Measure({
      position: 'topleft',
      primaryLengthUnit: 'kilometers',
      secondaryLengthUnit: 'meters',
      primaryAreaUnit: 'sqmeters',
      activeColor: '#1e88e5',
      completedColor: '#42a5f5',
      decPoint: ',',
      thousandsSep: '.',
    }));
    this.publicTransportService.newActiveMarker.subscribe((activeMarker: NewActiveLine) => {
      if (activeMarker) {
        this.publicTransportService.setActiveMarker(activeMarker.marker);
      } else {
        const levelId: string = WidgetType.PUBLIC_TRANSPORT_WIDGET + '-active';
        const layerGroup: FeatureGroup = this.layers.get(levelId);
        if (layerGroup) {
          this.map.removeLayer(layerGroup);
        }
      }
    });
    this.publicTransportService.activeStop.subscribe(async (s: StopSelect) => {
      const publicTransportsField: MapSubMenu = this.fields.find((field: MapSubMenu) => {
        return field.title === s.type;
      });
      if (publicTransportsField) {
        this.loaderService.show();
        this.publicTransportService.getTimeTable(this.timeMachineService.getCurrentSelection(),
          this.sourcesService.loadSourcesData(publicTransportsField.elements.sources) as Array<PublicTransportDataSource>, s).subscribe((result: DataResult<TimetableEntry>) => {
          const timeTableData: TimeTableData = {
            stopKey: s.stopKey,
            timetable: result.data,
          };
          this.dialog.closeAll();
          this.dialog.open(TimetableModalComponent, {
            data: timeTableData,
          });
          this.loaderService.hide();
        });
      }
    });
    this.publicTransportService.activeLine.subscribe(async (l: LineSelect) => {
      this.loaderService.show();
      const publicTransportsField: MapSubMenu = this.fields.find((field: MapSubMenu) => {
        return field.title === l.type;
      });
      if (publicTransportsField) {
        const publicTransportGenRoute: MapSubMenuElement = publicTransportsField.elements.submenu.splice(0, 1)[0];
        publicTransportGenRoute.key = Number(l.lineId);
        this.loaderService.show();
        const lineRequestData: LineRequest = {
          type: l.type,
          routeId: Number(l.lineId),
          tripId: l.tripId,
          shiftId: l.shiftId,
        };
        this.loaderService.show();
        this.publicTransportService.getSingleLineLevel(this.timeMachineService.getCurrentSelection(),
          this.sourcesService.loadSourcesData(publicTransportsField.elements.sources) as Array<PublicTransportDataSource>, lineRequestData)
          .subscribe((layer: FeatureGroup) => {
            this.loaderService.hide();
            const levelId: string = WidgetType.PUBLIC_TRANSPORT_WIDGET + '-active';
            const layerGroup: FeatureGroup = this.layers.get(levelId);
            if (layerGroup) {
              this.map.removeLayer(layerGroup);
            }
            this.layers.set(levelId, layer);
            if (layer) {
              layer.addTo(this.map);
            }
            this.loaderService.hide();
          });
      }
    });
    this.map.on('mousemove', (e: LeafletMouseEvent) => {
      if (this.mouseClickMode) {
        if (!this.circleLayer) {
          this.circleLayer = circle(e.latlng, { radius: 10 });
          this.circleLayer.addTo(this.map);
        }
        this.circleLayer.setLatLng(e.latlng);
      } else {
        if (this.circleLayer) {
          this.map.removeLayer(this.circleLayer);
          this.circleLayer = null;
        }
      }
    });
    this.map.on('moveend', async (e: LeafletMouseEvent) => {
      if (this.changeDefault) {
        await this.refreshGeoServer();
      }
    });
    this.map.on('mousedown', async (e: LeafletMouseEvent) => {
      this.changeDefault = true;
      if (this.mouseClickMode) {
        /*const registryWidgetLevel: MapControlBarButton = this.getButtonOfMapLevel(WidgetInstance.REGISTRY_WIDGET);
        const data: GeocodingLatitudeRequest = {
          type: GeocodingRequestType.LATITUDE,
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
          radius: 10 / 1000,
        };
        const layer: FeatureGroup = await this.registryService.getMapLevel(this.timestamp, this.sourcesService.loadSourcesData(registryWidgetLevel.sources, true), data);
        const oldLayer: FeatureGroup = this.layers.get(registryWidgetLevel.type + registryWidgetLevel.additionalData);
        if (oldLayer) {
          this.map.removeLayer(oldLayer);
        }
        if (layer) {
          layer.addTo(this.map);
          this.layers.set(registryWidgetLevel.type + registryWidgetLevel.additionalData, layer);
        }*/
      }
    });
    this.map.on('zoomstart', async (e: LeafletEvent) => {
      this.elementRef.nativeElement.style.setProperty('--railDuration', '1ms');
      this.elementRef.nativeElement.style.setProperty('--roadDuration', '1ms');
      this.elementRef.nativeElement.style.setProperty('--waterDuration', '1ms');
    });
    this.map.on('zoomend', async (e: LeafletEvent) => {
      const trafficMapLevelPanel: MapControlBarPanel = this.data.find((m: MapControlBarPanel) => {
        return m.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.PUBLIC_TRANSPORT_WIDGET;
        }) !== undefined;
      });
      if (trafficMapLevelPanel) {
        const trafficMapLevelWidget: MapControlBarButton = trafficMapLevelPanel.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.PUBLIC_TRANSPORT_WIDGET;
        });
        if (trafficMapLevelWidget.active) {
          this.injector.get(PublicTransportService).setDuration(this.sourcesService.loadSourcesData(trafficMapLevelWidget.sources) as Array<PublicTransportDataSource>, this.animation, this.elementRef);
        }
      }
    });
    this.map.on('zoom', async (e: LeafletEvent) => {
      this.changeDefault = true;
      const fiberMapLevelPanel: MapControlBarPanel = this.data.find((m: MapControlBarPanel) => {
        return m.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.ARCGIS_WIDGET && f.additionalData === 'fiber';
        }) !== undefined;
      });
      if (fiberMapLevelPanel) {
        const fiberMapLevelWidget: MapControlBarButton = fiberMapLevelPanel.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.ARCGIS_WIDGET && f.additionalData === 'fiber';
        });
        if (fiberMapLevelWidget.active && fiberMapLevelWidget.zoomLevel) {
          this.loadZoomLevel(fiberMapLevelWidget);
        }
      }
      const tideMapLevelSimulatorPanel: MapControlBarPanel = this.data.find((m: MapControlBarPanel) => {
        return m.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.TIDE_MAP_WIDGET && f.additionalData === TideMapType.SIMULATOR;
        }) !== undefined;
      });
      if (tideMapLevelSimulatorPanel) {
        const tideMapLevelSimulatorWidget: MapControlBarButton = tideMapLevelSimulatorPanel.fields.find((f: MapControlBarButton) => {
          return f.type === WidgetType.TIDE_MAP_WIDGET && f.additionalData === TideMapType.SIMULATOR;
        });
        if (tideMapLevelSimulatorWidget.active) {
          const controlComponent: ComponentRef<TideControlComponent>
            = this.controlComponents.get(tideMapLevelSimulatorWidget.type + tideMapLevelSimulatorWidget.additionalData);
          if (controlComponent) {
            this.tideMapService.getMapLevel(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(tideMapLevelSimulatorWidget.sources), {
              tide: controlComponent.instance.sliderValue,
              zoom: this.map.getZoom(),
            }).subscribe((layer: FeatureGroup) => {
              this.updateLayerOnMap(layer, tideMapLevelSimulatorWidget);
            });
          }
        }
      }
    });
    this.arcgisService.featureChanged.subscribe((e: FeatureEvent) => {
      const feature: Layer = e.featureLayer;
      if (e.source && e.source.sourceData && e.source.sourceData.iconAttribute) {
        switch (e.status) {
          case FeatureStatus.CREATED:
            // @ts-ignore
            const coordinates: LatLngs = feature.getLatLngs();
            const bounds: LatLngBounds = new LatLngBounds(coordinates);
            const center: LatLng = bounds.getCenter();
            let markerLabel: string = '';
            e.source.sourceData.iconAttribute.forEach((a: IconAttributes, i: number) => {
              const templateKey: string = e.feature.properties[a.key];
              if (templateKey !== a.defaultValue) {
                if (i > 0) {
                  markerLabel = markerLabel + '/';
                }
                markerLabel = markerLabel + templateKey;
              }
            });
            const markerClass: string = e.source.sourceData.iconClass.toLowerCase();
            const marker: Marker = new Marker(center, {
              icon: new DivIcon({
                className: `arcgis-marker arcgis-marker--${e.source.name}`,
                html: `<div class=\'marker-pin ${markerClass}\' ><span>${markerLabel}</span></div>`,
              }),
            });
            this.labels[e.feature.id] = marker;
            const tooltip: Tooltip = this.arcgisService.loadTooltipFromData(e.feature, e.source, e.additionalData);
            const template: string | HTMLElement | ((source: Layer) => Content) = tooltip.getContent();
            const dataSource: WidgetDataSource = this.sourcesService.loadSourceData(e.source.sourceData.fetchDataSource);
            marker.addTo(this.map);
            marker.on('click', () => {
              const popup: Popup = new Popup({
                autoClose: true,
                closeOnClick: true,
              });
              popup.setContent(template);
              marker.bindPopup(popup);
              marker.openPopup();
              const registryViewerPermission: SpecialPermissionConfig = this.appConfigService.getSpecialPermissionConfig('registry-viewer');
              if (registryViewerPermission && this.authenticationService.checkPermissionsConfig(registryViewerPermission)) {
                this.registryService.getRegistryInfo(dataSource, e.feature).subscribe((data: Array<Person>) => {
                  const personTemplate: string = this.registryService.parsePeopleTemplate(data);
                  const finalTemplate: string = template + personTemplate;
                  popup.setContent(finalTemplate);
                  marker.bindPopup(popup);
                  marker.openPopup();
                });
              }
            });
            break;
          case FeatureStatus.UPDATED:
            const addMarker: Marker = this.labels[e.feature.id];
            this.map.addLayer(addMarker);
            break;
          case FeatureStatus.DELETED:
            const removeMarker: Marker = this.labels[e.feature.id];
            this.map.removeLayer(removeMarker);
            break;
        }
      }
    });

    this.updateAllLevels();
  }

  async refreshGeoServer(): Promise<void> {
    this.isUpdating = true;
    const promises: Array<Promise<void>> = [];

    this.activeLayers.forEach((level: MapControlBarButton) => {
      if (level.type === WidgetType.GEOSERVER_WIDGET || level.type === WidgetType.FLOW_MAP_WIDGET ||
        level.type === WidgetType.MESSAGE_PANELS_WIDGET) {
        this.removeLevelData(level);
        promises.push(this.loadLevelData(level));
        if (level.zoomLevel) {
          this.removeZoomLevel(level);
          promises.push(this.loadZoomLevel(level));
        }
      }
    });
    return await Promise.all(promises).then(() => {
      this.isUpdating = false;
      this.changeDefault = false;
    });
    // const oldLayers: FeatureGroup = this.layers.get(WidgetType.GEOSERVER_WIDGET + this.boundingBox);
    // if (oldLayers) {
    //   this.removeOldLayers(oldLayers);
    // }
  }

  async loadZoomLevel(originalLevel: MapControlBarButton): Promise<void> {
    const zoomMapLevelWidget: MapControlBarButton = Object.assign({}, originalLevel);
    zoomMapLevelWidget.sources = originalLevel.zoomSources;
    zoomMapLevelWidget.additionalData = originalLevel.additionalZoomData;
    zoomMapLevelWidget.active = this.map.getZoom() >= originalLevel.zoomLevel;
    if (zoomMapLevelWidget.active) {
      await this.loadLevelData(zoomMapLevelWidget);
    } else {
      this.removeLevelData(zoomMapLevelWidget);
    }
  }

  async removeZoomLevel(originalLevel: MapControlBarButton): Promise<void> {
    const zoomMapLevelWidget: MapControlBarButton = Object.assign({}, originalLevel);
    zoomMapLevelWidget.sources = originalLevel.zoomSources;
    zoomMapLevelWidget.additionalData = originalLevel.additionalZoomData;
    this.removeLevelData(zoomMapLevelWidget);
  }

  async loadMapLevel(level: MapControlBarButton): Promise<void> {
    this.loaderService.show();
    try {
      if (level.active) {
        // rimuove gli altri livelli del gruppo
        const menu: MapControlBarPanel = this.data.find((m: MapControlBarPanel) => {
          return m.fields.find((f: MapControlBarButton) => {
            return f.id === level.id;
          }) !== undefined;
        });

        if (menu.mutuallyExclusive) {
          menu.fields.forEach((item: MapControlBarButton) => {
            if (item.id !== level.id) {
              this.disabledLayer(item);
            }
          });
        }
        await this.loadLevelData(level);
        if (level.zoomLevel) {
          this.loadZoomLevel(level);
        }

        if (level.startingLat && level.startingLon && level.startingZoom) {
          this.map.flyTo(latLng(level.startingLat, level.startingLon), level.startingZoom);
        }
      } else {
        this.removeLevelData(level);
        if (level.zoomLevel) {
          this.removeZoomLevel(level);
        }
        // if (!this.activeLayers.find((levelA: MapControlBarButton) => levelA.type === WidgetType.GEOSERVER_WIDGET)) {
        //   this.drawnItems = featureGroup();
        // }
      }
      this.loaderService.hide();
    } catch (err) {
      this.loaderService.hide();
      throw err;
    }
    this.map.invalidateSize();
  }


  updateRegistryAutocomplete(query: RegistrySearchQuery): void {
    const data: GeocodingAddressRequest = {
      type: GeocodingRequestType.ADDRESS,
      address: query.argument,
      addressType: query.type,
    };
    if (this.oldRegistrySearchSubscription) {
      this.oldRegistrySearchSubscription.unsubscribe();
    }
    this.oldRegistrySearchSubscription = this.registryService.getAddressesList(this.currentTimeMachineSelection, this.sourcesService.loadSourcesData(this.pageConfig.registrySources), data)
      .subscribe((response: Array<AddressResponse>) => {
        this.registryOptions = response;
      });
  }

  async registrySearch(query: RegistrySearchQuery): Promise<void> {
    const key: string = 'registry_search_level';
    this.loaderService.show();
    const data: GeocodingAddressRequest = {
      type: GeocodingRequestType.ADDRESS,
      address: query.argument,
      exactly: query.exactly,
    };
    this.registryService.getMapLevel(this.currentTimeMachineSelection,
      this.sourcesService.loadSourcesData(this.pageConfig.registrySources), data).subscribe((layer: FeatureGroup) => {
      const bounds: LatLngBounds = layer.getBounds();
      this.map.fitBounds(bounds);
      if (this.map.getZoom() > 20) {
        this.map.setZoom(20);
      }
      const oldLayer: FeatureGroup = this.layers.get(key);
      if (oldLayer) {
        this.map.removeLayer(oldLayer);
      }
      if (layer) {
        layer.addTo(this.map);
        this.layers.set(key, layer);
      }
    }, (e: any) => {
      if (e.message === 'NO_RESULTS_FOUND') {
        this.dialog.open(FailureComponent, {
          data: {
            message: 'PAGES.MAP.NO_RESULTS',
          },
        });
      }
      this.loaderService.hide();
    });
    this.loaderService.hide();
  }

  disabledLayer(layer: MapControlBarButton): void {
    if (layer) {
      layer.active = false;
      this.loadMapLevel(layer);
    } else {
      this.resetMap();
    }
  }

  resetMap(): void {
    this.data.forEach((m: MapControlBarPanel) => {
      m.fields.forEach((f: MapControlBarButton) => {
        f.active = false;
      });
    });
    this.layers = new Map<string, FeatureGroup>();
    this.map.eachLayer((l: Layer) => {
      this.map.removeLayer(l);
    });
    this.data = cloneDeep(this.originalData);
    this.intervals.forEach((value: Subscription) => {
      value.unsubscribe();
    });
    this.intervals = new Map<string, Subscription>();
    this.controlComponents = new Map<string, ComponentRef<any>>();
    this.activeLegends = [];
    this.activeLayers = [];
    this.legends = new Map<string, MapLegend>();
    this.fields = [];
    this.baseLayer.addTo(this.map);
    this.veniceBackgroundLayer.addTo(this.map);
    if (this.veniceLayerOn) {
      this.veniceLayer.addTo(this.map);
    }
    this.data = cloneDeep(this.originalData);
    this.updateAllLevels();
    this.controlContainer.clear();
    this.map.setView(latLng(this.pageConfig.mapSettings.center.lat, this.pageConfig.mapSettings.center.lon), this.pageConfig.mapSettings.zoom);
  }

  clearRegistry(): void {
    const key: string = 'registry_search_level';
    const oldLayer: FeatureGroup = this.layers.get(key);
    if (oldLayer) {
      this.map.removeLayer(oldLayer);
    }
  }

  onDrawCreated(e: DrawEvents.Created): void {
    this.drawnItems.addLayer(e.layer);
    this.refreshGeoServer();
  }

  onDrawDeleted(e: DrawEvents.Deleted): void {
    e.layers.eachLayer((layer: Layer) => {
      this.drawnItems.removeLayer(layer);
    });
    // this.removeAllDrawLayer(WidgetType.GEOSERVER_WIDGET);
    this.refreshGeoServer();
  }

  onDrawEdited(e: DrawEvents.Edited): void {
    this.refreshGeoServer();
  }
}
