import { Injectable } from '@angular/core';
import { StreetEntry, StreetType, TransitCount } from '@app/features/pages/sanctions-page/model';
import { SANCTIONS_LEGEND } from '@app/modules/widgets/sanctions/sanctions-map/data/sanctions-legend';
import { SanctionsService } from '@app/modules/widgets/sanctions/sanctions.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon } from 'geojson';
import { FeatureGroup, geoJSON, GeoJSONOptions, Layer, PathOptions } from 'leaflet';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SanctionsMapService extends SanctionsService {

  private static getStreetIds(streets: Array<StreetEntry>): Array<number> {
    return streets.filter((st: StreetEntry) => st.id !== 0).map((street: StreetEntry) => street.id);
  }

  private static getColor(counter: number): string {
    switch (true) {
      case counter <= 10:
        return '#28a745';
      case counter <= 25:
        return '#ffc107';
      default:
        return '#dc3545';
    }
  }

  private styleFeature(transitCount: Array<TransitCount>): (f: Feature<Geometry>) => PathOptions {
    return (f: Feature<Geometry>): PathOptions => {
      const transit: TransitCount = transitCount.find((t: TransitCount) => {
        return f.id.toString() === t.id;
      });
      let fillColor: string = '#B09060';
      if (transit) {
        fillColor = SanctionsMapService.getColor(transit.counter);
      }
      return {
        stroke: true,
        fill: true,
        fillColor: fillColor,
        color: '#000',
        fillOpacity: 1,
        weight: 0.5,
      };
    };
  }

  private getStreetsShapes(timeMachineData: TimeMachineData,
                           sources: Array<WidgetDataSource>): Observable<FeatureCollection<MultiPolygon>> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'sanctions-streets';
    });
    let url: string;
    url = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 1);
    return this.http.get<FeatureCollection<MultiPolygon>>(url);
  }


  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>, additionalData: StreetType): Observable<FeatureGroup> {
    const streetListObservable: Observable<Array<StreetEntry>> = this.getStreetList(timeMachineData, sources, [additionalData]);
    return streetListObservable.pipe(switchMap((streets: Array<StreetEntry>) => {
      const activeStreetsId: Array<number> = SanctionsMapService.getStreetIds(streets);
      const shapesObservable: Observable<FeatureCollection> = this.getStreetsShapes(timeMachineData, sources);
      const streetsObservable: Observable<Array<TransitCount>> = this.getPeriodTransitCount(timeMachineData, sources, activeStreetsId);
      return forkJoin([shapesObservable, streetsObservable]).pipe(
        switchMap((data: [FeatureCollection<Geometry, GeoJsonProperties>, Array<TransitCount>]) => {
          const f: FeatureCollection<Geometry, GeoJsonProperties> = data[0];
          const transitCount: Array<TransitCount> = data[1];
          const featureGroup: FeatureGroup = new FeatureGroup();
          const options: GeoJSONOptions = {
            style: this.styleFeature(transitCount),
          };
          featureGroup.addLayer(geoJSON(f, options));
          return of(featureGroup);
        }),
      );
    }));
  }

  public getLegend(): MapLegend {
    return SANCTIONS_LEGEND;
  }
}
