import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { MapWidgetData } from '@app/modules/widgets/map-widget/model/interfaces/mapWidgetData';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { HereMapWidgetService } from '@app/modules/widgets/here-map-widget/here-map-widget.service';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapConfig } from '@app/modules/widgets/map-widget/model';
import { FeatureGroup } from 'leaflet';

@Component({
  selector: 'app-here-map-widget',
  templateUrl: './here-map-widget.component.html',
  styleUrls: ['./here-map-widget.component.scss'],
})
export class HereMapWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  @Input()
  mapId: string;

  @Input()
  data: MapWidgetData;

  defaultConfig: MapConfig;

  map: any;

  currentLevel: FeatureGroup;

  @ViewChild('heremap', { static: true })
  public mapElement: ElementRef;

  constructor(public widgetService: HereMapWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources).subscribe((currentLevel: FeatureGroup) => {
      if (this.currentLevel) {
        this.map.removeLayer(this.currentLevel);
      }
      this.currentLevel = currentLevel;
      this.currentLevel.addTo(this.map);
    });
  }

}
