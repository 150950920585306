export enum WidgetType {
  WIDGET = 'widget',
  TIDE_WIDGET = 'tide_widget',
  LEAFLET_MAP_WIDGET = 'leaflet_widget',
  MAP_WIDGET = 'map_widget',
  ARRIVALS_WIDGET = 'arrivals_widget',
  METEO_WIDGET = 'meteo_widget',
  TIDE_FORECAST_WIDGET = 'tide_forecast_widget',
  TIM_BIGDATA_WIDGET = 'timbigdata_widget',
  WALKWAYS_WIDGET = 'walkways_widget',
  PEDESTRIAN_FLOW_WIDGET = 'pedestrian_flow_widget',
  MOBILE_NETWORK_LOADS_WIDGET = 'mobile_network_loads_widget',
  ROAD_FLOWS_WIDGET = 'road_flows_widget',
  FLOW_MAP_WIDGET = 'flow_map_widget',
  CELLS_ATTENDANCE_WIDGET = 'cells_attendance_widget',
  HEART_BEAT_WIDGET = 'heart_beat_widget',
  WAVY_WAY_WIDGET = 'wavy_way_widget',
  TRAFFIC_WIDGET = 'traffic_widget',
  HERE_MAP_WIDGET = 'here_map_widget',
  CIVIL_PROTECTION_WIDGET = 'civil_protection_widget',
  PUBLIC_TRANSPORT_WIDGET = 'public_transport_widget',
  PUBLIC_TRANSPORT_STOPS_WIDGET = 'public_transport_stops_widget',
  EVENTS_WIDGET = 'events_widget',
  ROAD_MAINTENANCE_WIDGET = 'road_maintenance_widget',
  BARRIERS_WIDGET = 'caselli_widget',
  AUXILIARY_WIDGET = 'auxiliary_widget',
  HIGHWAY_TRAFFIC_WIDGET = 'highway_traffic_widget',
  VIDEO_FEED_WIDGET = 'video_feed_widget',
  ALERTS_WIDGET = 'alerts_widget',
  ALERTS_SR_WIDGET = 'alerts_sr_widget',
  DANGER_INDEX_WIDGET = 'danger_index_widget',
  FLOW_CONTROL_WIDGET = 'flow_control_widget',
  BEHAVIORAL_STATISTICS_WIDGET = 'behavioral_statistics_widget',
  REGISTRY_WIDGET = 'registry_widget',
  BRIDGES_WIDGET = 'bridges_widget',
  ARCGIS_WIDGET = 'arcgis_widget',
  IFRAME_WIDGET = 'iframe_widget',
  FORECAST_WIDGET = 'forecast_widget',
  INDEXES_WIDGET = 'indexes_widget',
  INFRASTRUCTURES_WIDGET = 'infrastructures_widget',
  TEMPERATURES_WIDGET = 'temperatures_widget',
  TIDE_MAP_WIDGET = 'tide_map_widget',
  MOBILITY_MAP_WIDGET = 'mobility_map_widget',
  POI_WIDGET = 'poi_widget',
  WIND_WIDGET = 'wind_widget',
  HEATMAP_WIDGET = 'heatmap_widget',
  AIR_QUALITY_WIDGET = 'air_quality_widget',
  EVENT_MAP_WIDGET = 'event_map_widget',
  CULTURAL_EVENTS_WIDGET = 'cultural_events_widget',
  IMAGE_DEMO_WIDGET = 'image_demo_widget',
  PARKING_WIDGET = 'parking_widget',
  PUBLIC_LIGHTS_WIDGET = 'public_lights_widget',
  SANCTIONS_MAP_WIDGET = 'sanctions_map_widget',
  MESSAGE_PANELS_WIDGET = 'message_panels_widget',
  GEOSERVER_WIDGET = 'geoserver_widget',
  GEOSERVER_OL_WIDGET = 'geoserver_ol_widget',
  RADAR_WIDGET = 'radar_widget',
  WEATHER_WIDGET = 'weather_widget',
  ROAD_FLOW_BASE_WIDGET = 'road_flow_base_widget',
  ROAD_FLOW_CURRENT_WIDGET = 'road_flow_current_widget',
  ROAD_FLOW_NUMBER_WIDGET = 'road_flow_number_widget',
  VIDEOSURVEILLANCE_WIDGET = 'videosurveillance_widget',
  ROAD_ARRIVALS_WIDGET = 'road_arrivals_widget',
  ROAD_FLOW_DATE_WIDGET = 'road_flow_date_widget',
  ROAD_FLOW_MEAN_WIDGET = 'road_flow_mean_widget',
  TRAFFIC_IMAGE_WIDGET = 'traffic_image_widget',
  PRESENCES_CLUSTER_WIDGET = 'presences_cluster_widget',
  MAP_OBJECT_POSITION_WIDGET = 'map_object_position_widget',
  AMAT_WIDGET = 'amat_widget'
}
