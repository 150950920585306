import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { GeoserverWidgetComponent } from './geoserver-widget.component';
import { GeoserverService } from './geoserver-widget.service';

@NgModule({
  declarations: [GeoserverWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule
  ],
  providers: [GeoserverService,
    { provide: 'geoserver_service', useClass: GeoserverService }],
  exports: [GeoserverWidgetComponent],
})
export class GeoserverWidgetModule {
}
