import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @ViewChild('dashboards') dashboards: MatSelectionList;

  dashboardSelected: Array<string>;
  dashboardItems: Array<string>;
  confirmOverwrite: boolean = false;
  needOverwrite: boolean = false;
  result: string = null;
  activeDashboard: string;
  textArgs: any;

  constructor(public dialogRef: MatDialogRef<DeleteModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  async ngOnInit(): Promise<void> {
    this.dashboardItems = this.data.availableDashboards;
    this.activeDashboard = this.data.currentDashboard;
    this.textArgs = {
      dashboard: this.activeDashboard
    };
  }

  updateForm(selected: string): void {
    this.result = selected[0];
    this.needOverwrite = true;
    this.confirmOverwrite = false;
  }
}
