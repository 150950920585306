import {
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { __ngRendererSetElementAttributeHelper } from '@app/modules/dashboard/ngx-grid-stack/renderer-utils';
import { GridStack, GridstackOptions } from 'gridstack';
import { Subscription } from 'rxjs';
import { GridStackItemComponent } from '../grid-stack-item/grid-stack-item.component';
import { GridStackItem } from '../models/grid-stack-item.model';
import { isEqual } from 'lodash';

declare var _: any;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngx-grid-stack',
  templateUrl: './grid-stack.component.html',
  styleUrls: ['./grid-stack.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridStackComponent implements OnChanges, OnDestroy {
  private grid: GridStack = null;
  private defaultOptions: GridstackOptions = {
    alwaysShowResizeHandle: true,
    column: 12,
    float: true,
    minRow: 3,
    minWidth: 1920,
    disableResize: true,
    disableDrag: true,
  };
  private element: ElementRef;

  private loadingSubscription: Subscription;

  @Input() options: GridstackOptions;
  @ContentChildren(GridStackItemComponent) items: QueryList<GridStackItemComponent>;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.element = el;
  }

  private widgetChanged(change: GridStackItem): void {
    const jWidget: GridStackItem = change;
    const gridStackItem: GridStackItemComponent = this.items.find((item: GridStackItemComponent) => {
      if (item.jWidgetRef) {
        return item.jWidgetRef === jWidget.el;
      }
    });
    if (!gridStackItem) {
      return;
    }

    gridStackItem.update(change.x, change.y, change.width, change.height);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && !changes.options.isFirstChange() && !isEqual(changes.options.currentValue, changes.options.previousValue)) {
      this.options = changes.options.currentValue;
      if (this.options.column) {
        this.grid.column(this.options.column);
        __ngRendererSetElementAttributeHelper(this.renderer, this.el.nativeElement, 'data-gs-column', String(this.options.column));
      } else {
        this.grid.column(12);
        __ngRendererSetElementAttributeHelper(this.renderer, this.el.nativeElement, 'data-gs-column', String(this.options.column));
      }
    }
  }

  setColumn(column: number): void {
    this.grid.column(column);
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }

  public makeWidget(item: GridStackItemComponent): void {
    item.jGridRef = this.grid;
    if (item.option != null && item.option.noResize != null && item.option.noResize) {
      return;
    }

    this.updateWidget(item);
  }

  public updateGrid(height: number): void {
    if (!this.grid) {
      this.reloadGrid();
    }
    this.grid.batchUpdate();
    this.grid.cellHeight(height);
    this.grid.commit();
  }

  public updateWidget(item: GridStackItemComponent): void {
    this.grid.resizable(item.nativeElement, true);
    this.grid.move(item.nativeElement, item.option.x, item.option.y);
    this.grid.resize(item.nativeElement, item.option.width, item.option.height);
  }

  public addWidget(item: GridStackItemComponent): void {
    item.jGridRef = this.grid;
    if (item.option != null && item.option.noResize != null && item.option.noResize) {
      return;
    }
    if (item.option) {
      item.option.autoPosition = true;
      item.option.noMove = false;
      item.option.noResize = false;
    }
    this.grid.addWidget(item.nativeElement);
    this.updateWidget(item);
  }

  public removeWidget(item: GridStackItemComponent): void {
    this.grid.removeWidget(item.nativeElement, false);
  }

  reloadGrid(): void {
    const nativeElement: any = this.el.nativeElement;
    if (this.options == null) {
      this.options = this.defaultOptions;
    }

    for (const key of Object.keys(this.defaultOptions)) {
      if (!this.options.hasOwnProperty(key)) {
        this.options[key] = this.defaultOptions[key];
      }
    }

    if (this.grid) {
      this.grid.destroy();
    }
    this.grid = GridStack.init(this.options, nativeElement);

    this.grid.on('change', (e: any, items: any) => {
      _.each(items, (item: any) => this.widgetChanged(item));
    });

    __ngRendererSetElementAttributeHelper(this.renderer, nativeElement, 'data-gs-column', String(this.options.column));

    this.checkResize(!this.options.disableResize);
  }

  public makeWidgets(): void {
    this.loadingSubscription = this.items.changes.subscribe((g: QueryList<GridStackItemComponent>) => {
      g.toArray().forEach((item: GridStackItemComponent) => {
        this.grid.makeWidget(item.nativeElement);
      });
      this.loadingSubscription.unsubscribe();
    });
  }

  public checkResize(isGridResizable: boolean): void {
    if (this.grid) {
      this.grid.enableMove(isGridResizable);
      this.grid.enableResize(isGridResizable);
      this.grid.commit();
    }
  }

}
