import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-auxiliary-widget',
  templateUrl: './auxiliary-widget.component.html',
  styleUrls: ['./auxiliary-widget.component.scss']
})
export class AuxiliaryWidgetComponent extends WidgetComponent {

  events: Array<any> = [
    {
      title: 'Squadra 1',
      status: {
        info: 'Lavori in corso',
        type: 'success'
      }
    },
    {
      title: 'Squadra 2',
      status: {
        info: 'In movimento',
        type: 'info'
      }
    },
    {
      title: 'Squadra 3',
      status: {
        info: 'Rifornimento',
        type: 'info'
      }
    },
    {
      title: 'Squadra 4',
      status: {
        info: 'Lavori in corso',
        type: 'success'
      }
    },
    {
      title: 'Squadra 5',
      status: {
        info: 'Manutenzione',
        type: 'warning'
      }
    },
    {
      title: 'Squadra 6',
      status: {
        info: 'Lavori in corso',
        type: 'success'
      }
    },
    {
      title: 'Spargisale',
      status: {
        info: 'ok',
        type: 'success'
      }
    },
    {
      title: 'Squadra 8',
      status: {
        info: 'fermo',
        type: 'info'
      }
    }


  ];

}
