import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';

@Injectable()
export class RangeManagerService {

  private ranges: Ranges;
  private currentRange: string;

  constructor(private translateService: TranslateService) {
    this.refreshRanges();
  }

  public getRange(key: string): Range {
    this.refreshRanges();
    return this.ranges[key];
  }

  refreshRanges(): void {
    this.ranges = {
      'LAST_5_MINUTES': [moment().subtract(6, 'minutes').startOf('minute'), moment().endOf('minute')],
      'LAST_15_MINUTES': [moment().subtract(16, 'minutes').startOf('minute'), moment().endOf('minute')],
      'LAST_30_MINUTES': [moment().subtract(31, 'minutes').startOf('minute'), moment().endOf('minute')],
      'LAST_HOUR': [moment().subtract(1, 'hour').startOf('minute'), moment().endOf('minute')],
      'LAST_3_HOURS': [moment().subtract(3, 'hour').startOf('minute'), moment().endOf('minute')],
      'LAST_6_HOURS': [moment().subtract(6, 'hour').startOf('minute'), moment().endOf('minute')],
      'LAST_12_HOURS': [moment().subtract(12, 'hour').startOf('minute'), moment().endOf('minute')],
      'LAST_24_HOURS': [moment().subtract(24, 'hour').startOf('minute'), moment().endOf('minute')],
      'LAST_TWO_DAYS': [moment().subtract(2, 'days'), moment()],
      'LAST_WEEK': [moment().subtract(1, 'week'), moment()],
      'LAST_30_DAYS': [moment().subtract(30, 'days'), moment()],
      'LAST_90_DAYS': [moment().subtract(90, 'days'), moment()],
      'LAST_6_MONTHS': [moment().subtract(6, 'months'), moment()],
      'LAST_YEAR': [moment().subtract(1, 'year'), moment()],
      'TODAY': [moment().startOf('day'), moment().endOf('day')],
      'YESTERDAY': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
      'TODAY_SO_FAR': [moment().startOf('day'), moment().startOf('minute')],
      'THIS_WEEK': [moment().startOf('week'), moment().endOf('week')],
      'THIS_WEEK_SO_FAR': [moment().startOf('week'), moment()],
      'THIS_MONTH': [moment().startOf('month'), moment().endOf('month')],
      'THIS_MONTH_SO_FAR': [moment().startOf('month'), moment()],
      'THIS_YEAR': [moment().startOf('year'), moment().endOf('year')],
      'THIS_YEAR_SO_FAR': [moment().startOf('year'), moment()],
      'LAST_NEXT_WEEKS': [moment().subtract(7, 'day').startOf('day'), moment().add(7, 'day').endOf('day')],
      'TOMORROW': [moment().add(1, 'day').startOf('day'), moment().add(1, 'day').endOf('day')],
      'DAY_AFTER_TOMORROW': [moment().add(2, 'day').startOf('day'), moment().add(2, 'day').endOf('day')],
      'NEXT_HOUR': [moment(), moment().add(1, 'hour')],
      'NEXT_24_HOURS': [moment(), moment().add(1, 'day')],
      'NEXT_48_HOURS': [moment(), moment().add(2, 'day')],
      'NEXT_FOUR_DAYS': [moment(), moment().add(4, 'day')],
    };
  }

  getRanges(): Ranges {
    this.refreshRanges();
    const newRanges: Ranges = {};
    const translatedStrings: Array<Translation> = Object.keys(this.ranges).map((key: string) => {
      const newValue: string = this.translateService.instant('TIME_MACHINE.RANGES.' + key);
      return {
        oldString: key,
        newString: newValue,
      };
    });
    translatedStrings.forEach((t: Translation) => {
      newRanges[t.newString] = this.ranges[t.oldString];
    });
    return newRanges;
  }
}

export interface Ranges {
  [propName: string]: Range;
}

export interface Translation {
  oldString: string;
  newString: string;
}

export type Range = [Moment, Moment];
