import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FlowType, FlowZone, FlowZoneGroup } from '@app/shared/models/flow-models';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { CameraData, TrafficFlow, TrafficFlowData } from '@app/modules/widgets/flows/traffic-widget/models/traffic-flow';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { ChartOptions, ChartType } from 'chart.js';
import { Dictionary, groupBy, cloneDeep, orderBy } from 'lodash';
import { BaseChartDirective, Color, Label, MultiDataSet } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { TrafficLoadDataParams, TrafficTableDataSourceItem, TrafficTableDataSourceItemWater } from './models';
import { TrafficService } from './traffic.service';

@Component({
  selector: 'app-traffic-widget',
  templateUrl: './traffic-widget.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./traffic-widget.component.scss'],
})

export class TrafficWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('pieChart')
  public chart: BaseChartDirective;

  ngStyle: { [klass: string]: any; };

  squareHeight: string;
  squareWidth: string;

  tableDataSource: MatTableDataSource<TrafficTableDataSourceItem>;

  waterTableColumns: Array<string> = ['color', 'type', 'total', 'N', 'S', 'W', 'E'];
  roadTableColumns: Array<string> = ['color', 'type', 'total'];

  pieChartData: MultiDataSet = [[]];
  pieChartLabels: Array<Label> = [];
  pieChartType: ChartType = 'doughnut';
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    aspectRatio: 1,
  };
  pieChartColors: Array<Color>;

  activeSort: Sort;

  groups: Array<FlowZoneGroup>;
  activeZoneIds: Array<string>;
  trafficData: Array<CameraData>;
  originalTrafficData: Array<CameraData>;
  selectedEntry: TrafficTableDataSourceItem;

  zonesSelectedSubscription: Subscription;
  zonesActivatedSubscription: Subscription;
  trafficDataSubscription: Subscription;
  typePickSubscription: Subscription;
  panelActive: boolean;

  blockUpdate: boolean;

  constructor(public widgetService: TrafficService,
              public flowService: FlowsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.zonesActivatedSubscription = this.flowService.groupsUpdated.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.refreshUI();
    });
    this.zonesSelectedSubscription = this.flowService.groupsSelected.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      // this.refreshUI();
    });
    this.typePickSubscription = this.flowService.typePicked.subscribe((type: string) => {
      if (this.trafficData) {
        this.trafficData.forEach((c: CameraData) => {
          this.selectedEntry = c.tableDataSource.filteredData.find((t: TrafficTableDataSourceItem) => {
            return t.type === type;
          });
        });
      }
    });
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.dataSubscription = this.flowService.loadGroups(timeMachineData, this.sources).subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.isLoading = false;
      this.populateWidget();
    });
  }

  populateWidget(): void {
    this.isLoading = true;
    this.activeZoneIds = this.widgetService.getActiveZoneIds(this.groups);
    this.panelActive = true;
    const timeMachineData: TimeMachineData = this.timeMachineService.getCurrentSelection();
    const trafficQuery: TrafficLoadDataParams = {
      zoneIds: this.activeZoneIds,
      groupIds: [],
      type: this.data.type as FlowType,
    };
    this.trafficDataSubscription = this.widgetService.loadData(timeMachineData, this.sources, trafficQuery).subscribe((trafficData: TrafficFlowData) => {
      this.noData = false;
      trafficData.trafficFlow = orderBy(trafficData.trafficFlow, ['camera'], ['asc']);
      const orderedData: Dictionary<Array<TrafficFlow>> = groupBy(trafficData.trafficFlow, 'camera');
      this.isLoading = true;
      this.trafficData = [];
      this.groups.forEach((f: FlowZoneGroup) => {
        f.zones.forEach((z: FlowZone) => {
          if (z.active) {
            const zoneData: Array<TrafficFlow> = orderedData[z.camera.cameraId] || [];
            const tableData: Array<TrafficTableDataSourceItem> =
              this.widgetService.getTableData(timeMachineData, this.sources, trafficQuery, zoneData, trafficData.vehicleColors);
            this.trafficData.push({
              zone: z,
              camera: z.camera.cameraId,
              data: zoneData,
              total: 0,
              tableDataSource: new MatTableDataSource(tableData),
              expanded: false,
            });
          }
        });
      });
      this.noData = !this.groups.length;
      this.orderData();
      this.originalTrafficData = cloneDeep(this.trafficData);
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  deselectAllZones(): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.selected = false;
      });
    });
    this.flowService.setGroups(this.groups);
    this.flowService.groupsSelected.emit(this.groups);
  }

  selectZone(trafficDataEntry: CameraData): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.selected = (z.name === trafficDataEntry.zone.name);
      });
    });
    this.flowService.setGroups(this.groups);
    this.blockUpdate = true;
    this.flowService.groupsSelected.emit(this.groups);
  }

  refreshUI(): void {
    const trafficData: Array<CameraData> = [];
    this.groups.forEach((f: FlowZoneGroup) => {
      f.zones.forEach((z: FlowZone) => {
        if (z.active) {
          const zoneData: CameraData = this.originalTrafficData.find((c: CameraData) => {
            return c.zone.name === z.name;
          });
          const actualZoneData: CameraData = this.trafficData.find((c: CameraData) => {
            return c.zone.name === z.name;
          });
          if (zoneData) {
            const trafficDataZone: CameraData = cloneDeep(zoneData);
            trafficDataZone.zone = z;
            trafficData.push(trafficDataZone);
            trafficDataZone.expanded = actualZoneData ? actualZoneData.expanded : false;
          }
        }
      });
    });
    this.trafficData = trafficData;
    this.orderData();
  }

  expandPanel(cameraData: CameraData): void {
    cameraData.expanded = true;
  }

  scrollToTop(cameraData: CameraData): void {
    const scroll: HTMLElement = document.getElementById(cameraData.camera);
    scroll.scrollIntoView();
  }

  orderData(): void {
    this.trafficData = orderBy(this.trafficData, ['camera'], ['asc']);
    this.trafficData.forEach((c: CameraData) => {
      if (c && c.data) {
        c.total = c.data.reduce((acc: number, t: TrafficFlow) => {
          t.total = t.N + t.E + t.S + t.W;
          acc = acc + t.total;
          return acc;
        }, 0);
        this.initGraph(c.tableDataSource, c);
      }
    });
  }

  handleResize(resize: ResizedEvent): void {
    const minDimension: number = Math.min(resize.newHeight, resize.newWidth);
    const dimension: string = (minDimension - (minDimension * 20 / 100)).toString();
    this.squareHeight = dimension;
    this.squareWidth = dimension;
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  initGraph(trafficData: MatTableDataSource<TrafficTableDataSourceItem>, c: CameraData): void {
    const data: Array<TrafficTableDataSourceItem> = trafficData.filteredData;
    c.graphData = {
      data: [[].concat(data.map((item: TrafficTableDataSourceItem) => {
        return item.total;
      }))],
      labels: [].concat(data.map((item: TrafficTableDataSourceItem) => item.type)),
      colors: [{
        backgroundColor: [].concat(data.map((item: TrafficTableDataSourceItem) => item.color)),
      }],
    };
  }

  getTotal(dataSource: MatTableDataSource<TrafficTableDataSourceItemWater>, column: string): number {
    const data: Array<TrafficTableDataSourceItemWater> = dataSource.filteredData;
    return data.reduce((acc: number, t: TrafficTableDataSourceItemWater) => {
      return acc + (t[column] || 0);
    }, 0);
  }

  onSortActivate(sort: Sort, dataSource: MatTableDataSource<TrafficTableDataSourceItemWater>, camera: string): void {
    this.activeSort = sort;
    const newDataSource: MatTableDataSource<TrafficTableDataSourceItemWater> =
      this.sortData(this.activeSort, dataSource);
    const originalData: CameraData = this.trafficData.find((t: CameraData) => {
      return t.camera === camera;
    });
    if (originalData) {
      originalData.tableDataSource = newDataSource;
    }
  }

  sortData(sort: Sort, dataSource: MatTableDataSource<TrafficTableDataSourceItemWater>): MatTableDataSource<TrafficTableDataSourceItemWater> {
    const orderedData: Array<TrafficTableDataSourceItemWater> =
      orderBy(dataSource.filteredData, [sort.active], [sort.direction === 'asc' ? 'asc' : 'desc']);
    return new MatTableDataSource(orderedData);
  }

  selectData(row: TrafficTableDataSourceItem): void {
    this.selectedEntry = row;
    if (row) {
      this.flowService.typePicked.emit(row.type);
    } else {
      this.flowService.typePicked.emit(null);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.typePickSubscription) {
      this.typePickSubscription.unsubscribe();
    }
    if (this.zonesActivatedSubscription) {
      this.zonesActivatedSubscription.unsubscribe();
    }
    if (this.trafficDataSubscription) {
      this.trafficDataSubscription.unsubscribe();
    }
  }
}
