<div class="main overflow-hidden d-flex flex-row justify-content-between align-items-stretch"
     [ngClass]="{'darkMode': themeService.isDark}"
     *ngIf="isDesktopDevice || (isTablet &&!isPortrait) ">
  <div class="main__item d-flex flex-column justify-content-start align-items-stretch">
    <router-outlet class="d-flex flex-column justify-content-start align-items-stretch"></router-outlet>
  </div>
</div>
<div class="mobile-phone-error" *ngIf="isMobile || (isTablet && isPortrait)">
  <app-mobile-error></app-mobile-error>
</div>
<div id="time-machine-overlay" class="time-machine-overlay d-flex flex-row justify-content-center align-items-center"
     *ngIf="timeMachineService.isTimeMachineActive() && timeMachineService.enabled && timeMachineService.showLabel">
  <div class="time-machine-overlay__box  d-flex flex-row justify-content-center align-items-center"
       [ngClass]="{'time-machine-overlay__box--past': timeMachineService.past,
       'time-machine-overlay__box--future': timeMachineService.forecast}">
    <span><b>{{'HOME.TIME_MACHINE_ACTIVE' | translate | uppercase}}</b></span>
    <div class="pl-4"><span>{{'HOME.TIME_MACHINE_PERIOD' | translate }}</span>&nbsp;
      <span
        *ngIf="!timeMachineService.rangeOn">{{timeMachineService.getCurrentSelection() | date:'medium'}}</span>
      <span
        *ngIf="timeMachineService.rangeOn"><b>{{'HOME.FROM' | translate }}
        :</b> {{$any(timeMachineService.getCurrentSelection()).from | date:'medium'}}
        - <b>{{'HOME.TO' | translate }}
          :</b> {{$any(timeMachineService.getCurrentSelection()).to | date:'medium'}}</span>
    </div>
  </div>
</div>
<div id="time-machine-unactive-overlay"
     class="time-machine-overlay d-flex flex-row justify-content-center align-items-center"
     *ngIf="!timeMachineService.enabled && timeMachineService.showLabel">
  <div class="time-machine-overlay__box  d-flex flex-row justify-content-center align-items-center">
    <span><i>{{'HOME.TIME_MACHINE_DISABLED' | translate}}</i></span>
    <div class="pl-4" *ngIf="timeMachineService.showPeriod"><span>{{'HOME.TIME_MACHINE_PERIOD' | translate }}</span>&nbsp;
      <span
        *ngIf="!timeMachineService.rangeOn">{{timeMachineService.getCurrentSelection() | date:'medium'}}</span>
      <span
        *ngIf="timeMachineService.rangeOn"><b>{{'HOME.FROM' | translate }}
        :</b> {{$any(timeMachineService.getCurrentSelection()).from | date:'medium'}}
        - <b>{{'HOME.TO' | translate }}
          :</b> {{$any(timeMachineService.getCurrentSelection()).to | date:'medium'}}</span>
    </div>
  </div>
</div>
<div id="overlay" class="overlay" *ngIf="isLoading | async">
  <mat-progress-spinner
    color="#ddd"
    mode="indeterminate"></mat-progress-spinner>
</div>
<ngx-smart-modal #infoModal identifier="infoModal"
                 title="Information"
                 [autostart]="maintenance.status"
                 [closable]="maintenance.closable"
                 [escapable]="maintenance.closable"
                 [dismissable]="maintenance.closable">
  <div class="info-modal">
    <em class="icon icon-venice_info"></em>
    <h2 class="info-modal__text">{{'HOME.MAINTENANCE' | translate}}</h2>
  </div>
</ngx-smart-modal>
<ngx-smart-modal #idleModal identifier="idleModal"
                 title="idleModal"
                 [closable]="true"
                 [escapable]="true"
                 [dismissable]="true">
  <div class="info-modal">
    <em class="icon icon-venice_info"></em>
    <h2 class="info-modal__text">{{'HOME.IDLE' | translate:{ seconds: idleSeconds } }}</h2>
  </div>
</ngx-smart-modal>
