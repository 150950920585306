import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { MapObjectPositionService } from '@app/modules/widgets/map-object-position-widget/map-object-position.service';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FeatureGroup, LatLng, Layer, Marker } from 'leaflet';

@Component({
  selector: 'app-map-object-position-widget',
  templateUrl: './map-object-position-widget.component.html',
  styleUrls: ['./map-object-position-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapObjectPositionWidgetComponent extends MapWidgetComponent implements OnInit {
  constructor(public widgetService: MapObjectPositionService, public injector: Injector) {
    super(widgetService, injector);
  }

  private findOldMarkerLayer(): Marker {
    let result: Marker;
    this.map.eachLayer((l: Marker) => {
      if (l['name'] === 'object-marker') {
        result = l;
      }
    });
    return result;
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = (this.widgetService as MapWidgetService).getMapLevel(timeMachineData, this.sources)
      .subscribe((featureGroup: FeatureGroup) => {
        this.isLoading = false;
        if (featureGroup) {
          this.noData = false;
          const newMarkerLayer: Marker = featureGroup.getLayers().find((l: Layer) => {
            return (l['name'] === 'object-marker');
          }) as Marker;
          const oldMarkerLayer: Marker = this.findOldMarkerLayer();
          if (oldMarkerLayer) {
            oldMarkerLayer.setLatLng(newMarkerLayer.getLatLng());
            oldMarkerLayer.getPopup().setContent(newMarkerLayer.getPopup().getContent());
          } else {
            if (this.layer) {
              this.layer.remove();
            }
            this.layer = featureGroup;
            this.layer.addTo(this.map);
          }
          try {
            this.map.getBounds().extend(this.layer.getBounds());
          } catch (e) {
            console.error(e);
          }
        } else {
          this.noData = true;
          this.isLoading = false;
        }
      }, (error: any) => {
        this.noData = true;
        this.isLoading = false;
        console.error(error);
      });
  }

}
