import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { PUBLIC_TRANSPORT_LEGEND } from '@app/modules/widgets/public-transport-widget/mock/legend';
import { LineRequest } from '@app/modules/widgets/public-transport-widget/models/interfaces/line-request';
import { PublicTransportDataSource } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-data-source';
import { StopSelect } from '@app/modules/widgets/public-transport-widget/models/interfaces/stop-select';
import { TimetableEntry } from '@app/modules/widgets/public-transport-widget/models/interfaces/timetable-entry';
import { TranslateService } from '@ngx-translate/core';
import * as Cesium from 'cesium';
import { Feature, FeatureCollection, LineString, Point } from 'geojson';
import {
  DivIcon,
  FeatureGroup,
  geoJSON,
  GeoJSONOptions,
  LatLng, Layer,
  Marker,
  Map as LeafletMap,
  Tooltip, TooltipOptions,
} from 'leaflet';
import moment, { Moment } from 'moment';
import { forkJoin, timer, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeWhile } from 'rxjs/operators';
import {
  LineSelect, MarkerOnMap, PublicTransportRoute,
  PublicTransportSerializedVehicle,
  PublicTransportVehicle,
  Transports, TripResponse,
} from './models';

@Injectable()
export class PublicTransportService extends MapWidgetService {
  private VEHICLE_SOURCE_TAG: string = 'public-transports-vehicles';
  private activeMarkerLine: string;
  private activeMarker: Marker;
  private mapLayers: Map<string, MarkerOnMap> = new Map<string, MarkerOnMap>();

  DEFAULT_REFRESH_INTERVAL: number = 5 * 1000;

  activateLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  activeLine: EventEmitter<LineSelect> = new EventEmitter<LineSelect>();
  newActiveMarker: EventEmitter<NewActiveLine> = new EventEmitter<NewActiveLine>();
  activeStop: EventEmitter<StopSelect> = new EventEmitter<StopSelect>();

  stopAnimation: boolean = false;

  private addVehicleData(v: PublicTransportVehicle, vehicleRoute: PublicTransportRoute): Tooltip {
    const translate: TranslateService = this.translateService;
    const dataTooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [5, -15],
    });
    const delayLabel: string = v.earlyDelay < 0 ? translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.EARLIER') : translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.LATE');
    if (v.earlyDelay < 0) {
      v.earlyDelay = -v.earlyDelay;
    }
    const lastDate: Moment = moment(v.time);
    let template: string = '';
    template = template + `<h6><b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.VEHICLE')}:</b> ${v.vehicleID}</h6><br/>`;
    if (vehicleRoute) {
      template = template + `<b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.LINE')}:</b> ${vehicleRoute.longName}<br/>`;
    }
    template = template + `<b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.SPEED')}:</b> ${v.speed} Km/h<br/>`;
    template = template + `<b>${delayLabel}</b> ${v.earlyDelay} min.<br/>`;
    template = template + `<b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.LAST_STOP')}:</b> ${v.lastStopName ?
      v.lastStopName : translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.UNAVAILABLE')}<br/>`;
    template = template + `<b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.NEXT_STOP')}:</b> ${v.nextStopName ?
      v.nextStopName + ' ' + v.nextStopArrivalTime : translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.UNAVAILABLE')}<br/>`;
    template = template + `<b>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.TERMINAL_STOP')}:</b> ${v.terminalStopName ?
      v.terminalStopName + ' ' + v.terminalStopArrivalTime : translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.UNAVAILABLE')} <br/><br/>`;
    template = template + `<i>${translate.instant('WIDGETS.PUBLIC_TRANSPORTS.DATA.LAST_UPDATE')}:</i> ${lastDate.format('LTS')}`;
    dataTooltip.setContent(template);
    return dataTooltip;
  }


  cesiumLayerFromVehicle(type: Transports, v: PublicTransportVehicle, routes: Array<PublicTransportRoute>,
                         sources: Array<PublicTransportDataSource>): any {
    const vehiclesSource: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === this.VEHICLE_SOURCE_TAG;
    });
    if (vehiclesSource) {
      const sourceData: any = JSON.parse(vehiclesSource.configuration).sourceData;
      let vehicleRoute: PublicTransportRoute;
      let color: string = sourceData[type].color;
      if (v.routeKey || v.tripId) {
        vehicleRoute = routes.find((r: PublicTransportRoute) => {
          return r.key.toString() === v.routeKey || r.key.toString() === v.tripId;
        });
        if (vehicleRoute) {
          color = '#' + vehicleRoute.color;
        }
      }
      return {
        id: v['vehicleID'],
        type: type,
        name: v['vehicleID'],
        position: Cesium.Cartesian3.fromDegrees(v.lon, v.lat, 0),
        box: {
          dimensions: new Cesium.Cartesian3(10, 10, 10),
          material: Cesium.Color.fromCssColorString(color),
          heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        },
      };
    }
  }

  layerFromVehicle(type: Transports, v: PublicTransportVehicle, routes: Array<PublicTransportRoute>,
                   sources: Array<PublicTransportDataSource>): Marker {
    const vehiclesSource: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === this.VEHICLE_SOURCE_TAG;
    });
    if (vehiclesSource) {
      const sourceData: any = JSON.parse(vehiclesSource.configuration).sourceData;
      const markerPosition: LatLng = new LatLng(v.lat, v.lon);
      const marker: Marker = new Marker(markerPosition);
      let htmlIcon: string;
      let vehicleRoute: PublicTransportRoute;
      if (v.routeKey || v.tripId) {
        vehicleRoute = routes.find((r: PublicTransportRoute) => {
          return r.key.toString() === v.routeKey || r.key.toString() === v.tripId;
        });
        if (vehicleRoute) {
          htmlIcon = `<div class="vehicle-marker__icon" style="background-color:#${vehicleRoute.color}; color:#${vehicleRoute.textColor}">${vehicleRoute.shortName}</div>`;
        } else {
          htmlIcon = `<i class="${sourceData[type].iconFont}" style="color:${sourceData[type].color}"></i>`;
        }
      } else {
        htmlIcon = `<i class="${sourceData[type].iconFont}" style="color:${sourceData[type].color}"></i>`;
      }
      let delay: string = 'no';
      if (v.earlyDelay > 0) {
        delay = 'normal';
      }
      if (v.earlyDelay > 3) {
        delay = 'hard';
      }
      if (v.earlyDelay < 0) {
        delay = 'early';
      }
      const markerIcon: DivIcon = new DivIcon({
        className: `vehicle-marker vehicle-marker--${type} delay--${delay}`,
        html: htmlIcon,
      });
      if (!v.routeKey && !v.tripId) {
        markerIcon.options.className = markerIcon.options.className + ' vehicle-marker--not-line';
      }
      if (this.activeMarkerLine === v.vehicleID) {
        markerIcon.options.className = markerIcon.options.className + ' vehicle-marker--active-marker';
      }
      marker.setIcon(markerIcon);
      marker.on('click', async () => {
        if (marker.getIcon().options.className.includes('vehicle-marker--active-marker')) {
          this.newActiveMarker.emit(null);
          marker.getIcon().options.className = marker.getIcon().options.className.replace(' vehicle-marker--active-marker', '');
          marker.setIcon(marker.getIcon());
          marker.closeTooltip();
        } else {
          this.activeMarkerLine = v.vehicleID;
          this.newActiveMarker.emit({
            data: type,
            marker: marker,
          });
          this.activeMarker = marker;
          this.activateLoader.emit(true);
          if (v.routeKey || v.tripId) {
            this.activeLine.emit({
              lineId: v.routeKey,
              tripId: v.tripId,
              type: type,
              shiftId: v.shiftID,
            });
          }
          this.activateLoader.emit(false);
        }
      });
      const dataTooltip: Tooltip = this.addVehicleData(v, vehicleRoute);
      marker.bindTooltip(dataTooltip);
      const layerName: string = type + '-' + v.vehicleID;
      if (!this.mapLayers.get(layerName)) {
        this.mapLayers.set(layerName, { markerLayer: marker, newPosition: null, newToolTip: dataTooltip });
      } else {
        this.mapLayers.get(layerName).newPosition = markerPosition;
        this.mapLayers.get(layerName).newToolTip = dataTooltip;
      }

      marker['vehicleID'] = layerName;
      marker['type'] = type;
      return marker;
    }
  }

  getMapMarkers(): Map<string, MarkerOnMap> {
    return this.mapLayers;
  }

  resetMapMarkers(): void {
    this.mapLayers = new Map<string, MarkerOnMap>();
  }

  layerFromStops(requestData: LineRequest, stopFeature: Feature, color: string, isFirst: boolean, isLast: boolean,
                 featureTime?: FeatureCollection): FeatureGroup {
    const layerGroup: FeatureGroup = new FeatureGroup();
    const markerPosition: LatLng = new LatLng(stopFeature.geometry['coordinates'][1], stopFeature.geometry['coordinates'][0]);
    const marker: Marker = new Marker(markerPosition);
    let arrivalTime: string;
    let timeTableProperties: Feature;
    if (featureTime && featureTime.features.length) {
      timeTableProperties = featureTime.features.find((f: Feature) => {
        return f.properties.key === stopFeature.properties.key;
      });
      arrivalTime = timeTableProperties.properties.arrivalTime;
    }
    let newDiv: string;
    newDiv = ' ';
    if (color.toLowerCase() === '#ffffff') {
      color = 'black';
    }
    let markerIcon: DivIcon = new DivIcon({
      className: `stopMarker stopMarker--${requestData.type}`,
      html: `<svg height="12" width="12"><rect height="10" width="10" x="1" y="1" stroke="${color}" stroke-width="2" fill="white" /></svg>${newDiv}`,
    });
    let tooltipOptions: TooltipOptions = { offset: [10, 5], direction: 'right' };
    if (isFirst || isLast) {
      tooltipOptions = { offset: [25, 10], direction: 'right' };
      if (isFirst) {
        markerIcon = new DivIcon({
          className: `stopMarker stopMarker--${requestData.type} stopMarker--first`,
          html: `<svg height="30" width="30"><circle cx="15" cy="15" r="10" stroke="${color}" stroke-width="5" fill="white" /></svg>${newDiv}`,
        });
      } else {
        markerIcon = new DivIcon({
          className: `stopMarker stopMarker--${requestData.type} stopMarker--last`,
          html: `<svg height="30" width="30"><rect height="20" width="20" x="5" y="5" stroke="${color}" stroke-width="5" fill="white" /></svg>${newDiv}`,
        });
      }
    }
    marker.setIcon(markerIcon);
    const layerTooltip: Tooltip = new Tooltip(tooltipOptions);

    let template: string = '';
    if (arrivalTime) {
      template = template + `<b>${arrivalTime} - </b> `;
    }
    template = template + `${stopFeature.properties.name}`;
    layerTooltip.setContent(template);
    if (arrivalTime) {
      layerTooltip.options = Object.assign(layerTooltip.options, {
        permanent: true,
        interactive: true,
        sticky: true,
      });
    }
    layerTooltip.on('click', () => {
      marker.closeTooltip();
    });
    marker.on('click', () => {
      this.activeStop.emit({
        stopKey: stopFeature.properties.name,
        routeKey: requestData.routeId,
        tripId: requestData.tripId,
        type: requestData.type,
      });
    });
    marker.bindTooltip(layerTooltip);
    marker.addTo(layerGroup);
    marker.openTooltip();
    return layerGroup;
  }

  setActiveMarker(marker: Marker): void {
    if (this.activeMarker) {
      this.activeMarker.getIcon().options.className =
        this.activeMarker.getIcon().options.className.replace(' vehicle-marker--active-marker', '');
      this.activeMarker.setIcon(this.activeMarker.getIcon());
    }
    if (marker) {
      this.activeMarker = marker;
      this.activeMarker.getIcon().options.className = this.activeMarker.getIcon().options.className + ' vehicle-marker--active-marker';
      this.activeMarker.setIcon(this.activeMarker.getIcon());
    }
  }

  getActiveMarker(): Marker {
    return this.activeMarker;
  }

  public getLines(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<Array<PublicTransportRoute>> {
    const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === 'public-transports-lines';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      const sourceData: any = JSON.parse(source.configuration).sourceData;
      const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[type].id.toString());
      return this.http.get<DataResult<PublicTransportRoute>>(agencyUrl).pipe(
        map((serializedLines: DataResult<PublicTransportRoute>) => {
          return serializedLines.data;
        }),
      );
    }
  }

  public getRouteKeyFromTripId(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, tripId: string, type: Transports): Observable<TripResponse> {
    const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === 'public-transports-lines-trip';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      const sourceData: any = JSON.parse(source.configuration).sourceData;
      const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[type].id.toString());
      const routeUrl: string = this.replaceParameter(agencyUrl, 'trip_key', tripId.toString());
      return this.http.get<TripResponse>(routeUrl).pipe(
        switchMap((el: TripResponse) => of(el)),
        catchError(() => of(null)),
      );
    } else {
      return of(null);
    }
  }

  public getLineLevel(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, requestData: LineRequest): Observable<FeatureCollection<LineString>> {
    return forkJoin([this.getRouteKeyFromTripId(timeMachineData, sources, requestData.tripId, requestData.type)]).pipe(
      switchMap((c: Array<any>) => {
        const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
          return s.sourceType === 'public-transports-lines-shapes';
        });
        if (source) {
          const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
          const sourceData: any = JSON.parse(source.configuration).sourceData;
          const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[requestData.type].id.toString());
          const routeUrl: string = this.replaceParameter(agencyUrl, 'route_key', !isNaN(requestData.routeId) ? requestData.routeId.toString() : c[0].data[0].routeKey.toString());
          return this.http.get<FeatureCollection<LineString>>(routeUrl);
        }
      }));
  }

  public getTimeTable(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, requestData: StopSelect): Observable<DataResult<TimetableEntry>> {
    return forkJoin([this.getRouteKeyFromTripId(timeMachineData, sources, requestData.tripId, requestData.type)]).pipe(
      switchMap((c: Array<any>) => {
        const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
          return s.sourceType === 'public-transports-lines-timetable';
        });
        if (source) {
          const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
          const sourceData: any = JSON.parse(source.configuration).sourceData;
          const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[requestData.type].id.toString());
          const routeUrl: string = this.replaceParameter(agencyUrl, 'route_key', !isNaN(requestData.routeKey) ? requestData.routeKey.toString() : c[0].data[0].routeKey.toString());
          return this.http.get<DataResult<TimetableEntry>>(routeUrl);
        }
      }));
  }

  public getLineStops(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, requestData: LineRequest): Observable<FeatureCollection<Point>> {
    return forkJoin([this.getRouteKeyFromTripId(timeMachineData, sources, requestData.tripId, requestData.type)]).pipe(
      switchMap((c: Array<any>) => {
        const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
          return s.sourceType === 'public-transports-lines-stops';
        });
        if (source) {
          const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
          const sourceData: any = JSON.parse(source.configuration).sourceData;
          const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[requestData.type].id.toString());
          const routeUrl: string = this.replaceParameter(agencyUrl, 'route_key', !isNaN(requestData.routeId) ? requestData.routeId.toString() : c[0].data[0].routeKey.toString());
          return this.http.get<FeatureCollection<Point>>(routeUrl);
        }
      }));
  }

  public getLineStopsTimeTable(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, requestData: LineRequest): Observable<FeatureCollection<Point>> {
    return forkJoin([this.getRouteKeyFromTripId(timeMachineData, sources, requestData.tripId, requestData.type)]).pipe(
      switchMap((c: Array<any>) => {
        const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
          return s.sourceType === 'public-transports-lines-timetables';
        });
        if (source) {
          const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
          const sourceData: any = JSON.parse(source.configuration).sourceData;
          const agencyUrl: string = this.replaceParameter(url, 'type', sourceData[requestData.type].id.toString());
          const routeUrl: string = this.replaceParameter(agencyUrl, 'route_key', !isNaN(requestData.routeId) ? requestData.routeId.toString() : c[0].data[0].routeKey.toString());
          const timeTableUrl: string = this.replaceParameter(routeUrl, 'block_id', requestData.shiftId.toString());
          return this.http.get<FeatureCollection<Point>>(timeTableUrl);
        }
      }));
  }

  public getTransports(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<Array<PublicTransportVehicle>> {
    const source: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === this.VEHICLE_SOURCE_TAG;
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      const sourceData: any = JSON.parse(source.configuration).sourceData;
      const typeUrl: string = this.replaceParameter(url, 'type', sourceData[type].id.toString());
      return this.http.get<DataResult<PublicTransportSerializedVehicle>>(typeUrl).pipe(
        map((serializedTransports: DataResult<PublicTransportSerializedVehicle>) => {
          return serializedTransports.data.map((serializedTransport: PublicTransportSerializedVehicle) => {
            return new PublicTransportVehicle(serializedTransport);
          });
        }),
      );
    }
  }

  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<PublicTransportDataSource>, type: Transports): Observable<Array<PublicTransportRoute>> {
    return this.getLines(timeMachineData, sources, type);
  }


  public getSingleLineLevel(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, requestData: LineRequest): Observable<FeatureGroup> {
    const layers: FeatureGroup = new FeatureGroup();
    const vehiclesSource: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === this.VEHICLE_SOURCE_TAG;
    });
    if (vehiclesSource) {
      const sourceData: any = JSON.parse(vehiclesSource.configuration).sourceData;
      const options: GeoJSONOptions = {
        style: {
          color: sourceData[requestData.type].color,
          weight: 2,
          opacity: 1,
        },
      };
      const requestsObservables: Array<Observable<any>> = [];
      const linesObservable: Observable<FeatureCollection> = this.getLineLevel(timeMachineData, sources, requestData);
      const stopsObservable: Observable<FeatureCollection<Point>> = this.getLineStops(timeMachineData, sources, requestData);
      requestsObservables.push(linesObservable);
      requestsObservables.push(stopsObservable);
      if (requestData.shiftId) {
        const featureTimeObservable: Observable<FeatureCollection> = this.getLineStopsTimeTable(timeMachineData, sources, requestData);
        requestsObservables.push(featureTimeObservable);
      }
      return forkJoin(requestsObservables).pipe(
        map((data: Array<any>) => {
          const featureCollection: FeatureCollection = data[0];
          let routeColor: string = sourceData[requestData.type].color;
          featureCollection.features.forEach((feature: Feature) => {
            routeColor = '#' + feature.properties.routeColor;
            if ('color' in options.style) {
              options.style.color = routeColor;
            }

            const geoJsonLayer: FeatureGroup = geoJSON(feature, options);
            geoJsonLayer.addTo(layers);
          });
          const stopsFeatureCollection: FeatureCollection<Point> = data[1];
          let featureTime: FeatureCollection<Point>;
          if (requestData.shiftId) {
            featureTime = data[2];
          }
          const geoJsonLayers: Array<FeatureGroup> = stopsFeatureCollection.features.map((feature: Feature, index: number, object: Array<Feature>) => {
            const isFirst: boolean = (index === 0);
            const isLast: boolean = (index === object.length - 1);
            return this.layerFromStops(requestData, feature, routeColor, isFirst, isLast, featureTime);
          });
          geoJsonLayers.forEach((f: FeatureGroup) => {
            f.addTo(layers);
          });
          return layers;
        }),
      );
    }
  }

  public getMarkerById(leafletMap: LeafletMap, id: string): Marker {
    let response: Marker = null;
    leafletMap.eachLayer((layer: Layer) => {
      if (layer['vehicleID'] === id) {
        response = layer as Marker;
      }
    });
    return response;
  }


  public updateMapMarker(leafletMap: LeafletMap, f: Layer): void {
    // Cerco se ho il marker sulla mappa
    const o: Marker = this.getMarkerById(leafletMap, f['vehicleID']);
    if (o) {
      // Cerco se ho il marker nel servizio
      const p: MarkerOnMap = this.getMapMarkers().get(f['vehicleID']);
      if (p) {
        o.unbindTooltip();
        o.bindTooltip(p.newToolTip);
        const oldPos: LatLng = o.getLatLng();
        if (p.newPosition && oldPos !== p.newPosition) {
          o.setLatLng(p.newPosition);
        }
      } else {
        // Se lo ho nel servizio e non lo ho in mappa, ho qualche problema
        leafletMap.removeLayer(o);
      }
    } else {
      leafletMap.addLayer(f);
    }
  }

  public getTransportDataFromTime(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>,
                                  type: Transports, offset: number): Observable<[Array<PublicTransportVehicle>, Array<PublicTransportRoute>]> {
    const newTmData: TimeMachineData = this.calculateTimeMachineData(timeMachineData, offset);
    const vehiclesObservable: Observable<Array<PublicTransportVehicle>> = this.getTransports(newTmData, sources, type);
    const routesObservable: Observable<Array<PublicTransportRoute>> = this.getLines(newTmData, sources, type);
    return forkJoin([vehiclesObservable, routesObservable]);
  }

  public getCesiumLevel(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<any> {
    return timer(0, this.DEFAULT_REFRESH_INTERVAL).pipe(switchMap((offset: number) => {
      return this.getTransportDataFromTime(timeMachineData, sources, type, offset);
    }), switchMap((data: [Array<PublicTransportVehicle>, Array<PublicTransportRoute>]) => {
      const vehicles: Array<PublicTransportVehicle> = data[0];
      const routes: Array<PublicTransportRoute> = data[1];
      const markerLayers: Array<any> = vehicles.map((v: PublicTransportVehicle) => {
        return this.cesiumLayerFromVehicle(type, v, routes, sources);
      });
      return of(markerLayers);
    }));
  }

  setDuration(sources: Array<PublicTransportDataSource>, defaultAnimation: boolean, elementRef: ElementRef): void {
    const vehiclesSource: PublicTransportDataSource = sources.find((s: PublicTransportDataSource) => {
      return s.sourceType === 'public-transports-vehicles';
    });
    if (vehiclesSource && vehiclesSource.configuration) {
      const sourceData: any = JSON.parse(vehiclesSource.configuration).sourceData;
      const animation: boolean = sourceData.animation ? sourceData.animation : defaultAnimation;
      if (animation && sourceData.rail.animationSpeed) {
        elementRef.nativeElement.style.setProperty('--railDuration', sourceData.rail.animationSpeed);
      }
      if (animation && sourceData.road.animationSpeed) {
        elementRef.nativeElement.style.setProperty('--roadDuration', sourceData.road.animationSpeed);
      }
      if (animation && sourceData.water.animationSpeed) {
        elementRef.nativeElement.style.setProperty('--waterDuration', sourceData.water.animationSpeed);
      }
    }
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<PublicTransportDataSource>, type: Transports): Observable<FeatureGroup> {
    return timer(0, this.DEFAULT_REFRESH_INTERVAL).pipe(takeWhile(() => {
        return !this.stopAnimation;
      }),
      switchMap((offset: number) => {
        return this.getTransportDataFromTime(timeMachineData, sources, type, offset);
      }),
      switchMap((data: [Array<PublicTransportVehicle>, Array<PublicTransportRoute>]) => {
        const vehicles: Array<PublicTransportVehicle> = data[0];
        const routes: Array<PublicTransportRoute> = data[1];
        const markerLayers: Array<Marker> = vehicles.map((v: PublicTransportVehicle) => {
          return this.layerFromVehicle(type, v, routes, sources);
        });
        const layers: FeatureGroup = new FeatureGroup();
        markerLayers.forEach((marker: Marker) => {
          marker.addTo(layers);
        });
        return of(layers);
      }), catchError((error: any) => {
        return of(new FeatureGroup());
      }));
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const timeMachineData: number = moment().valueOf();
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
    const agencyUrl: string = this.replaceParameter(url, 'type', '1');
    const routeUrl: string = this.replaceParameter(agencyUrl, 'route_key', '1');
    const timeTableUrl: string = this.replaceParameter(routeUrl, 'block_id', '1');
    return this.http.get<any>(timeTableUrl);
  }

  public getLegend(): MapLegend {
    return PUBLIC_TRANSPORT_LEGEND;
  }
}

export interface NewActiveLine {
  data: Transports;
  marker: Marker;
}
