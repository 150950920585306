export interface ParkingStatus {
  occupancyStatus: OccupancyStatus;
  numParkingBay: string;
  inViolation: boolean;
  violationType: ViolationType;
  available: boolean;
  checked: boolean;
  outOfRange: boolean;
  userType?: UserType;
  occupancyTimestamp: Date;
  ticketExpirationTimestamp?: Date;
  updateTimestamp: Date;
}

export interface ParkingStatusResponse {
  status: Array<ParkingStatus>;
}

export enum OccupancyStatus {
  FREE = 'FREE',
  OCCUPIED = 'OCCUPIED',
  UNKNOWN = 'UNKNOWN'
}

export enum ViolationType {
  NO_PAYMENT = 'NO_PAYMENT',
  NO_VIOLATION = 'NO_VIOLATION',
  TICKET_OVERSTAY = 'TICKET_OVERSTAY',
  PASS_ALREADY_USED = 'PASS_ALREADY_USED',
  PAYMENT_OVERSTAY = 'PAYMENT_OVERSTAY',
  PASS_INVALID = 'PASS_INVALID'
}

export enum UserType {
  OCCASIONAL = 'OCCASIONAL',
  PASS = 'PASS',
  DISABLED = 'DISABLED'
}
