import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ArrivalsWidgetComponent } from './arrivals-widget.component';

@NgModule({
  declarations: [ArrivalsWidgetComponent],
  imports: [CommonModule],
  exports: [ArrivalsWidgetComponent],
})
export class ArrivalsWidgetModule {
}
