import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { WidgetType } from '@app/shared/enums/widget-type';

export const BRIDGES_LEGEND: MapLegend = {
  title: 'BRIDGES',
  type: WidgetType.BRIDGES_WIDGET,
  description: 'HR_DESCRIPTION',
  additionalData: 'navigations',
  elements: [{
    label: '1.50m < hr',
    color: 'green',
  }, {
    label: '1.00m < hr < 1.49m',
    color: 'yellow',
  }, {
    label: '0.10m < hr < 0.99m',
    color: 'orange',
  }, {
    label: '       hr < 0.10m',
    color: 'red',
  },
  ],
};
