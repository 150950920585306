import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsWidgetComponent } from '@app/modules/widgets/alerts-widget/alerts-widget.component';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AlertsService } from '@app/modules/widgets/alerts-widget/alerts.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
@NgModule({
  declarations: [AlertsWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MatCheckboxModule,
    TranslateModule,
    AppMaterialModule,
  ], exports: [AlertsWidgetComponent, AppMaterialModule],
  providers: [AlertsService,
    { provide: 'alerts_widget_service', useClass: AlertsService }],
})
export class AlertsWidgetModule {
}
