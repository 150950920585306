import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { IndexesWidgetService } from '@app/modules/widgets/indexes-widget/indexes-widget.service';
import { Indices } from '@app/modules/widgets/indexes-widget/model/indices';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';

@Component({
  selector: 'app-indexes-widget',
  templateUrl: './indexes-widget.component.html',
  styleUrls: ['./indexes-widget.component.scss'],
})
export class IndexesWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {

  @Input()
  data: any;
  index: number;
  label: string;
  showLabel: boolean;
  widgetWidth: number;
  innerWidth: number;
  fontSize: string;

  constructor(public widgetService: IndexesWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.innerWidth = window.innerWidth;
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((indices: Array<Indices>) => {
      this.index = indices[0][this.data.type];
      this.label = this.data.label;
      this.showLabel = this.data.showLabel;
      this.isLoading = false;
      this.noData = false;
    }, (error: any) => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  handleResize(resize: ResizedEvent): void {
    this.widgetWidth = resize.newWidth;

    const minSize: number = Math.min(resize.newHeight, resize.newWidth);
    this.fontSize = (minSize / 12).toString();

  }

  getCircleColor(value: number): string {
    return this.widgetService.getColorFill(value);
  }
}
