<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
           >
  <img class="mock img-fluid" [src]="baseImgUrl">
</app-widget>
