<app-page-menu-bar [pageLabel]="'CESIUM'" [menuKey]="'MAP3D'">
  <div class="d-block cesium-map-container">
    <div #map class="d-block map-container">

    </div>
    <div class="map__left d-flex flex-column justify-content-center align-items-center"
         [ngClass]="{'map__right__margin': pageConfig && pageConfig.showMenuBar}">
      <app-map-control-bar class="d-flex flex-column map-menu map-overlay-menu" *ngIf="data && pageConfig" [data]="data"
                           [showMenuBar]="pageConfig.showMenuBar"
                           [showSatellite]="pageConfig.mapSettings.satellitePic"
                           [cesiumMap]="true"
                           (levelActivated)="loadMapLevel($event)"
                           (cesiumSwitchActivated)="toggle3dLevel($event)">
      </app-map-control-bar>
    </div>
  </div>
</app-page-menu-bar>
<app-menu-bar class="menu-bar" #menu *ngIf="pageConfig && pageConfig.showMenuBar"></app-menu-bar>
