import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapObjectPositionService } from '@app/modules/widgets/map-object-position-widget/map-object-position.service';
import { MapWidgetModule } from '../map-widget/map-widget.module';
import { MapObjectPositionWidgetComponent } from './map-object-position-widget.component';

@NgModule({
  declarations: [MapObjectPositionWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule
  ], exports: [MapObjectPositionWidgetComponent],
  providers: [MapObjectPositionService]
})
export class MapObjectPositionWidgetModule { }
