import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapBaseControlModule } from '@app/modules/map/map-base-control/map-base-control.module';
import { MapLegendComponent } from '@app/modules/map/map-legend/map-legend.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';

@NgModule({
  declarations: [
    MapLegendComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    MapBaseControlModule,
    TranslateModule
  ],
  exports: [MapLegendComponent],
})
export class MapLegendModule {
}
