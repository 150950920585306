import { Component, Input, ViewEncapsulation } from '@angular/core';
import { HeartBeatWidgetService } from '../heart-beat-widget.service';

@Component({
  selector: 'app-lion',
  templateUrl: './lion.component.html',
  styleUrls: ['./lion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LionComponent {

  @Input()
  score: string;

  @Input()
  label: string;

  @Input()
  enabled: boolean;

  @Input()
  small: boolean;

  @Input()
  left: boolean;

  @Input()
  right: boolean;

  @Input()
  file: string;

  ngStyle: { [klass: string]: any; };

  constructor(private heartBeatWidgetService: HeartBeatWidgetService) {
  }

  getColor(start: number, end: number): boolean {
    if (this.score) {
      return parseFloat(this.score.replace(/,/, '.')) > start && parseFloat(this.score.replace(/,/, '.')) <= end;
    } else {
      return false;
    }
  }

  getLionColor(value: string, active: boolean): string {
    if (value) {
      return this.heartBeatWidgetService.getColorFill(parseFloat(value.replace(/,/, '.')), active);
    } else {
      return '#808080';
    }
  }
}
