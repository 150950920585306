import { AfterViewInit, Component, DoCheck, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { GeoserverService } from '@app/modules/widgets/geoserver-widget/geoserver-widget.service';
import { GeoserverDataSource } from '@app/modules/widgets/geoserver-widget/models/geoserver-data-source';
import { GeoserverWidgetData } from '@app/modules/widgets/geoserver-widget/models/geoserver-widget-data';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Control, LatLngBounds, Layer, FeatureGroup } from 'leaflet';

declare var L: any;

@Component({
  selector: 'app-geoserver-widget',
  templateUrl: './geoserver-widget.component.html',
})
export class GeoserverWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {

  layers: FeatureGroup;
  control: Control.Layers;

  sources: Array<GeoserverDataSource>;

  @Input()
  public data: GeoserverWidgetData;

  constructor(public widgetService: GeoserverService, public injector: Injector) {
    super(widgetService, injector);
  }


  ngOnInit(): void {
    if (this.data.mapConfig) {
      this.leafletOptions = Object.assign(this.leafletOptions, {
        crs: L.CRS.EPSG3857,
      });
    }
    if (this.data.timeDimension) {
      // @ts-ignore
      this.leafletOptions.timeDimensionOptions = {
        timeInterval: 'P1W/' + this.timeMachineService.getEndTimeMachineISOPeriod(this.timeMachineService.getCurrentSelection()),
        period: 'P1D',
        currentTime: this.timeMachineService.getCurrentSelection(),
      };
    }
    if (this.data.legends) {
      this.legends = this.data.legends;
    }
    this.isLoading = true;
    this.loadSources();
    this.zoomEnd.subscribe(() => {
      this.reloadWidget();
    });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.data.mapControl) {
      if (this.control) {
        this.control.remove();
      }
      this.control = new Control.Layers({}, {}, {
        hideSingleBase: true,
      });
    }
    const bboxList: Array<LatLngBounds> = [this.map.getBounds()];
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources, { bbox: bboxList }).subscribe((layers: FeatureGroup) => {
      try {
        if (this.layers) {
          this.layers.getLayers().forEach((l: Layer) => {
            this.map.removeLayer(l);
          });
        }
        if (this.data.mapControl) {
          layers.getLayers().forEach((l: Layer, index: number) => {
            if (l['options']['menuName']) {
              if (this.data.mapControlExclusive) {
                this.control.addBaseLayer(l, this.translateService.instant(l['options']['menuName']));
                if (l['options']['activeDefault']) {
                  l.addTo(this.map);
                }
              } else {
                this.control.addOverlay(l, this.translateService.instant(l['options']['menuName']));
                l.addTo(this.map);
              }
            }
          });
        } else {
          layers.addTo(this.map);
        }
        this.layers = layers;
        if (this.data.mapControl) {
          this.control.addTo(this.map);
        }
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    }, (error: any) => {
      console.error(error);
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.layers) {
      this.layers.remove();
    }
  }

}
