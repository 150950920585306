import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TimetableEntry } from '@app/modules/widgets/public-transport-widget/models/interfaces/timetable-entry';
import { Dictionary, orderBy, uniqBy, groupBy } from 'lodash';
import moment from 'moment';

@Component({
  selector: 'app-timetable-modal',
  templateUrl: './timetable-modal.component.html',
  styleUrls: ['./timetable-modal.component.scss'],
})
export class TimetableModalComponent implements OnInit {

  currentData: Array<TimetableEntry>;
  filteredData: Array<Array<TimetableEntry>>;
  stops: Array<Stops>;
  tripKeys: Array<number>;
  currentFilter: 'start' | 'end' = 'start';

  constructor(public dialogRef: MatDialogRef<TimetableModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: TimeTableData) {
  }

  ngOnInit(): void {
    this.currentData = this.data.timetable;
    this.filterData();
  }

  filterData(): void {
    this.filteredData = [];
    const groupedRoutes: Dictionary<Array<TimetableEntry>> = groupBy(this.currentData, 'tripKey');
    this.stops = uniqBy(this.currentData, 'sequence');
    this.stops = orderBy(this.stops, 'sequence');
    Object.keys(groupedRoutes).forEach((k: string) => {
      this.filteredData.push(groupedRoutes[k]);
    });
    this.tripKeys = uniqBy(this.currentData, 'tripKey').map((t: TimetableEntry) => {
      return t.tripKey;
    });
  }

  getTimes(sequence: number, tripKey: number, type: 'start' | 'end'): Array<string> {
    const timeData: Array<TimetableEntry> = this.currentData.filter((entry: TimetableEntry) => {
      return entry.sequence === sequence && entry.tripKey === tripKey;
    });
    if (timeData) {
      if (type === 'start') {
        return timeData.map((t: TimetableEntry) => {
          if (moment(t.departureTime, 'HH:mm:ss').isValid()) {
            return moment(t.departureTime, 'HH:mm:ss').format('HH:mm');
          } else {
            return '-';
          }
        });
      }
      if (type === 'end') {
        return timeData.map((t: TimetableEntry) => {
          if (moment(t.departureTime, 'HH:mm:ss').isValid()) {
            return moment(t.arrivalTime, 'HH:mm:ss').format('HH:mm');
          } else {
            return '-';
          }
        });
      }
    }
  }

}

export interface TimeTableData {
  stopKey: number;
  timetable: Array<TimetableEntry>;
}

export interface Stops {
  name: string;
  sequence: number;
}
