import { AfterViewInit, Component, OnInit, ViewChild, NgZone, ComponentFactoryResolver, Input, Injector } from '@angular/core';
import {
  MobilitySimulatorBaseLayer,
  MobilitySimulatorBaseLayerTypes, MobilitySimulatorGate, MobilitySimulatorGateLocationCategoryItem, MobilitySimulatorGateLocationItem,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-base-data';
import {
  MobilitySimulatorGateRequestInfo, MobilitySimulatorLabel, MobilitySimulatorLayerVisibility, MobilitySimulatorRequest, SimulatorResponse,
  SimulatorTimeSeriesItem,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-simulator';
import {
  MobilitySimulatorCategoryIndexDescriptor,
  MobilitySimulatorCategoryIndexDescriptorExt,
  MobilitySimulatorExclusionCause,
  MobilitySimulatorLayerStyle,
  MobilitySimulatorRange,
  MobilitySimulatorTypeConfig,
  MobilitySimulatorWidgetData,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-widget-data';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MobilityMapWidgetService } from '@app/modules/widgets/mobility-map-widget/mobility-map-widget.service';
import {
  FeatureGroup,
  TileLayer,
  tileLayer,
  latLng,
  Tooltip,
  Layer,
  MarkerOptions,
  DivIcon,
  LatLng,
  Marker,
  geoJSON,
  featureGroup,
} from 'leaflet';
import { MapConfig } from '../map-widget/model';
import { MobilityControlComponent } from '@app/modules/widgets/mobility-map-widget/mobility-control/mobility-control.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { MapLegend, MapLegendElement } from '@app/shared/models/map-legend/map-legend';
import { WidgetType } from '@app/shared/enums/widget-type';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FeatureCollection, Feature, Point } from 'geojson';
import { TideService } from '../tide-widget/tide.service';
import { CurrentTideEntry } from '../tide-widget/models';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-mobility-map-widget',
  templateUrl: './mobility-map-widget.component.html',
  styleUrls: ['./mobility-map-widget.component.scss'],
})
export class MobilityMapWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  @Input()
  data: MobilitySimulatorWidgetData;

  @ViewChild(MobilityControlComponent) controlComponent: MobilityControlComponent;

  tideLayer: FeatureGroup;
  currentTide: CurrentTideEntry;
  sliderTideValue: number;
  tideSliderEnabled: boolean;
  tideOffset: number = 0;
  walkways: boolean = false;

  gatesLayers: Record<MobilitySimulatorBaseLayerTypes, FeatureCollection> = {
    [MobilitySimulatorBaseLayerTypes.PEDESTRIAN]: null,
    [MobilitySimulatorBaseLayerTypes.WATER]: null,
    [MobilitySimulatorBaseLayerTypes.ROAD]: null,
  };
  gates: Array<MobilitySimulatorGate>;

  GATES_LAYER_KEY: string = 'gates_layer';
  TIDE_LAYER_KEY: string = 'tide_layer';
  BLACKLISTED_PATHS_LAYER_KEY: string = 'blacklisted_paths';
  layers: Map<string, FeatureGroup> = new Map<string, FeatureGroup>();

  simulationTimeline: Array<SimulatorTimeSeriesItem>;
  blockedAreas: Array<any>;
  simulatorLegend: Array<MapLegend>;
  timeserieLoading: boolean = false;

  defaultConfig: MapConfig = {
    center: {
      lat: 45.4287631,
      lon: 12.3238708,
    },
    zoom: 14,
    maxZoom: 25,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
  };

  labels: Array<MobilitySimulatorLabel>;
  availableCategories: Array<MobilitySimulatorCategoryIndexDescriptorExt>;
  selectedCategories: Array<MobilitySimulatorCategoryIndexDescriptorExt>;
  simulatorRunnedCategories: Array<MobilitySimulatorCategoryIndexDescriptorExt>;
  simulatorBaseLayers: Record<MobilitySimulatorBaseLayerTypes, MobilitySimulatorBaseLayer>;

  public baseLayer: TileLayer = tileLayer(this.defaultConfig.url, { zIndex: 1, maxZoom: 19 });

  constructor(public widgetService: MobilityMapWidgetService,
              public dialog: MatDialog,
              private componentFactoryResolver: ComponentFactoryResolver,
              private ngZone: NgZone,
              private tideService: TideService, public injector: Injector) {
    super(widgetService, injector);
    this.drawOptions = {
      position: 'topright',
      edit: {
        featureGroup: this.drawnItems,
      },
      draw: {
        marker: false,
        circle: false,
        circlemarker: false,
        polyline: false,
      },
    };
  }

  ngAfterViewInit(): void {
    this.checkSources();
    this.toggleDrawer();
    this.tideSliderEnabled = true;
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    if (!this.simulatorBaseLayers) {
      this.isLoading = true;

      if (!this.simulatorBaseLayers) {
        this.initBaseLayers().pipe(takeUntil(this.stopObservable)).subscribe(() => {
          this.labels = this.getLabels();

          this.availableCategories = this.getAvailableCategories();
          this.selectedCategories = this.availableCategories.filter((cat: MobilitySimulatorCategoryIndexDescriptorExt) => this.simulatorBaseLayers[cat.type] &&
            this.simulatorBaseLayers[cat.type].config !== undefined && this.simulatorBaseLayers[cat.type].config.visibleOnStart);
          this.isLoading = false;
        });
      }
    }

    try {
      this.refreshGatesLevels();
    } catch (err) {
      console.error(err);
    }

    this.refreshTideLevel();
  }

  refreshGatesLevels(): void {
    const observables: Array<Observable<FeatureCollection>> = new Array<Observable<FeatureCollection>>();
    const gatesTmp: Record<MobilitySimulatorBaseLayerTypes, Array<MobilitySimulatorGate>> = {
      [MobilitySimulatorBaseLayerTypes.PEDESTRIAN]: [],
      [MobilitySimulatorBaseLayerTypes.WATER]: [],
      [MobilitySimulatorBaseLayerTypes.ROAD]: [],
    };
    Object.values(MobilitySimulatorBaseLayerTypes).forEach((t: string) => {
      if (this.simulatorBaseLayers[t].config && this.simulatorBaseLayers[t].config.gates.length > 0) {
        observables.push(this.widgetService.getGatesLevel(this.timeMachineService.getCurrentSelection(), this.sources, {
          currentGates: this.gates,
          simulatorTypeConfig: this.simulatorBaseLayers[t].config,
        }));
      }
    });

    forkJoin(observables).pipe(
      takeUntil(this.stopObservable),
    ).subscribe((data: Array<FeatureCollection>) => {
      Object.values(MobilitySimulatorBaseLayerTypes).forEach((t: string, i: number) => {
        if (this.simulatorBaseLayers[t].config && this.simulatorBaseLayers[t].config.gates.length > 0 && data[i] && data[i].features) {
          gatesTmp[t] = data[i].features.map((ft: Feature) => ft.properties as MobilitySimulatorGate);
          this.gatesLayers[t] = data[i];
        }
      });
      this.gates = [...gatesTmp[MobilitySimulatorBaseLayerTypes.PEDESTRIAN], ...gatesTmp[MobilitySimulatorBaseLayerTypes.WATER], ...gatesTmp[MobilitySimulatorBaseLayerTypes.ROAD]];
    });
  }

  initBaseLayers(): Observable<Array<FeatureGroup>> {
    this.simulatorBaseLayers = {
      [MobilitySimulatorBaseLayerTypes.PEDESTRIAN]: null,
      [MobilitySimulatorBaseLayerTypes.WATER]: null,
      [MobilitySimulatorBaseLayerTypes.ROAD]: null,
    };
    const observables: Array<Observable<FeatureGroup>> = new Array<Observable<FeatureGroup>>();
    Object.values(MobilitySimulatorBaseLayerTypes).forEach((t: string) => {
      this.simulatorBaseLayers[t] = {
        config: this.data.simulationTypes.find((st: MobilitySimulatorTypeConfig) => st.type === t),
      };
      if (this.simulatorBaseLayers[t].config) {
        const style: MobilitySimulatorLayerStyle = this.simulatorBaseLayers[t].config.baseLayerStyle;
        this.widgetService.styles[t] = style;
        if (this.simulatorBaseLayers[t].config.visibleOnStart && !this.simulatorBaseLayers[t].baseLayer) {
          this.simulatorBaseLayers[t].visible = true;
          observables.push(this.loadBaseLayer(this.simulatorBaseLayers[t].config.type));
        }
      }
    });

    return forkJoin(observables);
  }

  loadBaseLayer(t: MobilitySimulatorBaseLayerTypes): Observable<FeatureGroup> {
    return this.widgetService.getMapBaseLevel(this.timeMachineService.getCurrentSelection(), this.sources, {
      type: t,
    }).pipe(
      takeUntil(this.stopObservable),
      switchMap((l: FeatureGroup) => {
        this.simulatorBaseLayers[t].layer = l;
        this.addNewLayer(this.simulatorBaseLayers[t].layer, t);
        return of(l);
      }));
  }

  refreshTideLevel(): void {
    this.tideService.loadData(this.timeMachineService.getCurrentSelection(), this.sources).pipe(
      takeUntil(this.stopObservable),
      switchMap((tide: CurrentTideEntry) => {
        this.currentTide = tide;
        if (!this.sliderTideValue) {
          this.sliderTideValue = this.currentTide.level;
        }
        this.tideSliderEnabled = false;
        return this.tideService.loadFeaturesLockedByTide(this.sources, {
          tideHeight: Math.round(this.sliderTideValue),
          layerStyle: {
            fillColor: this.data.tideLevelStyle.fillColor,
            fillOpacity: this.data.tideLevelStyle.fillOpacity,
          },
          useWalkways: this.walkways,
          tideOffset: this.tideOffset,
        });
      })).subscribe(
      (fg: FeatureGroup) => {
        this.tideLayer = fg;
        this.addNewLayer(this.tideLayer, this.TIDE_LAYER_KEY);
        this.tideSliderEnabled = true;
      },
      (err: any) => {
        if (!this.currentTide) {
          this.currentTide = {
            level: 1,
            trend: 1,
            moment: moment(),
            station: '',
          };
        }
        if (!this.sliderTideValue) {
          this.sliderTideValue = this.currentTide.level;
        }
        this.tideSliderEnabled = true;
        console.error(err);
      });
  }

  getSimulation(): void {
    this.isLoading = true;
    this.resetSimulation();
    this.simulatorRunnedCategories = this.selectedCategories;

    // refresh gates and tide to display correct values
    try {
      this.refreshGatesLevels();
    } catch (err) {
      console.error(err);
    }

    this.refreshTideLevel();

    const simulatedGates: Array<MobilitySimulatorGateRequestInfo> = new Array<MobilitySimulatorGateRequestInfo>();
    this.gates.forEach((g: MobilitySimulatorGate) => {
      g.locations.forEach((l: MobilitySimulatorGateLocationItem) => {
        if (l.categories.find((cat: MobilitySimulatorGateLocationCategoryItem) => cat.valueOverride !== null && cat.valueOverride !== undefined)) {
          const item: MobilitySimulatorGateRequestInfo = {
            id: l.id,
            counters: [],
          };
          l.categories.forEach((cat: MobilitySimulatorGateLocationCategoryItem, index: number) => {
            item.counters[index] = cat.valueOverride !== null && cat.valueOverride !== undefined ? cat.valueOverride : null;
          });
          simulatedGates.push(item);
        }
      });
    });
    try {
      const params: MobilitySimulatorRequest = {
        simulationType: [...new Set(this.selectedCategories.map((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type))],
        tideHeight: this.controlComponent.sliderTideValue,
        simStartTS: 0,
        simStopTS: 0,
        gates: simulatedGates,
        blacklistedAreas: this.drawnItems ? this.drawnItems.toGeoJSON() : null,
        useWalkways: this.walkways,
        tideOffset: this.tideOffset,
      };
      this.widgetService.getSimulationLevel(this.timeMachineService.getCurrentSelection(), this.sources, params).pipe(takeUntil(this.stopObservable)).subscribe((response: SimulatorResponse) => {
        this.blockedAreas = response.blockedAreas;
        this.simulationTimeline = response.timeseries;
        this.generateMapLegend();
        this.drawnItems = featureGroup();
        this.controlComponent.timeseries = this.simulationTimeline.map((ts: SimulatorTimeSeriesItem) => new Date(ts.timestamp));
        this.controlComponent.initTimeseries();
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      console.error(err);
      this.dialog.open(FailureComponent, {
        data: {
          message: 'WIDGETS.MOBILITY_MAP.SIMULATOR_ERROR',
        },
      });
    }
  }

  getLabels(): Array<MobilitySimulatorLabel> {
    const labels: Array<MobilitySimulatorLabel> = Array<MobilitySimulatorLabel>();

    labels.push({
      name: 'TOTAL',
      text: this.translateService.instant('WIDGETS.MOBILITY_MAP.TOTAL'),
    });
    labels.push({
      name: 'AREA',
      text: this.translateService.instant('WIDGETS.MOBILITY_MAP.AREA'),
    });
    labels.push({
      name: 'DENSITY',
      text: this.translateService.instant('WIDGETS.MOBILITY_MAP.DENSITY'),
    });
    this.data.simulationTypes.forEach((st: MobilitySimulatorTypeConfig) => {
      st.indexesDescriptor.categories.forEach((ct: MobilitySimulatorCategoryIndexDescriptor) => {
        labels.push({
          name: ct.label.toUpperCase(),
          text: this.translateService.instant('WIDGETS.MOBILITY_MAP.CATEGORIES.' + ct.label.toUpperCase()),
        });
      });
    });
    return labels;
  }

  gatesChanged(gates: Array<MobilitySimulatorGate>): void {
    this.gates = gates;
    Object.values(MobilitySimulatorBaseLayerTypes).forEach(async (t: string) => {
      if (this.simulatorBaseLayers[t].config && this.simulatorBaseLayers[t].config.gates.length > 0) {
        this.toggleGatesVisibility(this.controlComponent ? this.controlComponent.viewGates : this.data.gatesVisibleOnStart, t as MobilitySimulatorBaseLayerTypes);
      }
    });
  }

  changeSimulatorLayerVisibility(event: MobilitySimulatorLayerVisibility): void {
    this.simulatorBaseLayers[event.type].visible = event.visibile;

    this.ngZone.run(() => {
      const opacity: number = event.visibile ? 0.1 : 0;
      this.changeOpacityToLayer(this.simulatorBaseLayers[event.type], opacity);
    });
  }

  toggleSimulationLayer(event: MobilitySimulatorLayerVisibility): void {
    if (!this.simulatorBaseLayers[event.type].layer) {
      this.loadBaseLayer(event.type as MobilitySimulatorBaseLayerTypes).pipe(takeUntil(this.stopObservable)).subscribe(() => {
        this.changeSimulatorLayerVisibility(event);
      });
    } else {
      this.changeSimulatorLayerVisibility(event);
    }
  }

  resetSimulation(): void {
    this.resetSimulatorLayers();
    this.simulatorLegend = new Array<MapLegend>();
    this.controlComponent.timeseries = new Array<Date>();
    this.blockedAreas = [];
    this.simulationTimeline = new Array<SimulatorTimeSeriesItem>();
    this.simulatorRunnedCategories = new Array<MobilitySimulatorCategoryIndexDescriptorExt>();
  }

  walkwaysChanged(value: boolean): void {
    this.walkways = value;
    this.refreshTideLevel();
    if (this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.PEDESTRIAN].visible) {
      this.refreshSimulationSingleLayer(this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.PEDESTRIAN]);
    }
  }

  tideOffsetChanged(value: number): void {
    this.tideOffset = value;
    this.refreshTideLevel();
  }

  baseLayerChecked(): Record<MobilitySimulatorBaseLayerTypes, boolean> {
    return {
      [MobilitySimulatorBaseLayerTypes.PEDESTRIAN]: this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.PEDESTRIAN] &&
      this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.PEDESTRIAN].visible,
      [MobilitySimulatorBaseLayerTypes.WATER]: this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.WATER] &&
      this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.WATER].visible,
      [MobilitySimulatorBaseLayerTypes.ROAD]: this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.ROAD] &&
      this.simulatorBaseLayers[MobilitySimulatorBaseLayerTypes.ROAD].visible,
    };
  }

  generateMapLegend(): void {
    this.data.simulationTypes.forEach((st: MobilitySimulatorTypeConfig) => {
      if (this.selectedCategories.find((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type === st.type)) {
        const lelements: Array<MapLegendElement> = new Array<MapLegendElement>();
        if (st.autoRanges) {
          const maxMin: MobilitySimulatorRange = this.getMaxMinDensity(st);
          const delta: number = maxMin.max / st.colors.length;

          st.simulatorRanges = [];

          st.colors.forEach((c: string, i: number) => {
            if (i === 0) {
              st.simulatorRanges.push({
                min: 0,
                max: maxMin.min,
              });
            } else if (i <= st.colors.length - 1) {
              st.simulatorRanges.push({
                min: st.simulatorRanges[i - 1].max,
                max: i === st.colors.length - 1 ? null : delta * i,
              });
            }

            let l: string = st.simulatorRanges[i].min + ' < x <= ' + st.simulatorRanges[i].max;
            if (i === st.colors.length - 1) {
              l = 'x > ' + st.simulatorRanges[i].min;
            }
            lelements.push({
              label: l,
              color: c,
            });
          });
        } else {
          st.colors.forEach((c: string, i: number) => {
            let l: string = st.simulatorRanges[i].min + ' < x <= ' + st.simulatorRanges[i].max;
            if (i === st.colors.length - 1) {
              l = 'x > ' + st.simulatorRanges[i].min;
            }
            lelements.push({
              label: l,
              color: c,
            });
          });
        }
        this.simulatorLegend.push({
          title: 'MOBILITY_SIMULATOR.' + st.type.toUpperCase(),
          type: WidgetType.MOBILITY_MAP_WIDGET,
          additionalData: '',
          elements: lelements,
        });
      }
    });
  }

  resetSimulatorLayers(): void {
    Object.values(MobilitySimulatorBaseLayerTypes).forEach((t: string) => {
      if (this.simulatorBaseLayers[t] && this.simulatorBaseLayers[t].layer) {
        this.simulatorBaseLayers[t].layer.eachLayer((l: any) => {
          l.eachLayer((f: any) => {
            if (f.getTooltip()) {
              f.closeTooltip();
              f.unbindTooltip();
            }
            f.setStyle({
              fillColor: this.simulatorBaseLayers[t].config.baseLayerStyle.fillColor,
              fillOpacity: this.selectedCategories.find((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type === t)
                ? this.simulatorBaseLayers[t].config.baseLayerStyle.fillOpacity : 0,
            });
          });
        });
      }
    });
  }

  getMaxMinDensity(st: MobilitySimulatorTypeConfig): MobilitySimulatorRange {
    let maxDensity: number = 0;
    let minDensity: number = 10000000;
    this.simulationTimeline.forEach((ts: SimulatorTimeSeriesItem) => {
      const data: Array<any> = ts.poly.find(
        (item: Array<any>) => item[st.indexesDescriptor.type] === st.typeIndex);
      let total: number = 0;
      st.indexesDescriptor.categories
        .filter((ct: MobilitySimulatorCategoryIndexDescriptor) => this.selectedCategories.find((sct: MobilitySimulatorCategoryIndexDescriptorExt) =>
          sct.index === ct.index && sct.label === ct.label))
        .forEach((ct: MobilitySimulatorCategoryIndexDescriptor) => {
          const catIndexDescriptor: MobilitySimulatorCategoryIndexDescriptorExt = this.selectedCategories.find((sct: MobilitySimulatorCategoryIndexDescriptorExt) =>
            sct.index === ct.index && sct.label === ct.label);
          if (catIndexDescriptor.area) {
            total += data[ct.index] * catIndexDescriptor.area;
          } else {
            total += data[ct.index];
          }
        });
      const area: number = data[st.indexesDescriptor.area];
      const density: number = parseFloat((total / area).toFixed(3));
      if (density !== Infinity && density > maxDensity) {
        maxDensity = density;
      }
      if (density !== 0 && density < minDensity) {
        minDensity = density;
      }
    });

    return {
      max: maxDensity,
      min: minDensity,
    };
  }

  getAvailableCategories(): Array<MobilitySimulatorCategoryIndexDescriptorExt> {
    const ct: Array<MobilitySimulatorCategoryIndexDescriptorExt> = new Array<MobilitySimulatorCategoryIndexDescriptorExt>();

    this.data.simulationTypes.forEach((st: MobilitySimulatorTypeConfig) => {
      st.indexesDescriptor.categories.forEach(async (ctOptions: MobilitySimulatorCategoryIndexDescriptor) => {
        const ctTemp: MobilitySimulatorCategoryIndexDescriptorExt = ctOptions as MobilitySimulatorCategoryIndexDescriptorExt;
        ctTemp.color = st.baseLayerStyle.fillColor;
        ctTemp.type = st.type;
        ct.push(ctTemp);
      });
    });
    return ct;
  }

  refreshSimulationSingleLayer(baseLayer: MobilitySimulatorBaseLayer): void {
    if (baseLayer && baseLayer.layer) {

      const currentTimeSeriesItem: SimulatorTimeSeriesItem = this.simulationTimeline
        ? this.simulationTimeline[this.controlComponent.sliderTimeserieValue] : null;

      baseLayer.layer.eachLayer((l: any) => {
        l.eachLayer((f: any) => {
          let tooltip: Tooltip = f.getTooltip();
          if (!tooltip) {
            tooltip = new Tooltip({ direction: 'top' });
          } else {
            f.closeTooltip();
            f.unbindTooltip();
          }
          const typeConfig: MobilitySimulatorTypeConfig = baseLayer.config;

          let color: string = baseLayer.config.baseLayerStyle.fillColor;
          let opacity: number = this.simulatorBaseLayers[typeConfig.type].visible ? baseLayer.config.baseLayerStyle.fillOpacity : 0;

          if (currentTimeSeriesItem) {
            const data: Array<any> = currentTimeSeriesItem.poly.find(
              (item: Array<any>) => item[typeConfig.indexesDescriptor.type] === typeConfig.typeIndex &&
                item[typeConfig.indexesDescriptor.id] === f.feature.properties[typeConfig.featureIdKey]);
            const blocked: Array<any> = this.blockedAreas.find((b: Array<any>) =>
              b[0] === f.feature.properties[typeConfig.featureIdKey] && b[1] === typeConfig.typeIndex);

            let template: string = '';
            if (typeConfig.polyIdInTooltip) {
              template += `<h6>${f.feature.properties[typeConfig.featureIdKey]}</h6><br>`;
            }

            if (data && !blocked) {
              let total: number = 0;
              let totalTransit: number = 0;

              typeConfig.indexesDescriptor.categories
                .forEach((ct: MobilitySimulatorCategoryIndexDescriptor) => {
                  const catIndexDescriptor: MobilitySimulatorCategoryIndexDescriptorExt = this.selectedCategories.find((sct: MobilitySimulatorCategoryIndexDescriptorExt) =>
                    sct.index === ct.index && sct.label === ct.label);

                  if (this.selectedCategories.find((sct: MobilitySimulatorCategoryIndexDescriptorExt) => sct.index === ct.index && sct.label === ct.label)) {
                    if (catIndexDescriptor.area) {
                      total += data[ct.index] * catIndexDescriptor.area;
                    } else {
                      total += data[ct.index];
                    }
                    totalTransit += data[ct.index];
                    const label: string = this.labels.find((mbl: MobilitySimulatorLabel) => mbl.name === ct.label.toUpperCase()).text;
                    template += `<span>${label}: ${data[ct.index]}</span><br>`;
                  }
                });

              const totalLabel: string = this.labels.find((mbl: MobilitySimulatorLabel) => mbl.name === 'TOTAL').text;
              template += `<span>${totalLabel}: ${totalTransit}</span><br><br>`;

              const area: number = data[typeConfig.indexesDescriptor.area];
              if (typeConfig.areasInTooltip.enabled) {
                const areaLabel: string = this.labels.find((mbl: MobilitySimulatorLabel) => mbl.name === 'AREA').text;
                template += `<span>${areaLabel}: ${area} ${typeConfig.areasInTooltip.unit}</span></b><br><br>`;
              }
              const densityLabel: string = this.labels.find((mbl: MobilitySimulatorLabel) => mbl.name === 'DENSITY').text;
              const density: number = parseFloat((total / area).toFixed(3));
              template += `<b><span>${densityLabel}: ${density}</span></b>`;

              const colorIndex: number = typeConfig.simulatorRanges.findIndex((sr: MobilitySimulatorRange) => {
                if (sr.max) {
                  return density > sr.min && density <= sr.max;
                } else {
                  return density > sr.min;
                }
              });
              if (colorIndex !== undefined && colorIndex !== -1) {
                color = typeConfig.colors[colorIndex];
                opacity = this.simulatorBaseLayers[typeConfig.type].visible ? 1 : 0;
              }
            } else if (blocked) {
              const reason: string = blocked[2];
              color = typeConfig.exclusionCauses.find((c: MobilitySimulatorExclusionCause) => c.type === reason).style.fillColor;
              opacity = this.simulatorBaseLayers[typeConfig.type].visible ? 1 : 0;
              template += `<span>excluded by: ${reason}</span><br>`;
            }

            if (data || blocked) {
              tooltip.setContent(template);
              f.bindTooltip(tooltip);
            }
          }

          f.setStyle({
            color: this.data.walkwaysBorderColor,
            opacity: 0.5,
            stroke: f.feature.properties.walkway === 1 && this.walkways,
            fillColor: color,
            fillOpacity: opacity,
          });
        });
      });
    }
  }

  refreshSimulationLayers(): void {
    this.timeserieLoading = true;
    Object.values(MobilitySimulatorBaseLayerTypes).forEach((t: string) => {
      if (this.selectedCategories.find((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type === t)) {
        this.refreshSimulationSingleLayer(this.simulatorBaseLayers[t]);
      }
    });
    this.timeserieLoading = false;
  }

  categoriesSelectionChanged(selected: Array<MobilitySimulatorCategoryIndexDescriptorExt>): void {
    if (this.simulatorRunnedCategories) {
      const runnedCats: Array<string> = [...new Set(this.simulatorRunnedCategories.map((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type))];
      const olderCats: Array<string> = [...new Set(this.selectedCategories.map((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type))];
      const newCats: Array<string> = [...new Set(selected.map((ct: MobilitySimulatorCategoryIndexDescriptorExt) => ct.type))];
      runnedCats.forEach((oldct: string) => {
        if (!newCats.find((newct: string) => newct === oldct)) {
          this.controlComponent.checkedTypes[oldct] = false;
          this.toggleSimulationLayer({
            type: oldct,
            visibile: false,
          });
        }
        if (newCats.find((newct: string) => newct === oldct) && !olderCats.find((actualct: string) => actualct === oldct)) {
          this.controlComponent.checkedTypes[oldct] = true;
          this.toggleSimulationLayer({
            type: oldct,
            visibile: true,
          });
        }
      });
      let mustRenewSimulation: boolean = false;
      newCats.forEach((newct: string) => {
        if (!runnedCats.find((oldct: string) => newct === oldct)) {
          mustRenewSimulation = true;
        }
      });
      if (mustRenewSimulation) {
        this.dialog.open(FailureComponent, {
          data: {
            message: 'WIDGETS.MOBILITY_MAP.RENEW_SIMULATION',
          },
        });
      }
    }
    this.selectedCategories = selected;
    if (this.simulatorLegend && this.simulationTimeline) {
      this.simulatorLegend = new Array<MapLegend>();
      this.generateMapLegend();
      this.refreshSimulationLayers();
    }
  }

  changeOpacityToLayer(baseLayer: MobilitySimulatorBaseLayer, opacityValue: number): void {
    if (this.simulatorLegend && this.simulationTimeline && opacityValue > 0) {
      this.refreshSimulationSingleLayer(baseLayer);
    } else {
      baseLayer.layer.setStyle({
        fillOpacity: opacityValue,
      });
    }
  }

  peoplePerMinutePopup(): (f: Feature<Point>) => Layer {
    return (f: Feature<Point>): Layer => {
      const translate: TranslateService = this.translateService;
      const layer: FeatureGroup = new FeatureGroup();
      const tooltip: Tooltip = new Tooltip({ direction: 'top', className: 'text-left' });
      const gate: MobilitySimulatorGate = f.properties as MobilitySimulatorGate;
      let template: string = '';
      template = `<h6>${gate.label}</h6><br>`;

      gate.locations.forEach((l: MobilitySimulatorGateLocationItem) => {
        template += '<div class="mb-2">';
        template += `<label><b>${translate.instant('WIDGETS.MOBILITY_MAP.GATES.LOCATIONS.' + l.label)}</b></label><br>`;
        const val: string = l.value !== null && l.value !== undefined ? l.value : translate.instant('WIDGETS.MOBILITY_MAP.NOT_AVAILABLE');
        template += `<label class="mr-2 ml-2" >${translate.instant('WIDGETS.MOBILITY_MAP.REAL_TOTAL')}</label><span>${val}</span>`;

        if (l.categories.filter((c: MobilitySimulatorGateLocationCategoryItem) => c.valueOverride !== null && c.valueOverride !== undefined).length > 0) {
          template += `<br><label class="ml-2">${translate.instant('WIDGETS.MOBILITY_MAP.SIMULATED_VALUES')}</label>`;

          l.categories.filter((c: MobilitySimulatorGateLocationCategoryItem) => c.valueOverride !== null && c.valueOverride !== undefined)
            .forEach((c: MobilitySimulatorGateLocationCategoryItem) => {
              template += `<br><span class="ml-4 mr-2">${c.valueOverride}</span><label class="mr-2">${translate.instant('WIDGETS.MOBILITY_MAP.CATEGORIES.' + c.label)}</label><span>${translate.instant('WIDGETS.MOBILITY_MAP.PER_MIN')}</span>`;
            });
        }
        template += '</div>';
      });
      tooltip.setContent(template);

      let selected: string = '';
      if (f.properties.selected) {
        selected = 'selected';
      }
      let valueOverride: string = '';
      if (gate.locations.find((l: MobilitySimulatorGateLocationItem) => l.categories.filter((c: MobilitySimulatorGateLocationCategoryItem) => c.valueOverride !== null && c.valueOverride !== undefined).length > 0)) {
        valueOverride = 'value-override';
      }
      const style: string = `style='background-color: ${this.simulatorBaseLayers[f.properties.type].config.baseLayerStyle.fillColor};'`;

      const markerOptions: MarkerOptions = {
        icon: new DivIcon({
          className: 'gate-marker',
          html: `<div ${style} class='marker-pin ${selected} ${valueOverride} d-flex justify-content-center'></div>`,
        }),
      };
      const position: LatLng = new LatLng(f.geometry.coordinates[1], f.geometry.coordinates[0]);
      const marker: Marker = new Marker(position, markerOptions);
      marker.addTo(layer);
      marker.bindTooltip(tooltip);
      marker.on('click', () => {
        this.controlComponent.setCurrentGate(f.properties.id);
      });
      return layer;
    };
  }

  toggleAllGatesLayers(visible: boolean): void {
    Object.values(MobilitySimulatorBaseLayerTypes).forEach(async (t: string) => {
      if (this.simulatorBaseLayers[t].config && this.simulatorBaseLayers[t].config.gates.length > 0) {
        this.toggleGatesVisibility(visible, t as MobilitySimulatorBaseLayerTypes);
      }
    });
  }

  toggleGatesVisibility(visible: boolean, type: MobilitySimulatorBaseLayerTypes): void {
    if (!visible) {
      this.removeOldLayer(this.GATES_LAYER_KEY + type);
    } else {
      this.addNewLayer(geoJSON(this.gatesLayers[type], {
        pointToLayer: this.peoplePerMinutePopup(),
      }), this.GATES_LAYER_KEY + type);
    }
  }

  addNewLayer(layer: FeatureGroup, key: string): void {
    this.removeOldLayer(key);
    if (layer) {
      layer.addTo(this.map);
      this.layers.set(key, layer);
    }
  }

  removeOldLayer(key: string): void {
    const oldLayer: FeatureGroup = this.layers.get(key);
    if (oldLayer) {
      this.map.removeLayer(oldLayer);
    }
  }

  resetMap(): void {
    this.layers = new Map<string, FeatureGroup>();
    this.map.eachLayer((l: any) => {
      if (l.options.id !== 'base_layer') {
        this.map.removeLayer(l);
      }
    });
  }

  tideChanged(newValue: number): void {
    this.sliderTideValue = newValue;
    this.refreshTideLevel();
  }
}
