import { Injectable } from '@angular/core';
import { EnumData, EnumLabels } from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-source';
import { GeoserverDataSource, InfoData } from '@app/modules/widgets/geoserver-widget/models/geoserver-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Alert } from '@app/shared/models/alerts';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'geojson';
import { DivIcon, FeatureGroup, LatLngBounds, LatLngExpression, Layer, Marker, PathOptions, Popup, Tooltip, Util } from 'leaflet';
import moment from 'moment';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import urlRegex from 'url-regex';
import { MapWidgetService } from '../map-widget/map-widget.service';
import './models/leaflet-betterwms';
import './models/leaflet-bettertimedimension';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { GeoserverSourceData } from './models/geoserver-data-source';

declare var L: any;

@Injectable()
export class GeoserverService extends MapWidgetService {

  getSourceAdditionalData(additionalData: any): GeoserverSourceData | null {
    let sourceAdditionalData: any = null;
    if (additionalData && additionalData.levelInfo.overrideServer) {
      try {
        sourceAdditionalData = additionalData.levelInfo.additionalData ? JSON.parse(additionalData.levelInfo.additionalData.sourceData) : {};
      } catch (e) {
        sourceAdditionalData = null;
      }
    }
    return sourceAdditionalData;
  }

  getTemplateLine(languageKey: string, value: string): string {
    if (urlRegex().test(value)) {
      return `<div class="tooltip-line"><div class="mr-2"><b>${this.translateService.instant(languageKey)}:</b></div><div class="truncate"><a href="${value}" target="_blank">${value || ''}</a></div></div>`;
    } else {
      return `<div class="tooltip-line"><div class="mr-2"><b>${this.translateService.instant(languageKey)}:</b></div><div class="truncate">${value || ''}</div></div>`;
    }
  }

  loadTemplate(feature: Feature, source: GeoserverDataSource): string {
    const translate: TranslateService = this.translateService;
    let template: string = '';
    let sourceData: Array<InfoData> = [];
    if (source.sourceData && source.sourceData.infoData && source.sourceData.infoData.length) {
      sourceData = source.sourceData.infoData;
    }
    for (const [key, value] of Object.entries(feature.properties)) {
      if (sourceData && sourceData.length) {
        const i: InfoData = sourceData.find((d: InfoData) => {
          return key === d.value;
        });
        if (i) {
          const languageKey: string = `SOURCE.${source.name.toUpperCase()}.${i.value}`;
          if (i.type) {
            if (i.type === 'DATE') {
              template = template + `<div class="tooltip-line"><div class="mr-2"><b>${translate.instant(languageKey)}:</b></div> ${moment(value).format(i.format) || ''}</div>`;
            }

            if (i.type === 'ENUM') {
              const enumData: EnumData = source.sourceData.enumFields.find((item: EnumData) => item.field === i.value.toString());
              let enumValue: string = '';
              if (enumData) {
                const property: any = feature.properties[i.value];
                enumValue = enumData.values.find((label: EnumLabels) => label.value === property.toString()).name || property.toString();
              }
              template = template + `<div class="tooltip-line"><div class="mr-2"<b>${translate.instant(languageKey)}:</b></div> ${enumValue || ''}</div>`;
            }
          } else {
            template = template + this.getTemplateLine(languageKey, value as string);
          }
        }
      } else {
        template = template + this.getTemplateLine(key, value as string);
      }
    }
    return template;
  }

  loadTooltipFromData(feature: Feature, source: GeoserverDataSource): Tooltip {
    const dataTooltip: Tooltip = new Tooltip({
      offset: source.sourceData.marker.iconType && source.sourceData.marker.iconType === 'svg' ? [0, -36] : [0, -6],
      direction: 'top',
    });

    const template: string = this.loadTemplate(feature, source);
    dataTooltip.setContent(template);
    return dataTooltip;
  }

  addPopupToLayer(source: GeoserverDataSource, clusterLayer: any): (f: Feature, l: Layer) => void {
    return (f: Feature, l: Layer): void => {
      if (f.geometry.type !== 'Point' && f.geometry.type !== 'MultiPoint') {
        const popup: Popup = new Popup({
          autoClose: false,
          closeOnClick: false,
        });
        const template: string = this.loadTemplate(f, source);
        popup.setContent(template);
        l.bindPopup(popup);
        l.on('click', () => {
          l.openPopup();
        });

        clusterLayer.addLayer(l);
        this.markers.push({
          id: f.properties.id || f.id,
          markerOnMap: l,
        });
      }
    };
  }

  showInfoTooltip(source: GeoserverDataSource): (err: Error, latlng: LatLngExpression, content: any, levelMap: any) => void {
    return (err: Error, latlng: LatLngExpression, content: any, levelMap: any): void => {
      if (content && content.features) {
        content.features.forEach((f: Feature) => {
          const template: string = this.loadTemplate(f, source);
          const popup: Popup = new Popup({
            autoClose: true,
            closeOnClick: true,
          });
          popup.setLatLng(latlng);
          popup.setContent(template);
          popup.openOn(levelMap);
        });
      }
    };
  }

  addLevelTooltip(
    source: GeoserverDataSource,
    selectedAlert: Alert,
    menuDesc: string,
  ): (f: Feature, latlng: LatLngExpression) => Layer {
    return (f: Feature, latlng: LatLngExpression): Layer => {
      const widgetLevel: string = source.name;
      let markerUrl: string = '';

      if (source.sourceData.marker.iconType && source.sourceData.marker.iconType === 'svg') {
        const markerDown: any = require(`!raw-loader!./../../../../assets/img/markers/${source.sourceData.marker.iconUrl}`);
        const markerTop: any = require(`!raw-loader!./../../../../assets/img/markers/${source.sourceData.marker.iconTopUrl}`);
        const markerLeft: any = require(`!raw-loader!./../../../../assets/img/markers/${source.sourceData.marker.iconLeftUrl}`);
        const markerRight: any = require(`!raw-loader!./../../../../assets/img/markers/${source.sourceData.marker.iconRightUrl}`);

        switch (f.properties.direction) {
          case 'N':
            markerUrl = markerTop.default;
            break;
          case 'E':
            markerUrl = markerRight.default;
            break;
          case 'S':
            markerUrl = markerDown.default;
            break;
          case 'W':
            markerUrl = markerLeft.default;
            break;
          default:
            markerUrl = markerDown.default;
            break;
        }
      }

      const dataTooltip: Tooltip = this.loadTooltipFromData(f, source);
      const markerColor: string = source.sourceData.marker.color;
      const markerSelectedColor: string = source.sourceData.marker.selectedColor;
      let selected: boolean = false;
      const searchId: number = parseInt(f.id.toString().replace('pmv.', ''), 10);
      if (
        selectedAlert &&
        selectedAlert.databag &&
        selectedAlert.databag.panels &&
        selectedAlert.databag.panels.includes(searchId)
      ) {
        selected = true;
      }
      L.Marker.Custom = L.Marker.extend({
        options: {
          type: '',
          menuName: '',
        },
      });
      let marker: any;
      if (source.sourceData.marker.iconType && source.sourceData.marker.iconType === 'svg') {
        marker = new L.Marker.Custom(latlng, {
          icon: this.getMarkerIcon(selected, markerUrl, markerColor, markerSelectedColor),
          // rotationAngle: rotateAngle,
          menuName: menuDesc,
          type: source.name,
        });
      } else {
        marker = new L.Marker.Custom(latlng, {
          icon: new DivIcon({
            className: `arcgis-marker arcgis-marker--${widgetLevel}`,
            html: `<div class="marker-pin"><i style="color:${markerColor};" class="icon-venice icon-venice_${source.sourceData.marker.iconFont}"></i></div>`,
          }),
          // rotationAngle: rotateAngle,
          type: menuDesc,
        });
      }

      marker.bindTooltip(dataTooltip);
      this.markers.push({
        id: f.properties.id || f.id,
        markerOnMap: marker,
        additionalData: {
          rotate: 0,
          color: markerColor,
          selectedColor: markerSelectedColor,
          type: source.name,
        },
      });
      return marker;
    };
  }

  getMarkerIcon(selected: boolean, marker: string, markerColor: string, markerSelectedColor: string): DivIcon {
    const color: string = selected ? markerSelectedColor : markerColor;

    const iconSettings: any = {
      mapIconUrl: marker,
      mapIconColor: color,
    };
    return new DivIcon({
      className: 'prova',
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      html: Util.template(iconSettings.mapIconUrl, iconSettings),
    });
  }

  public levelStyle(): (feature: any) => PathOptions {
    return (): PathOptions => {
      return {
        color: 'green',
        weight: 2,
      };
    };
  }

  public hasTimeDimension(sources: Array<GeoserverDataSource>): boolean {
    const mainSource: GeoserverDataSource = sources.find((w: GeoserverDataSource) => {
      return w.sourceType === 'geoserver';
    });
    const sourceData: GeoserverSourceData = JSON.parse(mainSource.configuration).sourceData;
    return sourceData.timeDimension;
  }

  public getSingleMapLevel(
    timeMachineData: TimeMachineData,
    source: GeoserverDataSource,
    additionalData?: any,
  ): Observable<Layer> {
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
    let bboxFilter: string = '';
    let combinedCondition: any;
    const bboxVar: string = source.sourceData.bboxVariable ? source.sourceData.bboxVariable : 'geometry';
    let condition: any;
    if (additionalData && additionalData.bbox) {
      additionalData.bbox.forEach((element: LatLngBounds) => {
        if (bboxFilter === '') {
          bboxFilter += 'BBOX(' + bboxVar + ', ' + element.toBBoxString() + ') ';
          condition = new L.Filter.BBox(bboxVar, element, L.CRS.EPSG4326);
        } else {
          bboxFilter += 'OR BBOX(' + bboxVar + ', ' + element.toBBoxString() + ') ';
          const bbox: any = new L.Filter.BBox(bboxVar, element, L.CRS.EPSG4326);
          if (combinedCondition) {
            combinedCondition = new L.Filter.Or(combinedCondition, bbox);
          } else {
            combinedCondition = new L.Filter.Or(bbox, condition);
          }
        }
      });
    }
    if (!combinedCondition) {
      combinedCondition = condition;
    }
    let viewParamsData: string;

    if (source.sourceData.viewParams) {
      const newSource: GeoserverDataSource = Object.assign({}, source);
      newSource.rewriteUrl = source.sourceData.viewParams;
      viewParamsData = this.addTimeMachineDataToUrl(timeMachineData, newSource);

      if (this.timeMachineService.isTimeMachineActive()) {
        viewParamsData += ';isNow:0';
      }
    }

    const menuDesc: string = this.translateService.instant(
      `WIDGETS.GEOSERVER.${source.sourceData.typeNs.toUpperCase()}.${source.sourceData.typeName.toUpperCase()}.${source.name.toUpperCase()}`,
    );

    const clusterLayer: any = L.markerClusterGroup({
      disableClusteringAtZoom: source.sourceData.disableClusteringAtZoom
        ? source.sourceData.disableClusteringAtZoom
        : 14,
      iconCreateFunction: this.createClusterIcon(source),
      showCoverageOnHover: true,
      animate: false,
      spiderfy: true,
      spiderfyOnMaxZoom: source.sourceData.spiderfyOnMaxZoom !== undefined ? source.sourceData.spiderfyOnMaxZoom : true,
      zoomToBoundsOnClick: true,
      menuName: menuDesc,
      type: source.name,
    });
    let layer: any;

    if (source.sourceData.timeDimension) {
      L.TileLayer.BetterWMS.prototype.showGetFeatureInfo = this.showInfoTooltip(source);
      const wmsLayer: any = L.tileLayer.betterWms(url, {
        crs: L.CRS.EPSG3857,
        layers: `${source.sourceData.typeNs}:${source.sourceData.typeName}`,
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        opacity: source.sourceData.style.opacity,
      }).setParams({
        transparent: true,
        format: 'image/png',
        service: 'WMS',
        width: 1024,
        height: 1024,
        layers: `${source.sourceData.typeNs}:${source.sourceData.typeName}`,
        request: 'GetMap',
      });
      layer = L.tileLayer.betterTimeDimension(wmsLayer, {
        updateTimeDimension: true,
        updateTimeDimensionMode: 'replace',
        requestTimeFromCapabilities: source.sourceData.requestFromCapabilities,
        cache: 10,
      }).setParams({
        transparent: true,
        format: 'image/png',
        service: 'WMS',
        width: 1024,
        height: 1024,
        layers: `${source.sourceData.typeName}`,
        request: 'GetMap',
      });
      if (source.sourceData.timePeriod) {
        layer.setAvailableTimes(source.sourceData.timePeriod);
      }
    } else {
      if (source.sourceData.tileLayer) {
        L.TileLayer.BetterWMS.prototype.showGetFeatureInfo = this.showInfoTooltip(source);
        layer = L.tileLayer.betterWms(url, {
          crs: L.CRS.EPSG3857,
          layers: `${source.sourceData.typeNs}:${source.sourceData.typeName}`,
          format: `${source.sourceData.format}`,
          transparent: `${source.sourceData.transparent}`,
          version: '1.1.1',
          attribution: `${source.sourceData.attribution}`,
          exceptions: `${source.sourceData.exceptions}`,
        }).setParams({
          transparent: true,
          format: `${source.sourceData.format}`,
          service: 'WMS',
          width: 892,
          height: 892,
          layers: `${source.sourceData.typeNs}:${source.sourceData.typeName}`,
          request: 'GetMap',
        });
      } else {
        layer = new L.WFS(
          {
            crs: L.CRS.EPSG3857,
            url: url,
            filter: additionalData ? combinedCondition : null,
            typeNS: this.replaceUrl(source.sourceData.typeNs),
            typeName: this.replaceUrl(source.sourceData.typeName),
            opacity: source.sourceData.style.opacity,
            fillOpacity: source.sourceData.style.fillOpacity,
            style: source.sourceData.style,
            vendorOptions: {
              viewParams: source.sourceData.viewParams ? viewParamsData : null,
            },
          },
          new L.Format.GeoJSON({
            crs: L.CRS.EPSG3857,
            pointToLayer: this.addLevelTooltip(source, additionalData.selectedAlert, menuDesc),
          }),
        );
      }
    }

    layer.on('click', (event: any) => {
      const f: Feature = event.layer.feature;
      if (f.geometry.type !== 'Point' && f.geometry.type !== 'MultiPoint') {
        const template: string = this.loadTemplate(f, source);
        const currentPopup: any = event.layer.getPopup();

        if (currentPopup) {
          currentPopup.setContent(template);
          currentPopup.update();
          currentPopup.openPopup();
        } else {
          const popup: Popup = new Popup({
            autoClose: false,
            closeOnClick: false,
          });
          popup.setContent(template);
          event.layer.bindPopup(popup).openPopup();
        }
      }
    });

    if (source.sourceData.cluster) {
      return from(
        // tslint:disable-next-line:typedef
        new Promise<FeatureGroup<any>>((resolve, reject) => {
          layer.on('load', () => {
            clusterLayer.addLayer(layer);
            resolve(clusterLayer);
          });
        }),
      );
    } else {
      layer.options.menuName = menuDesc;
      layer.options.activeDefault = source.sourceData && source.sourceData.activeDefault;
      layer.options.type = source.name;
    }

    return of(layer);
  }

  createClusterIcon(source: GeoserverDataSource): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      if (source.sourceData.marker.iconClusterUrl) {
        const markerSvg: any = require(`!raw-loader!./../../../../assets/img/markers/${source.sourceData.marker.iconClusterUrl}`);

        const total: Array<Marker> = cluster.getChildCount();
        const widgetLevel: string = source.name;
        const iconSettings: any = {
          mapIconUrl: markerSvg.default,
          mapIconColor: source.sourceData.marker.clusterColor,
        };
        return new DivIcon({
          iconSize: [46, 46],
          iconAnchor: [23, 46],
          className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
          html: `${Util.template(iconSettings.mapIconUrl, iconSettings)}
        <div class="d-flex flex-column ">
          <div><span class="marker-group__svg_label1">${total}</span></div>
        </div>`,
        });
      } else {
        const total: Array<Marker> = cluster.getChildCount();
        const widgetLevel: string = source.name;
        const markerColor: string = source.sourceData.style.color;
        return new DivIcon({
          className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
          html: `<div class="d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column marker-pin">
                    <div style="color:${markerColor};" class=\'icon-venice ${source.sourceData.marker.iconCluster}\'><span class="marker-group__label">${total}</span></div>
                </div>
               </div>`,
        });
      }
    };
  }

  public getLegend(sources: Array<WidgetDataSource>): MapLegend {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'geoserver';
    });

    const sourceData: GeoserverSourceData = JSON.parse(mainSource.configuration).sourceData;
    return sourceData.legend ? sourceData.legend : null;
  }

  public getMapLevel(
    timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>,
    additionalData?: any,
  ): Observable<FeatureGroup> {
    const mapLevel: FeatureGroup = new FeatureGroup();
    const observables: Array<Observable<Layer>> = sources
      .filter((w: WidgetDataSource) => {
        return w.sourceType === 'geoserver';
      })
      .map((source: GeoserverDataSource) => {
        source.sourceData = JSON.parse(source.configuration).sourceData;
        return this.getSingleMapLevel(timeMachineData, source, additionalData);
      });
    return forkJoin(observables).pipe(
      map((features: Array<FeatureGroup>) => {
        features.forEach((f: FeatureGroup) => {
          f.addTo(mapLevel);
        });
        return mapLevel;
      }),
    );
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const sourceUrl: string = this.addTimeMachineDataToUrl(new Date().getTime(), source);
    const url: string = sourceUrl.replace('{z}/{x}/{-y}', '14/8733/10517');
    return this.http.get<any>(url);
  }
}
