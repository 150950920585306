import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { WidgetModule } from '../widget/widget.module';
import { AmatWidgetComponent } from './amat-widget.component';
import { AmatWidgetService } from './amat-widget.service';


@NgModule({
  declarations: [AmatWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    ChartsModule,
    MatSliderModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    MatExpansionModule,
    MatIconModule,
  ],
  exports: [AmatWidgetComponent],
  providers: [AmatWidgetService],
})
export class AmatWidgetModule {
}
