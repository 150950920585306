import { EventEmitter, Injectable } from '@angular/core';
import {
  NoUiSliderRangeTimestamps,
  SliderRange,
  TimeMachineData,
  TimeMachineAdvancedRange,
  TimeMachineSliderRange,
  TimeMachineUpdateSliderResult,
  TimeMachineUpdateSliderRangeResult, SerializedSlider,
} from '@app/shared/components/time-machine/models';
import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { Range, RangeManagerService } from '@services/range-manager/range-manager.service';
import moment, { DurationInputArg1, Moment } from 'moment';
import { interval, Observable, Subscription } from 'rxjs';

@Injectable()
export class TimeMachineService {

  private timeUpdatePeriod: number;
  minutesRefresh: number = 5;
  forecast: boolean = false;
  past: boolean = false;
  enabled: boolean;
  showLabel: boolean = true;

  timeSliderValue: number = 5;
  rangeTimeSliderValue: NoUiSliderRangeTimestamps;
  previousRangeTimeSliderValue: NoUiSliderRangeTimestamps;
  timeMachineRange: TimeMachineSliderRange;
  timeMachineAdvanceRange: TimeMachineAdvancedRange;
  advancedRangeTimePicker: SliderRange;
  advancedRangeTimePickerMaxDate?: Moment;
  advancedRangeTimePickerMinDate?: Moment;
  timer: Moment;
  rangeOn: boolean;
  advancedRangeOn: boolean;
  showAdvancedRange: boolean;
  allowPast: boolean;
  allowFuture: boolean;
  showPeriod: boolean;

  // Observable string streams
  timeMachineChanged: EventEmitter<TimeMachineData> = new EventEmitter<TimeMachineData>();

  refreshInterval: Observable<number>;
  refreshSubscription: Subscription;
  autoRefresh: boolean = true;
  currentTimeRangeKey: string;

  constructor(private rangeManagerService: RangeManagerService) {
    this.enabled = true;
    this.allowFuture = true;
    this.allowPast = true;
    this.advancedRangeOn = false;
    this.showAdvancedRange = true;
    this.timeSliderValue = 0;
    this.rangeTimeSliderValue = [-1, 0];
    this.previousRangeTimeSliderValue = [-1, 0];
    this.currentTimeRangeKey = null;
    this.timer = moment().seconds(0);
    const timeMachineRefresh: string = localStorage.getItem('time_machine_refresh');
    if (timeMachineRefresh) {
      this.minutesRefresh = Number(timeMachineRefresh);
    }
    this.updateRefresh(this.minutesRefresh);
    this.timeMachineRange = new TimeMachineSliderRange(this.rangeTimeSliderValue);
    this.timeMachineAdvanceRange = new TimeMachineAdvancedRange(this.timeMachineRange.from, this.timeMachineRange.to);
    this.advancedRangeTimePicker = { from: this.timeMachineRange.from, to: this.timeMachineRange.to };
  }

  getEndTimeMachineISOPeriod(range: TimeMachineData): string {
    let timeInterval: string;
    if (typeof range === 'number') {
      timeInterval = new Date(range).toISOString();
    } else {
      timeInterval = new Date(range.getToTimeStamp()).toISOString();
    }
    return timeInterval;
  }

  getTimeMachineISOPeriod(range: TimeMachineData): string {
    let timeInterval: string;
    if (typeof range === 'number') {
      const yesterday: number = new Date(range).setDate(new Date(range).getDate() - 7);
      timeInterval = `${moment(yesterday).format('YYYY-MM-DD')} /${moment(range).format('YYYY-MM-DD')}`;
    } else {
      timeInterval = `${moment(range.getFromTimeStamp()).format('YYYY-MM-DD')} /${moment(range.getToTimeStamp()).format('YYYY-MM-DD')}`;
    }
    return timeInterval;
  }

  reset(): void {
    this.timeSliderValue = 0;
    this.rangeTimeSliderValue = [-1, 0];
    this.previousRangeTimeSliderValue = [-1, 0];
    this.rangeOn = false;
    this.advancedRangeOn = false;
    this.currentTimeRangeKey = null;
    this.timer = moment().seconds(0);
    this.timeMachineRange = new TimeMachineSliderRange(this.rangeTimeSliderValue);
    this.timeMachineAdvanceRange = new TimeMachineAdvancedRange(this.timeMachineRange.from, this.timeMachineRange.to);
    this.advancedRangeTimePicker = { from: this.timeMachineRange.from, to: this.timeMachineRange.to };
  }

  checkForecast(data: TimeMachineData, offset: number): boolean {
    if (typeof data === 'number') {
      return moment(data).add(offset, 'hours').valueOf() > this.timer.valueOf();
    } else {
      return moment(data.to.valueOf()).add(offset, 'hours').valueOf() > this.timer.valueOf();
    }
  }

  setTimeMachineStatus(enabled: boolean): void {
    if (this.enabled !== enabled) {
      this.enabled = enabled;
    }
  }

  setAutoRefresh(newValue: boolean): void {
    this.autoRefresh = newValue;
  }

  toggleLabel(show: boolean): void {
    this.showLabel = show;
  }

  updateRefresh(newPeriod: number): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
    localStorage.setItem('time_machine_refresh', newPeriod.toString());
    this.minutesRefresh = newPeriod;
    this.timeUpdatePeriod = newPeriod * 1000 * 60;
    this.refreshInterval = interval(this.timeUpdatePeriod);
    this.refreshSubscription = this.refreshInterval.subscribe((seconds: number) => {
      this.handleTimeMachineRefresh();
    });
  }

  handleTimeMachineRefresh(): void {
    if (this.autoRefresh) {
      this.refreshMaxAndMin();
      this.timer = moment().add(this.timeSliderValue, 'hours');
      this.timeMachineRange = new TimeMachineSliderRange(this.rangeTimeSliderValue);
      if (this.timeMachineAdvanceRange) {
        this.setAdvancedRange(this.currentTimeRangeKey);
      }
      this.announceTimeChange(this.getCurrentSelection());
    }
  }

  isTimeMachineActive(): boolean {
    return (this.rangeOn || this.advancedRangeOn || this.timeSliderValue !== 0);
  }

  setAllowFuture(value: boolean): void {
    this.allowFuture = value;
    this.refreshMaxAndMin();
  }

  setAllowPast(value: boolean): void {
    this.allowPast = value;
    this.refreshMaxAndMin();
  }

  refreshMaxAndMin(): void {
    this.advancedRangeTimePickerMaxDate = this.allowFuture ? null : moment(new Date());
    this.advancedRangeTimePickerMinDate = this.allowPast ? null : moment(new Date());
    // TO DO set max and min on sliders
  }

  getToTimeStamp(offset: number): number {
    if (this.rangeOn && !this.advancedRangeOn) {
      return moment(this.timeMachineRange.to.valueOf()).add(offset, 'hours').valueOf();
    } else if (this.rangeOn && this.advancedRangeOn) {
      return moment(this.timeMachineAdvanceRange.to.valueOf()).add(offset, 'hours').valueOf();
    } else {
      return moment(this.timer.valueOf()).add(offset, 'hours').valueOf();
    }
  }

  getCurrentSelection(): TimeMachineData {
    if (this.rangeOn && !this.advancedRangeOn) {
      return this.timeMachineRange;
    } else if (this.rangeOn && this.advancedRangeOn) {
      return this.timeMachineAdvanceRange;
    } else {
      return this.timer.valueOf();
    }
  }

  getCurrentSelectionRange(): TimeMachineRange {
    if (this.rangeOn && !this.advancedRangeOn) {
      return this.timeMachineRange;
    } else if (this.rangeOn && this.advancedRangeOn) {
      return this.timeMachineAdvanceRange;
    }
  }

  getCurrentSelectionTimestamp(): number {
    if (this.rangeOn && !this.advancedRangeOn) {
      return this.timeMachineRange.to.valueOf();
    } else if (this.rangeOn && this.advancedRangeOn) {
      return this.timeMachineAdvanceRange.to.valueOf();
    } else {
      return this.timer.valueOf();
    }
  }

  timestampFromHours(hoursShift: number): Moment {
    const timer: Moment = moment();
    return timer.add(hoursShift as DurationInputArg1, 'h');
  }

  announceTimeChange(newValue: TimeMachineData): void {
    if (this.enabled) {
      this.timeMachineChanged.next(newValue);
    }
  }

  onNowClick(): void {
    const now: Moment = moment();
    if (this.rangeOn && !this.advancedRangeOn) {
      this.setTimeRangeFromMoment(moment(now).subtract(60, 'minutes'), now);
    } else if (this.rangeOn && this.advancedRangeOn) {
      this.setAdvancedRange('LAST_HOUR');
    } else {
      this.setTimeFromTimespan(now.toDate().valueOf());
    }
  }

  setTimeFromTimespan(timespan: number): void {
    // if the timeline is in range mode, switch to single day mode
    if (this.rangeOn) {
      this.rangeOn = false;
      this.advancedRangeOn = false;
      this.currentTimeRangeKey = null;
    }

    const newTime: number = Math.round(moment.duration(moment(timespan).diff(moment())).asHours());
    this.announceTimeChange(this.updateTime(newTime).value.valueOf());
    this.syncTimeAndRangeValue();
  }

  updateTime(newTime: number): TimeMachineUpdateSliderResult {
    if ((!this.allowFuture && this.timestampFromHours(newTime).isAfter(moment(new Date()))) ||
      (!this.allowPast && this.timestampFromHours(newTime).isBefore(moment(new Date())))) {
      this.timeSliderValue = 0;
      return {
        toUpdate: false,
        value: this.timer,
      };
    } else {
      this.timeSliderValue = newTime;
      this.timer = this.timestampFromHours(newTime);
      return {
        toUpdate: true,
        value: this.timer,
      };
    }
  }

  goInAdvancedRangeMode(): void {
    this.rangeOn = true;
    this.showAdvancedRange = true;
    this.advancedRangeOn = true;
  }

  setAdvancedTimeRangeFromMoment(start: Moment, end: Moment): void {
    // if the timeline is in not in advanced range mode, switch to advanced range mode
    this.rangeOn = true;
    this.advancedRangeOn = true;
    this.announceTimeChange(this.updateAdvancedTimeRange({ from: start, to: end }));
    this.syncTimeAndRangeValue();
  }

  updateAdvancedTimeRange(range: SliderRange): TimeMachineAdvancedRange {
    this.advancedRangeTimePicker = range;
    this.timeMachineAdvanceRange = new TimeMachineAdvancedRange(range.from, range.to);
    return this.timeMachineAdvanceRange;
  }

  setAdvancedRange(key: string): void {
    const range: Range = this.rangeManagerService.getRange(key);
    if (range) {
      this.setAdvancedTimeRangeFromMoment(range[0], range[1]);
      this.currentTimeRangeKey = key;
    }
  }

  setTimeRangeFromMoment(start: Moment, end: Moment): void {
    // if the timeline is in range mode, switch to range mode
    this.rangeOn = true;
    this.advancedRangeOn = false;
    this.currentTimeRangeKey = null;
    // TO DO find a way to get TimeMachineSliderRange from moment
    const newRange: NoUiSliderRangeTimestamps = new TimeMachineRange(start, end).getHoursDifference();
    this.rangeTimeSliderValue = newRange;
    const updateResult: TimeMachineUpdateSliderRangeResult = this.updateTimeRange(newRange);
    if (updateResult.toUpdate) {
      this.announceTimeChange(updateResult.value);
      this.syncTimeAndRangeValue();
    }
  }

  updateTimeRange(newRange: NoUiSliderRangeTimestamps): TimeMachineUpdateSliderRangeResult {
    const timeMachineRangeTmp: TimeMachineSliderRange = new TimeMachineSliderRange(newRange);
    if ((!this.allowFuture && timeMachineRangeTmp.getTo().isAfter(moment(new Date()))) ||
      (!this.allowPast && timeMachineRangeTmp.getFrom().isBefore(moment(new Date())))) {
      this.rangeTimeSliderValue = this.previousRangeTimeSliderValue;
      return {
        toUpdate: false,
        value: this.timeMachineRange,
      };
    } else {
      const mustUpdate: boolean = this.previousRangeTimeSliderValue[0] !== this.rangeTimeSliderValue[0] ||
        this.previousRangeTimeSliderValue[1] !== this.rangeTimeSliderValue[1];
      this.previousRangeTimeSliderValue = newRange;
      this.rangeTimeSliderValue = newRange;
      this.timeMachineRange = new TimeMachineSliderRange(newRange);
      this.timer = this.timeMachineRange.getTo();
      return {
        toUpdate: mustUpdate,
        value: this.timeMachineRange,
      };
    }
  }

  updateTimeSliderValue(timeSliderValue: NoUiSliderRangeTimestamps, newValue: number): NoUiSliderRangeTimestamps {
    if (timeSliderValue[0] < newValue) {
      timeSliderValue[1] = newValue;
    } else {
      timeSliderValue[0] = newValue;
    }
    return timeSliderValue;
  }

  checkForecastPast(): void {
    if (this.advancedRangeOn) {
      this.forecast = this.timeMachineAdvanceRange.isForecast();
      this.past = this.timeMachineAdvanceRange.isPast();
    } else if (this.rangeOn) {
      this.forecast = this.timeMachineRange.isForecast();
      this.past = this.timeMachineRange.isPast();
    } else {
      this.forecast = this.timeSliderValue > 0;
      this.past = this.timeSliderValue < 0;
    }
  }

  syncTimeAndRangeValue(): void {
    if (this.rangeOn) {
      this.timeSliderValue = this.rangeTimeSliderValue[1];
      this.timer = !this.advancedRangeOn ? this.timeMachineRange.getTo() : this.timeMachineAdvanceRange.to;
      if (!this.advancedRangeOn) {
        this.timeMachineAdvanceRange = new TimeMachineAdvancedRange(this.timeMachineRange.from, this.timeMachineRange.to);
      }
    } else {
      this.previousRangeTimeSliderValue = this.updateTimeSliderValue(this.rangeTimeSliderValue, this.timeSliderValue);
      this.rangeTimeSliderValue = this.updateTimeSliderValue(this.rangeTimeSliderValue, this.timeSliderValue);
      this.timeMachineRange = new TimeMachineSliderRange(this.rangeTimeSliderValue);
      if (!this.advancedRangeOn) {
        this.timeMachineAdvanceRange = new TimeMachineAdvancedRange(this.timeMachineRange.from, this.timeMachineRange.to);
      }
    }
    this.checkForecastPast();
  }
}
