import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { PedestrianFlowsWidgetService } from '@app/modules/widgets/pedestrian-flows-widget/pedestrian-flows-widget.service';
import { PedestrianFlowsWidgetComponent } from './pedestrian-flows-widget.component';


@NgModule({
  declarations: [PedestrianFlowsWidgetComponent],
  imports: [
    CommonModule,
    MapLegendModule,
    MapWidgetModule,
  ],
  providers: [PedestrianFlowsWidgetService],
  exports: [
    PedestrianFlowsWidgetComponent,
  ],
})
export class PedestrianFlowsWidgetModule {
}
