import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TideViewerComponent } from './tide-viewer.component';


@NgModule({
  declarations: [TideViewerComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ], exports: [TideViewerComponent],
})
export class TideViewerModule {
}
