import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-alerts-sr-widget',
  templateUrl: './alerts-sr-widget.component.html',
  styleUrls: ['./alerts-sr-widget.component.scss']
})
export class AlertsSrWidgetComponent extends WidgetComponent {

  baseImgUrl: string = 'assets/img/home/MicrosoftTeams-image.png';

  events: Array<any> = [
    {
      title: 'Nevicata in arrivo',
      description: 'Prevista tra le 16:00 e le 17:00',
      location: 'Tratto: Vicenza - Padova',
      type: 'brd-info',
      status: {
        info: '',
        type: ''
      }
    },
    {
      title: 'Code per incidente',
      description: '',
      location: 'Tratto: Peschiera - Sommacampagna',
      type: 'brd-danger',
      status: {
        info: '5 km di coda',
        type: 'warning'
      }
    },
    {
      title: 'Nevicata in arrivo',
      description: 'Peschiera - Sommacampagna',
      location: '',
      type: 'brd-info',
      status: {
        info: 'Soccorsi sul posto',
        type: 'warning'
      }
    }

  ];

}
