<app-map-widget class="mindicity-widget bridges-widget"
                [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
                [isEditable]="isEditable"
                [isLoading]="isLoading"
                (removeWidget)="sendRemoveWidget()"
                [sourceStatus]="sourceStatus"
                (sendReloadWidget)="reloadWidget()"
                [timeMachineOn]="timeMachineOn"
                [mapId]="mapId"
                [map]="map"
                [data]="data"
                [noData]="noData"
                (mapReady)="setMapHandlers($event)"
                (resized)="handleResize($event)"
                [showThresholdAlert]="showThresholdAlert"
                [showGlobalThresholdAlert]="showGlobalThresholdAlert"
                [legends]="legends"
                [leafletOptions]="leafletOptions"
                [showLegend]="showLegend"
                [showLegendReduced]="showLegendReduced">
</app-map-widget>
