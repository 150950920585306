import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppOptions } from '@app/shared/models/app-config/app-options';
import { AppConfigService } from '@services/app-config/app-config.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { LoaderService } from '@services/loader/loader.service';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';
import { Subject } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';

@Component({
  selector: 'app-fd-dashboard',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  isMobile: boolean = null;
  isTablet: boolean = null;
  isDesktopDevice: boolean = null;
  isPortrait: boolean = null;
  idleSeconds: number = 0;

  @ViewChild('infoModal', { static: true }) infoModal: NgxSmartModalComponent;
  @ViewChild('idleModal', { static: true }) idleModal: NgxSmartModalComponent;

  title: string;
  maintenance: {
    status: boolean,
    closable: boolean
  } = {
    status: false,
    closable: false,
  };

  isLoading: Subject<boolean> = this.loaderService.isLoading;
  currentTimeMachineData: TimeMachineData;

  constructor(
    public router: Router,
    public authenticationService: AuthenticationService,
    private appConfigService: AppConfigService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    public ngxSmartModalService: NgxSmartModalService,
    public timeMachineService: TimeMachineService,
    public themeService: ThemeService,
    private userIdle: UserIdleService,
    private deviceService: DeviceDetectorService,
  ) {
  }

  checkDevice(): void {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
    this.isPortrait = window.innerHeight > window.innerWidth;
  }

  ngOnInit(): void {
    this.currentTimeMachineData = this.timeMachineService.getCurrentSelection();
    this.title = this.appConfigService.getOptions().title;
    this.maintenance = this.appConfigService.getOptions().maintenance;
    this.translate.addLangs(['it']);
    moment.locale(['it']);
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.use('it');
    this.translate.setDefaultLang('it');
    this.checkDevice();
    this.setIdleService();
    this.timeMachineService.timeMachineChanged.subscribe((newValue: TimeMachineData) => {
      this.currentTimeMachineData = newValue;
    });
    this.loaderService.isMaintenance.subscribe(() => {
      this.ngxSmartModalService.open('infoModal');
    });
  }

  public setIdleService(): void {
    const options: AppOptions = this.appConfigService.getOptions();
    if (options.idleOptions?.enabled) {
      this.userIdle.setConfigValues({
        idle: options.idleOptions.idle,
        timeout: options.idleOptions.timeout,
        ping: options.idleOptions.ping,
      });
      //Start watching for user inactivity.
      this.userIdle.resetTimer();
      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe((count: number) => {
        this.idleSeconds = options.idleOptions.timeout - count;
        if (!this.ngxSmartModalService.get('idleModal').open()) {
          this.ngxSmartModalService.open('idleModal');
        }
      });

      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        this.authenticationService.makeLogout();
      });
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    this.userIdle.stopWatching();
    this.userIdle.resetTimer();
    this.userIdle.startWatching();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    this.checkDevice();
  }

  ngAfterViewInit(): void {
    try {
      this.maintenance = this.appConfigService.getOptions().maintenance;
      if (this.maintenance.status) {
        this.ngxSmartModalService.open('infoModal');
      }
    } catch (e) {
      this.router.navigateByUrl('/error');
    }
  }

}
