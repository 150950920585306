import { Injectable } from '@angular/core';
import { VehicleColor } from '@app/modules/widgets/flows/traffic-widget/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FlowType } from '@app/shared/models/flow-models';
import { FlowData } from '@app/shared/models/flow-models/flow-data';
import { BehavioralFlow } from '@app/modules/widgets/flows/behavioral-statistics-widget/models/behavioral-flow';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  BehaviorStatisticsTableDataSourceItem,
  BehaviorStatisticsTableDataSourceItemRoad,
  BehaviorStatisticsTableDataSourceItemWater,
  VehicleColorType,
} from './models';

@Injectable()
export class BehavioralStatisticsWidgetService extends FlowsService {

  loadData(timeMachineData: TimeMachineData,
           sources: Array<WidgetDataSource>,
           additionalData: FlowData): Observable<Array<BehaviorStatisticsTableDataSourceItem>> {
    return this.getVehicleColors(sources).pipe(switchMap((vc: VehicleColor) => {
      return this.getTableData(timeMachineData, sources, additionalData, vc);
    }));
  }

  getTableData(timeMachineData: TimeMachineData,
               sources: Array<WidgetDataSource>, params: FlowData, vc: VehicleColor): Observable<Array<BehaviorStatisticsTableDataSourceItem>> {

    if (params.type === FlowType.WATER) {

      const tableData: Array<BehaviorStatisticsTableDataSourceItemWater> = [];

      return this.getBehavioralData(timeMachineData, sources, params).pipe(
        switchMap((behavioralFlowData: Array<BehavioralFlow>) => {
          vc.waterVehicleColors.forEach((type: VehicleColorType) => {
            const typeData: Array<BehavioralFlow> = behavioralFlowData.filter((result: BehavioralFlow) => {
              return result.boat.toLowerCase() === type.name.toLowerCase();
            });
            tableData.push({
              type: type.name,
              crossing: this.getSum(behavioralFlowData, 'boat', type.name, 'orthogonal'),
              prohibited_stops: this.getSum(behavioralFlowData, 'boat', type.name, 'stop'),
              reverse: this.getSum(behavioralFlowData, 'boat', type.name, 'reverse'),
              speed_avg: typeData.length ?
                parseFloat((this.getSum(behavioralFlowData, 'boat', type.name, 'speed_avg') / typeData.length).toFixed(1))
                : 0,
              speed_max: typeData.length ? Math.max(...typeData.map((item: BehavioralFlow) => item.speed_max)) : 0,
              wrong_direction: this.getSum(behavioralFlowData, 'boat', type.name, 'direction'),
              color: type.color,
            });
          });

          return of(tableData);
        }),
      );

    } else if (params.type === FlowType.ROAD) {

      const tableData: Array<BehaviorStatisticsTableDataSourceItemRoad> = [];

      const jsonFromServices: any = {
        'data': [
          { type: 'Auto', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Furgone', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Camion', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Autotreno', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Autoarticolato', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Automobili', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Auto con rimorchio', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Moto', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Motociclette', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Camper', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Autocarri', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Autobus', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Furgoni', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
          { type: 'Altro', speed_avg: 0, speed_max: 0, prohibited_stops: 0, failed_precedence: 0 },
        ],
      };

      vc.roadVehicleColors.forEach((type: VehicleColorType) => {
        tableData.push(
          {
            type: type.name,
            prohibited_stops: this.getSum(jsonFromServices.data, 'type', type.name, 'prohibited_stops'),
            speed_avg: parseFloat((this.getSum(jsonFromServices.data, 'type', type.name, 'speed_avg') /
              jsonFromServices.data.filter((result: any) => {
                return result.type.toLowerCase() === type.name.toLowerCase();
              }).length).toFixed(1)),
            speed_max: Math.max(...jsonFromServices.data.filter((result: any) => {
              return result.type.toLowerCase() === type.name.toLowerCase();
            }).map((item: any) => item.speed_max)),
            color: type.color,
          });
      });
      return of(tableData);
    }
  }
}
