<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
           >
  <div class="container">
    <ul class="list-group w-100 mt-2">
      <li *ngFor="let event of events" class="mb-2 text-left">
        <div class="eventStatus mb-2">
          {{event.value}} <span class="badge badge-{{event.type}}">&nbsp;</span> {{event.title}}
        </div>
      </li>
    </ul>
  </div>
</app-widget>
