import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FlowConfiguration } from '@app/modules/widgets/flows/flow-map-widget/data/flow-configuration';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FlowType, FlowZone, FlowZoneGroup } from '@app/shared/models/flow-models';
import { FlowData } from '@app/shared/models/flow-models/flow-data';
import { BehavioralFlow } from '@app/modules/widgets/flows/behavioral-statistics-widget/models/behavioral-flow';
import { FlowControlWidgetService } from '@app/modules/widgets/flows/flow-control-widget/flow-control-widget.service';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { TrafficFlow } from '@app/modules/widgets/flows/traffic-widget/models/traffic-flow';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-flow-control-widget',
  templateUrl: './flow-control-widget.component.html',
  styleUrls: ['./flow-control-widget.component.scss'],
})
export class FlowControlWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  zones: Array<FlowZone>;
  groups: Array<FlowZoneGroup>;

  selectedZones: Array<FlowZone> = [];
  selectedGroups: Array<FlowZoneGroup> = [];

  flowData: Array<TrafficFlow>;
  behavioralData: Array<BehavioralFlow>;
  typePickSubscription: Subscription;
  cameraSelectedSubscription: Subscription;
  cameraActivatedSubscription: Subscription;

  allSelected: boolean;
  allFlows: number;
  filter: string;

  configurations: Array<FlowConfiguration>;

  constructor(public widgetService: FlowControlWidgetService,
              public flowService: FlowsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.allSelected = true;
    super.ngOnInit();
    this.typePickSubscription = this.flowService.typePicked.subscribe((type: string) => {
      this.filter = type;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
    this.flowService.groupsSelected.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
    this.flowService.groupsUpdated.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.groups = [];
    this.flowData = [];
    this.behavioralData = [];
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = forkJoin([this.flowService.loadGroups(timeMachineData, this.sources), this.flowService.getFlowConfiguration(this.data.type)]).pipe(
      switchMap((loadingData: [Array<FlowZoneGroup>, Array<FlowConfiguration>]) => {
        const groups: Array<FlowZoneGroup> = loadingData[0];
        this.configurations = loadingData[1];
        this.isLoading = true;
        const flowData: FlowData = {
          zoneIds: this.widgetService.getZonesFromGroups(groups),
          groupIds: [],
          type: this.data.type as FlowType,
        };
        return forkJoin([of(groups), this.widgetService.getTrafficData(timeMachineData, this.sources, flowData),
          this.widgetService.getBehavioralData(timeMachineData, this.sources, flowData)]);
      })).subscribe((data: Array<any>) => {
      this.noData = false;
      this.groups = data[0];
      this.flowData = data[1];
      this.behavioralData = data[2];
      this.refreshWidget(timeMachineData);
    }, (e: any) => {
      this.noData = true;
      this.isLoading = false;
      throw new Error(e);
    });
  }

  refreshWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.groups = this.widgetService.elaborateFlow(timeMachineData, this.groups, this.flowData, this.filter, this.configurations);
    this.groups = this.widgetService.elaborateBehavior(timeMachineData, this.groups, this.behavioralData, this.filter);
    this.zones = [];
    this.groups.forEach((group: FlowZoneGroup) => {
      this.zones = this.zones.concat(group.zones);
    });
    let totalVehicles: number = 0;
    this.zones.forEach((z: FlowZone) => {
      z.color = this.widgetService.getFlow(z.vehiclesPerMinute, this.configurations).color;
      totalVehicles = z.totalVehicles + totalVehicles;
    });
    this.zones.sort((a: FlowZone, b: FlowZone) => {
      return a.name > b.name ? 1 : -1;
    });
    const totalVehiclesPerMinute: number = this.widgetService.getVehiclesPerTenMinutes(timeMachineData, totalVehicles);
    this.allFlows = this.widgetService.getFlow(totalVehiclesPerMinute, this.configurations).id;
    this.updateZones();
    this.checkAllGroups();
    this.isLoading = false;
  }

  getActiveZones(): Array<FlowZone> {
    return this.zones.filter((z: FlowZone) => {
      return z.active === true;
    });
  }

  getActiveGroups(): Array<FlowZoneGroup> {
    return this.groups.filter((g: FlowZoneGroup) => {
      return g.active === true;
    });
  }

  updateGroups(): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.active = g.active;
      });
    });
    this.selectedGroups = this.groups.filter((g: FlowZoneGroup) => {
      return g.active;
    });
    this.updateZones();
  }

  updateZones(): void {
    this.selectedZones = this.zones.filter((z: FlowZone) => {
      return z.active;
    });
    this.checkGroups();
  }

  checkGroups(): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      if (g.active) {
        g.zones.forEach((z: FlowZone) => {
          if (!this.selectedZones.includes(z)) {
            g.active = false;
          }
        });
      } else {
        let active: boolean = true;
        g.zones.forEach((z: FlowZone) => {
          if (!this.selectedZones.includes(z)) {
            active = false;
          }
        });
        g.active = active;
      }
    });
  }

  selectZone(event: MouseEvent, zone: FlowZone): void {
    this.zones.forEach((z: FlowZone) => {
      z.selected = false;
    });
    zone.selected = true;
    this.groups = this.flowService.updateGroups(this.zones);
    this.flowService.groupsSelected.emit(this.groups);
  }

  deselectAllZones(): void {
    this.zones.forEach((z: FlowZone) => {
      z.selected = false;
    });
    this.groups.forEach((g: FlowZoneGroup) => {
      g.selected = false;
    });
    this.groups = this.flowService.updateGroups(this.zones);
    this.flowService.groupsSelected.emit(this.groups);
  }

  activateZone(event: MouseEvent, zone: FlowZone): void {
    if (zone.active && this.getActiveZones().length <= 1) {
      this.selectAllGroups();
    } else {
      if (zone.active && !event.ctrlKey) {
        zone.active = false;
      }
      const activeValue: boolean = zone.active;
      if (activeValue) {
        this.allSelected = false;
      }
      if (!event.ctrlKey) {
        this.allSelected = false;
        this.zones.forEach((z: FlowZone) => {
          z.active = false;
        });
      }
      zone.active = !activeValue;
      this.updateZones();
      this.groups = this.flowService.updateGroups(this.zones);
      this.flowService.groupsUpdated.emit(this.groups);
      this.flowService.zoneClick.emit(zone);
    }
  }

  activateGroup(event: MouseEvent, group: FlowZoneGroup): void {
    if (group.active && this.getActiveGroups().length <= 1) {
      this.selectAllGroups();
    } else {
      if (group.active && !event.ctrlKey) {
        group.active = false;
      }
      const activeValue: boolean = group.active;
      if (!event.ctrlKey) {
        this.allSelected = false;
        this.groups.forEach((g: FlowZoneGroup) => {
          g.active = false;
        });
      }
      group.active = !activeValue;
      this.updateGroups();
      this.flowService.setGroups(this.groups);
      this.groups = this.flowService.updateGroups(this.zones);
      this.flowService.groupsUpdated.emit(this.groups);
    }
  }

  selectGroup(event: MouseEvent, group: FlowZoneGroup): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.selected = false;
    });
    group.selected = true;
    this.zones.forEach((z: FlowZone) => {
      z.selected = group.zones.includes(z);
    });
    this.flowService.setGroups(this.groups);
    this.groups = this.flowService.updateGroups(this.zones);
    this.flowService.groupsSelected.emit(this.groups);
  }

  selectAllGroups(): void {
    this.allSelected = true;
    this.zones.forEach((z: FlowZone) => {
      z.active = true;
    });
    this.updateZones();
    this.groups = this.flowService.updateGroups(this.zones);
    this.flowService.groupsUpdated.emit(this.groups);
  }

  checkAllGroups(): void {
    this.allSelected = true;
    this.groups.forEach((g: FlowZoneGroup) => {
      if (!g.active) {
        this.allSelected = false;
      }
      g.zones.forEach((z: FlowZone) => {
        if (!z.active) {
          this.allSelected = false;
        }
      });
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.typePickSubscription) {
      this.typePickSubscription.unsubscribe();
    }
    if (this.cameraSelectedSubscription) {
      this.cameraSelectedSubscription.unsubscribe();
    }
    if (this.cameraActivatedSubscription) {
      this.cameraActivatedSubscription.unsubscribe();
    }
  }

}
