import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FlowType } from '@app/shared/models/flow-models';
import { FlowData } from '@app/shared/models/flow-models/flow-data';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { TrafficFlow, TrafficFlowData } from '@app/modules/widgets/flows/traffic-widget/models/traffic-flow';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  TrafficTableDataSourceItem,
  TrafficTableDataSourceItemRoad,
  TrafficTableDataSourceItemWater, VehicleColor,
  VehicleColorType,
} from './models';


@Injectable()
export class TrafficService extends FlowsService {

  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>,
                  additionalData: FlowData): Observable<TrafficFlowData> {
    return forkJoin([this.getVehicleColors(sources), this.getTrafficData(timeMachineData, sources, additionalData)])
      .pipe(switchMap((data: [VehicleColor, Array<TrafficFlow>]) => {
        return of({
          trafficFlow: data[1],
          vehicleColors: data[0],
        });
      }));
  }

  getTableData(timeMachineData: TimeMachineData,
               sources: Array<WidgetDataSource>, params: FlowData, trafficFlowData: Array<TrafficFlow>, vc: VehicleColor): Array<TrafficTableDataSourceItem> {

    const response: Array<TrafficTableDataSourceItem> = [];

    if (params.type === FlowType.WATER) {

      const tableData: Array<TrafficTableDataSourceItemWater> = [];

      vc.waterVehicleColors.forEach((type: VehicleColorType) => {
        const total: number = this.getSum(trafficFlowData, 'boat', type.name, 'N') +
          this.getSum(trafficFlowData, 'boat', type.name, 'S') +
          this.getSum(trafficFlowData, 'boat', type.name, 'W') +
          this.getSum(trafficFlowData, 'boat', type.name, 'E');
        tableData.push({
          type: type.name,
          total: total,
          N: this.getSum(trafficFlowData, 'boat', type.name, 'N'),
          S: this.getSum(trafficFlowData, 'boat', type.name, 'S'),
          W: this.getSum(trafficFlowData, 'boat', type.name, 'W'),
          E: this.getSum(trafficFlowData, 'boat', type.name, 'E'),
          color: type.color,
        });
      });

      return tableData;

    } else if (params.type === FlowType.ROAD) {

      const tableData: Array<TrafficTableDataSourceItemRoad> = [];


      vc.roadVehicleColors.forEach((type: VehicleColorType) => {
        const total: number = this.getSum(trafficFlowData, 'vehicle', type.name, 'N') +
          this.getSum(trafficFlowData, 'vehicle', type.name, 'S') +
          this.getSum(trafficFlowData, 'vehicle', type.name, 'W') +
          this.getSum(trafficFlowData, 'vehicle', type.name, 'E');
        tableData.push({
          type: type.label,
          total: total,
          N: this.getSum(trafficFlowData, 'vehicle', type.name, 'N'),
          S: this.getSum(trafficFlowData, 'vehicle', type.name, 'S'),
          W: this.getSum(trafficFlowData, 'vehicle', type.name, 'W'),
          E: this.getSum(trafficFlowData, 'vehicle', type.name, 'E'),
          color: type.color,
          fontColor: type.fontColor,
          font: type.font
        });
      });
      return tableData;
    }

    return response;
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const params: FlowData = {
      zoneIds: ['SC1'],
      groupIds: [],
      type: FlowType.WATER,
    };
    const timeStampUrl: string = this.addTimeMachineDataToUrl(new Date().getTime(), source, 1);
    const zones: string = this.getUrlZones(params);
    const groups: string = this.getUrlGroups(params);
    const zoneUrl: string = timeStampUrl.replace('{{zones}}', zones);
    const groupUrl: string = zoneUrl.replace('{{groups}}', groups);
    return this.http.get<any>(groupUrl);
  }
}
