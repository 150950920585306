import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { BehavioralStatisticsWidgetComponent } from './behavioral-statistics-widget.component';
import { BehavioralStatisticsWidgetService } from './behavioral-statistics-widget.service';

@NgModule({
  declarations: [BehavioralStatisticsWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MatTableModule,
    MatSortModule,
    TranslateModule
  ],
  providers: [BehavioralStatisticsWidgetService],
  exports: [BehavioralStatisticsWidgetComponent]
})
export class BehavioralStatisticsWidgetModule { }
