import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    FlowsService,
    { provide: 'flow_service', useClass: FlowsService }
  ],
})
export class FlowsModule {
}
