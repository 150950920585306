import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArcgisWidgetService } from '@app/modules/widgets/arcgis-widget/arcgis-widget.service';

@Component({
  selector: 'app-hotspot-control',
  templateUrl: './hotspot-control.component.html',
  styleUrls: ['./hotspot-control.component.scss'],
})
export class HotSpotControlComponent implements OnInit {

  arcgisService: ArcgisWidgetService;

  @Output()
  filterChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  filterValue: number;

  ngOnInit(): void {
    this.filterChanged.emit(this.filterValue);
  }

  radioChange(newValue: number): void {
    this.filterChanged.emit(newValue);
  }
}
