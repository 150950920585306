import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CesiumMapRoutingModule } from '@app/features/pages/cesium-map/cesium-map-routing.module';
import { MapPageModule } from '@app/features/pages/map-page/map-page.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularCesiumModule } from 'angular-cesium';
import { CesiumMapComponent } from './cesium-map.component';

@NgModule({
  declarations: [CesiumMapComponent],
  imports: [
    CommonModule,
    AngularCesiumModule.forRoot(),
    CesiumMapRoutingModule,
    SharedModule,
    MapPageModule,
    TranslateModule
  ],
})
export class CesiumMapModule {
}
