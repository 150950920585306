import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const PEDESTRIAN_FLOW_LEGEND: MapLegend = {
  title: 'PEDESTRIAN_FLOW_TITLE',
  description: 'PEDESTRIAN_FLOW_DESCRIPTION',
  type: 'PEDESTRIAN_FLOW_WIDGET',
  elements: [{
    color: '#96bdc6',
    label: 'Numero di persone in entrata',
  }, {
    color: '#cfb9a5',
    label: 'Numero di persone in uscita',
  }, {
    color: '#f7931e',
    label: 'Non ci sono persone in transito',
  }, {
    color: '#999999',
    label: 'Gruppo di sensori non funzionante',
  }, {
    label: 'Sensore perimetrale nel gruppo',
    color: '#96bdc6',
    border: {
      color: '#0868b4',
      width: '.2rem',
      style: 'solid',
    },
  }, {
    label: 'Sensore non funzionante nel gruppo',
    color: '#96bdc6',
    border: {
      color: 'yellow',
      width: '.2rem',
      style: 'solid',
    },
  }],
  additionalData: null,
};

export const PEDESTRIAN_FLOW_SENSOR_LEGEND: MapLegend = {
  title: 'PEDESTRIAN_SENSOR_FLOW_TITLE',
  description: 'PEDESTRIAN_SENSOR_FLOW_DESCRIPTION',
  type: 'PEDESTRIAN_FLOW_WIDGET',
  elements: [{
    color: '#f7931e',
    label: 'Gruppo di sensori funzionante',
  }, {
    color: '#999999',
    label: 'Gruppo di sensori non funzionante',
  }, {
    label: 'Sensore perimetrale nel gruppo',
    color: '#f7931e',
    border: {
      color: '#0868b4',
      width: '.2rem',
      style: 'solid',
    },
  }, {
    label: 'Sensore non funzionante nel gruppo',
    color: '#f7931e',
    border: {
      color: 'yellow',
      width: '.2rem',
      style: 'solid',
    },
  }],
  additionalData: null,
};
