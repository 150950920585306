import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss'],
})
export class EventsWidgetComponent extends WidgetComponent {

  baseImgUrl: string = 'assets/img/home/MicrosoftTeams-image.png';

  events: Array<any> = [
    {
      title: 'Veicolo in avaria',
      province: 'PD',
      km: '201 I',
      location: 'Peschiera - Sommacampagna',
      hour: '15:29',
      type: 'brd-warning',
      status: {
        info: 'Soccorsi in arrivo',
        type: 'success',
      },
    },
    {
      title: 'Incidente',
      province: 'BS',
      km: '143 VII',
      location: 'Peschiera - Sommacampagna',
      hour: '15:05',
      type: 'brd-danger',
      status: {
        info: 'Soccorsi sul posto',
        type: 'warning',
      },
    },
    {
      title: 'Code per incidente',
      province: 'BS',
      km: '156 II',
      location: 'Peschiera - Sommacampagna',
      hour: '15:05',
      type: 'brd-danger',
      status: {
        info: '5km di coda',
        type: 'danger',
      },
    },

    {
      title: 'Materiale in carreggiata',
      province: 'PD',
      km: '93 VII',
      location: 'Brescia - Rezzato',
      hour: '15:30',
      type: 'brd-danger',
      status: {
        info: 'Soccorsi da assegnare',
        type: 'danger',
      },
    },
    {
      title: 'Veicolo Fermo',
      province: 'PD',
      km: '143 VII',
      location: 'Vicenza Est - Grisgnano',
      hour: '15:30',
      type: 'brd-info',
      status: {
        info: 'Soccorsi in arrivo',
        type: 'warning',
      },
    },

    {
      title: 'Veicolo Fermo',
      province: 'PD',
      km: '143 VII',
      location: 'Casello Grisgnano',
      hour: '15:09',
      type: 'brd-info',
      status: {
        info: 'Soccorsi sul posto',
        type: 'success',
      },
    },
    {
      title: 'Materiale in carreggiata',
      province: 'PD',
      km: '12 VI',
      location: 'Brescia - Rezzato',
      hour: '14:30',
      type: 'brd-warning',
      status: {
        info: 'Soccorsi da assegnare',
        type: 'warning',
      },
    },
  ];
}
