import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardItem } from '@app/shared/models/dashboard/dashboard-item';

@Component({
  selector: 'app-remove-widget-modal',
  templateUrl: './remove-widget-modal.component.html',
  styleUrls: ['./remove-widget-modal.component.scss'],
})
export class RemoveWidgetModalComponent {


  constructor(public dialogRef: MatDialogRef<RemoveWidgetModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DashboardItem) {
  }
}
