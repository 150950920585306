import { Component } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-caselli-widget',
  templateUrl: './caselli-widget.component.html',
  styleUrls: ['./caselli-widget.component.scss']
})
export class CaselliWidgetComponent extends WidgetComponent {

  events: Array<any> = [
    {
      title: 'Vicenza Ovest',
      status: {
        info: '15 minuti di attesa',
        type: 'danger'
      }
    },
    {
      title: 'Peschiera del Garda',
      status: {
        info: '2 minuti di attesa',
        type: 'warning'
      }
    },
    {
      title: 'Sirmione',
      status: {
        info: 'traffico in entrata',
        type: 'warning'
      }
    },
    {
      title: 'Verona Sud',
      status: {
        info: 'ok',
        type: 'success'
      }
    }
  ];

}
