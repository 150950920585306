import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '@services/storage/storage.service';

@Component({
  selector: 'app-load-modal',
  templateUrl: './load-modal.component.html',
  styleUrls: ['./load-modal.component.scss'],
})
export class LoadModalComponent {

  dashboardSelected: Array<string>;
  dashboardItems: Array<string>;
  result: string = null;

  constructor(public dialogRef: MatDialogRef<LoadModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private storageService: StorageService) {
    this.dashboardItems = data.availableDashboards;
  }

  updateForm(selected: string): void {
    this.result = selected[0];
  }

}
