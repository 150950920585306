<form class="registry-search search justify-content-between align-items-stretch" name="map-search-form"
  (ngSubmit)="submit()">
  <div class="d-flex flex-column map-search-box mb-4">
    <div class="d-flex flex-row justify-content-between align-items-center pl-3 pt-3 pr-3 pb-1">
      <mat-label>
        <h6>{{'PAGES.MAP.SEARCH.SEARCH_FOR_1' | translate}}</h6>
      </mat-label>
      <mat-radio-group class="types" aria-label="Select an option" (ngModelChange)="updateSearchType($event)"
        [(ngModel)]="searchOption" name="type">
        <mat-radio-button [value]="0" class="pr-3" [checked]="true">{{'PAGES.MAP.SEARCH.ALL' | translate}}
        </mat-radio-button>
        <mat-radio-button [value]="1" class="pr-3">{{'PAGES.MAP.SEARCH.SESTIERE' | translate}}</mat-radio-button>
        <mat-radio-button [value]="2">{{'PAGES.MAP.SEARCH.ROAD' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="d-flex flex-row justify-content-between">
      <mat-form-field class="search__input">
        <mat-label>{{'PAGES.MAP.SEARCH.PLACEHOLDER' | translate}}</mat-label>
        <input matInput name="search-value" [placeholder]="'PAGES.MAP.SEARCH.PLACEHOLDER' | translate"
          [(ngModel)]="searchValue" (ngModelChange)="updateSearchOption($event)" [matAutocomplete]="auto"
          [formControl]="registrySearchControl" />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onAutocompileSelect()">
          <mat-option *ngFor="let option of options" [value]="option.roadAddress">
            <b>{{'PAGES.MAP.SEARCH.ROAD' | translate}}:</b> {{option.roadAddress | titlecase }}<br />
            <b>{{'PAGES.MAP.SEARCH.SESTIERE' | translate}}:</b> {{option.sestiereAddress | titlecase }}
          </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matSuffix name="submit" [type]="'button'" (click)="clearSearch()"
          [attr.aria-label]="'Clear'" [hidden]="!searchValue">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-form-field>
      <button mat-button [type]="'submit'">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>
  <div class="d-flex flex-row map-search-box align-items-stretch justify-content-between p-3" *ngIf="showActiveLayers">
    <div ngbDropdown class="d-inline-block active-levels" [autoClose]="'outside'">
      <button mat-button class="active-levels__selector" id="activeLevelsDropdown" ngbDropdownToggle>
        {{'PAGES.MAP.SUBMENU.ACTIVE_LAYERS' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="activeLevelsDropdown">
        <ng-container *ngFor="let item of data">
          <div ngbDropdownItem href="#" class="selected-item d-flex flex-row justify-content-between">
            <div class="d-flex justify-content-between selected-item__label">
              <span class="item">
                <!-- <em [ngStyle]="item.active && {'color': item.color}"
                                     class="icon icon-venice_{{item.icon}}"></em> -->
                <em *ngIf="!item.icon.includes('fa-')" [ngStyle]="item.active && {'color': item.color}"
                  class="icon icon-venice_{{item.icon}}"></em>
                <em *ngIf="item.icon.includes('fa-')" [ngStyle]="item.active && {'background-color': item.color}"
                  class="icon smallIcon fa {{item.icon}}"></em>
                <span class="label">{{item.panelTitle | translate}}</span>
              </span>
              <span class="cancel cursor-pointer" (click)="clearMap(item)">×</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <button class="pr-0" mat-button [type]="'button'" name="clearlayers" (click)="clearMap(null)">
      <mat-icon>layers_clear</mat-icon>
    </button>
  </div>
</form>
