import { AfterViewInit, Component, DoCheck, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { PUBLIC_TRANSPORT_LEGEND } from '@app/modules/widgets/public-transport-widget/mock/legend';
import { Transports } from '@app/modules/widgets/public-transport-widget/models';
import { PublicTransportDataSource } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-data-source';
import { PublicTransportWidgetData } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-widget-data';
import { PublicTransportService } from '@app/modules/widgets/public-transport-widget/public-transport.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { FeatureGroup, Layer, LeafletEvent } from 'leaflet';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-public-transport-widget',
  templateUrl: './public-transport-widget.component.html',
})
export class PublicTransportWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {

  sources: Array<PublicTransportDataSource>;
  legends: Array<MapLegend> = [PUBLIC_TRANSPORT_LEGEND];
  animation: boolean;

  @Input()
  data: PublicTransportWidgetData;

  constructor(public widgetService: PublicTransportService,
              private elementRef: ElementRef, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.animation = this.data.animation;
    super.ngOnInit();

    this.zoomEnd.subscribe((e: LeafletEvent) => {
      if (this.animation) {
        this.widgetService.setDuration(this.sources, this.animation, this.elementRef);
      }
    });

    this.zoomStart.subscribe((e: LeafletEvent) => {
      if (this.animation) {
        this.elementRef.nativeElement.style.setProperty('--railDuration', '1ms');
        this.elementRef.nativeElement.style.setProperty('--roadDuration', '1ms');
        this.elementRef.nativeElement.style.setProperty('--waterDuration', '1ms');
      }
    });
    document.removeEventListener('visibilitychange', this.browserHidden);
    document.addEventListener('visibilitychange', () => {
      this.browserHidden();
    });
  }

  checkData(): boolean {
    try {
      let validRail: boolean = false;
      let validRoad: boolean = false;
      let validWater: boolean = false;
      this.map.eachLayer((l: Layer) => {
        if (l['type'] === Transports.RAIL) {
          validRail = true;
        }
        if (l['type'] === Transports.ROAD) {
          validRoad = true;
        }
        if (l['type'] === Transports.WATER) {
          validWater = true;
        }
      });
      if (!(validRail && validRoad && validWater)) {
        this.sources.find((w: PublicTransportDataSource) => {
          return w.name === 'public-transports-vehicles';
        }).status = DataSourceStatus.WARNING;
        // Re-assign sources to trigger update
        this.sources = JSON.parse(JSON.stringify(this.sources));
        this.sourceStatus = DataSourceStatus.WARNING;
      } else {
        this.sourceStatus = DataSourceStatus.AVAILABLE;
      }
      return validRail || validRoad || validWater;
    } catch (e) {
      return false;
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.setDuration(this.sources, this.animation, this.elementRef);
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.map.eachLayer((l: Layer) => {
      if (l['type'] === Transports.RAIL || l['type'] === Transports.ROAD || l['type'] === Transports.WATER) {
        this.map.removeLayer(l);
      }
    });
    this.dataSubscription = combineLatest(this.data.activeLines.map((t: Transports) => {
      return this.widgetService.getMapLevel(timeMachineData, this.sources, t);
    })).subscribe((data: Array<FeatureGroup>) => {
      data.forEach((layer: FeatureGroup) => {
        layer.eachLayer((f: Layer) => {
          this.widgetService.updateMapMarker(this.map, f);
        });
      });
      this.noData = !this.checkData();
      this.isLoading = false;
    }, (error: any) => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.timeMachineSubscription) {
      this.timeMachineSubscription.unsubscribe();
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  browserHidden(): void {
    if (!document.hidden) {
      this.widgetService.stopAnimation = false;
      this.reloadWidget();
    } else {
      this.widgetService.stopAnimation = true;
    }
  }
}
