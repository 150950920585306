import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const TIDE_MAP_LEGEND: MapLegend = {
  title: 'TIDE_MAP_TITLE',
  description: 'TIDE_MAP_TITLE_DESCRIPTION',
  type: 'TIDE_MAP_WIDGET',
  elements: [{
    color: '#20ffff',
    label: 't = 10',
  }, {
    color: '#00a3ff',
    label: 't = 30',
  }, {
    color: '#0054ff',
    label: 't = 50',
  }, {
    color: '#000aff',
    label: 't = 80',
  }, {
    color: '#0000ad',
    label: 't = 100',
  }],
  additionalData: null,
};
