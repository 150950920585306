import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { AngularJsonSchemaForm } from '@app/shared/models/angular-json-schema-form';
import { Thresholds } from '@app/modules/widgets/temperature-widget/model';
import { Temperature } from '@app/modules/widgets/temperature-widget/model/temperature';
import { TemperatureWidgetService } from '@app/modules/widgets/temperature-widget/temperature-widget.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-temperature-widget',
  templateUrl: './temperature-widget.component.html',
  styleUrls: ['./temperature-widget.component.scss'],
})

export class TemperatureWidgetComponent extends WidgetComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  data: any;

  index: number;

  label: string;
  widgetWidth: number;
  innerWidth: number;
  fontSize: string;
  temperatures: Array<Temperature>;
  humidity: Array<Temperature>;
  lower: number;
  higher: number;
  schema: AngularJsonSchemaForm;
  thresholds: Thresholds;
  showData: boolean;
  updateOn: string;

  constructor(public widgetService: TemperatureWidgetService, public injector: Injector) {
    super(widgetService, injector);
    this.thresholds = {
      low_temperature: 5,
      high_temperature: 20,
    };
  }

  checkData(): boolean {
    try {
      return this.temperatures.length > 0;
    } catch {
      return false;
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    const schemaObservable: Observable<AngularJsonSchemaForm> = this.widgetService.getDataSchema(this.name);
    const temperaturesObservable: Observable<Array<Temperature>> = this.widgetService.loadData(timeMachineData, this.sources, 'temperature');
    const humidityObservable: Observable<Array<Temperature>> = this.widgetService.loadData(timeMachineData, this.sources, 'humidity');
    this.dataSubscription = forkJoin([schemaObservable, temperaturesObservable, humidityObservable]).subscribe((data: Array<any>) => {
      this.schema = data[0];
      this.temperatures = data[1];
      this.humidity = data[2];
      if (this.schema) {
        this.thresholds = Object.assign(this.thresholds, this.schema.data as Thresholds);
      }
      this.noData = !this.checkData();
      const stations: Array<string> = ['01026', '01039', '01021'];
      this.temperatures = this.temperatures.filter((x: Temperature) => {
        return stations.indexOf(x.stationId) !== -1;
      });
      this.humidity = this.humidity.filter((x: Temperature) => {
        return stations.indexOf(x.stationId) !== -1;
      });
      if (this.humidity.length > 0) {
        this.temperatures.forEach((t: Temperature) => {
          const hum: Array<Temperature> = this.humidity.filter((x: Temperature) => {
            return x.stationId === t.stationId;
          });
          t.humidity = hum[0].value;
          t.humidityTrend = hum[0].trend;
        });
      }
      this.showData = this.getShowDate();
      if (this.temperatures.length) {
        this.updateOn = this.temperatures[0].updateOn;
      }
      this.isLoading = false;
    }, (error: any) => {
      console.error(error);
      this.isLoading = false;
      this.noData = true;
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.innerWidth = window.innerWidth;
  }

  handleResize(resize: ResizedEvent): void {
    this.widgetWidth = resize.newWidth;

    const minSize: number = Math.min(resize.newWidth, resize.newHeight);
    this.fontSize = (minSize / 11).toString();
  }

  getCircleColor(value: string): string {
    return this.widgetService.getColorFill(Number(value), this.thresholds);
  }

  getShowArrow(value: number, up: boolean): boolean {
    let retValue: boolean = true;

    if (value) {
      if (up) {
        retValue = (value < 0);
      } else {
        retValue = (value > 0);
      }
    }

    return retValue;
  }

  getShowSpace(value: number): boolean {
    let retValue: boolean;

    retValue = !(value === undefined || value === 0);

    return retValue;
  }

  getShowDate(): boolean {
    let time: string = '';
    let i: number;
    let show: boolean;

    for (i = 0; i < this.temperatures.length; i++) {
      if (i > 0) {
        show = this.temperatures[i].updateOn !== time;
      } else {
        time = this.temperatures[i].updateOn;
      }
    }
    return show;
  }

  isLastElement(el: boolean): boolean {
    if (el) {
      return false;
    } else {
      return !this.showData;
    }
  }
}
