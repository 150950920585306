import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BigMapWidgetService } from '@app/modules/widgets/big-map-widget/big-map-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { MapWidgetModule } from '../map-widget/map-widget.module';
import { BigMapWidgetComponent } from './big-map-widget.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [BigMapWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MapWidgetModule,
    TranslateModule,
    LeafletModule,
    LeafletDrawModule,
  ],
  providers: [BigMapWidgetService],
  exports: [BigMapWidgetComponent],
})
export class BigMapWidgetModule {
}
