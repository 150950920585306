import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable, } from 'rxjs';

@Injectable()
export class RoadArrivalsService extends WidgetService {

  public loadData(timestamp: TimeMachineData, sources: Array<WidgetDataSource>): Observable<RoadArrivalsResponse> {
    const presencesSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'od-presences-arrivals';
    });
    if (presencesSource) {
      const presencesSourceUrl: string = this.addTimeMachineDataToUrl(timestamp, presencesSource);
      return this.http.get<RoadArrivalsResponse>(presencesSourceUrl);
    }
  }
}

export interface Arrivals {
  dateFrom: Date;
  dateTo: Date;
  total: number;
  outRegion: string;
}

export interface Presence {
  timestamp: Date;
  clusterName: string;
  total: number;
}

export interface RoadArrivalsResponse {
  datetime: Date;
  arrivals: Arrivals;
  presences: Array<Presence>;
}
