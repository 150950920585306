<div class="tide-control p-3">
  <h6>{{'WIDGETS.TIDE_MAP.HEIGHT' | translate }}</h6>
  <div class="control__slider d-flex flex-row justify-content-between align-items-center">
    <mat-slider class="flex-grow-1" [min]="-100" [max]="200" [step]="1" [(ngModel)]="sliderValue" [tickInterval]="20"
                (input)="updateValue($event)"
                (change)="setNewValue($event)"></mat-slider>
    <div class="control__slider__value flex-grow-0 pl-1">{{sliderValue | number:'1.0-0'}} cm</div>
    <div class="control__slider__spinner pl-4 pr-3 flex-shrink-0">
      <mat-spinner [hidden]="!loading" [diameter]="30"></mat-spinner>
    </div>
  </div>
  <div class="control__button d-flex flex-row justify-content-end align-items-center">
    <button [ngClass]="{'button--future': timeMachineService.forecast,
    'button--past': timeMachineService.past}" mat-raised-button
            (click)="setActualTide()">{{'WIDGETS.TIDE_MAP.ACTUAL_TIDE' | translate}}</button>
  </div>
</div>
