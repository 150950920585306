import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { WidgetType } from '@app/shared/enums/widget-type';

const normalParkingIcon: string = 'icon-parking_occasional';

export const PARKING_LEGEND: MapLegend = {
  title: 'PARKING_TITLE',
  type: WidgetType.PARKING_WIDGET,
  additionalData: 'parkings',
  elements: [{
    label: 'Libero',
    color: 'green',
    icon: {
      iconName: normalParkingIcon,
      color: 'white',
      backgroundColor: 'green',
      round: true,
    },
  }, {
    label: 'Occupato',
    color: 'red',
    icon: {
      iconName: normalParkingIcon,
      color: 'white',
      backgroundColor: 'red',
      round: true,
    },
  }, {
    label: 'Non raggiungibile',
    color: 'blue',
    icon: {
      iconName: normalParkingIcon,
      color: 'white',
      backgroundColor: 'blue',
      round: true,
    },
  }, {
    label: 'Dati non disponibili',
    color: 'grey',
    icon: {
      iconName: normalParkingIcon,
      color: '#ededed',
      backgroundColor: 'grey',
      round: true,
    },
    separatorAfter: true,
  }, {
    label: 'Parcheggio occasionale',
    color: 'blue',
    icon: {
      iconName: normalParkingIcon,
      color: 'white',
      backgroundColor: 'blue',
      round: true,
    },
  }, {
    label: 'Parcheggio con pass',
    color: 'blue',
    icon: {
      iconName: 'icon-parking_pass',
      color: 'white',
      backgroundColor: 'blue',
      round: true,
    },
  }, {
    label: 'Parcheggio disabili',
    color: 'blue',
    icon: {
      iconName: 'icon-parking_disabled',
      color: 'white',
      backgroundColor: 'blue',
      round: true,
    },
    separatorAfter: true,
  }, {
    label: 'Violazione rilevata',
    color: 'red',
    icon: {
      iconName: normalParkingIcon,
      color: 'white',
      backgroundColor: 'red',
      round: true,
      border: {
        color: 'yellow',
        width: '.2rem',
        style: 'solid',
      },
    },
  },
  ],
};
