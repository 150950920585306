import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RangeManagerService, Ranges } from '@services/range-manager/range-manager.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { Moment } from 'moment';
import { NouisliderComponent } from 'ng2-nouislider';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import {
  MyFormatter,
  TimeMachineAdvancedRange,
  TimeMachineSliderRange,
  TimeMachineUpdateSliderRangeResult,
  TimeMachineUpdateSliderResult,
} from './models/classes';

@Component({
  selector: 'app-time-machine',
  templateUrl: './time-machine.component.html',
  styleUrls: ['./time-machine.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimeMachineComponent implements OnInit {
  private SLIDER_TIME_FORMAT: string = 'DD.MM.YY | HH:mm';

  @ViewChild('slider', { static: true })
  public slider: NouisliderComponent;

  @ViewChild('rangeSlider', { static: true })
  public rangeSlider: NouisliderComponent;

  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;


  public selectedTime: string;
  public now: Moment;
  public nowRange: TimeMachineSliderRange;
  public nowAdvancedRange: TimeMachineAdvancedRange;

  // [value, step]
  range: any = {
    min: [-8760, -672],
    '10%': [-672, -168],
    '20%': [-168, -24],
    '30%': [-24, 1],
    '40%': [-12, 1],
    '54%': [-6, 1],
    '62%': [-1, 0.25],
    '70%': [0, 0.25],
    '78%': [1, 1],
    '84%': [6, 1],
    '92%': [24, 1],
    max: [168, 24],
  };

  singleConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: this.timeService.timeSliderValue,
    range: this.range,
    keyboard: true,
    tooltips: [new MyFormatter()],
    pips: {
      mode: 'values',
      values: [-8760, -672, -168, -24, -12, -6, -1, 0, 1, 6, 24, 168],
      density: 1,
      stepped: false,
      format: new MyFormatter(),
    },
  };

  rangeSliderConfig: any = {
    behaviour: 'drag',
    connect: true,
    start: this.timeService.rangeTimeSliderValue,
    range: this.range,
    keyboard: true,
    tooltips: [new MyFormatter(), new MyFormatter()],
    pips: {
      mode: 'values',
      values: [-8760, -672, -168, -24, -12, -6, -1, 0, 1, 6, 24, 168],
      density: 1,
      stepped: false,
      format: new MyFormatter(),
    },
  };

  ranges: Ranges;
  firstNgModelChange: boolean = true;

  constructor(
    public timeService: TimeMachineService,
    public translateService: TranslateService,
    private rangeManagerService: RangeManagerService,
    private cd: ChangeDetectorRef,
  ) {
    this.now = this.timeService.timer;
    this.nowRange = this.timeService.timeMachineRange;
    this.nowAdvancedRange = this.timeService.timeMachineAdvanceRange;
  }

  ngOnInit(): void {
    this.ranges = this.rangeManagerService.getRanges();
  }

  setAdvanceRange(): void {
    this.timeService.rangeOn = true;
    this.timeService.advancedRangeOn = true;
    this.timeService.announceTimeChange(this.nowAdvancedRange);
    this.cd.detectChanges();
    setTimeout(() => {
      this.pickerDirective.open();
    });
  }

  changeSlider(): void {
    if (this.timeService.rangeOn) {
      this.updateRange();
    } else {
      const updateResult: TimeMachineUpdateSliderResult = this.timeService.updateTime(this.timeService.timeSliderValue);
      this.now = updateResult.value;
      if (updateResult.toUpdate) {
        this.timeService.announceTimeChange(this.now.valueOf());
        this.timeService.syncTimeAndRangeValue();
      }
    }
  }

  updateInterval(newMinutes: number): void {
    this.timeService.updateRefresh(newMinutes);
  }

  updateRange(): void {
    if (!this.timeService.advancedRangeOn) {
      const updateResult: TimeMachineUpdateSliderRangeResult = this.timeService.updateTimeRange(this.timeService.rangeTimeSliderValue);
      this.nowRange = updateResult.value;
      if (updateResult.toUpdate) {
        this.timeService.announceTimeChange(this.nowRange);
        this.timeService.syncTimeAndRangeValue();
      }
    } else {
      this.nowAdvancedRange = this.timeService.updateAdvancedTimeRange(this.timeService.advancedRangeTimePicker);
      this.timeService.announceTimeChange(this.nowAdvancedRange);
    }
  }

  checkForecastPast(newModel: boolean, type: string): void {
    if (type === 'simpleRange') {
      this.timeService.rangeOn = newModel;
      if (!this.timeService.rangeOn) {
        this.timeService.advancedRangeOn = false;
        this.timeService.announceTimeChange(this.now.valueOf());
      } else {
        this.timeService.announceTimeChange(this.nowRange);
      }
    } else {
      this.timeService.advancedRangeOn = newModel;
      if (this.timeService.advancedRangeOn) {
        this.timeService.announceTimeChange(this.nowAdvancedRange);
      } else {
        this.timeService.announceTimeChange(this.nowRange);
      }
    }
    this.timeService.checkForecastPast();
  }

  updateAutoRefresh(newModel: boolean): void {
    this.timeService.setAutoRefresh(newModel);
  }

  onAdvanceRangeChange(): void {
    if (!this.firstNgModelChange) {
      this.timeService.timeMachineAdvanceRange = new TimeMachineAdvancedRange(this.timeService.advancedRangeTimePicker.from,
        this.timeService.advancedRangeTimePicker.to);
      this.timeService.checkForecastPast();
      this.updateRange();
    } else {
      this.firstNgModelChange = false;
    }
  }
}
