import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { SerializedForecast } from '@app/modules/widgets/forecast-widget/model';
import { Forecast } from '@app/modules/widgets/forecast-widget/model/forecast';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ForecastWidgetService extends WidgetService {

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>,
                  additionalData: Array<number>, forecast?: boolean): Observable<Array<Forecast>> {
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'arpav-meteo';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<DataResult<SerializedForecast>>(url).pipe(
        map((result: DataResult<SerializedForecast>) => {
          return result.data.map((s: SerializedForecast) => {
            return new Forecast(s);
          });
        }),
      );
    }
  }
}
