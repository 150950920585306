import { CommonModule, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { TemperatureWidgetComponent } from '@app/modules/widgets/temperature-widget/temperature-widget.component';
import { TemperatureWidgetService } from '@app/modules/widgets/temperature-widget/temperature-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';

registerLocaleData(localeIT);

@NgModule({
  declarations: [TemperatureWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule.forChild({
      extend: true,
    }),
    AngularResizedEventModule,
  ],
  providers: [TemperatureWidgetService, { provide: LOCALE_ID, useValue: 'it' }],
  exports: [TemperatureWidgetComponent],
})
export class TemperatureWidgetModule {
}
