import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { HereMapWidgetComponent } from './here-map-widget.component';
import { HereMapWidgetService } from './here-map-widget.service';

@NgModule({
  declarations: [HereMapWidgetComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MapWidgetModule,
  ],
  providers: [HereMapWidgetService,
    { provide: 'here_map_widget_service', useClass: HereMapWidgetService }],
  exports: [HereMapWidgetComponent],
})
export class HereMapWidgetModule {
}
