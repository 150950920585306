<div class="widget grid-stack-item-content">
    <ng-content></ng-content>

    <div class="header">
        <div class="title">{{ title }}</div>
        <div class="icons">
            <i class="icon-venice_plus cursor-pointer" (click)="toggleFullscreen()"></i>
            <i class="icon-venice_menu disabled"></i>
            <i class="icon-venice_data cursor-pointer green" (click)="flipIt()"></i>
        </div>
    </div>

    <div class="flipper" [class.voted]="flipped">
        <div class="front">
            <iframe class="container" src="https://vpc30.herokuapp.com/scr/tourist_arrivals" width="100%" height="100%" frameborder="0"></iframe>
        </div>
        <div class="back">
            <div class="description">
                Totale arrivi
            </div>
            <div class="sources">
                <span>Sorgente dati primaria:</span>
                <ul>
                    <li>
                        <div>VDL-05-TUR</div>
                        <div>Venice Data Lake</div>
                        <i class="icon-venice_data green"></i>
                    </li>
                </ul>
            </div>
            <div class="sources">
                <span>Sorgente dati secondaria:</span>
                <ul>
                    <li>
                        <div>ABB-01-STT</div>
                        <div>AirB&B Network</div>
                        <i class="icon-venice_data green"></i>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>