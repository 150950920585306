import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { IframeWidgetService } from './iframe-widget.service';
import { IframeDataSource } from './models/iframe-data-source';

@Component({
  selector: 'app-iframe-widget',
  templateUrl: './iframe-widget.component.html',
  styleUrls: ['./iframe-widget.component.scss'],
})
export class IframeWidgetComponent extends WidgetComponent implements AfterViewInit, OnInit {

  iframeSrc: SafeResourceUrl;

  sources: Array<IframeDataSource>;

  constructor(public widgetService: IframeWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.loadSources();
  }

  ngAfterViewInit(): void {
    this.timeMachineSubscription = this.timeMachineService.timeMachineChanged.subscribe((timeMachineData: TimeMachineData) => {
      this.loadWidget(timeMachineData);
    });
    this.loadWidget(this.timeMachineService.getCurrentSelection());
    this.sendReloadWidget.subscribe(() => {
      this.loadWidget(this.timeMachineService.getCurrentSelection());
    });
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.sources) {
      this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((url: string | null) => {
        if (url) {
          this.noData = false;
          this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
          this.noData = true;
        }
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }

  handleResize(resize: ResizedEvent): void {
    this.loadWidget(this.timeMachineService.getCurrentSelection());
  }

  iframeLoaded(): void {
    this.isLoading = false;
    if (this.sources) {
      const mainSource: IframeDataSource = this.sources[0];
      mainSource.status = DataSourceStatus.AVAILABLE;
    }
    this.sourceStatus = DataSourceStatus.AVAILABLE;
    this.noData = false;
  }

  iframeError(): void {
    this.isLoading = false;
    if (this.sources) {
      const mainSource: IframeDataSource = this.sources[0];
      mainSource.status = DataSourceStatus.UNAVAILABLE;
    }
    this.sourceStatus = DataSourceStatus.UNAVAILABLE;
    this.noData = true;
  }

}
