import { MobilitySimulatorTypeConfig } from '@app/modules/widgets/mobility-map-widget/models/mobility-widget-data';
import { FeatureGroup, PathOptions } from 'leaflet';

export enum MobilitySimulatorBaseLayerTypes {
  PEDESTRIAN = 'pedestrian',
  WATER = 'water',
  ROAD = 'road',
}

export interface MobilitySimulatorBaseLayerMapRequest {
  type: MobilitySimulatorBaseLayerTypes;
  pathOptions?: PathOptions;
}

export interface MobilitySimulatorBaseLayer {
  layer: FeatureGroup;
  config: MobilitySimulatorTypeConfig;
  visible: boolean;
}

export interface MobilitySimulatorGateLocationCategoryItem {
  label: string;
  valueOverride?: number;
}
export interface MobilitySimulatorGateLocationItem {
  id: string;
  label: string;
  value: number;
  categories: Array<MobilitySimulatorGateLocationCategoryItem>;
}
export interface MobilitySimulatorGate {
  selected?: boolean;
  label: string;
  id: string;
  locations: Array<MobilitySimulatorGateLocationItem>;
  type: MobilitySimulatorBaseLayerTypes;
}
