import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from '@app/core/core.module';
import { CustomRouteReuseStrategy } from '@app/custom-route-reuse-strategy';
import { CustomTranslateLoader } from '@app/custom-translate-loader';
import { CesiumMapModule } from '@app/features/pages/cesium-map/cesium-map.module';
import { SharedModule } from '@app/shared/shared.module';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { ChartsModule } from 'ng2-charts';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FlipModule } from 'ngx-flip';
import { QuillModule } from 'ngx-quill';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { UserIdleModule } from 'angular-user-idle';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

export function jwtOptionsFactory(authenticationService: AuthenticationService): any {
  return {
    tokenGetter: (): string => {
      return authenticationService.getTokenInstant();
    },
    isTokenExpired: (): boolean => {
      return authenticationService.isTokenExpired();
    },
    whitelistedDomains: ['localhost:4200',
      'dev-scr.mindicity.it',
      'stg-scr.mindicity.it',
      'stg-a4mob.mindicity.it',
      'scr.mindicity.it'],
    skipWhenExpired: true,
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    DeviceDetectorModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ChartsModule,
    CesiumMapModule,
    SharedModule,
    MatCarouselModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    UserIdleModule.forRoot({ idle: 600, timeout: 300, ping: 120 }),
    TranslateModule.forRoot({
      defaultLanguage: 'it',
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient, AuthenticationService],
      },
    }),
    NgxDaterangepickerMd.forRoot({
      separator: ' - ',
      applyLabel: 'OK',
    }),
    FlipModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [AuthenticationService],
      },
    }),
    QuillModule.forRoot(),
  ],
  providers: [
    Title,
    AuthenticationService, {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
