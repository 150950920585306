<div class="slider-box d-flex flex-column justify-content-around align-items-stretch">
  <nouislider class="gradientBg time-slider" id="timeSlider" (change)="changeSlider()" #slider
              [config]="singleConfig" [(ngModel)]="timeService.timeSliderValue"
              [hidden]="timeService.rangeOn"
              [ngClass]="{ 'future-disabled': !timeService.allowFuture, 'past-disabled': !timeService.allowPast }"></nouislider>
  <nouislider class="gradientBg time-slider--range" id="rangeSlider" (change)="changeSlider()" #rangeSlider
              [config]="rangeSliderConfig" [(ngModel)]="timeService.rangeTimeSliderValue"
              [hidden]="!timeService.rangeOn" [disabled]="timeService.advancedRangeOn"
              [ngClass]="{ 'disabled': timeService.advancedRangeOn, 'future-disabled': !timeService.allowFuture, 'past-disabled': !timeService.allowPast }"></nouislider>
  <div class="date-time-box d-flex flex-column justify-content-between">
    <div class="date-time ngx-daterangepicker-action"
         [hidden]="timeService.rangeOn"
         (click)="setAdvanceRange()">{{ timeService.timer.valueOf() | date: 'dd.MM.yyyy | HH:mm' }}</div>
    <div class="date-time  ngx-daterangepicker-action"
         (click)="setAdvanceRange()"
         [hidden]="timeService.advancedRangeOn || !timeService.rangeOn">
      {{ timeService.timeMachineRange.getFrom().valueOf() | date: 'dd.MM.yyyy | HH:mm' }}
      - {{ timeService.timeMachineRange.getTo().valueOf() | date: 'dd.MM.yyyy | HH:mm' }}
    </div>
    <div class="date-time" [hidden]="!(timeService.rangeOn && timeService.advancedRangeOn)">
      <input type="text" matInput
             ngxDaterangepickerMd
             [locale]="{cancelLabel: 'TIME_MACHINE.BUTTONS.CANCEL' | translate,
           applyLabel: 'TIME_MACHINE.BUTTONS.APPLY' | translate,
           format: 'DD.MM.YYYY | HH:mm', firstDay: 1}"
             startKey="from"
             endKey="to"
             [alwaysShowCalendars]="true"
             [(ngModel)]="this.timeService.advancedRangeTimePicker"
             (datesUpdated)="onAdvanceRangeChange()"
             [ranges]="ranges"
             [timePicker]="true"
             [timePickerSeconds]="false"
             [timePickerIncrement]="1"
             [timePicker24Hour]="true"
             [showCancel]="true"
             [maxDate]="this.timeService.advancedRangeTimePickerMaxDate"
             [minDate]="this.timeService.advancedRangeTimePickerMinDate"
             [drops]="'up'"
             [opens]="'center'"
             class="cursor-pointer"
             #timeMachineRangeSelector
             name="daterange" />
    </div>
  </div>
  <div class="d-flex flex-row justify-content-between align-items-stretch">
    <div class="range-controller d-flex flex-column align-items-start justify-content-between">
      <mat-slide-toggle (ngModelChange)="checkForecastPast($event, 'simpleRange')"
                        [(ngModel)]="timeService.rangeOn">{{'TIME_MACHINE.RANGE' | translate}}</mat-slide-toggle>
      <mat-slide-toggle [hidden]="!timeService.rangeOn || !timeService.showAdvancedRange"
                        (ngModelChange)="checkForecastPast($event, 'advancedRange')"
                        [(ngModel)]="timeService.advancedRangeOn">{{'TIME_MACHINE.FULL_PRECISION' | translate}}</mat-slide-toggle>
    </div>
    <div class="minutes-controller d-flex flex-column align-items-start justify-content-between">
      <mat-slide-toggle (ngModelChange)="updateAutoRefresh($event)"
                        [(ngModel)]="timeService.autoRefresh">{{'TIME_MACHINE.AUTO_REFRESH' | translate}}</mat-slide-toggle>
      <mat-slider [min]="1" [max]="60" [step]="1" thumbLabel [tickInterval]="5"
                  (valueChange)="updateInterval($event)"
                  [disabled]="!timeService.autoRefresh"
                  [value]="timeService.minutesRefresh"></mat-slider>
      <span
        *ngIf="timeService.autoRefresh">{{'TIME_MACHINE.INTERVAL' | translate:{ minutes: timeService.minutesRefresh } }}</span>
      <span *ngIf="!timeService.autoRefresh">{{'TIME_MACHINE.AUTO_REFRESH_DISABLED' | translate }}</span>
    </div>
    <div class="buttons d-flex flex-column justify-content-top">
      <button mat-raised-button color="primary" (click)="timeService.onNowClick()">
        <ng-container
          *ngIf="timeService.rangeOn">{{'TIME_MACHINE.BUTTONS.LAST_HOUR' | translate }}</ng-container>
        <ng-container *ngIf="!timeService.rangeOn">{{'TIME_MACHINE.BUTTONS.NOW' | translate }}</ng-container>
      </button>
    </div>
  </div>
</div>
