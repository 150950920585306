import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class RoadFlowNumberService extends WidgetService {

  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>): Observable<any> {
    const source: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'value';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<NumberValue>(url).pipe(switchMap((nv: NumberValue) => {
        return of(nv.value);
      }));
    }
  }
}

export interface NumberValue {
  time: Date;
  value: number;
}
