import { flatten } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ImageDemoService extends WidgetService {

  private static convertDataUrlToBlob(dataUrl: string): Blob {
    const arr: Array<string> = dataUrl.split(',');
    const mime: string = arr[0].match(/:(.*?);/)[1];
    const bstr: string = atob(arr[1]);
    let n: number = bstr.length;
    const u8arr: Uint8Array = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  public loadData(timeMachineData: TimeMachineData, sources?: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<Array<SafeResourceUrl>> {

    const imageSources: Array<WidgetDataSource> = sources.filter((s: WidgetDataSource) => {
      return s.sourceType === 'image';
    });

    const observables: Array<Observable<Array<Blob>>> = imageSources.map((s: WidgetDataSource) => {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, s);
      return this.http.get<Blob>(url, { responseType: 'blob' as 'json' }).pipe(switchMap((i: Blob) => {
        return of([i]);
      }));
    });

    const arrayImageSources: Array<WidgetDataSource> = sources.filter((s: WidgetDataSource) => {
      return s.sourceType === 'image-array';
    });

    const arrayObservables: Array<Observable<Array<Blob>>> = arrayImageSources.map((s: WidgetDataSource) => {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, s);
      return this.http.get<DataResult<string>>(url)
        .pipe(switchMap((d: DataResult<string>) => {
          return of(d.data.map((i: string) => {
            return ImageDemoService.convertDataUrlToBlob(i);
          }));
        }));
    });

    return forkJoin(observables.concat(arrayObservables)).pipe(
      switchMap((images: Array<Array<Blob>>) => {
        return of(flatten(images).map((image: Blob) => {
          return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(image));
        }));
      }));
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const timestamp: number = new Date().getTime();
    const url: string = this.addTimeMachineDataToUrl(timestamp, source);
    return this.http.get<HTMLImageElement>(url, { responseType: 'blob' as 'json' });
  }
}
