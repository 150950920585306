import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { HeartBeatSource, HeartBeatType } from '@app/modules/widgets/heart-beat-widget/model/heart-beat-source';
import { IndicesResponse } from '@app/modules/widgets/heart-beat-widget/model/indices-response';
import { LionMenuElement } from '@app/modules/widgets/heart-beat-widget/model/lion-menu-element';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ResizedEvent } from 'angular-resize-event';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { HeartBeatWidgetService } from './heart-beat-widget.service';
import { DataIndex, SerializedDataIndex } from './model';

@Component({
  selector: 'app-heart-beat-widget',
  templateUrl: './heart-beat-widget.component.html',
  styleUrls: ['./heart-beat-widget.component.scss'],
})
export class HeartBeatWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {

  widgetWidth: number;
  innerWidth: number;
  showIndexes: boolean;
  isTwoColumns: boolean;
  showLion: boolean;

  fontSize: string;
  lionMenu: Array<LionMenuElement>;
  indexesData: IndicesResponse;
  indexes: Array<DataIndex>;

  sourceStatus: DataSourceStatus = DataSourceStatus.AVAILABLE;

  @Input()
  data: HeartBeatSource;

  constructor(public widgetService: HeartBeatWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  onLionClick(lion: LionMenuElement): void {
    if (!this.isFullScreen) {
      this.isFullScreen = true;
      this.fullScreen.emit(this.isFullScreen);
    }
    this.lionMenu.forEach((l: LionMenuElement) => {
      l.small = l.id !== lion.id;
    });
    this.indexes = lion.index;
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.isTwoColumns = false;
    if (!this.data.file) {
      this.data.file = 'lion.svg';
    }
    super.ngOnInit();
  }

  serializeIndexes(result: SerializedDataIndex): Array<DataIndex> {
    const indexes: Array<DataIndex> = cloneDeep(this.data.indexes).map((element: DataIndex) => {
      element.value = result[element.id];
      return element;
    });
    return indexes.filter((i: DataIndex) => {
      return this.authenticationService.userHasRole(i.roles) || this.authenticationService.userHasPrivilege(i.privileges);
    });
  }

  parseIndicesData(): Array<LionMenuElement> {
    let pastLabel: string;
    if (this.timeMachineService.forecast || this.timeMachineService.past) {
      pastLabel = moment(this.timeMachineService.getToTimeStamp(-12)).format('DD MMMM HH:mm');
    } else {
      pastLabel = this.translateService.instant('WIDGETS.HEART_BEAT.HOURS_AGO', { hours: 12 });
    }
    const pastIndicesData: LionMenuElement = {
      id: 1,
      label: pastLabel,
      small: true,
      left: true,
      right: false,
      enabled: true,
      index: this.serializeIndexes(this.indexesData.past),
    };
    let presentLabel: string;
    if (this.timeMachineService.forecast || this.timeMachineService.past) {
      presentLabel = moment(this.timeMachineService.getToTimeStamp(0)).format('DD MMMM HH:mm');
    } else {
      presentLabel = this.translateService.instant('WIDGETS.HEART_BEAT.NOW', { hours: 0 });
    }
    const presentIndicesData: LionMenuElement = {
      id: 2,
      label: presentLabel,
      small: false,
      left: false,
      right: false,
      enabled: true,
      index: this.serializeIndexes(this.indexesData.present),
    };
    let futureLabel: string;
    if (this.timeMachineService.forecast || this.timeMachineService.past) {
      futureLabel = moment(this.timeMachineService.getToTimeStamp(4)).format('DD MMMM HH:mm');
    } else {
      futureLabel = this.translateService.instant('WIDGETS.HEART_BEAT.BETWEEN_HOURS', { hours: 4 });
    }
    const futureIndicesData: LionMenuElement = {
      id: 3,
      label: futureLabel,
      small: true,
      left: false,
      right: true,
      enabled: true,
      index: this.serializeIndexes(this.indexesData.future),
    };
    return [pastIndicesData, presentIndicesData, futureIndicesData];
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources, null).subscribe((indexesData: IndicesResponse) => {
      this.indexesData = indexesData;
      this.indexes = this.serializeIndexes(this.indexesData.present);
      this.lionMenu = this.parseIndicesData();
      this.checkShowIndexes();
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  handleResize(resize: ResizedEvent): void {
    this.widgetWidth = resize.newWidth;

    const minSize: number = resize.newHeight > resize.newWidth ? resize.newWidth : resize.newHeight;
    this.fontSize = (minSize / 30).toString();

    this.isTwoColumns = this.showIndexes && resize.newHeight < resize.newWidth / 2;

    this.checkShowIndexes();
  }

  checkShowIndexes(): void {
    this.showIndexes = (this.widgetWidth > 0.33 * this.innerWidth) || this.data.type === HeartBeatType.INDEXES;
    this.showLion = this.data.type === HeartBeatType.LION;
  }

  getCircleColor(value: number, active: boolean): string {
    return this.widgetService.getColorFill(value, active);
  }
}
