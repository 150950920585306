import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ThemeService {
  isDark: boolean = false;
  sendReload: EventEmitter<void> = new EventEmitter<void>();

  constructor() {
    if (localStorage.getItem('darkTheme')) {
      this.isDark = true;
    }
  }

  toggleDark(): void {
    this.isDark = !this.isDark;
    if (this.isDark) {
      localStorage.setItem('darkTheme', 'ON');
    } else {
      localStorage.removeItem('darkTheme');
    }
    this.sendReload.emit();
  }

  getGrafanaString(): string {
    if (this.isDark) {
      return 'dark';
    } else {
      return 'light';
    }
  }
}
