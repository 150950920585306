import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { Alert } from '@app/shared/models/alerts';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { OrderRequestDirection, SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { Observable } from 'rxjs';

@Injectable()
export class BigMapWidgetService extends MapWidgetService {
  checkSource(source: WidgetDataSource): Observable<any> {
    if (source.rewriteUrl.includes('queues-list')) {
      const request: SearchRequest = {
        order: [
          {
            field: 'unixstamp',
            mode: OrderRequestDirection.DESC,
          },
          {
            field: 'name.keyword',
            mode: OrderRequestDirection.DESC,
          },
        ],
        pagination: {
          from: 0,
          size: 1,
        },
      };
      return this.http.post<DataResult<Alert>>(source.rewriteUrl, request);
    } else {
      return super.checkSource(source);
    }
  }
}
