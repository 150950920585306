import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { BridgesWidgetService } from '@app/modules/widgets/bridges-widget/bridges-widget.service';

@Component({
  selector: 'app-bridges-control',
  templateUrl: './bridges-control.component.html',
  styleUrls: ['./bridges-control.component.scss'],
})
export class BridgesControlComponent implements OnInit {

  @Input()
  boatHeightValue: number;

  @Input()
  tideValue: number;

  @Input()
  originalTideValue: number;

  bridgesService: BridgesWidgetService;

  @Output()
  sliderChanged: EventEmitter<BridgesControlValues> = new EventEmitter<BridgesControlValues>();

  constructor(public timeMachineService: TimeMachineService) {
  }

  ngOnInit(): void {
    this.originalTideValue = this.tideValue;
    if (this.bridgesService) {
      this.boatHeightValue = this.bridgesService.arrowValue;
    }
  }

  updateValue(event: MatSliderChange, param: string): void {
    this[param] = event.value;
  }

  setNewValue(event: MatSliderChange, param: string): void {
    this.sliderChanged.emit({
      tide: this.tideValue,
      boatHeight: this.boatHeightValue,
    });
  }

  setActualTide(): void {
    this.tideValue = this.originalTideValue;
    this.sliderChanged.emit({
      tide: this.tideValue,
      boatHeight: this.boatHeightValue,
    });
  }
}

export interface BridgesControlValues {
  tide: number;
  boatHeight: number;
}
