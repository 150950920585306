import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { WidgetType } from '@app/shared/enums/widget-type';

export const WIND_LEGEND: MapLegend = {
  title: 'WIND',
  type: WidgetType.WIND_WIDGET,
  description: 'WIND_DESCRIPTION',
  additionalData: null,
  elements: [{
    label: 'Calma - 0 kn',
    color: '#eeffff',
  }, {
    label: 'Bava di vento - 1÷3 kn',
    color: '#66ffff',
  }, {
    label: 'Brezza Leggera - 4÷6 kn',
    color: '#00ccff',
  }, {
    label: 'Brezza Tesa - 7÷10 kn',
    color: '#0066ff',
  }, {
    label: 'Vento moderato - 11÷16 kn',
    color: '#32cd32',
  }, {
    label: 'Vento teso - 17÷21 kn',
    color: '#228b22',
  }, {
    label: 'Vento fresco - 22÷27 kn',
    color: '#006400',
  }, {
    label: 'Vento forte - 28÷33 kn',
    color: '#556b2f',
  }, {
    label: 'Burrasca - 34÷40 kn',
    color: '#ff0000',
  }, {
    label: 'Burrasca forte - 41÷47 kn',
    color: '#b22222',
  }, {
    label: 'Tempesta - 48÷55 kn',
    color: '#ff00ff',
  }, {
    label: 'Fortunale o tempesta violenta - 56÷63 kn',
    color: '#800080',
  }, {
    label: 'Uragano - > 64 kn',
    color: '#000000',
  },
  ],
};
