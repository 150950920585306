<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData"
>
  <div class="container d-flex flex-column justify-content-center align-items-center">
    <div class="number mb-2">
      {{number}}
    </div>
    <div class="date mt-2">
      <i>{{'WIDGETS.ROAD_BASE_NUMBER.LABEL' | translate }}</i>
    </div>
  </div>
</app-widget>
