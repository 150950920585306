import { NouiFormatter } from 'ng2-nouislider';

export class MyFormatter implements NouiFormatter {

  to(value: number): string {
    let result: string;
    if (value <= -8760) {
      value = value / 8760;
      result = value.toFixed(0);
      return result + 'y';
    }
    if (value <= -672) {
      value = value / 672;
      result = value.toFixed(0);
      return result + 'M';
    }
    if (value <= -168) {
      value = value / 168;
      result = value.toFixed(0);
      return result + 'w';
    }
    if (value < -24) {
      value = value / 24;
      result = value.toFixed(0);
      return result + 'd';
    }
    if (value <= -1) {
      result = value.toFixed(0);
      return result + 'h';
    }
    if (value <= 0) {
      result = (value * 60).toFixed(0);
      return result + 'm';
    }
    if (value < 1) {
      result = (value * 60).toFixed(0);
      result = '+' + result;
      return result + 'm';
    }
    if (value <= 24) {
      result = value.toFixed(0);
      result = '+' + result;
      return result + 'h';
    }
    if (value < 168) {
      value = value / 24;
      result = value.toFixed(0);
      result = '+' + result;
      return result + 'd';
    }
    if (value < 672) {
      value = value / 168;
      result = value.toFixed(0);
      result = '+' + result;
      return result + 'w';
    }
  }

  from(value: string): number {
    return parseInt(value, 10);
  }
}
