import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { SanctionsMapService } from '@app/modules/widgets/sanctions/sanctions-map/sanctions-map.service';
import { SanctionsService } from '@app/modules/widgets/sanctions/sanctions.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { SanctionsMapComponent } from './sanctions-map/sanctions-map.component';


@NgModule({
  declarations: [SanctionsMapComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MapWidgetModule
  ],
  providers: [SanctionsService, SanctionsMapService],
  exports: [SanctionsMapComponent],
})
export class SanctionsModule {
}
