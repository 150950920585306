<app-widget class="mindicity-widget heatmap-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isFullScreen]="isFullScreen" [isEditable]="isEditable" [isLoading]="isLoading" [noData]="noData">
  <div class="map" #mapEl (resized)="handleResize($event)"></div>
  <div class="map__right">
    <ng-container *ngIf="showDetails && map.getZoom() > 7">
      <div class="map-element mb-2 d-flex flex-row align-items-stretch">
        <div class="map-legend d-flex flex-column align-items-stretch justify-content-end">
          <div class="map-legend__description d-flex flex-row">
            <div>{{'WIDGETS.HEATMAP.LABEL' | translate}}</div>
          </div>
          <div class="map-legend__gradient">
          </div>
          <div class="map-legend__labels d-flex flex-row align-items-stretch justify-content-between">
            <div>0</div>
            <div>25</div>
            <div>100</div>
            <div>500</div>
            <div>2000+</div>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="map-controller map-element" [hidden]="!showDetails || !data.showFilter">
      <ng-select
        class="map-controller__select"
        [items]="layerOptions"
        [multiple]="true"
        [closeOnSelect]="false"
        bindLabel="label"
        [(ngModel)]="heatmapSelection"
        [hideSelected]="false"
        [placeholder]="'WIDGETS.HEATMAP.' + data.type | translate"
        (ngModelChange)="onChange($event)">
        <ng-template ng-header-tmp>
          <div>
            <button class="btn btn-link"
                    (click)="onSelectAll()">{{'WIDGETS.HEATMAP.SELECT_ALL' | translate}}</button>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="map-table map-element d-flex flex-column justify-content-start align-items-stretch"
       *ngIf="dataLevel && dataLevel.data && dataLevel.data.length && showDetails">
    <div class="map-table__entry row">
      <h5 class="mb-2 col-12"
          *ngIf="dataLevel.data[0] && dataLevel.data[0].regione">{{'WIDGETS.HEATMAP.REGIONS' | translate}}</h5>
      <h5 class="mb-2 col-12"
          *ngIf="dataLevel.data[0] && dataLevel.data[0].provincia">{{'WIDGETS.HEATMAP.PROVINCE' | translate}}</h5>
      <h5 class="mb-2 col-12"
          *ngIf="dataLevel.data[0] && dataLevel.data[0].comune">{{'WIDGETS.HEATMAP.TOWN' | translate}}</h5>
    </div>
    <div class="map-table__entry row">
      <i class="map-table__entry__data mb-2 col-6">{{'WIDGETS.HEATMAP.PEOPLE.' + data.type | translate}}</i>
      <i class="map-table__entry__data mb-2 col-6">{{'WIDGETS.HEATMAP.PEOPLE.TOTAL' | translate}}</i>
      <i *ngIf="false" class="map-table__entry__data mb-2 col-2">{{'WIDGETS.HEATMAP.ITALIANS' | translate}}</i>
      <i *ngIf="false" class="map-table__entry__data mb-2 col-2">{{'WIDGETS.HEATMAP.FOREIGN' | translate}}</i>
    </div>
    <div class="map-table__entry row pb-1"
         *ngFor="let level of dataLevel.data | slice:0:20">
      <div *ngIf="level.regione" class="text-left col-6">{{level.regione}}</div>
      <div *ngIf="level.provincia" class="text-left col-6">{{level.provincia}}</div>
      <div *ngIf="level.comune" class="text-left col-6">{{level.comune}}</div>
      <div class="text-right font-weight-bold col-6">{{level.total | number}}</div>
      <div *ngIf="false" class="text-right font-weight-bold col-2">{{level.ni | number}}</div>
      <div *ngIf="false" class="text-right font-weight-bold col-2">{{level.ns | number}}</div>
    </div>
    <div class="map-table__entry d-flex flex-row justify-content-between align-items-center">
      <i
        class="map-table__entry__data mt-2 text-left">{{'WIDGETS.HEATMAP.DEFAULT_TIMER' | translate: { date: dataLevel.endDate | date:'medium' } }}
        <ng-container *ngIf="data.type=='DESTINATIONS'">
          - {{'WIDGETS.HEATMAP.DESTINATIONS_DATA' | translate}}</ng-container>
      </i>
    </div>
  </div>
</app-widget>
