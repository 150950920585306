<div class="widget grid-stack-item-content" [ngClass]="{
  'widget--global': showThresholdAlert && showGlobalThresholdAlert,
  'widget--alarm': showThresholdAlert && !showGlobalThresholdAlert,
  'widget--full': data && data.fullView,
  'darkMode': themeService.isDark}">
  <div class="header d-flex flex-row justify-content-between align-items-center" *ngIf="!(data && data.fullView)">
    <div class="title-box d-flex flex-row justify-content-start align-items-center">
      <div *ngIf="showThresholdAlert" class="pulse pr-2"
           [ngClass]="{'yellow': showThresholdAlert && showGlobalThresholdAlert,
           'orange': showThresholdAlert && !showGlobalThresholdAlert}">
        <i class="icon-venice_alert"></i></div>
      <mat-icon class="clock cursor-pointer mr-2" *ngIf="timeMachineOn && timeMachineActive"
                [ngClass]="{'clock--forecast': this.timeMachineService.forecast, 'clock--past': this.timeMachineService.past}">
        schedule
      </mat-icon>
      <h6 class="title">{{ title }}</h6>
    </div>
    <div class="icons d-flex flex-row justify-content-center align-items-center" [ngSwitch]="sourceStatus">
      <mat-icon (click)="reloadWidget()" class=" cursor-pointer">
        refresh
      </mat-icon>
      <em class="cursor-pointer"
          [ngClass]="{'icon-venice_plus': !isFullScreen, 'icon-venice_minus': isFullScreen}"
          (click)="toggleFullscreen()"></em>
      <em class="icon-venice_menu cursor-pointer" (click)="sendEditData()"></em>
      <em *ngSwitchCase="'available'" class="icon-venice_data cursor-pointer available" (click)="flipIt()"></em>
      <em *ngSwitchCase="'unavailable'" class="icon-venice_data cursor-pointer unavailable" (click)="flipIt()"></em>
      <em *ngSwitchCase="'warning'" class="icon-venice_data cursor-pointer warning" (click)="flipIt()"></em>
      <em *ngIf="isEditable" class="icon-venice_close disabled cursor-pointer" (click)="sendRemoveWidget()"></em>
    </div>
  </div>

  <ngx-flip class="flip" [flip]="flipped">
    <div front class="widget-front" [ngClass]="{'widget-front--full': data && data.fullView}">
      <ng-content></ng-content>
      <div *ngIf="noData" class="no-data d-flex flex-row justify-content-center align-items-center">
        {{'WIDGETS.NO_DATA' | translate }}
      </div>
      <div class="loader widget-loader widget-loader d-flex flex-column justify-content-center align-items-center"
           *ngIf="isLoading">
        <mat-spinner class="widget-loader__spinner" diameter="30"></mat-spinner>
      </div>
    </div>
    <div back>
      <!-- <em class="icon-venice_minus cursor-pointer delete float-right" (click)="sendRemoveWidget()" ></em> -->
      <div class="description">
        <strong>{{ description }}</strong>
      </div>
      <div class="primary_sources pt-2 pb-2">
        <h6><strong class="text-left d-block pb-2">{{'WIDGETS.PRIMARY_DATA_SOURCE' | translate }}</strong></h6>
        <ul class="sources">
          <ng-container *ngFor="let source of sources">
            <li class="source row" *ngIf="source && source.level == 0"
                [ngClass]="source.status != 'unavailable' ? 'available' : 'unavailable'">
              <p class="col text-left">{{source.description}}</p>
              <p class="col text-left"
                 [ngClass]="sourceStatus != 'unavailable' ? 'available' : 'unavailable'">{{source.supplier}}</p>
              <p class="icon-venice_data green col right"
                 [className]="source.status == 'available' ? 'icon-venice_data cursor-pointer available' : 'icon-venice_data cursor-pointer unavailable'"></p>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="additional_sources">
        <h6><strong class="text-left d-block pb-2">{{'WIDGETS.ADDITIONAL_DATA_SOURCE' | translate }}</strong></h6>
        <ul class="sources">
          <ng-container *ngFor="let source of sources">
            <li class="source row" *ngIf="source && source.level == 1"
                [ngClass]="source.status != 'unavailable' ? 'available' : 'unavailable'">
              <p class="col text-left">{{source.description}}</p>
              <p class="col text-left"
                 [ngClass]="sourceStatus != 'unavailable' ? 'available' : 'unavailable'">{{source.supplier}}</p>
              <p class="icon-venice_data green col right"
                 [className]="source.status == 'available' ? 'icon-venice_data cursor-pointer available' : 'icon-venice_data cursor-pointer unavailable'"></p>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </ngx-flip>
</div>
