import { EventEmitter, Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Alert } from '@app/shared/models/alerts';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { MarkerList } from '@app/shared/models/map/marker-list';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { OrderRequestDirection, SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { AlertInsertResponse } from '@app/shared/models/venice-data-lake/update-response';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureCollection } from 'geojson';
import {
  circle,
  Circle,
  DivIcon,
  FeatureGroup,
  geoJSON,
  GeoJSONOptions,
  Icon,
  LatLngExpression,
  Layer,
  Marker,
  PointExpression,
  Util,
  Tooltip,
} from 'leaflet';
import { Observable, of, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertsService extends WidgetService {
  private alertRequest: Observable<Array<Alert>>;

  newAlerts: EventEmitter<boolean> = new EventEmitter<boolean>();
  markers: Array<MarkerList> = [];

  loadData(timeMachineData: TimeMachineData, sources?: Array<WidgetDataSource>): Observable<Array<Alert>> {
    if (!this.alertRequest) {
      const request: SearchRequest = {
        order: [
          {
            field: 'unixstamp',
            mode: OrderRequestDirection.DESC,
          },
          {
            field: 'name.keyword',
            mode: OrderRequestDirection.DESC,
          },
        ],
        pagination: {
          from: 0,
          size: 20,
        },
      };
      const mainSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
        return s.sourceType === 'alerts';
      });
      if (mainSource) {
        this.alertRequest = this.http.post<DataResult<Alert>>(mainSource.rewriteUrl, request).pipe(
          switchMap((d: DataResult<Alert>) => {
            this.alertRequest = null;
            return of(d.data);
          }));
      } else {
        throwError('No source provided');
      }
    }
    return this.alertRequest;
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const request: SearchRequest = {
      order: [
        {
          field: 'unixstamp',
          mode: OrderRequestDirection.DESC,
        },
        {
          field: 'name.keyword',
          mode: OrderRequestDirection.DESC,
        },
      ],
      pagination: {
        from: 0,
        size: 1,
      },
    };
    return this.http.post<DataResult<Alert>>(source.rewriteUrl, request);
  }

  updateData(index: string, domain: string): Observable<AlertInsertResponse> {
    const url: string = `gateway/mcapi/events/${domain}/users/readings/${index}`;
    return this.http.put<AlertInsertResponse>(url, {});
  }

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>): Observable<FeatureGroup> {
    try {
      this.markers = [];
      return this.loadData(timeMachineData, sources).pipe(
        switchMap((data: Array<Alert>) => {
          const geojson: FeatureCollection = {
            type: 'FeatureCollection',
            features: [],
          };

          data.forEach((alert: Alert) => {
            if ((alert.position || alert.shape) && !alert.read) {
              geojson.features.push({
                type: 'Feature',
                geometry: alert.shape ? alert.shape : alert.position,
                properties: {
                  databag: alert.databag,
                  sourceId: alert.sourceId,
                  description: alert.description,
                  radius: alert.radius,
                  indexId: alert.indexId,
                },
              });
            }
          });
          const options: any = {
            onEachFeature: this.addPopupToLayer(),
            pointToLayer: this.addLevelTooltip(),
            menuName: this.translateService.instant('PAGES.ALERTS.MAP_TITLE'),
            type: 'alerts',
          };
          const featureGroup: FeatureGroup = geoJSON(geojson, options);
          featureGroup['id'] = 'alerts';
          return of(featureGroup);
        }),
      );
    } catch (e) {
      throwError(e);
    }
  }

  loadTooltipFromData(feature: Feature): string {
    const translate: TranslateService = this.translateService;

    let template: string = '';
    if (feature.properties['description']) {
      template = template + `<b>${feature.properties['description']}</b><br/>`;
    } else if (feature.properties['name']) {
      template = template + `<b>${feature.properties['name']}</b><br/>`;
    }
    if (feature.properties.databag) {
      if (feature.properties.databag.startNode) {
        template += `<b>${translate.instant('WIDGETS.ALERT.START')}:</b> ${feature.properties.databag.startNode}<br/>`;
      }
      if (feature.properties.databag.endNode) {
        template += `<b>${translate.instant('WIDGETS.ALERT.END')}:</b> ${feature.properties.databag.endNode}<br/>`;
      }
      if (feature.properties.databag.speed) {
        template += `<b>${translate.instant('WIDGETS.ALERT.SPEED')}:</b> ${feature.properties.databag.speed}<br/>`;
      }
    }

    return template;
  }

  addPopupToLayer(): (f: Feature, l: Layer) => void {
    return (f: Feature, l: Layer): void => {
      if (f.geometry.type !== 'Point' && f.geometry.type !== 'MultiPoint') {
        l.bindTooltip(this.loadTooltipFromData(f),
          { offset: [0, -56], direction: 'top' },
        );
        this.markers.push({
          id: f.properties.indexId,
          additionalData: f.geometry.type,
          markerOnMap: l,
        });
      }
      l['id'] = f.properties.indexId;
    };
  }

  getIcon(feature: Feature): Icon | DivIcon {
    let pinUrl: string = '';
    switch (feature.properties.sourceId) {
      case 'ACCIDENT':
        pinUrl = 'assets/img/markers/bluepin_logo_accident.png';
        break;
      case 'CONSTRUCTION':
        pinUrl = 'assets/img/markers/bluepin_logo_construction.png';
        break;
      case 'HAZARD':
      case 'MISC':
        pinUrl = 'assets/img/markers/bluepin_logo_hazard.png';
        break;
      case 'IRREGULARITY':
      case 'JAM':
      case 'TRAFFIC_JAM':
        pinUrl = 'assets/img/markers/bluepin_logo_traffic.png';
        break;
      case 'ROAD_CLOSED':
        pinUrl = 'assets/img/markers/bluepin_logo_onshoulder.png';
        break;
      // case 'WEATHERHAZARD':
      //   //pinUrl = 'assets/img/markers/bluepin_logo_construction.png';
      //   break;
    }
    let pinIcon: Icon | DivIcon;
    if (pinUrl === '') {
      const markerDown: any = require('!raw-loader!@assets/img/markers/alert/m_alert-down.svg');
      const iconSettings: any = {
        mapIconUrl: markerDown.default,
        mapIconColor: '#72C5DE',
      };

      pinIcon = new DivIcon({
        className: 'prova',
        iconSize: [32, 32],
        iconAnchor: [16, 32],
        html: Util.template(iconSettings.mapIconUrl, iconSettings),
      });
    } else {
      pinIcon = new Icon({
        iconUrl: pinUrl,
        iconSize: [72, 64],
        iconAnchor: [36, 64],
      });
    }
    return pinIcon;
  }

  getTooltipPosition(feature: Feature): PointExpression {
    let toolTipPosition: PointExpression = [0, -32];
    const sourceId: Array<string> = ['ACCIDENT', 'CONSTRUCTION', 'HAZARD', 'MISC', 'IRREGULARITY', 'JAM', 'TRAFFIC_JAM', 'ROAD_CLOSED'];
    if (feature.properties.radius) {
      toolTipPosition = [0, 0];
    }

    if (sourceId.indexOf(feature.properties.sourceId) > -1) {
      toolTipPosition = [0, -64];
    }

    return toolTipPosition;
  }

  addLevelTooltip(): (feature: Feature, latlng: LatLngExpression) => Layer {
    return (feature: Feature, latlng: LatLngExpression): Layer => {
      const tooltip: Tooltip = new Tooltip({ offset: this.getTooltipPosition(feature), direction: 'top' });

      tooltip.setContent(this.loadTooltipFromData(feature));
      if (!feature.properties.radius) {
        const marker: Marker = new Marker(latlng, {
          icon: this.getIcon(feature),
        }).bindTooltip(tooltip);
        marker['id'] = feature.properties.indexId;

        this.markers.push({
          id: feature.properties.indexId,
          additionalData: feature.geometry.type,
          markerOnMap: marker,
        });
        return marker;
      } else {
        const c: Circle<any> = circle(latlng, { radius: feature.properties.radius });
        c.setStyle({
          fillOpacity: 0.2,
          color: '#3388ff',
          fillColor: '#3388ff',
          weight: 3,
        });
        c.bindTooltip(tooltip);
        c['id'] = feature.properties.indexId;
        this.markers.push({
          id: feature.properties.indexId,
          additionalData: feature.geometry.type,
          markerOnMap: c,
        });
        return c;
      }
    };
  }
}

export interface CustomGeoJSONOptions extends GeoJSONOptions {
  type: string;
  menuName: string;
}
