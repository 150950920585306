import moment, { Moment } from 'moment';
import { NoUiSliderRangeTimestamps, SliderRange } from './time-machine-data';

export class TimeMachineRange {
  hoursFrom: number;
  hoursTo: number;

  from: Moment;
  to: Moment;

  constructor(from: Moment, to: Moment) {
    this.setFields(from, to);
    this.updateTimeSlide();
  }

  setFields(f: Moment, t: Moment): void {
    this.from = f.seconds(0);
    this.to = t.seconds(0);
  }

  add(seconds: number): void {
    this.from = moment(this.from).seconds(0).add(seconds / 1000, 's');
    this.to = moment(this.to).seconds(0).add(seconds / 1000, 's');
  }

  getTimeStamps(): NoUiSliderRangeTimestamps {
    return [this.from.valueOf(), this.to.valueOf()];
  }

  getHoursDifference(): NoUiSliderRangeTimestamps {
    return [this.hoursFrom, this.hoursTo];
  }

  setMomentFields(from: Moment, to: Moment): void {
    this.from = from.seconds(0);
    this.to = to.seconds(0);
    this.updateTimeSlide();
  }

  isForecast(): boolean {
    return this.hoursFrom >= 0 && this.hoursTo >= 0;
  }

  isPast(): boolean {
    return this.hoursFrom <= 0 && this.hoursTo <= 0;
  }

  getFields(): SliderRange {
    return {
      from: this.from,
      to: this.to,
    };
  }

  getFrom(): Moment {
    return this.from;
  }

  getTo(): Moment {
    return this.to;
  }

  getFromTimeStamp(): number {
    return this.from.seconds(0).valueOf();
  }

  getToTimeStamp(): number {
    return this.to.seconds(0).valueOf();
  }

  updateTimeSlide(): void {
    const now: Moment = moment();
    this.hoursFrom = Math.round(moment.duration(this.from.diff(now)).asHours());
    this.hoursTo = Math.round(moment.duration(this.to.diff(now)).asHours());
  }
}
