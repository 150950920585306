import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { BridgesControlComponent } from '@app/modules/widgets/bridges-widget/bridges-control/bridges-control.component';
import { BRIDGES_LEGEND } from '@app/modules/widgets/bridges-widget/data';
import { BridgeTide, BridgeTideForecast, SerializedBridge } from '@app/modules/widgets/bridges-widget/models';
import { BridgesRequest } from '@app/modules/widgets/bridges-widget/models/bridges-request';
import { BridgesType } from '@app/modules/widgets/bridges-widget/models/bridges-type';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureCollection, MultiPolygon } from 'geojson';
import { FeatureGroup, geoJSON, GeoJSON as LeafletGeoJSON, GeoJSONOptions, LatLng, Layer, PathOptions, Polygon, Popup } from 'leaflet';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BridgesWidgetService extends MapWidgetService {

  NOT_AVAILABLE_LABEL: string = 'COMMONS.UNAVAILABLE';

  arrowValue: number = 1;

  getBridgeArrow(bridgeTides: Array<BridgeTide>, id: string): number {
    let bridgeTide: BridgeTide;
    bridgeTide = bridgeTides.find((b: BridgeTide) => {
      return b.bridgeID === id;
    });
    if (bridgeTide) {
      return bridgeTide.arrow;
    }
  }

  getBridges(sources: Array<WidgetDataSource>): Observable<FeatureCollection> {
    const bridgeSource: WidgetDataSource = sources.find((a: WidgetDataSource) => {
      return a.sourceType === 'bridges-shapes';
    });
    if (bridgeSource) {
      const url: string = bridgeSource.rewriteUrl;
      return this.http.get<FeatureCollection>(url);
    }
  }

  addPopupToBridge(bridgeTides: Array<BridgeTide | SerializedBridge>, additionalData: BridgesRequest):
    (f: Feature<MultiPolygon>, l: Layer) => void {
    return (f: Feature<MultiPolygon>, l: Layer): void => {
      const translate: TranslateService = this.translateService;
      const coordinates: Array<LatLng> = LeafletGeoJSON.coordsToLatLngs(f.geometry.coordinates, 2);
      const polygon: Polygon = new Polygon(coordinates);
      const center: LatLng = polygon.getBounds().getCenter();
      const popup: Popup = new Popup({
        autoClose: false,
        closeOnClick: false,
      });
      let template: string = `<h6>${f.properties['name']}</h6>
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.RIVER_NAME')}: </b>${f.properties['riverName']}
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.PLACE')}: </b>${f.properties['locality']}
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.OWNER')}: </b>${f.properties['owner']}
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.FREE')}: </b>${Number(f.properties['free']).toFixed(2)} m
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.NAV_WIDTH')}: </b>${Number(f.properties['navWidth']).toFixed(2)} m
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.REAL_LIGHT')}: </b>${Number(f.properties['realLight']).toFixed(2)} m
                              <br/><b>${translate.instant('WIDGETS.BRIDGES.ARROW')}: </b>${Number(f.properties['arrow']).toFixed(2)} m`;
      if (f.properties['notes']) {
        template += `<br/><b>${translate.instant('WIDGETS.BRIDGES.NOTES')}: </b>${f.properties['notes']}`;
      }
      if (additionalData.type === BridgesType.NAVIGATIONS || additionalData.type === BridgesType.TIDE_SIMULATOR) {
        let bridgeTideString: string = translate.instant(this.NOT_AVAILABLE_LABEL);
        let bridgeTideNavigationString: string = translate.instant(this.NOT_AVAILABLE_LABEL);
        let arrow: number = 0;
        if (additionalData.type === BridgesType.NAVIGATIONS) {
          arrow = this.getBridgeArrow(bridgeTides as Array<BridgeTide>, f.id.toString());
        }
        if (additionalData.type === BridgesType.TIDE_SIMULATOR) {
          const data: Array<SerializedBridge> = bridgeTides as Array<SerializedBridge>;
          arrow = data.find((s: SerializedBridge) => {
            return s.id.toString() === f.id.toString();
          }).arrow;
        }
        if (arrow) {
          bridgeTideString = arrow === -99 ? translate.instant(this.NOT_AVAILABLE_LABEL) : Number(arrow).toFixed(2) + ' m';
          bridgeTideNavigationString = arrow === -99 ? translate.instant(this.NOT_AVAILABLE_LABEL)
            : Number(arrow - additionalData.elevation).toFixed(2) + ' m';
        }
        template = template.concat(`<br/><b>${translate.instant('WIDGETS.BRIDGES.ARROW_LEFT')}: </b>${bridgeTideString}`);
        template = template.concat(`<br/><b>${translate.instant('WIDGETS.BRIDGES.HEIGHT_LEFT')}: </b>${bridgeTideNavigationString}`);
      }
      popup.setContent(template);
      l.bindPopup(popup);
      l.on('click', () => {
        l.openPopup(center);
      });
    };
  }

  getBridgeTideClass(arrow: number): string {
    if (arrow < -50) {
      return 'bridge--disabled';
    } else if (arrow < 0.1) {
      return 'bridge--red';
    } else if (arrow < 1) {
      return 'bridge--orange';
    } else if (arrow < 1.5) {
      return 'bridge--yellow';
    } else {
      return 'bridge--normal';
    }
  }

  getBridgeStyle(bridgeData: Array<BridgeTide | SerializedBridge>, additionalData: BridgesRequest)
    : (f: Feature<MultiPolygon>) => PathOptions {
    return (f: Feature<MultiPolygon>): PathOptions => {
      let className: string = 'bridge--normal';
      let arrow: number;
      if (additionalData.type !== BridgesType.BRIDGES) {
        if (additionalData.type === BridgesType.NAVIGATIONS) {
          arrow = this.getBridgeArrow(bridgeData as Array<BridgeTide>, f.id.toString());
        }
        if (additionalData.type === BridgesType.TIDE_SIMULATOR) {
          const data: Array<SerializedBridge> = bridgeData as Array<SerializedBridge>;
          arrow = data.find((s: SerializedBridge) => {
            return s.id.toString() === f.id.toString();
          }).arrow;
        }
        if (arrow) {
          className = this.getBridgeTideClass(arrow - additionalData.elevation);
        } else {
          className = this.getBridgeTideClass(-99);
        }
      }
      return {
        color: '#e72e31',
        weight: 2,
        opacity: 1,
        className: className,
      };
    };
  }


  getBridgeTideData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<BridgeTide>> {
    const bridgeSource: WidgetDataSource = sources.find((a: WidgetDataSource) => {
      return a.sourceType === 'bridges-tide';
    });
    if (bridgeSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, bridgeSource);
      return this.http.get<DataResult<BridgeTide>>(url).pipe(
        map((bridgeTideData: DataResult<BridgeTide>) => {
          return bridgeTideData.data;
        }),
      );
    }
  }

  getBridgeTideSimulatorData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>,
                             additionalData: BridgesRequest): Observable<Array<SerializedBridge>> {
    const bridgeSource: WidgetDataSource = sources.find((a: WidgetDataSource) => {
      return a.sourceType === 'bridges-tide-simulator';
    });
    if (bridgeSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, bridgeSource);
      const tideUrl: string = this.replaceParameter(url, 'tide', (0.01 * additionalData.tide).toString());
      return this.http.get<DataResult<SerializedBridge>>(tideUrl).pipe(
        map((bridgetTideData: DataResult<SerializedBridge>) => {
          return bridgetTideData.data;
        }),
      );
    }
  }

  getBridgeTideForecastData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<BridgeTideForecast>> {
    const bridgeSource: WidgetDataSource = sources.find((a: WidgetDataSource) => {
      return a.sourceType === 'bridges-tide-forecast';
    });
    if (bridgeSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, bridgeSource);
      return this.http.get<DataResult<BridgeTideForecast>>(url).pipe(
        map((bridgetTideData: DataResult<BridgeTideForecast>) => {
          return bridgetTideData.data;
        }),
      );
    }
  }

  getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData: BridgesRequest, forecast: boolean): Observable<FeatureGroup> {
    let layers: FeatureGroup;
    let bridgeTidesObservable: Observable<Array<BridgeTide> | Array<SerializedBridge>> = of(undefined);
    if (additionalData.type === BridgesType.NAVIGATIONS) {
      if (forecast) {
        bridgeTidesObservable = this.getBridgeTideForecastData(timeMachineData, sources);
      } else {
        bridgeTidesObservable = this.getBridgeTideData(timeMachineData, sources);
      }
    }
    if (additionalData.type === BridgesType.TIDE_SIMULATOR) {
      bridgeTidesObservable = this.getBridgeTideSimulatorData(timeMachineData, sources, additionalData);
    }
    return forkJoin([this.getBridges(sources), bridgeTidesObservable]).pipe(
      map((data: Array<any>) => {
        const featureCollection: FeatureCollection = data[0];
        const bridgeTides: Array<BridgeTide> | Array<SerializedBridge> = data[1];
        const options: GeoJSONOptions = {
          style: this.getBridgeStyle(bridgeTides, additionalData),
          onEachFeature: this.addPopupToBridge(bridgeTides, additionalData),
        };
        layers = geoJSON<MultiPolygon>(featureCollection, options);
        return layers;
      }),
      catchError((err: any) => {
        throw err;
      }),
    );
  }

  getLegend(additionalData: BridgesType): MapLegend {
    if (additionalData === BridgesType.NAVIGATIONS || additionalData === BridgesType.TIDE_SIMULATOR) {
      return BRIDGES_LEGEND;
    }
  }

  getControl(additionalData: BridgesType, container: ViewContainerRef,
             factoryResolver: ComponentFactoryResolver): ComponentRef<BridgesControlComponent> {
    if (additionalData === BridgesType.NAVIGATIONS || additionalData === BridgesType.TIDE_SIMULATOR) {
      const factory: ComponentFactory<BridgesControlComponent> = factoryResolver.resolveComponentFactory(BridgesControlComponent);
      return container.createComponent(factory);
    }
  }
}
