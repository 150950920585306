import { ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TIDE_MAP_LEGEND } from '@app/modules/widgets/tide-map-widget/mock/legend';
import { TideMapRequest } from '@app/modules/widgets/tide-map-widget/models/tide-map-request';
import { TideMapType } from '@app/modules/widgets/tide-map-widget/models/tide-map-type';
import { TideControlComponent } from '@app/modules/widgets/tide-map-widget/tide-control/tide-control.component';
import { TranslateService } from '@ngx-translate/core';
import { Feature } from 'geojson';
import { FeatureGroup, Layer, LeafletMouseEvent, Popup } from 'leaflet';
import { Observable, of } from 'rxjs';

declare var L: any;

@Injectable()
export class TideMapWidgetService extends MapWidgetService {
  levelLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  private static addTideToUrl(url: string, data: TideMapRequest): string {
    return url.replace('{{tide}}', data.tide.toFixed(0)).replace('{{zoom}}', data.zoom.toFixed(0));
  }

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>,
                     additionalData: TideMapRequest, forecast?: boolean): Observable<FeatureGroup> {
    const featureGroup: FeatureGroup = new FeatureGroup();
    const source: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide-map-data';
    });
    const parsedUrl: string = TideMapWidgetService.addTideToUrl(source.rewriteUrl, additionalData);
    const translate: TranslateService = this.translateService;
    const options: any = {
      zIndex: 3,
      rendererFactory: L.canvas.tile,
      attribution: 'fabbricadigitale',
      interactive: true,
      vectorTileLayerStyles: {
        'public.DynContourlines': (properties: any, zoom: number) => {
          const width: number = properties.width;
          const color: number = properties.color;
          return {
            weight: width,
            color: color,
          };
        },
      },
      getFeatureId: (f: Feature) => {
        return f.properties.id;
      },
    };

    const layer: Layer = L.vectorGrid.protobuf(parsedUrl, options);
    layer.on('click', (event: LeafletMouseEvent) => {
      const feature: Feature = event.layer;
      layer.closePopup();
      layer['popup'] = new Popup({
        autoClose: true,
        closeOnClick: true,
      });
      const template: string = `<b>${translate.instant('WIDGETS.TIDE_MAP.FLOOD')}:</b> ${feature.properties['diff']} cm
                                <br/><span style="font-size:.9em;"><b>${translate.instant('WIDGETS.TIDE_MAP.ELEVATION')}:</b> ${feature.properties['level']} cm</span>`;
      layer['popup'].setContent(template);
      layer.bindPopup(layer['popup']);
      layer.openPopup(event.latlng);
    });
    layer.on('loading', () => {
      this.levelLoading.emit(true);
    });
    layer.on('load', () => {
      this.levelLoading.emit(false);
    });
    layer.addTo(featureGroup);
    return of(featureGroup);
  }

  public getControl(additionalData: TideMapType, container: ViewContainerRef,
                    factoryResolver: ComponentFactoryResolver): ComponentRef<TideControlComponent> {
    if (additionalData === TideMapType.SIMULATOR) {
      const factory: ComponentFactory<TideControlComponent> = factoryResolver.resolveComponentFactory(TideControlComponent);
      return container.createComponent(factory);
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const sourceUrl: string = this.addTimeMachineDataToUrl(new Date().getTime(), source);
    const url: string = TideMapWidgetService.addTideToUrl(sourceUrl, {
      tide: 200,
      zoom: 18,
    }).replace('{z}/{x}/{y}', '18/140059/93850');
    return this.http.get<any>(url);
  }

  public getLegend(): MapLegend {
    return TIDE_MAP_LEGEND;
  }
}
