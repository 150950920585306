import { Component, Injector, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MapControlBarButton, MapControlBarPanel } from '@app/features/pages/map-page/models';
import { MapPageConfig } from '@app/features/pages/map-page/models/map-page-config';
import { PageDirective } from '@app/features/pages/page.directive';
import { ArcgisWidgetService } from '@app/modules/widgets/arcgis-widget/arcgis-widget.service';
import { ArcgisSource } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { Transports } from '@app/modules/widgets/public-transport-widget/models';
import { PublicTransportDataSource } from '@app/modules/widgets/public-transport-widget/models/interfaces/public-transport-data-source';
import { PublicTransportService } from '@app/modules/widgets/public-transport-widget/public-transport.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetType } from '@app/shared/enums/widget-type';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';
import { AppConfigService } from '@services/app-config/app-config.service';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { GridService } from '@services/grid/grid.service';
import { LoaderService } from '@services/loader/loader.service';
import { RangeManagerService } from '@services/range-manager/range-manager.service';
import { SourcesService } from '@services/sources/sources.service';
import { StorageService } from '@services/storage/storage.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { AcMapComponent, MapsManagerService } from 'angular-cesium';
import * as Cesium from 'cesium';
import { ArcGisMapServerImageryProvider, Cesium3DTileset } from 'cesium';
import { cloneDeep } from 'lodash';
import { forkJoin } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-cesium-map',
  templateUrl: './cesium-map.component.html',
  styleUrls: ['./cesium-map.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CesiumMapComponent extends PageDirective implements OnInit {
  @ViewChild('map', { static: true }) cesiumMapElement: { nativeElement: string | Element; };

  currentPage: string = '3dmapstest';
  pageConfig: MapPageConfig;

  data: Array<MapControlBarPanel>;
  originalData: Array<MapControlBarPanel>;

  map: AcMapComponent;

  currentTimeMachineSelection: TimeMachineData;
  mapLayers: Map<string, any> = new Map<string, any>();
  cesiumViewer: Cesium.Viewer;
  base3dLevel: Cesium3DTileset;

  constructor(public timeMachineService: TimeMachineService,
              public storageService: StorageService,
              public sourcesService: SourcesService,
              public appConfigService: AppConfigService,
              public gridService: GridService,
              public dialog: MatDialog,
              public sanitizer: DomSanitizer,
              public loaderService: LoaderService,
              public rangeManagerService: RangeManagerService,
              public activatedRoute: ActivatedRoute,
              public configurationService: ConfigurationService,
              public mapsManagerService: MapsManagerService,
              public injector: Injector,
              public themeService: ThemeService) {
    super(timeMachineService, storageService, sourcesService, appConfigService, gridService, dialog, sanitizer, loaderService, rangeManagerService, activatedRoute);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loaderService.show();

    this.cesiumViewer = new Cesium.Viewer(this.cesiumMapElement.nativeElement, {
      scene3DOnly: true,
      selectionIndicator: false,
      baseLayerPicker: false,
      timeline: false,
      animation: false,
    });

    forkJoin([this.configurationService.getConfiguration('map-config'),
      this.configurationService.getConfiguration('map-menu'),
      this.configurationService.getUserConfiguration()])
      .subscribe((c: [Configuration, Configuration, Array<Configuration>]) => {
        this.pageConfig = JSON.parse(c[0].configuration);
        const userConfiguration: Array<Configuration> = c[2];
        if (userConfiguration && userConfiguration.length) {
          this.pageConfig.mapSettings.zoom = userConfiguration[0].zoom;
          this.pageConfig.mapSettings.center.lat = userConfiguration[0].latitude;
          this.pageConfig.mapSettings.center.lon = userConfiguration[0].longitude;
        }
        this.data = JSON.parse(c[1].configuration);
        this.originalData = cloneDeep(this.data);
        this.initMap();
        this.loaderService.hide();
      });

  }

  initMap(): void {
    this.map = this.mapsManagerService.getMap();
    const initialPosition: Cesium.Cartesian3 = Cesium.Cartesian3.fromDegrees(12.3267, 45.4146, 1500);
    const initialOrientation: Cesium.HeadingPitchRoll = Cesium.HeadingPitchRoll.fromDegrees(7.1077496389876024807, -31.987223091598949054, 0.025883251314954971306);

    const homeCameraView: any = {
      destination: initialPosition,
      orientation: {
        heading: initialOrientation.heading,
        pitch: initialOrientation.pitch,
        roll: initialOrientation.roll,
      },
    };

    this.currentTimeMachineSelection = this.timeMachineService.getCurrentSelection();

    this.cesiumViewer.scene.camera.setView(homeCameraView);
    this.cesiumViewer.terrainProvider = Cesium.createWorldTerrain();
    this.cesiumViewer.scene.globe.depthTestAgainstTerrain = true;
    this.cesiumViewer.scene.globe.enableLighting = true;

    this.base3dLevel = Cesium.createOsmBuildings();
    this.cesiumViewer.scene.primitives.add(this.base3dLevel);

  }

  toggle3dLevel(activate: boolean): void {
    if (activate) {
      this.base3dLevel = Cesium.createOsmBuildings();
      this.cesiumViewer.scene.primitives.add(this.base3dLevel);
    } else {
      this.cesiumViewer.scene.primitives.remove(this.base3dLevel);
    }
  }

  async loadMapLevel(level: MapControlBarButton): Promise<void> {
    this.loaderService.show();
    try {
      if (level.active) {
        // rimuove gli altri livelli del gruppo
        const menu: MapControlBarPanel = this.data.find((m: MapControlBarPanel) => {
          return m.fields.find((f: MapControlBarButton) => {
            return f.id === level.id;
          }) !== undefined;
        });

        await this.loadLevelData(level);
      } else {
        this.removeLevelData(level);
        // if (!this.activeLayers.find((levelA: MapControlBarButton) => levelA.type === WidgetType.GEOSERVER_WIDGET)) {
        //   this.drawnItems = featureGroup();
        // }
      }
      this.loaderService.hide();
    } catch (err) {
      this.loaderService.hide();
      throw err;
    }
  }

  async loadLevelData(level: MapControlBarButton): Promise<void> {
    switch (level.type) {
      case WidgetType.ARCGIS_WIDGET:
        const arcgisService: ArcgisWidgetService = this.injector.get('arcgis_service');
        const arcgisSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
        const arcgisProviders: Array<ArcGisMapServerImageryProvider> = await arcgisService.getCesiumLevel(this.currentTimeMachineSelection,
          arcgisSources as Array<ArcgisSource>, { levelInfo: level }).toPromise();
        const imageryLayers: any = this.cesiumViewer.imageryLayers;
        const arcgisLevel: any = imageryLayers.addImageryProvider(arcgisProviders[0]);
        this.updateLayersOnMap(level.id, arcgisLevel);
        break;
      case WidgetType.PUBLIC_TRANSPORT_WIDGET:
        const publicTransportService: PublicTransportService = this.injector.get(PublicTransportService);
        const publicTransportDataSources: Array<WidgetDataSource> = this.sourcesService.loadSourcesData(level.sources);
        publicTransportService.getCesiumLevel(this.currentTimeMachineSelection,
          publicTransportDataSources as Array<PublicTransportDataSource>, level.additionalData as Transports).pipe(takeWhile(() => {
          return level.active;
        })).subscribe((publicTransportProvider: Array<any>) => {
          publicTransportProvider.forEach((b: any) => {
            this.updateEntityOnMap(b.id, b);
          });
        });
        break;
      case WidgetType.GEOSERVER_WIDGET:
        break;
      default:
        break;
    }

  }

  updateEntityOnMap(id: string, level: any): void {
    const layer: Cesium.Entity = this.cesiumViewer.entities.getById(id);
    if (layer) {
      layer.position = level.position;
    } else {
      this.cesiumViewer.entities.add(level);
    }
  }


  updateLayersOnMap(id: string, level: any): void {
    const layer: any = this.mapLayers.get(id);
    if (layer) {
      const imageryLayers: any = this.cesiumViewer.imageryLayers;
      imageryLayers.remove(layer);
    }
    this.mapLayers.set(id, level);
  }


  removeLevelData(level: MapControlBarButton): void {
    if (level.type === WidgetType.PUBLIC_TRANSPORT_WIDGET) {
      const entitiesToRemove: Array<any> = [];
      // @ts-ignore
      this.cesiumViewer.entities._entities._array.forEach((e: any) => {
        if (e.type === level.additionalData) {
          entitiesToRemove.push(e);
        }
      });
      entitiesToRemove.forEach((e: any) => {
        const layer: Cesium.Entity = this.cesiumViewer.entities.getById(e.id);
        this.cesiumViewer.entities.remove(layer);
      });
    } else {
      const layer: any = this.mapLayers.get(level.id);
      if (layer) {
        const imageryLayers: any = this.cesiumViewer.imageryLayers;
        imageryLayers.remove(layer);
      }
    }
  }
}
