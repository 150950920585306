import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoadFlowBaseWidgetComponent } from './road-flow-base-widget.component';
import { RoadFlowBaseService } from './road-flow-base.service';

@NgModule({
  declarations: [RoadFlowBaseWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule
  ],
  providers: [RoadFlowBaseService],
  exports: [RoadFlowBaseWidgetComponent]
})
export class RoadFlowBaseModule { }
