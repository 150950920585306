import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';

@Component({
  selector: 'app-save-modal',
  templateUrl: './save-modal.component.html',
  styleUrls: ['./save-modal.component.scss'],
})
export class SaveModalComponent implements OnInit {

  @ViewChild('dashboards') dashboards: MatSelectionList;

  dashboardSelected: Array<string>;
  dashboardItems: Array<string>;
  confirmOverwrite: boolean = false;
  needOverwrite: boolean = false;
  result: string = null;

  constructor(public dialogRef: MatDialogRef<SaveModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  async ngOnInit(): Promise<void> {
    this.dashboardItems = this.data.availableDashboards;
  }

  updateForm(selected: string): void {
    this.result = selected[0];
    this.needOverwrite = true;
    this.confirmOverwrite = false;
  }

  updateText(text: string): void {
    this.result = text;
    if (this.dashboardItems.indexOf(text) !== -1) {
      this.dashboardSelected = [text];
      this.needOverwrite = true;
      this.confirmOverwrite = false;
    } else {
      this.dashboardSelected = [];
      this.needOverwrite = false;
    }
  }
}
