<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" (editData)="sendEditData()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [showThresholdAlert]="showThresholdAlert"
            [showGlobalThresholdAlert]="showGlobalThresholdAlert"
            [timeMachineOn]="timeMachineOn"
            [isFullScreen]="isFullScreen" [isEditable]="isEditable" [isLoading]="isLoading">
  <div class="heart-beat d-flex flex-row justify-content-around align-items-stretch" (resized)="handleResize($event)"
       [ngStyle]="{'font-size.px': fontSize}">
    <ng-container *ngIf="showLion">
      <div class="lions d-flex flex-row justify-content-between align-items-center" *ngIf="lionMenu && lionMenu.length">
        <ng-container *ngFor="let lion of lionMenu">
          <app-lion *ngIf="data.file" class="lion-box cursor-pointer"
                    [class.fullscreen]="isFullScreen"
                    [ngClass]="{'lion-box--past': lion.left,
                'lion-box--big': !lion.small,
                'lion-box--future': lion.right,
                'lion-box--present': (!lion.left && !lion.right)}"
                    (click)="onLionClick(lion)"
                    [file]="data.file"
                    [label]="lion.label"
                    [score]="lion.index[0].value | number:'1.1-1'"
                    [enabled]="lion.enabled"
                    [small]="lion.small"
                    [left]="(lion.left && lion.small) || (lion.right && !lion.small)"
                    [right]="(lion.right && lion.small) || (lion.left && !lion.small)"></app-lion>
        </ng-container>
      </div>
    </ng-container>
    <div class="d-flex flex-row justify-content-center align-items-center" *ngIf="showIndexes && !indexes.length">
      <h3>{{'WIDGETS.HEART_BEAT.NO_PERMISSION' | translate}}</h3>
    </div>
    <div class="indexes" [hidden]="!showIndexes || !indexes.length"
         [ngClass]="{'indexes--small': !showLion && !isFullScreen, 'indexes--fullscreen': isFullScreen || !showLion, 'indexes--no-lion': isFullScreen && !showLion}">
      <ul class="indexes__table d-flex align-items-stretch justify-content-between"
          [ngClass]="{'indexes__table--small': !showLion && !isFullScreen, 'indexes__table--fullscreen': isFullScreen || !showLion, 'indexes__table--no-lion': isFullScreen && !showLion}">
        <li *ngFor="let index of indexes | slice:1" class="index d-flex flex-row align-items-center"
            [ngClass]="{'index--small':  !showLion && !isFullScreen, 'index--fullscreen': isFullScreen || !showLion,  'index--no-lion': isFullScreen || !showLion}">
          <div class="index__value d-flex justify-content-center align-items-center"
               [ngClass]="{'index__value--disabled': (!index.enabled || !index.value)}"
               [ngStyle]="{'border-color': getCircleColor(index.value, index.enabled)}">
            <span [hidden]="!index.enabled">{{index.value | number:'1.1-1'}}</span>
          </div>
          <div class="index__label text-left pl-5 pr-2"
               [ngClass]="{'index__label--disabled': (!index.enabled || !index.value)}">{{'WIDGETS.HEART_BEAT.INDEXES.' + index.label | translate}}</div>
        </li>
      </ul>
    </div>
  </div>
</app-widget>
