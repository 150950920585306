<ng-template #popContent>
  <app-settings class="menu-popover menu-settings" [hideDashboardSettings]="hideDashboardSettings"></app-settings>
</ng-template>

<ng-template #news>
  <h6 class="mb-1">
    <strong>{{date | date:'dd/MM/yyyy'}} – UPDATES</strong>
  </h6>
  <ng-container *ngFor="let note of notes">
    <p class="notes-title mb-1">
      <strong>{{'MENU.NOTES.' + note.key | translate}}</strong>
    </p>
    <table class="news-table container mb-3">
      <tr class="row">
        <th class="col-3 notes-text">{{'MENU.NOTES.CODE' | translate}}</th>
        <th class="col-5 notes-text">{{'MENU.NOTES.TITLE' | translate}}</th>
        <th class="col-4 notes-text">{{'MENU.NOTES.NOTE' | translate}}</th>
      </tr>
      <tr *ngFor="let element of note.values" class="row">
        <td class="col-3">{{element.code}}</td>
        <td class="col-5">{{element.title}}</td>
        <td class="col-4">{{element.description}}</td>
      </tr>
    </table>
  </ng-container>
  <hr />
  <div class="logos d-flex flex-column justify-content-between ">
    <div class="d-flex flex-column text-center justify-content-center">
      <a href="https://mindicity.com" target="_blank"><img class="logos--mindicity" [src]="'assets/logo/mindicity.jpg'" alt="Mindicity" /></a>
    </div>
    <div class="d-flex flex-row justify-content-between align-items-center credits">
      <!-- <p>{{'MENU.CREDITS.PLATFORM' | translate}} <a href="https://www.fabbricadigitale.com/"  target="_blank" class="fd">fabbrica<b>digitale</b></a></p> -->
      <div class="d-flex flex-column  justify-content-start">
        <ul class="list-group list-group-flush newsItems text-left version justify-content-left">
          <li class="list-group-item small">{{'MENU.CREDITS.VERSION' | translate}}&nbsp;<strong>{{version}}</strong></li>
        </ul>
      </div>
      <div class="d-flex flex-column justify-content-end ">
        <a class="fd small" href="https://mindicity.com/credits" target="_blank">{{'MENU.CREDITS.CREDITS' | translate}}</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #timeline>
  <app-time-machine></app-time-machine>
</ng-template>

<ng-template #alerts>
  <app-alerts class="menu-popover"></app-alerts>
</ng-template>

<div class="d-flex flex-column justify-content-between align-items-center menu-elements">
  <ul class="menu">
    <ng-container *ngFor="let item of menuItems">
      <li *ngIf="!item.big"
          class="menu__item" [routerLink]="item.link" [routerLinkActive]="'active-link'"
          [routerLinkActiveOptions]="{exact:true}" [class.disabled]="!item.enabled ? true : null">
        <span> {{ 'MENU.' + item.title | translate | uppercase }} </span>
        <em class="{{ item.icon }}" [ngClass]="{'menuItem--disable': !item.enabled}"></em>
      </li>
    </ng-container>
  </ul>

  <ul class="menu">
    <ng-container *ngIf="showAlerts">
      <li class="menu__item alerts">
        <span>{{'MENU.ALERT' | translate | uppercase}}</span>
        <em
          class="icon-venice_alert" [autoClose]="'outside'" [ngbPopover]="alerts" [container]="'body'"
          [placement]="'left-bottom'"
          [ngStyle]="{'color': alertColor}"></em>
      </li>
    </ng-container>
    <li class="menu__item news">
      <span>{{'MENU.NEWS' | translate | uppercase}}</span>
      <em
        class="icon-venice_info" [autoClose]="'outside'" [ngbPopover]="news" [container]="'body'"
        [placement]="'left-bottom'"></em>
    </li>
    <li class="menu__item">
      <span>{{'MENU.SETTINGS' | translate | uppercase}}</span>
      <em
        class="icon-venice_menu" [autoClose]="'outside'" [ngbPopover]="popContent" [container]="'body'"
        [placement]="'left-bottom'"></em>
    </li>
    <ng-container *ngFor="let item of menuItems">
      <li *ngIf="item.big"
          class="menu__item menu__item--big" [routerLink]="item.link" [routerLinkActive]="'active-link'"
          [routerLinkActiveOptions]="{exact:true}" [class.disabled]="!item.enabled ? true : null">
        <span> {{ 'MENU.' + item.title | translate | uppercase }} </span>
        <em class="{{ item.icon }}" [ngClass]="{'menuItem--disable': !item.enabled}"></em>
      </li>
    </ng-container>
    <li
      class="menu__item menu__item--big" [ngClass]="{'time-machine--past': timeMachineService.past,
      'time-machine--future': timeMachineService.forecast,
      'time-machine--disabled': !timeMachineService.enabled}">
      <em *ngIf="!timeMachineService.enabled" class="icon-venice_time-machine"></em>
      <em *ngIf="timeMachineService.enabled"
          class="icon-venice_time-machine" [autoClose]="'outside'" (shown)="recordShown()" (hidden)="recordHidden()" #popOver="ngbPopover" [ngbPopover]="timeline" [container]="'body'"
          [placement]="'left-bottom'"></em>
    </li>
  </ul>

</div>
