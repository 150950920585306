import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MapPageRoutingModule } from '@app/features/pages/map-page/map-page-routing.module';
import { MapPageComponent } from '@app/features/pages/map-page/map-page.component';
import { MapPageService } from '@app/features/pages/map-page/map-page.service';
import { SharedModule } from '@app/shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { MapControlBarComponent } from './map-control-bar/map-control-bar.component';
import { MapControlSubBarComponent } from './map-control-sub-bar/map-control-sub-bar.component';
import { WindCompassComponent } from './wind-compass/wind-compass.component';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';

@NgModule({
  declarations: [MapPageComponent, MapControlBarComponent, MapControlSubBarComponent, WindCompassComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    MapPageRoutingModule,
    LeafletDrawModule,
  ],
  providers: [MapPageService],
  exports: [MapPageComponent, MapControlBarComponent],
})
export class MapPageModule {
}
