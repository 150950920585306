import { ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { ArcgisSource } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import {
  EnumData, EnumLabels,
  EventMapSource,
  EventMapSourceData,
  InfoData,
  InfoEnumData,
} from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-source';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { PUBLIC_LIGHTS_TYPES, PublicLightsType } from '@app/modules/widgets/public-lights-widget/mock/public-lights-type';
import { PublicLightsRequest } from '@app/modules/widgets/public-lights-widget/models/public-lights-request';
import { PublicLightsControlComponent } from '@app/modules/widgets/public-lights-widget/public-lights-control/public-lights-control.component';
import { TranslateService } from '@ngx-translate/core';
import { FeatureLayer, FeatureLayerOptions } from 'esri-leaflet';
import { Feature, FeatureCollection } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, LatLngExpression, Layer, Marker, PathOptions, Tooltip } from 'leaflet';
import { FeatureLayer as ClusterFeatureLayer, FeatureLayerOptions as ClusterFeatureLayerOptions } from 'esri-leaflet-cluster';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PublicLightsService extends MapWidgetService {
  levelLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadTooltipFromData(feature: Feature, source: EventMapSource): Tooltip {
    const translate: TranslateService = this.translateService;
    const dataTooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [5, -15],
    });
    let template: string = '';
    source.sourceData.infoData.forEach((i: InfoData) => {
      const property: any = feature.properties[i.value];
      if (i.type) {
        if (i.type === 'DATE') {
          template = template + `<b>${translate.instant(i.value)}:</b> ${moment(property).format(i.format) || ''}<br/>`;
        }

        if (i.type === 'ENUM') {
          const enumData: EnumData = source.sourceData.enumFields.find((item: EnumData) => item.field === i.value.toString());
          let enumValue: string = '';
          if (enumData) {
            enumValue = enumData.values.find((label: EnumLabels) => label.value === property.toString()).name || property.toString();
          }
          template = template + `<b>${translate.instant(i.value)}:</b> ${enumValue || ''}<br/>`;
        }
      } else {
        template = template + `<b>${translate.instant(i.value)}:</b> <span style="width: 200px">${property || ''}</span><br/>`;
      }
    });

    dataTooltip.setContent(template);
    return dataTooltip;
  }

  addPopupToLayer(source: EventMapSource):
    (f: Feature, l: Layer) => void {
    return (f: Feature, l: Layer): void => {
      const widgetLevel: string = source.name;
      if (source.sourceData && source.sourceData.titleLabel) {
        const label: string = f.properties[source.sourceData.titleLabel];
        if (label && label.trim() !== '') {
          l.bindTooltip(label,
            { permanent: true, direction: 'center', className: `my-labels my-labels--${widgetLevel}` },
          ).openTooltip();
        }
      }
      if (source.sourceData && source.sourceData.infoData) {
        const dataTooltip: Tooltip = this.loadTooltipFromData(f, source);
        l.bindTooltip(dataTooltip, { className: 'tooltipwrap' });
      }
    };
  }

  loadColorFromEnum(feature: Feature, source: EventMapSource): string {
    const type: PublicLightsType = PUBLIC_LIGHTS_TYPES.find((t: PublicLightsType) => {
      return t.key === feature.properties['TEMATISMO'];
    });
    if (type) {
      return type.color;
    } else {
      return '#999999';
    }
  }

  addLevelTooltip(source: EventMapSource): (feature: Feature, latlng: LatLngExpression) => Layer {
    return (feature: Feature, latlng: LatLngExpression): Layer => {
      if (source.sourceData && source.sourceData.infoData) {
        const widgetLevel: string = source.name;
        const dataTooltip: Tooltip = this.loadTooltipFromData(feature, source);
        const markerColor: string = this.loadColorFromEnum(feature, source);
        return new Marker(latlng, {
          icon: new DivIcon({
            className: `arcgis-marker arcgis-marker--${widgetLevel}`,
            html: `<div class=\'marker-pin\'></div><i style="color:${markerColor};" class=\'icon-venice ${source.sourceData.iconFont}\'></i>`,
          }),
        }).bindTooltip(dataTooltip);
      }
    };
  }

  public levelStyle(source: EventMapSource): (feature: any) => PathOptions {
    return (): PathOptions => {
      return { color: source.sourceData.style.color, weight: 2 };
    };
  }

  getSourceAdditionalData(additionalData: PublicLightsRequest): EventMapSourceData | null {
    let sourceAdditionalData: any = null;
    if (additionalData && additionalData.levelInfo && additionalData.levelInfo.overrideServer) {
      try {
        sourceAdditionalData = additionalData.levelInfo.additionalData ? JSON.parse(additionalData.levelInfo.additionalData.sourceData) : {};
      } catch (e) {
        sourceAdditionalData = null;
      }
    }
    return sourceAdditionalData;
  }

  public queryUrl(source: EventMapSource, additionalData: PublicLightsRequest): FeatureLayer {
    const sourceAdditionalData: EventMapSourceData = this.getSourceAdditionalData(additionalData);
    let ignoreRenderer: boolean = true;
    if (sourceAdditionalData && sourceAdditionalData.keepArcgisStyle) {
      ignoreRenderer = false;
    }
    const options: FeatureLayerOptions = {
      url: source.rewriteUrl,
      style: this.levelStyle(source),
      onEachFeature: this.addPopupToLayer(source),
      pointToLayer: this.addLevelTooltip(source),
      ignoreRenderer: ignoreRenderer,
    };
    if (additionalData && additionalData.filter && additionalData.filter.length) {
      let where: string = '';
      if (where !== '') {
        where += ' AND ';
      }
      additionalData.filter.forEach((f: PublicLightsType, i: number, arr: Array<PublicLightsType>) => {
        where += `TEMATISMO = '${f.key}'`;
        if (i !== arr.length - 1) {
          where += ' OR ';
        }
      });
      options.where = where;
    }
    const clusterOptions: ClusterFeatureLayerOptions = Object.assign(options, {
      iconCreateFunction: ignoreRenderer ? this.createClusterIcon(source) : false,
      showCoverageOnHover: false,
      animate: false,
      spiderfy: false,
      spiderfyOnMaxZoom: false,
      zoomToBoundsOnClick: true,
      maxClusterRadius: 120,
      disableClusteringAtZoom: 22,
    });
    const layer: ClusterFeatureLayer = new ClusterFeatureLayer(clusterOptions);
    layer.on('loading', () => {
      this.levelLoading.emit(true);
    });
    layer.on('load', () => {
      this.levelLoading.emit(false);
    });
    return layer;
  }

  createClusterIcon(source: ArcgisSource): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const total: Array<Marker> = cluster.getChildCount();
      const widgetLevel: string = source.name;
      const markers: Array<Marker> = cluster.getAllChildMarkers();
      const markerColor: string = this.loadColorFromEnum(markers[0].feature, source);
      return new DivIcon({
        className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
        html: `<div class="d-flex flex-row justify-content-center align-items-center">
                <div class="d-flex flex-column marker-pin">
                    <div style="color:${markerColor};" class=\'icon-venice ${source.sourceData.iconFont}\'><span class="marker-group__label">${total}</span></div>
                </div>
               </div>`,
      });
    };
  }

  getSingleMapLevel(timeMachineData: TimeMachineData,
                    source: EventMapSource, additionalData: PublicLightsRequest): Observable<FeatureGroup> {
    source.rewriteUrl = this.addTimeMachineDataToUrl(timeMachineData, source);

    const singleMapLayer: FeatureGroup = new FeatureGroup();

    if (source.sourceData.static) {
      return this.http.get<FeatureCollection>(source.rewriteUrl).pipe(
        map((layer: FeatureCollection) => {
          return singleMapLayer.addLayer(geoJSON(layer, {
            style: this.levelStyle(source),
            onEachFeature: this.addPopupToLayer(source),
            pointToLayer: this.addLevelTooltip(source),
          }));
        }),
      );
    } else {
      let layer: FeatureLayer;
      layer = this.queryUrl(source, additionalData);
      return of(singleMapLayer.addLayer(layer));
    }
  }

  getMapLevel(timeMachineData: TimeMachineData,
              sources: Array<ArcgisSource>, additionalData: PublicLightsRequest): Observable<FeatureGroup> {
    const mapLevel: FeatureGroup = new FeatureGroup();
    const observables: Array<Observable<FeatureGroup>> = sources.map((source: ArcgisSource) => {
      source.sourceData = JSON.parse(source.configuration).sourceData;
      return this.getSingleMapLevel(timeMachineData, source, additionalData);
    });
    return forkJoin(observables).pipe(
      map((features: Array<FeatureGroup>) => {
        features.forEach((f: FeatureGroup) => {
          f.addTo(mapLevel);
        });
        return mapLevel;
      }),
    );
  }

  public getControl(container: ViewContainerRef,
                    factoryResolver: ComponentFactoryResolver): ComponentRef<PublicLightsControlComponent> {
    const factory: ComponentFactory<PublicLightsControlComponent> = factoryResolver.resolveComponentFactory(PublicLightsControlComponent);
    return container.createComponent(factory);
  }

  public checkSource(source: EventMapSource): Observable<any> {
    const url: string = this.addTimeMachineDataToUrl(moment().valueOf(), source);
    if (source.sourceData.static) {
      return this.http.get<any>(url);
    } else {
      return this.http.get<any>(url + '?f=pjson');
    }
  }
}
