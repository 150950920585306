import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { InlineSVGModule } from 'ng-inline-svg';
import { HeartBeatWidgetComponent } from './heart-beat-widget.component';
import { HeartBeatWidgetService } from './heart-beat-widget.service';
import { LionComponent } from './lion/lion.component';

@NgModule({
  declarations: [HeartBeatWidgetComponent, LionComponent],
  imports: [CommonModule,
    WidgetModule,
    AngularResizedEventModule,
    InlineSVGModule.forRoot({ baseUrl: '/' }),
    TranslateModule],
  providers: [HeartBeatWidgetService],
  exports: [HeartBeatWidgetComponent],
})
export class HeartBeatWidgetModule {
}
