export interface Changelog {
  createdAt: Date;
  domain: string;
  id: number;
  code: string;
  title: string;
  description: string;
  type: ChangelogType;
  updatedAt: Date;
  version: string;
}

export enum ChangelogType {
  FEATURES = 'FEATURES',
  ENHANCEMENTS = 'ENHANCEMENTS',
  BUGFIX = 'BUGFIX'
}

export interface Notes {
  key: ChangelogType;
  values: Array<Changelog>;
}
