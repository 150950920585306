import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import {
  PedestrianFlowMode,
  PedestrianFlowsWidgetService,
} from '@app/modules/widgets/pedestrian-flows-widget/pedestrian-flows-widget.service';
import { FeatureGroup } from 'leaflet';

@Component({
  selector: 'app-pedestrian-flows',
  templateUrl: './pedestrian-flows-widget.component.html'
})
export class PedestrianFlowsWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  level: FeatureGroup;

  legends: Array<MapLegend>;

  constructor(public widgetService: PedestrianFlowsWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.legends = [this.widgetService.getLegend(PedestrianFlowMode.SHOW_FLOW)];
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.level) {
      this.level.remove();
    }
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources, PedestrianFlowMode.SHOW_FLOW).subscribe((level: FeatureGroup) => {
      this.level = level;
      this.map.fitBounds(this.level.getBounds());
      this.level.addTo(this.map);
      this.isLoading = false;
    });
  }
}
