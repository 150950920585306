import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { TranslateLoader } from '@ngx-translate/core';
import _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {
  }

  getTranslation(lang: string): Observable<any> {
    const apiAddress: string = `${environment.staticFilesGateway}/assets/i18n/${lang}.json`;
    const observables: Array<Observable<any>> = [];
    observables.push(this.httpClient.get(apiAddress).pipe(
      catchError(() => this.httpClient.get(`assets/i18n/${lang}.json`)),
    ));
    observables.push(this.httpClient.get(`/gateway/mcapi/manager/localizations/i18n?culture=${lang}`).pipe(
      catchError(() => this.httpClient.get(`assets/i18n/${lang}.json`)),
    ));

    return forkJoin(observables).pipe(
      map((data: Array<any>) => {
        return _.merge(data[0], data[1]);
      }),
    );
  }
}
