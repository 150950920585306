import { MapLegend, MapLegendElement } from '@app/shared/models/map-legend/map-legend';

export const SANCTIONS_LEGEND: SanctionsMapLegend = {
  title: 'SANCTIONS_NUMBER',
  type: '',
  additionalData: '',
  description: 'SANCTIONS_DESCRIPTION',
  elements: [
    {
      color: '#dc3545',
      label: 's > 25',
      limit: 0,
    },
    {
      color: '#ffc107',
      label: '11 <= s <= 25',
      limit: 25,
    },
    {
      color: '#28a745',
      label: 's <= 10',
      limit: 10,
    }],
};

export interface SanctionsMapLegend extends MapLegend {
  elements: Array<SanctionsMapLegendElement>;
}

export interface SanctionsMapLegendElement extends MapLegendElement {
  limit: number;
}
