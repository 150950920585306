import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { RoadMaintenanceWidgetComponent } from './road-maintenance-widget.component';

@NgModule({
  declarations: [RoadMaintenanceWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule
  ],
  exports: [RoadMaintenanceWidgetComponent],
})
export class RoadMaintenanceWidgetModule {
}
