import { CommonModule, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { AirQualityWidgetComponent } from '@app/modules/widgets/air-quality-widget/air-quality-widget.component';
import { AirQualityWidgetService } from '@app/modules/widgets/air-quality-widget/air-quality-widget.service';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { TranslateModule } from '@ngx-translate/core';

registerLocaleData(localeIT);

@NgModule({
  declarations: [AirQualityWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MapLegendModule,
  ],
  providers: [AirQualityWidgetService, { provide: LOCALE_ID, useValue: 'it' }],
  exports: [AirQualityWidgetComponent],
})
export class AirQualityWidgetModule {
}
