import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditOverallData } from '@app/modules/dashboard/dashboard.component';
import { EditGlobalDataModalComponent } from '@app/shared/components/modals/global-modals/action-modals/edit-global-data-modal/edit-global-data-modal.component';
import { EditIndexesModalComponent } from '@app/shared/components/modals/global-modals/action-modals/edit-indexes-modal/edit-indexes-modal.component';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { SuccessComponent } from '@app/shared/components/modals/global-modals/message-modals/success/success.component';
import { Widget } from '@app/shared/models/widgets/widget';
import { WidgetInstance } from '@app/shared/models/widgets/widget-instance';
import { LoaderService } from '@services/loader/loader.service';
import { StorageService } from '@services/storage/storage.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import {
  Configuration,
  ConfigurationAddRequest,
  ConfigurationUpdateRequest,
} from '@app/shared/models/configuration/configurationUpdateRequest';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { OrderRequestDirection, SearchRequest } from '@app/shared/models/venice-data-lake/search-request';

@Injectable()
export class GridService {

  private isGridEditable: boolean = false;
  private WEIGHT_INDEX_KEY: string = 'weight_index';

  // Observable sources
  removeWidget: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
  clearGrid: EventEmitter<boolean> = new EventEmitter<boolean>();
  addWidget: EventEmitter<void> = new EventEmitter<void>();
  gridEditable: EventEmitter<boolean> = new EventEmitter<boolean>();
  saveGrid: EventEmitter<void> = new EventEmitter<void>();
  loadGrid: EventEmitter<void> = new EventEmitter<void>();
  removeGrid: EventEmitter<void> = new EventEmitter<void>();
  gridReady: EventEmitter<boolean> = new EventEmitter<boolean>();
  hideDashboardSettings: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public storageService: StorageService,
    public loaderService: LoaderService,
    public configurationService: ConfigurationService,
    public dialog: MatDialog) {
  }

  announceHideDashboardSettings(status: boolean): void {
    this.hideDashboardSettings.emit(status);
  }

  announceClearGrid(status: boolean): void {
    this.clearGrid.emit(status);
  }

  announceAddWidget(): void {
    this.addWidget.emit();
  }

  announceGridEditable(editable: boolean): void {
    this.isGridEditable = editable;
    this.gridEditable.emit(this.isGridEditable);
  }

  announceSaveGrid(): void {
    this.saveGrid.emit();
  }

  announceLoadGrid(): void {
    this.loadGrid.emit();
  }

  announceEditIndexData(): void {
    this.editIndexData();
  }

  announceRemoveGrid(): void {
    this.removeGrid.emit();
  }

  announceEditGlobalData(): void {
    this.editGlobalData();
  }

  checkGridEditable(): boolean {
    return this.isGridEditable;
  }

  public getWidgets(): Observable<Array<Widget>> {
    const widgets: Subject<Array<Widget>> = new Subject<Array<Widget>>();
    const widgetList: Array<Widget> = [];

    setTimeout(() => {
      widgets.next(widgetList);
    }, 1000);

    return widgets;
  }

  editGlobalData(): void {
    if (this.dialog.openDialogs.length === 0) {
      this.storageService.loadCustomThresholdsWidgetsTypes().subscribe((w: Array<WidgetInstance>) => {
        const editDataRef: MatDialogRef<EditGlobalDataModalComponent, EditOverallData> = this.dialog.open(EditGlobalDataModalComponent, {
          disableClose: false,
          data: w,
        });
        editDataRef.afterClosed().pipe(switchMap((result: EditOverallData) => {
          if (result && result.id) {
            this.loaderService.show();
            return this.storageService.saveGlobalThreshold(result);
          }
        })).subscribe(() => {
          this.dialog.open(SuccessComponent, {});
          this.loaderService.hide();
        }, (error: any) => {
          this.dialog.open(FailureComponent, {});
          this.loaderService.hide();
        });
      });
    }
  }

  editIndexData(): void {
    if (this.dialog.openDialogs.length === 0) {
      const request: SearchRequest = {
        filter: {
          type: 1,
        },
        order: [
          {
            field: 'title',
            mode: OrderRequestDirection.ASC,
          },
        ],
      };
      this.configurationService.getConfigurationList(request).subscribe((widgetTypes: Array<Configuration>) => {
        const editDataRef: MatDialogRef<EditIndexesModalComponent, EditOverallData> = this.dialog.open(EditIndexesModalComponent, {
          disableClose: true,
          width: '130rem',
          data: widgetTypes,
        });
        editDataRef.afterClosed().subscribe((result: EditOverallData) => {
          if (result) {
            this.loaderService.show();
            this.configurationService.getConfiguration(this.WEIGHT_INDEX_KEY).pipe(switchMap(() => {
              return of(false);
            }), catchError((err: any) => {
              return of(true);
            })).pipe((switchMap((saveNewConfiguration: boolean) => {
              if (saveNewConfiguration) {
                const body: ConfigurationAddRequest = {
                  name: result.name,
                  title: result.title,
                  configuration: JSON.stringify(result.data),
                };
                return this.configurationService.saveConfiguration(body);
              } else {
                const body: ConfigurationUpdateRequest = {
                  title: result.title,
                  configuration: JSON.stringify(result.data),
                };
                return this.configurationService.updateConfiguration(result.name, body);
              }
            }))).subscribe(() => {
              localStorage.setItem(result.name, JSON.stringify(result));
              this.dialog.open(SuccessComponent, {});
              this.loaderService.hide();
            }, (error: any) => {
              this.dialog.open(FailureComponent, {});
              this.loaderService.hide();
            });
          }
        });
      });
    }
  }
}
