import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { BridgesWidgetService } from '@app/modules/widgets/bridges-widget/bridges-widget.service';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { BridgesControlComponent } from './bridges-control/bridges-control.component';
import { BridgesWidgetComponent } from './bridges-widget.component';


@NgModule({
  declarations: [BridgesWidgetComponent, BridgesControlComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    MapWidgetModule,
    FormsModule,
    TranslateModule,
    MapLegendModule,
  ],
  providers: [BridgesWidgetService],
  exports: [BridgesWidgetComponent],
})
export class BridgesWidgetModule {
}
