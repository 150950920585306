import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoadArrivalsService } from '@app/modules/widgets/road-arrivals-widget/road-arrivals.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoadArrivalsWidgetComponent } from './road-arrivals-widget.component';

@NgModule({
  declarations: [RoadArrivalsWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule,
  ], providers: [RoadArrivalsService],
  exports: [RoadArrivalsWidgetComponent],
})
export class RoadArrivalsWidgetModule {
}
