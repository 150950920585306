import { Component, OnInit, Input } from '@angular/core';
import { CurrentTideEntry } from '@app/modules/widgets/tide-widget/models';

@Component({
  selector: 'app-tide-viewer',
  templateUrl: './tide-viewer.component.html',
  styleUrls: ['./tide-viewer.component.scss'],
})
export class TideViewerComponent implements OnInit {

  @Input()
  currentTide: CurrentTideEntry;

  @Input()
  tideLoading: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }
}
