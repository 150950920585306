import { AfterViewInit, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { VideoFeedData } from '@app/modules/widgets/video-feed-widget/model/video-feed-data';
import { VideoFeedService } from '@app/modules/widgets/video-feed-widget/video-feed.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { VgAPI } from 'ngx-videogular';

@Component({
  selector: 'app-video-feed-widget',
  templateUrl: './video-feed-widget.component.html',
  styleUrls: ['./video-feed-widget.component.scss'],
})
export class VideoFeedWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit {

  @Input() source: any;
  @ViewChild('videoPlayer') videoPlayer: ElementRef;

  api: VgAPI;
  isPlaying: boolean = false;

  data: VideoFeedData;

  constructor(public widgetService: VideoFeedService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  onPlayerReady(api: VgAPI): void {
    this.api = api;
    this.isPlaying = true;
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    if (this.data['feed']) {
      this.source = this.data['feed'];
    } else {
      this.widgetService.loadData(timeMachineData, this.sources).subscribe((f: string) => {
        if (this.data.number) {
          this.source = f.replace('{{number}}', this.data.number.toString());
        } else {
          this.source = f;
        }
      }, (error: string) => {
        this.noData = true;
      });
    }
  }

}

