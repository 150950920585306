import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { PublicTransportWidgetComponent } from './public-transport-widget.component';
import { PublicTransportService } from './public-transport.service';
import { TimetableModalComponent } from './modals/timetable-modal/timetable-modal.component';

@NgModule({
  declarations: [PublicTransportWidgetComponent, TimetableModalComponent],
  providers: [PublicTransportService],
  imports: [
    MapWidgetModule,
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    FormsModule,
    MatTableModule,
    MapLegendModule,
  ],
  exports: [PublicTransportWidgetComponent],
})
export class PublicTransportWidgetModule {
}
