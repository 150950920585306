import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { GeocodingRequest, GeocodingRequestType } from '@app/modules/widgets/registry-widget/model/geocoding-request';
import {
  AddressResponse,
  GeocodingResponse,
  Person,
  ProcessData,
  ProcessDataError,
  ProcessDataSuccess,
  RegistryInfo,
} from '@app/modules/widgets/registry-widget/model/geocoding-response';
import { Feature, FeatureCollection, MultiLineString, MultiPolygon } from 'geojson';
import {
  FeatureGroup,
  GeoJSON,
  geoJSON,
  GeoJSON as LeafletGeoJSON,
  GeoJSONOptions,
  Icon,
  LatLng,
  Layer,
  Marker,
  PathOptions,
  Polygon,
  Popup,
  Tooltip,
} from 'leaflet';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RegistryService extends MapWidgetService {

  getGeocodeUrl(sources: Array<WidgetDataSource>, requestData: GeocodingRequest): string {
    const source: WidgetDataSource = sources.find((d: WidgetDataSource) => {
      return d.name === 'geocode-service-owners';
    });
    if (source) {
      return source.rewriteUrl
        .replace('{{latitude}}', requestData.latitude.toFixed(5))
        .replace('{{longitude}}', requestData.longitude.toFixed(5))
        .replace('{{radius}}', requestData.radius.toFixed(3));
    }
  }

  addBuildingPopup(): (f: Feature<MultiPolygon>, l: Layer) => void {
    return (f: Feature<MultiPolygon>, l: Layer): void => {
      const coordinates: Array<LatLng> = LeafletGeoJSON.coordsToLatLngs(f.geometry.coordinates, 1);
      const polygon: Polygon = new Polygon(coordinates);
      const center: LatLng = polygon.getBounds().getCenter();
      const popup: Popup = new Popup({
        autoClose: true,
        closeOnClick: false,
      });
      let template: string = '';
      for (const k in f.properties) {
        if (f.properties.hasOwnProperty(k)) {
          template = template.concat(`<b>${k}:</b> ${f.properties[k]}<br />`);
        }
      }
      popup.setContent(template);
      l.bindPopup(popup);
      l.openPopup(center);
    };
  }

  addHomesPopup(infos: RegistryInfo): (f: Feature<MultiLineString>, l: Layer) => void {
    return (f: Feature<MultiLineString>, l: Layer): void => {
    };
  }

  getRegistryInfo(source: WidgetDataSource, feature: Feature): Observable<Array<Person>> {
    const url: string = source.rewriteUrl;
    const via: string = feature.properties['CIVICO_VIA'];
    const civico: string = feature.properties['CIVICO_NUM'];
    const sub: string = feature.properties['CIVICO_SUB'];
    if (via && civico && sub) {
      const viaUrl: string = url.replace('{{streetCode}}', via);
      const civicoUrl: string = viaUrl.replace('{{streetNumber}}', civico);
      let finalUrl: string;
      if (sub !== '_') {
        finalUrl = civicoUrl.replace('{{streetSubNumber}}', sub);
      } else {
        finalUrl = civicoUrl.replace('{{streetSubNumber}}', '');
      }
      return this.http.get<Array<Person>>(finalUrl);
    }
  }

  parsePeopleTemplate(people: Array<Person>): string {
    let template: string = '';
    template = template.concat(`<br/><h6>Persone:</h6>`);
    people.forEach((p: Person) => {
      template = template + this.parsePersonTemplate(p);
    });
    return template;
  }

  parsePersonTemplate(p: Person): string {
    let template: string = '';
    template = template.concat(`<b>Nome:</b> ${p.datiAnagrafici.nome}&emsp;<b>Cognome:</b>${p.datiAnagrafici.cognome}&emsp;<b>Sesso:</b>${p.datiAnagrafici.sesso}&emsp;<b>CF:</b>${p.datiAnagrafici.codiceFiscale}<br/>`);
    template = template.concat(`<b>Età:</b> ${p.eta}&emsp;<b>Data di Nascita:</b>${p.dataNascita.giorno}/${p.dataNascita.mese}/${p.dataNascita.anno}&emsp;<b>Cittadinanza:</b>${p.cittadinanza}&emsp;<br/>`);
    template = template.concat(`<b>Luogo di Nascita:</b> ${p.luogoNascita.comune} (${p.luogoNascita.provincia})&emsp;<b>Codice:</b>${p.luogoNascita.codice}<br/>`);
    template = template.concat(`<b>Residente in via:</b> ${p.indirizzoResidenza.via}&emsp;<b>Civico:</b>${p.indirizzoResidenza.civico}&emsp;<b>Piano:</b>${p.indirizzoResidenza.piano}&emsp;<b>Cap:</b>${p.indirizzoResidenza.cap}<br/>`);
    template = template.concat(`<b>N° Famiglia:</b> ${p.nFamiglia}&emsp;<b>N° ind:</b>${p.nind}<br/>`);
    template = template.concat(`-----------<br/><br/>`);
    return template;
  }

  addMarkerHomeLayer(f: Feature<MultiLineString>, registryInfo?: RegistryInfo): FeatureGroup {
    const featureGroup: FeatureGroup = new FeatureGroup();
    const coordinates: Array<LatLng> = LeafletGeoJSON.coordsToLatLngs(f.geometry.coordinates, 1);
    const polygon: Polygon = new Polygon(coordinates);
    const center: LatLng = polygon.getBounds().getCenter();
    const icon: Icon = new Icon({
      iconUrl: `assets/img/markers/marker_buildings.png`,
      iconSize: [22, 50],
      iconAnchor: [11, 50],
    });
    const marker: Marker = new Marker(center);
    marker.setIcon(icon);
    const tooltip: Tooltip = new Tooltip({ offset: [0, -50], direction: 'top' });
    let template: string = '';
    template = template.concat(`<h6>Indirizzi:</h6>`);
    template = template.concat(`<b>Via:</b> ${f.properties['DENOMINAZIONE_X_VIA']}<br />`);
    if (f.properties['DENOMINAZIONE_X_SESTIERE']) {
      template = template.concat(`<b>Sestiere:</b> ${f.properties['DENOMINAZIONE_X_SESTIERE']}<br />`);
    }
    if (registryInfo) {
      const data: ProcessData = registryInfo[f.id];
      if (data && data.processData) {
        const processData: ProcessDataSuccess | ProcessDataError = data.processData;
        const dataSuccess: ProcessDataSuccess = processData as ProcessDataSuccess;
        if (dataSuccess.persona) {
          this.parsePeopleTemplate(dataSuccess.persona);
        }
      }
    }
    tooltip.setContent(template);
    marker.bindTooltip(tooltip);
    marker.addTo(featureGroup);
    marker.on('click', () => {
      tooltip.openPopup();
    });
    return featureGroup;
  }

  getAddressesList(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: GeocodingRequest): Observable<Array<AddressResponse>> {
    if (additionalData.type === GeocodingRequestType.ADDRESS) {
      const source: WidgetDataSource = sources.find((d: WidgetDataSource) => {
        return d.name === 'geocode-service-addresses';
      });
      if (source) {
        return this.http.post<FeatureCollection<MultiLineString>>(source.rewriteUrl, {
          address: additionalData.address,
          like: additionalData.address,
          type: additionalData.addressType,
        }).pipe(
          map((f: FeatureCollection<MultiLineString>) => {
            return f.features.map((feature: Feature<MultiLineString>) => {
              const coordinates: Array<LatLng> = LeafletGeoJSON.coordsToLatLngs(feature.geometry.coordinates, 1);
              const polygon: Polygon = new Polygon(coordinates);
              return {
                roadAddress: feature.properties['DENOMINAZIONE_X_VIA'],
                sestiereAddress: feature.properties['DENOMINAZIONE_X_SESTIERE'] ? feature.properties['DENOMINAZIONE_X_SESTIERE'] : '-',
                point: polygon.getBounds().getCenter(),
              };
            });
          }),
        );
      }
    }
  }

  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>, additionalData?: GeocodingRequest, forecast?: boolean): Observable<GeocodingResponse> {
    const url: string = this.getGeocodeUrl(sources, additionalData);
    return this.http.get<GeocodingResponse>(url);
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: GeocodingRequest): Observable<FeatureGroup> {
    const featureGroup: FeatureGroup = new FeatureGroup();
    const style2: PathOptions = {
      color: '#2ebe11',
      weight: 2,
      opacity: 1,
    };
    if (additionalData.type === GeocodingRequestType.LATITUDE) {
      const url: string = this.getGeocodeUrl(sources, additionalData);
      return this.http.get<GeocodingResponse>(url).pipe(map((response: GeocodingResponse) => {
        const style: PathOptions = {
          color: '#53A2BE',
          weight: 2,
          opacity: 1,
        };
        const buildingsOptions: GeoJSONOptions = {
          style: style,
          // onEachFeature: this.addBuildingPopup(),
        };
        const homeOptions: GeoJSONOptions = {
          style: style2,
          onEachFeature: this.addHomesPopup(response.info),
        };
        if (response.geoBuildings.features.length) {
          const buildingLayers: GeoJSON<MultiPolygon> = geoJSON<MultiPolygon>(response.geoBuildings, buildingsOptions);
          buildingLayers.addTo(featureGroup);
        }
        if (response.geoNumbers.features.length) {
          const homeLayers: GeoJSON<MultiPolygon> = geoJSON<MultiPolygon>(response.geoNumbers, homeOptions);
          homeLayers.addTo(featureGroup);
          const markerLayers: Array<FeatureGroup> = response.geoNumbers.features.map((f: Feature<MultiLineString>) => {
            return this.addMarkerHomeLayer(f, response.info);
          });
          markerLayers.forEach((f: FeatureGroup) => {
            f.addTo(featureGroup);
          });
        }
        return featureGroup;
      }));
    }
    if (additionalData.type === GeocodingRequestType.ADDRESS) {
      const source: WidgetDataSource = sources.find((d: WidgetDataSource) => {
        return d.name === 'geocode-service-addresses';
      });
      if (source) {
        const geocodingRequest: any = {
          address: additionalData.address,
          type: additionalData.addressType,
        };
        if (!additionalData.exactly) {
          geocodingRequest.like = additionalData.address;
        }
        return this.http.post<FeatureCollection<MultiLineString>>(source.rewriteUrl, geocodingRequest).pipe(
          map((response: FeatureCollection<MultiLineString>) => {
            if (response && response.features && response.features.length > 0) {
              const buildingsOptions: GeoJSONOptions = {
                style: style2,
              };
              const homeLayers: GeoJSON<MultiLineString> = geoJSON<MultiLineString>(response, buildingsOptions);
              homeLayers.addTo(featureGroup);
              const markerLayers: Array<FeatureGroup> = response.features.map((f: Feature<MultiLineString>) => {
                return this.addMarkerHomeLayer(f);
              });
              markerLayers.forEach((f: FeatureGroup) => {
                f.addTo(featureGroup);
              });
              return featureGroup;
            } else {
              throw new Error('NO_RESULTS_FOUND');
            }
          }),
        );
      }
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    if (source.name === 'geocode-service-addresses') {
      return this.http.post<FeatureCollection<MultiLineString>>(source.rewriteUrl, {
        address: 'San Marco',
        like: 'San Marco',
        type: 0,
      });
    }
    if (source.name === 'geocode-service-owners') {
      const url: string = source.rewriteUrl
        .replace('{{latitude}}', '45.440316')
        .replace('{{longitude}}', '12.315556')
        .replace('{{radius}}', '0.0001');
      return this.http.get<GeocodingResponse>(url);
    }
  }
}
