import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DataList } from '@app/shared/models/venice-data-lake/data-result';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SourcesService {
  private sources: Array<WidgetDataSource>;

  constructor(private http: HttpClient) {
  }

  public setAppSources(sources: Array<WidgetDataSource>): void {
    this.sources = sources;
  }

  public loadSourcesData(name: Array<string>): Array<WidgetDataSource> {
    return this.sources.filter((s: WidgetDataSource) => {
      return name.includes(s.name);
    });
  }

  public loadSourceData(name: string): WidgetDataSource {
    return this.sources.find((s: WidgetDataSource) => {
      return name === s.name;
    });
  }


  public loadSource(name: string): Observable<WidgetDataSource> {
    return this.http.get<Array<WidgetDataSource>>(`/gateway/mcapi/manager/sources/?rsql=name=="${name}"`).pipe(switchMap((a: Array<WidgetDataSource>) => {
      return of(a[0]);
    }));
  }

  public loadSourceById(id: number): Observable<WidgetDataSource> {
    return this.http.get<Array<WidgetDataSource>>(`/gateway/mcapi/manager/sources/?rsql=id=="${id}"`).pipe(switchMap((a: Array<WidgetDataSource>) => {
      return of(a[0]);
    }));
  }

  public loadSourceList(): Observable<Array<WidgetDataSource>> {
    const sourceListRequestBody: any = {
      fields: [],
      filter: {},
      pagination: {},
      order: [{}],
    };
    return this.http.post<DataList<WidgetDataSource>>(`/gateway/mcapi/manager/widgets-sources/sources-list-all`, sourceListRequestBody)
      .pipe(switchMap((r: DataList<WidgetDataSource>) => {
        return of(r.list);
      }));
  }

}
