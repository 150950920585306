import { AfterViewInit, Component, Injector, Input } from '@angular/core';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { AIR_QUALITY_LEGEND } from '@app/modules/widgets/air-quality-widget/data/legend';
import { AirQualityWidgetData } from '@app/modules/widgets/air-quality-widget/model/air-quality-widget-data';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { AirQualityWidgetService } from './air-quality-widget.service';

@Component({
  selector: 'app-air-quality-widget',
  templateUrl: './air-quality-widget.component.html',
  styleUrls: ['./air-quality-widget.component.scss'],
})
export class AirQualityWidgetComponent extends MapWidgetComponent implements AfterViewInit {
  @Input()
  data: AirQualityWidgetData;

  fontSize: string;
  legends: Array<MapLegend> = [AIR_QUALITY_LEGEND];

  constructor(public widgetService: AirQualityWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngAfterViewInit(): void {
    this.checkSources();
    this.pingThresholdsSubscription = this.pingThresholdsInterval.subscribe(() => {
      this.pingThresholds();
    });
  }

}
