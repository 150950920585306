import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { ArcgisSource } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import {
  ColorData, EnumData, EnumLabels,
  EventMapSource,
  EventMapSourceData,
  InfoData,
  InfoEnumData,
} from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-source';
import { EventMapRequest } from '@app/modules/widgets/event-map-widget/models/interfaces/event-map-request';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { TranslateService } from '@ngx-translate/core';
import { FeatureLayer, FeatureLayerOptions } from 'esri-leaflet';
import { Feature, FeatureCollection } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, GeoJSONOptions, LatLngExpression, Layer, Marker, PathOptions, Tooltip } from 'leaflet';
import moment from 'moment';
import { PublicWorksControlComponent } from '@app/modules/widgets/event-map-widget/public-works-control/public-works-control.component';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EventMapWidgetService extends MapWidgetService {
  loadTooltipFromData(feature: Feature, source: EventMapSource): Tooltip {
    const translate: TranslateService = this.translateService;
    const dataTooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [5, -15],
    });
    let template: string = '';
    source.sourceData.infoData.forEach((i: InfoData) => {
      const property: any = feature.properties[i.value];
      if (i.type) {
        if (i.type === 'DATE') {
          template = template + `<b>${translate.instant(i.value)}:</b> ${moment(property).format(i.format) || ''}<br/>`;
        }

        if (i.type === 'ENUM') {
          const enumData: EnumData = source.sourceData.enumFields.find((item: EnumData) => item.field === i.value.toString());
          let enumValue: string = '';
          if (enumData) {
            enumValue = enumData.values.find((label: EnumLabels) => label.value === property.toString()).name || property.toString();
          }
          template = template + `<b>${translate.instant(i.value)}:</b> ${enumValue || ''}<br/>`;
        }
      } else {
        template = template + `<b>${translate.instant(i.value)}:</b> <span style="width: 200px">${property || ''}</span><br/>`;
      }
    });

    if (!this.timeMachineService.isTimeMachineActive()) {
      template += `<br/><br/><i>${translate.instant('WIDGETS.EVENT_MAP.PUBLIC_WORKS.DEFAULT_DATA')}</i>`;
    }

    dataTooltip.setContent(template);
    return dataTooltip;
  }

  addPopupToLayer(source: EventMapSource):
    (f: Feature, l: Layer) => void {
    return (f: Feature, l: Layer): void => {
      const widgetLevel: string = source.name;
      if (source.sourceData && source.sourceData.titleLabel) {
        const label: string = f.properties[source.sourceData.titleLabel];
        if (label && label.trim() !== '') {
          l.bindTooltip(label,
            { permanent: true, direction: 'center', className: `my-labels my-labels--${widgetLevel}` },
          ).openTooltip();
        }
      }
      if (source.sourceData && source.sourceData.infoData) {
        const dataTooltip: Tooltip = this.loadTooltipFromData(f, source);
        l.bindTooltip(dataTooltip, { className: 'tooltipwrap' });
      }
    };
  }

  loadColorFromEnum(feature: Feature, source: EventMapSource): string {
    let enumValue: string = source.sourceData.style.color;
    if (source.sourceData.colorByField) {
      const colorByFieldData: ColorData = source.sourceData.colorByField;
      const property: any = feature.properties[colorByFieldData.field];
      const enumData: InfoEnumData = colorByFieldData.colors.find((item: InfoEnumData) => item.value === property.toString());
      if (enumData && enumData.color) {
        enumValue = enumData.color;
      }
    }

    return enumValue;
  }

  addLevelTooltip(source: EventMapSource): (feature: Feature, latlng: LatLngExpression) => Layer {
    return (feature: Feature, latlng: LatLngExpression): Layer => {
      if (source.sourceData && source.sourceData.infoData) {
        const widgetLevel: string = source.name;
        const dataTooltip: Tooltip = this.loadTooltipFromData(feature, source);
        const markerColor: string = this.loadColorFromEnum(feature, source);
        return new Marker(latlng, {
          icon: new DivIcon({
            className: `arcgis-marker arcgis-marker--${widgetLevel}`,
            html: `<div class=\'marker-pin\'></div><i style="color:${markerColor};" class=\'icon-venice ${source.sourceData.iconFont}\'></i>`,
          }),
        }).bindTooltip(dataTooltip);
      }
    };
  }

  public levelStyle(source: EventMapSource): (feature: any) => PathOptions {
    return (): PathOptions => {
      return { color: source.sourceData.style.color, weight: 2 };
    };
  }

  getSourceAdditionalData(additionalData: EventMapRequest): EventMapSourceData | null {
    let sourceAdditionalData: any = null;
    if (additionalData && additionalData.levelInfo && additionalData.levelInfo.overrideServer) {
      try {
        sourceAdditionalData = additionalData.levelInfo.additionalData ? JSON.parse(additionalData.levelInfo.additionalData.sourceData) : {};
      } catch (e) {
        sourceAdditionalData = null;
      }
    }
    return sourceAdditionalData;
  }

  public queryUrl(source: EventMapSource, additionalData: EventMapRequest, timeMachineData: TimeMachineData): FeatureLayer {
    const sourceAdditionalData: EventMapSourceData = this.getSourceAdditionalData(additionalData);
    let ignoreRenderer: boolean = true;
    if (sourceAdditionalData && sourceAdditionalData.keepArcgisStyle) {
      ignoreRenderer = false;
    }
    const options: FeatureLayerOptions = {
      url: source.rewriteUrl,
      style: this.levelStyle(source),
      onEachFeature: this.addPopupToLayer(source),
      pointToLayer: this.addLevelTooltip(source),
      ignoreRenderer: ignoreRenderer,
    };
    let where: string = '';
    const formatData: string = 'YYYY-MM-DD HH:mm:ss';
    if (timeMachineData) {
      if (typeof timeMachineData === 'number') {
        if (!this.timeMachineService.isTimeMachineActive()) {
          where = `DATA_SEGNALAZIONE>= DATE '${moment(timeMachineData).subtract(source.sourceData.from, 'hours').format(formatData)}'`;
        } else {
          where = `DATA_SEGNALAZIONE>= DATE '${moment(timeMachineData).subtract(source.sourceData.from, 'hours').format(formatData)}' AND DATA_SEGNALAZIONE<= DATE '${moment(timeMachineData).format(formatData)}'`;
        }
      } else if (timeMachineData instanceof TimeMachineRange) {
        const from: number = timeMachineData.getFromTimeStamp();
        const to: number = timeMachineData.getToTimeStamp();
        where = `DATA_SEGNALAZIONE>= DATE '${moment(from).format(formatData)}' AND DATA_SEGNALAZIONE<= DATE '${moment(to).format(formatData)}'`;
      }
    }
    if (additionalData && additionalData.publicWorksState) {
      const workState: number = Number(additionalData.publicWorksState);
      if (workState) {
        if (where !== '') {
          where += ' AND ';
        }
        where += `COD_STATO=${additionalData.publicWorksState}`;
      }
    }

    options.where = where;
    return new FeatureLayer(options);
  }

  getSingleMapLevel(timeMachineData: TimeMachineData,
                    source: EventMapSource, additionalData: EventMapRequest): Observable<FeatureGroup> {
    source.rewriteUrl = this.addTimeMachineDataToUrl(timeMachineData, source);
    if (source.sourceData.static) {
      return this.http.get<FeatureCollection>(source.rewriteUrl).pipe(
        map((layer: FeatureCollection) => {
          const singleMapLayer: FeatureGroup = new FeatureGroup();
          const options: GeoJSONOptions = {
            style: this.levelStyle(source),
            onEachFeature: this.addPopupToLayer(source),
            pointToLayer: this.addLevelTooltip(source),
          };
          singleMapLayer.addLayer(geoJSON(layer, options));
          return singleMapLayer;
        }),
      );
    } else {
      const singleMapLayer: FeatureGroup = new FeatureGroup();
      let layer: FeatureLayer;
      layer = this.queryUrl(source, additionalData, timeMachineData);
      singleMapLayer.addLayer(layer);
      return of(singleMapLayer);
    }
  }

  getMapLevel(timeMachineData: TimeMachineData,
              sources: Array<ArcgisSource>, additionalData: EventMapRequest): Observable<FeatureGroup> {
    const mapLevel: FeatureGroup = new FeatureGroup();
    const observables: Array<Observable<FeatureGroup>> = sources.map((source: ArcgisSource) => {
      source.sourceData = JSON.parse(source.configuration).sourceData;
      return this.getSingleMapLevel(timeMachineData, source, additionalData);
    });
    return forkJoin(observables).pipe(
      map((features: Array<FeatureGroup>) => {
        features.forEach((f: FeatureGroup) => {
          f.addTo(mapLevel);
        });
        return mapLevel;
      }),
    );
  }

  public getControl(container: ViewContainerRef,
                    factoryResolver: ComponentFactoryResolver): ComponentRef<PublicWorksControlComponent> {
    const factory: ComponentFactory<PublicWorksControlComponent> = factoryResolver.resolveComponentFactory(PublicWorksControlComponent);
    return container.createComponent(factory);
  }

  public checkSource(source: EventMapSource): Observable<any> {
    const url: string = this.addTimeMachineDataToUrl(moment().valueOf(), source);
    if (source.sourceData.static) {
      return this.http.get<any>(url);
    } else {
      return this.http.get<any>(url + '?f=pjson');
    }
  }
}
