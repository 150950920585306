import { DataIndex } from '@app/modules/widgets/heart-beat-widget/model/data-index';
import { WidgetConfigurationData } from '@app/shared/models/dashboard/serialized-grid';

export interface HeartBeatSource extends WidgetConfigurationData {
  type: HeartBeatType;
  indexes: Array<DataIndex>;
  file: string;
}

export enum HeartBeatType {
  LION = 'LION',
  INDEXES = 'INDEXES'
}
