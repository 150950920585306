<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"

            [noData]="noData">
  <div class="container">
    <div class="row video">
      <vg-player (onPlayerReady)="onPlayerReady($event)" class="player" [hidden]="!isPlaying">
        <vg-buffering></vg-buffering>
        <video [vgHls]="source"
               [vgMedia]="videoPlayer"
               #videoPlayer
               id="cameraVideo"
               preload="auto"
               controls
               [muted]="'muted'"
               autoplay>
        </video>
      </vg-player>
    </div>
  </div>
</app-widget>
