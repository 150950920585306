import { ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injectable, ViewContainerRef } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { MobilityControlComponent } from '@app/modules/widgets/mobility-map-widget/mobility-control/mobility-control.component';
import {
  MobilitySimulatorBaseLayerMapRequest, MobilitySimulatorBaseLayerTypes, MobilitySimulatorGate,
  MobilitySimulatorGateLocationCategoryItem, MobilitySimulatorGateLocationItem,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-base-data';
import {
  MobilityGateRequestAdditionalData,
  MobilitySimulatorGateWaterTrafficFlow,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-gate';
import {
  MobilityPedestrianFlowStation,
  MobilitySimulatorRequest, SimulatorResponse,
} from '@app/modules/widgets/mobility-map-widget/models/mobility-simulator';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, MultiPolygon } from 'geojson';
import { FeatureGroup, geoJSON, Layer, LeafletMouseEvent } from 'leaflet';
import moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import {
  MobilitySimulatorCategoryIndexDescriptor,
  MobilitySimulatorGateConfig,
  MobilitySimulatorLayerStyle,
} from './models/mobility-widget-data';

declare var L: any;

@Injectable()
export class MobilityMapWidgetService extends MapWidgetService {
  private DEFAULT_STATION: string = 'Punta Salute Canal Grande';

  levelLoading: EventEmitter<boolean> = new EventEmitter<boolean>();
  sensorList: FeatureCollection;
  currentGateId: string;
  timeseries: Array<Date>;
  styles: Record<MobilitySimulatorBaseLayerTypes, MobilitySimulatorLayerStyle> = {
    [MobilitySimulatorBaseLayerTypes.PEDESTRIAN]: null,
    [MobilitySimulatorBaseLayerTypes.WATER]: null,
    [MobilitySimulatorBaseLayerTypes.ROAD]: null,
  };

  getMapBaseLevel(timeMachineData: TimeMachineData,
    sources: Array<WidgetDataSource>,
    additionalData: MobilitySimulatorBaseLayerMapRequest): Observable<FeatureGroup> {
    const fg: FeatureGroup = new FeatureGroup();
    let sourceName: string;
    switch (additionalData.type) {
      case MobilitySimulatorBaseLayerTypes.PEDESTRIAN: {
        sourceName = 'mobility-simulator-pedestrian-shapes';
        break;
      }
      case MobilitySimulatorBaseLayerTypes.WATER: {
        sourceName = 'mobility-simulator-water-shapes';
        break;
      }
    }
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.name === sourceName;
    });
    return this.http.get<FeatureCollection<MultiPolygon>>(widgetDataSource.rewriteUrl).pipe(switchMap((layer: FeatureCollection<MultiPolygon>) => {
      fg.addLayer(geoJSON(layer, {
        style: (f: Feature) => {
          return {
            className: 'mobility-map-base-layer-path',
            stroke: false,
            fill: true,
            fillColor: this.styles[additionalData.type].fillColor,
            fillOpacity: additionalData.pathOptions &&
            additionalData.pathOptions.fillOpacity !== undefined ? additionalData.pathOptions.fillOpacity : this.styles[additionalData.type].fillOpacity,
          };
        },
        onEachFeature: (f: Feature<Geometry, GeoJsonProperties>, l: Layer) => {
          l.on({
            mouseover: (e: LeafletMouseEvent) => {
              if (e.target.options.fillColor !== this.styles[additionalData.type].fillColor) {
                e.target.getElement().classList.add('cursor-auto');
              }
            },
            mouseout: (e: LeafletMouseEvent) => {
              e.target.getElement().classList.remove('cursor-auto');
            },
          });
        },
      }));
      return of(fg);
    }));
  }

  getSimulationLevel(timeMachineData: TimeMachineData,
                           sources: Array<WidgetDataSource>,
                           additionalData: MobilitySimulatorRequest,
                           forecast?: boolean): Observable<SimulatorResponse> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.name === 'mobility-simulator';
    });

    const timestamp: number = typeof timeMachineData === 'number' ? timeMachineData : timeMachineData.from.valueOf();
    additionalData.simStartTS = timestamp;
    additionalData.simStopTS = moment(timestamp).add(1, 'hours').valueOf();
    return this.http.post<SimulatorResponse>(widgetDataSource.rewriteUrl, additionalData).pipe(switchMap((data: SimulatorResponse) => {
      return of(data);
    }));
  }

  public getControl(container: ViewContainerRef,
                    factoryResolver: ComponentFactoryResolver): ComponentRef<MobilityControlComponent> {
    const factory: ComponentFactory<MobilityControlComponent> = factoryResolver.resolveComponentFactory(MobilityControlComponent);
    return container.createComponent(factory);
  }

  loadSensors(timeMachineData: TimeMachineData,
              sources: Array<WidgetDataSource>, additionalData: MobilityGateRequestAdditionalData): Observable<FeatureCollection> {
    const sensorSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.name === 'pedestrian-flow-sensors';
    });
    if (sensorSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, sensorSource, 1);
      return this.http.get<FeatureCollection>(url).pipe(
        map((sensors: FeatureCollection) => {
          sensors.features = sensors.features.filter((ft: Feature) =>
            additionalData.simulatorTypeConfig.gates.find((gate: MobilitySimulatorGateConfig) => gate.featureId === ft.id));
          sensors.features.forEach((ft: Feature) => {
            const gateConfig: MobilitySimulatorGateConfig = additionalData.simulatorTypeConfig.gates.find((gate: MobilitySimulatorGateConfig) => gate.featureId === ft.id);
            const loc: Array<MobilitySimulatorGateLocationItem> = new Array<MobilitySimulatorGateLocationItem>();
            const currentGate: MobilitySimulatorGate = additionalData.currentGates ? additionalData.currentGates.find((gt: MobilitySimulatorGate) => gt.id === ft.id) : null;

            gateConfig.locations.forEach((gl: MobilitySimulatorGateLocationItem) => {
              const station: MobilityPedestrianFlowStation = ft.properties.find((s: MobilityPedestrianFlowStation) => s.direction === 'IN' && s.location === gl.id);
              const previousLocation: MobilitySimulatorGateLocationItem = currentGate ? currentGate.locations.find((l: MobilitySimulatorGateLocationItem) => l.id === gl.id) : null;
              if (previousLocation) {
                previousLocation.value = station ? station.value : null;
                loc.push(previousLocation);
              } else {
                loc.push({
                  id: gl.id,
                  label: gl.label,
                  value: station ? station.value : null,
                  categories: this.getCategoriesForOverride(additionalData.simulatorTypeConfig.indexesDescriptor.categories),
                });
              }
            });
            const stationName: string = ft.properties.find((s: MobilityPedestrianFlowStation) => s.direction === 'IN')
              ? ft.properties.find((s: MobilityPedestrianFlowStation) => s.direction === 'IN').station
              : (gateConfig.featureDefaultLabel ? this.translateService.instant('WIDGETS.MOBILITY_MAP.GATES.FEATURES.' + gateConfig.featureId) : null);
            const mge: MobilitySimulatorGate = {
              id: gateConfig.featureId,
              label: stationName,
              selected: currentGate && currentGate.selected ? currentGate.selected : null,
              locations: loc,
              type: additionalData.simulatorTypeConfig.type,
            };
            ft.properties = mge;
          });
          return sensors;
        }),
      );
    }
  }

  loadCameras(timeMachineData: TimeMachineData,
              sources: Array<WidgetDataSource>, additionalData: MobilityGateRequestAdditionalData): Observable<FeatureCollection> {
    const camerasGeoJsonSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'cameras';
    });
    const camerasCounter: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'traffic-data';
    });
    if (camerasGeoJsonSource && camerasCounter) {
      let url: string = this.addTimeMachineDataToUrl(timeMachineData, camerasCounter, 1);
      url = this.replaceParameter(url, 'zones', '&cameras=' + JSON.stringify(additionalData.simulatorTypeConfig.gates.map((g: MobilitySimulatorGateConfig) => g.featureId)));
      url = this.replaceParameter(url, 'groups', '');
      camerasGeoJsonSource.rewriteUrl = this.replaceUrl(camerasGeoJsonSource.rewriteUrl, 0, 0);
      const cameraListObservable: Observable<FeatureCollection> = this.http.get<FeatureCollection>(camerasGeoJsonSource.rewriteUrl);
      const camerasDataObservable: Observable<DataResult<MobilitySimulatorGateWaterTrafficFlow>> = this.http.get<DataResult<MobilitySimulatorGateWaterTrafficFlow>>(url);
      return forkJoin([cameraListObservable, camerasDataObservable]).pipe(
        map((data: [FeatureCollection<Geometry, GeoJsonProperties>, DataResult<MobilitySimulatorGateWaterTrafficFlow>]) => {
          const camerasList: FeatureCollection = data[0];
          const camerasData: DataResult<MobilitySimulatorGateWaterTrafficFlow> = data[1];
          camerasList.features = camerasList.features.filter((ft: Feature) =>
            additionalData.simulatorTypeConfig.gates.find((gate: MobilitySimulatorGateConfig) => gate.featureId === ft.properties.name));

          camerasList.features.forEach((ft: Feature) => {
            const gateConfig: MobilitySimulatorGateConfig = additionalData.simulatorTypeConfig.gates.find((gate: MobilitySimulatorGateConfig) => gate.featureId === ft.properties.name);
            const loc: Array<MobilitySimulatorGateLocationItem> = new Array<MobilitySimulatorGateLocationItem>();
            const currentGate: MobilitySimulatorGate = additionalData.currentGates ? additionalData.currentGates.find((gt: MobilitySimulatorGate) => gt.id === ft.properties.name) : null;

            gateConfig.locations.forEach((gl: MobilitySimulatorGateLocationItem) => {
              const counters: Array<number> = camerasData.data.filter((cdata: MobilitySimulatorGateWaterTrafficFlow) => cdata.camera === gateConfig.featureId)
                .map((cdata: MobilitySimulatorGateWaterTrafficFlow) => cdata[gl.id]);

              const previousLocation: MobilitySimulatorGateLocationItem = currentGate ? currentGate.locations.find((l: MobilitySimulatorGateLocationItem) => l.id === gateConfig.featureId + '_' + gl.id) : null;
              if (previousLocation) {
                previousLocation.value = counters.reduce((a: number, b: number) => a + b, 0);
                loc.push(previousLocation);
              } else {
                loc.push({
                  id: gateConfig.featureId + '_' + gl.id,
                  label: gl.label,
                  value: counters.reduce((a: number, b: number) => a + b, 0),
                  categories: this.getCategoriesForOverride(additionalData.simulatorTypeConfig.indexesDescriptor.categories),
                });
              }
            });

            ft.properties = {
              id: ft.properties.name,
              label: ft.properties.groupName,
              selected: currentGate && currentGate.selected ? currentGate.selected : null,
              locations: loc,
              type: additionalData.simulatorTypeConfig.type,
            };
          });
          return camerasList;
        }),
      );
    }
  }

  getGatesLevel(timeMachineData: TimeMachineData,
                sources: Array<WidgetDataSource>, additionalData: MobilityGateRequestAdditionalData): Observable<FeatureCollection> {
    switch (additionalData.simulatorTypeConfig.type) {
      case MobilitySimulatorBaseLayerTypes.PEDESTRIAN: {
        return this.loadSensors(timeMachineData, sources, additionalData);
      }
      case MobilitySimulatorBaseLayerTypes.WATER: {
        return this.loadCameras(timeMachineData, sources, additionalData);
      }
      case MobilitySimulatorBaseLayerTypes.ROAD: {
        // gatesList = await this.loadSensors(timeMachineData, sources);
        break;
      }
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    source.status = DataSourceStatus.AVAILABLE;
    return of(source);
  }

  getCategoriesForOverride(cats: Array<MobilitySimulatorCategoryIndexDescriptor>): Array<MobilitySimulatorGateLocationCategoryItem> {
    const result: Array<MobilitySimulatorGateLocationCategoryItem> = new Array<MobilitySimulatorGateLocationCategoryItem>();
    cats.filter((c: MobilitySimulatorCategoryIndexDescriptor) => c.activeAsOverride)
      .forEach((c: MobilitySimulatorCategoryIndexDescriptor) => {
        result.push({
          label: c.label,
        });
      });
    return result;
  }
}
