export interface PublicLightsType {
  key: string;
  value: string;
  color?: string;
}

export const PUBLIC_LIGHTS_TYPES: Array<PublicLightsType> = [{
  key: 'ARTISTICO',
  value: 'ARTISTICO',
  color: 'rgba(255, 81, 0, 255)'
}, {
  key: 'BOCCIA',
  value: 'BOCCIA',
  color: 'rgba(89, 184, 0, 255)'
}, {
  key: 'CALATA',
  value: 'CALATA',
  color: 'rgba(255, 213, 0, 255)'
}, {
  key: 'CANDELABRO',
  value: 'CANDELABRO',
  color: 'rgba(255, 51, 0, 255)'
}, {
  key: 'CASSETTA DERIVAZIONE',
  value: 'CASSETTA DERIVAZIONE',
  color: 'rgba(255, 162, 0, 255)'
}, {
  key: 'DEF',
  value: 'DEF',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'DIVERSO',
  value: 'DIVERSO',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'EXPORT',
  value: 'EXPORT',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'FARETTO',
  value: 'FARETTO',
  color: 'rgba(216, 240, 0, 255)'
}, {
  key: 'FUORI SERVIZIO',
  value: 'FUORI SERVIZIO',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'LANTERNA',
  value: 'LANTERNA',
  color: 'rgba(255, 187, 0, 255)'
}, {
  key: 'MANUBRIO',
  value: 'MANUBRIO',
  color: 'rgba(148, 212, 0, 255)'
}, {
  key: 'MAZZORBO',
  value: 'MAZZORBO',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'NUMERO',
  value: 'NUMERO',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'P',
  value: 'P',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'PLAFONIERA',
  value: 'PLAFONIERA',
  color: 'rgba(193, 232, 0, 255)'
}, {
  key: 'POZZETTO',
  value: 'POZZETTO',
  color: 'rgba(255, 106, 0, 255)'
}, {
  key: 'PUNTO',
  value: 'PUNTO',
  color: 'rgba(0, 0, 0, 255)'
}, {
  key: 'QUADRO',
  value: 'QUADRO',
  color: 'rgba(73, 176, 0, 255)'
}, {
  key: 'TERRA',
  value: 'TERRA',
  color: 'rgba(56, 168, 0, 255)'
}];
