import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { TimeMachineService } from '@services/time-machine/time-machine.service';
import { TideMapWidgetService } from '@app/modules/widgets/tide-map-widget/tide-map-widget.service';

@Component({
  selector: 'app-tide-control',
  templateUrl: './tide-control.component.html',
  styleUrls: ['./tide-control.component.scss'],
})
export class TideControlComponent implements OnInit {

  @Input()
  sliderValue: number;

  tideMapService: TideMapWidgetService;

  @Output()
  sliderChanged: EventEmitter<number> = new EventEmitter<number>();

  loading: boolean;

  originalTideValue: number;

  constructor(public timeMachineService: TimeMachineService, public injector: Injector) {
  }

  ngOnInit(): void {
    this.originalTideValue = this.sliderValue;
    if (this.tideMapService) {
      this.tideMapService.levelLoading.subscribe((loading: boolean) => {
        this.loading = loading;
      });
    }
  }

  updateValue(event: MatSliderChange): void {
    this.sliderValue = event.value;
  }

  setNewValue(event: MatSliderChange): void {
    this.sliderChanged.emit(this.sliderValue);
  }

  setActualTide(): void {
    this.sliderValue = this.originalTideValue;
  }
}
