import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const PUBLIC_TRANSPORT_LEGEND: MapLegend = {
  title: 'PUBLIC_TRANSPORT_TITLE',
  description: 'PUBLIC_TRANSPORT_DESCRIPTION',
  type: 'PUBLIC_TRANSPORT',
  elements: [{
    color: '#ffff00',
    label: 'r <= 3',
  }, {
    color: '#ffa500',
    label: 'r > 3',
  }],
  additionalData: null,
};
