<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            [showThresholdAlert]="showThresholdAlert"
            [showGlobalThresholdAlert]="showGlobalThresholdAlert"
            [isEditable]="isEditable" [isLoading]="isLoading" (editData)="sendEditData()"
            [timeMachineOn]="timeMachineOn"
            (sendReloadWidget)="reloadWidget()">
  <div class="map">
    <div class="base" #base *ngIf=backgroundImageUrl [ngStyle]="{'background-image': 'url('+backgroundImageUrl+')'}"></div>
    <div class="animation" #animation *ngIf=trafficImageUrl [ngStyle]="{'background-image': 'url('+trafficImageUrl+')'}"></div>
  </div>
</app-widget>
