import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventMapWidgetService } from '@app/modules/widgets/event-map-widget/event-map-widget.service';

@Component({
  selector: 'app-public-works-control',
  templateUrl: './public-works-control.component.html',
  styleUrls: ['./public-works-control.component.scss'],
})
export class PublicWorksControlComponent implements OnInit {

  eventMapService: EventMapWidgetService;

  @Output()
  filterChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  filterValue: number;

  ngOnInit(): void {
    this.filterChanged.emit(this.filterValue);
  }

  radioChange(newValue: number): void {
    this.filterChanged.emit(newValue);
  }
}
