import { Component, Injector, OnInit } from '@angular/core';
import { CurrentFlowValue, RoadFlowCurrentService } from '@app/modules/widgets/flows/road-flow-current-widget/road-flow-current.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';

@Component({
  selector: 'app-road-flow-current-widget',
  templateUrl: './road-flow-current-widget.component.html',
  styleUrls: ['./road-flow-current-widget.component.scss'],
})
export class RoadFlowCurrentWidgetComponent extends WidgetComponent implements OnInit {
  data: CurrentFlowValue;

  constructor(public widgetService: RoadFlowCurrentService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.loadData(timeMachineData, this.sources).subscribe((result: CurrentFlowValue) => {
      this.data = result;
      this.isLoading = false;
      this.noData = false;
    }, (error: any) => {
      this.isLoading = false;
      this.noData = true;
    });
  }

}
