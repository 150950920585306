import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { OrderRequestDirection, SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { Alert } from '@app/shared/models/alerts';
import { AlertInsertResponse } from '@app/shared/models/venice-data-lake/update-response';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable()
export class AlertsService {
  private alertRequest: Observable<Array<Alert>>;
  newAlerts: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient, private authenticationService: AuthenticationService) {

  }

  loadData(): Observable<Array<Alert>> {
    if (!this.alertRequest) {
      const domain: string = this.authenticationService.readUserData().dom;
      const request: SearchRequest = {
        order: [
          {
            field: 'unixstamp',
            mode: OrderRequestDirection.DESC,
          },
          {
            field: 'name.keyword',
            mode: OrderRequestDirection.DESC,
          },
        ],
        pagination: {
          from: 0,
          size: 20,
        },
      };
      if (domain) {
        this.alertRequest = this.http.post<DataResult<Alert>>(`/gateway/mcapi/events/${domain}/users/queues-list`, request).pipe(
          switchMap((d: DataResult<Alert>) => {
            return of(d.data);
          }));
      }
    }
    return this.alertRequest;
  }

  checkForNewAlerts(): void {
    this.loadData().subscribe((alerts: Array<Alert>) => {
      const alertsNotRead: Array<Alert> = alerts.filter((x: Alert) => !x.read);
      const noAlert: boolean = alertsNotRead.length === 0;
      this.newAlerts.emit(!noAlert);
    });
  }

  updateData(index: string): Observable<AlertInsertResponse> {
    const domain: string = this.authenticationService.readUserData().dom;
    if (domain) {
      const url: string = `gateway/mcapi/events/${domain}/users/readings/${index}`;
      return this.http.put<AlertInsertResponse>(url, {});
    }
  }

  marksAllRead(indexes: Array<string>): Observable<AlertInsertResponse> {
    const domain: string = this.authenticationService.readUserData().dom;
    if (domain) {
      const url: string = `gateway/mcapi/events/${domain}/users/readings`;
      return this.http.put<AlertInsertResponse>(url, indexes);
    }
  }
}
