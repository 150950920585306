import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-transports-stops-widget',
  templateUrl: './public-transports-stops-widget.component.html'
})
export class PublicTransportsStopsWidgetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
