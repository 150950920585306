<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [data]="data"
            [isEditable]="isEditable" [isLoading]="isLoading"
            [noData]="noData">
  <form name="amat-widget-form" class="amat-widget d-flex flex-column justify-content-between align-items-stretch"
        (ngSubmit)="submitForm()">
    <div class="chart" (resized)="graphResize($event)">
      <canvas baseChart id="myChart" #myChart *ngIf="barChartData.length" [datasets]="barChartData"
              [labels]="barChartLabels" [options]="barChartOptions" [chartType]="'bar'">
      </canvas>
    </div>
    <mat-accordion>
      <mat-expansion-panel #panel>
        <mat-expansion-panel-header [expandedHeight]="'0px'">
          <mat-panel-title *ngIf="!panel.expanded">
            {{'WIDGETS.AMAT.CONTROLS' | translate}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="controller d-flex flex-column justify-content-end align-items-stretch mt-4">
          <div class="controls d-flex flex-row justify-content-between align-items-start mb-1"
               *ngIf="configuration && configuration.ratios">
            <div class="default">
              <div class="labels d-flex flex-column justify-content-between align-items-end">
                <div class="p-2 text-smaller">{{'WIDGETS.AMAT.NONE' | translate}}</div>
                <div class="p-3 text-smaller">{{'WIDGETS.AMAT.IN' | translate}}</div>
                <div class="p-2 text-smaller">{{'WIDGETS.AMAT.OUT' | translate}}</div>
              </div>
            </div>
            <div class="time-columns d-flex flex-row justify-content-around align-items-center">
              <div class="d-flex flex-column justify-content-between align-items-center"
                   *ngFor="let time of times; index as i">
                <div class="d-flex flex-column justify-content-between align-items-center">
                  <span class="text-small">10</span>
                  <mat-slider class="slider slider-vertical" [name]="'configuration.ratios'+time" thumbLabel vertical
                              min="0" max="10"
                              step="0.5" [(ngModel)]="configuration.ratios[time].value"></mat-slider>
                  <span class="text-small">0</span>
                </div>
                <mat-radio-group class="d-flex flex-column justify-content-between align-items-center flex-nowrap mt-2"
                                 [name]="time" [(ngModel)]="configuration.ratios[time].type">
                  <mat-radio-button class="p-2" [value]="'none'" [labelPosition]="'before'">
                  </mat-radio-button>
                  <mat-radio-button class="p-2" [value]="'in'" [labelPosition]="'before'">
                  </mat-radio-button>
                  <mat-radio-button class="p-2" [value]="'out'" [labelPosition]="'before'">
                  </mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
          <div class="ratio-controls d-flex flex-row justify-content-between p-4"
               *ngIf="configuration && configuration.scenario">
            <div class="ratio-controls__left d-flex flex-row justify-content-start align-items-end">
              <ng-container *ngFor="let key of Object.keys(configuration.scenario)">
                <ng-container *ngIf="key !== 'total'">
                  <div class="d-flex flex-column justify-content-center align-items-start pr-3">
                    <div class="text-smaller">{{'WIDGETS.AMAT.' + (key | uppercase) | translate}}</div>
                    <div class="d-flex flex-row justify-content-start align-items-center mt-6">
                      <div class="text-small pr-1">0</div>
                      <mat-slider class="slider" min="0" max="1" step="0.1" thumbLabel
                                  [name]="'configuration.scenario'+key"
                                  [(ngModel)]="configuration.scenario[key]"></mat-slider>
                      <div class="text-small pl-1">1.0</div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
              <mat-form-field class="total pr-3">
                <mat-label>{{'WIDGETS.AMAT.TOTAL' | translate}}</mat-label>
                <input matInput [placeholder]="'WIDGETS.AMAT.TOTAL' | translate" name='total' type="number"
                       [(ngModel)]="configuration.scenario.total">
              </mat-form-field>
            </div>
            <div class="ratio-controls__right d-flex flex-row justify-content-end align-items-end">
              <button mat-raised-button color="primary" class="mr-2" type="submit"
                      (click)="resetConfig()">{{'WIDGETS.AMAT.RESET' | translate}}</button>
              <button mat-raised-button color="primary" class="mr-2"
                      type="submit" (click)="saveConfig()">{{'WIDGETS.AMAT.SAVE' | translate}}</button>
              <button mat-raised-button color="primary" type="submit"
                      class="mr-2">{{'WIDGETS.AMAT.SUBMIT' | translate}}</button>
              <button mat-raised-button color="primary" type="submit"
                      class="mr-2" (click)="panel.close()">{{'WIDGETS.AMAT.CLOSE' | translate}}</button>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-icon class="help cursor-pointer"
              (mouseover)="setOverlay(true)"
              (mouseout)="setOverlay(false)">help
    </mat-icon>
    <div class="overlay d-flex flex-column justify-content-start align-items-start" *ngIf="showOverlay">
      <p>Questa pagina offre un generatore di scenari di mobilità sulla rete della metropolitana di Milano. Il modello
        sottostante, sviluppato in collaborazione con AMAT, utilizza due tipologie di fonti di dati: database storici e
        input dell'utente. I database storici contengono dati in semi-real time relativi all'utilizzo effettivo della
        rete della metropolitana (abbonamenti, tornellati, capacità). I dati inseriti dall'utente permettono di
        controllare, e quindi modellare in senso temporale, la componente di carico della rete relativa agli studenti,
        dividendo i contributi relativi alla fase di ingresso e a quella di uscita da scuole/attività lavorative.</p>

      <p>I parametri configurabili sono:<br />
        - 'Totale' il numero totale di studenti in mobilità in una data giornata.<br />
        - 'TPL Ratio' la frazione di studenti che utilizza i mezzi pubblici.<br />
        - 'TPL Metro Ratio' la frazione di studenti del punto precedente che utilizza la metro.<br />
        - 'Scenario Ratio' la frazione di studenti del punto precedente che viene effettivamente conteggiata in mobilità
        sulla rete della metropolitana.</p>

      <p>Ogni fascia oraria è dotata di un pannello di controllo granulare che permette di impostare:<br />
        - 'None/In/Out' attribuisce la tipologia specificata alla fascia oraria.<br />
        - 'Slider' rappresenta un peso (in unità di misura arbitrarie) che determina la frazione di studenti che viene
        distribuita in quella fascia oraria.</p>

      <p>Il modello utilizza<br />
        - i parametri per calcolare il numero di studenti totali previsti in mobilità nell'arco della giornata;<br />
        - i pesi, previa normalizzazione, per calcolare la componente di studenti in mobilità nella rete metropolitana
        nelle varie fasce orarie;<br />
        - utilizza i dati storici per costruire le componenti di mobilità relativi al resto della popolazione.</p>

      <p>Il grafico mostra la capienza nominale della metropolitana, come dimensionata dal gestore, (curva blu) e le
        presenze effettive di viaggiatori, disaggregate per tipologie, (grafico a barre) aggregate su base oraria. Per i
        dettagli sulle tipologie si rimanda alla legenda del grafico stesso.</p>
    </div>
  </form>
</app-widget>
