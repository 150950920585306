import { Injectable } from '@angular/core';
import { GeoserverDataSource } from '@app/modules/widgets/geoserver-widget/models/geoserver-data-source';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import Collection from 'ol/Collection';
import BaseLayer from 'ol/layer/Base';
import LayerGroup from 'ol/layer/Group';
import ImageLayer from 'ol/layer/Image';
import { ImageWMS } from 'ol/source';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class GeoserverOlWidgetService extends WidgetService {

  public getSingleMapLevel(
    timeMachineData: TimeMachineData,
    source: GeoserverDataSource,
    additionalData?: any,
  ): Observable<BaseLayer> {
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
    return of(new ImageLayer({
      source: new ImageWMS({
        ratio: 1,
        url: `${url}`,
        params: {
          layers: `${source.sourceData.typeNs}:${source.sourceData.typeName}`,
          format: `${source.sourceData.format}`,
          transparent: `${source.sourceData.transparent}`,
          version: '1.1.1',
          attribution: `${source.sourceData.attribution}`,
          exceptions: `${source.sourceData.exceptions}`,
        },
      }),
    }));
  }

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>, additionalData?: any): Observable<LayerGroup> {
    const layerGroup: LayerGroup = new LayerGroup();
    const observables: Array<Observable<BaseLayer>> = sources
      .filter((w: WidgetDataSource) => {
        return w.sourceType === 'geoserver';
      })
      .map((source: GeoserverDataSource) => {
        source.sourceData = JSON.parse(source.configuration).sourceData;
        return this.getSingleMapLevel(timeMachineData, source, additionalData);
      });
    return forkJoin(observables).pipe(
      map((layers: Array<BaseLayer>) => {
        layers.forEach((l: BaseLayer) => {
          const layerGroupLayers: Collection<BaseLayer> = layerGroup.getLayers();
          layerGroupLayers.push(l);
          layerGroup.setLayers(layerGroupLayers);
        });
        return layerGroup;
      }),
    );
  }

  public getLegend(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): string {
    const source: GeoserverDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'geoserver';
    }) as GeoserverDataSource;
    source.sourceData = JSON.parse(source.configuration).sourceData;
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
    return `${url}?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=${source.sourceData.format}&WIDTH=20&HEIGHT=20&LAYER=${source.sourceData.typeNs}:${source.sourceData.typeName}`;
  }

}
