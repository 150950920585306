import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { AlertsSrWidgetComponent } from './alerts-sr-widget.component';

@NgModule({
  declarations: [AlertsSrWidgetComponent],
  imports: [CommonModule,
    WidgetModule],
  exports: [AlertsSrWidgetComponent],
})
export class AlertsSrWidgetModule {
}
