<div class="d-flex route-manager d-flex flex-column justify-content-center">

  <div class="locations box">

    <div *ngIf="routeItems && routeItems.length >= 2" cdkDropList (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let item of routeItems, let i = index">
        <div class="locations__item d-flex flex-row justify-content-between align-items-center"
             (click)="switchActiveItem(item)" cdkDrag>
          <button class="drag" mat-icon-button [type]="'button'" cdkDragHandle>
            <mat-icon>drag_indicator</mat-icon>
          </button>
          <mat-form-field class="locations__item__input">
            <mat-label>{{'PAGES.NAVIGATOR.SEARCH.PLACEHOLDER' | translate}}</mat-label>
            <input matInput name="search-value" (keyup.enter)="searchItem(item)"
                   (ngModelChange)="updateSearchOption($event, item)"
                   [matAutocomplete]="auto"
                   [placeholder]="'PAGES.NAVIGATOR.SEARCH.PLACEHOLDER' | translate" [(ngModel)]="item.searchValue">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                              (optionSelected)="onAutocompileSelect($event, item)">
              <mat-option *ngFor="let option of item.options" [value]="option.roadAddress">
                <b>{{'PAGES.MAP.SEARCH.ROAD' | translate}}:</b> {{option.roadAddress | titlecase }}<br/>
                <b>{{'PAGES.MAP.SEARCH.SESTIERE' | translate}}:</b> {{option.sestiereAddress | titlecase }}
              </mat-option>
            </mat-autocomplete>
            <button [hidden]="!item.active" matSuffix mat-button [type]="'button'" (click)="searchItem(item)">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <button class="remove" mat-icon-button [type]="'button'" (click)="removeItem(item)"
                  [attr.aria-label]="'Clear'"
                  [style.visibility]="(routeItems.length > 2 && item.active) ? 'visible' : 'hidden'">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <div class="d-flex flex-row justify-content-center align-items-center p-2">
      <button mat-button [type]="'button'" (click)="addItem()" [attr.aria-label]="'Add'">
        <mat-icon>add</mat-icon>
        {{'PAGES.NAVIGATOR.ADD_DESTINATION' | translate}}
      </button>
      <button mat-button [type]="'button'" (click)="refreshPath()" [attr.aria-label]="'Add'">
        <mat-icon>refresh</mat-icon>
        {{'PAGES.NAVIGATOR.REFRESH_PATH' | translate}}
      </button>
    </div>
  </div>

  <div class="route-info box d-flex justify-content-around p-4"
       *ngIf="(estimatedTime && pathLength)">
    <div class="d-flex flex-column justify-content-center align-items-center">
      <label>{{'PAGES.NAVIGATOR.ESTIMATED_TIME' | translate}}</label>
      <div class="value mt-2" *ngIf="estimatedTime">{{getTimeFromMinutes(estimatedTime)}}</div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">
      <label>{{'PAGES.NAVIGATOR.PATH_LENGTH' | translate}}</label>
      <div class="value mt-2" *ngIf="pathLength">{{getPathFromMeters(pathLength)}}</div>
    </div>
  </div>

</div>
