import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppOptions } from '@app/shared/models/app-config/app-options';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { SourcesService } from '@services/sources/sources.service';
import { UserIdleService } from 'angular-user-idle';
import { now } from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { ConfigurationService } from '@services/configuration/configuration.service';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import Timeout = NodeJS.Timeout;

@Injectable()
export class InitializerService {
  private timeout: Timeout;

  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private configurationService: ConfigurationService,
    private authenticationService: AuthenticationService,
    private appConfigService: AppConfigService,
    private router: Router,
    private sourcesService: SourcesService,
    @Inject(DOCUMENT) private document: Document) {
  }

  private getParameterByName(name: string): string | null {
    const url: string = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex: RegExp = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results: RegExpExecArray = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  private removeParamByName(sourceURL: string, key: string): string {
    let rtn: string;
    let param: string;
    let paramsArr: Array<any> = [];
    let queryString: string;
    rtn = sourceURL.split('?')[0];
    queryString = (sourceURL.indexOf('?') !== -1) ? sourceURL.split('?')[1] : '';
    if (queryString !== '') {
      paramsArr = queryString.split('&');
      for (let i: number = paramsArr.length - 1; i >= 0; i -= 1) {
        param = paramsArr[i].split('=')[0];
        if (param === key) {
          paramsArr.splice(i, 1);
        }
      }
      rtn = rtn + '?' + paramsArr.join('&');
    }
    return rtn;
  }

  public async initApp(): Promise<void> {
    this.checkForTokenInUrl();
    await this.loadToken();
    await this.loadConfig();
    this.setupTokenRefresh();
  }

  public checkForTokenInUrl(): void {
    const ticket: string = this.getParameterByName('ticket');
    if (ticket) {
      this.authenticationService.setToken(ticket);
      this.document.location.href = this.removeParamByName(window.location.href, 'ticket');
    }
  }

  public async loadToken(): Promise<void> {
    try {
      await this.authenticationService.refreshToken().toPromise();
    } catch (e) {
      this.authenticationService.clearToken();
      this.document.location.href = this.authenticationService.getLoginUrl(this.document);
    }
  }

  public loadConfig(): Promise<void> {
    const optionsLoader: Observable<Configuration> = this.configurationService.getConfiguration('options');
    const sourcesLoader: Observable<Array<WidgetDataSource>> = this.sourcesService.loadSourceList();
    const menuLoader: Observable<Configuration> = this.configurationService.getConfiguration('menu');
    return forkJoin([optionsLoader, sourcesLoader, menuLoader]).toPromise()
      .then((data: [Configuration, Array<WidgetDataSource>, Configuration]) => {
        this.appConfigService.setOptions(JSON.parse(data[0].configuration));
        this.sourcesService.setAppSources(data[1]);
        this.appConfigService.setMenu(JSON.parse(data[2].configuration));
      }, (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authenticationService.clearToken();
          this.document.location.href = this.authenticationService.getLoginUrl(this.document);
        } else {
          this.router.navigateByUrl('/error');
        }
      });
  }

  public handleTokenRefresh(): void {
    const expDate: Date = this.authenticationService.getExpirationDate();
    const expSeconds: number = expDate.valueOf() - now().valueOf() - 10000;
    if (expSeconds > 0) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.authenticationService.refreshToken().toPromise().then(() => {
          this.handleTokenRefresh();
        }, (error: any) => {
          this.sendToLogin();
        });
      }, expSeconds);
    } else {
      this.authenticationService.refreshToken().toPromise().then(() => {
        this.handleTokenRefresh();
      }, (error: any) => {
        this.sendToLogin();
      });
    }
  }

  public setupTokenRefresh(): void {
    this.handleTokenRefresh();
    this.authenticationService.logout.subscribe(() => {
      this.document.location.href = this.authenticationService.getLogoutUrl(this.document);
    });
    this.authenticationService.sendToLogin.subscribe(() => {
      this.sendToLogin();
    });
  }

  sendToLogin(): void {
    this.document.location.href = this.authenticationService.getLoginUrl(this.document);
  }
}
