import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '@services/authentication/authentication.service';
import { InsertResponse, UpdateResponse } from '@app/shared/models/venice-data-lake/update-response';
import {
  ConfigurationUpdateRequest,
  ConfigurationAddRequest,
  Configuration,
} from '@app/shared/models/configuration/configurationUpdateRequest';
import { Observable, of } from 'rxjs';
import { SearchRequest } from '@app/shared/models/venice-data-lake/search-request';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class ConfigurationService {

  private BASE_URL: string = 'gateway/mcapi/manager/';

  constructor(
    public authenticationService: AuthenticationService,
    public http: HttpClient) {
  }

  getConfigurationList(filter: SearchRequest): Observable<Array<Configuration>> {
    return this.http.post<DataResult<Configuration>>(`/gateway/mcapi/manager/configurations-list`, filter)
      .pipe(switchMap((d: DataResult<Configuration>) => {
        return of(d.data);
      }));
  }

  getConfiguration(id: string): Observable<Configuration> {
    return this.http.get<Array<Configuration>>(`/gateway/mcapi/manager/configurations?rsql=name=="${id}"`).pipe(switchMap((a: Array<Configuration>) => {
      return of(a[0]);
    }));
  }

  getUserConfiguration(): Observable<Array<Configuration>> {
    // const domain: string = this.authenticationService.getDomain();
    return this.http.get<Configuration>('gateway/mcapi/resources/groups?rsql=type=="domain"').pipe(
      switchMap((el: Configuration) => of(el)),
      catchError(() => of(null)),
    );
  }

  updateConfiguration(id: string, config: ConfigurationUpdateRequest): Observable<UpdateResponse> {
    // const domain: string = this.authenticationService.getDomain();
    return this.http.put<UpdateResponse>(`/gateway/mcapi/manager/configurations?rsql=type%3D%3D0%3Bname%3D%3D${id}`, config);
  }

  deleteConfiguration(id: string): Observable<UpdateResponse> {
    const domain: string = this.authenticationService.getDomain();
    return this.http.delete<UpdateResponse>(`${this.BASE_URL}${domain}/configurations/${id}`);
  }

  saveConfiguration(config: ConfigurationAddRequest): Observable<InsertResponse> {
    const domain: string = this.authenticationService.getDomain();
    return this.http.post<InsertResponse>(`${this.BASE_URL}${domain}/configurations`, config);
  }
}
