import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { BigMapWidgetService } from '@app/modules/widgets/big-map-widget/big-map-widget.service';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { MapDataLevel } from '@app/modules/widgets/map-widget/model/interfaces/mapWidgetData';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import {
  Control,
  DrawEvents,
  FeatureGroup,
  LatLng,
  LatLngBounds,
  LeafletMouseEvent,
  LeafletEvent,
  Point,
  Polygon,
  Rectangle,
  Layer,
} from 'leaflet';
import { forkJoin, Observable, of } from 'rxjs';
import { Alert } from '@app/shared/models/alerts';
import { MarkerList } from '@app/shared/models/map/marker-list';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import moment from 'moment';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-big-map-widget',
  templateUrl: './big-map-widget.component.html',
  styleUrls: ['./big-map-widget.component.scss'],
})
export class BigMapWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {
  control: Control.Layers;
  cLayers: FeatureGroup;
  layers: Array<FeatureGroup> = [];
  bounds: LatLngBounds = new LatLngBounds([]);
  selectedAlert: Alert;
  cameras: Array<MarkerList> = [];
  panels: Array<MarkerList> = [];
  alerts: Array<MarkerList> = [];
  geoserver: Array<MarkerList> = [];
  markerBound: Array<MarkerList> = [];
  legends: Array<MapLegend> = [];
  changeDefault: boolean = false;
  refreshBounds: boolean = true;
  overlayList: Array<OverlayLayer> = [];

  mapLayers: Map<string, FeatureGroup> = new Map<string, FeatureGroup>();

  constructor(public widgetService: BigMapWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.leafletOptions.center = new LatLng(this.data.mapConfig.center.lat, this.data.mapConfig.center.lon);
    this.leafletOptions.zoom = this.data.mapConfig.zoom;

    this.moveEnd.subscribe((e: LeafletMouseEvent) => {
      if (this.changeDefault) {
        if (this.drawnItems.getLayers().length === 0) {
          this.refreshGeoServer(this.timeMachineService.getCurrentSelection(), null);
        } else {
          this.refreshDrawLayer();
        }
      }
    });
    this.mouseDown.subscribe((e: LeafletMouseEvent) => {
      this.changeDefault = true;
    });
    this.zoom.subscribe((e: LeafletEvent) => {
      this.changeDefault = true;
    });

    this.widgetsEventBusService.alertSelected.subscribe((item: Alert) => {
      this.selectedAlert = item;
      this.legends = [{
        title: 'EVENT_SELECTED_TITLE',
        description: item.name + '-' + item.description,
        type: 'EVENT',
        elements: [],
        additionalData: moment(item.createdAt).format('DD/MM/YYYY HH:mm'),
      }];
      if (!this.hasLayer('alerts')) {
        const alertsLayer: FeatureGroup = this.mapLayers.get('alerts');
        this.updateLayer('alerts', alertsLayer);
      }
      this.refreshWidget();
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
    this.toggleDrawer();
  }

  refreshDrawLayer(): void {
    this.drawnItems.getLayers().forEach((layer: Polygon | Rectangle) => {
      const bbox: Array<LatLngBounds> = [];
      if (layer instanceof Rectangle || layer instanceof Polygon) {
        bbox.push(layer.getBounds());
        this.refreshGeoServer(this.timeMachineService.getCurrentSelection(), bbox);
      }
    });
  }

  onDrawCreated(e: DrawEvents.Created): void {
    super.onDrawCreated(e);
    const layer: any = e.layer;
    const bbox: Array<LatLngBounds> = [];
    bbox.push(layer.getBounds());

    this.refreshGeoServer(this.timeMachineService.getCurrentSelection(), bbox);
  }

  onDrawDeleted(e: DrawEvents.Deleted): void {
    super.onDrawDeleted(e);
    this.refreshGeoServer(this.timeMachineService.getCurrentSelection(), null);
  }

  onDrawEdited(e: DrawEvents.Edited): void {
    super.onDrawEdited(e);
    this.refreshDrawLayer();
  }

  selectAlertMarker(alertMarker: any, selected: boolean): void {

    const markerGeometry: string = alertMarker.feature.geometry.type;

    if (selected) {
      this.markerBound.push(alertMarker);
      if (markerGeometry === 'Linestring') {
        alertMarker.setStyle({
          color: '#B200FF',
        });
      } else if (markerGeometry !== 'Point') {
        alertMarker.setStyle({
          fillOpacity: 1,
          color: '#B200FF',
          fillColor: '#B200FF',
        });
      }
    } else {
      if (markerGeometry === 'Linestring') {
        alertMarker.setStyle({
          color: '#3388FF',
        });
      } else if (markerGeometry !== 'Point') {
        alertMarker.setStyle({
          fillOpacity: 1,
          color: '#3388FF',
          fillColor: '#3388FF',
        });
      }
    }
  }

  refreshWidget(): void {
    if (this.selectedAlert) {
      let alertMarkers: any;

      const iterator: IterableIterator<FeatureGroup> = this.mapLayers.values();
      for (const layer of iterator) {
        if (layer['id'] === 'alerts') {
          alertMarkers = layer.getLayers();
        }
      }

      if (alertMarkers && alertMarkers.length) {
        alertMarkers.forEach((alertMarker: any) => {
          if (alertMarker['id'] === this.selectedAlert.indexId) {
            this.selectAlertMarker(alertMarker, true);
          } else {
            this.selectAlertMarker(alertMarker, false);
          }
        });
      }
    }
  }

  refreshGeoServer(timeMachineData: TimeMachineData, bboxList: Array<LatLngBounds>): void {

    if (this.overlayList.length > 0) {
      const l: Array<OverlayLayer> = this.overlayList.filter((x: OverlayLayer) => x.type === 'panels' || x.type === 'geoserver-barriers' || x.type === 'cameras');
      l.forEach((ol: OverlayLayer) => {
        this.control.removeLayer(ol.layer);
        this.map.removeLayer(ol.layer);
      });
    }

    if (!bboxList) {
      bboxList = [];
      bboxList.push(this.map.getBounds());
    }

    this.loadAllLayers(timeMachineData, bboxList, true);
  }

  loadBbox(): Array<LatLngBounds> {
    const bboxList: Array<LatLngBounds> = [];
    if (!this.drawnItems.getLayers().length) {
      bboxList.push(this.map.getBounds());
    } else {
      this.drawnItems.getLayers().forEach((layer: Polygon | Rectangle) => {
        if (layer instanceof Rectangle || layer instanceof Polygon) {
          bboxList.push(layer.getBounds());
        }
      });
    }
    return bboxList;
  }

  hasLayer(id: string): boolean {
    const layer: Layer = this.mapLayers.get(id);
    if (layer) {
      return this.map.hasLayer(layer);
    }
    return false;
  }

  loadAllLayers(timeMachineData: TimeMachineData, bboxList: Array<LatLngBounds>, refresh: boolean): void {
    const observables: Array<Observable<MapLayer>> = this.data.menu.filter((l: MapDataLevel) => {
      if (l.active) {
        if (refresh) {
          if (this.hasLayer(l.id)) {
            return l.refreshable;
          }
        } else {
          return true;
        }
      }
    }).map((l: MapDataLevel) => {
      return this.loadMapDataLevel(timeMachineData, l, bboxList).pipe(
        switchMap((f: FeatureGroup) => {
          return of({
            id: l.id,
            features: f,
          });
        }), catchError((error: any) => {
          return of({
            id: null,
            features: null,
          });
        }));
    });

    if (!refresh) {
      this.isLoading = true;
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = forkJoin(observables).subscribe((f: Array<MapLayer>) => {
      this.isLoading = false;
      this.noData = false;
      f.forEach((mapLayer: MapLayer) => {
        if (mapLayer.id) {
          const featureGroup: FeatureGroup = mapLayer.features;
          featureGroup['id'] = mapLayer.id;

          this.updateLayer(mapLayer.id, featureGroup);

          this.layers.push(featureGroup);
          this.bounds.extend(featureGroup.getBounds());
        }
      });
      if (this.refreshBounds) {
        this.refreshBounds = false;
        try {
          this.map.fitBounds(this.bounds, { padding: new Point(20, 20) });
        } catch (e) {
          console.error(e);
        }
      }
    }, () => {
      this.isLoading = false;
      this.noData = true;
    });
  }

  updateLayer(id: string, layer: FeatureGroup): void {
    if (this.mapLayers) {
      const oldLayer: FeatureGroup = this.mapLayers.get(id);
      if (oldLayer) {
        this.map.removeLayer(oldLayer);
        this.control.removeLayer(oldLayer);
      }
      this.mapLayers.set(id, layer);
      this.map.addLayer(layer);
      const menuLevel: MapDataLevel = this.data.menu.find((l: MapDataLevel) => {
        return l.id === id;
      });
      if (menuLevel) {
        const label: string = this.translateService.instant(`PAGES.MAP.LABELS.${menuLevel.label}`);
        this.control.addOverlay(layer, label);
      } else {
        this.control.addOverlay(layer, id);
      }
    }
  }

  removeAllLayers(): void {
    const iterator: IterableIterator<FeatureGroup> = this.mapLayers.values();
    for (const layer of iterator) {
      this.control.removeLayer(layer);
      this.map.removeLayer(layer);
    }
    this.mapLayers = new Map<string, FeatureGroup>();
    this.selectedAlert = null;
    this.legends = [];
  }

  loadMapDataLevel(timeMachineData: TimeMachineData, l: MapDataLevel, bboxList: Array<LatLngBounds>): Observable<FeatureGroup> {
    const mapLevelService: MapWidgetService = this.injector.get(l.service);
    let filteredSources: Array<WidgetDataSource>;
    if (l.sources) {
      filteredSources = this.sources.filter((w: WidgetDataSource) => {
        return l.sources.includes(w.name);
      });
    } else {
      filteredSources = this.sources;
    }
    const additionalData: any = Object.assign(l.additionalData, {
      bbox: bboxList,
      selectedAlert: this.selectedAlert,
    });
    return mapLevelService.getMapLevel(timeMachineData, filteredSources, additionalData);
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    if (this.control) {
      this.control.remove();
    }
    this.control = new Control.Layers();
    this.control.addTo(this.map);
    const bboxList: Array<LatLngBounds> = this.loadBbox();
    this.loadAllLayers(timeMachineData, bboxList, false);
  }
}

export interface OverlayLayer {
  name: string;
  layer: Layer;
  type: string;
}

export interface MapLayer {
  id: string;
  features: FeatureGroup;
}
