import { Component, Injector, OnInit } from '@angular/core';
import { Presence, RoadArrivalsResponse, RoadArrivalsService } from '@app/modules/widgets/road-arrivals-widget/road-arrivals.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';

@Component({
  selector: 'app-road-arrivals-widget',
  templateUrl: './road-arrivals-widget.component.html',
  styleUrls: ['./road-arrivals-widget.component.scss'],
})
export class RoadArrivalsWidgetComponent extends WidgetComponent implements OnInit {

  data: RoadArrivalsResponse;
  presences: Presence;

  constructor(public widgetService: RoadArrivalsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.loadData(timeMachineData, this.sources).subscribe((roadArrivalResponse: RoadArrivalsResponse) => {
      this.isLoading = false;
      this.noData = false;
      this.data = roadArrivalResponse;
      this.presences = roadArrivalResponse.presences.find((p: Presence) => {
        return p.clusterName === 'All';
      });
    }, (error: any) => {
      this.isLoading = false;
      this.noData = true;
    });
  }
}
