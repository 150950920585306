import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { IframeWidgetComponent } from './iframe-widget.component';
import { IframeWidgetService } from './iframe-widget.service';



@NgModule({
  declarations: [IframeWidgetComponent],
  imports: [
    CommonModule,
    TranslateModule,
    WidgetModule,
    AngularResizedEventModule
  ],
  providers: [IframeWidgetService],
  exports: [IframeWidgetComponent]
})
export class IframeWidgetModule { }
