import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppConfigService } from '@services/app-config/app-config.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss'],
})
export class MenuBarComponent implements OnInit {
  @Output()
  popOverShown: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  popOverHidden: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleMenuClass: string;
  baseImgUrl: string;

  constructor(
    private appConfigService: AppConfigService) {
  }

  ngOnInit(): void {
    this.baseImgUrl = 'assets/img/' + this.appConfigService.getOptions().logo;
  }

  toggleMenu($event: MouseEvent): void {
    this.toggleMenuClass = $event.type === 'mouseover' ? 'openMenu' : 'closeMenu';
  }

  recordShown(): void {
    this.popOverShown.emit(true);
  }

  recordHidden(): void {
    this.popOverHidden.emit(false);
  }
}
