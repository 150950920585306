<h1 mat-dialog-title>{{'MENU.MODALS.EDIT_GLOBAL.TITLE' | translate}}</h1>
<p class="mt-1 mb-4">{{'MENU.MODALS.EDIT_GLOBAL.SELECT_WIDGET' | translate}}</p>

<ng-select class="search__select"
  [items]="data"
  [multiple]="false"
  [closeOnSelect]="true"
  [hideSelected]="false"
  [clearable]="false"
  bindLabel="title"
  (change)="selectWidget($event)"
  [(ngModel)]="selectedLevel">
  <ng-template ng-option-tmp  let-item="item" >
    <div class="selected-item" >
      <div class="d-flex selected-item__label">
          <span class="item">
              <span class="label">{{item.title}}</span></span>
        </div>
    </div>
  </ng-template>

</ng-select>

<!-- <mat-button-toggle-group  >
  <mat-button-toggle class="widget-button" *ngFor="let widget of data" [value]="widget.name" (click)="selectWidget(widget)"
          [ngClass]="{'widget--active': activeWidgetType === widget}">
    {{widget.title}}
  </mat-button-toggle>
</mat-button-toggle-group> -->
<div class="form" *ngIf="activeWidgetType">
  <json-schema-form
    class="json-schema-form"
    language="it"
    [form]="thresholdsSchema"
    framework="material-design"
    [(ngModel)]="thresholdsSchema.data"
    [ngModelOptions]="{standalone: true}"
    [widgets]="widgets"
>
  </json-schema-form>
  <div class="d-flex flex-row justify-content-end align-items-stretch">
    <button mat-raised-button color="warn" class="mr-2" (click)="cancel()">{{'COMMONS.CANCEL' | translate}}</button>
    <button mat-raised-button color="primary" type="submit"
            (click)="submit(thresholdsSchema.data)">{{'COMMONS.OK' | translate}}</button>
  </div>
</div>
