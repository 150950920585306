import { Injectable } from '@angular/core';
import { AuthenticationService, User } from '@services/authentication/authentication.service';
import { AppOptions, SpecialPermissionConfig } from '@app/shared/models/app-config/app-options';
import { MenuVoice, SubMenu } from '@app/shared/models/menu/menu-voice';

@Injectable()
export class AppConfigService {
  private error: string = 'Config file not loaded';
  private options: AppOptions;
  private menu: Array<MenuVoice>;

  constructor(private authenticationService: AuthenticationService) {
  }

  private isVoiceVisible(x: MenuVoice | SubMenu, userData: User): boolean {
    let userRoles: Array<string> = [];
    let userPrivileges: Array<string> = [];
    if (userData.roles) {
      userRoles = this.isString(userData.roles) ? [userData.roles as string] : (userData.roles as Array<string>);
    }
    if (userData.privileges) {
      userPrivileges = this.isString(userData.privileges) ? [userData.privileges as string] : (userData.privileges as Array<string>);
    }
    let menuRole: Array<string> = [];
    let menuPrivileges: Array<string> = [];
    if (x.roles) {
      menuRole = x.roles.filter((y: string) => {
        return userRoles.indexOf(y) !== -1;
      });
    }
    if (x.privileges) {
      menuPrivileges = x.privileges.filter((y: string) => {
        return userPrivileges.indexOf(y) !== -1;
      });
    }
    return menuRole.length > 0 || menuPrivileges.length > 0;
  }

  setMenu(menu: Array<MenuVoice>): void {
    this.menu = menu;
  }

  getMenu(): Array<MenuVoice> {
    if (!this.menu) {
      throw Error('Menu not loaded');
    }
    const userData: User = this.authenticationService.readUserData();

    return this.menu.filter((x: MenuVoice) => {
      return this.isVoiceVisible(x, userData);
    });
  }

  setOptions(options: AppOptions): void {
    this.options = options;
  }

  getOptions(): AppOptions {
    if (!this.options) {
      throw Error('Options not loaded');
    }
    return this.options;
  }

  isString(obj: string | Array<string>): boolean {
    return typeof obj === 'string';
  }

  getPageMenu(pathUrl: string): MenuVoice {
    if (!this.menu) {
      throw Error(this.error);
    }
    return this.menu.find((m: MenuVoice) => {
      return m.id === pathUrl;
    });
  }

  subMenu(title: string): Array<SubMenu> {
    if (!this.menu) {
      throw Error(this.error);
    }
    const userData: User = this.authenticationService.readUserData();

    const data: MenuVoice = this.menu.find((s: MenuVoice) => {
      return s.title === title;
    });
    if (!data) {
      throw Error(this.error);
    }
    let subMenuList: Array<SubMenu>;
    subMenuList = data.subMenu.filter((z: SubMenu) => {
      return this.isVoiceVisible(z, userData);
    });
    if (subMenuList.length > 0) {
      const submenuActive: SubMenu = subMenuList.find((s: SubMenu) => {
        return s.active === true;
      });
      if (!submenuActive) {
        subMenuList[0].active = true;
      }
    }
    return subMenuList;
  }

  getSpecialPermissionConfig(id: string): SpecialPermissionConfig | undefined {
    if (this.options.specialPermissions) {
      return this.options.specialPermissions.find((s: SpecialPermissionConfig) => {
        return s.id === id;
      });
    }
  }
}
