import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { MessagePanelsWidgetComponent } from '@app/modules/widgets/message-panels-widget/message-panels-widget.component';
import { MessagePanelsService } from '@app/modules/widgets/message-panels-widget/message-panels.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';


@NgModule({
  declarations: [MessagePanelsWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule,
    WidgetModule,
    LeafletModule,
    LeafletDrawModule,
  ], exports: [MessagePanelsWidgetComponent],
  providers: [MessagePanelsService,
    { provide: 'message_panels_widget_service', useClass: MessagePanelsService }],
})
export class MessagePanelsWidgetModule {
}
