import { EventEmitter, Injectable } from '@angular/core';
import { Alert } from '@app/shared/models/alerts';
import { circle, Circle, Map, LatLng, LatLngBounds, latLngBounds, polygon, Polygon, Polyline, polyline } from 'leaflet';
import { MarkerList } from '@app/shared/models/map/marker-list';
@Injectable()
export class WidgetsEventBusService {
  maps: Array<Map> = [];
  selectedAlert: Alert;
  alertSelected: EventEmitter<Alert> = new EventEmitter<Alert>();

  unSyncMaps(): void {
    this.maps.forEach((map1: SyncedMap) => {
      this.maps.forEach((map2: SyncedMap) => {
        if (map1.isSynced(map2)) {
          map1.unSync(map2);
        }
      });
    });
    this.maps = [];
  }

  syncMap(leafletMap: Map): void {
    this.maps.push(leafletMap);
    this.maps.forEach((map1: SyncedMap) => {
      this.maps.forEach((map2: SyncedMap) => {
        if (!map1.isSynced(map2)) {
          map1.sync(map2);
        }
      });
    });
  }

  centerAllMap(alert: Alert, selectedMarker: Array<MarkerList>): void {
    this.maps.forEach((map1: SyncedMap) => {
      this.centerOnMap(alert, map1, selectedMarker);
    });
  }

  centerOnMap(alert: Alert, map: Map, selectedMarker: Array<MarkerList>): LatLngBounds {
    const markersGroup: Array<LatLng> = [];
    let newBounds: LatLngBounds;

    if (selectedMarker) {
      selectedMarker.forEach((o: MarkerList) => {
        if (o) {
          try {
            markersGroup.push(o.markerOnMap.getLatLng());
          } catch (error) {
            markersGroup.push(o.markerOnMap.getLatLngs());
          }
        }
      });
      newBounds = latLngBounds(markersGroup);
    } else {
      if (alert.position) {
        if (alert.position.type === 'Point') {
          const position: LatLng = new LatLng(alert.position.coordinates[1], alert.position.coordinates[0]);
          if (!alert.radius) {
            markersGroup.push(position);
            newBounds = latLngBounds(markersGroup);
          } else {
            const c: Circle<any> = circle(position, { radius: alert.radius });
            c.addTo(map);
            const bounds: LatLngBounds = c.getBounds();
            map.removeLayer(c);
            newBounds = bounds;
          }
          // map.setView(new LatLng(alert.position.coordinates[1], alert.position.coordinates[0]), 14);
        } else {
          const pol: Polygon<any> = polygon(alert.position.coordinates);
          const bounds: LatLngBounds = pol.getBounds();
          const southWest: LatLng = bounds.getSouthWest();
          const northEast: LatLng = bounds.getNorthEast();
          const cSouthWest: LatLng = new LatLng(southWest.lng, southWest.lat);
          const cNortEast: LatLng = new LatLng(northEast.lng, northEast.lat);
          markersGroup.push(cSouthWest);
          markersGroup.push(cNortEast);

          newBounds = latLngBounds(markersGroup);
        }
      }

      if (alert.shape && alert.shape.type === 'LineString') {
        const pol: Polyline<any> = polyline(alert.shape.coordinates);
        const bounds: LatLngBounds = pol.getBounds();
        const southWest: LatLng = bounds.getSouthWest();
        const northEast: LatLng = bounds.getNorthEast();
        const cSouthWest: LatLng = new LatLng(southWest.lng, southWest.lat);
        const cNortEast: LatLng = new LatLng(northEast.lng, northEast.lat);
        markersGroup.push(cSouthWest);
        markersGroup.push(cNortEast);

        newBounds = latLngBounds(markersGroup);
      }
    }

    map.fitBounds(newBounds);
    return newBounds;
  }

  selectAlert(alert: Alert): void {
    this.selectedAlert = alert;
    this.alertSelected.emit(alert);
  }

  resetMapList(): void {
    this.maps = [];
  }
}

export interface SyncOptions {
  noInitialSync: boolean;
  syncCursor: boolean;
}

export interface SyncedMap extends Map {
  sync(leafletMap: SyncedMap, options?: SyncOptions): void;

  unSync(leafletMap: SyncedMap): void;

  isSynced(leafletMap?: SyncedMap): boolean;
}
