import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TrafficImageWidgetComponent } from './traffic-image-widget.component';
import { TrafficImageService } from './traffic-image.service';


@NgModule({
  declarations: [TrafficImageWidgetComponent],
  imports: [
    WidgetModule,
    CommonModule,
  ], exports: [
    TrafficImageWidgetComponent,
  ], providers: [TrafficImageService],
})
export class TrafficImageWidgetModule {
}
