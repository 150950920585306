import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { SerializedDataIndex } from '@app/modules/widgets/heart-beat-widget/model';
import { IndicesResponse } from '@app/modules/widgets/heart-beat-widget/model/indices-response';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { WidgetType } from '@app/shared/enums/widget-type';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HeartBeatWidgetService extends WidgetService {
  widgetType: WidgetType = WidgetType.HEART_BEAT_WIDGET;
  schemaUrl: string = 'assets/schemas/heartbeat-widget.json';

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>,
                  additionalData: Array<number>, forecast?: boolean): Observable<IndicesResponse> {
    const mainSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'indicators';
    });
    const futureSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'indicators-forecast';
    });
    if (mainSource) {
      let nowUrl: string;
      let pastUrl: string;
      let futureUrl: string;
      if (this.timeMachineService.forecast && futureSource) {
        nowUrl = this.addTimeMachineDataToUrl(timeMachineData, futureSource, 0.25);
      } else {
        nowUrl = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 0.25);
      }
      if (this.timeMachineService.checkForecast(timeMachineData, -12) && futureSource) {
        pastUrl = this.addTimeMachineDataToUrl(timeMachineData, futureSource, 0, -12);
      } else {
        pastUrl = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 0, -12);
      }
      if (this.timeMachineService.checkForecast(timeMachineData, 4) && futureSource) {
        futureUrl = this.addTimeMachineDataToUrl(timeMachineData, futureSource, 0, 4);
      } else {
        futureUrl = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 0, 4);
      }

      const presentObservable: Observable<SerializedDataIndex> = this.http.get<DataResult<SerializedDataIndex>>(nowUrl)
        .pipe(map((d: DataResult<SerializedDataIndex>) => {
          return d.data[0];
        }));
      const pastObservable: Observable<SerializedDataIndex> = this.http.get<DataResult<SerializedDataIndex>>(pastUrl)
        .pipe(map((d: DataResult<SerializedDataIndex>) => {
          return d.data[0];
        }));
      const futureObservable: Observable<SerializedDataIndex> = this.http.get<DataResult<SerializedDataIndex>>(futureUrl)
        .pipe(map((d: DataResult<SerializedDataIndex>) => {
          return d.data[0];
        }));

      return forkJoin([presentObservable, pastObservable, futureObservable]).pipe(
        map((indexesData: Array<any>) => {
          return {
            past: indexesData[1],
            present: indexesData[0],
            future: indexesData[2],
          };
        }),
      );

    }
  }

  getColorFill(value: number, active: boolean): string {
    if (active && value) {
      if (value >= 0 && value <= 4) {
        return '#f7a0a3';
      } else if (value > 4 && value <= 7) {
        return '#fcd5a8';
        // } else if (value > 6 && value <= 7.5) {
        //   return '#FFFF99';
      } else if (value > 7 && value <= 10.0) {
        return '#d5efd9';
      }
    } else {
      return '#808080';
    }
  }

  public checkThresholds(timeMachineData: TimeMachineData): Observable<DataResult<WidgetEvent>> {
    return this.storageService.checkWidgetThresholds(timeMachineData, 'heartbeat');
  }
}
