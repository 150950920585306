L.TileLayer.BetterTimeDimension = L.TimeDimension.Layer.WMS.extend({

  _requestTimeDimensionFromCapabilities: function() {
    if (this._capabilitiesRequested) {
      return;
    }
    this._capabilitiesRequested = true;
    let url = this._getCapabilitiesUrl();
    if (this._proxy) {
      url = this._proxy + '?url=' + encodeURIComponent(url);
    }
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xml',
      },
    }).then(response => response.text()).then(data => {
      if (data !== null) {
        const xml = $.parseXML(data);
        this._defaultTime = Date.parse(this._getDefaultTimeFromCapabilities(xml));
        this._setDefaultTime = this._setDefaultTime || (this._timeDimension && this._timeDimension.getAvailableTimes().length == 0);
        this.setAvailableTimes(this._parseTimeDimensionFromCapabilities(xml));
        if (this._setDefaultTime && this._timeDimension) {
          this._timeDimension.setCurrentTime(this._defaultTime);
        }
      }
    });
  },

});


L.tileLayer.betterTimeDimension = function(layer, options) {
  return new L.TileLayer.BetterTimeDimension(layer, options);
};
