import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { AngularResizedEventModule } from 'angular-resize-event';

@NgModule({
  declarations: [MapWidgetComponent],
  imports: [CommonModule, WidgetModule, LeafletModule, LeafletDrawModule, MapLegendModule, AngularResizedEventModule],
  exports: [MapWidgetComponent, AngularResizedEventModule],
  providers: [MapWidgetService],
})
export class MapWidgetModule {
}
