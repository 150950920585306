import { AfterViewInit, Component, DoCheck, Injector, OnDestroy, OnInit } from '@angular/core';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { ArcgisSource } from '@app/modules/widgets/arcgis-widget/models/interfaces/arcgis-source';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { Control, FeatureGroup, Layer } from 'leaflet';
import { ArcgisWidgetService } from './arcgis-widget.service';

@Component({
  selector: 'app-arcgis-widget',
  templateUrl: './arcgis-widget.component.html',
  styleUrls: ['./arcgis-widget.component.scss'],
})
export class ArcgisWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit, DoCheck, OnDestroy {
  marked: boolean = false;
  control: Control.Layers;
  layers: FeatureGroup;

  sources: Array<ArcgisSource>;

  constructor(public widgetService: ArcgisWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadSources();
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    if (this.control) {
      this.control.remove();
    }
    this.control = new Control.Layers();
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources, null)
      .subscribe((layers: FeatureGroup) => {
        layers.getLayers().forEach((l: Layer, index: number) => {
          const arcgisUrl: string = (l as any).options.url;
          const levelSource: ArcgisSource = this.sources.find((a: ArcgisSource) => {
            return a.rewriteUrl === arcgisUrl;
          });
          if (levelSource.sourceData && levelSource.sourceData.shortDesc) {
            this.control.addOverlay(l, levelSource.sourceData.shortDesc);
          }
        });
        this.map.addLayer(layers);
        if (this.layers) {
          this.map.removeLayer(this.layers);
        }
        this.layers = layers;
        this.control.addTo(this.map);
        this.isLoading = false;
      });
  }

  ngDoCheck(): void {
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.layers) {
      this.layers.remove();
    }
  }

}
