import { Component } from '@angular/core';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';

@Component({
  selector: 'app-sanctions-map',
  templateUrl: './sanctions-map.component.html'
})
export class SanctionsMapComponent extends MapWidgetComponent {

}
