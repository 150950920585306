import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { WidgetModel } from '@app/shared/models/widgets/widget-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-widget-modal',
  templateUrl: './add-widget-modal.component.html'
})
export class AddWidgetModalComponent implements OnInit {
  @ViewChild('widgets') widgets: MatSelectionList;

  selectedName: string;
  selectedTitle: string;
  widgetList: Array<WidgetModel>;
  result: WidgetModel;
  fieldControl: FormControl = new FormControl();
  filteredOptions: Observable<Array<WidgetModel>>;

  constructor(public dialogRef: MatDialogRef<AddWidgetModalComponent>,
              private titleCasePipe: TitleCasePipe,
              @Inject(MAT_DIALOG_DATA) public data: Array<WidgetModel>) {
    this.widgetList = data;
  }

  private _filter(value: string | number): Array<WidgetModel> {
    if (typeof value === 'string') {
      const filterValue: string = value.toLowerCase();

      return this.widgetList.filter((option: WidgetModel) => option.title.toLowerCase().includes(filterValue));
    }
  }

  ngOnInit(): void {
    this.filteredOptions = this.fieldControl.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => this._filter(value)),
      );
  }

  updateForm(selection: MatAutocompleteSelectedEvent): void {
    this.selectedName = selection.option.value;
    this.result = this.widgetList.find((d: WidgetModel) => {
      return d.name === this.selectedName;
    });
    this.selectedTitle = this.titleCasePipe.transform(this.result.title);
  }
}
