<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData"
>
  <div class="date d-flex flex-column justify-content-center align-items-center">
    <ng-container *ngIf="date && status === 'normal'">
      <div class="day mb-2">
        {{date | date:'dd MMMM yyyy'}}</div>
      <div class="hour mt-2">
        {{date | date:'HH:mm'}}</div>
    </ng-container>
    <ng-container *ngIf="!date && status === 'negative'">
      <div class="text mb-2">{{'WIDGETS.ROAD_PARKING.NEGATIVE' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="!date && status === 'full'">
      <div class="text text-red mb-2">{{'WIDGETS.ROAD_PARKING.FULL' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="!date && status === 'stationary'">
      <div class="text mb-2">{{'WIDGETS.ROAD_PARKING.STATIONARY' | translate}}</div>
    </ng-container>
    <ng-container *ngIf="!this.timeMachineService.rangeOn">
      <div class="mt-2">
        <i>{{'WIDGETS.ROAD_PARKING.FORECAST_HOUR' | translate}}
          - {{this.timeMachineService.getCurrentSelectionTimestamp() | date:'dd/MM/yyyy - HH:mm:ss'}}</i></div>
    </ng-container>
    <ng-container *ngIf="this.timeMachineService.rangeOn">
      <div class="mt-2">
        <i>{{'WIDGETS.ROAD_PARKING.FORECAST_HOUR' | translate}}
        - {{this.timeMachineService.getCurrentSelectionRange().to | date:'dd/MM/yyyy - HH:mm:ss'}}</i></div>
    </ng-container>
  </div>
</app-widget>

