import { LatLng } from 'leaflet';
import moment, { Moment } from 'moment';
import * as UtmObj from 'utm-latlng';
import { PublicTransportSerializedVehicle } from '../interfaces';

export class PublicTransportVehicle {
  time: Moment;
  lat: number;
  lon: number;
  earlyDelay: number;
  lastStopArrivalTime: string;
  lastStopName: string;
  lastStopID: number;
  nextStopArrivalTime: string;
  nextStopName: string;
  nextStopID: number;
  terminalStopArrivalTime: string;
  terminalStopName: string;
  terminalStopID: number;
  shiftID: number;
  speed: number;
  vehicleID: string;
  routeKey: string;
  tripId: string;

  constructor(publicTransportSerializedVehicle: PublicTransportSerializedVehicle) {
    this.time = moment(publicTransportSerializedVehicle.time);
    const latLng: UtmObj = new UtmObj();
    if (publicTransportSerializedVehicle.Y_UTM && publicTransportSerializedVehicle.X_UTM) {
      const coordinates: LatLng = latLng.convertUtmToLatLng(publicTransportSerializedVehicle.Y_UTM, publicTransportSerializedVehicle.X_UTM, 32, 'T');
      this.lat = coordinates.lat;
      this.lon = coordinates.lng;
    }
    if (publicTransportSerializedVehicle.lat && publicTransportSerializedVehicle.lon) {
      this.lat = publicTransportSerializedVehicle.lat;
      this.lon = publicTransportSerializedVehicle.lon;
    }
    this.earlyDelay = publicTransportSerializedVehicle.earlyDelay;
    this.lastStopID = publicTransportSerializedVehicle.lastStopID;
    this.lastStopName = publicTransportSerializedVehicle.lastStopName;
    this.lastStopArrivalTime = publicTransportSerializedVehicle.lastStopArrivalTime;
    this.nextStopID = publicTransportSerializedVehicle.nextStopID;
    this.nextStopName = publicTransportSerializedVehicle.nextStopName;
    this.nextStopArrivalTime = publicTransportSerializedVehicle.nextStopArrivalTime;
    this.terminalStopID = publicTransportSerializedVehicle.terminalStopID;
    this.terminalStopName = publicTransportSerializedVehicle.terminalStopName;
    this.terminalStopArrivalTime = publicTransportSerializedVehicle.terminalStopArrivalTime;
    this.shiftID = publicTransportSerializedVehicle.shiftID;
    this.speed = publicTransportSerializedVehicle.speed;
    this.vehicleID = publicTransportSerializedVehicle.vehicleID;
    this.routeKey = publicTransportSerializedVehicle.route_key;
    if (publicTransportSerializedVehicle.trip_id) {
      this.tripId = publicTransportSerializedVehicle.trip_id.replace('200_', '235_');
    }
  }
}
