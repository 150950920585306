import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlowsModule } from '@app/modules/widgets/flows/flows.module';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { FlowControlWidgetComponent } from './flow-control-widget.component';
import { FlowControlWidgetService } from './flow-control-widget.service';

@NgModule({
  declarations: [FlowControlWidgetComponent],
  imports: [
    WidgetModule,
    CommonModule,
    TranslateModule,
    FlowsModule
  ],
  providers: [FlowControlWidgetService],
  exports: [FlowControlWidgetComponent]
})
export class FlowControlWidgetModule { }
