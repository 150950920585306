<div class="tide-container">
  <em class="icon-venice_acqua-alta"></em>
  <div class="tide d-flex flex-column justify-content-center align-items-center">
    <mat-spinner *ngIf="tideLoading" class="spinner" [diameter]="30"></mat-spinner>
    <ng-container *ngIf="currentTide">
      <span class="badge badge-primary" *ngIf="currentTide.valid">{{currentTide.level | number:'1.0-0'}} cm</span>
      <span class="badge badge-primary" *ngIf="!currentTide.valid">N.D.</span>
      <i class="tide__icon icon-venice_costante"
         [ngClass]="{'rotate-45-positive': currentTide.trend < 0, 'rotate-45-negative': currentTide.trend > 0 }"></i>
    </ng-container>
  </div>
</div>
