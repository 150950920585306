import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetEvent } from '@app/shared/models/widgets/widget-event';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetType } from '@app/shared/enums/widget-type';
import { SerializedTemperatures } from '@app/modules/widgets/temperature-widget/model';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  CurrentTideEntry,
  TideEntry,
  TideForecastEntry,
  TidePeakEntry,
  TideRequest,
  LockedByTideAdditionalDataRequest,
  LockedByTideRequest,
} from './models/interfaces';
import { FeatureGroup, geoJSON } from 'leaflet';
import { FeatureCollection, MultiPolygon, Feature } from 'geojson';

@Injectable()
export class TideService extends WidgetService {

  private DEFAULT_STATION: string = 'Punta Salute Canal Grande';

  schemaUrl: string = 'assets/schemas/tide-widget.json';
  widgetType: WidgetType = WidgetType.TIDE_WIDGET;

  private loadTideData(timestamp: TimeMachineData, sources: Array<WidgetDataSource>,
                       additionalData: TideRequest): Observable<CurrentTideEntry> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide';
    });

    try {
      const url: string = this.addTimeMachineDataToUrl(timestamp, widgetDataSource, 1);

      return this.http.get<DataResult<TideEntry>>(url).pipe(map((jsonFromServices: DataResult<TideEntry>) => {
        widgetDataSource.status = DataSourceStatus.AVAILABLE;

        if (jsonFromServices.total) {
          let defaultStation: TideEntry =
            jsonFromServices.data.find((element: TideEntry) => element.station === (additionalData && additionalData.station ? additionalData.station : this.DEFAULT_STATION));
          if (!defaultStation) {
            defaultStation = jsonFromServices.data[0];
          }
          if (defaultStation) {
            return {
              level: defaultStation.value * 100,
              moment: moment(defaultStation.time),
              trend: defaultStation.trend,
              station: defaultStation.station,
              valid: defaultStation.validValue,
              label: defaultStation.label,
            };
          } else {
            throw new Error('Default station ' + this.DEFAULT_STATION + ' not found');
          }
        }
      }, () => {
        widgetDataSource.status = DataSourceStatus.UNAVAILABLE;
        throw new Error('Default station ' + this.DEFAULT_STATION + ' not found');
      }));
    } catch (error) {
      widgetDataSource.status = DataSourceStatus.UNAVAILABLE;
      throw error;
    }
  }

  private loadTideForecastData(timeData: TimeMachineData, sources: Array<WidgetDataSource>,
                               additionalData: TideRequest): Observable<CurrentTideEntry> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide-forecast';
    });

    try {
      const url: string = this.addTimeMachineDataToUrl(timeData, widgetDataSource, 1);

      return this.http.get<TideForecastEntry>(url).pipe(map((tideForecastEntry: TideForecastEntry) => {
        return {
          level: tideForecastEntry.value,
          moment: moment(tideForecastEntry.time),
          trend: tideForecastEntry.trend,
          station: tideForecastEntry.station,
        };
      }));

    } catch (error) {
      widgetDataSource.status = DataSourceStatus.UNAVAILABLE;
      throw error;
    }
  }

  loadStationList(timestamp: TimeMachineData, sources: Array<WidgetDataSource>): Observable<Array<TideEntry>> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide';
    });
    const url: string = this.addTimeMachineDataToUrl(timestamp, widgetDataSource, 1);
    return this.http.get<DataResult<TideEntry>>(url).pipe(switchMap((stationsList: DataResult<TideEntry>) => {
      return of(stationsList.data);
    }));
  }

  loadPeakData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<TidePeakEntry> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide-peak';
    });
    if (widgetDataSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, widgetDataSource, 1);

      return this.http.get<TidePeakEntry>(url);
    }
  }

  public loadData(timeData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: TideRequest, forecast?: boolean): Observable<CurrentTideEntry> {
    if (!forecast) {
      return this.loadTideData(timeData, sources, additionalData);
    } else {
      return this.loadTideForecastData(timeData, sources, additionalData);
    }
  }

  public loadFeaturesLockedByTide(sources: Array<WidgetDataSource>,
                                  additionalData: LockedByTideAdditionalDataRequest): Observable<FeatureGroup> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'mobility-simulator-water-tide-blocked';
    });

    const body: LockedByTideRequest = {
      tideHeight: additionalData.tideHeight,
      tideOffset: additionalData.tideOffset,
      useWalkways: additionalData.useWalkways,
    };

    return this.http.post<FeatureCollection<MultiPolygon>>(widgetDataSource.rewriteUrl, body).pipe(switchMap((layer: FeatureCollection) => {
      return of(geoJSON(layer, {
        style: (f: Feature) => {
          return {
            interactive: false,
            stroke: false,
            fill: true,
            fillColor: additionalData.layerStyle.fillColor,
            fillOpacity: additionalData.layerStyle.fillOpacity,
          };
        },
      }));
    }));
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const timestamp: number = new Date().getTime();
    const url: string = source.rewriteUrl.replace('{{to}}', timestamp.toString());
    return this.http.get<DataResult<SerializedTemperatures>>(url);
  }

  public checkThresholds(timeMachineData: TimeMachineData): Observable<DataResult<WidgetEvent>> {
    return this.storageService.checkWidgetThresholds(timeMachineData, 'tide');
  }
}
