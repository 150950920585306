import { Component, OnInit } from '@angular/core';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { PRIMARY_SOURCES } from '@app/modules/widgets/cells-attendance-widget/data';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-cells-attendance-widget',
  templateUrl: './cells-attendance-widget.component.html',
  styleUrls: ['./cells-attendance-widget.component.scss'],
})
export class CellsAttendanceWidgetComponent extends WidgetComponent implements OnInit {
  imageSrc: string;

  sources: Array<WidgetDataSource> = PRIMARY_SOURCES;

  private getMainSource(): WidgetDataSource {
    return this.sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'cells_attendance';
    });
  }

  ngOnInit(): void {
    this.imageSrc = this.getMainSource().rewriteUrl;
  }

  imageLoaded(): void {
    const mainSource: WidgetDataSource = this.getMainSource();
    mainSource.status = DataSourceStatus.AVAILABLE;
    this.sourceStatus = DataSourceStatus.AVAILABLE;
  }

  imageError(): void {
    const mainSource: WidgetDataSource = this.getMainSource();
    mainSource.status = DataSourceStatus.UNAVAILABLE;
    this.sourceStatus = DataSourceStatus.UNAVAILABLE;
  }

}
