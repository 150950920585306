import { AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { BehavioralFlow } from '@app/modules/widgets/flows/behavioral-statistics-widget/models/behavioral-flow';
import { FlowConfiguration } from '@app/modules/widgets/flows/flow-map-widget/data/flow-configuration';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { TrafficFlow } from '@app/modules/widgets/flows/traffic-widget/models/traffic-flow';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapMarker } from '@app/modules/widgets/map-widget/model';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { FlowType, FlowWidgetData, FlowZone, FlowZoneGroup } from '@app/shared/models/flow-models';
import { FlowData } from '@app/shared/models/flow-models/flow-data';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { DivIcon, latLng, LatLng, LatLngBounds, Map, Marker, Polygon, Util } from 'leaflet';
import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FlowMapWidgetService } from './flow-map-widget.service';

declare var L: any;

@Component({
  selector: 'app-flow-map-widget',
  templateUrl: './flow-map-widget.component.html',
  styleUrls: ['./flow-map-widget.component.scss'],
})
export class FlowMapWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  polygons: Array<WaterFlowPolygon>;

  map: Map;

  @Input()
  data: FlowWidgetData;

  @Input()
  mapId: string;


  refreshBounds: boolean = true;

  groups: Array<FlowZoneGroup>;
  flowData: Array<TrafficFlow>;
  behavioralData: Array<BehavioralFlow>;
  legends: Array<MapLegend> = [];
  filter: string;
  zonesUpdateSubscription: Subscription;
  zonesActivatedSubscription: Subscription;
  typePickSubscription: Subscription;
  flowDataSubscription: Subscription;
  clusterLayer: any;
  cluster: boolean;
  maxClusterRadius: number;

  configurations: Array<FlowConfiguration>;

  constructor(public widgetService: FlowMapWidgetService,
              public flowService: FlowsService,
              private cd: ChangeDetectorRef, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.polygons = [];
    super.ngOnInit();
    this.initDefaultConfig(this.data.type);
    this.flowService.groupsSelected.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
    this.flowService.groupsUpdated.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
    this.flowService.typePicked.subscribe((type: string) => {
      this.filter = type;
      this.refreshWidget(this.timeMachineService.getCurrentSelection());
    });
    this.flowService.zoneClick.subscribe((zone: FlowZone) => {
      const cam: MapMarker = this.markers.find((x: MapMarker) => x.name === zone.name);
      this.clusterLayer.zoomToShowLayer(cam.marker);
    });
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.groups = [];
    this.flowData = [];
    this.behavioralData = [];
    this.maxClusterRadius = 80;
    this.cluster = false;
    const cameraDataSource: WidgetDataSource = this.sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'cameras';
    });
    if (cameraDataSource) {
      const sourceData: any = cameraDataSource.configuration ? JSON.parse(cameraDataSource.configuration).sourceData : '';
      this.maxClusterRadius = sourceData && sourceData.maxClusterRadius ? sourceData.maxClusterRadius : 80;
      this.cluster = sourceData && sourceData.cluster ? sourceData.cluster : false;
    }
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.dataSubscription = forkJoin([this.flowService.loadGroups(timeMachineData, this.sources), this.flowService.getFlowConfiguration(this.data.type), this.widgetService.getLegend(this.data.type)]).pipe(
      switchMap((loadingData: [Array<FlowZoneGroup>, Array<FlowConfiguration>, MapLegend]) => {
        const groups: Array<FlowZoneGroup> = loadingData[0];
        this.configurations = loadingData[1];
        this.legends = [loadingData[2]];
        this.isLoading = true;
        const flowData: FlowData = {
          zoneIds: this.widgetService.getZonesFromGroups(groups),
          groupIds: [],
          type: this.data.type,
        };
        return forkJoin([of(groups), this.widgetService.getTrafficData(timeMachineData, this.sources, flowData),
          this.widgetService.getBehavioralData(timeMachineData, this.sources, flowData)]);
      })).subscribe((data: Array<any>) => {
      this.noData = false;
      this.groups = data[0];
      this.flowData = data[1];
      this.behavioralData = data[2];
      if (this.groups && this.flowData && this.behavioralData) {
        this.refreshWidget(timeMachineData);
      } else {
        this.isLoading = false;
      }
    }, (e: any) => {
      this.noData = true;
      this.isLoading = false;
      throw new Error(e);
    });
  }

  addSelectionToGroups(zones: Array<FlowZone>): void {
    this.groups = this.flowService.updateGroups(zones);
    if (this.timeMachineService.rangeOn) {
      this.refreshWidget(this.timeMachineService.timeMachineRange);
    } else {
      this.refreshWidget(this.timeMachineService.timer.valueOf());
    }
  }

  refreshUI(): void {
    this.removePolygons();
    this.removeMarkers();
    if (this.data.cameras) {
      this.drawMarkers();
    }
    this.drawCameras();
  }

  refreshWidget(timeMachineData: TimeMachineData): void {
    this.groups = this.widgetService.elaborateFlow(timeMachineData, this.groups, this.flowData, this.filter, this.configurations);
    this.groups = this.widgetService.elaborateBehavior(timeMachineData, this.groups, this.behavioralData, this.filter);
    this.refreshUI();
    if (this.refreshBounds) {
      this.refreshBounds = false;
      this.fitMap(this.data.type);
      this.map.panTo(this.leafletOptions.center);
    }
    this.isLoading = false;
  }

  initDefaultConfig(type: FlowType): void {
    if (this.data.mapConfig) {
      this.leafletOptions.center = latLng(this.data.mapConfig.center.lat, this.data.mapConfig.center.lon);
    }
  }

  fitMap(type: FlowType): void {
    if (this.groups && this.groups.length) {
      const bounds: LatLngBounds = this.map.getBounds();
      this.groups.forEach((group: FlowZoneGroup) => {
        group.zones.forEach((zone: FlowZone) => {
          const coordinates: LatLng = new LatLng(zone.camera.coordinates.latitude, zone.camera.coordinates.longitude);
          bounds.extend(coordinates);
        });
      });
      this.map.fitBounds(bounds);
    }
  }

  drawCameras(): void {
    if (this.groups) {
      this.groups.forEach((group: FlowZoneGroup) => {
        group.zones.forEach((zone: FlowZone) => {
          const coordinates: LatLng = new LatLng(zone.camera.coordinates.latitude, zone.camera.coordinates.longitude);
          this.addCamera(coordinates, zone);
        });
      });
    }
  }

  createClusterIcon(): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const markerSvg: any = require('!raw-loader!@assets/img/markers/cam/new/m_camera.svg');
      const total: Array<Marker> = cluster.getChildCount();
      const markers: any = cluster.getAllChildMarkers();
      let i: number;
      let clusterColor: string = '#FFF';

      for (i = 0; i < markers.length; i++) {
        if (markers[i].options.selected) {
          clusterColor = '#A8E8EF';
        }
      }
      if (markers.every((l: any) => l.options.active === false)) {
        clusterColor = '#808080';
      }

      const widgetLevel: string = 'camera';
      const iconSettings: any = {
        mapIconUrl: markerSvg.default,
        mapIconColor: clusterColor,
      };

      return new DivIcon({
        className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
        iconSize: [46, 46],
        iconAnchor: [23, 46],
        html: `${Util.template(iconSettings.mapIconUrl, iconSettings)}
            <div class="d-flex flex-column ">
              <div><span class="marker-group__svg_label1">${total}</span></div>
            </div>`,
      });
    };
  }

  drawMarkers(): void {
    if (this.groups) {
      this.clusterLayer = L.markerClusterGroup({
        // disableClusteringAtZoom: 19,
        iconCreateFunction: this.createClusterIcon(),
        showCoverageOnHover: true,
        animate: false,
        spiderfy: true,
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: true,
        maxClusterRadius: this.maxClusterRadius,
      });

      this.groups.forEach((group: FlowZoneGroup) => {
        group.zones.forEach((zone: FlowZone) => {
          const marker: Marker = this.widgetService.addMarker(zone, this.sources, this.data.cameras, null, this.configurations);
          this.markers.push({
            name: zone.name,
            groupName: zone.groupName,
            marker: marker,
          });
          marker.on('click', () => {
            this.activateZone(zone);
            this.flowService.groupsUpdated.emit(this.groups);
            this.cd.detectChanges();
          });

          if (this.cluster) {
            marker.addTo(this.clusterLayer);
          } else {
            marker.addTo(this.map);
          }
        });
      });
      if (this.cluster) {
        this.clusterLayer.addTo(this.map);
      }
    }
  }

  removePolygons(): void {
    if (this.polygons) {
      this.polygons.forEach((p: WaterFlowPolygon) => {
        this.map.removeLayer(p.polygon);
      });
      this.polygons = [];
    }
  }

  removeMarkers(): void {
    if (this.markers) {
      this.markers.forEach((m: MapMarker) => {
        this.map.removeLayer(m.marker);
      });
      this.markers = [];
    }
  }

  addCamera(coordinates: LatLng, zone: FlowZone): void {
    if (zone.camera) {
      const cameraPolygon: Polygon = this.widgetService.loadCameraPolygon(zone, this.sources, this.data.home, {
        type: FlowType.WATER,
        showTooltip: false,
        showMarkers: true,
        selectedAlert: null,
      }, this.configurations);
      if (cameraPolygon) {
        cameraPolygon.addTo(this.map);
        this.polygons.push({
          polygon: cameraPolygon,
          zone: zone,
        });
      }
    }
  }


  activateZone(zone: FlowZone): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.active = (z.name === zone.name);
      });
    });
    this.flowService.setGroups(this.groups);
  }

  selectZone(zone: FlowZone): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.selected = (z.name === zone.name);
      });
    });
    this.flowService.setGroups(this.groups);
  }

  selectAllZones(): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.active = true;
      g.zones.forEach((z: FlowZone) => {
        z.active = true;
      });
    });
    this.flowService.setGroups(this.groups);
    this.flowService.groupsUpdated.emit(this.groups);
  }

  deselectAllZones(): void {
    this.groups.forEach((g: FlowZoneGroup) => {
      g.zones.forEach((z: FlowZone) => {
        z.selected = false;
      });
    });
    this.flowService.setGroups(this.groups);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.typePickSubscription) {
      this.typePickSubscription.unsubscribe();
    }
    if (this.zonesUpdateSubscription) {
      this.zonesUpdateSubscription.unsubscribe();
    }
    if (this.zonesActivatedSubscription) {
      this.zonesActivatedSubscription.unsubscribe();
    }
  }
}

export interface WaterFlowPolygon {
  zone: FlowZone;
  polygon: Polygon<FlowZone>;
}

export interface CameraData {
  id: number;
}
