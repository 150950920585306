import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { WeatherService } from './weather.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Subscription } from 'rxjs';
import { Weather } from '@app/modules/widgets/weather-widget/models/weather';
import { HttpErrorResponse } from '@angular/common/http';
import { ResizedEvent } from 'angular-resize-event';
import moment from 'moment';
import { MatSelectChange } from '@angular/material/select';
import {
  Forecast,
  WindSpeedRange
} from './models';

interface City {
  name: string;
  country: string;
}

@Component({
  selector: 'app-weather-widget',
  templateUrl: './weather-widget.component.html',
  styleUrls: ['./weather-widget.component.scss']
})
export class WeatherWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  cities: Array<City> = [
    { name: 'Milano', country: 'IT' },
    { name: 'Venezia', country: 'IT' },
    { name: 'Torino', country: 'IT' }
  ];

  selectedCity: string = '';
  weatherDataSubscription: Subscription;
  currentWeatherData: Weather;
  showForecast: boolean = true;
  fontSize: string;
  windSpeed: Array<WindSpeedRange> = [
    {
      speed_interval: [0, 0.3],
      key: 'SETTINGS'
    },
    {
      speed_interval: [0.3, 1.6],
      key: 'CALM'
    },
    {
      speed_interval: [1.6, 3.3],
      key: 'LIGHTBREEZE'
    },
    {
      speed_interval: [3.4, 5.5],
      key: 'GENTLEBREEZE'
    },
    {
      speed_interval: [5.5, 8],
      key: 'MODERATEBREEZE'
    },
    {
      speed_interval: [8, 10.8],
      key: 'FRESHBREEZE'
    },
    {
      speed_interval: [10.8, 13.9],
      key: 'STRONGBREEZE'
    },
    {
      speed_interval: [13.9, 17.2],
      key: 'HIGHWIND'
    },
    {
      speed_interval: [17.2, 20.7],
      key: 'GALE'
    },
    {
      speed_interval: [20.8, 24.5],
      key: 'SEVEREGALE'
    },
    {
      speed_interval: [24.5, 28.5],
      key: 'STORM'
    },
    {
      speed_interval: [28.5, 32.7],
      key: 'VIOLENTSTORM'
    },
    {
      speed_interval: [32.7, 64],
      key: 'HURRICANE'
    }];


  constructor(public widgetService: WeatherService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.selectedCity = 'Milano';
  }

  selectCity(event: MatSelectChange): void {
    this.selectedCity = event.value;
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.weatherDataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((weatherData: Weather) => {
      this.noData = false;
      this.isLoading = true;

      weatherData.temp = Math.round(weatherData.temp);
      weatherData.wind_description = this.translateService.instant('WIDGETS.WEATHER.WIND');
      weatherData.wind_description += weatherData.wind_speed.toFixed(1);
      weatherData.wind_description += this.translateService.instant('WIDGETS.WEATHER.WIND_UNIT');
      weatherData.wind_description += this.translateService.instant('WIDGETS.WEATHER.' + this.getWindDescription(Number(weatherData.wind_speed.toFixed(1))));
      weatherData.weather_icon = this.data.iconPath + weatherData.weather_icon + '.png';

      if (weatherData.forecast) {
        weatherData.forecast.sort((a: Forecast, b: Forecast) => {
          return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
        });
        weatherData.forecast.forEach((e: Forecast, index: number) => {
          e.weather_icon = this.data.iconPath + e.weather_icon + '.png';
          e.shortDate = moment(e.datetime).format('D MMM');
          e.temp_min = Math.round(e.temp_min);
          e.temp_max = Math.round(e.temp_max);
          if (moment(e.datetime).isSame(moment())) {
            e.dayName = this.translateService.instant('WIDGETS.WEATHER.TODAY');
          } else {
            e.dayName = moment(e.datetime).format('ddd');
            e.dayName = e.dayName.charAt(0).toUpperCase() + e.dayName.substring(1);
          }
        });
      }

      this.currentWeatherData = weatherData;
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  handleResize(resize: ResizedEvent): void {
    const minSize: number = resize.newHeight > resize.newWidth ? resize.newWidth : resize.newHeight;
    this.fontSize = (minSize / 30).toString();
    this.showForecast = resize.newWidth > 400;
  }

  getWindDescription(speed: number): string {
    return this.windSpeed.find((e: WindSpeedRange) => speed >= e.speed_interval[0] && speed < e.speed_interval[1]).key;
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
