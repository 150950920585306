import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { FlowType, FlowZoneGroup } from '@app/shared/models/flow-models';
import { FlowsService } from '@app/modules/widgets/flows/flows.service';
import { TrafficLoadDataParams } from '@app/modules/widgets/flows/traffic-widget/models';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { orderBy } from 'lodash';
import { Subscription } from 'rxjs';
import { BehavioralStatisticsWidgetService } from './behavioral-statistics-widget.service';
import { BehaviorStatisticsTableDataSourceItem } from './models';

@Component({
  selector: 'app-behavioral-statistics-widget',
  templateUrl: './behavioral-statistics-widget.component.html',
  styleUrls: ['./behavioral-statistics-widget.component.scss'],
})

export class BehavioralStatisticsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {

  tableDataSource: MatTableDataSource<BehaviorStatisticsTableDataSourceItem>;
  waterTableColumns: Array<string> = ['color', 'type', 'speed_avg', 'speed_max', 'reverse', 'crossing', 'wrong_direction', 'prohibited_stops'];
  roadTableColumns: Array<string> = ['color', 'type', 'speed_avg', 'speed_max', 'prohibited_stops'];

  activeSort: Sort;

  groups: Array<FlowZoneGroup>;
  activeZoneIds: Array<string>;
  trafficData: Array<BehaviorStatisticsTableDataSourceItem>;
  selectedEntry: BehaviorStatisticsTableDataSourceItem;
  flowZonesSubscription: Subscription;
  typePickSubscription: Subscription;

  constructor(public widgetService: BehavioralStatisticsWidgetService,
              public flowService: FlowsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.selectedEntry = null;
    super.ngOnInit();
    this.flowZonesSubscription = this.flowService.groupsUpdated.subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.populateWidget();
    });
    this.typePickSubscription = this.flowService.typePicked.subscribe((type: string) => {
      if (this.trafficData) {
        this.selectedEntry = this.trafficData.find((t: BehaviorStatisticsTableDataSourceItem) => {
          return t.type === type;
        });
      }
    });
  }

  selectData(row: BehaviorStatisticsTableDataSourceItem): void {
    this.selectedEntry = row;
    if (row) {
      this.flowService.typePicked.emit(row.type);
    } else {
      this.flowService.typePicked.emit(null);
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.flowZonesSubscription = this.flowService.loadGroups(timeMachineData, this.sources).subscribe((groups: Array<FlowZoneGroup>) => {
      this.groups = groups;
      this.isLoading = false;
      this.populateWidget();
    });
  }

  populateWidget(): void {
    this.isLoading = true;
    this.activeZoneIds = this.widgetService.getActiveZoneIds(this.groups);
    this.activeZoneIds = orderBy(this.activeZoneIds);
    const timeMachineData: TimeMachineData = this.timeMachineService.getCurrentSelection();
    const trafficQuery: TrafficLoadDataParams = {
      zoneIds: this.activeZoneIds,
      groupIds: [],
      type: this.data.type as FlowType,
    };
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources, trafficQuery).subscribe((data: Array<BehaviorStatisticsTableDataSourceItem>) => {
      this.noData = false;
      this.trafficData = data;
      this.tableDataSource = new MatTableDataSource(this.trafficData);
      this.isLoading = false;
    }, (error: HttpErrorResponse) => {
      this.noData = true;
      this.isLoading = false;
    });
  }


  ngAfterViewInit(): void {
    this.checkSources();
  }

  getTotal(column: string): number {
    return this.data && this.trafficData ? this.trafficData.map((t: BehaviorStatisticsTableDataSourceItem) => t[column]).reduce((acc: number, value: number) => acc + value, 0) : 0;
  }

  getAvg(column: string): number {
    return this.data && this.trafficData ? Math.round(this.trafficData.map((item: BehaviorStatisticsTableDataSourceItem) => item[column])
      .reduce((acc: number, cur: number) => {
        return acc + cur;
      }, 0) / this.trafficData.length) : 0;
  }

  getMax(column: string): number {
    return this.data && this.trafficData ? Math.max(...this.trafficData.map((item: BehaviorStatisticsTableDataSourceItem) => item[column])) : 0;
  }

  onSortActivate(sort: Sort): void {
    this.activeSort = sort;
    this.sortData(this.activeSort);
  }

  sortData(sort: Sort): void {
    this.trafficData = orderBy(this.trafficData, [sort.active], [sort.direction === 'asc' ? 'asc' : 'desc']);
    this.tableDataSource = new MatTableDataSource(this.trafficData);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.typePickSubscription) {
      this.typePickSubscription.unsubscribe();
    }
    if (this.flowZonesSubscription) {
      this.flowZonesSubscription.unsubscribe();
    }
  }

}
