<app-widget class="mindicity-widget" [sources]="sources" [title]="title" [description]="description"
            (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable" [isLoading]="isLoading">
  <div id="ol-map" class="map" #mapElement>

  </div>
  <div class="legend"><img [src]="legend" alt="legend" *ngIf="legend" /></div>
</app-widget>
