import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SerializedGridInstance } from '@app/shared/models/dashboard/serialized-grid';
import {
  DashboardInsertResponse,
  DashboardUpdateResponse,
  LoadDashboardResponse, SaveDashboardRequest, UpdateDashboardRequest,
} from '@app/shared/models/dashboard/dashboard';

@Injectable()
export class DashboardService {
  currentDashboard: string;
  dashboard: Map<string, SerializedGridInstance> = new Map<string, SerializedGridInstance>();
  availableDashboards: Array<string> = [];

  constructor(public http: HttpClient) {
  }

  getDefaultDashboard(): Observable<LoadDashboardResponse> {
    return this.http.get<LoadDashboardResponse>(`/gateway/mcapi/manager/ui-dashboards/dashboards`);
  }

  getDashboard(dashboard: string): Observable<LoadDashboardResponse> {
    return this.http.get<LoadDashboardResponse>(`/gateway/mcapi/manager/ui-dashboards/dashboards/${dashboard}`);
  }

  saveDashboard(request: SaveDashboardRequest): Observable<DashboardInsertResponse> {
    return this.http.post<DashboardInsertResponse>(`/gateway/mcapi/manager/ui-dashboards/dashboards`, request);
  }

  updateDashboard(dashboard: string, request: UpdateDashboardRequest): Observable<DashboardUpdateResponse> {
    return this.http.put<DashboardUpdateResponse>(`/gateway/mcapi/manager/ui-dashboards/dashboards/${dashboard}`, request);
  }

  deleteDashboard(dashboard: string): Observable<void> {
    return this.http.delete<void>(`/gateway/mcapi/manager/ui-dashboards/dashboards/${dashboard}`);
  }
}
