import { EventEmitter, Injectable } from '@angular/core';
import { BehavioralFlow } from '@app/modules/widgets/flows/behavioral-statistics-widget/models/behavioral-flow';
import { FlowConfiguration } from '@app/modules/widgets/flows/flow-map-widget/data/flow-configuration';
import { VehicleColor, VehicleColorType } from '@app/modules/widgets/flows/traffic-widget/models';
import { TrafficFlow } from '@app/modules/widgets/flows/traffic-widget/models/traffic-flow';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';
import { FlowCamera, FlowCameraStatus, FlowType, FlowZone, FlowZoneGroup } from '@app/shared/models/flow-models';
import { FlowData } from '@app/shared/models/flow-models/flow-data';
import { MarkerList } from '@app/shared/models/map/marker-list';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureCollection, Point, Polygon } from 'geojson';
import { LatLngBounds } from 'leaflet';
import { Dictionary, flatten, groupBy, values } from 'lodash';
import moment, { Duration, Moment } from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

declare var L: any;

@Injectable()
export class FlowsService extends MapWidgetService {
  GROUPS_PLACEHOLDER: string = '{{groups}}';
  DEFAULT_HOURS_AGO: number = 1;
  defaultZones: Array<FlowZone>;
  defaultGroups: Array<FlowZoneGroup>;
  zones: Array<FlowZone>;
  groups: Array<FlowZoneGroup>;
  groupsUpdated: EventEmitter<Array<FlowZoneGroup>> = new EventEmitter<Array<FlowZoneGroup>>();
  groupsSelected: EventEmitter<Array<FlowZoneGroup>> = new EventEmitter<Array<FlowZoneGroup>>();
  zoneClick: EventEmitter<FlowZone> = new EventEmitter<FlowZone>();

  typePicked: EventEmitter<string> = new EventEmitter<string>();

  waterVehicleColors: Array<VehicleColorType> = [];
  roadVehicleColors: Array<VehicleColorType> = [];
  markers: Array<MarkerList> = [];

  getFlowConfiguration(additionalData: FlowType): Observable<Array<FlowConfiguration>> {
    return this.configurationService.getConfiguration(additionalData + '-flow-thresholds').pipe(switchMap((c: Configuration) => {
      const flowConfiguration: Array<FlowConfiguration> = JSON.parse(c.configuration);
      return of(flowConfiguration);
    }));
  }

  getSum(array: Array<any>, vehicleType: string, name: string, prop: string): number {
    return array.filter((result: any) => {
      return result[vehicleType].toLowerCase() === name.toLowerCase();
    }).map((item: any) => item[prop]).reduce((acc: number, cur: number) => {
      if (!isNaN(cur)) {
        return acc + cur;
      }
      return acc;
    }, 0);
  }

  getUrlZones(params: FlowData): string {
    let zones: string = '';
    if (params.zoneIds && params.zoneIds.length > 0) {
      zones = '&cameras=' + JSON.stringify(params.zoneIds);
    }
    return zones;
  }

  getUrlGroups(params: FlowData): string {
    let groups: string = '';
    if (params.groupIds && params.groupIds.length > 0) {
      groups = '&groups=' + JSON.stringify(params.groupIds);
    }
    return groups;
  }

  getActiveZoneIds(groups: Array<FlowZoneGroup>): Array<string> {
    let activeZonesIDs: Array<string> = [];

    groups.forEach((group: FlowZoneGroup) => {
      activeZonesIDs = activeZonesIDs
        .concat(group.zones.filter((zone: FlowZone) => zone.active === true)
          .map((zone: FlowZone) => zone.camera.cameraId));
    });
    return activeZonesIDs;
  }

  getZonesFromGroups(groups: Array<FlowZoneGroup>): Array<string> {
    return flatten(groups.map((g: FlowZoneGroup) => {
      return g.zones.map((z: FlowZone) => {
        return z.name;
      });
    }));
  }

  setGroups(groups: Array<FlowZoneGroup>): void {
    this.groups = groups;
  }

  updateGroups(zones: Array<FlowZone>): Array<FlowZoneGroup> {
    if (this.groups) {
      this.groups = this.groups.map((group: FlowZoneGroup) => {
        group.zones = group.zones.map((zone: FlowZone) => {
          const updatedZone: FlowZone = zones.find((z: FlowZone) => {
            return z.name === zone.name;
          });
          if (updatedZone) {
            zone = updatedZone;
          }
          return zone;
        });
        return group;
      });
      return this.groups;
    } else {
      return [];
    }
  }

  getVehiclesPerTenMinutes(timeMachineData: TimeMachineData, total: number): number {
    if (typeof timeMachineData === 'number') {
      return 10 * (total / (this.DEFAULT_HOURS_AGO * 60));
    } else {
      const from: Moment = timeMachineData.from;
      const to: Moment = timeMachineData.to;
      const duration: Duration = moment.duration(to.diff(from));
      const minutes: number = Math.round(duration.asMinutes());
      return 10 * (total / minutes);
    }
  }

  getFlow(vehiclesPerMinute: number, configurations: Array<FlowConfiguration>): FlowConfiguration {
    return configurations.find((flow: FlowConfiguration) => {
      if (flow.min === '') {
        flow.min = null;
      }
      if (flow.max === '') {
        flow.max = null;
      }
      if (flow.min !== null && flow.max !== null) {
        return vehiclesPerMinute > flow.min && vehiclesPerMinute <= flow.max;
      }
      if (flow.min !== null && flow.max === null) {
        return vehiclesPerMinute >= flow.min;
      }
      if (flow.max !== null && flow.min === null) {
        return vehiclesPerMinute <= flow.max;
      }
    });
  }

  getTooltip(zone: FlowZone, fullVersion: boolean): string {
    const translate: TranslateService = this.translateService;
    let template: string = '';
    template = template + `<h6><b>${translate.instant('WIDGETS.FLOW_MAP.GROUP')}:</b> ${zone.groupName}<br/>`;
    template = template + `<b>${translate.instant('WIDGETS.FLOW_MAP.CAMERA')}:</b> ${zone.name}<br/>`;
    if (!zone.camera.stream) {
      template = template + `<b><i>${translate.instant('WIDGETS.FLOW_MAP.CAMERA_OFF')}</i></b>`;
    } else if (zone.camera.status === FlowCameraStatus.NOT_REACHABLE) {
      template = template + `<b><i>${translate.instant('WIDGETS.FLOW_MAP.CAMERA_NOT_REACHABLE')}</i></b>`;
    } else {
      if (zone.camera.reliability) {
        template = template + `<b>${translate.instant('WIDGETS.FLOW_MAP.CAMERA_RELIABILITY')}:</b> ${zone.camera.reliability}</h6><br/>`;
      }
      if (!isNaN(zone.totalVehicles)) {
        template = template + `<h6><b>${translate.instant('WIDGETS.FLOW_MAP.TOTAL_VEHICLES')}:</b> ${zone.totalVehicles}</h6>`;
        if (zone.trafficFlowData && fullVersion) {
          zone.trafficFlowData.forEach((t: TrafficFlow) => {
            const total: number = t.E + t.S + t.N + t.W;
            if (t.boat) {
              template = template + `<b>${t.boat}:</b> ${total}</h6><br/>`;
            }
            if (t.vehicle) {
              template = template + `<b>${t.vehicle}:</b> ${total}</h6><br/>`;
            }
          });
        }
      }
      template = template + `<b>${translate.instant('WIDGETS.FLOW_MAP.TOTAL_VEHICLES_PER_MINUTE')}:</b> ${zone.vehiclesPerMinute.toFixed(2)}<br/>`;
      if (!isNaN(zone.totalInfractions) && fullVersion) {
        template = template + `<br/><h6><b>${translate.instant('WIDGETS.FLOW_MAP.TOTAL_REPORTS')}:</b> ${zone.totalInfractions}</h6>`;
        if (zone.behavioralFlowData) {
          zone.behavioralFlowData.forEach((t: BehavioralFlow) => {
            let total: number | string = t.direction + t.reverse + t.stop + t.orthogonal + t.anomalies;
            if (isNaN(total)) {
              total = 'N/D';
            }
            if (t.boat) {
              template = template + `<b>${t.boat}:</b> ${total}</h6><br/>`;
            }
            if (t.vehicle) {
              template = template + `<b>${t.vehicle}:</b> ${total}</h6><br/>`;
            }
          });
        }
      }
      if (!this.timeMachineService.rangeOn) {
        template = template + `<i>${translate.instant('WIDGETS.FLOW_MAP.DEFAULT_DATA')}</i>`;
      }
      const notAvailable: string = translate.instant('WIDGETS.FLOW_MAP.VERSION_NOT_AVAILABLE');
      if (zone.camera.version) {
        template = template + `<i class="font-size-small"><br/><b>${translate.instant('WIDGETS.FLOW_MAP.VERSION')} - ${translate.instant('WIDGETS.FLOW_MAP.VERSION_PEGASO')}:</b> ${zone.camera.version.Pegaso ? zone.camera.version.Pegaso : notAvailable};&nbsp;`;
        template = template + `<b>${translate.instant('WIDGETS.FLOW_MAP.VERSION_DETECTOR')}:</b> ${zone.camera.version.Detector ? zone.camera.version.Detector : notAvailable};&nbsp;`;
        template = template + `<b>${translate.instant('WIDGETS.FLOW_MAP.VERSION_TRACKER')}:</b> ${zone.camera.version.Tracker ? zone.camera.version.Tracker : notAvailable}</i>`;
      } else {
        template = template + `<i class="font-size-small"><br/><b>${translate.instant('WIDGETS.FLOW_MAP.VERSION')}:</b> ${notAvailable}</i>`;
      }
    }
    return template;
  }

  filterData(data: Array<TrafficFlow | BehavioralFlow>, z: FlowZone, filter: any): Array<TrafficFlow | BehavioralFlow> {
    if (data) {
      return data.filter((t: TrafficFlow) => {
        if (filter) {
          return t.camera === z.name && t.boat === filter;
        } else {
          return t.camera === z.name;
        }
      });
    } else {
      return [];
    }
  }

  elaborateFlow(timeMachineData: TimeMachineData,
                groups: Array<FlowZoneGroup>, flowData: Array<TrafficFlow>, filter: any, flowConfigurations: Array<FlowConfiguration>): Array<FlowZoneGroup> {
    groups = groups.map((g: FlowZoneGroup) => {
      g.zones = g.zones.map((z: FlowZone) => {
        const zoneData: Array<TrafficFlow> = this.filterData(flowData, z, filter) as Array<TrafficFlow>;
        z.totalVehicles = zoneData.reduce((acc: number, currentValue: TrafficFlow) => {
          return acc + currentValue.N + currentValue.S + currentValue.W + currentValue.E;
        }, 0);
        z.vehiclesPerMinute = this.getVehiclesPerTenMinutes(timeMachineData, z.totalVehicles);
        z.trafficFlowData = zoneData;
        z.flow = this.getFlow(z.vehiclesPerMinute, flowConfigurations).id;
        return z;
      });
      g.totalVehicles = g.zones.reduce((acc: number, currentValue: FlowZone) => {
        return acc + currentValue.totalVehicles;
      }, 0);
      g.vehiclesPerMinute = this.getVehiclesPerTenMinutes(timeMachineData, g.totalVehicles) / g.zones.length;
      g.flow = this.getFlow(g.vehiclesPerMinute, flowConfigurations).id;
      return g;
    });
    return groups;
  }

  elaborateBehavior(timeMachineData: TimeMachineData,
                    groups: Array<FlowZoneGroup>, flowData: Array<BehavioralFlow>, filter: any): Array<FlowZoneGroup> {
    groups = groups.map((g: FlowZoneGroup) => {
      g.zones = g.zones.map((z: FlowZone) => {
        const zoneData: Array<BehavioralFlow> = this.filterData(flowData, z, filter) as Array<BehavioralFlow>;
        z.totalInfractions = zoneData.reduce((acc: number, currentValue: BehavioralFlow) => {
          return acc + currentValue.anomalies +
            currentValue.orthogonal +
            currentValue.stop +
            currentValue.reverse +
            currentValue.direction;
        }, 0);
        z.behavioralFlowData = zoneData;
        return z;
      });
      g.totalInfractions = g.zones.reduce((acc: number, currentValue: FlowZone) => {
        return acc + currentValue.totalInfractions;
      }, 0);
      return g;
    });
    return groups;
  }

  getBehavioralData(timestamp: TimeMachineData,
                    sources: Array<WidgetDataSource>, params: FlowData): Observable<Array<BehavioralFlow>> {
    let zones: string = '';
    let groups: string = '';

    zones = this.getUrlZones(params);

    groups = this.getUrlGroups(params);
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'behavioral-data';
    });
    if (mainSource) {
      let url: string;
      url = this.addTimeMachineDataToUrl(timestamp, mainSource, this.DEFAULT_HOURS_AGO);
      url = url.replace('{{zones}}', zones);
      url = url.replace(this.GROUPS_PLACEHOLDER, groups);
      return this.http.get<DataResult<BehavioralFlow>>(url).pipe(
        map((jsonFromServices: DataResult<BehavioralFlow>) => {
          return jsonFromServices.data;
        }),
      );
    } else {
      return of([]);
    }
  }

  public getTrafficData(timestamp: TimeMachineData,
                        sources: Array<WidgetDataSource>, params: FlowData): Observable<Array<TrafficFlow>> {
    let zones: string = '';
    let groups: string = '';

    zones = this.getUrlZones(params);

    groups = this.getUrlGroups(params);

    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'traffic-data';
    });
    if (mainSource) {
      let url: string;
      url = this.addTimeMachineDataToUrl(timestamp, mainSource, 1);
      url = url.replace('{{zones}}', zones);
      url = url.replace(this.GROUPS_PLACEHOLDER, groups);
      return this.http.get<DataResult<TrafficFlow>>(url).pipe(
        map((jsonFromServices: DataResult<TrafficFlow>) => {
          return jsonFromServices.data;
        }),
      );
    } else {
      return of([]);
    }
  }

  parseCameras(shapes: FeatureCollection<Polygon>, points: FeatureCollection<Point>): Array<FlowZoneGroup> {
    const result: Array<FlowZoneGroup> = [];
    const groups: Dictionary<Array<Feature>> = groupBy(points.features, (f: Feature) => {
      return f.properties['type'];
    });
    const groupArray: Array<Array<Feature>> = values(groups);
    groupArray.forEach((groupFeature: Array<Feature>) => {
      const group: FlowZoneGroup = {
        name: groupFeature[0].properties['description'],
        zones: [],
        active: true,
        selected: false,
        flow: 0,
        totalVehicles: 0,
        totalInfractions: 0,
        vehiclesPerMinute: 0,
        id: groupFeature[0].properties['type'],
      };
      groupFeature.forEach((zoneCameraFeature: Feature) => {
        let shapeCameraFeature: Feature<Polygon>;
        if (shapes) {
          shapeCameraFeature = shapes.features.find((f: Feature) => {
            return f.properties['name'] === zoneCameraFeature.properties['name'];
          });
        }
        if (zoneCameraFeature.properties['metadata']) {
          const metadata: any = JSON.parse(zoneCameraFeature.properties['metadata']);
          group.zones.push({
            geometry: shapeCameraFeature ? shapeCameraFeature.geometry : undefined,
            selected: false,
            active: true,
            flow: 0,
            totalVehicles: 0,
            totalInfractions: 0,
            vehiclesPerMinute: 0,
            trafficFlowData: null,
            behavioralFlowData: null,
            color: null,
            name: zoneCameraFeature.properties['name'],
            groupName: zoneCameraFeature.properties['description'],
            camera: {
              coordinates: {
                latitude: zoneCameraFeature.geometry['coordinates'][1],
                longitude: zoneCameraFeature.geometry['coordinates'][0],
              },
              id: zoneCameraFeature.id,
              cameraId: zoneCameraFeature.properties['name'],
              stream: metadata['stream'],
              reliability: metadata['reliability'],
              status: metadata['status'],
              version: metadata['version'] ? JSON.parse(metadata['version']) : undefined,
              streamUrl: metadata['streamUrl'],
              statusUrl: metadata['statusUrl'],
              direction: metadata['direction'],
            },
          });
        } else {
          group.zones.push({
            geometry: shapeCameraFeature ? shapeCameraFeature.geometry : undefined,
            selected: false,
            active: true,
            flow: 0,
            totalVehicles: 0,
            totalInfractions: 0,
            vehiclesPerMinute: 0,
            trafficFlowData: null,
            behavioralFlowData: null,
            color: null,
            name: zoneCameraFeature.properties['name'],
            groupName: zoneCameraFeature.properties['description'],
            camera: {
              coordinates: {
                latitude: zoneCameraFeature.geometry['coordinates'][1],
                longitude: zoneCameraFeature.geometry['coordinates'][0],
              },
              id: zoneCameraFeature.id,
              cameraId: zoneCameraFeature.properties['name'],
              stream: zoneCameraFeature.properties['stream'],
              reliability: zoneCameraFeature.properties['reliability'],
              status: zoneCameraFeature.properties['status'],
              version: zoneCameraFeature.properties['version'] ? JSON.parse(zoneCameraFeature.properties['version']) : undefined,
              streamUrl: zoneCameraFeature.properties['stream_url'],
              statusUrl: zoneCameraFeature.properties['status_url'],
              direction: zoneCameraFeature.properties['direction'],
            },
          });
        }
      });
      let groupFlow: number = 0;
      group.zones.forEach((z: FlowZone) => {
        groupFlow = groupFlow + z.flow;
      });
      groupFlow = Math.round(groupFlow / group.zones.length);
      group.flow = groupFlow;

      result.push(group);
    });
    return result;
  }

  getZones(dataSources: Array<WidgetDataSource>, additionalData?: any): Observable<Array<FlowZoneGroup>> {
    const shapesDataSource: WidgetDataSource = dataSources.find((s: WidgetDataSource) => {
      return s.sourceType === 'shapes';
    });

    const cameraDataSource: WidgetDataSource = dataSources.find((s: WidgetDataSource) => {
      return s.sourceType === 'cameras';
    });

    const observables: Array<Observable<FeatureCollection>> = [];
    if (shapesDataSource) {
      shapesDataSource.rewriteUrl = this.replaceUrl(shapesDataSource.rewriteUrl, 0, 0);
      observables.push(this.http.get<FeatureCollection<Polygon>>(shapesDataSource.rewriteUrl));
    } else {
      observables.push(of(undefined));
    }

    if (cameraDataSource) {
      cameraDataSource.rewriteUrl = this.replaceUrl(cameraDataSource.rewriteUrl, 0, 0);
      // questo pezzo serve per la gestione con geoserver
      if (cameraDataSource.configuration) {
        const sourceData: any = JSON.parse(cameraDataSource.configuration).sourceData;

        if (sourceData.geoserver) {
          const rootUrl: string = cameraDataSource.rewriteUrl;
          let bboxFilter: string = '';
          let parameters: any;

          const defaultParameters: any = {
            service: 'WFS',
            request: 'GetFeature',
            typeName: sourceData.typeNs + ':' + sourceData.typeName,
            outputFormat: 'application/json',
            format_options: 'callback: getJson',
            srsName: 'EPSG:4326',
          };

          if (additionalData && additionalData.bbox) {
            additionalData.bbox.forEach((element: LatLngBounds) => {
              if (bboxFilter === '') {
                bboxFilter += 'BBOX(geometry, ' + element.toBBoxString() + ') ';
              } else {
                bboxFilter += 'OR BBOX(geometry, ' + element.toBBoxString() + ') ';
              }
            });

            const customParams: any = {
              CQL_FILTER: bboxFilter,
            };
            parameters = L.Util.extend(defaultParameters, customParams);
          } else {
            parameters = L.Util.extend(defaultParameters);
          }

          observables.push(this.http.get<FeatureCollection<Point>>(rootUrl + L.Util.getParamString(parameters)));
        } else {
          observables.push(this.http.get<FeatureCollection<Point>>(cameraDataSource.rewriteUrl));
        }
      } else {
        observables.push(this.http.get<FeatureCollection<Point>>(cameraDataSource.rewriteUrl));
      }
    } else {
      observables.push(of(undefined));
    }

    return forkJoin(observables).pipe(
      switchMap((data: [FeatureCollection<Polygon>, FeatureCollection<Point>]) => {
        const shapes: FeatureCollection<Polygon> = data[0];
        const points: FeatureCollection<Point> = data[1];
        if (data[1]) {
          return of(this.parseCameras(shapes, points));
        } else {
          return of([]);
        }
      }),
    );
  }

  getCameraZones(dataSources: Array<WidgetDataSource>): Observable<Array<FlowCamera>> {
    const widgetDataSource: WidgetDataSource = dataSources.find((s: WidgetDataSource) => {
      return s.sourceType === 'cameras';
    });
    if (widgetDataSource) {
      return this.http.get<FeatureCollection>(widgetDataSource.rewriteUrl).pipe(
        map((geoJson: FeatureCollection) => {
          return geoJson.features.map((f: Feature) => {
            return {
              coordinates: {
                latitude: f.geometry['coordinates'][1],
                longitude: f.geometry['coordinates'][0],
              },
              id: f.id,
              cameraId: f.properties['name'],
              stream: f.properties['stream'],
              reliability: f.properties['reliability'],
              status: f.properties['status'],
              version: f.properties['version'] ? JSON.parse(f.properties['version']) : null,
              streamUrl: f.properties['streamUrl'],
              statusUrl: f.properties['statusUrl'],
            };
          });
        }),
      );
    } else {
      return of([]);
    }
  }

  public loadGroups(timestamp: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: any): Observable<Array<FlowZoneGroup>> {
    if (sources && sources.length) {
      return this.getZones(sources, additionalData).pipe(
        map((groups: Array<FlowZoneGroup>) => {
          this.defaultGroups = groups;
          this.groups = groups;
          return groups;
        }),
      );
    } else {
      return of([]);
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    const params: FlowData = {
      zoneIds: ['SC1'],
      groupIds: [],
      type: FlowType.WATER,
    };
    const timeStampUrl: string = this.addTimeMachineDataToUrl(new Date().getTime(), source, 1);
    const zones: string = this.getUrlZones(params);
    const groups: string = this.getUrlGroups(params);
    const zoneUrl: string = timeStampUrl.replace('{{zones}}', zones);
    const groupUrl: string = zoneUrl.replace(this.GROUPS_PLACEHOLDER, groups);
    return this.http.get<any>(groupUrl);
  }

  public getVehicleColors(dataSources: Array<WidgetDataSource>): Observable<VehicleColor> {
    const widgetDataSource: WidgetDataSource = dataSources.find((s: WidgetDataSource) => {
      return s.sourceType === 'vehicle-colors';
    });
    if (widgetDataSource) {
      return this.http.get<VehicleColor>(widgetDataSource.rewriteUrl);
    }
  }
}
