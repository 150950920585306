import { NgModule } from '@angular/core';
import { AirQualityWidgetModule } from '@app/modules/widgets/air-quality-widget/air-quality-widget.module';
import { AlertsSrWidgetModule } from '@app/modules/widgets/alerts-sr-widget/alerts-sr-widget.module';
import { AlertsWidgetModule } from '@app/modules/widgets/alerts-widget/alerts-widget.module';
import { AmatWidgetModule } from '@app/modules/widgets/amat-widget/amat-widget.module';
import { ArcgisWidgetModule } from '@app/modules/widgets/arcgis-widget/arcgis-widget.module';
import { ArrivalsWidgetModule } from '@app/modules/widgets/arrivals-widget/arrivals-widget.module';
import { AuxiliaryWidgetModule } from '@app/modules/widgets/auxiliary-widget/auxiliary-widget.module';
import { BigMapWidgetModule } from '@app/modules/widgets/big-map-widget/big-map-widget.module';
import { BridgesWidgetModule } from '@app/modules/widgets/bridges-widget/bridges-widget.module';
import { CaselliWidgetModule } from '@app/modules/widgets/caselli-widget/caselli-widget.module';
import { CellsAttendanceWidgetModule } from '@app/modules/widgets/cells-attendance-widget/cells-attendance-widget.module';
import { CulturalEventsWidgetModule } from '@app/modules/widgets/cultural-events-widget/cultural-events-widget.module';
import { DangerIndexWidgetModule } from '@app/modules/widgets/danger-index-widget/danger-index-widget.module';
import { EventMapWidgetModule } from '@app/modules/widgets/event-map-widget/event-map-widget.module';
import { EventsWidgetModule } from '@app/modules/widgets/events-widget/events-widget.module';
import { BehavioralStatisticsWidgetModule } from '@app/modules/widgets/flows/behavioral-statistics-widget/behavioral-statistics-widget.module';
import { FlowControlWidgetModule } from '@app/modules/widgets/flows/flow-control-widget/flow-control-widget.module';
import { FlowMapWidgetModule } from '@app/modules/widgets/flows/flow-map-widget/flow-map-widget.module';
import { FlowsModule } from '@app/modules/widgets/flows/flows.module';
import { RoadFlowBaseModule } from '@app/modules/widgets/flows/road-flow-base-widget/road-flow-base.module';
import { RoadFlowCurrentModule } from '@app/modules/widgets/flows/road-flow-current-widget/road-flow-current.module';
import { RoadFlowDateWidgetModule } from '@app/modules/widgets/flows/road-flow-date-widget/road-flow-date-widget.module';
import { RoadFlowMeanWidgetModule } from '@app/modules/widgets/flows/road-flow-mean-widget/road-flow-mean-widget.module';
import { RoadFlowNumberModule } from '@app/modules/widgets/flows/road-flow-number-widget/road-flow-number.module';
import { TrafficWidgetModule } from '@app/modules/widgets/flows/traffic-widget/traffic-widget.module';
import { GeoserverOlWidgetModule } from '@app/modules/widgets/geoserver-ol-widget/geoserver-ol-widget.module';
import { MapObjectPositionWidgetModule } from '@app/modules/widgets/map-object-position-widget/map-object-position-widget.module';
import { PresencesClusterWidgetModule } from '@app/modules/widgets/presences-cluster-widget/presences-cluster-widget.module';
import { RadarWidgetModule } from '@app/modules/widgets/radar-widget/radar-widget.module';
import { ForecastWidgetModule } from '@app/modules/widgets/forecast-widget/forecast-widget.module';
import { GeoserverWidgetModule } from '@app/modules/widgets/geoserver-widget/geoserver-widget.module';
import { HeartBeatWidgetModule } from '@app/modules/widgets/heart-beat-widget/heart-beat-widget.module';
import { HeatmapWidgetModule } from '@app/modules/widgets/heatmap-widget/heatmap-widget.module';
import { HereMapWidgetModule } from '@app/modules/widgets/here-map-widget/here-map-widget.module';
import { HighwayTrafficWidgetModule } from '@app/modules/widgets/highway-traffic-widget/highway-traffic-widget.module';
import { IframeWidgetModule } from '@app/modules/widgets/iframe-widget/iframe-widget.module';
import { ImageDemoWidgetModule } from '@app/modules/widgets/image-demo-widget/image-demo-widget.module';
import { IndexesWidgetModule } from '@app/modules/widgets/indexes-widget/indexes-widget.module';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { MessagePanelsWidgetModule } from '@app/modules/widgets/message-panels-widget/message-panels-widget.module';
import { MeteoWidgetModule } from '@app/modules/widgets/meteo-widget/meteo-widget.module';
import { MobilityMapWidgetModule } from '@app/modules/widgets/mobility-map-widget/mobility-map-widget.module';
import { ParkingWidgetModule } from '@app/modules/widgets/parking-widget/parking-widget.module';
import { PedestrianFlowsWidgetModule } from '@app/modules/widgets/pedestrian-flows-widget/pedestrian-flows-widget.module';
import { PublicLightsWidgetModule } from '@app/modules/widgets/public-lights-widget/public-lights-widget.module';
import { PublicTransportWidgetModule } from '@app/modules/widgets/public-transport-widget/public-transport-widget.module';
import { PublicTransportsStopsWidgetModule } from '@app/modules/widgets/public-transports-stops-widget/public-transports-stops-widget.module';
import { RegistryWidgetModule } from '@app/modules/widgets/registry-widget';
import { RoadArrivalsWidgetModule } from '@app/modules/widgets/road-arrivals-widget/road-arrivals-widget.module';
import { RoadMaintenanceWidgetModule } from '@app/modules/widgets/road-maintenance-widget/road-maintenance-widget.module';
import { SanctionsModule } from '@app/modules/widgets/sanctions/sanctions.module';
import { TemperatureWidgetModule } from '@app/modules/widgets/temperature-widget/temperature-widget.module';
import { TideForecastWidgetModule } from '@app/modules/widgets/tide-forecast-widget/tide-forecast-widget.module';
import { TideMapWidgetModule } from '@app/modules/widgets/tide-map-widget/tide-map-widget.module';
import { TideWidgetModule } from '@app/modules/widgets/tide-widget/tide-widget.module';
import { TrafficImageWidgetModule } from '@app/modules/widgets/traffic-image-widget/traffic-image-widget.module';
import { VideoFeedWidgetModule } from '@app/modules/widgets/video-feed-widget/video-feed-widget.module';
import { VideosurveillanceWidgetModule } from '@app/modules/widgets/videosurveillance-widget/videosurveillance-widget.module';
import { WindWidgetModule } from '@app/modules/widgets/wind-widget/wind-widget.module';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { WeatherWidgetModule } from '@app/modules/widgets/weather-widget/weather-widget.module';


// @ts-ignore
@NgModule({
  imports: [
    AirQualityWidgetModule,
    AlertsSrWidgetModule,
    AlertsWidgetModule,
    AmatWidgetModule,
    ArcgisWidgetModule,
    ArrivalsWidgetModule,
    AuxiliaryWidgetModule,
    BigMapWidgetModule,
    BridgesWidgetModule,
    CaselliWidgetModule,
    CellsAttendanceWidgetModule,
    CulturalEventsWidgetModule,
    DangerIndexWidgetModule,
    EventMapWidgetModule,
    EventsWidgetModule,
    FlowsModule,
    BehavioralStatisticsWidgetModule,
    FlowControlWidgetModule,
    FlowMapWidgetModule,
    TrafficWidgetModule,
    RadarWidgetModule,
    WeatherWidgetModule,
    ForecastWidgetModule,
    HeartBeatWidgetModule,
    HeatmapWidgetModule,
    HereMapWidgetModule,
    HighwayTrafficWidgetModule,
    GeoserverWidgetModule,
    GeoserverOlWidgetModule,
    IframeWidgetModule,
    ImageDemoWidgetModule,
    IndexesWidgetModule,
    MapWidgetModule,
    MapObjectPositionWidgetModule,
    MeteoWidgetModule,
    MessagePanelsWidgetModule,
    MobilityMapWidgetModule,
    ParkingWidgetModule,
    PedestrianFlowsWidgetModule,
    PublicLightsWidgetModule,
    PublicTransportWidgetModule,
    PublicTransportsStopsWidgetModule,
    PresencesClusterWidgetModule,
    RegistryWidgetModule,
    RoadArrivalsWidgetModule,
    RoadMaintenanceWidgetModule,
    RoadFlowBaseModule,
    RoadFlowCurrentModule,
    RoadFlowNumberModule,
    RoadFlowDateWidgetModule,
    RoadFlowMeanWidgetModule,
    SanctionsModule,
    TemperatureWidgetModule,
    TideForecastWidgetModule,
    TideMapWidgetModule,
    TideWidgetModule,
    TrafficImageWidgetModule,
    VideoFeedWidgetModule,
    VideosurveillanceWidgetModule,
    WindWidgetModule,
    WidgetModule,
  ],
  exports: [
    AirQualityWidgetModule,
    AlertsSrWidgetModule,
    AlertsWidgetModule,
    AmatWidgetModule,
    ArcgisWidgetModule,
    ArrivalsWidgetModule,
    AuxiliaryWidgetModule,
    BigMapWidgetModule,
    BridgesWidgetModule,
    CaselliWidgetModule,
    CellsAttendanceWidgetModule,
    CulturalEventsWidgetModule,
    DangerIndexWidgetModule,
    EventMapWidgetModule,
    EventsWidgetModule,
    FlowsModule,
    BehavioralStatisticsWidgetModule,
    FlowControlWidgetModule,
    FlowMapWidgetModule,
    TrafficWidgetModule,
    MapObjectPositionWidgetModule,
    RadarWidgetModule,
    WeatherWidgetModule,
    ForecastWidgetModule,
    HeartBeatWidgetModule,
    HeatmapWidgetModule,
    HereMapWidgetModule,
    HighwayTrafficWidgetModule,
    GeoserverWidgetModule,
    GeoserverOlWidgetModule,
    IframeWidgetModule,
    ImageDemoWidgetModule,
    IndexesWidgetModule,
    MapWidgetModule,
    MeteoWidgetModule,
    MessagePanelsWidgetModule,
    MobilityMapWidgetModule,
    ParkingWidgetModule,
    PedestrianFlowsWidgetModule,
    PublicLightsWidgetModule,
    PublicTransportWidgetModule,
    PublicTransportsStopsWidgetModule,
    PresencesClusterWidgetModule,
    RegistryWidgetModule,
    RoadArrivalsWidgetModule,
    RoadMaintenanceWidgetModule,
    RoadFlowBaseModule,
    RoadFlowCurrentModule,
    RoadFlowNumberModule,
    RoadFlowDateWidgetModule,
    RoadFlowMeanWidgetModule,
    SanctionsModule,
    TemperatureWidgetModule,
    TideForecastWidgetModule,
    TideMapWidgetModule,
    TideWidgetModule,
    TrafficImageWidgetModule,
    VideoFeedWidgetModule,
    VideosurveillanceWidgetModule,
    WindWidgetModule,
    WidgetModule,
  ],

})
export class WidgetsModule {
}
