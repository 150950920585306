import { Component, Injector, Input, OnInit } from '@angular/core';
import { BridgesControlValues } from '@app/modules/widgets/bridges-widget/bridges-control/bridges-control.component';
import { BridgesWidgetService } from '@app/modules/widgets/bridges-widget/bridges-widget.service';
import { BridgesRequest } from '@app/modules/widgets/bridges-widget/models/bridges-request';
import { BridgesType } from '@app/modules/widgets/bridges-widget/models/bridges-type';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import { MapWidgetData } from '@app/modules/widgets/map-widget/model/interfaces/mapWidgetData';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { FeatureGroup } from 'leaflet';

@Component({
  selector: 'app-bridges-widget',
  templateUrl: './bridges-widget.component.html',
  styleUrls: ['./bridges-widget.component.scss'],
})
export class BridgesWidgetComponent extends MapWidgetComponent implements OnInit {

  @Input()
  data: BridgesWidgetData;

  currentLevel: FeatureGroup;

  legends: Array<MapLegend>;

  heightLevel: number = 1;
  tide: number = 1;

  constructor(public widgetService: BridgesWidgetService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.heightLevel = this.data.heightLevel;
    this.tide = this.data.tide;
    super.ngOnInit();
  }


  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.legends = [this.widgetService.getLegend(BridgesType.TIDE_SIMULATOR)];
    const bridgeRequest: BridgesRequest = {
      type: BridgesType.NAVIGATIONS,
      elevation: this.heightLevel,
    };
    this.dataSubscription = this.widgetService.getMapLevel(timeMachineData, this.sources,
      bridgeRequest, this.timeMachineService.forecast).subscribe((currentLevel: FeatureGroup) => {
      if (this.currentLevel) {
        this.map.removeLayer(this.currentLevel);
      }
      this.currentLevel = currentLevel;
      this.currentLevel.addTo(this.map);
      this.noData = false;
      this.isLoading = false;
    }, () => {
      this.noData = true;
      this.isLoading = false;
    });
  }

  heightChanged(newHeight: BridgesControlValues): void {
    this.heightLevel = newHeight.boatHeight;
    this.loadWidget(this.timeMachineService.getCurrentSelection());
  }
}

export interface BridgesWidgetData extends MapWidgetData {
  heightLevel: number;
  tide: number;
}
