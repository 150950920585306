import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective, Label } from 'ng2-charts';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MeteoService } from './meteo.service';

@Component({
  selector: 'app-meteo-widget',
  templateUrl: './meteo-widget.component.html',
  styleUrls: ['./meteo-widget.component.scss'],
})
export class MeteoWidgetComponent extends WidgetComponent implements OnInit {
  private tempAriaSerieName: string = 'Temperatura Aria';
  private umiditaSerieName: string = 'Umidita';

  public chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  chartType: ChartType = 'bar';
  chartLegend: boolean = true;
  chartLabels: Array<Label> = [];
  chartPlugins: [];

  chartData: Array<ChartDataSets> = [
    { data: [], label: '' },
  ];

  tempAria: Subscription;
  umidita: Subscription;
  dateTime: string;

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  constructor(public widgetService: MeteoService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    interval(600000) // 10 minutes polling
      .pipe(
        startWith(0),
        switchMap(() => this.widgetService.getTempAria()),
      )
      .subscribe((data: any) => {
        this.addSeriesToChart(data, this.tempAriaSerieName);
      });
  }


  /**
   *
   * @param data
   * @param serieName
   */
  addSeriesToChart(data: any, serieName: any): void {
    const tempData: any = JSON.parse(data);

    this.resetOldData();
    const chartDataObj: any = { data: [], label: serieName };
    this.dateTime = tempData[0].data;

    tempData.forEach((element: any) => {
      this.chartLabels.push(element.stazione);
      const value: any = this.formatTemperatureValue(element.valore);
      chartDataObj.data.push(value);
    });

    this.chartData.push(chartDataObj);
  }

  /**
   *
   * @param stringValue
   */
  formatTemperatureValue(stringValue: string): number {
    stringValue = stringValue.replace('\u00b0C', '');
    stringValue = stringValue.trim();
    return parseFloat(stringValue);
  }

  /**
   *
   */
  resetOldData(): void {
    this.chartLabels = [];
    this.chartData = [];
  }

}
