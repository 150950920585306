import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MapControlBarPanel } from '@app/features/pages/map-page/models';
import { MapMenuBar } from '@app/features/pages/map-page/models/map-menu-bar';
import { WidgetType } from '@app/shared/enums/widget-type';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class MapPageService {

  subMenuDataLoaded: EventEmitter<DataLoad> = new EventEmitter<DataLoad>();

  constructor(private http: HttpClient) {
  }

  loadSubMenuData(data: unknown, type: WidgetType): void {
    this.subMenuDataLoaded.emit({
      data: data,
      type: type,
    });
  }
}

export interface DataLoad {
  data: unknown;
  type: WidgetType;
}
