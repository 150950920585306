import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Feature } from 'geojson';
import { FeatureGroup, DivIcon, LatLngExpression, Layer, LatLngBounds, Marker, Tooltip, Util } from 'leaflet';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { EditDataModalComponent } from '@app/shared/components/modals/dashboard-modals/edit-data-modal/edit-data-modal.component';
import { Alert } from '@app/shared/models/alerts';
import { MarkerList } from '@app/shared/models/map/marker-list';
import {
  PmvRequestBody,
  PmvInsertResponse,
  PmvGetResponse,
  PmvEditableData,
} from '@app/modules/widgets/message-panels-widget/data/message-panel-setting';
import { FailureComponent } from '@app/shared/components/modals/global-modals/message-modals/failure/failure.component';
import { SuccessComponent } from '@app/shared/components/modals/global-modals/message-modals/success/success.component';
import { MessagePanelDataSource } from '@app/modules/widgets/message-panels-widget/models/message-panels-data-source';
import { TranslateService } from '@ngx-translate/core';

declare var L: any;

@Injectable()
export class MessagePanelsService extends MapWidgetService {
  markers: Array<MarkerList> = [];

  loadTooltipFromData(feature: Feature): Tooltip {
    const dataTooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [0, -36]
    });
    let template: string = '';
    template = template + `<b>${feature.properties['description']}</b><br/>`;
    dataTooltip.setContent(template);
    return dataTooltip;
  }

  addLevelTooltip(source: MessagePanelDataSource, selectedAlert: Alert, clusterLayer: any, menuDesc: string): (feature: Feature, latlng: LatLngExpression) => Layer {
    return (feature: Feature, latlng: LatLngExpression): Layer => {
      let selectedCamera: boolean = false;

      if (selectedAlert && selectedAlert.databag && selectedAlert.databag.panels && selectedAlert.databag.panels.includes(feature.id)) {
        selectedCamera = true;
      }

      const markerColor: string = '#08842C';
      const markerSelectedColor: string = '#A8E8EF';

      const dataTooltip: Tooltip = this.loadTooltipFromData(feature);
      L.Marker.Custom = L.Marker.extend({
        options: {
          type: '',
          menuName: '',
        },
      });
      const marker: any = new L.Marker.Custom(latlng, {
        icon: this.getMarkerIcon(selectedCamera, feature.properties.direction, markerColor, markerSelectedColor),
        // rotationAngle: rotateAngle,
        menuName: menuDesc,
        type: source.name,
      });

      marker.bindTooltip(dataTooltip);
      marker.on('click', () => {
        // TODO: open modal
        this.zone.run(() => {
          this.openPmvPanelDialog(feature);
        });
      });
      clusterLayer.addLayer(marker);
      this.markers.push({
        id: feature.properties.id || feature.id,
        markerOnMap: marker,
        additionalData: {
          rotate: 0,
          color: markerColor,
          selectedColor: markerSelectedColor,
          type: source.sourceType,
        },
      });
      marker.id = feature.properties.id || feature.id;
      return marker;
    };
  }

  getMarkerIcon(selected: boolean, direction: string, markerColor: string, markerSelectedColor: string): DivIcon {
    const color: string = selected ? markerSelectedColor : markerColor;

    const markerDown: any = require('!raw-loader!@assets/img/markers/pmv/m_avviso-down.svg');
    const markerTop: any = require('!raw-loader!@assets/img/markers/pmv/m_avviso-up.svg');
    const markerLeft: any = require('!raw-loader!@assets/img/markers/pmv/m_avviso-left.svg');
    const markerRight: any = require('!raw-loader!@assets/img/markers/pmv/m_avviso-right.svg');
    let marker: string = '';
    switch (direction) {
      case 'N':
        marker = markerTop.default;
        break;
      case 'E':
        marker = markerRight.default;
        break;
      case 'S':
        marker = markerDown.default;
        break;
      case 'W':
        marker = markerLeft.default;
        break;
    }

    const iconSettings: any = {
      mapIconUrl: marker,
      mapIconColor: color,
    };

    return new DivIcon({
      className: 'prova',
      iconSize: [36, 36],
      iconAnchor: [18, 36],
      html: Util.template(iconSettings.mapIconUrl, iconSettings),
    });
  }

  openPmvPanelDialog(feature: Feature): void {
    this.loaderService.show();
    this.getPmvMessage(feature.id).subscribe((pmvInfo: PmvGetResponse) => {
      const metadata: any = JSON.parse(pmvInfo.schema);

      metadata.data.actual_message = pmvInfo.message;
      metadata.schema.properties.messages.enum = ['Incidente', 'Rallentamenti', 'Code', 'Ristringimento di corsia'];
      metadata.layout[0].title = feature.properties['description'];

      const editDataRef: MatDialogRef<EditDataModalComponent, PmvEditableData> = this.dialog.open(EditDataModalComponent, {
        data: {
          configuration: JSON.stringify(metadata),
          type: 'message_panels_widget',
          name: pmvInfo.id,
        },
      });
      editDataRef.afterOpened().subscribe(() => {
        this.loaderService.hide();
      });
      editDataRef.afterClosed().subscribe((result: PmvEditableData) => {
        if (result) {
          try {
            this.loaderService.show();
            this.savePmv(result).subscribe(() => {
              this.dialog.open(SuccessComponent, {});
              this.loaderService.hide();
            }, (error: any) => {
              this.dialog.open(FailureComponent, {});
              this.loaderService.hide();
            });
          } catch (e) {
            this.dialog.open(FailureComponent, {});
            this.loaderService.hide();
          }
        }
      });
    }, (error: any) => {
      this.dialog.open(FailureComponent, {});
      this.loaderService.hide();
    });
  }

  public getSingleMapLevel(timeMachineData: TimeMachineData, source: MessagePanelDataSource, additionalData?: any): Observable<FeatureGroup> {

    const featureGroup: FeatureGroup = new FeatureGroup<any>();
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
    let bboxFilter: string = '';
    let combinedCondition: any;
    let condition: any;
    additionalData.bbox.forEach((element: LatLngBounds) => {
      if (bboxFilter === '') {
        bboxFilter += 'BBOX(geometry, ' + element.toBBoxString() + ') ';
        condition = new L.Filter.BBox('geometry', element, L.CRS.EPSG4326);
      } else {
        bboxFilter += 'OR BBOX(geometry, ' + element.toBBoxString() + ') ';
        const bbox: any = new L.Filter.BBox('geometry', element, L.CRS.EPSG4326);
        if (combinedCondition) {
          combinedCondition = new L.Filter.Or(combinedCondition, bbox);
        } else {
          combinedCondition = new L.Filter.Or(bbox, condition);
        }
      }
    });
    if (!combinedCondition) {
      combinedCondition = condition;
    }
    const translate: TranslateService = this.translateService;
    const menuDesc: string = translate.instant(`WIDGETS.GEOSERVER.${source.sourceData.typeNs.toUpperCase()}.${source.sourceData.typeName.toUpperCase()}.${source.sourceType.toUpperCase()}`);
    const clusterLayer: any = L.markerClusterGroup({
      disableClusteringAtZoom: 14,
      iconCreateFunction: this.createClusterIcon(source),
      showCoverageOnHover: true,
      animate: false,
      spiderfy: true,
      spiderfyOnMaxZoom: true,
      zoomToBoundsOnClick: true,
      maxClusterRadius: source.sourceData.maxClusterRadius ? source.sourceData.maxClusterRadius : 80,
      menuName: menuDesc,
      type: source.name,
    });

    const layer: any = new L.WFS({
      crs: L.CRS.EPSG4326,
      url: url,
      filter: additionalData ? combinedCondition : null,
      typeNS: source.sourceData.typeNs,
      typeName: source.sourceData.typeName,
      style: source.sourceData.style,
    }, new L.Format.GeoJSON({
      crs: L.CRS.EPSG4326,
      pointToLayer: this.addLevelTooltip(source, additionalData.selectedAlert, clusterLayer, menuDesc),
    }));

    if (source.sourceData.cluster) {
      featureGroup.addLayer(clusterLayer);
    } else {
      layer.options.type = source.sourceType;
      featureGroup.addLayer(layer);
    }
    return of(featureGroup);
  }

  createClusterIcon(source: MessagePanelDataSource): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const markerSvg: any = require('!raw-loader!@assets/img/markers/pmv/m_avviso.svg');
      const total: Array<Marker> = cluster.getChildCount();
      const widgetLevel: string = source.name;
      const iconSettings: any = {
        mapIconUrl: markerSvg.default,
        mapIconColor: source.sourceData.marker.clusterColor
      };
      return new DivIcon({
        iconSize: [46, 46],
        iconAnchor: [23, 46],
        className: `arcgis-marker marker-group arcgis-marker--${widgetLevel}`,
        html: `${Util.template(iconSettings.mapIconUrl, iconSettings)}
        <div class="d-flex flex-column ">
          <div><span class="marker-group__svg_label1">${total}</span></div>
        </div>`
      });
    };
  }

  public getMapLevel(timeMachineData: TimeMachineData,
    sources: Array<MessagePanelDataSource>, additionalData?: any): Observable<FeatureGroup> {
    const mapLevel: FeatureGroup = new FeatureGroup();
    const observables: Array<Observable<FeatureGroup>> = sources.filter((w: WidgetDataSource) => {
      return w.sourceType === 'panels';
    }).map((source: MessagePanelDataSource) => {
      source.sourceData = JSON.parse(source.configuration).sourceData;
      return this.getSingleMapLevel(timeMachineData, source, additionalData);
    });
    return forkJoin(observables).pipe(
      map((features: Array<FeatureGroup>) => {
        features.forEach((f: FeatureGroup) => {
          f.addTo(mapLevel);
        });
        return mapLevel;
      }),
    );
  }

  savePmv(result: PmvEditableData): Observable<PmvInsertResponse> {
    if (typeof result.info === 'object' && Object.keys(result.info).length === 0) {
      result.info = '';
    }

    const body: PmvRequestBody = {
      message: result.info.messages || result.info || '',
    };
    const url: string = `/gateway/mcapi/traffic/pmv/${result.id}`;
    return this.http.put<PmvInsertResponse>(url, body);
  }

  getPmvMessage(id: string | number): Observable<PmvGetResponse> {
    if (typeof id === 'string') {
      id = id.replace('pmv.', '');
    }
    const url: string = `/gateway/mcapi/traffic/pmv/${id}`;
    return this.http.get<PmvGetResponse>(url);
  }
}
