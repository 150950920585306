import { Injectable } from '@angular/core';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { PEDESTRIAN_FLOW_LEGEND, PEDESTRIAN_FLOW_SENSOR_LEGEND } from '@app/modules/widgets/pedestrian-flows-widget/mock/legend';
import { TranslateService } from '@ngx-translate/core';
import { Feature, FeatureCollection, Point } from 'geojson';
import { DivIcon, FeatureGroup, geoJSON, LatLng, Layer, Marker, MarkerOptions, Popup } from 'leaflet';
import { orderBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

declare var L: any;

@Injectable()
export class PedestrianFlowsWidgetService extends MapWidgetService {

  private getRay(total: number): number {
    switch (true) {
      case total < 500:
        return 40;
      case total < 1000:
        return 50;
      case total < 1500:
        return 60;
      case total < 2000:
        return 70;
      case total < 4000:
        return 80;
      case total < 8000:
        return 90;
      default:
        return 100;
    }
  }

  loadSensors(timeMachineData: TimeMachineData,
              sources: Array<WidgetDataSource>): Observable<FeatureCollection> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.name === 'pedestrian-flow-sensors';
    });
    if (mainSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 1);
      return this.http.get<FeatureCollection>(url);
    }
  }

  addStationPopup(timeMachineData: TimeMachineData, mode: PedestrianFlowMode): (f: Feature<Point>) => Layer {
    return (f: Feature<Point>): Layer => {
      f.properties = orderBy(f.properties, ['location', 'ASC']);
      const translate: TranslateService = this.translateService;
      const layer: FeatureGroup = new FeatureGroup();
      const popup: Popup = new Popup();
      let totalIn: number = 0;
      let totalOut: number = 0;
      let template: string = '';
      let perimeter: boolean = false;
      if (f.properties.length) {
        const stationName: string = f.properties[0]['station'];
        template = `<h6>${stationName}</h6>`;
        if (mode === PedestrianFlowMode.SHOW_FLOW) {
          f.properties.forEach((s: PedestrianFlowStation) => {
            const flowValue: number = s.value;
            const sensorPerimeter: boolean = s.type === 'I';
            template += `<i ${sensorPerimeter ? 'class="perimeter"' : ''}>${s.location}</i>: ${flowValue}<br/>`;
            if (s.direction === 'IN') {
              totalIn += flowValue;
            } else {
              totalOut += flowValue;
            }
            if (sensorPerimeter) {
              perimeter = true;
            }
          });
          template += `<br/><b>${translate.instant('WIDGETS.PEDESTRIAN_FLOW.TOTAL_IN')}</b>: ${totalIn}<br/>`;
          template += `<b>${translate.instant('WIDGETS.PEDESTRIAN_FLOW.TOTAL_OUT')}</b>: ${totalOut}<br/>`;

          let minutesRange: number;
          if (typeof timeMachineData === 'number') {
            minutesRange = 60;
          } else {
            minutesRange = timeMachineData.to.diff(timeMachineData.from, 'minutes');
          }
          const flowIn: number = totalIn / minutesRange;
          const flowOut: number = totalOut / minutesRange;
          template += `<br/><h6><b>${translate.instant('WIDGETS.PEDESTRIAN_FLOW.FLOW_IN')}</b>: ${flowIn.toFixed(2)}</h6>`;
          template += `<h6><b>${translate.instant('WIDGETS.PEDESTRIAN_FLOW.FLOW_OUT')}</b>: ${flowOut.toFixed(2)}</h6>`;
          template += `<br/><i class="perimeter">${translate.instant('WIDGETS.PEDESTRIAN_FLOW.PERIMETER_FLOWS')}</i>`;
          if (!this.timeMachineService.rangeOn) {
            template += `<br/><br/><i>${translate.instant('WIDGETS.PEDESTRIAN_FLOW.DEFAULT_DATA')}</i>`;
          }
        } else {
          f.properties.forEach((s: PedestrianFlowStation) => {
            const sensorPerimeter: boolean = s.type === 'I';
            if (sensorPerimeter) {
              perimeter = true;
            }
          });
        }
      } else {
        template = `<h6>${f.id}</h6>`;
      }
      popup.setContent(template);
      let markerOptions: MarkerOptions;
      let flowClass: string;
      if (totalIn === totalOut) {
        flowClass = 'flow-counter--equal';
      } else if (totalIn > totalOut) {
        flowClass = 'flow-counter--in';
      } else if (totalIn < totalOut) {
        flowClass = 'flow-counter--out';
      }
      if (mode === PedestrianFlowMode.SHOW_SENSORS) {
        if (f.properties.length) {
          markerOptions = {
            icon: new DivIcon({
              className: `flow-marker ${perimeter ? 'flow-marker--perimeter' : ''}`,
              html: `<div class=\'marker-pin\'></div><i style="color:#f7931e;" class=\'icon-venice_sensori-flussi-pedonali'></i>`,
            }),
          };
        } else {
          markerOptions = {
            icon: new DivIcon({
              className: `flow-marker ${perimeter ? 'flow-marker--perimeter' : ''}`,
              html: `<div class=\'marker-pin\'></div><i style="color:#999999;" class=\'icon-venice_sensori-flussi-pedonali'></i>`,
            }),
          };
        }
      } else {
        if (f.properties.length) {
          markerOptions = {
            icon: new DivIcon({
              className: `flow-marker ${perimeter ? 'flow-marker--perimeter' : ''}`,
              html: `<div class=\'flow-counter flow-counter--equal ${flowClass} marker-pin\'>0</div>`,
            }),
          };
        } else {
          markerOptions = {
            icon: new DivIcon({
              className: `flow-marker ${perimeter ? 'flow-marker--perimeter' : ''}`,
              html: `<div class=\'flow-counter flow-counter--broken ${flowClass} marker-pin\'>N/D</div>`,
            }),
          };
        }
      }
      const position: LatLng = new LatLng(f.geometry.coordinates[1], f.geometry.coordinates[0]);
      if (mode === PedestrianFlowMode.SHOW_SENSORS) {
        const marker: Marker = new Marker(position, markerOptions);
        marker.addTo(layer);
        marker.bindPopup(popup);
        marker.on('click', () => {
          popup.openPopup(position);
        });
      } else {
        if (totalIn + totalOut === 0) {
          const marker: Marker = new Marker(position, markerOptions);
          marker.addTo(layer);
          marker.bindPopup(popup);
          marker.on('click', () => {
            popup.openPopup(position);
          });
        } else {
          const graphWidth: number = this.getRay(totalIn + totalOut);
          const chart: any = L.minichart(position, {
            data: [totalOut, totalIn],
            colors: ['#cfb9a5', '#96bdc6'],
            type: 'pie',
            width: graphWidth,
            labels: [totalOut, totalIn],
          });
          if (perimeter) {
            chart.setStyle({
              stroke: true,
              color: '#0868b4',
              weight: 4,
            });
          }
          chart.addTo(layer);
          if (f.properties.length) {
            chart.bindPopup(popup);
            chart.on('click', () => {
              popup.openPopup(position);
            });
          }
        }
      }
      return layer;
    };
  }

  getMapLevel(timeMachineData: TimeMachineData,
              sources: Array<WidgetDataSource>, additionalData: PedestrianFlowMode): Observable<FeatureGroup> {
    return this.loadSensors(timeMachineData, sources).pipe(map((sensorsList: FeatureCollection) => {
      return geoJSON(sensorsList, {
        pointToLayer: this.addStationPopup(timeMachineData, additionalData),
      });
    }));
  }

  getLegend(mode: PedestrianFlowMode): MapLegend {
    if (mode === PedestrianFlowMode.SHOW_SENSORS) {
      return PEDESTRIAN_FLOW_SENSOR_LEGEND;
    } else {
      return PEDESTRIAN_FLOW_LEGEND;
    }
  }
}

export enum PedestrianFlowMode {
  SHOW_SENSORS = 'showSensor',
  SHOW_FLOW = 'showFlow'
}

export interface PedestrianFlowStation {
  direction: 'IN' | 'OUT';
  location: string;
  station: string;
  value: number;
  type: 'T' | 'I';
}
