import { Component, Injector, OnInit } from '@angular/core';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TideForecastService } from '@app/modules/widgets/tide-forecast-widget/tide-forecast.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';

@Component({
  selector: 'app-tide-forecast-widget',
  templateUrl: './tide-forecast-widget.component.html',
  styleUrls: ['./tide-forecast-widget.component.scss'],
})
export class TideForecastWidgetComponent extends WidgetComponent implements OnInit {

  imageSrc: string;

  constructor(public widgetService: TideForecastService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.loadSources();
    this.isLoading = true;
    this.imageSrc = this.sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'tide-forecast-graph';
    }).rewriteUrl;
  }

  imageLoaded(): void {
    this.isLoading = false;
    const mainSource: WidgetDataSource = this.sources[0];
    mainSource.status = DataSourceStatus.AVAILABLE;
    this.sourceStatus = DataSourceStatus.AVAILABLE;
  }

  imageError(): void {
    this.isLoading = false;
    const mainSource: WidgetDataSource = this.sources[0];
    mainSource.status = DataSourceStatus.UNAVAILABLE;
    this.sourceStatus = DataSourceStatus.UNAVAILABLE;
  }

}
