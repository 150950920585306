import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { PresencesClusterWidgetComponent } from './presences-cluster-widget.component';
import { PresencesClusterService } from './presences-cluster.service';


@NgModule({
  declarations: [PresencesClusterWidgetComponent],
  imports: [
    CommonModule,
    MapWidgetModule
  ],
  providers: [PresencesClusterService,
    { provide: 'presences_cluster_service', useClass: PresencesClusterService }],
  exports: [PresencesClusterWidgetComponent],
})
export class PresencesClusterWidgetModule {
}
