import { AfterViewInit, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { AlertsService } from '@app/modules/widgets/alerts-widget/alerts.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { Alert } from '@app/shared/models/alerts';
import { interval, Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { AlertInsertResponse } from '@app/shared/models/venice-data-lake/update-response';

@Component({
  selector: 'app-alerts-widget',
  templateUrl: './alerts-widget.component.html',
  styleUrls: ['./alerts-widget.component.scss'],
})
export class AlertsWidgetComponent extends WidgetComponent implements OnInit, AfterViewInit, OnDestroy {
  private DEFAULT_REFRESH_INTERVAL: number = 30 * 1000;
  refreshInterval: Observable<number>;
  subscriptionActive: boolean;

  events: Array<Alert>;
  intervalSubscription: Subscription;

  constructor(public widgetService: AlertsService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngOnInit(): void {
    this.refreshInterval = interval(this.DEFAULT_REFRESH_INTERVAL);
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.refreshWidget(timeMachineData, 0);
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.intervalSubscription = this.refreshInterval.subscribe((value: number) => {
      if (this.subscriptionActive) {
        const offset: number = (1 + value) * this.DEFAULT_REFRESH_INTERVAL;
        this.refreshWidget(timeMachineData, offset);
      } else {
        this.intervalSubscription.unsubscribe();
      }
    });
  }

  refreshWidget(timeMachineData: TimeMachineData, offset: number): void {
    this.isLoading = true;
    this.dataSubscription = this.widgetService.loadData(timeMachineData, this.sources).subscribe((alerts: Array<Alert>) => {
      this.isLoading = false;
      this.events = alerts;
      this.noData = false;
    }, (error: any) => {
      this.isLoading = false;
      this.noData = true;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionActive = false;
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  onAlertClick(alert: Alert): void {
    alert.read = true;
    const domain: string = this.authenticationService.readUserData().dom;
    if (domain) {
      this.widgetService.updateData(alert.indexId, domain).subscribe((response: AlertInsertResponse) => {
        // this.updateNewAlertsIcon();
      });
    }
  }

  checkNewAlert(alert: Alert): boolean {
    return moment(alert.createdAt).format('HH:mm') === moment().format('HH:mm');
  }

  centerOnMap(alert: Alert): void {
    if (alert.position || alert.shape) {
      this.widgetsEventBusService.selectAlert(alert);
      this.widgetsEventBusService.centerAllMap(alert, null);
    }
  }
}
