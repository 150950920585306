import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { ChartsModule } from 'ng2-charts';
import { RadarWidgetComponent } from './radar-widget.component';
import { RadarService } from './radar.service';


@NgModule({
  declarations: [RadarWidgetComponent],
  imports: [
    CommonModule,
    MatTableModule,
    WidgetModule,
    TranslateModule,
    ChartsModule,
    MatSortModule,
    MatTreeModule,
    AngularResizedEventModule,
    MatExpansionModule,
  ],
  providers: [RadarService],
  exports: [RadarWidgetComponent],
})
export class RadarWidgetModule {
}
