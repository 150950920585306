import { Injectable } from '@angular/core';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { WIND_LEGEND } from '@app/modules/widgets/wind-widget/data';
import { Station } from '@app/modules/widgets/wind-widget/model';
import { TranslateService } from '@ngx-translate/core';
import { DivIcon, FeatureGroup, LatLng, LayerGroup, Marker, Tooltip } from 'leaflet';
import 'leaflet-rotatedmarker';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindService extends MapWidgetService {
  private static getBeaufortScale(strength: number): number {
    switch (true) {
      case(strength <= 0.3):
        return 0;
      case(strength > 0.3 && strength <= 1.5):
        return 1;
      case(strength > 1.5 && strength <= 3.5):
        return 2;
      case(strength > 3.5 && strength <= 5.5):
        return 3;
      case(strength > 5.5 && strength <= 8):
        return 4;
      case(strength > 8 && strength <= 10.8):
        return 5;
      case(strength > 10.8 && strength <= 13.9):
        return 6;
      case(strength > 13.9 && strength <= 17.2):
        return 7;
      case(strength > 17.2 && strength <= 20.8):
        return 8;
      case(strength > 20.8 && strength <= 24.5):
        return 9;
      case(strength > 24.5 && strength <= 28.5):
        return 10;
      case(strength > 28.5 && strength <= 32.6):
        return 11;
      case(strength > 32.6):
        return 12;
    }
  }

  private getDataLabel(data: any, measure?: string, precision?: number): string {
    const translate: TranslateService = this.translateService;
    if (typeof data === 'number' && precision) {
      data = data.toPrecision(precision);
    }
    return data ? data + measure : translate.instant('COMMONS.UNAVAILABLE');
  }

  private getBeaufortLabel(strength: number): string {
    return this.translateService.instant('WIDGETS.WIND.BEAUFORT.' + strength.toString());
  }

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>): Observable<DataResult<Station>> {
    const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'arpav-meteo-stations';
    });
    if (widgetDataSource) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, widgetDataSource);
      return this.http.get<DataResult<Station>>(url);
    }
  }

  public loadStationInfo(s: Station): LayerGroup {
    const translate: TranslateService = this.translateService;
    const l: LayerGroup = new LayerGroup();
    const position: LatLng = new LatLng(s.latDDN, s.lonDDE);
    const arrowDirection: number = s.wind_direction ? Math.floor((s.wind_direction + 180) % 360) : null;
    const direction: number = s.wind_direction ? Math.floor((s.wind_direction) % 360) : null;
    const tooltip: Tooltip = new Tooltip({
      direction: 'top',
      offset: [5, -15],
    });
    const tide: string = (s.value !== '') ? (Number(s.value) * 100).toPrecision(2) : null;
    const windStrength: string = s.wind_speed ? this.getBeaufortLabel(WindService.getBeaufortScale(s.wind_speed)) : null;
    tooltip.setContent(`<h6><b>${translate.instant('WIDGETS.WIND.STATION')}:</b> ${s.station}</h6><br/>
                      <b>${translate.instant('WIDGETS.WIND.PRESSURE')}:</b> ${this.getDataLabel(s.pressure, ' hPa')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.RAINING')}:</b> ${this.getDataLabel(s.rainfall, ' mm')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.AVERAGE_WIND_DIRECTION')}:</b> ${this.getDataLabel(direction, '°')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.AVERAGE_WIND_SPEED_METERS')}:</b> ${this.getDataLabel(s.wind_speed, ' m/s')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.AVERAGE_WIND_SPEED_NODES')}:</b> ${this.getDataLabel(s.wind_speed * 1.944, ' kn', 2)}<br/>
                      <b>${translate.instant('WIDGETS.WIND.MAX_WIND_SPEED_METERS')}:</b> ${this.getDataLabel(s.wind_speed_gust, ' m/s')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.MAX_WIND_SPEED_NODES')}:</b> ${this.getDataLabel(s.wind_speed_gust * 1.944, ' kn', 2)}<br/>
                      <b>${translate.instant('WIDGETS.WIND.WIND_STRENGTH')}:</b> ${this.getDataLabel(windStrength, '')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.HUMIDITY')}:</b> ${this.getDataLabel(s.humidity, ' g/m3')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.TIDE')}:</b> ${this.getDataLabel(tide, ' cm')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.WATER_TEMPERATURE')}:</b> ${this.getDataLabel(s.water_temp, ' °C')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.WAVE_MAX_HEIGHT')}:</b> ${this.getDataLabel(s.wave_max_height, ' m')}<br/>
                      <b>${translate.instant('WIDGETS.WIND.WAVE_SIGNIFICANT_HEIGHT')}:</b> ${this.getDataLabel(s.wave_significant_height, ' m')}<br/><br/>
                      <i>${translate.instant('WIDGETS.WIND.RELEVANCE_TIME')}: ${this.getDataLabel(moment(s.time).format('LLLL'), '')}</i>`);
    if (arrowDirection) {
      const arrowMarker: Marker = new Marker(position);
      const arrowMarkerIcon: DivIcon = new DivIcon({
        className: `arrow-marker arrow-marker-${WindService.getBeaufortScale(s.wind_speed)}`,
        iconSize: [60, 60],
        iconAnchor: [30, 30],
        html: '<i class=\'icon-venice icon-venice_lancetta-vento\'>',
      });
      arrowMarker.options.zIndexOffset = 1;
      arrowMarker.options.rotationAngle = arrowDirection;
      arrowMarker.options.rotationOrigin = 'center';
      arrowMarker.setIcon(arrowMarkerIcon);
      arrowMarker.bindTooltip(tooltip);
      arrowMarker.addTo(l);
      const textMarker: Marker = new Marker(position);
      const textColor: string = WindService.getBeaufortScale(s.wind_speed) <= 6 ? 'black' : 'white';
      const textMarkerIcon: DivIcon = new DivIcon({
        className: `text-marker text-marker--${textColor}`,
        iconSize: [60, 60],
        iconAnchor: [30, 30],
        html: `<div class="label">${(s.wind_speed * 1.944).toFixed(0)}</div>`,
      });
      textMarker.options.zIndexOffset = 2;
      textMarker.setIcon(textMarkerIcon);
      textMarker.bindTooltip(tooltip);
      textMarker.addTo(l);
    } else {
      const marker: Marker = new Marker(position);
      const disabledMarkerIcon: DivIcon = new DivIcon({
        className: `station-marker station-marker--disabled`,
        iconSize: [40, 40],
        html: '<i class=\'icon-venice icon-venice_vento\'>',
      });
      marker.setIcon(disabledMarkerIcon);
      marker.bindTooltip(tooltip);
      marker.addTo(l);
    }
    return l;
  }

  public getLegend(): MapLegend {
    return WIND_LEGEND;
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<FeatureGroup> {
    return this.loadData(timeMachineData, sources).pipe(
      map((stationResponse: DataResult<Station>) => {
        const featureGroup: FeatureGroup = new FeatureGroup();
        stationResponse.data.forEach((s: Station) => {
          featureGroup.addLayer(this.loadStationInfo(s));
        });
        return featureGroup;
      }),
    );
  }
}
