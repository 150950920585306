import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { FeatureCollection, Point } from 'geojson';
import { FeatureGroup, geoJSON } from 'leaflet';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class HighwayTrafficService extends MapWidgetService {

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>): Observable<FeatureGroup> {
    const panelDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
      return s.sourceType === 'panels';
    });

    if (panelDataSource) {
      return this.http.get<FeatureCollection<Point>>(panelDataSource.rewriteUrl).pipe(
        switchMap((f: FeatureCollection<Point>) => {
          return of(geoJSON(f));
        }),
      );
    }
  }
}
