<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
  [isEditable]="isEditable" (sendReloadWidget)="reloadWidget()" [timeMachineOn]="timeMachineOn"
  (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus" [isLoading]="isLoading" [noData]="noData">

  <div id="openweathermap-widget" (resized)="handleResize($event)" [ngStyle]="{'font-size.px': fontSize}">
    <div id="container-openweathermap-widget" *ngIf="!noData">

      <div class="widget-left" *ngIf="currentWeatherData">
        <div class="widget-left-menu widget-left-menu">
          <div class="widget-left-menu__layout">
            <!-- <mat-select panelClass="widget-left-menu__header" (selectionChange)="selectCity($event)" [(ngModel)]="selectedCity">
              <mat-option *ngFor="let option of cities" [value]="option.name">
                {{ option.name }}
              </mat-option>
            </mat-select> -->
            <h2 class="widget-left-menu__header">{{'WIDGETS.WEATHER.CITY.' + currentWeatherData.city | uppercase | translate}}</h2>
          </div>
        </div>
        <div class="widget-left__body">
          <div class="weather-left-card">
            <div class="weather-left-card__row1">
              <img src="{{currentWeatherData.weather_icon}}"
                alt="{{currentWeatherData.name}}" class="weather-left-card__img">
              <div class="weather-left-card__col">
                <p class="weather-left-card__number">{{currentWeatherData.temp}}<span
                    class="weather-left-card__degree">{{'WIDGETS.WEATHER.TEMP_UNIT' | translate}}</span></p><span
                  class="weather-left-card__rising" [hidden]="true">and rising</span>
              </div>
            </div>
            <div class="weather-left-card__row2">
              <p class="weather-left-card__means" *ngIf="currentWeatherData.weather_id">{{'WIDGETS.WEATHER.DESCRIPTION.' + currentWeatherData.weather_id | translate}}</p>
              <p class="weather-left-card__wind">{{currentWeatherData.wind_description}}</p>
            </div>
          </div>
          <div class="widget-left__calendar"
            *ngIf="currentWeatherData.forecast && showForecast">
            <ul class="calendar">
              <li *ngFor="let index of currentWeatherData.forecast" class="calendar__item">
                {{index.dayName}}<br>
                {{index.shortDate}}<br>
                <img src="{{index.weather_icon}}" alt="{{index.dayName}}">
                <div class="detail">
                  <div class="right">
                    <span class="red">{{index.temp_max}}{{'WIDGETS.WEATHER.TEMP_UNIT' | translate}}</span><br>
                    <span class="azure">{{index.temp_min}}{{'WIDGETS.WEATHER.TEMP_UNIT' | translate}}</span>
                  </div>
                </div>
              </li>

            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>
</app-widget>
