export enum RatioType {
  NONE = 'none',
  IN = 'in',
  OUT = 'out'
}

export interface Ratio {
  value: number;
  type: RatioType;
}

export interface Ratios {
  [propName: string]: Ratio;
}

export interface Scenario {
  total: number;
  tpl_ratio: number;
  tpl_metro_ratio: number;
  scenario_ratio: number;
}

export interface AmatConfiguration {
  ratios: Ratios;
  scenario: Scenario;
}
