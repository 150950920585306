<h1 mat-dialog-title>{{'MENU.MODALS.LOAD.TITLE' | translate}}</h1>
<div mat-dialog-content>
  <p>{{'MENU.MODALS.LOAD.TEXT' | translate}}</p>
  <mat-selection-list [multiple]="false" #dashboards [(ngModel)]="dashboardSelected"
                      (ngModelChange)="updateForm($event)">
    <mat-list-option [value]="null">
      {{'MENU.MODALS.LOAD.DEFAULT_DASHBOARD' | translate}}
    </mat-list-option>
    <mat-list-option *ngFor="let dashboard of dashboardItems" [value]="dashboard">
      {{dashboard}}
    </mat-list-option>
  </mat-selection-list>
  <br>
  <br>
  <hr/>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button [color]="'warn'"
          [mat-dialog-close]="false">{{'MENU.MODALS.LOAD.CANCEL' | translate}}</button>
  <button mat-raised-button [color]="'primary'" [disabled]="result === undefined" [mat-dialog-close]="result"
          cdkFocusInitial>{{'MENU.MODALS.LOAD.OK' | translate}}</button>
</div>
