import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppOptions, SpecialPermissionConfig } from '@app/shared/models/app-config/app-options';
import { MenuVoice } from '@app/shared/models/menu/menu-voice';
import { AppConfigService } from '@services/app-config/app-config.service';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appConfigService: AppConfigService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let canActivate: boolean = false;
    if (route.data.checkOnMenu) {
      const menu: Array<MenuVoice> = this.appConfigService.getMenu();
      const url: string = state.url;
      const activeMenuVoice: MenuVoice = menu.find((m: MenuVoice) => {
        return url.includes(m.link);
      });
      if (activeMenuVoice) {
        canActivate = this.authenticationService.checkPermissionsConfig(activeMenuVoice);
      }
    }
    if (route.data.checkOnSpecial) {
      const pageName: string = route.data.specialPageName;
      const config: AppOptions = this.appConfigService.getOptions();
      if (config.specialPermissions) {
        const pageConfig: SpecialPermissionConfig = this.appConfigService.getSpecialPermissionConfig(pageName);
        if (pageConfig) {
          canActivate = this.authenticationService.checkPermissionsConfig(pageConfig);
        }
      }
    }
    if (!canActivate) {
      this.router.navigate(['/error/unauthorized']);
    }
    return canActivate;
  }

}
