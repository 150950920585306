import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Changelog } from '@app/shared/models/changelogs/changelog';

@Injectable()
export class ChangelogsService {

  constructor(private http: HttpClient) {
  }

  getChangelog(version: string): Observable<Array<Changelog>> {
    const baseVersion: string = `${version.split('.')[0]}.${version.split('.')[1]}.${'0'}`;
    const uri: string = encodeURIComponent(`version=le="${version}";version=ge="${baseVersion}"`);
    return this.http.get<Array<Changelog>>(`/gateway/mcapi/manager/changelogs?rsql=${uri}`);
  }
}
