<div class="map-control-sub-bar">
  <mat-expansion-panel class="panel" [ngClass]="{'panel--expanded': panelExpanded}"
                       [expanded]="field.staticSubMenu || selectedRoutes.length"
                       (opened)="panelOpened(true)"
                       (closed)="panelOpened(false)">
    <mat-expansion-panel-header [ngStyle]="{'background-color': field.color}">
      <mat-panel-title>
        <h6 *ngIf="field">{{'PAGES.MAP.SUBMENU.' + field.label | translate}}</h6>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="panel__body">
      <p *ngIf="field.staticSubMenu">{{field.staticSubMenu.content | translate}}</p>
      <ng-select
        *ngIf="field && field.submenu && field.submenuActive && field.submenu.length"
        [items]="field.submenu"
        [multiple]="true"
        [closeOnSelect]="false"
        [hideSelected]="true"
        groupBy="group"
        [groupValue]="extractGroupValue"
        bindLabel="label"
        [placeholder]="'PAGES.MAP.SUBMENU.SELECTED_ELEMENTS' | translate"
        (add)="onSubmenuChange($event, true, field)"
        (remove)="onSubmenuChange($event['value'], false, field)"
        (clear)="onClearAll(field)"
        [(ngModel)]="selectedRoutes">
        <ng-template ng-optgroup-tmp let-item="item" let-index="index">
          <div class="item d-flex flex-row">
            <div class="item__icon">
            <span [ngClass]="{'item__icon--border': item.color === '#FFFFFF'}"
                  [style.background-color]="item.color"
                  [style.color]="item.textColor">{{item.labelIcon}}</span>
            </div>
          </div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <div class="selected-item" [style.background-color]="item.color"
               [ngClass]="{'selected-item--border': item.color === '#FFFFFF'}">
          <span [style.color]="item.textColor" class="ng-value-icon left" (click)="clear(item)"
                aria-hidden="true">×</span>
            <span class="selected-item__label"
                  [style.color]="item.textColor">{{item.labelIcon}}</span>
            <span class="selected-item__label" [style.color]="item.textColor">&nbsp;{{item.label}}</span>
          </div>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          <div class="item d-flex flex-row">
            <div class="item__label">{{item.label}}</div>
          </div>
        </ng-template>
      </ng-select>
    </div>
  </mat-expansion-panel>
</div>
