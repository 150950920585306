import { SerializedTemperatures } from '@app/modules/widgets/temperature-widget/model/serialized-temperatures';
import moment, { Moment } from 'moment';

export class Temperature {
  time: Moment;
  updateOn: string;
  latDDN: number | null;
  latDMSN: number | null;
  lonDDE: number | null;
  lonDMSE: number | null;
  order: number | null;
  station: string;
  stationAbbr: string;
  stationId: string;
  value: string;
  trend: number | null;
  humidity: string;
  humidityTrend: number | null;

  constructor (s: SerializedTemperatures) {
    this.time = moment(s.time);
    this.updateOn = moment(s.time).format('DD/MM/YYYY HH:mm');
    this.latDDN = s.latDDN;
    this.latDMSN = s.latDMSN;
    this.lonDDE = s.lonDDE;
    this.lonDMSE = s.lonDMSE;
    this.order = s.order;
    this.station = s.station;
    this.stationAbbr = s.station_abbr;
    this.stationId = s.station_id;
    this.value = s.value;
    this.trend = s.trend;
  }
}
