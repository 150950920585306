<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isEditable]="isEditable" [isLoading]="isLoading" [noData]="noData">
  <div class="flow-control d-flex flex-column justify-content-start align-items-center">
    <div class="row">
      <div class="col-8 text-left">
        <h6>{{'WIDGETS.FLOW_CONTROL.ZONES' | translate}}</h6>
      </div>
      <div class="col-4 text-left">
        <h6>{{'WIDGETS.FLOW_CONTROL.GROUPS' | translate}}</h6>
      </div>
    </div>
    <div class="row d-flex flow-control__table">
      <div class="col-8 d-flex">
        <div class="row group d-flex" (mouseleave)="deselectAllZones()">
          <div class="col-3 col-sm-12 col-md-3 text-left entry d-flex flex-row align-items-center"
               [ngClass]="{'entry--selected': zone.selected}"
               *ngFor="let zone of zones"
               (click)="activateZone($event, zone)"
               (mouseenter)="selectZone($event,zone)">
            <div
              [ngClass]="{
              'ball--unreachable': zone.camera && zone.camera.status === 1,
              'ball--unactive': !zone.active}"
              [ngStyle]="zone.camera.status !== 1 && {'background-color': zone.color}"
              class="ball">
              <img *ngIf="zone.camera.stream && zone.camera.status !== 1" [src]="'assets/img/markers/waterflow/telecamera-01.png'" alt="camera"/>
              <img *ngIf="zone.camera && zone.camera.status === 1" [src]="'assets/img/markers/waterflow/telecamera-red.png'" alt="camera"/>
            </div>
            {{zone.name}}
          </div>
        </div>
      </div>
      <div class="col-4 d-flex">
        <div class="row group d-flex" (mouseleave)="deselectAllZones()">
          <div class="col-12 text-left entry d-flex flex-row align-items-center" (click)="selectAllGroups()">
            <div
              [ngClass]="{
              'ball--zero': allSelected,
              'ball--unactive': !allSelected}"
              class="ball">
            </div>
            {{'WIDGETS.FLOW_CONTROL.ALL' | translate | uppercase}}
          </div>
          <div class="col-12 text-left entry  d-flex flex-row align-items-center" *ngFor="let group of groups"
               (click)="activateGroup($event, group)"
               [ngClass]="{'entry--selected': group.selected}"
               (mouseenter)="selectGroup($event,group)">
            <div
              [ngClass]="{
              'ball--zero': group.active,
              'ball--unactive': !group.active}"
              class="ball">
            </div>
            {{group.name.toString() | uppercase }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-widget>
