import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
import { MapBaseControlModule } from '@app/modules/map/map-base-control/map-base-control.module';
import { TideViewerModule } from '@app/modules/map/tide-viewer/tide-viewer.module';
import { MobilityMapWidgetComponent } from '@app/modules/widgets/mobility-map-widget/mobility-map-widget.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { MapLegendModule } from '@app/modules/map/map-legend/map-legend.module';
import { MobilityMapWidgetService } from '@app/modules/widgets/mobility-map-widget/mobility-map-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { MobilityControlComponent } from './mobility-control/mobility-control.component';
import { MapTideControlModule } from '@app/modules/map/map-tide-control/map-tide-control.module';

@NgModule({
  declarations: [MobilityControlComponent, MobilityMapWidgetComponent],
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    WidgetModule,
    MapWidgetModule,
    AngularResizedEventModule,
    LeafletModule,
    LeafletDrawModule,
    MapBaseControlModule,
    TideViewerModule,
    MapLegendModule,
    MapTideControlModule
  ],
  providers: [MobilityMapWidgetService],
  exports: [MobilityControlComponent, MobilityMapWidgetComponent],
})
export class MobilityMapWidgetModule {
}
