import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapWidgetModule } from '../map-widget/map-widget.module';
import { WidgetModule } from '../widget/widget.module';
import { PublicTransportsStopsWidgetComponent } from './public-transports-stops-widget.component';
import { PublicTransportsStopsService } from './public-transports-stops.service';


@NgModule({
  declarations: [PublicTransportsStopsWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    MapWidgetModule,
  ], providers: [PublicTransportsStopsService],
  exports: [PublicTransportsStopsWidgetComponent],
})
export class PublicTransportsStopsWidgetModule {
}
