import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RegistrySearchModule } from '@app/modules/map/registry-search/registry-search.module';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { RegistryWidgetComponent } from './registry-widget.component';
import { RegistryService } from './registry.service';

@NgModule({
  declarations: [RegistryWidgetComponent],
  imports: [CommonModule,
    MapWidgetModule,
    RegistrySearchModule],
  providers: [RegistryService, TitleCasePipe],
  exports: [RegistryWidgetComponent],
})
export class RegistryWidgetModule {
}
