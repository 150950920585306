import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { MapLegend } from '@app/shared/models/map-legend/map-legend';
import { DataResult } from '@app/shared/models/venice-data-lake/data-result';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetType } from '@app/shared/enums/widget-type';
import { HeatmapData } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-data';
import { HeatmapLevels } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-levels';
import { HeatmapMapping, HeatmapResponse } from '@app/modules/widgets/heatmap-widget/interfaces/heatmap-mapping';
import * as GeoJsonParser from 'geojson';
import { FeatureCollection, Point } from 'geojson';
import { FeatureGroup } from 'leaflet';
import moment from 'moment';
import { Dictionary, groupBy, orderBy } from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

declare var L: any;

@Injectable()
export class HeatmapService extends MapWidgetService {
  widgetType: WidgetType.HEATMAP_WIDGET;

  sourceMapping: Array<HeatmapMapping> = [{
    source: 'tim-big-data',
    level: 'all',
  }, {
    source: 'tim-big-data-region',
    level: 'regions',
  }, {
    source: 'tim-big-data-province',
    level: 'provinces',
  }, {
    source: 'tim-big-data-veneto',
    level: 'towns',
  }];

  private static addFields(url: string, fields: Array<number>): string {
    if (fields) {
      url = url.replace('{{list}}', `[${fields.toString()}]`);
    } else {
      url = url.replace('{{list}}', '[1, 2, 3]');
    }
    return url;
  }

  public fetchData(url: string): Observable<DataResult<HeatmapData>> {
    return this.http.get<DataResult<HeatmapData>>(url);
  }

  public getSingleLevelData(timeMachineData: TimeMachineData, mainSource: WidgetDataSource, additionalData: Array<number>, s: HeatmapMapping):
    Observable<HeatmapResponse> {
    try {
      let url: string = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 24);
      url = HeatmapService.addFields(url, additionalData);
      return this.fetchData(url).pipe(
        map((heatmapData: DataResult<HeatmapData>) => {
          heatmapData.endDate = new Date(heatmapData.endDate);
          return {
            data: heatmapData,
            level: s.level,
          };
        }),
      );
    } catch (e) {
      return of({
        data: {
          data: [],
          total: 0,
          endDate: new Date(),
        },
        level: s.level,
      });
    }
  }

  public getMapLevel(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>,
                     additionalData: Array<number>, forecast?: boolean): Observable<FeatureGroup> {
    return this.loadData(timeMachineData, sources, [1, 2, 3], forecast).pipe(switchMap((heatmapLevels: HeatmapLevels) => {
      const featureGroup: FeatureGroup = new FeatureGroup<any>();
      const data: any = heatmapLevels.all.data;
      const heatmapLayer: any = new L.HeatLayer(data.map((d: any) => {
        return [d.lat, d.lon, d.total];
      }), {
        radius: 20,
        gradient: {
          0.00: 'rgb(255,0,255)',
          0.25: 'rgb(0,0,255)',
          0.50: 'rgb(0,255,0)',
          0.75: 'rgb(255,255,0)',
          1.00: 'rgb(255,0,0)'
        },
      });
      featureGroup.addLayer(heatmapLayer);
      return of(featureGroup);
    }));
  }

  public heatMapDataToGeoJson(data: Array<HeatmapData>): FeatureCollection<Point> {
    const groupedData: Dictionary<Array<HeatmapData>> = groupBy(data, (e: HeatmapData) => {
      return e.lat + e.lon;
    });
    const parsedData: Array<HeatmapData> = [];
    Object.keys(groupedData).forEach((k: string) => {
      const cityArray: Array<HeatmapData> = groupedData[k];
      const aggregatedData: HeatmapData = {
        ace: groupedData[k][0].ace,
        lat: groupedData[k][0].lat,
        lon: groupedData[k][0].lon,
        comune: groupedData[k][0].comune,
        ni: cityArray.reduce((acc: number, currentValue: HeatmapData) => {
          return acc + currentValue.ni;
        }, 0),
        ns: cityArray.reduce((acc: number, currentValue: HeatmapData) => {
          return acc + currentValue.ns;
        }, 0),
        total: cityArray.reduce((acc: number, currentValue: HeatmapData) => {
          return acc + currentValue.total;
        }, 0),
      };
      parsedData.push(aggregatedData);
    });
    // @ts-ignore
    return GeoJsonParser.parse(parsedData, { Point: ['lat', 'lon'] });
  }

  public loadData(timeMachineData: TimeMachineData, sources: Array<WidgetDataSource>,
                  additionalData: Array<number>, forecast?: boolean): Observable<HeatmapLevels> {
    if (sources && sources.length) {
      const response: HeatmapLevels = {};
      const observables: Array<Observable<HeatmapResponse>> = this.sourceMapping.map((s: HeatmapMapping) => {
        const mainSource: WidgetDataSource = sources.find((source: WidgetDataSource) => {
          return s.source === source.sourceType && source.level === 1;
        });
        return this.getSingleLevelData(timeMachineData, mainSource, additionalData, s);
      });
      return forkJoin(observables).pipe(map((data: Array<HeatmapResponse>) => {
        data.forEach((h: HeatmapResponse) => {
          h.data.data = orderBy(h.data.data, 'total', 'desc');
          response[h.level] = h.data;
        });
        return response;
      }));
    }
  }

  public checkSource(source: WidgetDataSource): Observable<any> {
    let url: string = source.rewriteUrl;
    url = url.replace('{{to}}', moment().valueOf().toString());
    url = url.replace('{{from}}', moment().valueOf().toString());
    url = url.replace('{{list}}', '[]');
    return this.http.get<any>(url);
  }

  /*  public getLegend(sources: Array<WidgetDataSource>): MapLegend {
  return {
     title: 'TITLE',
     description: 'DESCRIPTION',
     type: 'LEGEND',
     gradient: true,
     elements: [{
       color: '#D21933',
       label: 'Test label first',
       position: 0,
     }, {
       color: '#FFFF74',
       label: '1',
       position: 0.3,
     }, {
       color: '#99E600',
       label: 'Test label last',
       position: 1,
     }],
     additionalData: null,
   };
 }*/

}
