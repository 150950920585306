<app-page-menu-bar [pageLabel]="'MAP'" [menuKey]="'MAP'">
  <div class="map-page">
    <ng-template #timeline>
      <app-time-machine></app-time-machine>
    </ng-template>

    <div class="d-flex flex-row align-items-stretch map">

      <mat-accordion class="map__left d-flex flex-column align-items-stretch" *ngIf="activeFields()">
        <div class="additional-menus d-flex flex-column align-items-stretch">
          <app-map-control-sub-bar #controlSubBar class="additional-menus__menu" *ngFor="let field of fields"
                                   [field]="field.elements" [staticSubMenu]="field.elements.staticSubMenu"
                                   [hidden]="!field || !field.elements.submenuActive"
                                   (subMenuChanged)="handleSubMenuChange($event)"
                                   (subMenuSelectAll)="handleSubMenuSelectAll($event)"></app-map-control-sub-bar>
        </div>
      </mat-accordion>
      <div leaflet *ngIf="leafletOptions" [leafletOptions]="leafletOptions" (leafletMapReady)="onMapReady($event)"
           (resized)="invalidateMap()" class="map__container"
           [ngClass]="{'animated': animation}">

        <div *ngIf="showDrawer" leafletDraw [leafletDrawOptions]="drawOptions"
             (leafletDrawCreated)="onDrawCreated($event)" (leafletDrawDeleted)="onDrawDeleted($event)"
             (leafletDrawEdited)="onDrawEdited($event)"></div>

        <div [leafletLayer]="drawnItems"></div>
      </div>


      <div class="map__control-panel d-flex flex-column align-items-stretch justify-content-end">
        <app-map-base-control [hidden]="!controlComponents.size" [show]="true" [titleLabel]="'PAGES.MAP.CONTROLS'">
          <ng-template #controlContainer></ng-template>
        </app-map-base-control>
        <app-map-legend class="map__legend mb-2" *ngIf="activeLegends && activeLegends.length" [hiddenCondition]="false"
                        [legends]="activeLegends"></app-map-legend>
      </div>

      <div class="map__right d-flex flex-column justify-content-between align-items-end"
           [ngClass]="{'map__right__margin': pageConfig && pageConfig.showMenuBar}">

        <app-registry-search class="d-block map-search" (searchSubmit)="registrySearch($event)"
                             (searchUpdate)="updateRegistryAutocomplete($event)" (clear)="clearRegistry()"
                             (clearAll)="disabledLayer($event)"
                             [data]="activeLayers" [options]="registryOptions" selectLevel="true"
                             [showActiveLayers]="true"
                             *ngIf="pageConfig && pageConfig.registrySearch"></app-registry-search>
        <app-map-control-bar class="d-flex map-menu map-overlay-menu" *ngIf="data && pageConfig" [data]="data"
                             [showMenuBar]="pageConfig.showMenuBar"
                             [showSatellite]="pageConfig.mapSettings.satellitePic"
                             (switchActivated)="toggleVeniceLevel($event)"
                             (levelActivated)="loadMapLevel($event)">
        </app-map-control-bar>

        <div class="map__right__bottom d-flex flex-row justify-content-around align-items-center" [ngClass]="{'map__right__padding': (pageConfig && pageConfig.showMenuBar && isTimeMachinePopoverOpen)}">
          <app-tide-viewer *ngIf="showTide" class="d-block map__tide" [tideLoading]="tideLoading" [currentTide]="currentTide"></app-tide-viewer>

          <div class="time-machine-button" *ngIf="pageConfig && !pageConfig.showMenuBar"
               [ngClass]="{'time-machine--past': timeMachineService.past, 'time-machine--future': timeMachineService.forecast}">
            <em class="icon-venice_time-machine" [autoClose]="'outside'" [ngbPopover]="timeline" container="body"></em>
          </div>
        </div>


      </div>

    </div>
  </div>
</app-page-menu-bar>
<app-menu-bar class="menu-bar" #menu *ngIf="pageConfig && pageConfig.showMenuBar" (popOverShown)="recordShown()"
              (popOverHidden)="recordHidden()"></app-menu-bar>
