import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class RoadFlowDateService extends WidgetService {
  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>): Observable<FlowDateTimestamp> {
    const source: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'road-flow-date';
    });
    if (source) {
      const url: string = this.addTimeMachineDataToUrl(timeMachineData, source);
      return this.http.get<FlowDateData>(url).pipe(switchMap((d: FlowDateData) => {
        return of({
          date: d.date ? Date.parse(d.date) : null,
          status: d.status,
        });
      }));
    }
  }
}

export interface FlowDateData {
  date: string;
  status: ParkingStatus;
}

export interface FlowDateTimestamp {
  date: number | null;
  status: ParkingStatus;
}

export enum ParkingStatus {
  NORMAL = 'normal',
  NEGATIVE = 'negative',
  FULL = 'full',
  STATIONARY = 'stationary'
}
