import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonsWidgetComponent } from '@app/shared/components/modals/buttons-widget/buttons-widget.component';
import { AngularJsonSchemaForm, AngularJsonSchemaFormError } from '@app/shared/models/angular-json-schema-form';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';

@Component({
  selector: 'app-edit-indexes-modal',
  templateUrl: './edit-indexes-modal.component.html',
  styleUrls: ['./edit-indexes-modal.component.scss'],
})
export class EditIndexesModalComponent implements OnInit {

  activeWeightType: Configuration;
  weightsSchema: any;
  weight: any;
  selectedWeight: Configuration;
  formValid: boolean;
  errorMessage: Array<AngularJsonSchemaFormError>;

  constructor(public dialogRef: MatDialogRef<EditIndexesModalComponent>, @Inject(MAT_DIALOG_DATA) public data: AngularJsonSchemaForm) {
    this.weight = {
      submit: ButtonsWidgetComponent,
    };
    this.formValid = true;
  }

  ngOnInit(): void {
    this.selectWeight(this.data[0]);
    this.selectedWeight = this.data[0];
  }

  selectWeight(weight: Configuration): void {
    this.activeWeightType = weight;
    this.formValid = true;
    this.errorMessage = [];
    if (weight.configurationSchema) {
      this.weightsSchema = JSON.parse(weight.configurationSchema);
      this.weightsSchema.data = Object.assign(this.weightsSchema.data, JSON.parse(weight.configuration));
    } else {
      this.weightsSchema = {};
    }
  }

  submit(data: any): void {
    if (this.formValid) {
      this.dialogRef.close({
        title: this.activeWeightType.title,
        name: this.activeWeightType.name,
        data: data
      });
    }
  }

  isValid (data: boolean): void {
    this.formValid = data;
  }

  validationErrors(data: Array<AngularJsonSchemaFormError>): void {
    data.forEach((msg: AngularJsonSchemaFormError) => {
      if (msg.dataPath.indexOf('/') >= 0) {
        const splitted: Array<string> = msg.dataPath.split('/');
        let newMsg: string = splitted[1];
        if (splitted.length > 2) {
          newMsg += '[' + splitted[2] + ']';
        }
        msg.dataPath = newMsg;
      }
    });
    this.errorMessage = data;

  }

  cancel(): void {
    this.dialogRef.close();
  }
}
