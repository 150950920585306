import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.vectorgrid';
import 'leaflet-linear-measure';
import 'leaflet-measure/dist/leaflet-measure.it';
import 'leaflet.minichart';
import 'leaflet.sync';
import 'leaflet.wms';
import 'leaflet-wfst';
import 'leaflet-geoserver-request';
import 'leaflet-timedimension';
import 'leaflet.heat';
import 'esri-leaflet';
import 'esri-leaflet-cluster';
import 'esri-leaflet-renderers';
import 'gaugeJS';
import 'zone.js/dist/zone';

if (environment.production) {
  enableProdMode();
}

declare var Cesium: any;

window['CESIUM_BASE_URL'] = '/assets/cesium/';
Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');
Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmZDBhMmZlNy1lMDc4LTRkZTktYTgwMy0zNmI1OGJlMTY2ZjkiLCJpZCI6NzE1MDgsImlhdCI6MTYzNTI0MzQ3N30.RINZ4ACJ5nufkkP42S3chybdCCJ4sX0ldZU_90OlkXc';
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
document.getElementById('loader').remove();
// testdeploy
