import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wind-compass',
  templateUrl: './wind-compass.component.html',
  styleUrls: ['./wind-compass.component.scss']
})
export class WindCompassComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
