<ng-template #prevision let-forecastsGrouped="forecastsGrouped" let-from="from" let-to="to"
  let-showDetails="showDetails">
  <table>
    <thead class="forecast-header" [ngClass]="{'forecast-header--bigger': showDetails}">
      <tr>
        <th colspan="2">

        </th>
        <th colspan="2">
          {{'WIDGETS.FORECAST.WEATHER_LABEL' | translate}}
        </th>
        <th>
          {{'WIDGETS.FORECAST.TEMPERATURE_LABEL' | translate}}
        </th>
        <th>
          {{'WIDGETS.FORECAST.PRECIPITATIONS_LABEL' | translate}}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container
        *ngFor="let dayForecast of forecastsGrouped | slice:from:to; let index=index; let odd=odd; let even=even;">
        <tr class="forecast"
          [ngClass]="{'forecast--bigger': showDetails, 'forecast--odd': odd, 'forecast--even': even, 'forecast--last': last}"
          *ngFor="let forecast of dayForecast.forecasts; let index=index; let last=last;">
          <td class="forecast__day text-center" *ngIf="index === 0" [rowSpan]="dayForecast.forecasts.length">
            <b>{{dayForecast.day.valueOf() | date:'E d' | uppercase}}</b>
          </td>
          <td class="text-nowrap">
            <ng-container *ngIf="forecast.partOfDay === 'm'">{{'WIDGETS.FORECAST.NIGHT' | translate}}</ng-container>
            <ng-container *ngIf="forecast.partOfDay === 'a'">{{'WIDGETS.FORECAST.AFTERNOON' | translate}}</ng-container>
          </td>
          <td class="forecast__image-container">
            <div class="forecast__image-container__image" [ngStyle]="{'background-image': 'url('+forecast.symbol+ ')'}">
            </div>
          </td>
          <td>
            <ng-container>{{forecast.sky | lowercase}}</ng-container>
          </td>
          <td class="text-nowrap">
            <ng-container *ngIf="forecast.temperature">
              {{'WIDGETS.FORECAST.TEMPERATURE' | translate:{ temperature: forecast.temperature } }}</ng-container>
          </td>
          <td class="text-nowrap forecast__precipitations">
            <ng-container *ngIf="forecast.precipitationProbability === '0%'">
              {{'WIDGETS.FORECAST.NO_PRECIPITATIONS' | translate}}</ng-container>
            <ng-container *ngIf="forecast.precipitationProbability !== '0%'">
              <ng-container *ngIf="showDetails">{{forecast.precipitation}}&nbsp;</ng-container>
              {{forecast.precipitationProbability}}
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</ng-template>

<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()" [noData]="noData"
            [timeMachineOn]="timeMachineOn" [isFullScreen]="isFullScreen"
            [isEditable]="isEditable" [isLoading]="isLoading" (resized)="handleResize($event)">
  <div class="forecasts d-flex flex-column justify-content-start align-items-stretch"
       [ngStyle]="{'font-size.px': fontSize}" *ngIf="forecastsGrouped">
    <ng-container *ngIf="!isFullScreen && forecastsGrouped.length > 2">
      <mat-carousel
        color="primary"
        style="height: 100%;"
        timings="250ms ease-in"
        interval="10000"
        [autoplay]="true"
        [maintainAspectRatio]="false"
        [hideArrows]="true"
        [hideIndicators]="false"
        [loop]="true">
        <mat-carousel-slide
          overlayColor="#fff">
          <ng-container
            *ngTemplateOutlet="prevision; context:{forecastsGrouped: forecastsGrouped, from:0, to:2}"></ng-container>
        </mat-carousel-slide>
        <mat-carousel-slide
          overlayColor="#fff">
          <ng-container
            *ngTemplateOutlet="prevision; context:{forecastsGrouped: forecastsGrouped, from:2, to:4}"></ng-container>
        </mat-carousel-slide>
      </mat-carousel>
    </ng-container>
    <ng-container *ngIf="isFullScreen || forecastsGrouped.length < 2">
      <ng-container
        *ngTemplateOutlet="prevision; context:{showDetails: isFullScreen, forecastsGrouped: forecastsGrouped, from:0, to:5}"></ng-container>
    </ng-container>
  </div>
</app-widget>

