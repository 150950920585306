import { Injectable } from '@angular/core';
import { PositionObject } from '@app/modules/widgets/map-object-position-widget/model/position-object';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DivIcon, FeatureGroup, LatLng, Marker } from 'leaflet';
import { Observable, of, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class MapObjectPositionService extends MapWidgetService {

  DEFAULT_REFRESH_INTERVAL: number = 2 * 1000;

  private handlePopup(marker: Marker): void {
    const TIMEOUT_POPUP: number = 10 * 1000;
    marker.openPopup();
    setTimeout(() => {
      marker.closePopup();
    }, TIMEOUT_POPUP);
  }

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>, additionalData?: any, forecast?: boolean): Observable<FeatureGroup> {
    return timer(0, this.DEFAULT_REFRESH_INTERVAL).pipe(
      switchMap(
        (offset: number) => {
          const newTmData: TimeMachineData = this.calculateTimeMachineData(timeMachineData, offset);

          const widgetDataSource: WidgetDataSource = sources.find((s: WidgetDataSource) => {
            return s.sourceType === 'object-position';
          });
          const url: string = this.addTimeMachineDataToUrl(newTmData, widgetDataSource, 10 / 3600);
          return this.http.get(url);
        },
      ), switchMap((data: Array<PositionObject>) => {
        const featureGroup: FeatureGroup = new FeatureGroup();

        if (data.length > 0) {
          const el: PositionObject = data[0];
          if (el.geoposition_lat && el.geoposition_lon) {
            const name: string = el.vehicle_id.split('_')[0];
            const id: string = el.vehicle_id.split('_')[1];
            const sp: number = (el.speed * 60 * 60) / 1000;
            let h: string = '<h5>Linea <span class=\'name\'>' + name + '</span></h5>';
            h += '<p><b>Veicolo ' + id + '</b></p>';
            h += '<p>Velocità ' + Math.trunc(sp * 100) / 100 + ' km/h</p>';
            h += '<p>Livello batteria ' + el.batterystatus_level + '%</p>';
            h += '<p>Modalità ' + el.robotmode + '</p>';

            const marker: Marker = new Marker(new LatLng(el.geoposition_lat, el.geoposition_lon), {
              icon: new DivIcon({
                className: 'my-div-icon',
                html: '<div class="my-div-inner">' +
                  '<span class="my-div-span">' + name + '</span>' +
                  '</div>',
              }),
            });
            marker.bindPopup(h, {
              className: 'my-popup',
            });


            marker.on({
              mouseover: () => {
                this.handlePopup(marker);
              },
              click: () => {
                this.handlePopup(marker);
              },
            });
            marker['name'] = 'object-marker';

            featureGroup.addLayer(marker);
          }
        }
        return of(featureGroup);
      }));
  }

}
