import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertInsertResponse } from '@app/shared/models/venice-data-lake/update-response';
import { AlertsService } from '@services/alerts/alerts.service';
import { Alert } from '@app/shared/models/alerts/alert';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit, OnDestroy {
  alerts: Array<Alert>;
  iconClasses: Array<string> = ['icon-venice_pedonali', 'icon-venice_marea-allarme', 'icon-venice_marker-camere', 'icon-venice_stazioni-polizia', 'icon-venice_celle-dati', 'icon-venice_twitter'];
  colorStrings: Array<string> = ['#FF6600', '#0000FF', '#FFFF00', '#FF0000'];
  isLoading: boolean;
  noalert: boolean;
  viewMultiSelection: boolean;

  alertsSubscription: Subscription;

  constructor(private alertsService: AlertsService) {
    this.isLoading = false;
    this.noalert = true;
    this.viewMultiSelection = true;
  }

  ngOnInit(): void {
    this.loadEvents();
  }

  onAlertClick(alert: Alert): void {
    alert.read = true;
    this.alertsService.updateData(alert.indexId).subscribe((response: AlertInsertResponse) => {
      this.updateNewAlertsIcon();
    });
  }

  loadEvents(): void {
    this.isLoading = true;
    this.alertsSubscription = this.alertsService.loadData().subscribe((alerts: Array<Alert>) => {
      this.isLoading = false;
      this.alerts = alerts;
      this.alerts.forEach((x: Alert) => {
        if (x.typeId && x.typeId.toString() === 'tide') {
          x.typeId = 2;
        }
      });
      this.alerts.sort((a: Alert, b: Alert) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });
      this.updateNewAlertsIcon();
    });
  }

  updateNewAlertsIcon(): void {
    const alertsNotRead: Array<Alert> = this.alerts.filter((x: Alert) => !x.read);
    this.noalert = alertsNotRead.length === 0;
    this.alertsService.newAlerts.emit(!this.noalert);
  }

  onSelectAllClick(): void {
    this.isLoading = true;
    this.alerts.forEach((x: Alert) => {
      x.checked = true;
    });
    this.isLoading = false;
  }

  onAlertAllClick(): void {
    this.isLoading = true;
    const alertToUpdate: Array<Alert> = this.alerts.filter((item: Alert) => {
      return item.checked === true;
    });

    const alertIdList: Array<string> = alertToUpdate.map((x: Alert) => {
      return x.indexId;
    });
    this.alertsService.marksAllRead(alertIdList).subscribe(() => {
      this.alerts = this.alerts.filter((a: Alert) => {
        return !alertIdList.includes(a.indexId);
      });
      this.isLoading = false;
    }, (error: any) => {
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.alertsSubscription) {
      this.alertsSubscription.unsubscribe();
    }
  }
}
