<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus"
            [showThresholdAlert]="showThresholdAlert"
            [showGlobalThresholdAlert]="showGlobalThresholdAlert"
            [isEditable]="isEditable" [isLoading]="isLoading" (editData)="sendEditData()"
            [timeMachineOn]="timeMachineOn"
            (sendReloadWidget)="reloadWidget()"
            (resized)="handleResize($event)">
  <div class="tide-grid d-flex flex-row justify-content-center align-items-stretch"
       [ngStyle]="{'font-size.px': fontSize, 'height': '99%'}">
    <div class="labels left d-flex flex-row justify-content-center align-items-stretch flex-grow-1 flex-shrink-1">
      <div class="dynamic-resize">
        <ng-container *ngFor="let tideLevel of tideLevels">
          <div class="label label--left" *ngIf="tideLevel.display">
            <ng-container *ngIf="!tideLevel.right">
              <div class="icons" [class.pulse]="getPulse(tideLevel)" [ngClass]="displayLevelClass(tideLevel)">
                <i *ngFor="let icon of tideLevel.icons" class="icon-venice" [ngClass]="'icon-venice_' + icon"></i>
              </div>
              <div class="label__number">{{tideLevel.value}}</div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="graphs d-flex justify-content-center align-items-stretch flex-grow-1 flex-shrink-1">
      <div class="dynamic-resize d-flex flex-column justify-content-center align-items-stretch">
        <div class="background" (resized)="handleTideResize($event)">
          <div *ngFor="let tideLevel of tideLevels" [ngClass]="tideLevel.area" class="border-bottom">
          </div>
        </div>
        <div class="lines">
          <div *ngFor="let tideLevel of tideLevels" [ngClass]="{'border-bottom': tideLevel.display && !tideLevel.right}"
               class="border-bottom">
          </div>
        </div>
        <div class="lines lines--right">
          <div *ngFor="let tideLevel of tideLevels" [ngClass]="{'border-bottom': tideLevel.display && tideLevel.right}"
               class="border-bottom">
          </div>
        </div>
        <div class="animated-background-tide" *ngIf="displayedTide">
          <div class="progress-bar-striped" [ngStyle]="{'height': tideHeight}">
          </div>
        </div>
        <div class="tide" *ngIf="displayedTide">
          <div class="tide__value" *ngIf="displayedTide.valid">
            <span class="tide__value__level">{{displayedTide.level.toFixed(0)}}</span>
            <span class="tide__value__unit">&nbsp;cm</span>
          </div>
          <div class="tide__value" *ngIf="!displayedTide.valid">
            <span class="tide__value__level">N.D.</span>
          </div>
          <p class="tide__date">{{displayedTide.moment.format('HH:mm')}}</p>
          <i class="icon-venice_costante tide__icon"
             [ngClass]="{'rotate-45-positive': displayedTide.trend < 0, 'rotate-45-negative': displayedTide.trend > 0 }"></i>

        </div>

      </div>
    </div>
    <div class="labels d-flex flex-row justify-content-center align-items-stretch flex-grow-1 flex-shrink-1">
      <div class="dynamic-resize">
        <ng-container *ngFor="let tideLevel of tideLevels">
          <div class="label label--right" *ngIf="tideLevel.display">
            <ng-container *ngIf="tideLevel.right">
              <div class="label__number label__number--right">{{tideLevel.value}}</div>
              <div class="icons icons--right" [class.pulse]="getPulse(tideLevel)"
                   [ngClass]="displayLevelClass(tideLevel)">
                <i *ngFor="let icon of tideLevel.icons" class="icon-venice" [ngClass]="'icon-venice_' + icon"></i>
              </div>
            </ng-container>
          </div>
        </ng-container>

        <div class="station" [ngStyle]="{'width': infoWidth}">
          <ng-container *ngIf="peakTide">
            <div *ngIf="displayedTide" class="peak text-center">{{'TIDE_WIDGET.MINMAX' | translate }}</div>
            <div class="peak text-center">{{'TIDE_WIDGET.NEXT' | translate:{
              'minmax': peakTide.minmax
            } }} </div>
            <div class="peak peakValue text-center">{{peakTide.value}} cm</div>
            <div *ngIf="displayedTide" class="peakData text-center">
              ({{peakTide.time.format('DD/MM/YYYY HH:mm')}})
            </div>
          </ng-container>
          <ng-container *ngIf="!peakTide">
            <div class="peak peak--unavailable text-center mb-4">
              {{'TIDE_WIDGET.PEAK_UNAVAILABLE' | translate }}
            </div>
          </ng-container>
          <div *ngIf="displayedTide" class="flex-row  text-center">
            <select class="custom-select" [(ngModel)]="selectedStation" (ngModelChange)="stationChange($event)">
              <option [ngValue]="station" *ngFor="let station of stationList">
                {{station.station}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

  </div>
</app-widget>
