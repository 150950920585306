import { Component, Injector } from '@angular/core';
import { CulturalEventsService } from '@app/modules/widgets/cultural-events-widget/cultural-events.service';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';

@Component({
  selector: 'app-cultural-events-widget',
  templateUrl: './cultural-events-widget.component.html'
})
export class CulturalEventsWidgetComponent extends MapWidgetComponent {

  constructor(public widgetService: CulturalEventsService, public injector: Injector) {
    super(widgetService, injector);
  }

}
