import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardComponent } from '@app/modules/dashboard/dashboard.component';
import { GridStackModule } from '@app/modules/dashboard/ngx-grid-stack/grid-stack.module';
import { WidgetsModule } from '@app/modules/widgets/widgets.module';
import { AngularResizedEventModule } from 'angular-resize-event';
import { Ng2PanZoomModule } from 'ng2-panzoom';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [
    CommonModule,
    GridStackModule,
    TranslateModule,
    MaterialDesignFrameworkModule,
    Ng2PanZoomModule,
    AngularResizedEventModule,
    WidgetsModule,
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {
}
