import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';
import { Observable } from 'rxjs';

@Injectable()
export class RoadFlowBaseService extends WidgetService {

  public loadData(timeMachineData: TimeMachineData,
                  sources: Array<WidgetDataSource>): Observable<Configuration> {
    return this.configurationService.getConfiguration('most');
  }
}
