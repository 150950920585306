<app-map-widget class="mindicity-widget mobility-map-widget"
                [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
                [isEditable]="isEditable"
                [isLoading]="isLoading"
                (removeWidget)="sendRemoveWidget()"
                [sourceStatus]="sourceStatus"
                (sendReloadWidget)="reloadWidget()"
                [timeMachineOn]="timeMachineOn"
                [mapId]="mapId"
                [map]="map"
                [data]="data"
                [noData]="noData"
                (resized)="handleResize($event)"
                (mapReady)="setMapHandlers($event)"
                [showThresholdAlert]="showThresholdAlert"
                [showGlobalThresholdAlert]="showGlobalThresholdAlert"
                [legends]="legends"
                [leafletOptions]="leafletOptions">
  <app-tide-viewer class="tide-viewer" [currentTide]="currentTide"></app-tide-viewer>

  <app-mobility-control *ngIf="currentTide" class="map-control"
                        [gates]="gates"
                        [sliderTideValue]="sliderTideValue"
                        [originalTideValue]="currentTide.level"
                        [legends]="simulatorLegend"
                        [tideSliderEnabled]="tideSliderEnabled"
                        [checkedTypes]="baseLayerChecked()"
                        [categories]="availableCategories"
                        [categoriesSelection]="selectedCategories"
                        [timeserieLoading]="timeserieLoading"
                        [viewGates]="data.gatesVisibleOnStart"
                        (sliderTideChanged)="tideChanged($event)"
                        (viewGatesChanged)="toggleAllGatesLayers($event)"
                        (gatesChanged)="gatesChanged($event)"
                        (startSimulation)="getSimulation($event)"
                        (layerVisibilityChanged)="toggleSimulationLayer($event)"
                        (simulatorTimeChanged)="refreshSimulationLayers($event)"
                        (walkwaysChanged)="walkwaysChanged($event)"
                        (sliderTideOffsetChanged)="tideOffsetChanged($event)"
                        (categoriesSelectionChanged)="categoriesSelectionChanged($event)"
                        (clearSimulation)="resetSimulation()"></app-mobility-control>
</app-map-widget>
