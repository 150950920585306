<h1 mat-dialog-title>{{'MENU.MODALS.ADD_WIDGET.TITLE' | translate}}</h1>
<div mat-dialog-content>
  <mat-form-field>
    <label>
      <input type="text" matInput
             [formControl]="fieldControl"
             [matAutocomplete]="availableWidgets"
             [placeholder]="'MENU.MODALS.ADD_WIDGET.WIDGET_TYPE' | translate"
             [(ngModel)]="selectedTitle">
    </label>
  </mat-form-field>
  <mat-autocomplete #availableWidgets="matAutocomplete" (optionSelected)="updateForm($event)">
    <mat-option *ngFor="let widget of filteredOptions | async" [value]="widget.name">
      {{widget.title | titlecase}}
    </mat-option>
  </mat-autocomplete>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button
          [mat-dialog-close]="false">{{'MENU.MODALS.DELETE.CANCEL' | translate}}</button>
  <button mat-raised-button [color]="'warn'"
          [mat-dialog-close]="result"
          cdkFocusInitial>{{'MENU.MODALS.DELETE.OK' | translate}}</button>
</div>
