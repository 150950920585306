import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeatmapService } from '@app/modules/widgets/heatmap-widget/heatmap.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { HeatmapWidgetComponent } from './heatmap-widget.component';

@NgModule({
  declarations: [HeatmapWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    AngularResizedEventModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
  ],
  providers: [HeatmapService, {provide: 'heatmap_service', useClass: HeatmapService}],
  exports: [
    HeatmapWidgetComponent,
  ],
})
export class HeatmapWidgetModule {
}
