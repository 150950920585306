import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WidgetsEventBusService } from '@app/modules/widgets/widget/widgets-event-bus.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularResizedEventModule } from 'angular-resize-event';
import { FlipModule } from 'ngx-flip';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';

export function createTranslateLoader(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [WidgetComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    FlipModule,
    AngularResizedEventModule,
    MatProgressSpinnerModule,
    MatIconModule,
  ],
  providers: [WidgetService, WidgetsEventBusService],
  exports: [WidgetComponent, AngularResizedEventModule],
})
export class WidgetModule {
}
