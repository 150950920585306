import { CommonModule, registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { ForecastWidgetComponent } from '@app/modules/widgets/forecast-widget/forecast-widget.component';
import { ForecastWidgetService } from '@app/modules/widgets/forecast-widget/forecast-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { AngularResizedEventModule } from 'angular-resize-event';

registerLocaleData(localeIT);

@NgModule({
  declarations: [ForecastWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule,
    AngularResizedEventModule,
    MatCarouselModule
  ],
  providers: [ForecastWidgetService, { provide: LOCALE_ID, useValue: 'it' }],
  exports: [ForecastWidgetComponent],
})
export class ForecastWidgetModule {
}
