import { Injectable } from '@angular/core';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import { DivIcon, FeatureGroup, LatLng, Marker } from 'leaflet';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

declare var L: any;

@Injectable()
export class PresencesClusterService extends MapWidgetService {

  getClusterDivIcon(value: number): DivIcon {
    const content: string = `${Math.round(value / 1000) > 1 ? Math.round(value) / 1000 + 'K' : value}`;
    const size: number = 1.4 * Math.log10(value) * 10;
    return new DivIcon({
      iconSize: [size, size],
      iconAnchor: [0.5 * size, size],
      className: `arcgis-marker marker-pin cluster-presences-marker d-flex flex-row justify-content-center align-items-center`,
      html: `<div class="cluster-presences-marker__text">${content}</div>`,
    });
  }

  createClusterIcon(): (cluster: any) => DivIcon {
    return (cluster: any): DivIcon => {
      const children: Array<Marker> = cluster.getAllChildMarkers();
      let sum: number = 0;
      children.forEach((m: Marker) => {
        sum += m['value'];
      });
      return this.getClusterDivIcon(sum);
    };
  }

  public createClusterLevel(c: ClusterPresence, additionalData: string): Marker {
    const position: LatLng = new LatLng(c.Lat, c.Lon);
    const marker: Marker = new Marker(position, {
      icon: this.getClusterDivIcon(c[additionalData]),
    });
    marker['value'] = c[additionalData];
    return marker;
  }

  public getMapLevel(timeMachineData: TimeMachineData,
                     sources: Array<WidgetDataSource>,
                     additionalData?: any): Observable<FeatureGroup> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.sourceType === 'presences-cluster';
    });
    const url: string = this.addTimeMachineDataToUrl(timeMachineData, mainSource);
    return this.http.get<Array<ClusterPresence>>(url).pipe(switchMap(((d: Array<ClusterPresence>) => {
      const clusterLayer: FeatureGroup = L.markerClusterGroup({
        iconCreateFunction: this.createClusterIcon(),
        showCoverageOnHover: false,
        animate: true,
        spiderfy: true,
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: true,
      });
      d.forEach((cls: ClusterPresence) => {
        clusterLayer.addLayer(this.createClusterLevel(cls, additionalData));
      });
      return of(clusterLayer);
    })));
  }
}

export interface ClusterPresence {
  IDCluster: number;
  ClusterName: string;
  Timestamp: Date;
  P: number;
  Italiani: number;
  Stranieri: number;
  VisitatoriExtraregionali: number;
  VisitatoriIntraregionali: number;
  Pendolari: number;
  Residenti: number;
  Lat: number;
  Lon: number;
}
