import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { Moment } from 'moment';

export class TimeMachineAdvancedRange extends TimeMachineRange {
  constructor(from: Moment, to: Moment) {
    super(from, to);
  }

  isForecast(): boolean {
    this.updateTimeSlide();
    return super.isForecast();
  }

  isPast(): boolean {
    this.updateTimeSlide();
    return super.isPast();
  }
}
