<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            [isEditable]="isEditable"
            [isLoading]="isLoading"
            (removeWidget)="sendRemoveWidget()"
            [sourceStatus]="sourceStatus"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [noData]="noData"
>
  <div class="container d-flex flex-column justify-content-around">
    <ng-container *ngIf="data && presences">
      <div class="d-flex flex-row justify-content-center align-items-center">
        <span>{{'WIDGETS.ROAD_ARRIVALS.DATE' | translate}}:&nbsp;{{data.datetime | date:'dd/MM/yyyy HH:mm'}}</span>
      </div>
      <div class="d-flex flex-row justify-content-between" *ngIf="presences">
        <h5 class="text-left">{{'WIDGETS.ROAD_ARRIVALS.PRESENCES' | translate}}:</h5>
        <h4 class="text-right">{{presences.total / 1000 | number:'1.0-0'}} K</h4>
      </div>
      <div class="separator border-bottom"></div>
      <div class="d-flex flex-row justify-content-between">
        <h5 class="text-left">{{'WIDGETS.ROAD_ARRIVALS.ARRIVALS' | translate}}:</h5>
        <h4 class="text-right">{{data.arrivals.total / 1000 | number:'1.0-0'}} K</h4>
      </div>
    </ng-container>
  </div>
</app-widget>
