import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoadFlowCurrentWidgetComponent } from '@app/modules/widgets/flows/road-flow-current-widget/road-flow-current-widget.component';
import { RoadFlowCurrentService } from '@app/modules/widgets/flows/road-flow-current-widget/road-flow-current.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RoadFlowCurrentWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    TranslateModule
  ],
  providers: [RoadFlowCurrentService],
  exports: [RoadFlowCurrentWidgetComponent]
})
export class RoadFlowCurrentModule { }
