import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';

export const PRIMARY_SOURCES: Array<any> = [
  {
    name: 'venice_data_lake',
    supplier: 'VDL-09-PP',
    description: 'Venice Data Lake',
    status: DataSourceStatus.AVAILABLE,
  }
];

export const ADDITIONAL_SOURCES: Array<any> = [
  {
    name: 'cells_attendance',
    supplier: 'TIM-09-PP',
    description: 'Tim Big Data',
    url: 'https://scrgr.trackhera.com/public/images/images_tim_o/last.png',
    status: DataSourceStatus.WARNING,
  },
];
