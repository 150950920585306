<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
            (removeWidget)="sendRemoveWidget()"
            (sendReloadWidget)="reloadWidget()"
            [timeMachineOn]="timeMachineOn"
            [isLoading]="isLoading"
            [data]="data"
            [noData]="noData"
            [sourceStatus]="sourceStatus"
            [isEditable]="isEditable"
            [ready]="ready">
  <div class="map-box" *ngIf="leafletOptions && ready">
    <div leaflet [id]="mapId"
         [leafletOptions]="leafletOptions"
         (leafletMapReady)="onMapReady($event)"
         class="map-container">

      <div *ngIf="showDrawer" leafletDraw [leafletDrawOptions]="drawOptions"
           (leafletDrawCreated)="onDrawCreated($event)" (leafletDrawDeleted)="onDrawDeleted($event)"
           (leafletDrawEdited)="onDrawEdited($event)"></div>

      <div [leafletLayer]="drawnItems"></div>
    </div>
  </div>
  <div class="map-containers d-flex flex-column justify-content-between align-items-stretch">
    <app-map-legend class="map-legend" [hiddenCondition]="!checkLegendVisibility()" *ngIf="legends && legends.length"
                    [legends]="legends"></app-map-legend>
  </div>
  <ng-content></ng-content>
</app-widget>
