import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MapControlBarButton } from '@app/features/pages/map-page/models/map-control-bar-button';
import { MapControlBarPanel } from '@app/features/pages/map-page/models/map-control-bar-panel';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Component({
  selector: 'app-map-control-bar',
  templateUrl: './map-control-bar.component.html',
  styleUrls: ['./map-control-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MapControlBarComponent implements OnInit, OnChanges {

  @Input()
  data: Array<MapControlBarPanel>;

  @Input()
  switchActive: boolean = false;

  @Input()
  cesium3dActive: boolean = true;

  @Input()
  showSatellite: boolean;

  @Input()
  showMenuBar: boolean;

  @Input()
  cesiumMap: boolean;

  @Output()
  levelActivated: EventEmitter<MapControlBarButton> = new EventEmitter<MapControlBarButton>();

  @Output()
  switchActivated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  cesiumSwitchActivated: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    if (this.data) {
      this.processPanels();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && !changes.data.isFirstChange()) {
      this.processPanels();
    }
  }

  processPanels(): void {
    this.data.forEach((m: MapControlBarPanel) => {
      m.canView = this.checkPermissions(m.roles, m.privileges);
      m.fields.forEach((f: MapControlBarButton) => {
        f.canView = this.checkPermissions(f.roles, f.privileges);
      });
    });
  }

  togglePanel(panel: MapControlBarButton): void {
    if (panel.enabled) {
      panel.active = !panel.active;
      this.levelActivated.emit(panel);
    }
  }

  activateSwitch(): void {
    this.switchActivated.emit(this.switchActive);
  }

  activateCesiumSwitch(): void {
    this.cesiumSwitchActivated.emit(this.cesium3dActive);
  }

  checkPermissions(roles: Array<string>, privileges: Array<string>): boolean {
    return this.authenticationService.userHasRole(roles) || this.authenticationService.userHasPrivilege(privileges);
  }

}
