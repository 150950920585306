<div class="map-control-bar">
  <div class="header d-flex flex-row align-items-center justify-content-start mb-1 border-bottom border-dark">
    <em id="back" class="icon icon-venice_home cursor-pointer" [routerLink]="['/']" *ngIf="!showMenuBar"></em>
    <div class="header__title text-center align-content-center flex-fill">
      <h5>{{'PAGES.MAP.TITLE' | translate | uppercase}}</h5>
    </div>
  </div>
  <div class="switch-panel" *ngIf="showSatellite && !cesiumMap">
    <mat-slide-toggle [(ngModel)]="switchActive" (ngModelChange)="activateSwitch()">
      {{'PAGES.MAP.VENICE_LEVEL' | translate | uppercase }}</mat-slide-toggle>
  </div>
  <div class="switch-panel" *ngIf="cesiumMap">
    <mat-slide-toggle [(ngModel)]="cesium3dActive" (ngModelChange)="activateCesiumSwitch()">
      {{'PAGES.MAP.BUILDINGS' | translate | uppercase }}</mat-slide-toggle>
  </div>
</div>
<mat-accordion class="map-control-bar">
  <ng-container *ngFor="let panel of data">
    <mat-expansion-panel class="panel" *ngIf="panel.canView">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h6>{{ ('PAGES.MAP.LABELS.' + panel.label | translate) !== 'PAGES.MAP.LABELS.' + panel.label ? ('PAGES.MAP.LABELS.' + panel.label | translate | uppercase) : panel.label | uppercase }}</h6>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let field of panel.fields">
        <div class="panel__entry" [ngClass]="{'disabled': !field.enabled}" *ngIf="field.canView">
          <div class="panel__entry__top">
            <div class="panel__entry__top__left" (click)="togglePanel(field)">
              <span>{{ ('PAGES.MAP.LABELS.' + field.label | translate) !== 'PAGES.MAP.LABELS.' + field.label ? ('PAGES.MAP.LABELS.' + field.label | translate | uppercase) : field.label | uppercase }}</span>
            </div>
            <em *ngIf="!field.icon.includes('fa-')" [ngStyle]="field.active && {'color': field.color}"
                (click)="togglePanel(field)"
                class="icon icon-venice_{{field.icon}}"></em>
            <em *ngIf="field.icon.includes('fa-')" [ngStyle]="field.active && {'background-color': field.color}"
                (click)="togglePanel(field)"
                class="icon fa {{field.icon}}"></em>
          </div>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>
