import { Injectable } from '@angular/core';
import { WidgetService } from '@app/modules/widgets/widget/widget.service';
import { TimeMachineRange } from '@app/shared/components/time-machine/models/classes/time-machine-range';
import { Observable, of } from 'rxjs';
import { IframeDataSource } from './models/iframe-data-source';

@Injectable()
export class IframeWidgetService extends WidgetService {

  loadRangeData(timeRange: TimeMachineRange, sources: Array<IframeDataSource>): Observable<string> {
    const mainSource: IframeDataSource = sources.find((i: IframeDataSource) => {
      return i.sourceType === 'iframe';
    });
    if (mainSource) {
      return of(this.replaceTimeRangeUrl(timeRange, mainSource));
    } else {
      return of(null);
    }
  }

  loadTimeStampData(timestamp: number, sources: Array<IframeDataSource>): Observable<string> {
    const mainSource: IframeDataSource = sources.find((i: IframeDataSource) => {
      return i.sourceType === 'iframe';
    });
    if (mainSource) {
      return of(this.replaceTimeStampUrl(timestamp, mainSource, 24, 0));
    } else {
      return of(null);
    }
  }

  replaceTimestamp(url: string, index: number, timestamp: string): string {
    return url.substring(0, index) + timestamp + url.substring(index + 13);
  }
}
