import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppMaterialModule } from '@app/modules/app-material/app-material.module';
import { MapBaseControlComponent } from '@app/modules/map/map-base-control/map-base-control.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    MapBaseControlComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    TranslateModule,
  ],
  exports: [MapBaseControlComponent],
})
export class MapBaseControlModule {
}
