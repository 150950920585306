import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-map-base-control',
  templateUrl: './map-base-control.component.html',
  styleUrls: ['./map-base-control.component.scss'],
})
export class MapBaseControlComponent implements OnInit {

  @Input()
  show: boolean;

  @Input()
  titleLabel: string;

  @Input()
  loading: boolean;

  constructor() {
  }

  ngOnInit(): void {
    if (this.show === undefined) {
      this.show = true;
    }
  }

  toggleControl(): void {
    this.show = !this.show;
  }
}
