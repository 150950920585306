import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { TideMapWidgetService } from '@app/modules/widgets/tide-map-widget/tide-map-widget.service';
import { TranslateModule } from '@ngx-translate/core';
import { TideControlComponent } from './tide-control/tide-control.component';
import { TideMapWidgetComponent } from './tide-map-widget.component';

@NgModule({
  declarations: [TideMapWidgetComponent, TideControlComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    MapWidgetModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  providers: [TideMapWidgetService],
  exports: [TideMapWidgetComponent, TideControlComponent],
})
export class TideMapWidgetModule {
}
