import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MapTideControlComponent } from './map-tide-control.component';
import { MapBaseControlModule } from '../map-base-control/map-base-control.module';
import { MatSliderModule } from '@angular/material/slider';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [MapTideControlComponent],
  imports: [
    CommonModule,
    MapBaseControlModule,
    MatSliderModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
    FormsModule
  ],
  exports: [MapTideControlComponent]
})

export class MapTideControlModule { }
