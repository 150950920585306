import { MapLegend } from '@app/shared/models/map-legend/map-legend';

export const AIR_QUALITY_LEGEND: MapLegend = {
  title: 'AIR_QUALITY_TITLE',
  description: 'AIR_QUALITY_DESCRIPTION',
  type: 'AIR_QUALITY',
  elements: [{
    color: '#cdcdcd',
    label: 'Non disponibile',
  }, {
    color: '#99ffff',
    label: 'c < 50',
  }, {
    color: '#66d9ff',
    label: '50 < c < 100',
  }, {
    color: '#00b3ff',
    label: 'c > 100',
  }],
  additionalData: null,
};
