import { TideLevel } from '../models/interfaces/tide-level';

export const TIDE_LEVELS: Array<TideLevel> = [{
  value: 150,
  color: null,
  area: 'red',
  icons: [],
  display: false,
  right: false,
}, {
  value: 140,
  color: 'red',
  area: 'red',
  icons: ['marea-allarme'],
  display: true,
  right: true,
}, {
  value: 120,
  color: 'red',
  area: 'orange',
  icons: ['marea-passerelle-off'],
  display: true,
  right: false,
}, {
  value: 110,
  color: 'orange',
  area: 'yellow',
  icons: ['marea-motore-off', 'marea-sirena'],
  display: true,
  right: true,
}, {
  value: 80,
  color: 'orange',
  area: 'yellow-bis',
  icons: ['marea-messaggio', 'marea-pedana'],
  display: true,
  right: false,
}, {
  value: 0,
  color: null,
  area: 'green',
  icons: [],
  display: true,
  right: false,
}, {
  value: -50,
  color: 'yellow',
  area: 'green-bis',
  icons: ['marea-motore-off'],
  display: true,
  right: false,
}, {
  value: -90,
  color: 'yellow',
  area: 'white',
  icons: [],
  display: true,
  right: false,
}, {
  value: -100,
  color: null,
  area: 'blue',
  icons: [],
  display: false,
  right: false,
}];
