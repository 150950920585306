import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SpecialPermissionConfig } from '@app/shared/models/app-config/app-options';
import { AppConfigService } from '@services/app-config/app-config.service';
import { GridService } from '@services/grid/grid.service';
import { AuthenticationService, User } from '@services/authentication/authentication.service';
import { StorageService } from '@services/storage/storage.service';
import { ThemeService } from '@services/theme-service/theme.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  userData: User;
  userFirstname: string;
  userSurname: string;
  viewRole: boolean;
  viewPrivilege: boolean;
  isGridMovableSubscription: Subscription;
  isGridEditable: boolean;
  savedDashboard: string;
  docs: string;
  isAdmin: boolean;
  isIndexWeightAdmin: boolean;
  isThresholdAdmin: boolean;
  canSeeDarkTheme: boolean;


  @Input()
  hideDashboardSettings: boolean;

  constructor(
    public dashboardService: GridService,
    private appConfigService: AppConfigService,
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    public themeService: ThemeService,
    public dialog: MatDialog) {
  }

  isString(obj: string | Array<string>): boolean {
    return typeof obj === 'string';
  }

  ngOnInit(): void {
    this.isGridEditable = this.dashboardService.checkGridEditable();
    this.isGridMovableSubscription = this.dashboardService.gridEditable.subscribe((status: boolean) => {
      this.isGridEditable = status;
    });
    this.userData = this.authenticationService.readUserData();
    this.savedDashboard = this.storageService.getSavedDashboard();
    this.viewRole = this.userData.roles && this.userData.roles.length === 1;
    this.viewPrivilege = this.userData.privileges && this.userData.privileges.length === 1;
    this.userFirstname = this.userData.firstname ? this.userData.firstname : '';
    this.userSurname = this.userData.lastname ? this.userData.lastname : '';
    this.docs = this.appConfigService.getOptions().docs;
    this.isAdmin = this.authenticationService.checkPermissionsConfig(this.appConfigService.getSpecialPermissionConfig('admin'));
    this.isIndexWeightAdmin = this.authenticationService.checkPermissionsConfig(this.appConfigService.getSpecialPermissionConfig('index-weight-admin'));
    this.isThresholdAdmin = this.authenticationService.checkPermissionsConfig(this.appConfigService.getSpecialPermissionConfig('threshold-admin'));
    this.canSeeDarkTheme = this.authenticationService.checkPermissionsConfig(this.appConfigService.getSpecialPermissionConfig('dark-theme'));
  }

  openManager(): void {
    const managerUrl: string = this.appConfigService.getOptions().manager;
    window.open(managerUrl, '_blank');
  }

  clearGrid(): void {
    this.dashboardService.announceClearGrid(true);
  }

  toggleDark(): void {
    this.themeService.toggleDark();
  }

  addWidget(): void {
    this.dashboardService.announceAddWidget();
  }

  toggleEditWidgets(): void {
    this.isGridEditable = !this.isGridEditable;
    this.dashboardService.announceGridEditable(this.isGridEditable);
  }

  saveGrid(): void {
    this.dashboardService.announceSaveGrid();
  }

  loadGrid(): void {
    this.dashboardService.announceLoadGrid();
  }

  removeGrid(): void {
    this.dashboardService.announceRemoveGrid();
  }

  logout(): void {
    this.authenticationService.makeLogout();
  }

  editIndexData(): void {
    this.dashboardService.announceEditIndexData();
  }

  editGlobalData(): void {
    this.dashboardService.announceEditGlobalData();
  }

  userHasRole(role: string): boolean {
    if (!this.isString(this.userData.roles)) {
      const hasRole: string = (this.userData.roles as Array<string>).find((d: string) => {
        return d === role;
      });
      return hasRole !== undefined;
    } else {
      return this.userData.roles === role;
    }
  }
}
