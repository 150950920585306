import { Injectable } from '@angular/core';
import {
  StreetEntry,
  StreetListRequest,
  StreetListResult,
  StreetType,
  TransitCount,
  TransitCountRequestBody,
} from '@app/features/pages/sanctions-page/model';
import { MapWidgetService } from '@app/modules/widgets/map-widget/map-widget.service';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { WidgetDataSource } from '@app/shared/models/app-config/widget-data-source';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class SanctionsService extends MapWidgetService {

  private getTransitCountParams(timeMachineData: TimeMachineData, streetIds?: Array<number>): TransitCountRequestBody {
    const params: TransitCountRequestBody = {
      ids: streetIds,
      dateFrom: '',
      dateTo: null,
    };
    if (typeof timeMachineData === 'number') {
      params.dateFrom = moment(timeMachineData).startOf('day').format('YYYY-MM-DD HH:mm:ss.SS');
      params.dateTo = moment(timeMachineData).endOf('day').format('YYYY-MM-DD HH:mm:ss.SS');
    } else {
      params.dateFrom = timeMachineData.getFrom().format('YYYY-MM-DD HH:mm:ss.SS');
      params.dateTo = timeMachineData.getTo().format('YYYY-MM-DD HH:mm:ss.SS');
    }
    return params;
  }

  protected getStreetList(timeMachineData: TimeMachineData,
                          sources: Array<WidgetDataSource>, streetTypes: Array<StreetType>): Observable<Array<StreetEntry>> {
    const body: StreetListRequest = {
      fields: [],
      filter: {
        types: streetTypes,
      },
      order: [],
      pagination: {
        from: 0,
        size: 10000,
      },
    };
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.name === 'sanctions-street-list';
    });
    if (mainSource) {
      let url: string;
      url = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 1);
      return this.http.post<StreetListResult>(url, body).pipe(switchMap((s: StreetListResult) => {
        return of(s.data);
      }));
    }
  }

  getPeriodTransitCount(timeMachineData: TimeMachineData,
                        sources: Array<WidgetDataSource>, streetIds: Array<number>): Observable<Array<TransitCount>> {
    const mainSource: WidgetDataSource = sources.find((w: WidgetDataSource) => {
      return w.name === 'sanctions-transit-count';
    });
    if (mainSource) {
      let url: string;
      url = this.addTimeMachineDataToUrl(timeMachineData, mainSource, 1);
      const body: TransitCountRequestBody = this.getTransitCountParams(timeMachineData, streetIds);
      return this.http.post<Array<TransitCount>>(url, body);
    }
  }

}
