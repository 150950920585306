import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { RegistrySearchQuery } from '@app/modules/map/registry-search/registry-search.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { MapWidgetComponent } from '@app/modules/widgets/map-widget/map-widget.component';
import {
  GeocodingAddressRequest,
  GeocodingLatitudeRequest,
  GeocodingRequest,
  GeocodingRequestType,
} from '@app/modules/widgets/registry-widget/model';
import { RegistryService } from '@app/modules/widgets/registry-widget/registry.service';
import { Circle, circle, FeatureGroup, LeafletMouseEvent, Map } from 'leaflet';

@Component({
  selector: 'app-registry-widget',
  templateUrl: './registry-widget.component.html',
  styleUrls: ['./registry-widget.component.scss'],
})
export class RegistryWidgetComponent extends MapWidgetComponent implements OnInit, AfterViewInit {

  private circleRadius: number = 20;

  level: FeatureGroup;
  circleLayer: Circle;

  constructor(public widgetService: RegistryService, public injector: Injector) {
    super(widgetService, injector);
  }

  ngAfterViewInit(): void {
    this.checkSources();
  }

  onMapReady(leafletMap: Map): void {
    super.onMapReady(leafletMap);
    this.map.on('click', async (e: LeafletMouseEvent) => {
      const data: GeocodingLatitudeRequest = {
        type: GeocodingRequestType.LATITUDE,
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
        radius: this.circleRadius / 1000,
      };
      this.loadRegistryLevel(data);
    });
    this.map.on('mousemove', (e: LeafletMouseEvent) => {
      if (!this.circleLayer) {
        this.circleLayer = circle(e.latlng, { radius: this.circleRadius });
        this.circleLayer.addTo(this.map);
      }
      this.circleLayer.setLatLng(e.latlng);
    });
  }

  clear(): void {
    if (this.level) {
      this.level.remove();
    }
  }

  loadWidget(timeMachineData: TimeMachineData): void {
  }

  registrySearch(query: RegistrySearchQuery): void {
    this.isLoading = true;
    const data: GeocodingAddressRequest = {
      type: GeocodingRequestType.ADDRESS,
      address: query.argument,
      exactly: query.exactly,
    };
    this.loadRegistryLevel(data);
  }

  loadRegistryLevel(data: GeocodingRequest): void {
    this.isLoading = true;
    this.dataSubscription = this.widgetService.getMapLevel(this.timeMachineService.getCurrentSelection(), this.sources, data).subscribe((level: FeatureGroup) => {
      if (this.level) {
        this.level.remove();
      }
      this.level = level;
      this.level.addTo(this.map);
      this.isLoading = false;
    });
  }
}
