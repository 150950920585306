import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import moment from 'moment';
import { RouteEntry } from './model';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AddressResponse } from '@app/modules/widgets/registry-widget';

@Component({
  selector: 'app-destination-route-manager',
  templateUrl: './destination-route-manager.component.html',
  styleUrls: ['./destination-route-manager.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DestinationRouteManagerComponent {

  @Input()
  routeItems: Array<RouteEntry>;

  @Input()
  estimatedTime: number;

  @Input()
  pathLength: number;

  @Output()
  search: EventEmitter<NavigatorSearchQuery> = new EventEmitter<NavigatorSearchQuery>();

  @Output()
  searchUpdate: EventEmitter<NavigatorSearchQuery> = new EventEmitter<NavigatorSearchQuery>();

  @Output()
  refreshRoute: EventEmitter<Array<RouteEntry>> = new EventEmitter<Array<RouteEntry>>();

  @Output()
  selectedChange: EventEmitter<RouteEntry> = new EventEmitter<RouteEntry>();

  @Output()
  remove: EventEmitter<RouteEntry> = new EventEmitter<RouteEntry>();

  constructor(private titleCasePipe: TitleCasePipe) {
  }

  updateSearchOption(newModel: string, item: RouteEntry): void {
    item.searchValue = newModel;
    this.updateAutocomplete(item);
  }

  updateAutocomplete(item: RouteEntry): void {
    if (item.searchValue && item.searchValue.length >= 3) {
      this.searchUpdate.emit({ type: 0, argument: item.searchValue, exactly: false, index: item.id });
    } else if (item.options.length > 0) {
      item.options = [];
      if (item.marker) {
        item.marker.remove();
        item.marker = null;
        item.latitude = null;
        item.longitude = null;
        this.refreshRoute.emit(this.routeItems);
      }
    }
  }

  drop(event: CdkDragDrop<Array<RouteEntry>>): void {
    moveItemInArray(this.routeItems, event.previousIndex, event.currentIndex);
    this.refreshRoute.emit(this.routeItems);
  }

  switchActiveItem(route: RouteEntry): void {
    this.routeItems.filter((r: RouteEntry) => r.id !== route.id).forEach((r: RouteEntry) => {
      r.active = false;
    });
    route.active = true;
    this.selectedChange.emit(route);
  }

  removeItem(route: RouteEntry): void {
    this.routeItems = this.routeItems.filter((r: RouteEntry) => r.id !== route.id);
    this.routeItems.forEach((r: RouteEntry, index: number) => {
      r.id = index + 1;
    });
    this.remove.emit(route);
    this.refreshRoute.emit(this.routeItems);
  }

  searchItem(route: RouteEntry): void {
    this.switchActiveItem(route);
    this.search.emit({ type: 0, argument: route.searchValue, exactly: false, index: route.id });
  }

  addItem(): void {
    this.routeItems.push({
      id: this.routeItems.length + 1,
      active: false,
    });
    this.switchActiveItem(this.routeItems[this.routeItems.length - 1]);
    // this.refreshRoute.emit(this.routeItems);
  }

  refreshPath(): void {
    this.refreshRoute.emit(this.routeItems);
  }

  getTimeFromMinutes(minutes: number): string {
    let time: string = '';
    const hours: number = Math.floor(moment.duration(minutes, 'minutes').asHours());
    if (hours > 0) {
      time += hours + 'h ';
    }
    time += moment.duration(minutes, 'minutes').minutes() + 'min';
    return time;
  }

  getPathFromMeters(meters: number): string {
    return meters >= 1000 ? (Math.floor(meters / 1000) + 'Km ' + ((meters % 1000) > 0 ? (meters % 1000) + 'm' : '')) : meters + 'm';
  }

  displayFn(address: string): string {
    return address ? address : '';
  }

  onAutocompileSelect(event: MatAutocompleteSelectedEvent, route: RouteEntry): void {
    const selected: AddressResponse = route.options ? route.options.find((a: AddressResponse) => a.roadAddress === event.option.value) : null;
    if (selected) {
      route.searchValue = selected.roadAddress.toUpperCase();
      route.latitude = selected.point.lat;
      route.longitude = selected.point.lng;
      if (route.marker) {
        route.marker.remove();
        route.marker = null;
      }
    }
    this.refreshRoute.emit(this.routeItems);
  }
}

export interface NavigatorSearchQuery {
  type: number;
  argument: string;
  exactly: boolean;
  index: number;
}
