<app-map-base-control [titleLabel]="titleLabel" [loading]="loading">
  <div class="map-tide-control p-3">
    <div>
      <span>{{ 'COMMONS.MAP_TIDE_CONTROL.HEIGHT' | translate}}</span>
      <mat-slider [min]="-100" [max]="200" [step]="1"
                  [(ngModel)]="sliderTideValue" [tickInterval]="20" [disabled]="loading"
                  (ngModelChange)="updateTideValue($event)"></mat-slider>
      <div>{{sliderTideValue | number:'1.0-0'}} cm</div>
      <button [ngClass]="{'button--future': future,
      'button--past': past}" mat-raised-button [disabled]="loading"
              (click)="setActualTide()">{{'COMMONS.MAP_TIDE_CONTROL.ACTUAL_TIDE' | translate}}</button>
    </div>
    <div *ngIf="options.offsetEnabled">
      <span>{{ offsetLabel | translate}}</span>
      <mat-slider [min]="0" [max]="100" [step]="1"
                  [(ngModel)]="sliderTideOffsetValue" [disabled]="loading"
                  (ngModelChange)="updateTideOffsetValue($event)"></mat-slider>
      <div class="value mr-2">{{sliderTideOffsetValue | number:'1.0-0'}} cm</div>
      <mat-checkbox [(ngModel)]="walkways" [disabled]="loading || options.walkwaysReadOnly" (change)="walkwaysChanged.emit(walkways)">{{'COMMONS.MAP_TIDE_CONTROL.WALKWAYS' | translate}}</mat-checkbox>
    </div>
    <div *ngIf="options.bootsEnabled">
      <span>{{ bootsLabel | translate}}</span>
      <mat-select (selectionChange)="selectedBootsChanged($event)" [(ngModel)]="bootsValue">
        <mat-option *ngFor="let option of options.bootsOptions" [value]="option">
          {{ option.label | translate}}
        </mat-option>
      </mat-select>
      <div class="value">{{bootsValue ? bootsValue.value : 0 | number:'1.0-0'}} cm</div>
      <mat-checkbox [(ngModel)]="walkways" [disabled]="loading || options.walkwaysReadOnly" (change)="walkwaysChanged.emit(walkways)">{{'COMMONS.MAP_TIDE_CONTROL.WALKWAYS' | translate}}</mat-checkbox>
    </div>
    <div *ngIf="options.bootsEnabled" class="d-flex justify-content-end">
      <span>{{ bootsOffsetLabel | translate}}</span>
      <span class="ml-2">{{bootsValue ? bootsValue.offset : 0 | number:'1.0-0'}} cm</span>
      <mat-icon class="ml-3" (click)="openBootsSettings()">settings</mat-icon>
    </div>
  </div>
</app-map-base-control>
