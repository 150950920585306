<div class="p-3">
  <h6>{{'WIDGETS.BRIDGES.HEIGHT' | translate }}</h6>
  <div class="control__slider d-flex flex-row justify-content-between align-items-center">
    <mat-slider class="flex-grow-1" [min]="0" [max]="3" [step]="0.1" [(ngModel)]="boatHeightValue" [tickInterval]="10"
                (input)="updateValue($event, 'boatHeightValue')"
                (change)="setNewValue($event, 'boatHeightValue')"></mat-slider>
    <div class="control__slider__value flex-grow-0 pl-1">{{boatHeightValue | number:'1.0-2'}} m</div>
  </div>
  <h6>{{'WIDGETS.BRIDGES.TIDE' | translate }}</h6>
  <div class="control__slider d-flex flex-row justify-content-between align-items-center">
    <mat-slider class="flex-grow-1" [min]="-100" [max]="200" [step]="1" [(ngModel)]="tideValue" [tickInterval]="50"
                (input)="updateValue($event, 'tideValue')"
                (change)="setNewValue($event, 'tideValue')"></mat-slider>
    <div class="control__slider__value flex-grow-0 pl-1">{{tideValue | number:'1.0-1'}} cm</div>
  </div>
  <div class="control__button d-flex flex-row justify-content-end align-items-center">
    <button mat-raised-button [ngClass]="{'button--future': timeMachineService.forecast,
    'button--past': timeMachineService.past}"
            (click)="setActualTide()">{{'WIDGETS.BRIDGES.ACTUAL_TIDE' | translate}}</button>
  </div>
</div>
