<ng-template #table let-tableDataSource="tableDataSource" let-camera="camera">
  <div class="table" [hidden]="!(data.type==='water')" *ngIf="tableDataSource">
    <table mat-table matSort [dataSource]="tableDataSource"
      (matSortChange)="onSortActivate($event, tableDataSource, camera)">
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="round" [style.backgroundColor]="row.color">

          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.TRAFFIC.WATER_LABELS.TYPE' | translate }}</th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.type}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' |
          translate }} </td>
      </ng-container>

      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.WATER_LABELS.BOAT_MIN' | translate }}
        </th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.total}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal(tableDataSource, 'total')}} </td>
      </ng-container>

      <!-- N Column -->
      <ng-container matColumnDef="N">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.WATER_LABELS.NORTH' | translate }}
        </th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.N}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal(tableDataSource, 'N')}} </td>
      </ng-container>

      <!-- S Column -->
      <ng-container matColumnDef="S">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.WATER_LABELS.SOUTH' | translate }}
        </th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.S}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal(tableDataSource, 'S')}} </td>
      </ng-container>

      <!-- W Column -->
      <ng-container matColumnDef="W">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.WATER_LABELS.WEST' | translate }}
        </th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.W}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal(tableDataSource, 'W')}} </td>
      </ng-container>

      <!-- E Column -->
      <ng-container matColumnDef="E">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.WATER_LABELS.EAST' | translate }}
        </th>
        <td mat-cell (click)="selectData(row)" *matCellDef="let row"> {{row.E}} </td>
        <td mat-footer-cell (click)="selectData(null)" *matFooterCellDef>{{getTotal(tableDataSource, 'E')}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="waterTableColumns"></tr>
      <tr mat-row [ngClass]="{'selected': (selectedEntry && selectedEntry.type == row.type)}"
        *matRowDef="let row; columns:waterTableColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="waterTableColumns"></tr>
    </table>
  </div>

  <!-- Table For Road Flows -->
  <div class="table" [hidden]="!(data.type==='road')" *ngIf="tableDataSource">
    <table mat-table matSort [dataSource]="tableDataSource">
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="td-icon">

            <div class="road-round" [style.backgroundColor]="row.color"></div>
            <i [ngClass]="row.font" [style.color]="row.fontColor"></i>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'WIDGETS.TRAFFIC.ROAD_LABELS.TYPE' | translate }}</th>
        <td mat-cell *matCellDef="let row"> {{row.type}} </td>
        <td mat-footer-cell *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' | translate }} </td>
      </ng-container>

      <!-- Total Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'WIDGETS.TRAFFIC.ROAD_LABELS.VEHICLE_TIME' | translate
          }} </th>
        <td mat-cell *matCellDef="let row"> {{row.total}} </td>
        <td mat-footer-cell *matFooterCellDef>{{getTotal(tableDataSource, 'total')}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="roadTableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns:roadTableColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="roadTableColumns"></tr>
    </table>
  </div>

</ng-template>

<ng-template #cameraData let-trafficDataEntry="trafficDataEntry" let-data="data">
  <div class="note" *ngIf="!timeMachineService.rangeOn">
    <i class="note__text">{{'WIDGETS.TRAFFIC.DEFAULT_DATA' | translate }}</i>
  </div>
  <ng-container *ngTemplateOutlet="table; context:trafficDataEntry"></ng-container>
  <!-- Pie Chart -->
  <div class="pie-chart d-flex justify-content-center align-items-center-center"
    *ngIf="(data.type==='water' || data.type==='road') && trafficDataEntry && trafficDataEntry.graphData">
    <div class="wrapper d-flex justify-content-center align-items-center">
      <canvas #pieChart baseChart [data]="trafficDataEntry.graphData.data" [labels]="trafficDataEntry.graphData.labels"
        [chartType]="pieChartType" [options]="pieChartOptions" [colors]="trafficDataEntry.graphData.colors">
      </canvas>
    </div>
  </div>
</ng-template>

<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
  [isEditable]="isEditable" (sendReloadWidget)="reloadWidget()" [timeMachineOn]="timeMachineOn"
  (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus" [isLoading]="isLoading" [noData]="noData">
  <div class="traffic d-flex flex-column justify-content-between align-items-stretch">
    <mat-accordion class="traffic__data" *ngIf="trafficData && trafficData.length > 1" [displayMode]="'flat'"
      (mouseleave)="deselectAllZones()">
      <ng-container *ngFor="let trafficDataEntry of trafficData">
        <mat-expansion-panel [id]="trafficDataEntry.camera" *ngIf="trafficDataEntry && trafficDataEntry.zone"
          [expanded]="trafficDataEntry.expanded" (mouseover)="selectZone(trafficDataEntry)"
          (click)="expandPanel(trafficDataEntry)" (afterExpand)="scrollToTop(trafficDataEntry)">
          <mat-expansion-panel-header class="traffic__data__panel" [collapsedHeight]="'3rem'" [expandedHeight]="'3rem'"
            [ngClass]="{'traffic__data__panel--selected': trafficDataEntry.zone.selected}">
            <mat-panel-title class="title">
              <strong>{{trafficDataEntry.camera}}</strong>
            </mat-panel-title>
            <mat-panel-description>
              {{'WIDGETS.TRAFFIC.TOTAL' | translate }} {{trafficDataEntry.total}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ng-container *ngTemplateOutlet="cameraData; context:{trafficDataEntry: trafficDataEntry, data: data}">
            </ng-container>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <ng-container *ngIf="trafficData && trafficData.length === 1">
      <div class="traffic__data__title">
        <b>{{trafficData[0].zone.name}}</b>
      </div>
      <ng-container *ngTemplateOutlet="cameraData; context:{trafficDataEntry: trafficData[0], data: data}">
      </ng-container>
    </ng-container>
  </div>
</app-widget>
