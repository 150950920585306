import { Component, Injector, OnInit } from '@angular/core';
import { RoadFlowBaseService } from '@app/modules/widgets/flows/road-flow-base-widget/road-flow-base.service';
import { WidgetComponent } from '@app/modules/widgets/widget/widget.component';
import { TimeMachineData } from '@app/shared/components/time-machine/models';
import { DataSourceStatus } from '@app/shared/models/app-config/data-source-status';
import { Configuration } from '@app/shared/models/configuration/configurationUpdateRequest';

@Component({
  selector: 'app-road-flow-base-widget',
  templateUrl: './road-flow-base-widget.component.html',
  styleUrls: ['./road-flow-base-widget.component.scss'],
})
export class RoadFlowBaseWidgetComponent extends WidgetComponent implements OnInit {
  number: any;

  constructor(public widgetService: RoadFlowBaseService, public injector: Injector) {
    super(widgetService, injector);
  }

  loadWidget(timeMachineData: TimeMachineData): void {
    this.isLoading = true;
    this.widgetService.loadData(timeMachineData, this.sources).subscribe((d: Configuration) => {
      const data: any = JSON.parse(d.configuration);
      this.number = data.base;
      this.sourceStatus = DataSourceStatus.AVAILABLE;
      this.isLoading = false;
      this.noData = false;
    }, (error: any) => {
      this.isLoading = false;
      this.noData = true;
    });
  }
}
