import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MapWidgetModule } from '@app/modules/widgets/map-widget/map-widget.module';
import { EventMapWidgetService } from './event-map-widget.service';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { TranslateModule } from '@ngx-translate/core';
import { PublicWorksControlComponent } from './public-works-control/public-works-control.component';
import { EventMapWidgetComponent } from '@app/modules/widgets/event-map-widget/event-map-widget.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [EventMapWidgetComponent, PublicWorksControlComponent],
  imports: [CommonModule,
    FormsModule,
    MatSliderModule,
    TranslateModule,
    WidgetModule,
    MapWidgetModule,
    MatRadioModule],
  providers: [EventMapWidgetService],
  exports: [EventMapWidgetComponent, PublicWorksControlComponent],
})
export class EventMapWidgetModule {
}
