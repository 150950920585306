<h1 mat-dialog-title>{{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.TITLE' | translate}}</h1>
<div mat-dialog-content>
  <div class="row">
    <div class="col-md-12">
      <h4>{{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.STARTS' | translate}}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table>
        <thead>
        <tr>
          <th class="name p-2">
            {{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.TRIP_KEY' | translate}}
          </th>
          <th class="p-2" *ngFor="let tripKey of tripKeys">
            {{tripKey}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stop of stops" [ngClass]="{'active': stop.sequence === data.stopKey}">
          <td class="name p-2">
            {{stop.name}}
          </td>
          <td class="p-2" *ngFor="let tripKey of tripKeys">
            <ng-container
              *ngFor="let time of getTimes(stop.sequence, tripKey, 'start')">{{time}}</ng-container>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <hr/>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <h4>{{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.FINISHES' | translate}}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table>
        <thead>
        <tr>
          <th class="name p-2">
            {{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.TRIP_KEY' | translate}}
          </th>
          <th class="p-2" *ngFor="let tripKey of tripKeys">
            {{tripKey}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let stop of stops">
          <td class="name p-2">
            {{stop.name}}
          </td>
          <td class="p-2" *ngFor="let tripKey of tripKeys">
            <ng-container
              *ngFor="let time of getTimes(stop.sequence, tripKey, 'end')">{{time}}</ng-container>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end align-items-center">
  <button mat-raised-button [color]="'primary'" [mat-dialog-close]="false"
          cdkFocusInitial>{{'WIDGETS.PUBLIC_TRANSPORTS.MODAL.CLOSE' | translate}}</button>
</div>
