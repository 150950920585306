import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/pages/base-page/base-page.module').then((m: any) => m.BasePageModule),
  },
  {
    path: 'page/:page',
    loadChildren: () => import('./features/pages/base-page/base-page.module').then((m: any) => m.BasePageModule),
    canActivate: [AuthGuard],
    data: { checkOnMenu: true },
  },
  {
    path: 'map',
    loadChildren: () => import('./features/pages/map-page/map-page.module').then((m: any) => m.MapPageModule),
    canActivate: [AuthGuard],
    data: { checkOnMenu: true },
  },
  {
    path: 'sanctions',
    loadChildren: () => import('./features/pages/sanctions-page/sanctions-page.module').then((m: any) => m.SanctionsPageModule),
    canActivate: [AuthGuard],
    data: { checkOnMenu: true },
  },
  {
    path: 'cesium',
    loadChildren: () => import('./features/pages/cesium-map/cesium-map.module').then((m: any) => m.CesiumMapModule),
    canActivate: [AuthGuard],
    data: { checkOnMenu: true },
  },
  {
    path: 'navigator',
    loadChildren: () => import('./features/pages/navigator-page/navigator-page.module').then((m: any) => m.NavigatorPageModule),
    canActivate: [AuthGuard],
    data: { checkOnMenu: true },
  },
  {
    path: 'camera-window',
    loadChildren: () => import('./features/camera-window/camera-window.module').then((m: any) => m.CameraWindowModule),
  },
  {
    path: 'error',
    loadChildren: () => import('./features/error-page/error-page.module').then((m: any) => m.ErrorPageModule),
  },
  // otherwise redirect to home
  {
    path: '**',
    loadChildren: () => import('./features/error-page/error-page.module').then((m: any) => m.ErrorPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
