<ng-template #table let-tableDataSource="tableDataSource" let-camera="camera">
<!-- Table For Radar FEP -->
<div class="table" *ngIf="tableDataSource" [hidden]="!(data.type==='radar_fep')">
  <table mat-table matSort [dataSource]="tableDataSource">
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="td-icon">

            <div class="road-round" [style.backgroundColor]="row.color"></div>
            <i [ngClass]="row.font" [style.color]="row.fontColor"></i>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef >{{'WIDGETS.TRAFFIC.ROAD_LABELS.TYPE' | translate }}</th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      <td mat-footer-cell *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' | translate }} </td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>  {{'WIDGETS.TRAFFIC.ROAD_LABELS.VEHICLE_MIN' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.total}} </td>
      <td mat-footer-cell *matFooterCellDef>{{getTotal(tableDataSource, 'total')}} </td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="speed">
      <th mat-header-cell *matHeaderCellDef>  {{'WIDGETS.TRAFFIC.ROAD_LABELS.AVERAGE_SPEED' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.speedAvg}} </td>
      <td mat-footer-cell *matFooterCellDef> </td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="distance">
      <th mat-header-cell *matHeaderCellDef>  {{'WIDGETS.TRAFFIC.ROAD_LABELS.AVERAGE_DISTANCE' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.distanceAvg}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="radarFepTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns:radarFepTableColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="radarFepTableColumns"></tr>
  </table>
</div>

<!-- Table For Radar Smart Sensor -->
<div class="table" *ngIf="tableDataSource" [hidden]="!(data.type==='radar_ss')">
  <table mat-table matSort [dataSource]="tableDataSource">
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row">
          <div class="td-icon">

            <div class="road-round" [style.backgroundColor]="row.color"></div>
            <i [ngClass]="row.font" [style.color]="row.fontColor"></i>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef >{{'WIDGETS.TRAFFIC.ROAD_LABELS.TYPE' | translate }}</th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      <td mat-footer-cell *matFooterCellDef> {{'WIDGETS.TRAFFIC.COMMON_LABELS.TOTAL' | translate }} </td>
    </ng-container>

    <!-- Total Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef>  {{'WIDGETS.TRAFFIC.ROAD_LABELS.VEHICLE_MIN' | translate }} </th>
      <td mat-cell *matCellDef="let row"> {{row.total}} </td>
      <td mat-footer-cell *matFooterCellDef>{{getTotal(tableDataSource, 'total')}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="radarSmartSensorTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns:radarSmartSensorTableColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="radarSmartSensorTableColumns"></tr>
  </table>
</div>
</ng-template>

<ng-template #cameraData let-trafficDataEntry="trafficDataEntry" let-data="data">
  <div class="note" *ngIf="!timeMachineService.rangeOn">
    <i class="note__text">{{'WIDGETS.TRAFFIC.DEFAULT_DATA' | translate }}</i>
  </div>
  <ng-container *ngTemplateOutlet="table; context:trafficDataEntry"></ng-container>
  <!-- Pie Chart -->
  <div class="pie-chart d-flex justify-content-center align-items-center-center"
    *ngIf="trafficDataEntry && trafficDataEntry.graphData">
    <div class="wrapper d-flex justify-content-center align-items-center">
      <canvas #pieChart baseChart [data]="trafficDataEntry.graphData.data" [labels]="trafficDataEntry.graphData.labels"
        [chartType]="pieChartType" [options]="pieChartOptions" [colors]="trafficDataEntry.graphData.colors">
      </canvas>
    </div>
  </div>
</ng-template>
<app-widget class="mindicity-widget"
            [sources]="sources" [title]="title" [description]="description" (fullScreen)="toggleFullscreen()"
  [isEditable]="isEditable" (sendReloadWidget)="reloadWidget()" [timeMachineOn]="timeMachineOn"
  (removeWidget)="sendRemoveWidget()" [sourceStatus]="sourceStatus" [isLoading]="isLoading" [noData]="noData">
  <div class="radar d-flex flex-column justify-content-between align-items-stretch">
    <mat-accordion class="traffic__data" *ngIf="trafficData && trafficData.length > 1" [displayMode]="'flat'"
      >
      <ng-container *ngFor="let trafficDataEntry of trafficData">
        <mat-expansion-panel [id]="trafficDataEntry.name" *ngIf="trafficDataEntry && trafficDataEntry.name"
          [expanded]="trafficDataEntry.expanded"
          (click)="expandPanel(trafficDataEntry)" (afterExpand)="scrollToTop(trafficDataEntry)">
          <mat-expansion-panel-header class="traffic__data__panel" [collapsedHeight]="'3rem'" [expandedHeight]="'3rem'"
            [ngClass]="{'traffic__data__panel--selected': trafficDataEntry.selected}">
            <mat-panel-title class="title">
              <strong>{{trafficDataEntry.name}}</strong>
            </mat-panel-title>
            <mat-panel-description>
              {{'WIDGETS.TRAFFIC.TOTAL' | translate }} {{trafficDataEntry.total}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <ng-container *ngTemplateOutlet="cameraData; context:{trafficDataEntry: trafficDataEntry, data: data}">
            </ng-container>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
    <ng-container *ngIf="trafficData && trafficData.length === 1">
      <div class="traffic__data__title">
        <b>{{trafficData[0].name}}</b>
      </div>
      <ng-container *ngTemplateOutlet="cameraData; context:{trafficDataEntry: trafficData[0], data: data}">
      </ng-container>
    </ng-container>
  </div>
</app-widget>
