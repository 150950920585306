import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetModule } from '@app/modules/widgets/widget/widget.module';
import { ChartsModule } from 'ng2-charts';
import { MeteoWidgetComponent } from './meteo-widget.component';
import { MeteoService } from './meteo.service';

@NgModule({
  declarations: [MeteoWidgetComponent],
  imports: [
    CommonModule,
    WidgetModule,
    ChartsModule,
  ],
  providers: [MeteoService],
  exports: [MeteoWidgetComponent],
})
export class MeteoWidgetModule {
}
